import {
  useRunDrawer,
  useTrpcMutation,
  useTrpcQuery,
  useV2TransactionQuery,
} from '@finalytic/data-ui';
import {
  ActionButton,
  Button,
  InputRangeDate,
  LoadingIndicator,
  Modal,
  ModalFooter,
  Select,
  SelectItem,
  StringParam,
  showErrorNotification,
  useQueryParams,
} from '@finalytic/ui';
import { Maybe, day, toTitleCase } from '@finalytic/utils';
import { Avatar, Center, Group, Input, Stack, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

// allow to extract all of connection
// allow to extract only certain key from connection
// prefill modal when opening from mapping
// some systems allow extracting by date ranges

export const useExtractModal = () => {
  const [{ extractConnectionId, extractType }, setExtract] = useQueryParams({
    extractConnectionId: StringParam,
    extractType: StringParam,
  });

  const close = () =>
    setExtract({ extractConnectionId: undefined, extractType: undefined });

  const open = ({
    connectionId,
    extractType,
  }: { connectionId: string; extractType: string | undefined }) =>
    setExtract({ extractConnectionId: connectionId, extractType });

  return {
    opened: !!extractConnectionId,
    close,
    open,
    data: { extractType, extractConnectionId },
  };
};

type FormInputs = {
  extractType: string | undefined | null;
  dateRange: [Date | null, Date | null] | undefined | null;
};

export const ExtractModal = () => {
  const {
    close,
    opened,
    data: { extractConnectionId, extractType },
  } = useExtractModal();

  const methods = useForm<FormInputs>();

  const { runExtraction } = useExtractMutation({
    closeModal: close,
    extractConnectionId,
  });

  const { connection, extractors, loading, refetch } = useConnectionData({
    extractConnectionId,
    opened,
  });

  useEffect(() => {
    if (opened && extractType) {
      methods.setValue('extractType', extractType);
    }
  }, [extractType, opened]);

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={
        loading ? (
          'Loading...'
        ) : connection ? (
          <Group noWrap>
            <Avatar size='sm' src={connection.logo} />
            <Text lineClamp={1}>{connection.name}</Text>
          </Group>
        ) : (
          <Text>Missing connection</Text>
        )
      }
    >
      {loading ? (
        <Center mih={300}>
          <LoadingIndicator />
        </Center>
      ) : !connection ? (
        <Center mih={300} sx={{ flexDirection: 'column' }}>
          <Text align='center' component='h3' mx='auto' mb={0}>
            Failed to fetch connection
          </Text>
          <Text align='center' component='p' mx='auto'>
            An error has occurred. Please try fetching query again.
          </Text>
          <Group>
            <Button onClick={close}>Cancel</Button>
            <ActionButton onClick={refetch}>Refetch connection</ActionButton>
          </Group>
        </Center>
      ) : (
        <>
          <Stack mb='lg'>
            {/* Select for extractTypes */}
            <Controller
              control={methods.control}
              name='extractType'
              render={({ field: { onChange, value } }) => {
                const data = useMemo<SelectItem[]>(
                  () =>
                    extractors.map((ex) => ({
                      label: toTitleCase(ex.name) || '',
                      value: ex.name,
                    })),
                  [extractors]
                );

                const selectValue = useMemo<SelectItem | undefined>(
                  () => data.find((d) => d.value === value),
                  [data, value]
                );

                return (
                  <Input.Wrapper label='Extract type'>
                    <Select
                      data={data}
                      value={selectValue}
                      setValue={(val) => onChange(val.value)}
                      removeValue={() => onChange(null)}
                      disabled={loading}
                      withSearch
                      placeholder='Select extract type'
                      popoverWidth='target'
                      searchPlaceholder='Search extract type...'
                      loading={loading}
                      withBorder
                    />
                  </Input.Wrapper>
                );
              }}
            />
            <Input.Wrapper label='Date range'>
              <Controller
                control={methods.control}
                name='dateRange'
                render={({ field: { name, onChange, value } }) => {
                  return (
                    <InputRangeDate
                      value={value || undefined}
                      name={name}
                      onChange={onChange}
                      disabled={loading}
                      placeholder='Select date range'
                      clearable
                    />
                  );
                }}
              />
            </Input.Wrapper>
          </Stack>

          <ModalFooter>
            <Group position='right'>
              <Button onClick={close}>Cancel</Button>
              <ActionButton
                loading={methods.formState.isSubmitting}
                onClick={methods.handleSubmit(runExtraction)}
              >
                Extract
              </ActionButton>
            </Group>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

const useExtractMutation = ({
  extractConnectionId,
  closeModal,
}: { extractConnectionId: Maybe<string>; closeModal: () => void }) => {
  const { setResult } = useRunDrawer();

  const { mutate } = useTrpcMutation('ecosystem', 'extract');

  const runExtraction = async (data: FormInputs) => {
    try {
      if (!extractConnectionId)
        return showErrorNotification({
          message: 'Missing connection id',
          color: 'yellow',
        });

      const range =
        data.dateRange?.every((i) => !!i) && data.dateRange.length === 2;

      console.log(data.dateRange);

      const res = await mutate({
        connectionId: extractConnectionId,
        type: data.extractType || undefined,
        range: range
          ? {
              start: day(data.dateRange![0]!).yyyymmdd(),
              end: day(data.dateRange![1]!).yyyymmdd(),
            }
          : undefined,
      });

      closeModal();
      setResult(res.runId);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    runExtraction,
  };
};

const useConnectionData = ({
  extractConnectionId,
  opened,
}: { extractConnectionId: Maybe<string>; opened: boolean }) => {
  const disableQueries = !extractConnectionId || !opened;

  const {
    data,
    loading: loadingSchema,
    refetch: refetchSchema,
  } = useTrpcQuery(
    'ecosystem',
    'extractSchema',
    {
      connectionId: extractConnectionId!,
    },
    { skip: disableQueries }
  );

  const extractors = data?.extractors ?? [];

  const {
    data: queryConnection,
    isLoading: loadingQuery,
    refetch: refetchQuery,
  } = useV2TransactionQuery(
    (q, args) => {
      const connection = q.connectionById({ id: args.connectionId });

      return {
        id: connection.id,
        name: connection.name,
        logo: connection.app?.iconRound,
      };
    },
    {
      skip: disableQueries,
      variables: {
        connectionId: extractConnectionId,
      },
    }
  );

  const [debouncedConnection] = useDebouncedValue(queryConnection, 300);

  const connection = queryConnection || debouncedConnection;

  const loading = loadingSchema || loadingQuery;

  const refetch = () => {
    refetchSchema();
    refetchQuery();
  };

  return {
    loading,
    connection,
    extractors,
    refetch,
  };
};
