import {
  gqlV2,
  useDataSourceV2,
  useTeamId,
  useV2TransactionQuery,
} from '../../../hooks';
import { formatOwnerName } from '../../../utils';
import { ListingNameCell } from '../../../views/listings/_components';
import {
  AddListingToOwnerButton,
  ListingOwnerEllipsisMenu,
} from '../../button';
import { SelectOwnershipVendor } from '../listing-detail-drawer/_components/SelectOwnershipVendor';
import { EditOwnerForm } from './EditOwnerForm';
import { OwnerDrawerMenuButton } from './OwnerDrawerMenuButton';
import { getSourceDescription } from '@finalytic/common';
import {
  Drawer,
  DrawerHeader,
  IconButton,
  LoadingIndicator,
  NoListingsTableOverlay,
  Table,
  useColors,
} from '@finalytic/ui';
import { StringParam, useQueryParamSet, useQueryParams } from '@finalytic/ui';
import { ColDef, ICellRendererParams } from '@finalytic/ui-grid';
import { ensure } from '@finalytic/utils';
import {
  faAnglesRight,
  faBuildings,
  faEnvelope,
  faLocationDot,
  faPencil,
  faPhone,
  faX,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Center,
  Divider,
  Group,
  Stack,
  Tabs,
  Text,
  Transition,
  createStyles,
} from '@mantine/core';
import { ReactNode, useMemo } from 'react';

type Ownership = ReturnType<typeof useOwner>['ownerships'][number];

function useOwner(ownerId?: string | null) {
  const [teamId] = useTeamId();
  const {
    data,
    isLoading: loading,
    refetch,
  } = useV2TransactionQuery(
    (q, args) => {
      const user = q.user({ where: args.where, limit: 1 }).map((user) => {
        const membership = user?.memberships({ limit: 1 }).map((ship) => ({
          id: ship?.id,
          status: ship?.status,
          tenantId: ship?.tenantId,
        }))[0];

        return {
          id: user?.id,
          name: user?.name,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          companyName: user?.companyName,
          addressLine1: user?.addressLine1,
          addressCity: user?.addressCity,
          addressPostcode: user?.addressPostcode,
          addressCountry: user?.addressCountry,
          phone: user?.phone,
          ownerships: user?.ownerships().map((ship) => ({
            id: ship?.id,
            listing: {
              id: ship?.listing?.id,
              address: ship?.listing?.address,
              name: ship?.listing?.title || ship?.listing?.name,
            },
            vendor: ship
              .settingsLeft({ where: { key: { _eq: 'vendor' } }, limit: 1 })
              .map((setting) => ({
                label:
                  setting.rightSource?.description ||
                  setting.rightSource?.remoteId ||
                  '',
                value: setting.value || '',
                settingId: setting.id,
              }))[0],
          })),
          status: membership?.status,
          teamId: membership?.tenantId,
        };
      });

      const sources = q
        .source({
          where: { tenantId: { _eq: args.teamId }, type: { _eq: 'vendor' } },
          order_by: [{ id: 'asc' }],
        })
        .map((source) => ({
          id: source?.id,
          remoteId: source?.remoteId,
          description: getSourceDescription(source),
        }));

      return {
        user: user ? user[0] : undefined,
        sources,
      };
    },
    {
      skip: !ownerId || !teamId,
      queryKey: ['sources', 'owners'],
      variables: {
        where: ensure<gqlV2.user_bool_exp>({
          id: { _eq: ownerId },
        }),
        teamId,
      },
    }
  );

  const owner = data?.user;

  const vendors =
    data?.sources?.map((item) => ({
      label: item.description || item.remoteId || '',
      value: item.id || '',
    })) || [];

  return {
    owner,
    ownerships: owner?.ownerships || [],
    loading,
    vendors,
    refetch,
  };
}

export const OwnerDetailDrawer = () => {
  const [{ owner: ownerId, view = 'overview' }, setQuery] = useQueryParams({
    owner: StringParam,
    view: StringParam,
  });

  const { refetch: refetchStore } = useDataSourceV2('owners');

  const closeDrawer = () => {
    setQuery({
      owner: undefined,
      view: undefined,
    });
    refetchStore('owners');
  };

  const { classes } = useTableStyles();
  const { themeColors, black, gray } = useColors();

  const { owner, loading, ownerships, refetch, vendors } = useOwner(ownerId);
  const setListing = useQueryParamSet('listing', StringParam);

  const isReinvite = owner?.status !== 'inactive';

  const columnDef = useMemo<ColDef[]>(
    () => [
      {
        field: 'listing.name',
        headerName: 'Listings',
        sort: 'asc',
        flex: 1,
        cellRenderer: ListingNameCell,
      },
      {
        field: 'vendor',
        sortable: false,
        minWidth: 150,
        flex: 1,
        cellStyle: () => ({
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '100%',
        }),
        cellRenderer: SelectOwnershipVendor,
      },
      {
        type: 'rightAligned',
        width: 50,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: ({ data, context }: ICellRendererParams<Ownership>) => {
          if (!data?.id) return null;

          return (
            <ListingOwnerEllipsisMenu
              ownershipId={data.id}
              refetchTable={context.refetchTable}
              withOwnerSplitReset={false}
            />
          );
        },
      },
    ],
    []
  );

  const iconColor = themeColors.gray[4];

  const title = formatOwnerName({
    companyName: owner?.companyName,
    firstName: owner?.firstName,
    lastName: owner?.lastName,
  });

  return (
    <Drawer opened={!!ownerId} onClose={closeDrawer} size={600}>
      <DrawerHeader title={title} onClose={closeDrawer}>
        {owner?.id && (
          <IconButton
            onClick={() =>
              setQuery((state) => ({
                view:
                  state.view === 'overview' || !state.view
                    ? 'edit'
                    : 'overview',
                owner: ownerId,
              }))
            }
          >
            <FontAwesomeIcon icon={view === 'overview' ? faPencil : faX} />
          </IconButton>
        )}
        {owner?.id && owner?.email && (
          <OwnerDrawerMenuButton
            email={owner.email}
            ownerId={owner.id}
            isReinvite={isReinvite}
            teamId={owner?.teamId}
            firstName={owner?.firstName || ''}
            lastName={owner?.lastName || owner?.name || ''}
            refetchTable={refetch}
            closeDrawer={closeDrawer}
          />
        )}
      </DrawerHeader>

      <Divider sx={{ opacity: 0.3 }} />

      {/* Info/Table Content */}
      {owner?.id && owner?.id === ownerId ? (
        <Tabs value={view || 'overview'}>
          <Tabs.Panel value='overview'>
            <Transition transition='fade' mounted={view === 'overview'}>
              {(styles) => (
                <Stack h='100%' style={styles} mb='sm'>
                  <Box px={10} pt={20}>
                    <InfoRow
                      text={owner?.email}
                      icon={
                        <FontAwesomeIcon icon={faEnvelope} color={iconColor} />
                      }
                    />
                    <InfoRow
                      text={owner?.companyName || ''}
                      icon={
                        <FontAwesomeIcon icon={faBuildings} color={iconColor} />
                      }
                    />
                    <InfoRow
                      text={owner?.phone || ''}
                      icon={
                        <FontAwesomeIcon icon={faPhone} color={iconColor} />
                      }
                    />
                    <InfoRow
                      text={[
                        owner.addressLine1,
                        owner.addressCity,
                        owner.addressPostcode,
                        owner.addressCountry,
                      ]
                        .filter((i) => !!i)
                        .join(', ')}
                      icon={
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          color={iconColor}
                        />
                      }
                    />
                  </Box>

                  <Group position='apart'>
                    <Text weight={500} size='lg' sx={{ letterSpacing: 0.4 }}>
                      Listings
                    </Text>
                    <AddListingToOwnerButton
                      refetchTable={refetch}
                      id={ownerId!}
                      select='listing'
                      teamId={owner.teamId}
                      currentIds={ownerships.map((i) => i.listing.id)}
                    />
                  </Group>
                  <Table
                    rowData={ownerships}
                    columnDefs={columnDef}
                    defaultColDef={{
                      resizable: false,
                      sortable: false,
                      suppressMovable: true,
                    }}
                    pagination={false}
                    suppressCellFocus={true}
                    getRowId={({ data }) => data?.id}
                    animateRows
                    noRowsOverlayComponent={() => (
                      <NoListingsTableOverlay
                        pt={0}
                        size={50}
                        text='No listings available for this owner.'
                      />
                    )}
                    rowClass={classes.row}
                    className={classes.table}
                    domLayout='autoHeight'
                    headerHeight={0}
                    rowHeight={55}
                    context={{ refetchTable: refetch, vendors }}
                    onRowClicked={({ data }) => {
                      if (data?.listing?.id) {
                        setListing(data?.listing?.id, 'push');
                      }
                    }}
                  />
                </Stack>
              )}
            </Transition>
          </Tabs.Panel>
          <Tabs.Panel value='edit'>
            <Transition
              mounted={view === 'edit'}
              transition={'fade'}
              duration={500}
              exitDuration={0}
            >
              {(styles) => (
                <Box
                  style={styles}
                  pb='sm'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  {loading ? (
                    <LoadingIndicator isFullPageLoading />
                  ) : (
                    owner?.id &&
                    owner?.email && (
                      <EditOwnerForm
                        email={owner.email}
                        addressLine1={owner.addressLine1 || ''}
                        addressCity={owner.addressCity || ''}
                        addressCountry={owner.addressCountry || ''}
                        addressPostcode={owner.addressPostcode || ''}
                        companyName={owner.companyName || ''}
                        ownerId={owner.id}
                        phone={owner.phone || ''}
                        firstName={owner.firstName || ''}
                        lastName={owner.lastName || ''}
                        userStatus={owner?.status || ''}
                        cancelEdit={() => {
                          setQuery({ view: 'overview', owner: ownerId });
                          refetch();
                          refetchStore('owners');
                        }}
                      />
                    )
                  )}
                </Box>
              )}
            </Transition>
          </Tabs.Panel>
        </Tabs>
      ) : (
        <Center pb={30} sx={{ height: '100%' }}>
          {loading ? (
            <LoadingIndicator isFullPageLoading />
          ) : (
            <Text color={gray.dark} weight={500}>
              Missing owner.
            </Text>
          )}
        </Center>
      )}
    </Drawer>
  );
};

const InfoRow = ({ icon, text }: { text?: string; icon: ReactNode }) => {
  const { gray } = useColors();
  if (!text) return null;

  return (
    <Group noWrap my={10}>
      <Center sx={{ width: 20 }}>{icon}</Center>
      <Text size='sm' component='span' color={gray.dark}>
        {text}
      </Text>
    </Group>
  );
};

const useTableStyles = createStyles(() => ({
  row: {
    cursor: 'pointer',
    '&.ag-row-first': {
      borderTop: 'none',
    },
  },
  table: {
    '.ag-header': {
      borderBottom: 'none',
    },
  },
}));
