import { create } from 'zustand';

type Integration = {
  id: string;
  name: string;
  icon: string;
};

type IntegrationHoverStore = {
  pms: Integration | undefined | null;
  accounting: Integration | undefined | null;
};

export type IntegrationHoverActions = {
  setPms: (integration: Integration | undefined) => void;
  setAccounting: (integration: Integration | undefined) => void;
};

const initialState: IntegrationHoverStore = {
  pms: undefined,
  accounting: undefined,
};

export function useIntegrationHover() {
  const store = useIntegrationHoverStore();

  return {
    pms: store.pms,
    accounting: store.accounting,
    setPms: (integration: Integration | undefined) => store.setPms(integration),
    setAccounting: (integration: Integration | undefined) =>
      store.setAccounting(integration),
  };
}

const useIntegrationHoverStore = create<
  IntegrationHoverStore & IntegrationHoverActions
>()((set, get) => ({
  ...initialState,
  setPms: (integration) => {
    set((state) => ({
      ...state,
      pms: integration,
    }));
  },
  setAccounting: (integration) => {
    set((state) => ({
      ...state,
      accounting: integration,
    }));
  },
}));
