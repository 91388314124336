import {
  ActionButton,
  AvatarGroup,
  Input,
  Logo,
  Modal,
  showErrorNotification,
  showSuccessNotification,
} from '@finalytic/ui';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Center, Text } from '@mantine/core';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

type Props = {
  opened: boolean;
  close: () => void;
  type: 'PMS' | 'Accounting';
  onSubmit: (
    integrationName: string
  ) => Promise<{ ok: boolean; errorMessage?: boolean }>;
};

type FormValues = {
  website: string;
};

export const MissingIntegrationModal = ({
  close,
  opened,
  onSubmit,
  type,
}: Props) => {
  const methods = useForm<FormValues>({ defaultValues: { website: '' } });

  useEffect(() => {
    if (!opened) {
      setTimeout(() => {
        methods.reset();
      }, 300);
    }
  }, [opened]);

  const handleSuccess = async (data: FormValues) => {
    try {
      const res = await onSubmit(data.website);
      if (res?.ok) {
        return showSuccessNotification({
          title: 'Thanks for your input!',
          message: `We will notify you once your ${type} is supported. Would you like to connect to different ${type}, meanwhile?`,
        });
      } else if (res?.errorMessage) {
        return showErrorNotification({
          message:
            res?.errorMessage || 'Something went wrong. Please try again.',
        });
      }
    } catch (error: any) {
      console.log(error?.message || error);
      showErrorNotification({ message: error?.message || error });
    }
  };

  const handleError = () =>
    showErrorNotification({
      title: 'Incorrect Website URL',
      message: 'Please enter a website. (https://integration.com)',
    });

  return (
    <Modal
      opened={opened}
      onClose={close}
      size={500}
      styles={{
        body: {
          padding: 20,
          paddingTop: 'initial',
        },
      }}
    >
      <Center>
        <AvatarGroup
          spacing={10}
          size={60}
          leftIcon={<Logo width={40} />}
          leftBgColor='white'
          rightIcon={<FontAwesomeIcon icon={faEllipsisH} />}
        />
      </Center>
      <Text component='h2' size='xl' align='center'>
        Request {type}
      </Text>
      <Text component='p' size='sm' align='center' mb='xl'>
        Let us know what {type} you are missing from our list. We will notify
        you once it&apos;s added.
      </Text>
      <Text component='span' display='block' size='sm' mb={5}>
        Website of {type}
      </Text>
      <Controller
        name='website'
        control={methods.control}
        rules={{
          // Copilot regex
          pattern: new RegExp(
            '^(https?:\\/\\/)?(www\\.)?([a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:[0-9]{1,5})?(\\/.*)?$',
            'i'
          ),
          required: true,
        }}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            type='url'
            inputName={field.name}
            placeholderText='https://'
            pattern='^https?://.*'
            required
            mb='xl'
          />
        )}
      />

      <Box>
        <ActionButton
          fullWidth
          onClick={methods.handleSubmit(handleSuccess, handleError)}
          loading={methods.formState.isSubmitting}
          disabled={!methods.formState.isValid}
        >
          Send integration request
        </ActionButton>
      </Box>
    </Modal>
  );
};
