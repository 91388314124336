import { ensure, usePartnerTeams, useTeamId } from '../..';
import { PaymentsFilterParams } from './_types';
import { payment_bool_exp } from '@finalytic/graphql';
import { day } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import { useMemo } from 'react';

export function useWherePayments({
  date,
  amount,
  connectionApp,
  connectionName,
  type2,
  searchInput: search,
  teamId,
}: PaymentsFilterParams & { searchInput?: string }) {
  const [currentTeamId] = useTeamId();
  const [teams] = usePartnerTeams();

  const searchQuery = useMemo(() => {
    if (search) {
      const trimmedSearch = search.trim();
      return ensure<payment_bool_exp[]>([
        {
          uniqueRef: { _ilike: `%${trimmedSearch}%` },
        },
        {
          lines: {
            reservation: { guestName: { _ilike: `%${trimmedSearch}%` } },
          },
        },
        {
          lines: {
            reservation: { confirmationCode: { _ilike: `%${trimmedSearch}%` } },
          },
        },
        {
          lines: {
            reservation: { pmsReferenceCode: { _ilike: `%${trimmedSearch}%` } },
          },
        },
        {
          lines: {
            description: { _ilike: `%${trimmedSearch}%` },
          },
        },
        /*{
          connection: {
            name: { _ilike: `%${search}%` },
          },
        },*/
      ]);
    }
    return undefined;
  }, [search]);

  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 500);

  const dateIsActive = Array.isArray(date) && !!date[0];

  const dateGt = date?.[0]
    ? day(date[0])
        .startOf('day')
        .toISOString()
    : undefined;

  const dateLt = date?.[1]
    ? day(date[1])
        .endOf('day')
        .toISOString()
    : undefined;

  return ensure<payment_bool_exp>({
    tenantId:
      teams.length > 0
        ? teamId
          ? { _eq: teamId }
          : { _in: teams.map((i) => i.id) }
        : { _eq: currentTeamId },
    type: { _in: ['payout', 'custom'] },
    payedAt: dateIsActive
      ? {
          _gte: dateGt,
          _lte: dateLt || day(dateGt).endOf('day').toISOString(),
          _is_null: false,
        }
      : { _is_null: false },
    centTotal:
      amount?.gt || amount?.lt
        ? {
            _gte: amount?.gt && amount.gt * 100,
            _lte: amount?.lt && amount.lt * 100,
          }
        : undefined,
    connection:
      connectionName || connectionApp
        ? {
            id: connectionName ? { _eq: connectionName } : undefined,
            app: connectionApp ? { id: { _eq: connectionApp } } : undefined,
            status: { _neq: 'deleted' },
          }
        : { status: { _neq: 'deleted' } },
    lines: type2
      ? {
          type2: {
            _eq: type2,
          },
        }
      : undefined,
    _or: debouncedSearchQuery,
  });
}
