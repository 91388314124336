import { useTrpcMutation } from '@finalytic/data-ui';
import { showErrorNotification } from '@finalytic/ui';

export function useStatementPdfV2Mutation() {
  const { loading: loadingPdf, mutate } = useTrpcMutation(
    'pdfExport',
    'statement',
    {
      successMessage: {
        message: 'Successfully exported PDF.',
      },
    }
  );

  const downloadPdf = async (args: Parameters<typeof mutate>[0]) => {
    if (!args.startAt && !args.ownerId && (args.statementIds || []).length < 1)
      return showErrorNotification({
        title: 'Missing input',
        message: 'Please provide either an owner or a start date.',
      });

    const res = await mutate(args);

    if (!res.data?.url)
      return showErrorNotification({ message: 'Missing download url.' });

    window.open(res.data?.url, '_blank');
  };

  return {
    downloadPdf,
    loadingPdf,
  };
}
