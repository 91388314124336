export const CheckInIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 3V6M7 21H17C19.2091 21 21 19.2091 21 17V8C21 5.79086 19.2091 4 17 4H7C4.79086 4 3 5.79086 3 8V17C3 19.2091 4.79086 21 7 21Z'
      stroke='#5C6178'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M16 3V6'
      stroke='#5C6178'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M15 11L11.25 15L9 13.1818'
      stroke='#5C6178'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const CheckOutIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 3V6M7 21H17C19.2091 21 21 19.2091 21 17V8C21 5.79086 19.2091 4 17 4H7C4.79086 4 3 5.79086 3 8V17C3 19.2091 4.79086 21 7 21Z'
      stroke='#5C6178'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M16 3V6'
      stroke='#5C6178'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <g clipPath='url(#clip0_140_23068)'>
      <path
        d='M15 13L12.6667 10.6667M9 13L15 13L9 13ZM15 13L12.6667 15.3333L15 13Z'
        stroke='#5C6178'
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_140_23068'>
        <rect
          width='8'
          height='8'
          fill='white'
          transform='translate(16 17) rotate(-180)'
        />
      </clipPath>
    </defs>
  </svg>
);
