import { useRunDrawer, useTeam } from '@finalytic/data-ui';
import { StringParam, useQueryParam } from '@finalytic/ui';
import { day } from '@finalytic/utils';

export function useStatementRunDrawer() {
  const [queryDate, setQueryDate] = useQueryParam('date', StringParam);

  const { openDrawer } = useRunDrawer();
  const [{ automations }] = useTeam();

  const automation = automations.find((x) =>
    x.name?.toLowerCase().endsWith('ownerstatements')
  );

  // TODO: Remove if works
  //const isLegacyAutomation =
  //  automation?.name === 'quickbooksOwnerStatements_ownerStatements';

  return {
    automation,
    // isLegacyAutomation,
    isLegacyAutomation: false,
    open: (date?: string) => {
      if (!queryDate) {
        setQueryDate(date || day().format('YYYY-MM-01'));
      }
      openDrawer(automation?.automationId);
    },
  };
}
