import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mantine/core';
import { forwardRef } from 'react';

export const MenuButton = forwardRef<HTMLButtonElement, { isActive: boolean }>(
  ({ isActive, ...props }, ref) => (
    <Button
      variant='white'
      name='test'
      ref={ref}
      className='view-filter-dropdown'
      p={8}
      sx={{
        opacity: isActive ? 1 : 0,
      }}
      {...props}
    >
      <FontAwesomeIcon icon={faCaretDown} color='#BEC0C9' />
    </Button>
  )
);

// Removes react/display-name warning
MenuButton.displayName = 'MenuButton';
