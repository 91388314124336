export const ReservationsIcon = ({ size = 24 }: { size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.4 10.1959V20.3079C20.4 20.9689 20.1345 21.6028 19.6619 22.0702C19.1893 22.5376 18.5483 22.8002 17.88 22.8002H6.11998C5.45163 22.8002 4.81066 22.5376 4.33807 22.0702C3.86547 21.6028 3.59998 20.9689 3.59998 20.3079V3.6925C3.59998 3.0315 3.86547 2.39757 4.33807 1.93018C4.81066 1.46278 5.45163 1.2002 6.11998 1.2002H11.3043C11.7498 1.20026 12.1769 1.37526 12.4919 1.68671L19.908 9.02137C20.223 9.3329 20.3999 9.75535 20.4 10.1959ZM7.35 13.5C7.35 13.0029 7.75294 12.6 8.25 12.6H15.75C16.2471 12.6 16.65 13.0029 16.65 13.5C16.65 13.9971 16.2471 14.4 15.75 14.4H8.25C7.75294 14.4 7.35 13.9971 7.35 13.5ZM8.25 16.35C7.75294 16.35 7.35 16.7529 7.35 17.25C7.35 17.7471 7.75294 18.15 8.25 18.15H15.75C16.2471 18.15 16.65 17.7471 16.65 17.25C16.65 16.7529 16.2471 16.35 15.75 16.35H8.25Z'
      fill='url(#paint0_linear_1705_41992)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_1705_41992'
        x1='3.59998'
        y1='2.4002'
        x2='20.4'
        y2='22.2002'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.6' />
        <stop offset='1' stopColor='white' stopOpacity='0.35' />
      </linearGradient>
    </defs>
  </svg>
);
