export const formatName = (
  {
    firstName,
    lastName,
  }: {
    firstName: string | null | undefined;
    lastName: string | null | undefined;
  },
  options?: { lastNameFirst: boolean }
) => {
  const first = firstName?.trim() || '';
  const last = lastName?.trim() || '';

  // const dash = (!!first || !!last) && company ? ' - ' : '';

  if (!first && !last) return 'System';

  if (options?.lastNameFirst) return `${last ? `${last}, ` : ''}${first}`;

  return `${first ? `${first} ` : ''}${last}`;
};
