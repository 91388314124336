import { useColors } from '../../styles';
import { Box } from '@mantine/core';
import { ReactNode, useMemo } from 'react';

type TagCellProps = {
  children: ReactNode;
  color?: 'green' | 'yellow' | 'red';
};

export const TagCell = ({ children, color }: TagCellProps) => {
  const { green, red } = useColors();

  const tagColor = useMemo(() => {
    switch (color) {
      case 'green':
        return green.base;
      case 'red':
        return red.base;
      case 'yellow':
        return '#FFE066';
      default:
        return undefined;
    }
  }, [color]);

  return (
    <Box
      sx={{
        border: '1px solid #EEEFF1',
        borderRadius: 5,
        padding: '2px 7px',
        backgroundColor: '#fff',
        fontSize: 13,
      }}
    >
      {tagColor && (
        <Box
          sx={{
            display: 'inline-block',
            width: 10,
            height: 10,
            borderRadius: 2,
            // TODO: choose color based on tag
            backgroundColor: tagColor,
            marginRight: 5,
          }}
        />
      )}
      {children}
    </Box>
  );
};
