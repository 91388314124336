import {
  ActionButton,
  Button,
  DelayedInputSearch,
  Drawer,
  IconButton,
  SRMHeaderSelectCell,
  StringParam,
  Table,
  showErrorNotification,
  useQueryParam,
} from '@finalytic/ui';
import { Box, Group, Tabs, Text, Transition } from '@mantine/core';

import { OverviewSectionContainer } from '../views/edit-automation/_components';
import { RunExistingChangesModal } from './RunExistingChangesModal';
import { ActionTable, JobTable, useRetryTask } from './components';
import { useSourcesDatasource } from './useSourcesDatasource';
import {
  InputsByJSONSchema,
  ListingsTable,
  PaymentFilter,
  PaymentTable,
  ReservationFilter,
  ReservationTable,
  ReservationViewFilter,
  TasksTable,
  gqlV2,
  resultViewParamKey,
  useBilling,
  useRunDrawer,
  useV2TransactionQuery,
  useV2TransactionSubscription,
  useWherePayments,
  useWhereReservations,
} from '@finalytic/data-ui';
import {
  StatementListFilter,
  StatementListTable,
  useStatementsList,
} from '@finalytic/owner-statements';
import { AgGridReact, ColDef } from '@finalytic/ui-grid';
import { Maybe, day, toTitleCase } from '@finalytic/utils';
import {
  faBolt,
  faChevronLeft,
  faPlayCircle,
  faRefresh,
  faSearch,
  faWrench,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSetState } from '@mantine/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

const previewMaxWidth = 1100;
const filterAllPagesSelectedKey = 'allPagesSelected';

type TableRef = React.RefObject<AgGridReact>;

export const RunDrawer = () => {
  const tableRef = useRef<AgGridReact>(null);
  const [queryParamDate] = useQueryParam('date', StringParam);

  const {
    activeDrawer,
    close,
    setPreview,
    automationId,
    navigateBack,
    result: resultId,
    runTask,
    runTaskLoading,
    getAutomationInput,
  } = useRunDrawer();

  const _filterState = useSetState<any>({});
  const filterState: FilterStateProp = {
    current: _filterState[0],
    set: _filterState[1],
    reset: () => _filterState[1]({}),
  };
  const showDrawer = !!activeDrawer;

  const resetDrawerSettings = () => {
    filterState.reset();
    methods.reset();
  };

  useEffect(() => {
    if (!showDrawer) {
      resetDrawerSettings();
    }
  }, [showDrawer]);

  const getSelectedIds: () => string[] = () =>
    tableRef.current?.api.getSelectedRows().map((i) => i.id) || [];

  const showMissingIdNotification = () =>
    showErrorNotification({
      message: 'Please select a row.',
      color: 'yellow',
      icon: undefined,
    });

  const preview = async () => {
    const ids = getSelectedIds();
    if (ids.length < 1) return showMissingIdNotification();

    setPreview(ids);
  };
  const methods = useForm();

  const { data } = useV2TransactionQuery(
    (q) => {
      const automation = q.automation({ id: automationId });

      const automationTemplate = automation?.ttemplate;

      const template = {
        label: automationTemplate?.label,
        name: automationTemplate?.name,
        input: automationTemplate?.input,
        params: automationTemplate?.params(),
      };

      const connections = {
        leftConnection: {
          id: automation?.leftConnection?.id,
          appId: automation?.leftConnection?.appId,
        },
        rightConnection: {
          id: automation?.rightConnection?.id,
          appId: automation?.rightConnection?.appId,
        },
      };

      return {
        template,
        connections,
      };
    },
    {
      queryKey: 'automations',
      skip: !automationId,
      variables: {
        automationId,
      },
    }
  );

  const template = data?.template;
  const connections = data?.connections;
  const isOwnerStatementAutomation = template?.name
    ?.toLowerCase()
    .endsWith('ownerstatements');

  const input = useMemo(() => getAutomationInput(template), [template]);

  const connectionId = useMemo(() => {
    const app = input.namespace;
    if (connections?.leftConnection.appId === app)
      return connections.leftConnection.id;

    if (connections?.rightConnection.appId === app)
      return connections.rightConnection.id;

    return '';
  }, [input.namespace, connections]);
  // console.log(input, template);

  const run = async () => {
    const ids = getSelectedIds();
    const values = methods.getValues();

    console.log(values);
    if (!automationId)
      return showErrorNotification({ message: 'Missing automation id.' });

    resetDrawerSettings();

    const allPagesSelected = filterState.current?.[filterAllPagesSelectedKey];

    const filteredDate: [Date | null | Date | null] | undefined =
      filterState.current?.date;

    const date = filteredDate
      ? filteredDate
          .filter(Boolean)
          .map((d) => day(d).format('YYYY-MM-DD'))
          .join('...')
      : values.date;

    /*if (template?.name === 'quickbooksOwnerStatements_ownerStatements') {
      await generateStatements({
        leftConnectionId: connections?.leftConnection.id,
        date: values['month'],
        listingIds: ids.length > 0 ? ids : undefined,
        legacy: true,
        refreshAfter: undefined,
      });
    } else*/
    await runTask({
      id: automationId,
      input: allPagesSelected ? [] : ids, // todo: if all pages selected, send empty array and the date filter
      params: {
        ...values,
        ...(filterState.current || {}),
        date,
        month: values['month'] || day().format('YYYY-MM-01'),
        [input.field]: ids.length === 0 ? undefined : ids,
      },
    });
  };

  // For owner statement automations extend default value with query param
  const inputJSONSchema = useMemo(() => {
    if (isOwnerStatementAutomation) {
      const temp = { ...(template?.params || {}) };
      if (temp['properties']['month']) {
        temp['properties']['month'].default = queryParamDate;
      }

      return temp;
    }
    // Otherwise return default template params
    return template?.params;
  }, [template, queryParamDate, isOwnerStatementAutomation]);

  const children = (() => {
    if (activeDrawer === 'run') {
      return (
        <>
          <Group pr='sm' pl='lg' position='apart'>
            <Text component='h2' size='xl'>
              {toTitleCase(template?.label)}
            </Text>
            <Group>
              <Button
                rightIcon={<FontAwesomeIcon icon={faSearch} />}
                onClick={preview}
                disabled
              >
                Show Preview
              </Button>
              <ActionButton
                rightIcon={<FontAwesomeIcon icon={faPlayCircle} />}
                onClick={run}
                loading={runTaskLoading}
              >
                Run
              </ActionButton>
            </Group>
          </Group>
          <Box px='sm' mb='sm'>
            <InputsByJSONSchema
              renderContainer={(children) => (
                <OverviewSectionContainer title='Parameters' withBorder>
                  {children}
                </OverviewSectionContainer>
              )}
              ignoreKeys={['listingId', 'dimensions', input.field]}
              schema={inputJSONSchema}
              methods={methods}
            />
          </Box>
          {input.schema === 'finalytic.reservation' && (
            <Reservations
              tableRef={tableRef}
              filterState={filterState}
              automationId={automationId!}
            />
          )}
          {input.schema === 'finalytic.payment' && (
            <Payments tableRef={tableRef} filterState={filterState} />
          )}
          {input.schema === 'finalytic.listing' && (
            <Listings
              tableRef={tableRef}
              filterState={filterState}
              automationId={automationId}
            />
          )}
          {input.schema === 'finalytic.ownerStatement' && (
            <OwnerStatements tableRef={tableRef} filterState={filterState} />
          )}
          {input.namespace !== 'finalytic' && input.namespace !== '' && (
            <Sources
              type={input.type}
              tableRef={tableRef}
              filterState={filterState}
              connectionId={connectionId}
            />
          )}
        </>
      );
    } else if (activeDrawer === 'result') {
      return (
        <>
          <Group pr='sm' pl='lg'>
            <IconButton onClick={navigateBack as any}>
              <FontAwesomeIcon color='black' icon={faChevronLeft} />
            </IconButton>
            <Text component='h2' size='xl'>
              {toTitleCase(template?.label)} Results
            </Text>
          </Group>
          <>
            <RunResults taskId={resultId} />
          </>
        </>
      );
    }
    return null;
  })();
  return (
    <>
      <Drawer
        opened={!!activeDrawer}
        zIndex={101}
        onClose={close}
        size={previewMaxWidth}
        containerSx={{
          marginRight: 20,
          borderRadius: 20,
          padding: 0,
        }}
      >
        {children}
      </Drawer>
      <RunExistingChangesModal />
    </>
  );
};

type FilterStateProp<T = any> = {
  current: T;
  set: (state: T) => void;
  reset: () => void;
};

const RunResults = ({ taskId }: { taskId: string | undefined | null }) => {
  const [activeTab, setActiveTab] = useQueryParam(
    resultViewParamKey,
    StringParam,
    { updateType: 'replaceIn' }
  );

  const [search, setSearch] = useState('');
  const whereSearch = useMemo<gqlV2.action_bool_exp | object>(() => {
    const trimmed = search.trim();

    return trimmed
      ? {
          _or: [
            { message: { _ilike: `%${trimmed}%` } },
            { title: { _ilike: `%${trimmed}%` } },
          ],
        }
      : {};
  }, [search]);

  return (
    <Tabs
      onTabChange={(tab) => setActiveTab(tab)}
      defaultValue={activeTab || 'result'}
      styles={{
        root: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
        panel: { height: '100%', flex: 1 },
      }}
    >
      <Tabs.List>
        <Tabs.Tab value='result'>Jobs</Tabs.Tab>
        <Tabs.Tab value='succeeded'>Actions</Tabs.Tab>
        <Tabs.Tab value='issues'>Errors</Tabs.Tab>

        <Group sx={{ marginLeft: 'auto', marginBottom: 4, marginRight: 4 }}>
          <RerunButtons taskId={taskId} />

          <DelayedInputSearch
            searchInput={search}
            setSearchInput={setSearch}
            placeholder='Search...'
          />
        </Group>
      </Tabs.List>

      <Tabs.Panel value='result'>
        <Box sx={{ height: '100%' }}>
          <JobTable
            where={{
              workflowId: { _eq: taskId },
            }}
          />
        </Box>
      </Tabs.Panel>

      <Tabs.Panel value='succeeded'>
        <Box sx={{ height: '100%' }}>
          <ActionTable
            where={{
              job: {
                plan: {
                  workflowId: { _eq: taskId },
                },
              },
              status: { _neq: 'failed' },
              ...whereSearch,
            }}
          />
        </Box>
      </Tabs.Panel>

      <Tabs.Panel value='issues'>
        <Box sx={{ height: '100%' }}>
          <ActionTable
            where={{
              job: {
                plan: {
                  workflowId: { _eq: taskId },
                },
              },
              status: { _eq: 'failed' },
              ...whereSearch,
            }}
          />
        </Box>
      </Tabs.Panel>
    </Tabs>
  );
};

const RerunButtons = ({ taskId }: { taskId: Maybe<string> }) => {
  const { retryTask } = useRetryTask();

  const { data } = useV2TransactionSubscription(
    (q, { taskId }) => {
      return (
        q
          .jobPlans({
            where: { workflowId: { _eq: taskId } },
            order_by: [
              {
                createdAt: 'asc',
              },
            ],
          })
          .map((plan) => {
            return {
              id: plan.id,
              automationId: plan.automationId,
              params: plan.params(),
              status: plan.status,
              jobs: plan.jobs().map((item) => ({
                id: item.id,
                status: item.status || '',
                title: item.params()?.title || '',
                message: item.title || 'Running',
              })),
            };
          })?.[0] || {}
      );
    },
    {
      taskId,
    }
  );

  const disabled =
    !data?.automationId ||
    !taskId ||
    data?.status?.toLowerCase() !== 'completed';

  return (
    <>
      <Button
        disabled={disabled}
        leftIcon={<FontAwesomeIcon icon={faRefresh} />}
        onClick={() =>
          retryTask({
            automationId: data?.automationId,
            retryPlanId: undefined,
            params: data?.params,
          })
        }
      >
        Rerun
      </Button>
      <ActionButton
        leftIcon={<FontAwesomeIcon icon={faBolt} />}
        disabled={disabled}
        onClick={() =>
          retryTask({
            automationId: data?.automationId,
            retryPlanId: data?.id,
            params: data?.params,
          })
        }
      >
        Retry Fails
      </ActionButton>
    </>
  );
};

const Reservations = ({
  tableRef,
  filterState,
  automationId,
}: {
  tableRef: TableRef;
  filterState: FilterStateProp;
  automationId: string;
}) => {
  const { tableLimits } = useBilling();

  const where = useWhereReservations({
    ...filterState.current,
    onlyEnabledListingByAutomationId: automationId,
  });
  const newWhere = useMemo(() => {
    return {
      ...where,
      connection: {
        app: {
          category: {
            _eq: 'propertyManagementSystem',
          },
        },
      },
    };
  }, [where]);

  return (
    <>
      <Group px='sm' position='apart'>
        <ReservationFilter
          hide={['connection', 'status']}
          filterState={filterState.current}
          setFilterState={filterState.set}
        />
        <Box sx={{ flexGrow: 1 }} />
        <ReservationViewFilter gridRef={tableRef} />
        <DelayedInputSearch
          searchInput={filterState.current.search}
          setSearchInput={(search) => filterState.set({ search })}
          placeholder='Guest, Reference...'
        />
      </Group>
      <ReservationTable
        limit={tableLimits.reservations}
        ref={tableRef}
        where={newWhere}
        multiSelect
        rowSelectable
        isTopRounded={false}
        onSelectAllPages={(selected) =>
          filterState.set({ [filterAllPagesSelectedKey]: selected })
        }
      />
    </>
  );
};

const Payments = ({
  tableRef,
  filterState,
}: {
  tableRef: TableRef;
  filterState: FilterStateProp;
}) => {
  const { tableLimits } = useBilling();

  const where = useWherePayments(filterState.current);

  return (
    <>
      <Group px='sm' position='apart'>
        <PaymentFilter
          filterState={filterState.current}
          setFilterState={filterState.set}
          resetFilterState={filterState.reset}
        />
        <DelayedInputSearch
          searchInput={filterState.current.search}
          setSearchInput={(search) => filterState.set({ searchInput: search })}
          placeholder='Guest, Reference...'
        />
      </Group>
      <PaymentTable
        limit={tableLimits.payments}
        ref={tableRef}
        where={where}
        multiSelect
        rowSelectable
        isTopRounded={false}
        onSelectAllPages={(selected) =>
          filterState.set({ [filterAllPagesSelectedKey]: selected })
        }
      />
    </>
  );
};

const Listings = ({
  tableRef,
  filterState,
  automationId,
}: {
  tableRef: TableRef;
  filterState: FilterStateProp;
  automationId: string | undefined | null;
}) => {
  return (
    <>
      <Box px='sm' mb='sm'>
        <DelayedInputSearch
          placeholder='Owner, Unit Name, Address...'
          searchInput={filterState.current.search}
          setSearchInput={(search) => filterState.set({ search })}
          reset={filterState.reset}
        />
      </Box>
      <ListingsTable
        ref={tableRef}
        filterState={{
          ...filterState.current,
          disabledByAutomationId: automationId,
        }}
        isTopRounded={false}
        multiSelect
        rowSelectable
        onSelectAllPages={(selected) =>
          filterState.set({ [filterAllPagesSelectedKey]: selected })
        }
      />
    </>
  );
};

const OwnerStatements = ({
  tableRef,
  filterState,
}: {
  tableRef: TableRef;
  filterState: FilterStateProp;
}) => {
  const { ownerStatements, refetch, loading } = useStatementsList({
    billingLimit: undefined,
    filter: filterState.current,
  });

  return (
    <>
      <Group px='sm' mb='sm'>
        <StatementListFilter
          filter={filterState.current}
          setFilter={filterState.set}
          statementsLoading={loading}
        />
        <DelayedInputSearch
          placeholder='Unit Name, Address'
          searchInput={filterState.current.search}
          setSearchInput={(search) => filterState.set({ search })}
          reset={filterState.reset}
          sx={{ flex: 1 }}
        />
      </Group>
      <StatementListTable
        ref={tableRef}
        rowData={ownerStatements}
        refetch={refetch}
        isTopRounded={false}
        selectable
      />
    </>
  );
};

const Sources = ({
  type,
  filterState,
  tableRef,
  connectionId,
}: {
  type: string;
  connectionId: string;
  tableRef: TableRef;
  filterState: FilterStateProp;
}) => {
  const onSelectAllPages = (selected: boolean) =>
    filterState.set({ [filterAllPagesSelectedKey]: selected });

  const { dataSource, refetch, paginationSize } = useSourcesDatasource({
    ...filterState.current,
    type,
    connectionId,
  });

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'remoteId',
        width: 130,
        minWidth: 130,
        headerName: 'ID',
        checkboxSelection: true,
        headerComponent: SRMHeaderSelectCell,
        headerComponentParams: { onSelectAllPages },
      },
      { field: 'description', flex: 1 },
    ],
    [onSelectAllPages]
  );

  return (
    <>
      <Box px='sm' mb='sm'>
        <DelayedInputSearch
          placeholder='Unit Name, Address'
          searchInput={filterState.current.search}
          setSearchInput={(search) => filterState.set({ search })}
          reset={filterState.reset}
        />
      </Box>
      <Table
        ref={tableRef}
        columnDefs={columnDefs}
        serverSideDatasource={dataSource}
        serverSideInfiniteScroll
        rowModelType='serverSide'
        cacheBlockSize={paginationSize}
        paginationPageSize={paginationSize}
        isTopRounded={false}
        noRowsOverlayComponent={() => 'No Sources Available'}
        suppressRowClickSelection
        defaultColDef={{
          sortable: false,
          resizable: false,
          suppressMovable: true,
          suppressMenu: true,
        }}
        rowSelection='multiple'
        context={{
          refetch,
        }}
      />
    </>
  );
};
