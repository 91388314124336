import { TableSettings } from '@finalytic/ui';
import { ComponentProps } from 'react';

export const ReservationViewFilter = ({
  gridRef,
}: {
  gridRef: ComponentProps<typeof TableSettings>['gridRef'];
}) => {
  return (
    <TableSettings
      table='reservations'
      gridRef={gridRef}
      defaultSortBy='checkIn'
      sortOptions={[
        {
          label: 'Connection',
          value: 'connection.name',
          sort: 'asc',
        },
        { label: 'Check In', value: 'checkIn', sort: 'desc' },
        { label: 'Check Out', value: 'checkOut', sort: 'desc' },
        { label: 'Booked At', value: 'bookedAt', sort: 'desc' },
        { label: 'Guest Name', value: 'guestName', sort: 'asc' },
        // { label: 'Status', value: 'status', sort: 'asc' },
      ]}
      columnOptions={[
        { label: 'Connection', value: 'connection.name' },
        { label: 'Automations', value: 'results' },
        { label: 'Check In/Out', value: 'checkIn' },
        { label: 'Guest Name', value: 'guestName' },
        { label: 'Status', value: 'status' },
        { label: 'Paid', value: 'paid', defaultHidden: true },
        { label: 'Total', value: 'total' },
      ]}
    />
  );
};
