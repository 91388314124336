import { replaceStatementFormulaFields } from '../../expressions';
import {
  NetRevenueColumn,
  NetRevenueColumnType,
  NetRevenueRow,
  NetRevenueTotals,
  StatementLine,
  StatementSummaryUnion,
} from '../_types';
import { ensure, expression, formatCurrency, sum } from '@finalytic/utils';

export const getNetRevenueTotalRow = ({
  groupedBy,
  columns,
  rows,
  lines: netRevenueLines,
}: {
  groupedBy: StatementSummaryUnion;
  rows: NetRevenueRow[];
  columns: NetRevenueColumn[];
  lines: StatementLine[];
}): NetRevenueTotals => {
  // return exactly the same object from rows[number] but with totals
  const totals: NetRevenueRow = {
    id: 'total',
    group: undefined,
    name: 'Total:',
    statementId: undefined,
    columnValues: {},
  };

  if (rows.length < 1) return null;

  const currency = netRevenueLines[0]?.currency || 'USD';

  // Run through all rows and set totals
  Object.keys(rows[0].columnValues).forEach((key: string) => {
    const column = columns.find((i) => i.id === key);

    if (!column) return;

    if (
      // total columns
      ensure<NetRevenueColumnType[]>([
        'sumAccounts',
        'sumColumns',
        'subtractColumns',
      ]).includes(column.type)
    ) {
      const value = sum(rows.map((row) => row.columnValues[key]?.value || 0));

      totals.columnValues[key] = {
        columnId: column.id,
        columnType: column.type,
        formattedValue: formatCurrency(value, currency),
        value,
      };
    } else if (column.type === 'metadata') {
      const value = sum(rows.map((row) => row.columnValues[key]?.value || 0));

      totals.columnValues[key] = {
        columnId: column.id,
        columnType: column.type,
        formattedValue: value,
        value,
      };
    } else if (column.type === 'field') {
      // total nights/total
      if (['reservation.nights', 'total'].includes(column.value)) {
        const value = sum(rows.map((row) => row.columnValues[key]?.value || 0));
        totals.columnValues[key] = {
          columnId: column.id,
          columnType: column.type,
          formattedValue: formatCurrency(value, currency),
          value,
        };
      } else if (column.value === 'reservation.checkIn') {
        const value = sum(netRevenueLines, (x) => x.reservation?.nights || 0);
        totals.columnValues[key] = {
          columnId: column.id,
          columnType: column.type,
          formattedValue: `${value} night${value === 1 ? '' : 's'}`,
          value,
        };
      } else {
        const value = ' ';
        totals.columnValues[key] = {
          columnId: column.id,
          columnType: column.type,
          formattedValue: value,
          value,
        };
      }
    } else if (column.type === 'formula') {
      const columnExpression = column.value;

      let value: number | string | undefined;

      try {
        // Replace fields in expression
        const res = replaceStatementFormulaFields(
          columnExpression,
          columns,
          netRevenueLines
        );

        // convert to tree
        const tree = expression.toTree(res);

        // and get executed result
        const executed = expression.execute(tree);

        if (executed === Infinity) {
          value = '-';
        } else {
          value = executed || 0;
        }
      } catch (error: any) {
        console.log(error);
      }

      totals.columnValues[key] = {
        columnId: column.id,
        columnType: column.type,
        formattedValue:
          typeof value === 'number'
            ? formatCurrency(value, currency)
            : value || ' ',
        value: typeof value !== 'undefined' ? value : ' ',
      };
    } else {
      const value = ' ';
      totals.columnValues[key] = {
        columnId: column.id,
        columnType: column.type,
        formattedValue: value,
        value,
      };
    }
  });

  return totals;
};
