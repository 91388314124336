import { ConfirmModalResultIcon } from '../_components';
import { ActionButton, TransparentButton, useColors } from '@finalytic/ui';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Text } from '@mantine/core';

type ErrorScreenProps = {
  resultMessage: string;
  onClose: () => void;
  onSubmit: () => void;
};

export const ErrorScreen = ({
  resultMessage,
  onClose,
  onSubmit,
}: ErrorScreenProps) => {
  const { gray } = useColors();

  return (
    <>
      <Stack spacing={0} align='center' justify='center' sx={{ flex: 1 }}>
        <ConfirmModalResultIcon color='#FFEEEE'>
          <FontAwesomeIcon icon={faExclamation} color='#FF5656' size='lg' />
        </ConfirmModalResultIcon>
        <Text weight={500} mb={10} sx={{ textAlign: 'center' }}>
          {resultMessage}
        </Text>
      </Stack>

      <ActionButton onClick={onSubmit} fullWidth mb={10} sx={{ height: 40 }}>
        Retry
      </ActionButton>
      <TransparentButton onClick={onClose} fullWidth sx={{ color: gray.dark }}>
        Cancel
      </TransparentButton>
    </>
  );
};
