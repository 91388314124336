import { faCheck, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showNotification, updateNotification } from '@mantine/notifications';

export const showLoadingNotification = ({ id }: { id: string }) =>
  showNotification({
    id,
    loading: true,
    title: 'Loading...',
    message: 'We will update you shortly.',
    autoClose: false,
    radius: 10,
  });

export const showErrorNotification: typeof showNotification = ({
  title,
  message,
  ...props
}) =>
  showNotification({
    title,
    message,
    color: 'red',
    icon: <FontAwesomeIcon icon={faX} size='sm' />,
    radius: 10,
    ...props,
  });

export const updateErrorNotification: typeof updateNotification = ({
  title,
  message,
  ...props
}) =>
  updateNotification({
    title,
    message,
    color: 'red',
    icon: <FontAwesomeIcon icon={faX} size='sm' />,
    radius: 10,
    ...props,
  });

export const showSuccessNotification: typeof showNotification = ({
  title,
  message,
  ...props
}) =>
  showNotification({
    title,
    message,
    color: 'teal',
    icon: <FontAwesomeIcon icon={faCheck} />,
    radius: 10,
    ...props,
  });

export const updateSuccessNotification: typeof updateNotification = ({
  title,
  message,
  ...props
}) =>
  updateNotification({
    title,
    message,
    color: 'teal',
    icon: <FontAwesomeIcon icon={faCheck} />,
    radius: 10,
    ...props,
  });
