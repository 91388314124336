import { gqlV2, useTeam, useV2TransactionQuery } from '../../../hooks';
import { ensure } from '@finalytic/utils';

export function useSingleSettingByTargetQuery({
  automationId,
  loadDataInCell,
  settingKey,
  targetId,
}: {
  loadDataInCell: boolean;
  automationId: string | undefined;
  targetId: string | undefined;
  settingKey: string | undefined;
}) {
  const [{ automations, id: teamId }] = useTeam();
  const automation = automations.find((i) => i.automationId === automationId);

  const types = {
    leftConnectionId: automation?.leftConnectionId,
    rightConnectionId: automation?.rightConnectionId,
    leftType: automation?.mappings[settingKey || ''].left.schema,
    rightType: automation?.mappings[settingKey || ''].right.schema,
  };

  const { data: setting, isLoading: loading } = useV2TransactionQuery(
    (q, args) => {
      const res = q.setting({ where: args.whereSettings, limit: 1 });

      const setting = res?.[0];

      return setting
        ? {
            settingId: setting?.id,
            value: setting?.value,
          }
        : null;
    },
    {
      skip: !loadDataInCell || Object.values(types).some((i) => !i),
      variables: {
        whereSettings: ensure<gqlV2.setting_bool_exp>({
          tenant_id: { _eq: teamId },
          key: { _eq: settingKey },
          parentSettingId: { _is_null: true },

          // automationId: { _eq: automationId },
          _or: [
            // JUDGEMENT FREE ZONE!
            {
              leftConnectionId: { _eq: types.rightConnectionId },
              rightConnectionId: { _eq: types.leftConnectionId },
              leftType: { _eq: types.leftType },
              rightType: { _eq: types.rightType },
              target: { _eq: targetId },
            },
            {
              rightConnectionId: { _eq: types.rightConnectionId },
              leftConnectionId: { _eq: types.leftConnectionId },
              rightType: { _eq: types.leftType },
              leftType: { _eq: types.rightType },
              value: { _eq: targetId },
            },
            //
            {
              leftConnectionId: { _eq: types.leftConnectionId },
              rightConnectionId: { _eq: types.rightConnectionId },
              leftType: { _eq: types.leftType },
              rightType: { _eq: types.rightType },
              target: { _eq: targetId },
            },
            {
              rightConnectionId: { _eq: types.leftConnectionId },
              leftConnectionId: { _eq: types.rightConnectionId },
              rightType: { _eq: types.leftType },
              leftType: { _eq: types.rightType },
              value: { _eq: targetId },
            },
          ],
        }),
      },
      queryKey: 'settings',
    }
  );

  return {
    setting,
    loading,
  };
}
