import { useSignInForm } from '../hooks/useSignInForm';
import { Button } from '@finalytic/ui';
import { Group } from '@mantine/core';
import { PropsWithChildren } from 'react';

export const SocialAuthButtons = ({
  signInWithSocials,
}: {
  signInWithSocials: ReturnType<typeof useSignInForm>['signInWithSocials'];
}) => {
  const signInWithGoogle = () => signInWithSocials('oauth_google');
  const signInWithLinkedIn = () => signInWithSocials('oauth_linkedin');

  return (
    <Group
      sx={{
        button: {
          flex: 1,
        },
      }}
    >
      {/* <SocialButton onClick={signInWithGoogle} icon='google'>
        Sign up with Google
      </SocialButton> */}
      <SocialButton onClick={signInWithLinkedIn} icon='linkedin'>
        Sign up with LinkedIn
      </SocialButton>
    </Group>
  );
};

const SocialButton = ({
  onClick,
  children,
  icon,
}: PropsWithChildren<{ onClick: () => void; icon: keyof typeof icons }>) => {
  return (
    <Button
      onClick={onClick}
      variant='outline'
      color='white'
      styles={{
        label: { fontWeight: 400, color: 'black' },
      }}
      leftIcon={icons[icon]}
    >
      {children}
    </Button>
  );
};

const icons = {
  google: (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_549_1049)'>
        <path
          d='M15.9998 8.17735C15.9998 7.52146 15.9454 7.04283 15.8275 6.54648H8.16309V9.50685H12.6619C12.5712 10.2425 12.0815 11.3505 10.993 12.095L10.9777 12.1941L13.4011 14.0286L13.569 14.045C15.1109 12.6534 15.9998 10.6059 15.9998 8.17735Z'
          fill='#4285F4'
        />
        <path
          d='M8.16363 15.9771C10.3677 15.9771 12.218 15.268 13.5695 14.0449L10.9935 12.0949C10.3042 12.5647 9.37902 12.8926 8.16363 12.8926C6.00492 12.8926 4.17273 11.5011 3.51961 9.5777L3.42387 9.58564L0.904047 11.4913L0.871094 11.5808C2.21348 14.1867 4.97086 15.9771 8.16363 15.9771Z'
          fill='#34A853'
        />
        <path
          d='M3.51924 9.5777C3.34691 9.08136 3.24717 8.54951 3.24717 8C3.24717 7.45043 3.34691 6.91864 3.51017 6.42229L3.50561 6.31658L0.954205 4.38031L0.870727 4.41911C0.317464 5.50047 0 6.71479 0 8C0 9.2852 0.317464 10.4995 0.870727 11.5808L3.51924 9.5777Z'
          fill='#FBBC05'
        />
        <path
          d='M8.16363 3.10733C9.69649 3.10733 10.7305 3.75436 11.3201 4.29507L13.6239 2.09692C12.209 0.811712 10.3677 0.0228577 8.16363 0.0228577C4.97086 0.0228577 2.21348 1.81327 0.871094 4.41912L3.51054 6.42229C4.17273 4.49892 6.00492 3.10733 8.16363 3.10733Z'
          fill='#EB4335'
        />
      </g>
      <defs>
        <clipPath id='clip0_549_1049'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ),
  linkedin: (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.6334 13.6155H11.2821V9.90757C11.2821 9.02338 11.2664 7.88515 10.0592 7.88515C8.83459 7.88515 8.64721 8.84851 8.64721 9.84319V13.6153H6.29592V5.99023H8.55316V7.03228H8.58476C8.81066 6.64334 9.13708 6.32338 9.52927 6.10648C9.92146 5.88958 10.3647 5.78386 10.8119 5.80057C13.195 5.80057 13.6344 7.37905 13.6344 9.43254L13.6334 13.6155ZM3.6429 4.94794C3.37304 4.948 3.1092 4.86746 2.88479 4.71652C2.66037 4.56558 2.48546 4.35102 2.38214 4.09997C2.27882 3.84892 2.25175 3.57266 2.30435 3.30612C2.35695 3.03958 2.48686 2.79472 2.67766 2.60254C2.86845 2.41034 3.11155 2.27944 3.37622 2.22637C3.6409 2.1733 3.91525 2.20047 4.16459 2.30441C4.41394 2.40837 4.62707 2.58443 4.77704 2.81037C4.92701 3.03629 5.00709 3.30193 5.00714 3.57369C5.00717 3.75413 4.9719 3.9328 4.90336 4.09951C4.83481 4.26624 4.73435 4.41772 4.60767 4.54533C4.48099 4.67295 4.33058 4.77418 4.16504 4.84326C3.9995 4.91234 3.82209 4.94791 3.6429 4.94794ZM4.81854 13.6155H2.46481V5.99023H4.81854V13.6155ZM14.8056 0.00114973H1.28329C0.976376 -0.00233804 0.680623 0.117018 0.461052 0.332992C0.24148 0.548965 0.11605 0.843884 0.112305 1.15294V14.8263C0.115922 15.1355 0.241277 15.4306 0.460842 15.6468C0.680406 15.863 0.976224 15.9826 1.28329 15.9792H14.8056C15.1133 15.9832 15.41 15.8639 15.6304 15.6477C15.8508 15.4316 15.977 15.1361 15.9813 14.8263V1.15196C15.9769 0.842285 15.8505 0.547029 15.6302 0.331062C15.4098 0.115094 15.1132 -0.00392143 14.8056 0.000162785'
        fill='#0A66C2'
      />
    </svg>
  ),
};
