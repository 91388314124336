import { StatementStatusType } from '..';
import { ExtendedCustomColors } from './theme';
import { useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';

export function useStatementStatusColor(status: StatementStatusType) {
  const theme = useMantineTheme();

  const colors = useMemo<[string[], ExtendedCustomColors]>(() => {
    const themeColor: { [key in StatementStatusType]: string } = {
      draft: 'yellow',
      inReview: 'purple',
      posted: 'green',
      published: 'green',
      void: 'gray',
    };

    return [theme.colors[themeColor[status]], themeColor[status]];
  }, [status]);

  return colors;
}
