import { Banner, BannerProps } from './Banner';
import { utc } from '@finalytic/utils';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Anchor, Group, Text } from '@mantine/core';

type Props = { date?: Date; onClick?: () => void };

export const TeamDeletionBanner = ({
  date,
  onClick,
  ...props
}: BannerProps & Props) => {
  return (
    <Banner themeColor='orange' px='lg' py='xs' {...props}>
      <Group spacing={5}>
        <FontAwesomeIcon icon={faTrashAlt} />
        <Text size='xs' component='p' m={0} ml='xs'>
          This team is scheduled for{' '}
          <Text weight={500} component='span'>
            deletion
          </Text>
          {date && ` on ${utc(date).format('DD MMM YYYY')}`}.{' '}
          {onClick && (
            <Anchor ml={5} inherit color='dark' onClick={onClick}>
              Cancel
            </Anchor>
          )}
        </Text>
      </Group>
    </Banner>
  );
};
