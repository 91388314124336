import { useTeam, useV2TransactionSubscription } from '../../hooks';
import { NavbarIssueBadge } from '@finalytic/ui';

export const LineMasterIssueBadge = () => {
  const [{ name: partnerName }] = useTeam();

  const { data: amount } = useV2TransactionSubscription(
    (q, args) => {
      const count = q
        .paymentLineClassifications()
        .map((i) => ({
          id: i.name,
          settingsCount: i
            .settings({
              where: {
                partner: { _eq: args.partnerName },
                key: { _eq: 'accountingType' },
              },
            })
            .filter((i) => !!i.value).length,
        }))
        .filter((i) => i.settingsCount < 1).length;

      return count;
    },
    { partnerName: partnerName.toLowerCase() }
  );

  return (
    <NavbarIssueBadge
      amount={amount || 0}
      tooltipText={(text, amount) =>
        `${text} missing mapping${amount > 1 ? 's' : ''}`
      }
    />
  );
};
