import {
  useNotifiedV2Mutation,
  useTeam,
  useV2TransactionQuery,
} from '../../../../../hooks';
import { ExistingSetting, FormValues } from './_types';
import { setting_insert_input } from '@finalytic/graphql';
import { useMemo } from 'react';

export function useListingSettings(listingId: string) {
  const [team] = useTeam();

  const automations = team?.automations || [];
  const automationIds = automations.map((a) => a.automationId);

  const {
    data,
    isLoading: loading,
    refetch,
  } = useV2TransactionQuery(
    (q, { listingId, automationIds }) => {
      const listingStatus = q.listing({ id: listingId }).status;

      const settings = q
        .setting({
          where: {
            automationId: { _in: automationIds },
            target: { _eq: listingId },
            key: { _eq: 'exclude' },
            leftType: {
              _in: ['finalytic.listing', 'disableOwnerStatement'],
            },
            rightType: { _eq: 'schema.boolean' },
          },
        })
        .map((setting) => ({
          settingId: setting.id,
          automationId: setting.automationId,
        }));

      return {
        settings,
        listingStatus,
      };
    },
    {
      queryKey: 'settings',
      variables: { listingId, automationIds },
      skip: !listingId || automationIds.length < 1,
    }
  );

  const automationExcludedSettings = useMemo(
    () =>
      automationIds.map((id) => {
        const existing = data?.settings.find(
          (setting) => setting.automationId === id
        );

        return {
          automationId: id,
          settingId: existing?.settingId,
          checked: !existing?.settingId,
        };
      }),
    [automationIds, data?.settings]
  );

  return {
    automationExcludedSettings,
    loading,
    refetch,
    automations,
    listingStatus: data?.listingStatus || 'enabled',
  };
}

export function useListingSettingMutation({
  listingName,
}: { listingName: string | undefined }) {
  const { mutate, loading } = useNotifiedV2Mutation(
    (
      q,
      args: {
        listingId: string;
        listingTeamId: string;
        formValue: FormValues;
        automationSettings: ExistingSetting[];
      }
    ) => {
      const status = q.updateListing({
        pk_columns: { id: args.listingId },
        _set: { status: args.formValue.status ? 'enabled' : 'disabled' },
      }).status;

      const formSettings = Object.entries(args.formValue.automations);

      const removeSettings = args.automationSettings.filter((setting) => {
        const value = args.formValue.automations[setting.automationId];
        return value && setting.settingId;
      });

      const insertAutomationIds = formSettings
        .filter(([, checked]) => !checked)
        .map(([automationId]) => automationId);

      const insertChanges = q.insert_setting({
        objects: insertAutomationIds.map<setting_insert_input>((id) => ({
          automationId: id,
          listingId: args.listingId,
          key: 'exclude',
          value: 'true',
          target: args.listingId,
          leftType: 'finalytic.listing',
          group: 'finalytic.listing',
          rightType: 'schema.boolean',
          tenant_id: args.listingTeamId,
        })),
      }).affected_rows;

      const removedChanges = q.delete_setting({
        where: {
          // id: { _in: removeSettings.map((i) => i.settingId) },
          target: { _eq: args.listingId },
          key: { _eq: 'exclude' },
          automationId: { _in: removeSettings.map((i) => i.automationId) },
          leftType: { _in: ['finalytic.listing', 'disableOwnerStatement'] },
          rightType: { _eq: 'schema.boolean' },
          tenant_id: { _eq: args.listingTeamId },
        },
      }).affected_rows;

      return {
        status,
        insertChanges,
        removedChanges,
      };
    },
    {
      successMessage: {
        title: 'Updated settings',
        message: listingName
          ? `Successfully updated ${listingName}.`
          : 'Successfully updated listing.',
      },
      invalidateQueryKeys: ['settings', 'listings'],
    }
  );

  return {
    mutate,
    loading,
  };
}
