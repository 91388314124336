import { useV2TransactionQuery } from '../../../hooks';
import { AuditDrawerTable } from './AuditDrawerTable';
import { AuditFilter } from './AuditFilter';
import { formatName } from './_utils';
import { useAuditDrawer } from './useAuditDrawer';
import {
  Button,
  Drawer,
  IconButton,
  LoadingIndicator,
  TableContainer,
} from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { faArrowLeft, faXmarkLarge } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Center,
  Collapse,
  Divider,
  Group,
  Table as MTable,
  Tabs,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactNode } from 'react';

export const AuditDrawer = () => {
  const {
    close,
    filter,
    opened,
    setFilter,
    resetFilter,
    auditId,
    setAuditId,
    showFilter,
  } = useAuditDrawer();

  return (
    <>
      <Drawer opened={!!opened} onClose={close} size={900}>
        <Group position='apart' px={5}>
          <Group spacing={4}>
            {auditId && (
              <IconButton onClick={() => setAuditId(undefined)}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </IconButton>
            )}
            <Text component='h2' size='xl'>
              History
            </Text>
          </Group>
          <Group>
            <IconButton onClick={close}>
              <FontAwesomeIcon icon={faXmarkLarge} size='sm' />
            </IconButton>
          </Group>
        </Group>

        <Tabs
          value={auditId ? 'audit-view' : 'table-view'}
          styles={{
            root: {
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            },
            panel: {
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            },
          }}
        >
          <Tabs.Panel value='audit-view'>
            <AuditDetailView auditId={auditId} />
          </Tabs.Panel>
          <Tabs.Panel value='table-view'>
            {showFilter && (
              <>
                <AuditFilter
                  filter={filter}
                  setFilter={setFilter}
                  resetFilter={resetFilter}
                />
              </>
            )}
            <TableContainer mb='sm'>
              <AuditDrawerTable setAuditId={setAuditId} />
            </TableContainer>
          </Tabs.Panel>
        </Tabs>
      </Drawer>
    </>
  );
};

const AuditDetailView = ({
  auditId,
}: {
  auditId: string | undefined | null;
}) => {
  const [opened, handlers] = useDisclosure(false);

  const { data: audit, isLoading: loading } = useV2TransactionQuery(
    (query, args) => {
      const audit = query.auditLog({ id: args.auditId });

      const user = {
        id: audit?.user?.id,
        firstName: audit?.user?.firstName,
        lastName: audit?.user?.lastName,
        email: audit?.user?.email,
      };

      return {
        auditId: audit?.id,
        user: `${formatName({
          firstName: user?.firstName,
          lastName: user?.lastName,
        })} ${user?.email ? `(${user?.email})` : ''}`,
        operation: audit?.op,
        tableName: audit?.tableName,
        objectId: audit?.objectId,
        deltaJson: audit?.deltaJson(),
      };
    },
    {
      variables: { auditId },
      queryKey: 'auditLogs',
      skip: !auditId,
    }
  );

  return (
    <>
      {audit && (
        <>
          <Title loading={loading}>Description</Title>

          <MTable>
            <thead>
              <tr>
                <th>Label</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(audit)
                .filter(([key]) => key !== 'deltaJson')
                .map(([key, value]) => (
                  <tr key={key}>
                    <td>{toTitleCase(key)}</td>
                    <td>{value}</td>
                  </tr>
                ))}
            </tbody>
          </MTable>

          <Divider my={30} />

          <Group position='apart'>
            <Title>Changes</Title>
            <Button onClick={handlers.toggle}>Toggle JSON</Button>
          </Group>
          <MTable>
            <thead>
              <tr>
                <th>Key</th>
                <th>Type</th>
                <th>Old Value</th>
                <th>New Value</th>
              </tr>
            </thead>
            <tbody>
              {audit.deltaJson?.map(
                (element: {
                  key: string;
                  type: string;
                  oldValue: any;
                  value: any;
                }) => {
                  const formatValue = (val: any) => {
                    if (element?.key.includes('cent'))
                      return ['number', 'bigint'].includes(typeof val)
                        ? val / 100
                        : val;

                    return `${
                      ['string', 'number', 'bigint'].includes(typeof val)
                        ? val
                        : val || ''
                    }`;
                  };

                  const formatKey = (key: string | undefined) => {
                    return key && toTitleCase(key.replace('cent', ''));
                  };

                  return (
                    <tr key={element?.key}>
                      <td>{formatKey(element?.key)}</td>
                      <td>{element?.type}</td>
                      <td>{formatValue(element?.oldValue)}</td>
                      <td>{formatValue(element?.value)}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </MTable>
          <Collapse in={opened}>
            <pre>{JSON.stringify(audit?.deltaJson, null, 2)}</pre>
          </Collapse>
        </>
      )}

      {!audit && (
        <Center sx={{ height: '100%' }}>
          {loading ? <LoadingIndicator /> : <Text>Missing Audit.</Text>}
        </Center>
      )}
    </>
  );
};

const Title = ({
  children,
  loading,
}: {
  children: ReactNode;
  loading?: boolean;
}) => (
  <Group position='apart'>
    <Text size='xl' weight={500} ml={4}>
      {children}
    </Text>
    {loading && <LoadingIndicator size='sm' />}
  </Group>
);
