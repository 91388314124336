import { gqlV2, useDataSourceV2 } from '../../../hooks';
import { order_by } from '@finalytic/graphql';
import { IServerSideDatasource, SortModelItem } from '@finalytic/ui-grid';
import { sum, uniqueBy } from '@finalytic/utils';
import { useMemo, useState } from 'react';

const rowsPerPage = 100;
export type ReservationType = any;

export function useReservationsDatasource({
  where,
  billingLimit,
}: {
  where: gqlV2.reservation_bool_exp;
  billingLimit: number | undefined;
}) {
  const [refetchKey, setRefetchKey] = useState(0);
  const { fetch, keys } = useDataSourceV2('reservations');

  const dataSource = useMemo<IServerSideDatasource>(() => {
    console.log('Refresh datasource', where);
    const fn = async ({
      offset,
      limit,
      orderBy,
    }: {
      offset: number;
      limit: number;
      orderBy: SortModelItem;
    }) => {
      console.log(`Refresh data ${offset}/${limit}`);
      const data = await fetch((query) => {
        const aggregate =
          query.reservationAggregate({ where }).aggregate?.count() || 0;

        const sort: order_by = `${orderBy.sort}_nulls_last`;

        console.log(orderBy, sort);
        const list = query
          .reservations({
            where,
            limit,
            offset,
            order_by: [
              orderBy.colId
                ? orderBy.colId === 'connection.name'
                  ? { connection: { name: sort } }
                  : { [orderBy.colId]: sort }
                : { checkIn: 'desc_nulls_last' },
            ],
          })
          .map((reservation) => {
            const lines = reservation.paymentLines().map((line) => ({
              id: line.id,
              type: line.type,
              type2: line.type2,
              centTotal: line.centTotal,
              skipReconcile: line.skipReconcile,
              accountingType: line
                .settingsByType2({
                  limit: 1,
                  where: { key: { _eq: 'accountingType' } },
                  order_by: [
                    {
                      created_at: 'asc',
                    },
                  ],
                })
                .map((setting) => ({
                  value: setting?.value,
                  key: setting.key,
                }))?.[0]?.value,
            }));
            const total = sum(
              lines?.filter(
                (x) => x.accountingType === 'invoice' && x.skipReconcile
              ),
              'centTotal'
            );
            const paidLines = lines?.filter(
              (x) => !x.skipReconcile && x.type === 'unclassified'
            );
            const paid = sum(paidLines, 'centTotal');
            const unpaid = total - paid;

            return {
              id: reservation.id,
              connection: {
                id: reservation.connection?.id,
                name: reservation.connection?.name,
                app: {
                  id: reservation?.connection?.app?.id,
                  iconRound: reservation?.connection?.app?.iconRound,
                },
              },
              bookedAt: reservation?.bookedAt,
              checkIn: reservation?.checkIn,
              checkOut: reservation?.checkOut,
              nights: reservation?.nights,
              confirmationCode: reservation?.confirmationCode,
              guestName: reservation?.guestName,
              status: reservation?.status,
              currency: reservation?.currency,
              listingConnection: {
                id: reservation?.listingConnection?.id,
                listing: {
                  id: reservation?.listingConnection?.listing?.id,
                  name:
                    reservation?.listingConnection?.listing?.title ||
                    reservation?.listingConnection?.listing?.name,
                },
              },
              tenant: {
                id: reservation?.tenant?.id,
                name: reservation?.tenant?.name,
                logo: reservation?.tenant?.logo,
                colorPrimary: reservation?.tenant?.colorPrimary,
              },
              paid,
              total,
              unpaid,
              actions: uniqueBy(
                reservation
                  ?.actionLinks({
                    order_by: [{ createdAt: 'asc' }],
                  })
                  .map((item) => ({
                    id: item.id,
                    status: item.action?.status,
                    type: item.action?.schema?.uniqueRef,
                    message: item.action?.title,
                  })),
                'type'
              ),
              results: reservation
                ?.results({
                  order_by: [{ type: 'asc' }, { created_at: 'desc' }],
                  distinct_on: ['type'],
                })
                .map((result) => ({
                  id: result.id,
                  status: result.status,
                  type: result.type,
                  message: result.message,
                })),
            };
          });
        return { list, aggregate };
      });
      return data;
    };
    return {
      getRows: (params) => {
        fn({
          offset: params.request.startRow || 0,
          limit: billingLimit || rowsPerPage,
          orderBy: params.request.sortModel[0],
        })
          .then((data) => {
            console.log('Refresh datasource success');
            const list = data?.list || [];
            const rowData = billingLimit
              ? [
                  ...list,
                  ...Array.from(
                    {
                      length: rowsPerPage - list.length || 0,
                    },
                    (_, index) => ({
                      ...list[list.length - 1],
                      id: list[list.length - 1]?.id + index,
                    })
                  ),
                ]
              : list;

            if (data && rowData.length === 0) {
              params.api.showNoRowsOverlay();
            } else {
              params.api.hideOverlay();
            }

            params.success({
              rowData,
              rowCount: data?.aggregate,
            });
          })
          .catch(() => params.fail());
      },
    };
  }, [JSON.stringify(where), billingLimit, refetchKey, ...keys]);
  return {
    dataSource,
    refetch: () => {
      console.log('Set refresh key datasource');
      setRefetchKey((key) => key + 1);
    },
    paginationSize: rowsPerPage,
  };
}
