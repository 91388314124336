import { TableCollapse, TableRow, TableRowProps } from '@finalytic/ui';
import { Box, Table as MantineTable } from '@mantine/core';

type Props = {
  data: TableRowProps[];
  color?: string;
};

export const StatementSummaryBox = ({ data, color }: Props) => {
  return (
    <Box sx={{ maxWidth: 400, width: '100%' }}>
      <TableCollapse
        collapsed={true}
        color={color}
        title='Summary'
        table={
          <Box
            sx={{
              paddingBottom: 3,
            }}
          >
            <MantineTable
              sx={{
                '& tr td:first-of-type': {
                  paddingLeft: '12px',
                },
                '& tr td:nth-of-type(2)': {
                  textAlign: 'right',
                  paddingRight: '20px',
                },
              }}
            >
              <tbody>
                {data.map((data) => (
                  <TableRow
                    // isEstimated={!published}
                    key={data.label}
                    {...data}
                    isThemed={!!color}
                  />
                ))}
              </tbody>
            </MantineTable>
          </Box>
        }
      />
    </Box>
  );
};
