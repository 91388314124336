import { FormattedStatement } from '@finalytic/common/src';
import { Logo } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Group,
  Stack,
  Table as MantineTable,
  Text,
} from '@mantine/core';

export const StatementHeader = ({
  header,
  maxWidth = '700px',
  isSummaryView,
}: {
  header: FormattedStatement['header'];
  maxWidth?: string | number;
  isSummaryView?: boolean;
}) => {
  return (
    <>
      <Stack
        p={15}
        justify='stretch'
        sx={{
          flexGrow: '1 !important' as any,
          position: 'relative',

          maxWidth,
          border: '1px solid #E6E8ED',
          borderRadius: 20,
          '@media (max-width: 400px)': {
            padding: 16,
          },
        }}
      >
        <Box>
          <Group mb='md'>
            <Avatar
              size={60}
              src={header?.team.logo}
              sx={{
                border: '2px solid #fff',
                borderRadius: 10,
                boxShadow:
                  '0px 14px 8px rgba(0, 0, 0, 0.01), 0px 6px 6px rgba(0, 0, 0, 0.02), 0px 2px 3px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.02)',
              }}
              styles={{
                placeholder: {
                  backgroundColor: '#fff',
                },
                image: {
                  objectFit: 'contain',
                },
              }}
            >
              {!header?.team?.logo && <Logo width={50} />}
            </Avatar>
            <Box>
              <Text size='1.7rem' weight={500}>
                {header?.team.name}
              </Text>
              <Text weight={500} color='#5C6178' mt={-3}>
                {isSummaryView
                  ? 'Owner Statement | Summary'
                  : header?.statement.subline}
              </Text>
            </Box>
          </Group>

          {/* <Text pl='xs' size='xl' weight={500}>
            {header?.listing.name}
          </Text> */}
          <Text pl='xs' size='xl' weight={500}>
            {header?.listing.address}
          </Text>
        </Box>

        <MantineTable
          sx={{
            flex: 1,
            minWidth: '330px',
            alignSelf: 'center',
            '& tr td:nth-of-type(2)': {
              textAlign: 'right',
            },
            '@media (max-width: 400px)': {
              tr: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                td: {
                  paddingLeft: '0 !important',
                  width: 'calc(100% - 24px)',
                  '&:nth-of-type(2)': {
                    textAlign: 'left',
                  },
                },
                '&:last-of-type td:nth-of-type(1)': {
                  borderBottom: '1px solid rgb(243,244,239)',
                },
              },
            },
          }}
        >
          <tbody>
            {Object.entries(header?.owner || {})
              .filter(([, value]) => !!value)
              .map(([key, value]) => {
                return (
                  <tr key={key}>
                    <Text component='td' sx={{ color: '#5C6178' }}>
                      {toTitleCase(key)}
                    </Text>
                    <Text component='td' sx={{ flexShrink: 0 }}>
                      {value}
                    </Text>
                  </tr>
                );
              })}
          </tbody>
        </MantineTable>
      </Stack>
    </>
  );
};
