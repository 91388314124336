import { gql, useQuery } from '@finalytic/data-ui';
import { StringParam, useQueryParam } from '@finalytic/ui';

export type IntegrationType = {
  id: string;
  iconRound?: string;
  type?: string;
  name?: string;
  authentication?: {
    type: string;
  };
};

function useAppByConnectionId(id?: string) {
  const [{ data, fetching }] = useQuery(
    {
      connectionById: [
        { id: gql.$`id` },
        {
          id: true,
          app: {
            id: true,
            name: true,
            iconRound: true,
            type: true,
            authentication: [{}, true],
          },
        },
      ],
    },
    { pause: !id, variables: { id } }
  );

  const app = (id && data?.connectionById?.app) || undefined;

  return { app, loading: fetching };
}

export function useRedirectedApp() {
  const [connectionId] = useQueryParam('connectionId', StringParam);

  const { app } = useAppByConnectionId(connectionId || undefined);

  return { redirectedApp: app };
}
