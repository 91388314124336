import { StatementLine, StatementSummaryUnion } from './_types';
import { groupBy, utc } from '@finalytic/utils';

export const groupStatementLinesBy = ({
  groupedBy,
  lines,
  groupedListingAndMonth,
}: {
  lines: StatementLine[];
  groupedBy: StatementSummaryUnion;
  groupedListingAndMonth: boolean;
}): {
  [key: string]: StatementLine[];
} => {
  if (groupedBy === 'groupByReservation') {
    return groupBy(lines, (x) => x.reservation?.id || 'missingReservation');
  } else if (groupedBy === 'groupByListing') {
    return groupBy(lines, (x) => {
      const listingId = x.reservation?.listing?.id || 'missingListing';
      if (!groupedListingAndMonth) {
        return listingId;
      }
      return `${listingId}.${utc(x.date).format('YYYY-MM-01')}`;
    });
  } else if (groupedBy === 'groupByMonth') {
    return groupBy(lines, (x) => utc(x.date).format('YYYY-MM-01'));
  } else if (groupedBy === 'groupByBookingChannel') {
    return groupBy(
      lines,
      (x) => x.reservation?.bookingChannel || 'missingBookingChannel'
    );
  } else {
    return {};
  }
};
