import { ArrowCell, DeleteRowCell } from '../_cells';
import { LeftSideTooltip } from '../_cells/LeftSideTooltip';
import { useEditAutomationContext } from '../_context';
import { EditorTypeUnion } from '../_hooks/useTabEditorType';
import {
  AdditionalMappingCellParams,
  MappingEditorCell,
  MappingNameCell,
} from '@finalytic/data-ui';
import { showErrorNotification } from '@finalytic/ui';
import { ColDef } from '@finalytic/ui-grid';
import { ensure } from '@finalytic/utils';

export const deleteRowColumn: ColDef = {
  cellRenderer: DeleteRowCell,
  width: 50,
  resizable: false,
  field: 'delete',
  headerName: '',
  pinned: 'right',
  onCellClicked: (params) => params.node.setDataValue('value', undefined),
};

export const arrowColumn: ColDef = {
  width: 50,
  cellRenderer: ArrowCell,
  resizable: false,
  field: 'arrow',
  headerName: '',
  suppressNavigable: true,
};

export const useDefaultValueColumn = ({
  editorType,
  settingKey,
}: {
  editorType: EditorTypeUnion;
  settingKey: string;
}): ColDef => {
  const { automation } = useEditAutomationContext();

  const cellParams = ensure<AdditionalMappingCellParams>({
    automationId: automation.id,
    loadDataInCell: false,
    settingKey: settingKey,
  });

  return {
    field: 'value',
    headerName: '',
    flex: 1,
    minWidth: 250,
    editable: true,
    cellEditor: MappingEditorCell,
    cellRenderer: MappingNameCell,
    cellRendererParams: cellParams,
    cellEditorParams: cellParams,
    singleClickEdit: true,
    suppressKeyboardEvent: (params) => {
      const key = params.event.code;

      const KEY_UP = 'ArrowUp';
      const KEY_DOWN = 'ArrowDown';
      const KEY_ENTER = 'Enter';

      if (editorType === 'select') {
        if (key === 'Backspace') {
          console.log(params);

          if (
            ((params.node.level === 0 &&
              (params.data?.childSettings || [])?.length) ||
              0) > 0
          ) {
            showErrorNotification({
              message: 'Please remove any line exceptions first.',
              icon: null,
              color: 'yellow',
            });
          } else {
            params.node.setDataValue('value', undefined);
            params.api.refreshCells({
              rowNodes: [params.node],
              columns: ['childSettings'],
              force: true,
            });
          }

          return true;
        }

        return false;
      }

      if (editorType === 'expression') {
        // return true (to suppress) if editing and user hit up/down keys
        const gridShouldDoNothing =
          params.editing &&
          (key === KEY_UP || key === KEY_DOWN || key === KEY_ENTER);
        return gridShouldDoNothing;
      }
      return false;
    },
  };
};

export const defaultNameColumn: ColDef = {
  field: 'name',
  flex: 1,
  minWidth: 300,
  maxWidth: 300,
  tooltipField: 'name',
  tooltipComponent: LeftSideTooltip,
};
