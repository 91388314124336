import { ChangeBadge } from '../../_components';
import {
  ActionButton,
  InputSearch,
  LoadingIndicator,
  Modal,
  ModalFooter,
  TransparentButton,
  useColors,
} from '@finalytic/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Group, Tabs } from '@mantine/core';

import { useEditAutomationContext } from '../../_context';
import { useAutomationChangeStore } from '../../_hooks';
import { ChildMappingRow, MappingRow } from '../../_tables';
import { ModalTabButton } from './ModalTabButton';
import { ModalTable } from './ModalTable';
import { LeftSchemaUnion, useModalData } from './_query';
import { useModalChanges } from './useModalChanges';
import { toTitleCase } from '@finalytic/utils';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';

type MappingModalProps = {
  opened: boolean;
  closeModal: () => void;
  modalTitle?: string;
  onSubmit: () => void;
  exceptions: ChildMappingRow[];
  parentRow?: MappingRow;
  settingKey: string; // settingKey
};

export const MappingModal = ({
  opened,
  closeModal,
  modalTitle = 'Manage Overwrites',
  onSubmit,
  exceptions,
  parentRow,
  settingKey,
}: MappingModalProps) => {
  const { template, automation } = useEditAutomationContext();
  const addAutomationChanges = useAutomationChangeStore((store) => store.add);

  const leftSchema = template.mappings[settingKey].left.schema;
  const rightType = template.mappings[settingKey].right.schema;

  const [searchInput, setSearchInput] = useState('');
  const [delayedSearch] = useDebouncedValue(searchInput, 300);

  const { queryData, loading } = useModalData(opened);
  const modalChanges = useModalChanges();

  const { gray } = useColors();

  // Merge QueryData with PassedIn Line Exceptions to give to Table
  const rowData = useMemo(() => {
    const d: { [group: string]: ChildMappingRow[] } = {};

    Object.entries(queryData).forEach(([leftType, leftData]) => {
      d[leftType] = leftData.map((left) => {
        const prevMapping = exceptions.find((i) => i.target === left.id);

        return {
          name: left.name,
          target: left.id,
          childSettings: [],
          leftType,
          rightType,
          parentSettingId: parentRow?.settingId,
          settingId: prevMapping?.settingId,
          value: prevMapping?.value,
          parentRowTypeId: parentRow?.target,
        };
      });
    });

    return d;
  }, [queryData, exceptions, rightType]);

  useEffect(() => {
    modalChanges.reset();
  }, [opened]);

  // On Submit return new exceptions to add to table
  const submit = () => {
    const childRows = modalChanges.changes();

    console.log(childRows);

    // Only run if there were changes
    if (Object.keys(childRows).length > 0) {
      // merge prev mappings with the updated
      const merge: { [key: string]: ChildMappingRow } = {};
      // initiate prev mappings
      exceptions.forEach((row) => {
        merge[row.target] = row;
      });

      // merge updates
      Object.values(childRows).forEach((row) => {
        merge[row.target] = row;
      });

      // Submit for for line mappings
      if (parentRow?.value) {
        // new children => only rows with values
        const newChildren = Object.values(merge).filter((i) => !!i.value);

        addAutomationChanges(
          {
            ...parentRow,
            childSettings: newChildren,
          },
          { isGlobalSetting: false, settingKey, automationId: automation.id }
        );
      } else {
        // Submit global rows
        Object.values(merge).forEach((row) => {
          addAutomationChanges(row, {
            isGlobalSetting: true,
            settingKey,
            automationId: automation.id,
          });
        });
      }

      // Add childRows to automationContext for that parent
      onSubmit();
    }

    modalChanges.reset();
    closeModal();
  };

  const baseViews: LeftSchemaUnion[] = [
    'finalytic.owner',
    'finalytic.app',
    'finalytic.connection',
    'finalytic.lineType',
    'finalytic.listing',
    'finalytic.listingConnection',
    'finalytic.bookingChannel',
  ];

  const views = useMemo<string[]>(() => {
    return baseViews.filter((i) => i !== leftSchema);
  }, [leftSchema, settingKey, baseViews]);

  const closeOnClickOutside = useMemo(() => {
    return modalChanges.numberOfChanges < 1;
  }, [modalChanges.numberOfChanges]);

  const defaultView =
    template.mappings[settingKey].left.secondary || baseViews[0];

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      size={900}
      title={modalTitle}
      closeOnEscape={closeOnClickOutside}
      closeOnClickOutside={closeOnClickOutside}
    >
      <Tabs
        radius='md'
        defaultValue={defaultView}
        styles={{
          tabsList: {
            borderBottom: 'none',
          },
        }}
      >
        <Group position='apart' align='flex-end'>
          <Tabs.List>
            {views.map((i) => (
              <ModalTabButton key={i} value={i} rowData={rowData[i]}>
                {toTitleCase(i.split('.').reverse()[0])?.replace(
                  'Listing Connection',
                  'Unit'
                )}
              </ModalTabButton>
            ))}
          </Tabs.List>
          <Group noWrap mb={10}>
            {loading && <LoadingIndicator size='xs' />}
            <ChangeBadge numberOfChanges={modalChanges.numberOfChanges} />
          </Group>
        </Group>

        <Divider mx={-20} sx={{ opacity: 0.5 }} />

        <InputSearch
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
          reset={() => setSearchInput('')}
          mt={20}
        />

        {views.map((i) => {
          return (
            <Tabs.Panel key={i} value={i}>
              <ModalTable
                rowData={rowData[i]}
                modalChanges={modalChanges}
                view={i}
                searchInput={delayedSearch}
                settingKey={settingKey}
              />
            </Tabs.Panel>
          );
        })}
      </Tabs>

      <ModalFooter>
        <Group position='right' my={5}>
          <TransparentButton color={gray.dark} onClick={closeModal}>
            Discard
          </TransparentButton>
          <ActionButton
            leftIcon={<FontAwesomeIcon icon={faCheck} />}
            onClick={submit}
          >
            Save
          </ActionButton>
        </Group>
      </ModalFooter>
    </Modal>
  );
};
