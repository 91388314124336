import { defaultInputStyles } from '../_styles';
import {
  NumberInput as MantineNumberInput,
  NumberInputProps as MantineNumberInputProps,
} from '@mantine/core';
import { ComponentPropsWithRef } from 'react';

type InputProps = { inputName: string };

export const InputNumber = ({
  inputName,
  styles,
  sx,
  ...props
}: InputProps & MantineNumberInputProps & ComponentPropsWithRef<'input'>) => {
  return (
    <MantineNumberInput
      id={inputName}
      name={inputName}
      styles={{ ...defaultInputStyles, ...styles }}
      sx={{
        height: 40,
        ...sx,
      }}
      {...props}
    />
  );
};
