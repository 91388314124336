import { useEditAutomationContext } from '../../_context';
import {
  formatOwnerName,
  useTeamId,
  useV2TransactionQuery,
} from '@finalytic/data-ui';
import { toTitleCase } from '@finalytic/utils';
import { useMemo } from 'react';

export type LeftSchemaUnion =
  | 'finalytic.owner'
  | 'finalytic.app'
  | 'finalytic.connection'
  | 'finalytic.lineType'
  | 'finalytic.listing'
  | 'finalytic.listingConnection'
  | 'finalytic.bookingChannel';

export function useModalData(opened: boolean) {
  const { automation } = useEditAutomationContext();

  const [teamId] = useTeamId();
  const { data, isLoading: loading } = useV2TransactionQuery(
    (q, args) => {
      const tenantUser = q
        .tenantUser({
          where: { tenantId: { _eq: args.teamId }, role: { _eq: 'owner' } },
          order_by: [{ user: { firstName: 'asc' } }],
        })
        .map((tenantUser) => ({
          id: tenantUser.id,
          user: {
            id: tenantUser.user.id,
            firstName: tenantUser.user.firstName,
            lastName: tenantUser.user.lastName,
            companyName: tenantUser.user.companyName,
          },
        }));

      const app = q
        .app({
          where: {
            connections: { tenantId: { _eq: args.teamId } },
            id: { _neq: 'finalytic' },
          },
          order_by: [{ name: 'asc' }],
        })
        .map((app) => ({
          id: app.id || '',
          name: app.name || '',
        }));

      const connection = q
        .connection({
          where: {
            app: {
              id: { _neq: 'finalytic' },
            },
            tenantId: { _eq: args.teamId },
          },
          order_by: [{ name: 'asc' }],
        })
        .map((con) => ({
          id: con.id || '',
          name: con.name || '',
        }));

      const listingConnections = q
        .listingConnections({
          where: { tenantId: { _eq: args.teamId } },
          order_by: [{ name: 'asc' }],
        })
        .map((con) => ({
          id: con.id || '',
          name: con.name || '',
        }));

      const paymentLines = q
        .paymentLineClassifications({
          where: { lines: { tenantId: { _eq: args.teamId } } },
          order_by: [{ name: 'asc' }],
        })
        .map((line) => ({
          id: line.name || '',
          name: line.name || '',
        }));

      const listings = q
        .listings({
          where: {
            tenantId: { _eq: args.teamId },
            _not: {
              _or: [
                {
                  settingsRight: {
                    key: { _eq: 'exclude' },
                    automationId: { _eq: args.automationId },
                    leftType: { _eq: 'finalytic.listing' },
                    rightType: { _eq: 'schema.boolean' },
                  },
                },
                { status: { _eq: 'disabled' } },
              ],
            },
          },
          order_by: [{ name: 'asc' }],
        })
        .map((listing) => ({
          id: listing.id,
          name: listing.title || listing.name || '',
        }));

      const bookingChannels = q
        .bookingChannels({
          where: {
            reservations: {
              tenantId: { _eq: args.teamId },
            },
          },
          order_by: [{ uniqueRef: 'asc_nulls_last' }],
        })
        .map((channel) => ({
          id: channel.id,
          name: toTitleCase(channel.uniqueRef) || 'Missing booking channel',
        }));

      return {
        tenantUser,
        app,
        connection,
        paymentLines,
        listings,
        listingConnections,
        bookingChannels,
      };
    },
    {
      variables: {
        teamId,
        automationId: automation.id,
      },
      skip: !opened,
    }
  );
  const queryData = useMemo<{
    [key in LeftSchemaUnion]: { id: string; name: string }[];
  }>(
    () => ({
      'finalytic.owner':
        data?.tenantUser.map((i) => {
          const firstName = i.user.firstName?.trim();
          const lastName = i.user.lastName?.trim();
          const companyName = i.user.companyName;

          return {
            id: i.id,
            name: formatOwnerName({ firstName, lastName, companyName }),
          };
        }) || [],
      'finalytic.app': data?.app || [],
      'finalytic.connection': data?.connection || [],
      'finalytic.lineType': data?.paymentLines || [],
      'finalytic.listing': data?.listings || [],
      'finalytic.listingConnection': data?.listingConnections || [],
      'finalytic.bookingChannel': data?.bookingChannels || [],
    }),
    [data]
  );

  return {
    queryData,
    loading,
  };
}
