import { ActionButton, LoadingIndicator, TransparentButton } from '../..';
import { Select, SelectItem } from '../../select';
import { Modal } from '../Modal';
import { ModalFooter } from '../ModalFooter';
import { Box, Center, Group, Text } from '@mantine/core';

import { showErrorNotification } from '../../../hooks';
import { useEffect, useState } from 'react';

type Props = {
  opened: boolean;
  close: () => void;
  loading?: boolean;

  selectOptions: SelectItem[];
  onSubmit: (newListingId: string) => Promise<void>;
  listingName?: string | null;
};

export const ListingMigrationModal = ({
  close,
  opened,
  loading,
  selectOptions = [],
  listingName,
  onSubmit,
}: Props) => {
  const [selectedListing, setSelectedListing] = useState<SelectItem>();
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (!opened) {
      setTimeout(() => {
        setSelectedListing(undefined);
      }, 300);
    }
  }, [opened]);

  const submit = async (newListingId: string | undefined) => {
    setSubmitLoading(true);
    if (!newListingId)
      return showErrorNotification({ message: 'Missing new listing id.' });
    try {
      await onSubmit(newListingId);
    } catch (error: any) {
      console.log(error?.message || error);
    }
    setSubmitLoading(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={close}
      size={550}
      title={`Migrate ${listingName || 'Listing'} Statements`}
      closeOnEscape={false}
    >
      {loading ? (
        <Center sx={{ minHeight: 430 }}>
          <LoadingIndicator />
        </Center>
      ) : (
        <Box mb='lg' mt='xs'>
          <Text component='p'>
            Migrating will transfer all owner statements from{' '}
            {listingName ? <b>{listingName}</b> : 'the old listing'} to the new
            listing.
          </Text>
          <Text component='p' mb={0} weight={500} size='xs'>
            Select a listing:
          </Text>
          <Select
            searchPlaceholder='Search listings...'
            placeholder='Select a listing to migrate to...'
            noOptionsText='No listings available to migrate to.'
            withSearch
            popoverWidth='target'
            data={selectOptions}
            loading={loading}
            withBorder
            value={selectedListing}
            sort='asc'
            removeValue={() => setSelectedListing(undefined)}
            setValue={(value) => setSelectedListing(value)}
          />
          <Text component='p' mb={0} size='xs'>
            Balances will not be updated in case the new listings already has
            owner statements.
          </Text>
        </Box>
      )}
      <ModalFooter>
        <Group position={'right'}>
          <Group>
            <TransparentButton color='gray' onClick={close}>
              Cancel
            </TransparentButton>
            <ActionButton
              onClick={() => submit(selectedListing?.value)}
              loading={submitLoading}
              disabled={loading || !selectedListing?.value}
            >
              Submit
            </ActionButton>
          </Group>
        </Group>
      </ModalFooter>
    </Modal>
  );
};
