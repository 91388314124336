import { listing_bool_exp } from '@finalytic/graphql';

export function listingIsExcluded(automationId: string): listing_bool_exp {
  return {
    _not: {
      _and: [
        {
          status: {
            _eq: 'disabled',
          },
        },
        {
          settingsRight: {
            key: {
              _eq: 'exclude',
            },
            automationId: {
              _eq: automationId,
            },
            value: {
              _eq: 'true',
            },
          },
        },
      ],
    },
  };
}
