import { Maybe } from '@finalytic/graphql';
import { InputPercentage } from '@finalytic/ui';
import { ICellRendererParams } from '@finalytic/ui-grid';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

type Split = Maybe<number>;

export const OwnershipSplitCell = forwardRef(
  (params: ICellRendererParams<any, Split>, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [newPercentageValue, setNewPercentageValue] = useState(params.value);

    useEffect(() => {
      inputRef?.current?.focus();
    }, []);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return Math.trunc(newPercentageValue || 0);
        },
      };
    });

    return (
      <InputPercentage
        inputRef={inputRef}
        value={newPercentageValue || params.value || 0}
        setValue={setNewPercentageValue}
        precision={0}
      />
    );
  }
);

OwnershipSplitCell.displayName = 'OwnershipSplitCell';
