export const ListingsIcon = ({
  color = 'none',
  size = 24,
}: { color?: string; size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill={color}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.54547 8.07948C3.94968 8.53342 3.59998 9.2395 3.59998 9.98852V19.1999C3.59998 20.5253 4.67449 21.5999 5.99998 21.5999H10.5V18C10.5 17.1715 11.1716 16.5 12 16.5C12.8284 16.5 13.5 17.1715 13.5 18V21.5999H18C19.3255 21.5999 20.4 20.5253 20.4 19.1999V9.98852C20.4 9.2395 20.0503 8.53342 19.4545 8.07948L13.4545 3.50805C12.5953 2.85344 11.4047 2.85343 10.5455 3.50805L4.54547 8.07948Z'
      fill={color}
    />
    <defs>
      <linearGradient
        id='paint0_linear_1705_41993'
        x1='3.59998'
        y1='4.04947'
        x2='17.5519'
        y2='23.1627'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={color} stopOpacity='0.6' />
        <stop offset='1' stopColor={color} stopOpacity='0.35' />
      </linearGradient>
    </defs>
  </svg>
);
