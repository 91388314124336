import { faWrench } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Center, Stack, Text } from '@mantine/core';

export const MaintenanceView = () => {
  return (
    <Center h='100vh'>
      <Stack>
        <FontAwesomeIcon icon={faWrench} size='xl' />
        <Text component='h1' size='lg'>
          Sorry, we&apos;re down for maintenance.
        </Text>
      </Stack>
    </Center>
  );
};
