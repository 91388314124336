import { Group, GroupProps } from '@mantine/core';
import { ReactNode } from 'react';

type FilterWrapperProps = {
  children: ReactNode;
};

export const FilterWrapper = ({
  children,
  ...props
}: FilterWrapperProps & GroupProps) => (
  <Group my={12} spacing={12} align='center' position='left' {...props}>
    {children}
  </Group>
);
