import {
  gql,
  gqlV2,
  useMutation,
  useTeamId,
  useV2Mutation,
} from '../../../hooks';

export function useOwnerMutations(
  refetch?: (arg: { requestPolicy: 'network-only' }) => void
) {
  const [teamId] = useTeamId();

  const [insertOwner, { isLoading: insertLoading, error: insertError }] =
    useV2Mutation((query, args: gqlV2.add_owner_request) => {
      const data = query.addOwner({ object: args });

      return {
        ok: data?.ok,
        data: data?.data,
      };
    });

  const [updateProps, updateOwner] = useMutation({
    updateUserById: [
      { pk_columns: { id: gql.$`id` }, _set: gql.$`input` },
      {
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        address: true,
        addressCountry: true,
      },
    ],
  });

  const [insertOwnershipsProps, insertOwnerships] = useMutation({
    insertListingOwners: [
      { objects: gql.$`input` },
      { returning: { id: true, listingId: true, ownerId: true } },
    ],
  });

  const [removeOwnershipsProps, removeOwnerships] = useMutation({
    deleteListingOwners: [
      { where: { listingId: { _in: gql.$`input` } } },
      { returning: { id: true, listingId: true, ownerId: true } },
    ],
  });

  return {
    loading:
      insertLoading ||
      updateProps.fetching ||
      insertOwnershipsProps.fetching ||
      removeOwnershipsProps.fetching,
    error:
      insertError ||
      updateProps.error ||
      insertOwnershipsProps.error ||
      removeOwnershipsProps.error,
    insert: (input: Omit<gqlV2.add_owner_request, 'tenantId'>) =>
      insertOwner({
        args: {
          ...input,
          tenantId: teamId,
        },
      }).then((response) => {
        if (refetch) {
          refetch({ requestPolicy: 'network-only' });
        }
        return response;
      }),
    update: (ownerId: string, input: gql.user_set_input) =>
      updateOwner({ id: ownerId, input: input }),
    updateWithOwnerShips: (
      ownerId: string,
      data: {
        input: gql.user_set_input;
        newListingIds: string[];
        removeListingIds: string[];
      }
    ) => {
      const update = updateOwner({ id: ownerId, input: data.input });

      const insertShips = insertOwnerships({
        input: data.newListingIds.map((listingId) => ({
          listingId,
          ownerId: ownerId,
        })),
      });

      const removeShips = insertOwnerships({
        input: data.removeListingIds,
      });

      return Promise.all([update, insertShips, removeShips]);
    },
    insertOwnerships: (input: gql.listing_owner_insert_input[]) =>
      insertOwnerships({ input }),

    removeOwnerships: (listingIds: string[]) =>
      removeOwnerships({ input: listingIds }),
  };
}
