import { ReactNode, createContext, useContext, useMemo } from 'react';

import { AutomationTemplateType, AutomationType } from '../../hooks';
import { FormProvider, useForm } from 'react-hook-form';

// ** Automation Setting
// 1. id
// 2. target => listing.id | owner.id etc.
// 3. group (non-nullable) => listing | owner | connection etc.
// 4. key (non-nullable) => SchemaKey
// 5. value (non-nullable) => undefined
// 6. sourceId => source.id
// 7. childSettings => [setting, setting, setting, etc.]

type context = {
  automation: AutomationType;
  template: AutomationTemplateType;
  refetchAutomation: () => void;
};

const EditAutomationContext = createContext<context | null>(null);

export const useEditAutomationContext = () =>
  useContext(EditAutomationContext) as context;

export const EditAutomationContextProvider = ({
  children,
  template,
  automation,
  refetchAutomation,
}: {
  children: ReactNode;
  template: AutomationTemplateType;
  automation: AutomationType;
  refetchAutomation: () => void;
}) => {
  const methods = useForm({
    mode: 'onChange',
  });

  const val = useMemo(
    () => ({
      template,
      automation,
      refetchAutomation,
    }),
    [template, automation, refetchAutomation]
  );

  return (
    <FormProvider {...methods}>
      <EditAutomationContext.Provider value={val}>
        {children}
      </EditAutomationContext.Provider>
    </FormProvider>
  );
};
