import { FilesTable } from '../../../views';
import { useReservation } from './_hooks';
import { ReservationFinancialsTable } from './_tables';
import { ReservationPaymentsTable } from './_tables/ReservationPaymentsTable';
import {
  Drawer,
  DrawerHeader,
  DrawerReservationDetails,
  LoadingIndicator,
  TableContainer,
  useQueryParam,
} from '@finalytic/ui';
import { StringParam } from '@finalytic/ui';
import { ScrollArea, Text } from '@mantine/core';
import { ReactNode } from 'react';

export const ReservationDetailDrawer = () => {
  const [reservationId, setQuery] = useQueryParam('reservation', StringParam);

  const closeDrawer = () => {
    setQuery(undefined);
  };

  const { reservation, paymentLines, reservationLines, loading, refetch } =
    useReservation(reservationId);

  const reservationFiles = reservation?.files || [];

  return (
    <Drawer opened={!!reservationId} onClose={closeDrawer} size={1000}>
      {/* Modal Header */}
      <DrawerHeader title='Reservation Details' onClose={closeDrawer} />

      {loading ? (
        <LoadingIndicator isFullPageLoading />
      ) : (
        <>
          {/* Reservation Details */}
          <DrawerReservationDetails
            guestName={reservation?.guestName as string | undefined}
            appIcon={
              reservation?.connection?.app.iconRound as string | undefined
            }
            appName={reservation?.connection?.app.name as string | undefined}
            status={reservation?.status as string | undefined}
            checkIn={reservation?.checkIn as string | undefined}
            checkOut={reservation?.checkOut as string | undefined}
            confirmationCode={`${[
              reservation?.confirmationCode,
              reservation?.pmsReferenceCode,
            ]
              .filter((x) => x)
              .join(', ')}`}
            bookingAt={
              (reservation?.status?.toLowerCase() === 'booked' &&
                reservation?.bookedAt) as string | undefined
            }
            listingName={
              (reservation?.listingConnection?.listing?.name ||
                reservation?.listing?.name ||
                reservation?.listingConnection?.name) as string | undefined
            }
          />

          <ScrollArea
            type={reservationFiles.length > 1 ? 'always' : undefined}
            offsetScrollbars
          >
            <TabTitle>Reservation Financials</TabTitle>
            <TableContainer h={290}>
              <ReservationFinancialsTable
                currency={reservation?.currency as string | undefined}
                rowData={reservationLines}
              />
            </TableContainer>

            <TabTitle>Payments</TabTitle>
            <TableContainer h={200}>
              <ReservationPaymentsTable rowData={paymentLines} />
            </TableContainer>

            {reservationFiles.length > 0 && (
              <>
                <TabTitle>Files</TabTitle>
                <TableContainer h={200}>
                  <FilesTable
                    rowData={reservationFiles}
                    refetch={refetch}
                    hideOwnerColumn
                    hideNoRowOverlay
                    pagination={false}
                  />
                </TableContainer>
              </>
            )}
          </ScrollArea>
        </>
      )}
    </Drawer>
  );
};

const TabTitle = ({ children }: { children: ReactNode }) => (
  <Text component='h3' size='lg' mb={5} px={5}>
    {children}
  </Text>
);
