import { AuditFilterParams, AuditTableRow } from './_types';
import { formatName } from './_utils';
import { audit_log_bool_exp } from '@finalytic/graphql';
import { IServerSideDatasource } from '@finalytic/ui-grid';
import { useMemo, useState } from 'react';

import { useV2Client } from '../../../hooks';

const rowsPerPage = 150;
export function useAuditDatasource(params: AuditFilterParams) {
  const [refetchKey, setRefetchKey] = useState(0);
  const client = useV2Client();

  const { objectId, table, teamId } = params;

  const dataSource = useMemo<IServerSideDatasource>(() => {
    console.log('Refresh datasource');
    const fn = async ({ offset, limit }: { offset: number; limit: number }) => {
      console.log(`Refresh data ${offset}/${limit}`);
      const data = await client.query(
        (query) => {
          const objId = objectId?.trim();

          const where: audit_log_bool_exp = {
            tenantId: teamId ? { _eq: teamId } : undefined,
            tableName: table ? { _eq: table } : undefined,
            _or: objId
              ? [{ objectId: { _eq: objId } }, { automationId: { _eq: objId } }]
              : undefined,
          };

          const list = query
            .auditLogs({
              where,
              limit,
              offset,
              order_by: [{ createdAt: 'desc' }],
            })
            .map<AuditTableRow>((i) => ({
              id: i.id,
              op: i.op || '',
              when: i.createdAt,
              what: i.deltaJson(),
              who: formatName({
                firstName: i.user?.firstName,
                lastName: i.user?.lastName,
              }),
              table: i.tableName || '',
            }));

          const aggregate =
            query.auditLogAggregate({ where }).aggregate?.count() || 0;

          return { list, aggregate };
        },
        {
          noCache: true,
        }
      );
      return data;
    };
    return {
      getRows: (params) => {
        fn({
          offset: params.request.startRow || 0,
          limit: rowsPerPage,
        })
          .then((data) => {
            console.log(params.request.startRow);

            console.log('Refresh datasource success');
            const rowData = data?.list || [];

            if (data && rowData.length === 0) {
              params.api.showNoRowsOverlay();
            } else {
              params.api.hideOverlay();
            }

            params.success({
              rowData,
              rowCount: data?.aggregate,
            });
          })
          .catch(() => params.fail());
      },
    };
  }, [refetchKey, table, teamId, objectId]);

  return {
    dataSource,
    refetch: () => {
      console.log('Set refresh key datasource');
      setRefetchKey((key) => key + 1);
    },
  };
}
