import { MappingRow } from '../../_tables';
import { Badge, Box, TabProps, Tabs, Tooltip } from '@mantine/core';
import { ReactNode, useMemo } from 'react';

export const ModalTabButton = ({
  children,
  rowData = [],
  ...props
}: {
  children: ReactNode;
  rowData: MappingRow[];
} & TabProps) => {
  const amount = useMemo(
    () => rowData.filter((i) => !!i.value).length,
    [rowData]
  );

  return (
    <Box component={Tabs.Tab} {...props}>
      {children}
      {amount > 0 && (
        <Tooltip label={`${amount} saved  exceptions`} withinPortal withArrow>
          <Badge ml={7} px={8}>
            {amount}
          </Badge>
        </Tooltip>
      )}
    </Box>
  );
};
