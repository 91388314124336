import { StatementSummaryBox } from '../../StatementSummaryBox';
import { StatementHeader } from '../../headers';
import { StatementLinesTable } from './_tables';
import { getFormattedStatement, getStatementQuery } from '@finalytic/common';
import { gqlV2, useTeamId, useV2TransactionQuery } from '@finalytic/data-ui';
import {
  Drawer,
  DrawerHeader,
  DrawerReservationDetails,
  LoadingIndicator,
  useQueryParam,
} from '@finalytic/ui';
import { StringParam, useQueryParams } from '@finalytic/ui';
import { ensure } from '@finalytic/utils';
import { Box, Group, Text } from '@mantine/core';
import { useMemo } from 'react';

function useOwnerStatement({
  statementId,
  reservationId,
}: {
  statementId?: string | null;
  reservationId?: string | null;
}) {
  const [teamId] = useTeamId();

  const [ownerId] = useQueryParam('statementOwnership', StringParam);

  const { data: ownerStatements, isLoading: loading } = useV2TransactionQuery(
    (q, args) => {
      return q
        .ownerStatements({
          where: args.where,
          limit: 1,
          order_by: [{ startAt: 'desc' }],
        })
        .map((statement) =>
          getStatementQuery(statement, { lines: args.whereLines })
        );
    },
    {
      skip:
        (!reservationId && !statementId) ||
        reservationId === 'missingReservation',
      variables: {
        where: statementId
          ? ensure<gqlV2.owner_statement_bool_exp>({
              id: { _eq: statementId },
              tenantId: { _eq: teamId },
            })
          : undefined,
        whereLines: reservationId
          ? reservationId === 'none'
            ? ensure<gqlV2.owner_statement_line_bool_exp>({
                reservationId: { _is_null: true },
              })
            : ensure<gqlV2.owner_statement_line_bool_exp>({
                reservationId: { _eq: reservationId },
              })
          : undefined,
        teamId,
      },
    }
  );

  const statement = ownerStatements?.[0];
  const statementTemplate = statement?.template;

  const formattedStatement = useMemo(() => {
    return getFormattedStatement({
      groupedBy: 'groupByReservation',
      ownerId: ownerId || statement?.statementOwners?.[0]?.owner.id,
      statements: statement ? [statement] : [],
      type: 'propertyManager',
    });
  }, [statement]);

  const accounts = useMemo(() => {
    const a: string[] = [];
    if (statementTemplate?.data) {
      const netRevColumns =
        statementTemplate?.data?.netRevenueSections[0]?.columns;

      // const columns = statementTemplate?.data?.otherSections[0]?.columns;
      if (netRevColumns) {
        netRevColumns.forEach((c: any) => {
          if (c.type === 'sumAccounts') a.push(...c.value);
        });
        return a;
      }
    }
    return a;
  }, [statementTemplate]);

  const lines = useMemo(() => statement?.lines, [statement?.lines, accounts]);
  const reservation = lines ? lines[0]?.reservation : undefined;

  const currency = statement?.currency || 'USD';

  return {
    loading,
    lines,
    reservation,
    currency,
    statements: ownerStatements || [],
    formattedStatement,
  };
}

export const StatementLinesDetailDrawer = () => {
  const [
    { ownerstatement: statementId, statementreservation: reservationId },
    setQuery,
  ] = useQueryParams({
    ownerstatement: StringParam,
    statementreservation: StringParam,
  });

  const closeDrawer = () =>
    setQuery({ ownerstatement: undefined, statementreservation: undefined });

  const { loading, lines, reservation, currency, formattedStatement } =
    useOwnerStatement({
      statementId,
      reservationId,
    });

  return (
    <Drawer opened={!!statementId} onClose={closeDrawer} size={1200}>
      <DrawerHeader title='Statement Lines' onClose={closeDrawer} />

      {/* Modal Header */}
      {loading ? (
        <LoadingIndicator isFullPageLoading={true} />
      ) : (
        <>
          {/* Payment Details */}

          {reservationId && (
            <DrawerReservationDetails
              guestName={reservation?.guestName || 'No reservation'}
              status={reservation?.status}
              checkIn={reservation?.checkIn}
              checkOut={reservation?.checkOut}
              confirmationCode={reservation?.confirmationCode}
            />
          )}

          {!reservationId && (
            <Group position='apart'>
              <StatementHeader header={formattedStatement?.header} />
              <StatementSummaryBox
                data={formattedStatement?.summary?.totals?.summaryLines || []}
              />
            </Group>
          )}

          {/* Payment Lines */}
          <Text component='h3' size='lg' mb={5} px={5}>
            Statement Lines{' '}
            {reservationId && (
              <>
                for reservation:{' '}
                <Text component='span' ml={8} weight={400}>
                  {reservation?.confirmationCode}
                </Text>
                {reservation?.pmsReferenceCode &&
                reservation?.pmsReferenceCode !==
                  reservation?.confirmationCode ? (
                  <Text component='span' ml={8} weight={400}>
                    / {reservation?.pmsReferenceCode}
                  </Text>
                ) : null}
              </>
            )}
          </Text>
          <StatementLinesTable rowData={lines} currency={currency} />
          {/* <PaymentWorkflowsTable rowData={workflows} /> */}
        </>
      )}
    </Drawer>
  );
};
