import { Box, Collapse, Group, Text, Transition } from '@mantine/core';
import { ReactNode, useEffect, useState } from 'react';

import { useColors, useOwnerPortalColor } from '../../styles';
import { gridBorderRadius } from '../table/_styles';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type TableCollapseProps = {
  title: string;
  table: ReactNode;
  closedByDefault?: boolean;
  color?: string;
  tableTotal?: string;
  disableCollapse?: boolean;
  collapsed?: boolean;
};

export const TableCollapse = ({
  title,
  table,
  closedByDefault = false,
  color: c,
  tableTotal,
  disableCollapse = false,
  collapsed,
}: TableCollapseProps) => {
  const { black } = useColors();
  const color = c || black.base;

  const ownerPortalColor = useOwnerPortalColor(color);

  const [opened, setOpen] = useState(
    disableCollapse ? false : closedByDefault ? false : true
  );

  useEffect(() => {
    setOpen(disableCollapse ? false : closedByDefault ? false : true);
  }, [disableCollapse, closedByDefault]);

  const toggleOpen = () =>
    typeof collapsed === 'boolean' ? null : setOpen((o) => !o);

  return (
    <Box>
      <Group
        onClick={disableCollapse ? undefined : toggleOpen}
        position='apart'
        px='sm'
        py='xs'
        sx={(theme) => {
          const radius = `${theme.radius.lg}`;

          return {
            backgroundColor: c
              ? ownerPortalColor
              : theme.colors[theme.primaryColor][8],
            color: '#fff',
            transition: 'border-radius 0.3s ease-out',
            borderRadius: opened ? `${radius} ${radius} 0 0` : radius,
            cursor: disableCollapse ? undefined : 'pointer',
          };
        }}
      >
        <Text weight='bold'>{title}</Text>
        <Group pr={disableCollapse ? 'xl' : undefined}>
          {tableTotal && (
            <Transition
              mounted={!opened}
              transition='fade'
              duration={300}
              timingFunction='ease'
            >
              {(style) => (
                <Text weight='bold' style={style}>
                  {tableTotal}
                </Text>
              )}
            </Transition>
          )}
          {!disableCollapse && typeof collapsed !== 'boolean' && (
            <Box
              sx={{
                transform: opened ? 'rotate(180deg)' : '',
                transition: 'transform 0.3s ease-out',
              }}
            >
              <FontAwesomeIcon icon={faCaretDown} />
            </Box>
          )}
        </Group>
      </Group>
      <Collapse in={typeof collapsed === 'boolean' ? collapsed : opened}>
        <Box
          sx={{
            height: '100%',
            border: '1px solid #e6e8ed',
            boxShadow: '0px 3px 3px rgba(20, 23, 25, 0.05)',
            borderRadius: `0 0 ${gridBorderRadius}  ${gridBorderRadius} `,
          }}
        >
          {table}
        </Box>
      </Collapse>
    </Box>
  );
};
