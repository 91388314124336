import { gqlV2, useTeamId, useV2Client } from '@finalytic/data-ui';
import { IServerSideDatasource } from '@finalytic/ui-grid';
import { ensure } from '@finalytic/utils';
import { useMemo, useState } from 'react';

export function useSourcesDatasource(params: {
  search: string | undefined;
  type: string | undefined;
  connectionId: string | undefined;
}) {
  const rowsPerPage = 100;

  const [refetchKey, setRefetchKey] = useState(0);
  const client = useV2Client();
  const { search, connectionId, type } = params;

  const [teamId] = useTeamId();

  const teamIds = [teamId];
  const dataSource = useMemo<IServerSideDatasource>(() => {
    const fn = async ({ offset, limit }: { offset: number; limit: number }) => {
      const data = await client.query(
        (query) => {
          const searchQuery = search
            ? ensure<gqlV2.source_bool_exp[]>([
                {
                  description: { _ilike: `%${search.trim()}%` },
                },
                {
                  remoteId: { _ilike: `%${search.trim()}%` },
                },
              ])
            : undefined;

          const where = ensure<gqlV2.source_bool_exp>({
            tenantId: { _in: teamIds },
            type: { _eq: type },
            connectionId: { _eq: connectionId },
            // description: { _is_null: false },
            _or: searchQuery,
          });
          const aggregate =
            query.sourceAggregate({ where }).aggregate?.count() || 0;

          const list = query
            .source({
              where,
              limit,
              offset,
              order_by: [{ description: 'asc' }],
            })
            .map((source) => ({
              id: source.id,
              description: source.description,
              remoteId: source.remoteId,
            }));
          return { list, aggregate };
        },
        {
          noCache: true,
        }
      );
      return data;
    };
    return {
      getRows: (params) => {
        fn({
          offset: params.request.startRow || 0,
          limit: rowsPerPage,
        })
          .then((data) => {
            console.log('Refresh datasource success');
            const rowData = data?.list || [];

            if (data && rowData.length === 0) {
              params.api.showNoRowsOverlay();
            } else {
              params.api.hideOverlay();
            }

            params.success({
              rowData,
              rowCount: data?.aggregate,
            });
          })
          .catch(() => params.fail());
      },
    };
  }, [search, connectionId, type, refetchKey, ...teamIds]);
  return {
    dataSource,
    refetch: () => {
      console.log('Set refresh key datasource');
      setRefetchKey((key) => key + 1);
    },
    paginationSize: rowsPerPage,
  };
}
