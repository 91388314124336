import { gql, useNotifiedMutation, useV2TransactionQuery } from '../../hooks';
import { formatOwnerName } from '../../utils';
import {
  Select,
  SelectItem,
  TransparentButton,
  useColors,
} from '@finalytic/ui';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';

export type AddListingToOwnerSelectOption = 'listing' | 'owner';

type QueryProps = {
  teamId: string;
  select: AddListingToOwnerSelectOption;
};

export type AddListingToOwnerButtonProps = {
  refetchTable: () => void;
  id: string;
  buttonText?: string;
  currentIds: string[];
  openAddOwnerModal?: (listingId: string) => void;
} & QueryProps;

export function useData({
  select,
  teamId,
  search,
}: QueryProps & {
  search: string;
}) {
  const limit = 50;

  const s = search?.trim();

  const {
    data,
    isLoading: loading,
    refetch,
  } = useV2TransactionQuery(
    (q, args) => {
      if (select === 'owner') {
        return q
          .tenantUser({
            where: {
              tenantId: { _eq: args.teamId },
              role: { _eq: 'owner' },
              _or: args.search
                ? [
                    { user: { firstName: { _ilike: `%${args.search}%` } } },
                    { user: { lastName: { _ilike: `%${args.search}%` } } },
                    { user: { companyName: { _ilike: `%${args.search}%` } } },
                  ]
                : undefined,
            },
            order_by: [{ user: { firstName: 'asc' } }],
            limit: args.limit,
          })
          .map((tenantUser) => ({
            id: tenantUser.userId,
            name: formatOwnerName({
              companyName: tenantUser.user.companyName,
              firstName: tenantUser.user.firstName,
              lastName: tenantUser.user.lastName,
            }),
          }));
      }

      if (select === 'listing') {
        return q
          .listings({
            where: {
              tenantId: { _eq: args.teamId },
              status: { _neq: 'disabled' },
              _or: args.search
                ? [{ name: { _ilike: `%${args.search}%` } }]
                : undefined,
            },
            order_by: [{ name: 'asc' }],
            limit: args.limit,
          })
          .map((listing) => ({
            id: listing.id,
            name: listing?.title || listing.name,
          }));
      }
    },
    {
      skip: !teamId || !select,
      queryKey: ['listings', 'tenantUsers'],
      variables: { teamId, select, limit, search: s },
    }
  );

  return {
    data: data || [],
    loading,
    refetch,
  };
}

export const AddListingToOwnerButton = ({
  refetchTable,
  select,
  teamId,
  currentIds,
  id,
  buttonText = 'Add listing',
  openAddOwnerModal,
}: AddListingToOwnerButtonProps) => {
  const { purple } = useColors();

  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const { data } = useData({ select, teamId, search });

  const { mutate } = useNotifiedMutation(
    {
      insertListingOwner: [
        { object: gql.$`input` },
        { id: true, listingId: true, ownerId: true },
      ],
    },
    { successMessage: { message: 'Successfully added ownership!' } }
  );

  const addOwnership = (value: string) => {
    if (select === 'listing') {
      // id = ownerId
      mutate({ input: { listingId: value, ownerId: id } }).then(refetchTable);
    } else if (select === 'owner') {
      // id = listingId
      mutate({ input: { listingId: id, ownerId: value } }).then(refetchTable);
    }

    setOpen(false);
  };

  const values = useMemo<SelectItem[]>(
    () =>
      data
        ?.filter((i) => !(currentIds || []).includes(i.id))
        .map((i) => ({ label: i.name || '', value: i.id })) || [],
    [data, currentIds]
  );
  return (
    <>
      <Select
        data={values}
        withSearch
        setValue={(value) => addOwnership(value.value)}
        onSearchInput={(s) => setSearch(s)}
        opened={open}
        onClose={() => setOpen(false)}
        removeValue={() => undefined}
        searchPlaceholder='Search ...'
        addOptionText={openAddOwnerModal ? () => 'Add owner' : undefined}
        onAddCustomValue={
          openAddOwnerModal ? () => openAddOwnerModal(id) : undefined
        }
        customTarget={
          <TransparentButton
            ref={(ref) => {
              if (!ref) return;
              ref.onclick = (e: any) => {
                e.stopPropagation(); // this works
                setOpen((o) => !o);
              };
            }}
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            sx={{
              height: 25,
              '& .mantine-Button-label': {
                opacity: 1,
                fontSize: 13,
              },
            }}
            color={purple.base}
          >
            {buttonText}
          </TransparentButton>
        }
      />
    </>
  );
};
