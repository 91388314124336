import { useTeamId } from './team';
import useLocalStorageState from 'use-local-storage-state';

export function useOwnerPreviewId() {
  const [activeTeamId] = useTeamId();
  const OWNER_PREVIEW_STORAGE_KEY = 'owner_preview_id';

  const defaultValue = { userId: '', teamId: '' };

  const [ownerPreview, setOwnerPreview] = useLocalStorageState(
    OWNER_PREVIEW_STORAGE_KEY,
    {
      defaultValue: { userId: '', teamId: '' },
    }
  );

  const resetOwnerPreview = () => setOwnerPreview(defaultValue);

  return {
    ownerPreview,
    setOwnerPreview: ({
      userId,
      teamId,
    }: {
      userId: string;
      teamId?: string;
    }) => setOwnerPreview({ userId, teamId: teamId || activeTeamId }),
    resetOwnerPreview,
  };
}
