import { LeftSchemaUnion } from '../_modals/mapping-modal/_query';
import { MappingRow } from '../_tables';
import {
  formatOwnerName,
  useTeamId,
  useV2TransactionQuery,
} from '@finalytic/data-ui';
import { LoadingIndicator } from '@finalytic/ui';
import { ICellRendererParams } from '@finalytic/ui-grid';
import { ensure, isUUID, toTitleCase } from '@finalytic/utils';
import { Badge, Group, Text } from '@mantine/core';

function useExceptionName(id: string, leftSchema: LeftSchemaUnion) {
  const [teamId] = useTeamId();

  const { data: name, isLoading: loading } = useV2TransactionQuery(
    (q, { id, leftSchema }) => {
      if (leftSchema === 'finalytic.owner') {
        const tenantUser = q.tenantUserById({ id });

        return (
          formatOwnerName({
            companyName: tenantUser?.user?.companyName,
            firstName: tenantUser?.user?.firstName,
            lastName: tenantUser?.user?.lastName,
          }) || 'Missing owner'
        );
      }

      if (leftSchema === 'finalytic.app') {
        const app = q.app({
          where: {
            id: { _eq: id },
          },
          limit: 1,
        });

        return app ? app[0]?.name || '' : 'Missing app';
      }

      if (leftSchema === 'finalytic.connection') {
        const connection = q.connectionById({ id });

        return connection?.name || 'Missing connection';
      }

      if (leftSchema === 'finalytic.listing') {
        const listing = q.listing({ id });

        return listing?.name || 'Missing listing';
      }

      if (leftSchema === 'finalytic.lineType') {
        const lineType = q.paymentLineClassification({ name: id });

        return lineType?.name || 'Missing line type';
      }

      if (leftSchema === 'finalytic.listingConnection') {
        const listingConnection = q.listingConnection({ id });

        return listingConnection?.name || 'Missing unit';
      }

      if (leftSchema === 'finalytic.bookingChannel') {
        const bookingChannel = q.bookingChannel({ id });

        return (
          toTitleCase(bookingChannel?.uniqueRef) || 'Missing booking channel'
        );
      }

      return 'Missing schema';
    },
    {
      skip:
        !teamId ||
        !id ||
        !leftSchema ||
        !ensure<LeftSchemaUnion[]>([
          'finalytic.app',
          'finalytic.lineType',
        ]).includes(leftSchema)
          ? !isUUID(id)
          : false,
      variables: {
        id,
        leftSchema,
      },
    }
  );

  return {
    name,
    loading,
  };
}

export const LeftSideNameCell = (params: ICellRendererParams<MappingRow>) => {
  const value = params.value || '';
  const key: LeftSchemaUnion | undefined = params.data
    ?.leftType as LeftSchemaUnion;

  const { loading, name } = useExceptionName(value, key);

  if (loading)
    return <LoadingIndicator mt={9} size={'sm'} isFullPageLoading={false} />;

  return (
    <Group sx={{ height: '100%' }} spacing={10} noWrap>
      <Text component='span' lineClamp={1} sx={{ maxWidth: 400 }}>
        {value === '*' ? 'Set Default:' : name}
      </Text>
      <Badge size='sm'>
        {toTitleCase(key?.split('.').reverse()[0])?.replace(
          'Listing Connection',
          'Unit'
        )}
      </Badge>
    </Group>
  );
};
