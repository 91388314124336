export const PaymentsIcon = ({ size = 24 }: { size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.0565 8.16244C10.3067 7.96398 10.6044 7.81954 10.9231 7.72803V10.8097C10.6086 10.7236 10.3141 10.5763 10.0565 10.3764C9.62206 10.0313 9.45447 9.62554 9.45447 9.26941C9.45447 8.91328 9.62206 8.50754 10.0565 8.16244ZM12.5769 15.7878V12.6741C12.9595 12.7678 13.309 12.9255 13.5924 13.1383C14.0632 13.4911 14.2308 13.8947 14.2308 14.231C14.2308 14.5672 14.0632 14.9708 13.5924 15.3236C13.2885 15.5447 12.9431 15.7022 12.5769 15.7867V15.7878Z'
      fill='url(#paint0_linear_1705_40076)'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.75 1C5.81269 1 1 5.81269 1 11.75C1 17.6873 5.81269 22.5 11.75 22.5C17.6873 22.5 22.5 17.6873 22.5 11.75C22.5 5.81269 17.6873 1 11.75 1ZM12.5769 5.13462C12.5769 4.9153 12.4898 4.70497 12.3347 4.54989C12.1796 4.39481 11.9693 4.30769 11.75 4.30769C11.5307 4.30769 11.3204 4.39481 11.1653 4.54989C11.0102 4.70497 10.9231 4.9153 10.9231 5.13462V6.03431C10.2316 6.15066 9.57994 6.4371 9.02667 6.86785C8.24164 7.4919 7.80062 8.35631 7.80062 9.26923C7.80062 10.1833 8.24164 11.0466 9.02777 11.6706C9.58126 12.1116 10.2428 12.384 10.9231 12.5042V15.7876C10.5568 15.7027 10.2115 15.5448 9.90762 15.3234L8.93846 14.5957C8.85159 14.5306 8.75273 14.4832 8.64753 14.4562C8.54234 14.4293 8.43287 14.4233 8.32536 14.4386C8.10825 14.4697 7.91236 14.5857 7.78077 14.7611C7.64918 14.9366 7.59268 15.1571 7.6237 15.3742C7.65471 15.5913 7.7707 15.7872 7.94615 15.9188L8.91531 16.6465C9.50297 17.0875 10.2042 17.3576 10.9231 17.4734V18.3654C10.9231 18.5847 11.0102 18.795 11.1653 18.9501C11.3204 19.1052 11.5307 19.1923 11.75 19.1923C11.9693 19.1923 12.1796 19.1052 12.3347 18.9501C12.4898 18.795 12.5769 18.5847 12.5769 18.3654V17.4723C13.303 17.3632 13.9919 17.0798 14.5847 16.6465C15.4061 16.0302 15.8846 15.1646 15.8846 14.2308C15.8846 13.2969 15.4061 12.4314 14.5847 11.8151C13.9921 11.3812 13.3032 11.0975 12.5769 10.9881V7.73005C12.8967 7.82046 13.1933 7.9649 13.4435 8.16336L13.9011 8.5272C14.0729 8.66362 14.2918 8.7262 14.5098 8.70118C14.7277 8.67616 14.9268 8.56559 15.0632 8.3938C15.1996 8.222 15.2622 8.00305 15.2372 7.78511C15.2122 7.56718 15.1016 7.36811 14.9298 7.23169L14.4722 6.86785C13.9192 6.43766 13.2679 6.15161 12.5769 6.03541V5.13462Z'
      fill='url(#paint1_linear_1705_40076)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_1705_40076'
        x1='9.45447'
        y1='8.17579'
        x2='15.7714'
        y2='13.8483'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.6' />
        <stop offset='1' stopColor='white' stopOpacity='0.35' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_1705_40076'
        x1='1'
        y1='2.19444'
        x2='16.5831'
        y2='25.8076'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.6' />
        <stop offset='1' stopColor='white' stopOpacity='0.35' />
      </linearGradient>
    </defs>
  </svg>
);
