import {
  AppLayout,
  AuditDrawer,
  ListingDetailDrawer,
  OwnerDetailDrawer,
  PaymentDetailDrawer,
  ReservationDetailDrawer,
} from '../components';
import {
  TasksProvider,
  useDashboardType,
  useMe,
  useOwnerPreviewId,
  useTeam,
  useTeamId,
  useTeamRole,
} from '../hooks';
import { SettingsNavigation } from '../navigation';
import { OwnerNavigation } from '../navigation/OwnerNavigation';
import { PmNavigation } from '../navigation/PmNavigation';
import { EmptyView } from '../views';
import { DashboardRoutes } from './dashboard';
import { RunDrawer } from '@finalytic/automations';
import { ConnectionDetailDrawer } from '@finalytic/connections';
import { OnboardingRoutes } from '@finalytic/onboarding';
import {
  StatementLinesDetailDrawer,
  StatementSummaryDetailDrawer,
} from '@finalytic/owner-statements';
import { utc } from 'dayjs';
import { useLocation } from 'react-router';

export const AppRoutes = () => {
  const user = useMe();
  const [teamId] = useTeamId();
  const { ownerPreview } = useOwnerPreviewId();
  const [dashboard] = useDashboardType();
  const [team] = useTeam();
  const location = useLocation();

  // If user hasn't loaded => show loading
  if (!user?.id || (teamId && !team?.id)) {
    return <EmptyView loading />;
  }

  // Legacy Onboarding
  // if (!teamId) {
  //   return <OnboardingRoutes />;
  // }

  // New users are created with "onboarding" status and shown only onboarding screens
  if (
    team.status === 'onboarding' &&
    utc(team.createdAt).isAfter(utc('2023-05-22 00:00:00'))
  ) {
    return <OnboardingRoutes />;
  }

  if (
    user.memberships.some((i) => i.status?.toLowerCase() === 'active') ||
    !!ownerPreview?.userId
  ) {
    const DashboardNavigation =
      dashboard === 'owner' ? OwnerNavigation : PmNavigation;

    const isSettingsView = location.pathname.startsWith('/settings');

    return (
      <TasksProvider>
        <AppLayout
          navigation={isSettingsView ? SettingsNavigation : DashboardNavigation}
          drawers={[
            ConnectionDetailDrawer,
            ListingDetailDrawer,
            OwnerDetailDrawer,
            PaymentDetailDrawer,
            ReservationDetailDrawer,
            StatementLinesDetailDrawer,
            StatementSummaryDetailDrawer,
            RunDrawer,
            AuditDrawer,
          ]}
        >
          <DashboardRoutes />
        </AppLayout>
      </TasksProvider>
    );
  }

  return <EmptyView title='This user is not active. Please reach out to us.' />;
};
