import { useColors } from '../../styles';
import { PopoverButton } from '../table-filter';
import { SortDirection, TableSettingsType } from './table-settings-types';
import { onTableSettingChange } from './table-settings-utils';
import { AgGridReact, ColDef } from '@finalytic/ui-grid';
import { sortBy as sortByUtil, toTitleCase } from '@finalytic/utils';
import { faSliders, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Anchor,
  Box,
  Center,
  Chip,
  Divider,
  Group,
  NativeSelect,
  Popover,
  Stack,
  Text,
  TextProps,
} from '@mantine/core';
import { SelectItem as MantineSelectItem } from '@mantine/core';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { ReactNode, RefObject, useEffect, useMemo } from 'react';

const localStorageKeys: Record<Table, string> = {
  reservations: 'restable',
  statements: 'stltable',
  partnerTeams: 'pttable',
};

// type Option = string | SelectItem;
type Table = 'statements' | 'reservations' | 'partnerTeams';

type Option = MantineSelectItem;

interface SortOption extends Option {
  sort?: SortDirection;
}
type ShowColumnOption = {
  value: string;
  label: string;
  defaultHidden?: boolean;
};

type Props = {
  table: Table;

  customOnSettingsChange?: (settings: TableSettingsType) => void;
  gridRef: RefObject<AgGridReact>;

  defaultSortBy?: string | null | undefined;
  defaultGroupBy?: string | null;

  sortOptions?: SortOption[];
  groupOptions?: Option[];
  columnOptions?: ShowColumnOption[];
  withUngrouped?: boolean;

  width?: number;
};

function optionsToStrings(options: Option[]) {
  return options?.map((col) => (typeof col === 'string' ? col : col.value));
}

export const TableSettings = ({
  groupOptions,
  sortOptions,
  columnOptions,
  defaultGroupBy = '-',
  defaultSortBy,
  width = 300,
  gridRef,
  withUngrouped,
  table,
}: Props) => {
  const [opened, handlers] = useDisclosure(false);

  const localStorageKey = localStorageKeys[table];

  const defaultVisibleColumns = columnOptions?.filter((c) => !c.defaultHidden);

  const defaultCols = defaultVisibleColumns?.length
    ? optionsToStrings(defaultVisibleColumns)
    : optionsToStrings(columnOptions || []);

  const defaultState: TableSettingsType = {
    groupBy: defaultGroupBy || '',
    sortDirection: 'asc',
    sortBy: defaultSortBy || '',
    shownColumns: defaultCols,
  };

  const [{ groupBy, shownColumns, sortBy, sortDirection }, setState] =
    useLocalStorage<TableSettingsType>({
      key: localStorageKey,
      defaultValue: defaultState,
    });

  const setSortBy = (sort: string) =>
    setState((prev) => ({ ...prev, sortBy: sort }));
  const setSortDirection = (sortDir: SortDirection) =>
    setState((prev) => ({ ...prev, sortDirection: sortDir }));
  const setGroupBy = (group: string) =>
    setState((prev) => ({ ...prev, groupBy: group }));

  const { border } = useColors();

  const updateTableSettings = () =>
    onTableSettingChange(
      gridRef,
      {
        groupBy,
        sortBy,
        shownColumns,
        sortDirection,
      },
      defaultCols
    );

  const resetTableSettings = () => setState(defaultState);

  const onClose = () => {
    handlers.close();
  };

  useEffect(() => {
    updateTableSettings();
  }, [sortBy, groupBy, shownColumns, gridRef?.current]);

  const gOptions = useMemo<Option[] | undefined>(
    () =>
      withUngrouped && groupOptions
        ? [...(groupOptions || []), { value: '-', label: 'No Group' }]
        : groupOptions,
    [withUngrouped, groupOptions]
  );

  if (!gOptions && !sortOptions && !columnOptions) return null;

  const isActive = useMemo(() => {
    const isSameGroupBy = groupBy === (defaultGroupBy || '');
    const isSameSort = sortBy === (defaultSortBy || '');
    const isSameColumns =
      defaultCols.length === shownColumns.length &&
      sortByUtil(defaultCols).every(
        (element, index) => element === sortByUtil(shownColumns)[index]
      );

    return !isSameGroupBy || !isSameColumns || !isSameSort;
  }, [groupBy, shownColumns, defaultGroupBy, defaultCols]);

  return (
    <Popover
      opened={opened}
      onClose={onClose}
      width={width}
      position='bottom-end'
      withArrow
      arrowOffset={20}
      shadow='md'
      radius='md'
      styles={(theme) => ({
        dropdown: {
          paddingInline: theme.spacing.xs,
        },
      })}
    >
      <Popover.Target>
        <PopoverButton
          onClick={handlers.toggle}
          title={'View'}
          isActive={isActive}
          Icon={() => <FontAwesomeIcon size='xs' icon={faSliders} />}
        />
      </Popover.Target>
      <Popover.Dropdown>
        {/* Sort By & Group By */}
        <Stack spacing='xs'>
          {sortOptions && (
            <Group position='apart'>
              <InputLabel>Sort By</InputLabel>

              <SelectInput
                options={sortOptions}
                value={sortBy}
                setValue={(sort) => {
                  setSortDirection(
                    sortOptions.find((i) => i.value === sort)?.sort || 'asc'
                  );
                  setSortBy(sort);
                }}
              />
            </Group>
          )}
          {gOptions && (
            <Group position='apart'>
              <InputLabel>Group By</InputLabel>
              <SelectInput
                options={gOptions}
                value={groupBy}
                setValue={setGroupBy}
              />
            </Group>
          )}
        </Stack>

        {/* Display Columns */}
        {columnOptions && (
          <>
            <Divider mt='md' mb='xs' color={border.gray} />
            <InputLabel mb='xs'>Columns</InputLabel>
            <Group spacing='xs' noWrap={false}>
              {columnOptions.map((column) => {
                const name = column?.label;
                const value = column?.value;

                const isActive = shownColumns.includes(value);

                const onChange = (checked: boolean) =>
                  setState((state) => {
                    const cols = state.shownColumns;
                    const newColumns = checked
                      ? [...cols, value]
                      : cols.filter((c) => c !== value);
                    return { ...state, shownColumns: newColumns };
                  });

                return (
                  <Chip
                    size='xs'
                    key={value}
                    variant='filled'
                    radius='sm'
                    checked={isActive}
                    onChange={onChange}
                  >
                    {toTitleCase(name)}
                  </Chip>
                );
              })}
            </Group>
          </>
        )}

        {/* Reset button */}
        {isActive && (
          <Center>
            <Anchor
              component='button'
              size='xs'
              mt='xs'
              onClick={resetTableSettings}
              align='center'
              sx={(theme) => ({
                ':hover': {
                  textDecoration: 'none !important',
                  borderBottom: '1px solid',
                  borderBottomColor: theme.colors[theme.primaryColor][7],
                },
              })}
            >
              <Group position='center' w='100%' spacing='xs'>
                <FontAwesomeIcon icon={faX} size='xs' />{' '}
                <Text component='span'>Reset</Text>
              </Group>
            </Anchor>
          </Center>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};

const InputLabel = ({
  children,
  ...props
}: { children: ReactNode } & TextProps) => {
  const { gray } = useColors();

  return (
    <Text
      size='xs'
      weight={500}
      component='p'
      m={0}
      color={gray.dark}
      {...props}
    >
      {children}
    </Text>
  );
};

const SelectInput = ({
  options,
  setValue,
  value,
}: {
  options: Option[];
  value: string | undefined;
  setValue: (v: string) => void;
}) => {
  return (
    <NativeSelect
      size='xs'
      radius='md'
      styles={(theme) => ({
        root: {
          minWidth: 150,
        },
        input: {
          borderColor: 'transparent !important',
          // backgroundColor: `${
          //   theme.colors[theme.primaryColor][0]
          // }40 !important`,
          backgroundColor: '#f1f3f5 !important',
        },
      })}
      data={options}
      value={value}
      onChange={(e) => setValue(e.currentTarget.value)}
    />
  );
};

export function useSavedColumnDefs(
  columnDefs: ColDef[],
  {
    memoKeys,
    table,
  }: {
    memoKeys: any[];
    table: Table;
  }
) {
  const [settings] = useLocalStorage<TableSettingsType>({
    key: localStorageKeys[table],
    defaultValue: {
      groupBy: undefined,
      shownColumns: [],
      sortBy: undefined,
      sortDirection: undefined,
    },
  });

  const shownColumns = settings?.shownColumns || [];

  return useMemo(() => {
    if (!shownColumns.length) return columnDefs;

    return columnDefs.map((col) => {
      const columnId = col.field || col.colId || '';

      const groupBy = settings?.groupBy === columnId ? true : false;
      const sortBy = settings?.sortBy;
      const sortDirections = settings?.sortDirection || 'asc';

      return {
        ...col,
        sort: columnId === sortBy ? sortDirections : null,
        rowGroup: groupBy,
        hide:
          !['info', 'menu'].includes(columnId) &&
          !shownColumns.includes(columnId),
      };
    });
  }, [...memoKeys, shownColumns]);
}
