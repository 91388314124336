// Handle magic link verification results
import { useEffect, useState } from 'react';

import { AuthContainer } from '../components';
import { useSignIn } from '@finalytic/authentication';
import {
  StringParam,
  showSuccessNotification,
  useQueryParam,
} from '@finalytic/ui';
import { Text } from '@mantine/core';
import { Navigate } from 'react-router';

type VerifyTokenViewProps = {
  isAuthenticated?: boolean;
};

export const VerifyTokenView = ({ isAuthenticated }: VerifyTokenViewProps) => {
  if (isAuthenticated) return <Authenticated />;

  return <Verify />;
};

const Authenticated = () => {
  const [token] = useQueryParam('token', StringParam);

  if (token) {
    showSuccessNotification({
      id: 'redirect',
      title: 'Successfully redirected.',
      message:
        'If you are trying to authenticate a different user, please sign out and try the link in the email again.',
      autoClose: false,
    });
  }

  return <Navigate to='/' />;
};

const Verify = () => {
  const [verificationStatus, setVerificationStatus] = useState('loading');

  const [token] = useQueryParam('token', StringParam);

  const { signIn } = useSignIn();

  useEffect(() => {
    async function verify() {
      try {
        await signIn?.create({
          strategy: 'ticket',
          ticket: token || '',
        });
        setVerificationStatus('verified');
      } catch (err) {
        // Verification has failed.
        const status = 'failed';
        setVerificationStatus(status);
      }
    }
    if (token) {
      verify();
    }
  }, [token]);

  if (!token)
    return (
      <AuthContainer bgColor='#fff' loading={false}>
        <Text>Missing token</Text>
      </AuthContainer>
    );

  if (verificationStatus === 'loading') {
    return <AuthContainer bgColor='#fff' loading={true} />;
  }

  if (verificationStatus === 'failed') {
    return (
      <AuthContainer bgColor='#fff' loading={false}>
        <Text>Token verification failed</Text>
      </AuthContainer>
    );
  }

  return <Navigate to='/' />;
};
