import { createUUID } from '@finalytic/data-ui';
import { ReactNode } from 'react';

import { StatementTemplateType } from './_types';
import { NetRevenueColumn } from '@finalytic/common';
import { ensure } from '@finalytic/utils';
import { FormProvider, useForm } from 'react-hook-form';

export const StatementSettingsContextProvider = ({
  children,
  template,
}: {
  children: ReactNode;
  template: StatementTemplateType;
}) => {
  const methods = useForm<StatementTemplateType>({
    defaultValues: template,
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export const defaultNetRevenueTemplate = ensure<{
  title: string;
  columns: NetRevenueColumn[];
}>({
  title: 'Net Revenue Section',
  columns: [
    {
      id: createUUID(),
      name: 'Guest Name',
      type: 'field',
      value: 'reservation.guestName',
      visible: true,
      locked: true,
    },
    {
      id: createUUID(),
      name: 'Confirmation Code',
      type: 'field',
      value: 'reservation.confirmationCode',
      visible: true,
      locked: true,
    },
    {
      id: createUUID(),
      name: 'Reservation Dates',
      type: 'field',
      value: 'reservation.checkIn',
      visible: true,
    },

    {
      id: createUUID(),
      name: 'Total',
      type: 'field',
      value: 'total',
      visible: true,
    },
  ],
});

// ** NOTE: Schema
// {
//   "sections": [
//     {
//       "title": "Net Revenue Section",
//       "groupBy": null,
//       "aggregate": false,
//       "columns": [
//         {
//           "name": "Guest Name",
//           "type": "field",
//           "value": "guestName",
//           "visible": false
//         },
//         {
//           "name": "Net Rental Income",
//           "type": "sumAccounts",
//           "value": ["4000", "4001"]
//         },
//         {
//           "name": "Management Commission",
//           "type": "sumAccounts",
//           "value": ["5000"]
//         },
//         {
//           "name": "Something",
//           "type": "sumColumns",
//           "value": [1, 2]
//         }
//       ]
//     },
//     {
//       "title": "Expenses",
//       "columns": []
//     },
//     {
//       "title": "Adjustments",
//       "columns": []
//     }
//   ]
// }
