import { StatementStatusType, useStatementStatusColor } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { Badge } from '@mantine/core';

export const StatementStatusBadge = ({
  status,
}: {
  status: StatementStatusType;
}) => {
  const [colors, key] = useStatementStatusColor(status);

  return (
    <Badge
      color={key}
      radius={'sm'}
      px={5}
      styles={{
        root: { backgroundColor: colors[1], color: colors[9] },
      }}
    >
      {toTitleCase(status)}
    </Badge>
  );
};
