import { DangerButton, TransparentButton } from '../../../components';
import { useColors } from '../../../styles';
import { Modal } from '../Modal';
import { ModalDeleteIcon } from './_icons';
import { Box, Stack, Text } from '@mantine/core';
import { ReactNode } from 'react';

type DeleteModalProps = {
  opened: boolean;
  onClose: () => void;
  title: string;
  subtitle: ReactNode;
  onSubmit: () => void;
  size?: number;
};

export const DeleteModal = ({
  opened,
  onClose,
  title,
  subtitle,
  onSubmit,
  size,
}: DeleteModalProps) => {
  const { gray } = useColors();

  const onDangerSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={size || 'sm'}
      withCloseButton={false}
      styles={{ body: { paddingBottom: 15 } }}
    >
      <Stack align='center' mb={20}>
        <Box mt={20}>
          <ModalDeleteIcon />
        </Box>
        <Box mb={10} sx={{ textAlign: 'center' }}>
          <Text weight={500} mb={10}>
            {title}
          </Text>
          <Text size='sm'>{subtitle}</Text>
        </Box>
      </Stack>
      <DangerButton
        onClick={onDangerSubmit}
        fullWidth
        mb={10}
        sx={{ height: 40 }}
      >
        Delete
      </DangerButton>
      <TransparentButton onClick={onClose} fullWidth sx={{ color: gray.dark }}>
        Cancel
      </TransparentButton>
    </Modal>
  );
};
