import { useClerk } from '@finalytic/authentication';
import { ReactNode, useMemo } from 'react';
import { Provider, createClient, dedupExchange, fetchExchange } from 'urql';

import { PLATFORM } from '../env';
import {
  ApiProvider,
  ClientV2Provider,
  DBUserProvider,
  DashboardTypeProvider,
  dashboardTypes,
  useMe,
  useSpotlightContext,
} from '../hooks';
import { CustomThemes } from '@finalytic/ui';
import { useMantineTheme } from '@mantine/core';
import { NovuProvider } from '@novu/notification-center';
import { cacheExchange } from '@urql/exchange-graphcache';

type AppRouterProps = {
  graphqlUri: string;
  apiUri: string;
  children: ReactNode;
  dashboardType?: dashboardTypes;
};

export function DBProvider(props: AppRouterProps) {
  return <DB {...props} />;
}

function DB({
  apiUri: API_URI,
  graphqlUri: GRAPHQL_URI,
  children,
  dashboardType,
}: AppRouterProps) {
  const auth = useClerk();
  const spotlight = useSpotlightContext();

  const urql = useMemo(() => {
    /*const subscriptionClient = new SubscriptionClient(
      'ws://localhost/graphql',
      { reconnect: true }
    );*/
    async function getToken() {
      const accessToken = await auth.session?.getToken({ template: 'Hasura' });
      return {
        token: `Bearer ${accessToken}`,
        uri: undefined,
      };
    }
    /*const wsClient = createWSClient({
      connectionParams: async () => {
        const { token } = await getToken();
        return {
          headers: {
            Authorization: token,
          },
        };
      },
      url: GRAPHQL_URI.replace('http', 'ws'),
    });*/

    return createClient({
      url: GRAPHQL_URI,
      // requestPolicy: 'cache-and-network',
      requestPolicy: 'cache-first',
      exchanges: [
        //...defaultExchanges,
        dedupExchange,
        cacheExchange({
          keys: {
            reservation_aggregate: () => null,
            reservation_aggregate_fields: () => null,
            payment_line_sum_fields: () => null,
            payment_line_aggregate_fields: () => null,
            payment_line_aggregate: () => null,
            payment_aggregate: () => null,
            payment_aggregate_fields: () => null,
            owner_statement_aggregate: () => null,
          },
        }),
        fetchExchange,

        /* cacheExchange({
          updates: {
            Mutation: {
              insert_view_one(result, _args, cache, _info) {
                console.log('UDATE VIEW');
                const List = gql`
                  {
                    views {
                      id
                    }
                  }
                `;
                cache.updateQuery({ query: List }, (data) => {
                  data.views.push(result.insert_view_one);
                  return data;
                });
              },
            },
          },
        }),*/
        /*subscriptionExchange({
          forwardSubscription: (operation) => ({
            subscribe: (sink) => ({
              unsubscribe: wsClient.subscribe(operation, sink),
            }),
          }),
        }),*/
      ],
      async fetch(uri, options) {
        const token = await getToken();
        (options as any).headers.Authorization = token.token;
        //if (LogRocket.sessionURL)
        //  (options as any).headers['Logrocket-Session'] = LogRocket.sessionURL;
        if (
          typeof crypto !== 'undefined' &&
          typeof crypto.randomUUID === 'function'
        )
          (options as any).headers['Correlation-ID'] = crypto.randomUUID();
        if (spotlight.current.hypervisorQueue)
          (options as any).headers['Finalytic-Hypervisor-Queue'] =
            spotlight.current.hypervisorQueue;
        if (spotlight.current.integrationQueue)
          (options as any).headers['Finalytic-Integration-Queue'] =
            spotlight.current.integrationQueue;
        if (PLATFORM) (options as any).headers['Finalytic-Platform'] = PLATFORM;
        return fetch(token.uri || uri, options);
      },
    });
  }, [GRAPHQL_URI]);

  return (
    <ClientV2Provider>
      <Provider value={urql}>
        <DBUserProvider>
          {/* <Novu> */}
          <DashboardTypeProvider dashboard={dashboardType}>
            <ApiProvider url={API_URI}>{children}</ApiProvider>
          </DashboardTypeProvider>
          {/* </Novu> */}
        </DBUserProvider>
      </Provider>
    </ClientV2Provider>
  );
}

function Novu({ children }: { children: ReactNode }) {
  const { primaryColor } = useMantineTheme();
  const me = useMe();

  const appName = primaryColor as CustomThemes;

  const applicationIdentifier =
    appName === 'VRPlatform' ? 'Ll14JqIdc47x' : 'OlqcAsb06XAy';
  return (
    <NovuProvider
      subscriberId={me.id}
      applicationIdentifier={applicationIdentifier}
    >
      {children}
    </NovuProvider>
  );
}
