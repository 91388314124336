import {
  useAutomationChangeStore,
  useAutomationSettingMutation,
} from '../views/edit-automation/_hooks';
import { useRunDrawer, useV2TransactionQuery } from '@finalytic/data-ui';
import {
  ActionButton,
  Modal,
  ModalFooter,
  TransparentButton,
  showErrorNotification,
  useColors,
} from '@finalytic/ui';
import { Group, Text } from '@mantine/core';
import { useEffect, useState } from 'react';

function useAutomation({
  automationId,
  modalOpen,
}: {
  modalOpen: boolean;
  automationId: string | null | undefined;
}) {
  const {
    data: automation,
    isLoading: loading,
    refetch,
  } = useV2TransactionQuery(
    (q, args) => {
      const item = q.automation({
        id: args.automationId,
      });
      const connectionData = {
        rightConnectionId: item?.rightConnection?.id,
        rightConnectionName: item?.rightConnection?.name,
        rightAppId: item?.rightConnection?.appId,
        rightAppLogo: item?.rightConnection?.app.iconRound,
        leftConnectionId: item?.leftConnection?.id,
        leftConnectionName: item?.leftConnection?.name,
        leftAppId: item?.leftConnection?.appId,
        leftAppLogo: item?.leftConnection?.app.iconRound,
      };

      const connections = {
        [connectionData?.leftAppId || '']: connectionData?.leftConnectionId,
        [connectionData?.rightAppId || '']: connectionData?.rightConnectionId,
      };
      return { id: args.automationId, connections };
    },
    {
      skip: !automationId || !modalOpen,
      variables: {
        automationId,
      },
      queryKey: 'automations',
    }
  );

  return {
    automation,
    loading,
    refetch,
  };
}

export const RunExistingChangesModal = () => {
  const { automationId } = useRunDrawer();
  const { saveAutomation } = useAutomationSettingMutation();
  const changes = useAutomationChangeStore((store) => store.changes);
  const [opened, setOpened] = useState(false);
  const { gray } = useColors();

  const { automation, loading } = useAutomation({
    automationId,
    modalOpen: opened,
  });

  const close = () => setOpened(false);
  const open = () => setOpened(true);
  const submit = async () => {
    if (!automation?.connections || !automationId) {
      showErrorNotification({
        message: 'Missing automation data. Please try again.',
      });
      return;
    }

    const { ok } = await saveAutomation({
      automationId,
      connections: automation.connections,
    });

    if (ok) close();
  };

  useEffect(() => {
    // open modal if changes for this automation exist
    if (
      automationId &&
      Object.keys(changes[automationId] || {}).length > 0 &&
      Object.values(changes[automationId]).some((t) => t.target !== '*')
    ) {
      open();
    }
  }, [automationId]);

  return (
    <Modal opened={opened} title='Save Automation' onClose={close}>
      <Text mb='sm'>
        We found changes that haven&apos;t been saved for this automation. To
        run the automation with the new changes, please save the automation
        first.
      </Text>

      <ModalFooter>
        <Group position='right'>
          <TransparentButton color={gray.dark} onClick={close}>
            Cancel
          </TransparentButton>
          <ActionButton
            onClick={submit}
            loading={loading}
            disabled={!automation?.connections}
          >
            Save automation
          </ActionButton>
        </Group>
      </ModalFooter>
    </Modal>
  );
};
