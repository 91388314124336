import { useV2TransactionQuery } from '../../../hooks';
import { getSourceDescription } from '@finalytic/common';

import { useSingleSettingByTargetQuery } from './_hooks';
import { AdditionalMappingCellParams } from './mapping-cell-types';
import { useTabEditorType } from './useTabEditorType';
import { InputFormula, LoadingIndicator } from '@finalytic/ui';
import { ICellRendererParams } from '@finalytic/ui-grid';
import { isUUID, toTitleCase } from '@finalytic/utils';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group, Text, ThemeIcon } from '@mantine/core';

export const MappingNameCell = (
  params: ICellRendererParams & AdditionalMappingCellParams
) => {
  const { automationId, settingKey, loadDataInCell } = params;

  const { rightEditorType, rightEditorParams } = useTabEditorType({
    automationId,
    settingKey,
  });

  const { setting } = useSingleSettingByTargetQuery({
    loadDataInCell,
    automationId,
    settingKey,
    targetId: params.data.id,
  });

  const value = loadDataInCell ? setting?.value : params.value;

  switch (rightEditorType) {
    case 'expression':
      return <FormulaCell value={value} />;

    case 'percentage':
      return <PercentageCell value={value} />;

    case 'select':
      if ((rightEditorParams?.options?.length || 0) > 0)
        return toTitleCase(value);

      return <SelectCell value={value} />;

    case 'text':
      return value;

    default:
      return null;
  }
};

const PercentageCell = ({ value }: { value: string }) => {
  if (!value) return null;

  return <>{value} %</>;
};

const FormulaCell = ({ value }: { value: string }) => {
  return (
    <InputFormula
      value={value}
      setValue={() => null}
      placeholder=''
      fields={[]}
      rounded={false}
      withBorder={false}
      disabled
      hideIcon={!value}
    />
  );
};

const SelectCell = ({ value }: { value: string }) => {
  const { source, loading } = useSourceById(value);

  return (
    <Group position='apart' noWrap sx={{ height: '100%' }}>
      {loading ? (
        <LoadingIndicator size='xs' />
      ) : (
        <Text lineClamp={1} sx={{ display: 'block' }}>
          {value ? source?.label : ''}
        </Text>
      )}
      <ThemeIcon color='dark' variant='outline' sx={{ border: 'none' }}>
        <FontAwesomeIcon size='sm' icon={faChevronDown} />
      </ThemeIcon>
    </Group>
  );
};

function useSourceById(id: string | undefined) {
  const { data, isLoading: loading } = useV2TransactionQuery(
    (q, args) => {
      const source = q.sourceById({ id: args.sourceId });
      return {
        value: source?.id,
        label: getSourceDescription(source),
      };
    },
    {
      skip: !id || !isUUID(id),
      variables: {
        sourceId: id,
      },
      queryKey: 'sources',
    }
  );

  return {
    source: data,
    loading,
  };
}
