import { getSourceDescription } from '@finalytic/common';
import {
  formatOwnerName,
  getNamespaceAndType,
  gqlV2,
  useTeamId,
  useV2Client,
} from '@finalytic/data-ui';

import { useEditAutomationContext } from '../../_context';
import {
  AutomationChangeActions,
  useAutomationChangeStore,
} from '../../_hooks';
import { ChildMappingRow, MappingRow } from '../_table-types';
import { IServerSideDatasource } from '@finalytic/ui-grid';
import { ensure, hasValue, toTitleCase } from '@finalytic/utils';
import { useMemo, useState } from 'react';

export type MappingDatasourceParams = {
  settingKey: string;
};

export type MappingTableFilterParams = {
  search: string | undefined;
  unmappedOnly: boolean;
};

type GqlClient = ReturnType<typeof useV2Client>;

export const rowsPerPage = 50;

export function useMappingsDatasource({
  settingKey,
  search,
  unmappedOnly,
}: MappingDatasourceParams & MappingTableFilterParams) {
  const client = useV2Client();

  const findChange = useAutomationChangeStore((store) => store.find);
  const { automation, template } = useEditAutomationContext();
  const [refetchKey, setRefetchKey] = useState(0);

  const params = template.mappings[settingKey].params;
  const leftSchema = template.mappings[settingKey].left.schema;
  const leftParams = template.mappings[settingKey].left.params || params;
  const rightSchema = template.mappings[settingKey].right.schema;

  const [teamId] = useTeamId();

  const dataSource = useMemo<IServerSideDatasource>(() => {
    const fn = async ({ offset, limit }: { offset: number; limit: number }) => {
      const data = await getRowData(client, {
        settingKey,
        connections: automation.connections,
        teamId,
        leftSchema,
        leftParams,
        offset,
        limit,
        search,
        unmappedOnly,
        rightSchema,
        automationId: automation.id,
      });

      const mergedData = formatData({
        data: data.list as { id: string; name: string; settings: Setting[] }[],
        settingKey,
        findChange,
        tabLeftType: leftSchema,
        tabRightType: rightSchema,
        automationId: automation.id,
      });

      return { list: mergedData, aggregate: data.aggregate };
    };
    return {
      getRows: (params) => {
        fn({
          offset: params.request.startRow || 0,
          limit: rowsPerPage,
        })
          .then((data) => {
            const d = data;

            const rowData = d?.list || [];

            if (d && rowData.length === 0) {
              params.api.showNoRowsOverlay();
            } else {
              params.api.hideOverlay();
            }

            params.success({
              rowData,
              rowCount: d?.aggregate || 0,
            });
          })
          .catch(() => params.fail());
      },
    };
  }, [
    search,
    refetchKey,
    teamId,
    leftParams,
    leftSchema,
    settingKey,
    automation,
    unmappedOnly,
  ]);
  return {
    dataSource,
    refetch: () => {
      setRefetchKey((key) => key + 1);
    },
  };
}

const getRowData = async (
  client: GqlClient,
  {
    connections,
    settingKey,
    leftSchema,
    limit,
    offset,
    leftParams,
    teamId,
    search: s,
    unmappedOnly,
    rightSchema,
    automationId,
  }: MappingTableFilterParams & {
    connections: Record<string, string>;
    settingKey: string;
    leftSchema: string;
    rightSchema: string;
    leftParams: Record<string, any>;
    teamId: string;
    offset: number;
    limit: number;
    automationId: string;
  }
) =>
  await client.query(
    (q) => {
      const [appId, type] = getNamespaceAndType(leftSchema);
      const leftConnectionId = connections[appId];
      const rightConnectionId =
        connections[getNamespaceAndType(rightSchema)[0]];

      const search = s?.trim();

      const whereSettings: gqlV2.setting_bool_exp = {
        tenant_id: { _eq: teamId },
        key: { _eq: settingKey },
        // automationId: { _eq: automationId },
        _or: [
          // JUDGEMENT FREE ZONE!
          {
            leftConnectionId: { _eq: rightConnectionId },
            rightConnectionId: { _eq: leftConnectionId },
            leftType: { _eq: leftSchema },
            rightType: { _eq: rightSchema },
          },
          {
            rightConnectionId: { _eq: rightConnectionId },
            leftConnectionId: { _eq: leftConnectionId },
            rightType: { _eq: leftSchema },
            leftType: { _eq: rightSchema },
          },
          //
          {
            leftConnectionId: { _eq: leftConnectionId },
            rightConnectionId: { _eq: rightConnectionId },
            leftType: { _eq: leftSchema },
            rightType: { _eq: rightSchema },
          },
          {
            rightConnectionId: { _eq: leftConnectionId },
            leftConnectionId: { _eq: rightConnectionId },
            rightType: { _eq: leftSchema },
            leftType: { _eq: rightSchema },
          },
        ],
      };

      const orderBySettings: gqlV2.setting_order_by = { created_at: 'desc' };

      if (appId === 'finalytic')
        switch (type) {
          case 'owner': {
            const where: gqlV2.user_bool_exp = {
              type: { _eq: 'owner' },
              ownerships: { listing: { tenantId: { _eq: teamId } } },
              _not: unmappedOnly
                ? {
                    _or: [
                      { settingsRight: whereSettings },
                      { settingsLeft: whereSettings },
                    ],
                  }
                : undefined,
              _or: search
                ? [
                    { firstName: { _ilike: `%${search}%` } },
                    { lastName: { _ilike: `%${search}%` } },
                    { companyName: { _ilike: `%${search}%` } },
                  ]
                : undefined,
            };

            const list = q
              .user({
                where,
                order_by: [{ lastName: 'asc' }, { companyName: 'asc' }],
              })
              .map((i) => ({
                id: i.id,
                name: formatOwnerName(
                  {
                    companyName: i.companyName,
                    firstName: i.firstName,
                    lastName: i.lastName,
                  },
                  { lastNameFirst: true }
                ),
                settings: [
                  ...i
                    .settingsLeft({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                  ...i
                    .settingsRight({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                ],
              }));

            const aggregate =
              q.userAggregate({ where }).aggregate?.count() || 0;

            return { list, aggregate };
          }
          case 'app': {
            const where: gqlV2.app_bool_exp = {
              _and: [
                {
                  connections: { tenantId: { _eq: teamId } },
                  id: {
                    _neq: 'finalytic',
                  },
                  category: { _neq: 'accountingPlatform' },
                  _not: unmappedOnly
                    ? {
                        _or: [
                          { settingsRight: whereSettings },
                          { settingsLeft: whereSettings },
                        ],
                      }
                    : undefined,
                  _or: search
                    ? [{ name: { _ilike: `%${search}%` } }]
                    : undefined,
                },
                leftParams?.filter,
              ].filter(hasValue),
            };

            const list = q
              .app({
                where,
                order_by: [{ name: 'asc' }],
                limit,
                offset,
              })
              .map((i) => ({
                id: i.id || '',
                name: i.name,
                settings: [
                  ...i
                    .settingsLeft({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                  ...i
                    .settingsRight({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                ],
              }));

            const aggregate = q.appAggregate({ where }).aggregate?.count() || 0;

            return {
              list,
              aggregate,
            };
          }
          case 'bookingChannel': {
            const where: gqlV2.booking_channel_bool_exp = {
              reservations: {
                tenantId: { _eq: teamId },
              },
              _or: search
                ? [
                    {
                      uniqueRef: { _ilike: `%${search}%` },
                    },
                  ]
                : undefined,
            };
            const list = q
              .bookingChannels({
                where,
                limit,
                offset,
                order_by: [{ uniqueRef: 'asc' }],
              })
              .map((i) => ({
                id: i.id || '',
                name: toTitleCase(i.uniqueRef),
              }));

            const aggregate =
              q
                .bookingChannelAggregate({
                  where,
                })
                .aggregate?.count() || 0;

            return {
              list,
              aggregate,
            };
          }
          case 'connection': {
            const where: gqlV2.connection_bool_exp = {
              _and: [
                {
                  tenantId: { _eq: teamId },
                  app: {
                    id: {
                      _neq: 'finalytic',
                    },
                    category: { _neq: 'accountingPlatform' },
                  },
                  _not: unmappedOnly
                    ? {
                        _or: [
                          { settingsRight: whereSettings },
                          { settingsLeft: whereSettings },
                        ],
                      }
                    : undefined,
                  _or: search
                    ? [{ name: { _ilike: `%${search}%` } }]
                    : undefined,
                },
                leftParams?.filter,
              ].filter(hasValue),
            };

            console.log({
              tenantId: { _eq: teamId },
              type: { _neq: 'accounting' },
              _not: unmappedOnly
                ? {
                    _or: [
                      { settingsRight: whereSettings },
                      { settingsLeft: whereSettings },
                    ],
                  }
                : undefined,
              _or: search ? [{ name: { _ilike: `%${search}%` } }] : undefined,
            });
            const list = q
              .connection({
                where,
                order_by: [{ name: 'asc' }],
                limit,
                offset,
              })
              .map((i) => ({
                id: i.id || '',
                name: i.name,
                settings: [
                  ...i
                    .settingsLeft({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                  ...i
                    .settingsRight({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                ],
              }));

            const aggregate =
              q.connectionAggregate({ where }).aggregate?.count() || 0;

            return {
              list,
              aggregate,
            };
          }
          case 'listing': {
            const where: gqlV2.listing_bool_exp = {
              tenantId: { _eq: teamId },
              _and: [
                {
                  _not: {
                    _or: [
                      {
                        settingsRight: {
                          key: { _eq: 'exclude' },
                          automationId: { _eq: automationId },
                          leftType: { _eq: 'finalytic.listing' },
                          rightType: { _eq: 'schema.boolean' },
                        },
                      },
                      { status: { _eq: 'disabled' } },
                    ],
                  },
                },
                {
                  _not: unmappedOnly
                    ? {
                        _or: [
                          { settingsRight: whereSettings },
                          { settingsLeft: whereSettings },
                        ],
                      }
                    : undefined,
                },
              ],
              _or: search
                ? [
                    {
                      address: { _ilike: `%${search}%` },
                    },
                    {
                      uniqueRef: { _ilike: `%${search}%` },
                    },
                    {
                      name: { _ilike: `%${search}%` },
                    },
                  ]
                : undefined,
            };

            const list = q
              .listings({
                where,
                order_by: [{ name: 'asc' }],
                limit,
                offset,
              })
              .map((i) => ({
                id: i.id || '',
                name: i.name,
                settings: [
                  ...i
                    .settingsLeft({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                  ...i
                    .settingsRight({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                ],
              }));

            const aggregate = q.listingAggregate({ where }).aggregate?.count();

            return {
              list,
              aggregate,
            };
          }
          case 'listingConnection': {
            const where: gqlV2.listing_connection_bool_exp = {
              tenantId: { _eq: teamId },
              _not: unmappedOnly
                ? {
                    _or: [
                      { settingsRight: whereSettings },
                      { settingsLeft: whereSettings },
                    ],
                  }
                : undefined,
              _or: search
                ? [
                    {
                      name: { _ilike: `%${search}%` },
                    },
                  ]
                : undefined,
            };

            const list = q
              .listingConnections({
                where,
                order_by: [{ name: 'asc' }],
                limit,
                offset,
              })
              .map((i) => ({
                id: i.id || '',
                name: i.name,
                settings: [
                  ...i
                    .settingsLeft({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                  ...i
                    .settingsRight({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                ],
              }));

            const aggregate = q
              .listingConnectionAggregate({ where })
              .aggregate?.count();

            return {
              list,
              aggregate,
            };
          }
          case 'listingOwner': {
            const where: gqlV2.listing_owner_bool_exp = {
              listing: {
                tenantId: { _eq: teamId },
                _not: {
                  _or: [
                    {
                      settingsRight: {
                        key: { _eq: 'exclude' },
                        automationId: { _eq: automationId },
                        leftType: { _eq: 'finalytic.listing' },
                        rightType: { _eq: 'schema.boolean' },
                      },
                    },
                    { status: { _eq: 'disabled' } },
                  ],
                },
              },
              _not: unmappedOnly
                ? {
                    _or: [
                      { settingsRight: whereSettings },
                      { settingsLeft: whereSettings },
                    ],
                  }
                : undefined,
              _or: search
                ? [
                    { owner: { firstName: { _ilike: `%${search}%` } } },
                    { owner: { lastName: { _ilike: `%${search}%` } } },
                    { owner: { companyName: { _ilike: `%${search}%` } } },
                    { listing: { name: { _ilike: `%${search}%` } } },
                  ]
                : undefined,
            };

            const list = q
              .listingOwners({
                where,
                order_by: [{ listing: { name: 'asc' } }],
                limit,
                offset,
              })
              .map((i) => ({
                id: i.id,
                name: `${i.listing.title || i.listing.name} - ${formatOwnerName(
                  {
                    companyName: i.owner.companyName,
                    firstName: i.owner.firstName,
                    lastName: i.owner.lastName,
                  },
                  { lastNameFirst: true }
                )}`,
                settings: [
                  ...i
                    .settingsLeft({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                  ...i
                    .settingsRight({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                ],
              }));

            const aggregate =
              q.listingOwnerAggregate({ where }).aggregate?.count() || 0;

            return {
              list,
              aggregate,
            };
          }
          case 'lineType': {
            const where: gqlV2.payment_line_classification_bool_exp = {
              lines: { tenantId: { _eq: teamId } },
              _not: unmappedOnly
                ? {
                    _or: [
                      { settingsRight: whereSettings },
                      { settingsLeft: whereSettings },
                    ],
                  }
                : undefined,
              _or: search ? [{ name: { _ilike: `%${search}%` } }] : undefined,
            };
            const list = q
              .paymentLineClassifications({
                order_by: [{ name: 'asc' }],
                where,
              })
              .map((i) => ({
                id: i.name || '',
                name: i.name || '',
                accountingType: getAccountingType(teamId, i),
                settings: [
                  ...i
                    .settingsLeft({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                  ...i
                    .settingsRight({
                      where: whereSettings,
                      order_by: [orderBySettings],
                    })
                    .map((setting) => getSetting(setting, leftSchema)),
                ],
              }))
              .filter((i) =>
                isLineAccountingType(leftParams?.accountingType, i)
              );

            return {
              list,
              aggregate: list.length,
            };
          }

          default:
            return { list: [], aggregate: 0 };
        }
      else {
        const where: gqlV2.source_bool_exp = {
          tenantId: { _eq: teamId },
          connectionId: { _eq: leftConnectionId },
          type: { _eq: type },
          _not: unmappedOnly
            ? {
                _or: [
                  { settingsRight: whereSettings },
                  { settingsLeft: whereSettings },
                ],
              }
            : undefined,
          _or: search
            ? [
                { description: { _ilike: `%${search.trim()}%` } },
                { remoteId: { _eq: `${search.trim()}` } },
              ]
            : undefined,
        };

        const list = q
          .source({
            order_by: [{ description: 'asc' }],
            where,
            offset,
            limit,
          })
          .map((i) => ({
            id: i.id || '',
            name: getSourceDescription(i),
            settings: [
              ...i
                .settingsLeft({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
              ...i
                .settingsRight({
                  where: whereSettings,
                  order_by: [orderBySettings],
                })
                .map((setting) => getSetting(setting, leftSchema)),
            ],
          }));

        const aggregate = q.sourceAggregate({ where }).aggregate?.count() || 0;

        return {
          list,
          aggregate,
        };
      }
    },
    {
      noCache: true,
    }
  );

type Setting = {
  value: string | undefined;
  target: string | undefined;
  leftType: string | undefined;
  rightType: string | undefined;

  settingId: string;
  parentSettingId: string | undefined;
  childSettings?: ChildMappingRow[];
};

const getSetting = (setting: gqlV2.setting, leftSchema: string): Setting => {
  const originalSetting = {
    target: setting.target as string | undefined,
    settingId: setting.id,
    value: setting.value as string | undefined,
    leftType: setting.leftType || '',
    rightType: setting.rightType as string | undefined,
    parentSettingId: setting.parentSettingId,
    childSettings: setting.childSettings().map((childSet) => ({
      settingId: childSet.id || '',
      parentSettingId: childSet.parentSettingId || '',
      target: childSet.target || '',
      value: childSet.value || '',
      leftType: childSet.leftType || '',
      rightType: childSet.rightType || '',
      childSettings: [],
      parentRowTypeId: setting.target,
      name: '',
    })),
  };

  function invertSetting<T extends typeof originalSetting>(value: T): T {
    if (value.leftType !== leftSchema) {
      return {
        ...value,
        leftType: value.rightType,
        rightType: value.leftType,
        value: value.target,
        target: value.value,
      };
    }

    return value;
  }

  return invertSetting({
    ...originalSetting,
    childSettings: originalSetting.childSettings?.map((child) => child),
  });
};

const formatData = ({
  data,
  findChange,
  settingKey,
  tabLeftType,
  tabRightType,
  automationId,
}: {
  data: { id: string; name: string; settings: Setting[] }[];
  settingKey: string;
  tabLeftType: string;
  tabRightType: string;
  findChange: AutomationChangeActions['find'];
  automationId: string;
}) => {
  return data.map<MappingRow>((i) => {
    const rowTargetId = i['id'];
    const rowTargetName = i['name'] || '';

    const settings = i.settings;

    // setting.leftType === automationTempalte[setting].leftK

    const parentException =
      findChange({
        isGlobalSetting: false,
        settingKey,
        target: rowTargetId,
        automationId,
      }) || settings?.find((setting) => setting.target === rowTargetId);

    // return row data
    return ensure<MappingRow>({
      target: rowTargetId,
      name: rowTargetName,
      settingId: parentException?.settingId,
      value: parentException?.value,
      leftType: parentException?.leftType || tabLeftType,
      rightType: parentException?.rightType || tabRightType,
      childSettings: (parentException?.childSettings || []).filter(
        (childRow) => !!childRow.value
      ),
      parentSettingId: undefined,
    });
  });
};

export function isLineAccountingType(
  accountingType: string,
  line: {
    accountingType: {
      value?: string;
      children: { target?: string; value?: string }[];
    }[];
  }
) {
  if (!accountingType) return true;
  return line.accountingType.find((x) => {
    const included = x.value === accountingType;

    const childIncluded = !!x.children.find((x) => x.value === accountingType);
    return childIncluded || included;
  });
}

export function getAccountingType(
  tenantId: string,
  line: gqlV2.payment_line_classification | gqlV2.payment_line
) {
  const fn =
    'settingsRight' in line ? line.settingsRight : line.settingsByType2;
  return fn({
    where: {
      _or: [
        // inclusion is team specific
        {
          key: { _eq: 'inclusion' },
          tenant_id: { _eq: tenantId },
        },
        // accountingType is global
        {
          key: { _eq: 'accountingType' },
        },
      ],
    },
  }).map((setting) => ({
    key: setting.key,
    value: setting.value,
    children: setting
      .childSettings({
        where: {
          _or: [
            // inclusion is team specific
            {
              key: { _eq: 'inclusion' },
              tenant_id: { _eq: tenantId },
            },
            // accountingType is global
            {
              key: { _eq: 'accountingType' },
            },
          ],
        },
      })
      .map((child) => ({
        key: child.key,
        target: child.target,
        value: child.value,
      })),
  }));
}
