import { gqlV2, useV2TransactionQuery } from './graphql-v2';
import { useUser } from '@finalytic/authentication';
import * as React from 'react';
import useLocalStorageState from 'use-local-storage-state';

import { useOwnerPreviewId } from './owner';
import { appsContext } from './useApps';
//import LogRocket from 'logrocket';

import { formatOwnerName } from '../utils';
import { useExtension } from './useExtension';
import { useMantineTheme } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useNavigate } from 'react-router';

// Segment Analytics Types
declare global {
  interface Window {
    analytics:
      | {
          track: (event: string, obj?: Record<string, any>) => void;
          identify: (userId: string, obj?: Record<string, any>) => void;
          page: (page: string) => void;
        }
      | undefined;
  }
}

export const captureSentryError = (error: Error | string) => {
  if (error instanceof Error) {
    Sentry.captureException(error);
  } else {
    Sentry.captureException(new Error(error));
  }
};

if (location.hostname !== 'localhost') {
  console.log('Load sentry');
  // LogRocket.init('finalytic/app-v3-ghtxm');
  Sentry.init({
    dsn: 'https://4ba9b6d2701d4a889e6f323c050a4a46@o515442.ingest.sentry.io/5624987',

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.DEV ? 'development' : 'production',
    tracesSampleRate: 0.1,
    integrations: [
      new Sentry.Replay({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
      }),
      new BrowserTracing(),
    ],
  });
}

function __useMe(args: {
  userId?: string;
  realUserId?: string;
  activeTeamId?: string;
  role?: string;
}) {
  // console.log('USER ID', id);
  const {
    data: user,
    isLoading,
    error,
    refetch,
  } = useV2TransactionQuery(
    (q, { userId, activeTeamId }) => {
      const user: gqlV2.user = q.userById({
        id: userId,
      })!;

      const currentTeam = q
        .tenant({
          limit: 1,
          where: activeTeamId ? { id: { _eq: activeTeamId } } : undefined,
        })
        .map((item) => {
          const membership = item
            .members({ where: { userId: { _eq: userId } } })
            .map((x) => ({ id: x.id, role: x.role }))[0];

          const apps = item
            .connections({
              where: { status: { _nin: ['deleted', 'disabled'] } },
              distinct_on: ['appId'],
              order_by: [{ appId: 'asc_nulls_first' }],
            })
            .map((connection) => ({
              id: connection.appId,
              name: connection.app.name,
              type: connection.app.type,
              category: connection.app.category,
              connectionId: connection.id,
            }));

          const accountingPlatforms = item
            .connections({
              where: {
                status: { _nin: ['deleted', 'disabled'] },
                app: { category: { _eq: 'accountingPlatform' } },
              },
              order_by: [{ appId: 'asc_nulls_first' }],
            })
            .map((connection) => ({
              appId: connection.appId,
              name: connection.app.name,
              type: connection.app.type,
              category: connection.app.category,
              id: connection.id,
            }));

          const propertyManagementSystems = item
            .connections({
              where: {
                status: { _nin: ['deleted', 'disabled'] },
                app: { category: { _eq: 'propertyManagementSystem' } },
              },
              order_by: [{ appId: 'asc_nulls_first' }],
            })
            .map((connection) => ({
              appId: connection.appId,
              name: connection.app.name,
              type: connection.app.type,
              category: connection.app.category,
              id: connection.id,
            }));

          return {
            role: membership?.role,
            membershipId: membership?.id,
            id: item.id!,
            logo: item.logo!,
            name: item.name!,
            status: item.status!,
            createdAt: item.createdAt!,
            colorPrimary: item.colorPrimary!,
            partnerId: item.partnerId!,
            featureFlags: item.featureFlags({})! as string[],
            type: item.type!,
            apps,
            finalyticConnectionId: apps.find((i) => i.id === 'finalytic')
              ?.connectionId as string,
            accountingPlatforms,
            propertyManagementSystems,
            automations:
              args.role === 'owner'
                ? []
                : item
                    .automations({
                      order_by: [{ title: 'asc_nulls_last' }],
                    })
                    .map((automation) => ({
                      automationId: automation.id,
                      status: automation.status,
                      templateId: automation.ttemplate?.id,
                      template: {
                        name: automation.ttemplate?.name,
                        label: automation.ttemplate?.label,
                        input: automation.ttemplate?.input,
                        params: automation.ttemplate?.params(),
                        visibility: automation.ttemplate?.visibility,
                      },
                      name: automation.ttemplate?.name,
                      title: automation?.title || automation.ttemplate?.label,
                      mappings: automation.ttemplate?.mappings({}),
                      leftConnectionId: automation.leftConnectionId,
                      leftConnection: {
                        id: automation.leftConnection?.id,
                        name: automation.leftConnection?.name,
                        icon: automation.leftConnection?.app?.iconRound,
                      },
                      rightConnectionId: automation.rightConnectionId,
                      rightConnection: {
                        id: automation.rightConnection?.id,
                        name: automation.rightConnection?.name,
                        icon: automation.rightConnection?.app?.iconRound,
                      },
                      connections: {
                        [automation.leftConnection?.appId || '']:
                          automation?.leftConnectionId,
                        [automation.rightConnection?.appId || '']:
                          automation?.rightConnectionId,
                      },
                    })),
          };
        })
        .find((x) => x);

      const partnerTeams = activeTeamId
        ? q
            .tenant({
              where: { partnerId: { _eq: activeTeamId } },
            })
            .map((item) => {
              const membership = item
                .members({ where: { userId: { _eq: userId } } })
                .map((x) => ({ id: x.id, role: x.role }))[0];
              return {
                role: membership?.role,
                membershipId: membership?.id,
                id: item.id!,
                tenantId: item.id!,
                logo: item.logo!,
                name: item.name!,
                colorPrimary: item.colorPrimary!,
                partnerId: item.partnerId,
              };
            })
        : undefined;

      const apps = q.app({}).map((item) => ({
        id: item.id!,
        name: item.name!,
        iconRound: item.iconRound!,
      }));

      const memberships = user.memberships().map((membership) => ({
        status: membership?.status,
        id: membership?.tenantId,
        role: membership?.role,
        teamType: membership?.tenant?.type,
      }));
      return {
        partnerTeams,
        currentTeam,
        id: user.id!,
        notificationPreferences: user.notificationPreferences() as string[],
        type: user.type!,
        name: user.name!,
        memberships,
        firstName: user.firstName as string | undefined,
        lastName: user.lastName as string | undefined,
        companyName: user.companyName as string | undefined,
        partnerId: user.partnerId,
        isAdmin: user.isAdmin!,
        isPartnerAdmin:
          memberships.find((x) => x.teamType === 'partner')?.role === 'admin',
        email: user.email!,
        createdAt: user.createdAt!,
        ownerships: user.ownerships({}).map((item) => ({
          id: item.id!,
          listingId: item.listingId!,
        })),
        apps: apps?.reduce((acc, app) => ({ ...acc, [app.id]: app }), {}) || {},
      };
    },
    {
      variables: args,
      queryKey: ['users', 'teams'],
    }
  );

  React.useEffect(() => {
    if (user?.id) {
      Sentry.setUser({
        name: user.name,
        email: user.email,
        createdAt: user.createdAt,
        id: user.id,
      });

      // Segment.io
      window.analytics?.identify(user.id, {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        name: formatOwnerName(
          {
            firstName: user.firstName,
            lastName: user.lastName,
          },
          { lastNameFirst: false }
        ),
        teamId: user?.currentTeam?.id,
        team: user?.currentTeam?.name,
        type: user?.currentTeam?.role,
        status: user?.currentTeam?.status,
      });
    }
  }, [user?.id, user?.currentTeam?.id]);

  return {
    apps: user?.apps,
    user: user ? { ...user, realUserId: args.realUserId } : undefined,
    loading: isLoading,
    error,
    refetch,
  };
}

type User = NonNullable<ReturnType<typeof __useMe>['user']>;
export type Team = NonNullable<User['currentTeam']>;
type PartnerTeam = NonNullable<User['partnerTeams']>[number];

const userContext = React.createContext<User>(undefined as any);
export const teamIdContext = React.createContext<{
  teamId: string;
  setTeamId: (value: string) => void;
}>({
  teamId: '',
  setTeamId: () => undefined,
});
export const teamContext = React.createContext<{
  teamId: string;
  setTeamId: (value: string) => void;
  team: Team;
  partnerTeams: PartnerTeam[];
  role: string;
  isAdmin: boolean;
  isPartnerAdmin: boolean;
  isSuperAdmin: boolean;
  refetchTeam: () => void;
  refetchPartnerTeams: () => void;
}>({
  isAdmin: false,
  isPartnerAdmin: false,
  isSuperAdmin: false,
  role: 'user',
  team: {} as any,
  partnerTeams: [],
  teamId: '',
  setTeamId: () => undefined,
  refetchTeam: () => undefined,
  refetchPartnerTeams: () => undefined,
});

export function DBUserProvider({ children }: { children: React.ReactNode }) {
  const { user: u } = useUser();

  const { ownerPreview } = useOwnerPreviewId();
  const realUserId = `${u?.publicMetadata.user_id || ''}`;
  const ownerPreviewUserId = ownerPreview?.userId?.trim() || '';
  const ownerPreviewTeamId = ownerPreview?.teamId?.trim() || '';

  const userId =
    ownerPreviewUserId || `${u?.publicMetadata.user_id || ''}` || undefined;
  // Ream
  const [_teamId, setTeamId] = useLocalStorageState<string>(`${userId}_tid`);

  const { user, refetch, apps, error } = __useMe({
    userId,
    activeTeamId: ownerPreviewTeamId || _teamId,
    role: `${u?.publicMetadata.role || ''}`,
    realUserId,
  });

  // For webextension
  React.useEffect(() => {
    if (u) localStorage.setItem('cid', u.id);
    else localStorage.removeItem('cid');
    if (userId) localStorage.setItem('uid', userId);
    else localStorage.removeItem('uid');

    const tid = _teamId || user?.currentTeam?.id;
    if (tid) localStorage.setItem('tid', tid);
    else localStorage.removeItem('tid');
  }, [userId, u?.id, _teamId, user?.currentTeam?.id]);

  const teamIdValue = React.useMemo(() => {
    return {
      teamId: _teamId || user?.currentTeam?.id,
      setTeamId,
    };
  }, [_teamId || user?.currentTeam?.id, setTeamId]);

  const teamValue = React.useMemo(() => {
    return {
      teamId: user?.currentTeam?.id,
      setTeamId,
      team: user?.currentTeam || {},
      partnerTeams: user?.partnerTeams || [],
      role: user?.currentTeam?.role,
      isAdmin: user?.isAdmin || user?.currentTeam?.role === 'admin',
      isPartnerAdmin: user?.isAdmin || user?.isPartnerAdmin,
      isSuperAdmin: user?.isAdmin,
      refetchTeam: refetch,
      refetchPartnerTeams: () => undefined,
    };
  }, [JSON.stringify(user?.currentTeam), setTeamId]);

  // if (error) return <>Error: ${error.message}</>;
  return (
    <userContext.Provider value={user as any}>
      <teamIdContext.Provider value={teamIdValue as any}>
        <teamContext.Provider value={teamValue as any}>
          <appsContext.Provider value={apps as any}>
            {children}
          </appsContext.Provider>
        </teamContext.Provider>
      </teamIdContext.Provider>
    </userContext.Provider>
  );
}

export function useMe() {
  let user = React.useContext(userContext);
  if (!user) user = {} as any;
  return user;
}

export function useTeamId() {
  const { sendMessage } = useExtension();
  const goto = useNavigate();

  let tenant = React.useContext(teamIdContext);
  if (!tenant) tenant = [undefined, () => undefined] as any;

  const setTeamId = async (teamId: string) => {
    tenant.setTeamId(teamId);

    if (
      window.location.pathname.includes('statement') &&
      !window.location.search?.includes('sti=')
    ) {
      goto('/statements');
    }

    try {
      sendMessage({ message: 'team_changed', data: { teamId } }, (reply) =>
        console.log(reply)
      );
    } catch (error) {
      console.log(error);
    }
  };

  return [tenant.teamId, setTeamId] as [string, (value: string) => void];
}

export function useTeam() {
  let tenant = React.useContext(teamContext);
  if (!tenant) tenant = [undefined, () => undefined] as any;
  return [tenant.team, tenant.refetchTeam] as [
    typeof tenant.team,
    typeof tenant.refetchTeam
  ];
}

export function useFeatureFlags() {
  let tenant = React.useContext(teamContext);
  if (!tenant) tenant = [undefined, () => undefined] as any;
  return tenant.team.featureFlags;
}

// export function useBilling() {
//   let tenant = React.useContext(teamContext);
//   if (!tenant) tenant = {} as any;

//   const { primaryColor: appName } = useMantineTheme();
//   const isVRP = appName === 'VRPlatform';

//   const billingFlags = ['VRPBronze', 'VRPSilver', 'VRPGold'] as const;

//   // Get team subscription from feature flags
//   const featureFlags = tenant?.team?.featureFlags || [];
//   const teamSubscription = featureFlags.find((flag) =>
//     billingFlags.includes(flag as any)
//   ) as typeof billingFlags[number] | undefined;
//   const hasSubscription = !!teamSubscription;

//   // Calculate trial End based on team createdAt
//   const t = utc(tenant.team.createdAt).add(2, 'weeks');
//   const trialEnd =
//     featureFlags.includes('trial') && t.isAfter(utc()) ? t.toDate() : undefined;

//   // TEMPORARY: billing Enabled flag
//   const billingEnabled =
//     isVRP && featureFlags.some((r) => [...billingFlags, 'trial'].includes(r));
//   //  || tenant.team.type === 'admin');

//   // Helper booleans
//   const hasStatements = billingEnabled ? teamSubscription === 'VRPGold' : true;
//   const hasAutomations = true; // automations are always activated

//   const showTableLimit = billingEnabled && !hasSubscription && !trialEnd;

//   return {
//     subscription: teamSubscription,
//     hasSubscription: !!teamSubscription,
//     billingEnabled,
//     trialEnd,
//     hasStatements,
//     hasAutomations,
//     tableLimits: {
//       payments: showTableLimit ? 5 : undefined,
//       reservations: showTableLimit ? 5 : undefined,
//       statements: showTableLimit ? 5 : undefined,
//     },
//   };
// }

export function usePartnerTeams() {
  let tenant = React.useContext(teamContext);
  if (!tenant) tenant = [[], () => undefined] as any;
  return [tenant.partnerTeams, tenant.refetchPartnerTeams] as [
    typeof tenant.partnerTeams,
    typeof tenant.refetchPartnerTeams
  ];
}

export function useTeamRole() {
  let tenant = React.useContext(teamContext);
  if (!tenant) tenant = [undefined, () => undefined] as any;
  return {
    teamRole: tenant.role,
    isTeamAdmin: tenant.isPartnerAdmin || tenant.isAdmin,
    isPartnerAdmin: tenant.isPartnerAdmin,
    isSuperAdmin: tenant.isSuperAdmin,
  };
}
