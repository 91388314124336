import { useSignInForm } from '../hooks/useSignInForm';
import { SocialAuthButtons } from './SocialAuthButtons';
import { InputsBySchema } from '@finalytic/data-ui';
import { ForgotPasswordModal } from '@finalytic/onboarding';
import { ActionButton, CustomThemes } from '@finalytic/ui';
import {
  Anchor,
  Divider,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export const SignInForm = ({
  handleSubmit,
  loading,
  methods,
  schema,
  showingSignUp,
  signInWithSocials,
  sendMagicLink,
}: ReturnType<typeof useSignInForm>) => {
  const { primaryColor: appName, colors } = useMantineTheme();

  const [opened, handlers] = useDisclosure(false);

  const dividerColor = colors.gray[5];

  const email = methods.watch('email');

  return (
    <>
      <Stack
        spacing={15}
        sx={{
          maxWidth: 400,
          width: '100%',
          margin: '0 auto',
          label: {
            fontWeight: 400,
          },
        }}
      >
        {/* SSO Buttons */}
        <SocialAuthButtons signInWithSocials={signInWithSocials} />

        <Group mt='sm' noWrap sx={{ div: { flex: 1 } }}>
          <Divider sx={{ backgroundColor: dividerColor }} />
          <Text
            component='span'
            size='xs'
            color={dividerColor}
            sx={{ opacity: 0.8 }}
          >
            OR
          </Text>
          <Divider sx={{ backgroundColor: dividerColor }} />
        </Group>

        {/* Email Form */}
        {!showingSignUp ? (
          <>
            <InputsBySchema schema={schema} methods={methods} mb={0} />
            <Anchor
              onClick={handlers.open}
              component='button'
              p={0}
              h='auto'
              mih={'auto'}
              size='xs'
              sx={(theme) => ({
                borderRadius: theme.radius.sm,
                fontWeight: 400,
                lineHeight: '1rem',
                alignSelf: 'flex-start',
              })}
            >
              Forgot Password
            </Anchor>
            <ActionButton
              id='signInButton'
              fullWidth
              loading={loading}
              disabled={!methods.formState.isValid}
              onClick={methods.handleSubmit(handleSubmit)}
              mt={30}
              mb='lg'
            >
              Sign In
            </ActionButton>
            <Text component='p' align='center' color='gray' size='xs'>
              No account yet?{' '}
              <Anchor component={Link} to='/sign-up'>
                Sign Up
              </Anchor>
            </Text>
          </>
        ) : (
          <>
            <InputsBySchema schema={schema} methods={methods} />
            <ActionButton
              fullWidth
              loading={loading}
              disabled={!methods.formState.isValid}
              onClick={methods.handleSubmit(handleSubmit)}
              mt={30}
              mb='lg'
            >
              Sign Up
            </ActionButton>
            <Text component='p' align='center' size='xs'>
              {policyText[appName as CustomThemes]}
            </Text>

            <Text component='p' align='center' color='gray' size='xs'>
              Already have account?{' '}
              <Anchor component={Link} to='/login'>
                Log in
              </Anchor>
            </Text>
          </>
        )}
      </Stack>

      <ForgotPasswordModal
        opened={opened}
        close={handlers.close}
        email={email}
        onSubmit={sendMagicLink}
      />
    </>
  );
};

const policyText: { [key in CustomThemes]: ReactNode } = {
  VRPlatform: (
    <>
      By signing up, you accept our{' '}
      <Anchor
        weight={500}
        href={'https://ximplifi.com/ximplifi-privacy-policy/'}
        target='_blank'
      >
        Privacy Policy
      </Anchor>
      .
    </>
  ),
  Finalytic: (
    <>
      By signing up, you accept our{' '}
      <Anchor weight={500} href={'https://finalytic.io/terms'} target='_blank'>
        Terms of Service
      </Anchor>{' '}
      and{' '}
      <Anchor
        weight={500}
        href={'https://finalytic.io/privacy-policy'}
        target='_blank'
      >
        Privacy Policy
      </Anchor>
      .
    </>
  ),
};
