import { GOOGLE_MAPS_KEY, STRIPE_PUBLISHABLE_KEY } from '../../env';
import { ActionButton, showErrorNotification } from '@finalytic/ui';
import { Box } from '@mantine/core';
import {
  AddressElement,
  AddressElementProps,
  Elements,
  useElements,
} from '@stripe/react-stripe-js';
import { StripeAddressElementChangeEvent, loadStripe } from '@stripe/stripe-js';
import { ReactNode } from 'react';

export type StripeAddress = NonNullable<
  AddressElementProps['options']['defaultValues']
>;

const stripe = loadStripe(STRIPE_PUBLISHABLE_KEY);

type Props = {
  address: StripeAddress;
  onAddressChange:
    | ((event: StripeAddressElementChangeEvent) => void)
    | undefined;
};

export const StripeAddress = ({ address, onAddressChange }: Props) => {
  return (
    <AddressElement
      options={{
        mode: 'billing',
        defaultValues: address,
        autocomplete: { apiKey: GOOGLE_MAPS_KEY, mode: 'google_maps_api' },
        fields: {
          phone: 'never',
        },
        display: {
          name: 'organization',
        },
      }}
      onChange={onAddressChange}
    />
  );
};

export const StripeAddressProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Elements
      stripe={stripe}
      options={{
        appearance: {
          theme: 'none',
          disableAnimations: true,
          rules: {
            '.Input': {
              border: '0.0625rem solid #ced4da',
              borderRadius: '0.5rem',
              fontSize: '13px',
            },
            '.Input:focus': {
              border: '0.0625rem solid #BEC0C9',
              boxShadow: '0px 0px 0px 1px #BEC0C9',
              outline: 'none',
            },
            '.Label': {
              fontSize: '0.875rem',
              fontWeight: '500',
              color: '#000',
            },
          },
        },
      }}
    >
      {children}
    </Elements>
  );
};

export const StripeAddressWithProvider = (props: Props) => {
  return (
    <StripeAddressProvider>
      <StripeAddress {...props} />
    </StripeAddressProvider>
  );
};

export const StripeAddressWithProviderStory = (address: StripeAddress) => {
  const elements = useElements();

  const submit = async () => {
    const addressElement = elements?.getElement('address');

    const { complete, value } = await (addressElement as any)?.getValue!();

    if (complete) {
      alert(JSON.stringify(value, null, 2));
      // Allow user to proceed to the next step
      // Optionally, use value to store the address details
    } else {
      showErrorNotification({
        message: 'Please finish entering your details.',
      });
    }
  };

  return (
    <Box maw={400}>
      <Box mb='lg'>
        <StripeAddressWithProvider
          address={address}
          onAddressChange={undefined}
        />
      </Box>
      <ActionButton w='100%' onClick={submit}>
        Submit
      </ActionButton>
    </Box>
  );
};
