import { gqlV2, useDataSourceV2 } from '../../../hooks';
import { order_by } from '@finalytic/graphql';
import { IServerSideDatasource, SortModelItem } from '@finalytic/ui-grid';
import { sum, uniqueBy } from '@finalytic/utils';
import { useMemo, useState } from 'react';

const rowsPerPage = 100;
export type PaymentType = any;

export function usePaymentsDatasource({
  where,
  billingLimit,
}: {
  where: gqlV2.payment_bool_exp;
  billingLimit: number | undefined;
}) {
  const [refetchKey, setRefetchKey] = useState(0);
  const { fetch, keys } = useDataSourceV2('reservations');

  const dataSource = useMemo<IServerSideDatasource>(() => {
    console.log('Refresh datasource', where);
    const fn = async ({
      offset,
      limit,
      orderBy,
    }: {
      offset: number;
      limit: number;
      orderBy: SortModelItem;
    }) => {
      console.log(`Refresh data ${offset}/${limit}`);
      const data = await fetch((query) => {
        const aggregate =
          query.paymentAggregate({ where }).aggregate?.count() || 0;

        const list = query
          .payments({
            where,
            limit,
            offset,
            order_by: [
              {
                payedAt: 'desc',
              },
            ],
          })
          .map((item) => {
            return {
              id: item.id,
              payedAt: item.payedAt,
              type: item.type,
              centTotal: item.centTotal,
              currency: item.currency,
              tenant: {
                id: item.tenant?.id,
                name: item.tenant?.name,
                logo: item.tenant?.logo,
                colorPrimary: item.tenant?.colorPrimary,
              },
              connection: {
                id: item.connection?.id,
                name: item.connection?.name,
                appId: item.connection?.appId,
                app: {
                  id: item.connection?.app?.id,
                  iconRound: item.connection?.app?.iconRound,
                },
              },
              actions: uniqueBy(
                item
                  ?.actionLinks({
                    order_by: [{ createdAt: 'asc' }],
                  })
                  .map((item) => ({
                    id: item.id,
                    status: item.action?.status,
                    type: item.action?.schema?.uniqueRef,
                    message: item.action?.title,
                  })),
                'type'
              ),
              results: item
                ?.results({
                  order_by: [{ type: 'asc' }, { created_at: 'desc' }],
                  where: { status: { _in: ['ok', 'fail'] } },
                  distinct_on: ['type'],
                })
                .map((result) => ({
                  id: result.id,
                  status: result.status,
                  type: result.type,
                  message: result.message,
                })),
            };
          });
        console.log(list);
        return { list, aggregate };
      });
      return data;
    };
    return {
      getRows: (params) => {
        fn({
          offset: params.request.startRow || 0,
          limit: billingLimit || rowsPerPage,
          orderBy: params.request.sortModel[0],
        })
          .then((data) => {
            console.log('Refresh datasource success');
            const list = data?.list || [];
            const rowData = billingLimit
              ? [
                  ...list,
                  ...Array.from(
                    {
                      length: rowsPerPage - list.length || 0,
                    },
                    (_, index) => ({
                      ...list[list.length - 1],
                      id: list[list.length - 1]?.id + index,
                    })
                  ),
                ]
              : list;

            if (data && rowData.length === 0) {
              params.api.showNoRowsOverlay();
            } else {
              params.api.hideOverlay();
            }

            params.success({
              rowData,
              rowCount: data?.aggregate,
            });
          })
          .catch((err) => {
            console.error(err);
            params.fail();
          });
      },
    };
  }, [JSON.stringify(where), billingLimit, refetchKey, ...keys]);
  return {
    dataSource,
    refetch: () => {
      console.log('Set refresh key datasource');
      setRefetchKey((key) => key + 1);
    },
    paginationSize: rowsPerPage,
  };
}

/*
async function getPayments(
  client: ReturnType<typeof useClient>,
  {
    where,
    offset,
    limit,
    billingLimit,
  }: {
    where: payment_bool_exp;
    offset: number;
    limit: number;
    billingLimit: number | undefined;
  }
) {
  const { data } = await client.query(
    {
      payments: [
        {
          where: gql.$`where`,
          order_by: [
            {
              payedAt: 'desc',
            },
          ],
          offset: gql.$`offset`,
          limit: gql.$`limit`,
        },
        {
          id: true,
          payedAt: true,
          type: true,
          centTotal: true,
          currency: true,
          tenant: {
            id: true,
            name: true,
            logo: true,
            colorPrimary: true,
          },
          connection: {
            id: true,
            name: true,
            appId: true,
            app: {
              id: true,
              iconRound: true,
            },
          },
          results: [
            {
              order_by: [{ type: 'asc' }, { created_at: 'desc' }],
              where: { status: { _in: ['ok', 'fail'] } },
              distinct_on: ['type'],
            },
            { id: true, status: true, type: true, message: true },
          ],
        },
      ],

      paymentAggregate: [
        { where: gql.$`where` },
        {
          aggregate: {
            count: [{}, true],
          },
        },
      ],
    },
    {
      where,
      offset,
      limit: billingLimit || limit,
    }
  );
  const d = data!;

  return billingLimit
    ? {
        ...d,
        payments: [
          ...d.payments,
          ...Array.from(
            {
              length: limit - d.payments.length || 0,
            },
            (_, index) => ({
              ...d.payments[d.payments.length - 1],
              id: d.payments[d.payments.length - 1]?.id || `${index}`,
            })
          ),
        ],
      }
    : d;
}
*/
