import { usePriceByProperties } from '@finalytic/data-ui';
import { Price, useColors } from '@finalytic/ui';
import { formatCurrency } from '@finalytic/utils';
import { faBadgeCheck, faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Badge,
  Box,
  Group,
  HoverCard,
  List,
  Skeleton,
  Text,
  TextProps,
  Transition,
  useMantineTheme,
} from '@mantine/core';
import { ReactNode } from 'react';

export type OnboardingPrice = {
  productId: string | undefined;
  priceId: string;
  title: string;
  unit_label: string | undefined | null;
  unit_amount: number;
  currency: string;
  features: string[];
  isFeatured: boolean;
  icon: string;
  tiers: {
    up_to: number;
    unit_amount: number;
  }[];
};

export type SimplePricingTableProps = {
  prices: OnboardingPrice[];
  onPriceSelect: (price: OnboardingPrice) => void;
  activePriceId: string | undefined;
  properties: number;
};

export const SimplePricingTable = ({
  prices,
  onPriceSelect,
  activePriceId,
  properties,
}: SimplePricingTableProps) => {
  return (
    <>
      <Transition mounted={prices.length > 0} transition='fade' duration={800}>
        {(styles) => (
          <Group
            py='lg'
            spacing={10}
            position='center'
            align='center'
            sx={{ alignItems: 'flex-start' }}
            noWrap={prices.length < 1}
            mr={prices.length < 1 ? 'lg' : undefined}
            style={styles}
          >
            {prices.map((product) => (
              <PriceColumn
                key={product.productId}
                onClick={onPriceSelect}
                {...product}
                isActive={activePriceId === product.priceId}
                properties={properties}
              />
            ))}
          </Group>
        )}
      </Transition>

      {prices.length < 1 && (
        <Group
          py='lg'
          spacing={10}
          position='center'
          align='center'
          sx={{ alignItems: 'flex-start' }}
          noWrap
          mr={'lg'}
        >
          <Skeleton w='100%' h={340} />
          <Skeleton w='100%' h={340} />
          <Skeleton w='100%' h={340} />
        </Group>
      )}
    </>
  );
};

type PriceColumnProps = OnboardingPrice & {
  onClick: SimplePricingTableProps['onPriceSelect'];
  isActive: boolean;
  properties: number;
};

const PriceColumn = (price: PriceColumnProps) => {
  const {
    title,
    unit_label,
    unit_amount,
    currency,
    features,
    isFeatured,
    isActive,
    icon,
    tiers,
  } = price;
  const { border } = useColors();

  const { primaryColor, colors } = useMantineTheme();

  const amount = usePriceByProperties(price.properties, tiers);

  return (
    <Box
      w={210}
      p={10}
      mih={340}
      onClick={() => price.onClick(price)}
      sx={(theme) => ({
        border: '1px solid',
        cursor: 'pointer',
        borderColor: isActive ? colors[primaryColor][6] : border.gray,
        borderRadius: theme.radius.lg,
        // backgroundColor: isActive ? theme.colors.gray[1] : undefined,
        transition: 'all 0.1s ease-in-out',
        '&:hover': {
          backgroundColor: !isActive ? theme.colors.gray[0] : undefined,
        },
      })}
    >
      <Group spacing={0} sx={{ minHeight: 70, alignItems: 'flex-start' }}>
        <Avatar src={icon} alt={title} size={25} mr={10} />
        <Text
          size={'md'}
          weight={500}
          color={colors[primaryColor][6]}
          sx={{ flexGrow: 1 }}
        >
          {title}
        </Text>
        {isFeatured && (
          <Badge
            color='dark'
            mb={-3}
            ml={3}
            display='inline-block'
            radius='md'
            px={6}
            sx={{
              fontWeight: 500,
              textTransform: 'none',
              '@keyframes shimmer': {
                '100%': { webkitMaskPosition: 'left' },
              },
              WebkitMask:
                'linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%',
              backgroundRepeat: 'no-repeat',
              animation: 'shimmer 2.5s infinite',
            }}
          >
            Most popular
          </Badge>
        )}
      </Group>
      {/* <SmallText mb={-10}>Starting at</SmallText> */}
      <Box mb={15}>
        <Text component='h3' my={0} sx={{ fontSize: '2.3rem' }} weight={700}>
          {formatCurrency(
            amount / 100,
            currency,
            undefined,
            (amount / 100) % 1 ? undefined : 0
          )}
        </Text>
        <Box mt={-5} mb={8}>
          <SmallText mb={-3}>per {unit_label}/month</SmallText>
        </Box>
      </Box>

      <DescriptionText mb={5}>This includes:</DescriptionText>
      <List icon={<FontAwesomeIcon icon={faBadgeCheck} color='gray' />}>
        {features.map((feature, index) => {
          return (
            <List.Item key={index} sx={{ fontSize: 14 }}>
              {feature}
            </List.Item>
          );
        })}
      </List>
    </Box>
  );
};

const PriceHoverCard = ({
  tiers,
  currency,
  unit_label,
}: {
  tiers: Price['tiers'];
  unit_label: Price['unit_label'];
  currency: Price['currency'];
}) => {
  return (
    <HoverCard
      width={280}
      shadow='md'
      disabled={tiers?.length < 1}
      withinPortal
      withArrow
      position='top'
    >
      <HoverCard.Target>
        <Group
          spacing={8}
          mb='lg'
          noWrap
          sx={{
            textDecoration: 'underline',
            textDecorationStyle: 'dotted',
            textUnderlineOffset: '3px',
          }}
        >
          <FontAwesomeIcon icon={faEye} color='gray' size='xs' />
          <SmallText>Pricing Preview</SmallText>
        </Group>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <List sx={{ maxHeight: 300, overflowY: 'scroll' }} listStyleType='none'>
          {tiers?.map((tier, index, arr) => {
            const prev = arr[index - 1];
            return (
              <List.Item key={index} sx={{ fontSize: 13 }}>
                {prev ? 'Next' : 'First'}{' '}
                {tier.up_to ? tier.up_to - (prev?.up_to || 0) : <>&#8734;</>}:{' '}
                {formatCurrency(tier.unit_amount / 100, currency)} per{' '}
                {unit_label}
              </List.Item>
            );
          })}
        </List>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const SmallText = ({
  children,
  ...props
}: { children: ReactNode } & TextProps) => {
  return (
    <Text component='p' m={0} size={13} color={'dark'} opacity={0.6} {...props}>
      {children}
    </Text>
  );
};

const DescriptionText = ({
  children,
  ...props
}: { children: ReactNode } & TextProps) => {
  return (
    <Text component='p' m={0} size={14} color={'dark'} {...props}>
      {children}
    </Text>
  );
};
