import { faCheck, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  PasswordInput,
  PasswordInputProps,
  Popover,
  Progress,
  Text,
} from '@mantine/core';
import { ChangeEventHandler, useState } from 'react';

const minPasswordLength = 8;

type InputPasswordProps = {
  withPopover?: boolean;
  name: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const InputPassword = ({
  withPopover = true,
  value = '',
  onChange,
  name,
  ...props
}: InputPasswordProps & PasswordInputProps) => {
  const [requirementsOpen, setRequirementsOpen] = useState(false);

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.message}
      meets={requirement.value.test(value)}
    />
  ));

  const strength = getStrength(value);
  const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

  const inputPadding = '20px 16px';

  return (
    <Popover
      opened={withPopover ? requirementsOpen : false}
      position='bottom-start'
      radius={10}
      withArrow
      styles={{
        dropdown: { maxWidth: '100%', width: 360 },
      }}
      trapFocus={false}
      transitionProps={{ transition: 'pop-top-left' }}
      width='target'
    >
      <Popover.Target>
        <div
          onFocusCapture={() => setRequirementsOpen(true)}
          onBlurCapture={() => setRequirementsOpen(false)}
        >
          <PasswordInput
            radius={10}
            id={name}
            value={value}
            name={name}
            onChange={onChange}
            styles={(theme) => ({
              innerInput: {
                padding: inputPadding,
              },
              input: {
                padding: inputPadding,
                fontSize: '13px',
                borderRadius: 8,
                borderColor: props.error ? theme.colors.red[4] : undefined,
                '& ::placeholder, & ::-webkit-input-placeholder': {
                  color: '#BEC0C9',
                  fontSize: 13,
                  fontWeight: 500,
                },
                '&:focus-within': {
                  border: '1px solid #BEC0C9 !important',
                  boxShadow: '0px 0px 0px 1px #BEC0C9',
                },
              },
            })}
            {...props}
            error={undefined}
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Progress
          color={color}
          value={strength}
          size={5}
          style={{ marginBottom: 10 }}
        />
        <PasswordRequirement
          label='Includes at least 8 characters'
          meets={value.length > minPasswordLength - 1}
        />
        {checks}
      </Popover.Dropdown>
    </Popover>
  );
};

function PasswordRequirement({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) {
  return (
    <Text
      color={meets ? 'teal' : 'red'}
      sx={{ display: 'flex', alignItems: 'center' }}
      mt={7}
      size='sm'
    >
      {meets ? (
        <FontAwesomeIcon icon={faCheck} size={'lg'} />
      ) : (
        <FontAwesomeIcon icon={faX} size={'lg'} />
      )}{' '}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d$&+,:;=?@#|'<>.^*()%!-]{8,}/;

const requirements = [
  { value: /[0-9]/, message: 'Includes number' },
  { value: /[a-z]/, message: 'Includes lowercase letter' },
  { value: /[A-Z]/, message: 'Includes uppercase letter' },
  // { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' },
];

function getStrength(password: string) {
  let multiplier = password.length > minPasswordLength - 1 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.value.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}
