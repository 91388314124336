import { useListingWasUpdated } from '../../useListingWasUpdated';
import { TableTitle } from '../TableTitle';
import { useListingSettingMutation, useListingSettings } from './_hooks';
import { FormValues } from './_types';
import { ActionButton, Button } from '@finalytic/ui';
import {
  Collapse,
  Divider,
  Group,
  ScrollArea,
  Stack,
  Switch,
} from '@mantine/core';
import { useEffect } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';

type Props = {
  listingId: string;
  listingName: string | undefined;
  listingTeamId: string;
};

type ListingSettingsData = ReturnType<typeof useListingSettings>;

export const ListingSettings = (props: Props) => {
  const query = useListingSettings(props.listingId);

  return <SettingsForm {...props} {...query} />;
};

const SettingsForm = ({
  automationExcludedSettings,
  listingId,
  listingName,
  listingTeamId,
  listingStatus,
  automations,
  loading: loadingQuery,
  refetch,
}: Props & ListingSettingsData) => {
  const methods = useForm<FormValues>();
  const opened = methods.watch('status');
  const { setListingEdited } = useListingWasUpdated();

  const { loading: loadingMutation, mutate } = useListingSettingMutation({
    listingName,
  });

  const resetForm = () => {
    const disabledAutomations: FormValues['automations'] = {};
    automationExcludedSettings.forEach(({ automationId, checked }) => {
      disabledAutomations[automationId] = checked;
    });

    methods.reset(
      {
        status: listingStatus === 'disabled' ? false : true,
        automations: disabledAutomations,
      },
      {
        keepDirty: false,
      }
    );
  };

  useEffect(() => {
    resetForm();
  }, [automationExcludedSettings, listingStatus, automations]);

  const loading = loadingQuery || loadingMutation;

  if (!automations.length) return null;

  return (
    <FormProvider {...methods}>
      <Group mt={5} mb={5} position='apart'>
        <TableTitle mt='lg' mb={5}>
          Automation Settings
        </TableTitle>
        <SubmitButtons
          resetForm={resetForm}
          saveSettings={(values) =>
            mutate({
              args: {
                formValue: values,
                automationSettings: automationExcludedSettings,
                listingId,
                listingTeamId,
              },
            }).then(() => {
              setListingEdited(true);
              refetch();
            })
          }
        />
      </Group>
      <ScrollArea
        mah={300}
        p={'md'}
        sx={(theme) => ({
          backgroundColor: theme.colors.gray[2],
          borderRadius: theme.radius.md,
          overflowY: 'scroll',
        })}
      >
        <Controller
          control={methods.control}
          name='status'
          render={({ field }) => (
            <SettingSwitch
              disabled={loading}
              label='Enable automations for this listing?'
              labelBold
              checked={field.value}
              onChange={(event) => field.onChange(event.currentTarget.checked)}
            />
          )}
        />
        <Collapse in={opened} transitionDuration={1000}>
          <Divider my={'md'} />
          <Stack>
            {automations.map((automation) => {
              return (
                <Controller
                  key={automation.automationId}
                  defaultValue={true}
                  name={`automations.${automation.automationId}`}
                  render={({ field }) => {
                    return (
                      <SettingSwitch
                        disabled={loading}
                        key={automation.automationId}
                        label={automation.title || ''}
                        checked={field.value}
                        onChange={(event) =>
                          field.onChange(event.currentTarget.checked)
                        }
                      />
                    );
                  }}
                />
              );
            })}
          </Stack>
        </Collapse>
      </ScrollArea>
    </FormProvider>
  );
};

const SubmitButtons = ({
  resetForm,
  saveSettings,
}: {
  resetForm: () => void;
  saveSettings: (values: FormValues) => Promise<any>;
}) => {
  const { handleSubmit, formState } = useFormContext<FormValues>();

  const submit = (values: FormValues) => saveSettings(values);

  return (
    <Group>
      <Button disabled={!formState.isDirty} onClick={resetForm}>
        Cancel
      </Button>
      <ActionButton
        onClick={handleSubmit(submit)}
        loading={formState.isSubmitting}
        disabled={!formState.isDirty}
      >
        Save settings
      </ActionButton>
    </Group>
  );
};

const SettingSwitch = ({
  checked,
  label,
  onChange,
  labelBold,
  disabled,
}: {
  label: string;
  checked: boolean;
  labelBold?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  disabled: boolean;
}) => {
  return (
    <Switch
      label={label}
      labelPosition='left'
      color='teal'
      checked={checked}
      disabled={disabled}
      onChange={(event) => onChange(event)}
      styles={(theme) => ({
        root: { flex: 1, display: 'flex' },
        body: { justifyContent: 'space-between', flex: 1 },
        labelWrapper: { flex: 1 },
        label: labelBold ? { fontWeight: 500 } : undefined,
        track: {
          backgroundColor: theme.colors.gray[4],
          borderColor: theme.colors.gray[4],
        },
      })}
    />
  );
};
