import { ExtendedCalendar, ExtendedCalendarProps } from '../../calendar';
import { FilterPopover, PopoverButton, PopoverHeader } from '../_components';
import { DateIcon } from '../_icons';
import { useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

type DateFilterProps = {
  defaulButtonLabel: string;
  alwaysShowDefaultButtonLabel?: boolean;
};

export const DateFilter = ({
  defaulButtonLabel,
  filterValue: dateValue,
  setFilterValue: setDateValue,
  dateType,
  setDateType,
  alwaysShowDefaultButtonLabel,
}: DateFilterProps & ExtendedCalendarProps) => {
  const [opened, handlers] = useDisclosure(false);
  const [buttonLabel, setButtonLabel] = useState('');

  const filterIsActive = !!(Array.isArray(dateValue) && dateValue[0]);

  useEffect(() => {
    if (filterIsActive) {
      const first = dayjs(dateValue[0]);
      const second = dateValue[1] && dayjs(dateValue[1]);
      const firstDateFormat =
        first.year() === second?.year() ? 'MMM DD' : 'MMM DD YYYY';
      const firstDate = first.format(firstDateFormat);
      const secondDate = second?.format('MMM DD YYYY');
      setButtonLabel(
        `${firstDate}${[secondDate && ' - ', secondDate]
          .filter(Boolean)
          .join('')}`
      );
    } else {
      setButtonLabel('');
    }
  }, [dateValue]);

  const onClosePopover = () => {
    handlers.close();
  };

  const resetFilter = () => {
    handlers.close();
    setDateValue(undefined);
    if (setDateType) {
      setDateType('all');
    }
  };

  return (
    <FilterPopover
      opened={opened}
      onClose={onClosePopover}
      target={
        <PopoverButton
          onClick={handlers.toggle}
          title={
            !alwaysShowDefaultButtonLabel
              ? buttonLabel || defaulButtonLabel
              : defaulButtonLabel
          }
          Icon={DateIcon}
          isActive={filterIsActive}
        />
      }
    >
      <PopoverHeader
        title={defaulButtonLabel}
        handleFilterReset={resetFilter}
      />

      <ExtendedCalendar
        setFilterValue={setDateValue}
        dateType={dateType}
        filterValue={dateValue}
        setDateType={setDateType}
      />
    </FilterPopover>
  );
};
