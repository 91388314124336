import { GRAPHQL_URI, PLATFORM } from '../../../env';
import { createUUID } from '../../../utils';
import { useSpotlightContext } from '../../spotlight';
import { useExtension } from '../../useExtension';
import { useClerk } from '@finalytic/authentication';
import { createClientV2, wrapGraphQLClient } from '@finalytic/graphql';
import { showErrorNotification } from '@finalytic/ui';
import { hashString } from '@finalytic/utils';
import { faCheck, faWifiSlash, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  hideNotification,
  showNotification,
  updateNotification,
} from '@mantine/notifications';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import * as Sentry from '@sentry/react';

import type * as gqlV2 from '@finalytic/graphql';
import type { GeneratedSchema } from '@finalytic/graphql';
import { useNetwork } from '@mantine/hooks';
import type { GQtyError } from 'gqty';
import React, {
  createContext,
  createElement,
  useContext,
  useEffect,
  useRef,
} from 'react';

export type { gqlV2, GeneratedSchema, GQtyError };

export type Client = {
  subscribe: ReturnType<typeof createClientV2>['subscribe'];
  client: gqlV2.GQtyClient<gqlV2.GeneratedSchema>;
  wrapped: ReturnType<typeof wrapGraphQLClient>;
};
const context = createContext<Client>({
  client: undefined,
  subscribe: undefined,
  wrapped: undefined,
} as any);

const clientFetcher = (url: string, params: RequestInit) => {
  if (params.body && typeof params.body === 'string') {
    const operation = params.body.includes('"query":"mutation')
      ? 'mutation'
      : 'query';

    // Remove duplicates and get all query keys
    const queryKeys = [
      ...new Set(
        [...params.body.matchAll(/{[a-zA-Z]+_/g)].map((i) => i[0].slice(1, -1))
      ),
    ]
      .filter((i) => !['count'].includes(i))
      .map((key) => `&queryKey=${key}`)
      .join('');

    url = `${url}?operation=${operation}${queryKeys}`;
  }

  return fetch(url, params);
};

export function ExtensionClientProvider({
  children,
  token,
}: {
  children: React.ReactNode;
  token: string;
}) {
  const ref = useRef<Client>();
  if (!ref.current) {
    async function getToken() {
      const headers: { [s: string]: string } = {
        authorization: `Bearer ${token}`,
      };
      return headers;
    }
    const { client, subscribe } = createClientV2(GRAPHQL_URI, {
      headers: async () => await getToken(),
      cache: false,
      normalization: false,
      subscriptions: true,
      fetch: clientFetcher,
    });

    ref.current = {
      subscribe,
      client,
      wrapped: wrapGraphQLClient(client, client as any),
    };
  }

  return createElement(context.Provider, { value: ref.current }, children);
}

export function ClientV2Provider({ children }: { children: React.ReactNode }) {
  const auth = useClerk();
  const spotlight = useSpotlightContext();
  const ref = useRef<Client>();
  const { sendMessage } = useExtension();
  if (!ref.current) {
    async function getToken() {
      const accessToken = await auth.session?.getToken({
        template: 'Hasura',
      });
      // For webextension
      sendMessage({ message: 'token', data: { token: accessToken } });
      if (accessToken) localStorage.setItem('at', accessToken);
      else localStorage.removeItem('at');

      const headers: { [s: string]: string } = {
        authorization: `Bearer ${accessToken}`,
      };
      if (spotlight.current.hypervisorQueue)
        headers['Finalytic-Hypervisor-Queue'] =
          spotlight.current.hypervisorQueue;
      if (spotlight.current.integrationQueue)
        headers['Finalytic-Integration-Queue'] =
          spotlight.current.integrationQueue;
      if (PLATFORM) headers['Finalytic-Platform'] = PLATFORM;
      return headers;
    }
    const { client, subscribe } = createClientV2(GRAPHQL_URI, {
      headers: async () => await getToken(),
      cache: false,
      normalization: false,
      subscriptions: true,
      fetch: clientFetcher,
    });

    ref.current = {
      subscribe,
      client,
      wrapped: wrapGraphQLClient(client, client as any),
    };
  }

  return createElement(context.Provider, { value: ref.current }, children);
}

export type V2MutationOptions<TData, TArgs> = {
  onError?: (error: any) => void;
  onCompleted?: (data: TData) => void;
  invalidateQueryKeys?: QueryKeyUnion[];
};

export function useV2Mutation<TData, TArgs>(
  fn: (mutation: gqlV2.Mutation, vars: TArgs) => TData,
  options?: V2MutationOptions<TData, TArgs>
) {
  const { client } = useContext(context);

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error, data } = useMutation(
    async (mutationOptions: { args: TArgs }) => {
      const data = await client.resolved<TData>(
        () => fn(client.mutation, mutationOptions.args),
        {
          noCache: true,
        }
      );

      if (options?.invalidateQueryKeys) {
        options.invalidateQueryKeys.forEach((key) => {
          queryClient.invalidateQueries({ queryKey: [key] });
        });
        queryClient.invalidateQueries({ queryKey: ['default'] });
      }

      return data;
    },
    {
      onSuccess: options?.onCompleted,
      onError: options?.onError,
    }
  );
  return [mutateAsync, { isLoading, error: error as any, data }] as const;
}

export function useInvalidateQueries(
  invalidateQueryKeys?: QueryKeyUnion[] | true | undefined
) {
  const queryClient = useQueryClient();

  return () => {
    if (!invalidateQueryKeys) return;
    if (invalidateQueryKeys) {
      if (Array.isArray(invalidateQueryKeys))
        invalidateQueryKeys.forEach((key) => {
          queryClient.invalidateQueries({ queryKey: [key] });
        });
      queryClient.invalidateQueries({ queryKey: ['default'] });
    }
  };
}

export const useNotifiedV2Mutation = <TData, TArgs>(
  fn: (mutation: gqlV2.Mutation, vars: TArgs) => TData,
  options?: V2MutationOptions<TData, TArgs> & {
    successMessage?: {
      id?: string;
      message?: string;
      title?: string;
    };
    errorMessage?: {
      title?: string;
      message?: string;
    };
  }
) => {
  const notifyRef = useRef(createUUID());

  const notifyId = options?.successMessage?.id || notifyRef.current;

  const [mutate, { isLoading, error }] = useV2Mutation(fn, {
    ...options,
    onCompleted: (data) => {
      if (options?.onCompleted) options.onCompleted(data);

      if (options?.successMessage) {
        updateNotification({
          id: notifyId,
          message:
            options?.successMessage?.message ||
            'Sucessfully updated your action.',
          title: options?.successMessage?.title || 'Success!',
          color: 'teal',
          icon: <FontAwesomeIcon icon={faCheck} />,
          radius: 10,
        });
      }
    },
    onError: (error) => {
      if (options?.successMessage) {
        updateNotification({
          id: notifyId,
          title: options?.errorMessage?.title || error.name,
          message: options?.errorMessage?.message || error.message,
          color: 'red',
          icon: <FontAwesomeIcon icon={faX} size='sm' />,
          radius: 10,
        });
      } else {
        showErrorNotification({
          title: options?.errorMessage?.title || error.name,
          message: options?.errorMessage?.message || error.message,
        });
      }
    },
  });

  const m: typeof mutate = async (...opts) => {
    if (options?.successMessage) {
      showNotification({
        id: notifyId,
        loading: true,
        title: 'Loading...',
        message: 'We will update you shortly.',
        autoClose: false,
        radius: 10,
      });
    }

    const result = await mutate(...opts);
    return result;
  };

  return { mutate: m, loading: isLoading, error };
};

export const useV2TransactionSubscription = <TData, TVariables = undefined>(
  fn: (sub: gqlV2.Subscription, variables: TVariables) => TData,
  variables?: TVariables,
  options?: {
    skip?: boolean;
    queryKey?: QueryKeyUnion | QueryKeyUnion[];
  }
): {
  data: TData | undefined;
  error?: any;
  isLoading: boolean;
  isCalled: boolean;
} => {
  const skip: any = options?.skip || false;
  const errorRef = useRef<any>(undefined);
  const { client } = useContext(context);
  const uniqueKey = useRef(0);
  if (!uniqueKey.current) uniqueKey.current = hashString(fn.toString());
  const queryKey = [uniqueKey.current, variables];

  const queryClient = useQueryClient();

  const { subscribe } = useContext(context);

  useEffect(() => {
    const unsubscribe = subscribe(
      (s) => fn(s, variables!),
      (data) => {
        errorRef.current = undefined;
        queryClient.setQueryData(queryKey, () => data);
      },
      (error) => {
        errorRef.current = error;
        // queryClient.setQueryData(queryKey, undefined);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [uniqueKey.current, JSON.stringify(variables)]);

  const { isLoading, data, error } = useQuery(
    queryKey,
    () => client.resolved(() => fn(client.query as any, variables!), {}),
    {
      enabled: !skip,
    }
  );

  return {
    isLoading,
    data: data as any,
    error: errorRef.current || error,
    isCalled: true,
  };
};

export type QueryKeyUnion =
  | 'ownerStatements'
  | 'ownerStatementTemplates'
  | 'settings'
  | 'automations'
  | 'automationTemplates'
  | 'apps'
  | 'connections'
  | 'tenantUsers'
  | 'teams'
  | 'users'
  | 'sources'
  | 'paymentLineClassifications'
  | 'paymentLines'
  | 'listings'
  | 'listingOwners'
  | 'listingConnections'
  | 'reservations'
  | 'payments'
  | 'tasks'
  | 'auditLogs'
  | 'files'
  | 'scheduledEvents'
  | 'owners';

export const useV2TransactionQuery = <TData, TVariables = undefined>(
  fn: (query: GeneratedSchema['query'], variables: TVariables) => TData,
  options?: {
    variables?: TVariables;
    skip?: boolean;
    queryKey?: QueryKeyUnion | QueryKeyUnion[];
  }
) => {
  const uniqueKey = useRef('');
  const networkStatus = useNetwork();

  if (!uniqueKey.current) uniqueKey.current = fn.toString();

  const errorRef = useRef<Error | undefined>(undefined);

  const { client } = useContext(context);
  const variables: any = options?.variables || {};
  const skip: any = options?.skip || false;

  const offlineNotificationId = 'offline-query';
  const showOfflineNotification = () =>
    showErrorNotification({
      color: 'yellow',
      id: offlineNotificationId,
      title: 'Network Offline',
      message:
        'You are currently offline. Please check your internet connection.',
      autoClose: false,
      icon: <FontAwesomeIcon icon={faWifiSlash} />,
    });

  const queryKeys = Array.isArray(options?.queryKey)
    ? options?.queryKey || []
    : options?.queryKey
    ? [options.queryKey]
    : [];

  const { isFetching, data, error, refetch } = useQuery(
    [...queryKeys, 'global', uniqueKey.current, variables],
    // () => client.resolved(() => fn(client.query, variables), {}),
    () =>
      client
        .resolved(() => fn(client.query, variables))
        .catch((error: GQtyError) => {
          errorRef.current = error;
          return undefined;
        }),
    {
      enabled: !skip && !!networkStatus.online,
      onError: (error: Error) => {
        console.error(error);

        // don't log error messages if user is offline.
        if (!networkStatus.online) return;

        let message = error?.message || 'Missing error.';

        if (message.includes('data is undefined'))
          message = message.replace(/\[.*\]/, '');

        Sentry.captureException(new Error(message));

        showErrorNotification({
          title: 'Query Error',
          message,
          autoClose: false,
        });
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!networkStatus.online) showOfflineNotification();
    else hideNotification(offlineNotificationId);
  }, [networkStatus.online]);

  return {
    data,
    error: errorRef.current || error,
    refetch,
    isLoading: isFetching,
    isCalled: true,
  };
};

export function useV2Client() {
  const { wrapped } = useContext(context);
  return wrapped;
}
