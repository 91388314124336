import { LeftSideNameCell } from '../../_cells';
import { LeftSideTooltip } from '../../_cells/LeftSideTooltip';
import { useEditAutomationContext } from '../../_context';
import { useAutomationChangeStore } from '../../_hooks';
import { useTabEditorType } from '../../_hooks/useTabEditorType';
import { MappingModal } from '../../_modals';
import {
  arrowColumn,
  defaultNameColumn,
  deleteRowColumn,
  useDefaultValueColumn,
} from '../_default-columns';
import {
  ChildMappingRow,
  MappingRow,
  MappingTableContext,
} from '../_table-types';
import {
  MappingDatasourceParams,
  rowsPerPage,
  useMappingsDatasource,
} from './useMappingsDatasource';
import { useMe } from '@finalytic/data-ui';
import {
  EllipsisMenu,
  EllipsisMenuItem,
  Table,
  showErrorNotification,
  showSuccessNotification,
} from '@finalytic/ui';
import {
  AgGridReact,
  ColDef,
  GetDetailRowDataParams,
  GetRowIdParams,
  GridOptions,
  ICellRendererParams,
  NewValueParams,
} from '@finalytic/ui-grid';
import { ensure } from '@finalytic/utils';
import { faCopy, faPlus, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { memo, useMemo, useRef, useState } from 'react';

type MappingTableProps = MappingDatasourceParams;

export const MappingTable = memo(({ settingKey }: MappingTableProps) => {
  const gridRef = useRef<AgGridReact | null>(null);

  const { isAdmin } = useMe();
  const { automation } = useEditAutomationContext();
  const [activeRowData, setActiveRowData] = useState<MappingRow | undefined>(
    undefined
  );
  const [activeExceptions, setActiveExceptions] = useState<ChildMappingRow[]>(
    []
  );

  const [opened, handlers] = useDisclosure(false);

  const addChanges = useAutomationChangeStore((state) => state.add);
  const filter = useAutomationChangeStore((state) => state.filter);

  const search =
    (filter[automation.id] && filter[automation.id][settingKey]?.search) || '';
  const unmappedOnly =
    (filter[automation.id] &&
      filter[automation.id][settingKey]?.unmappedOnly) ||
    false;
  const { dataSource } = useMappingsDatasource({
    settingKey,
    search,
    unmappedOnly,
  });

  const { rightEditorType } = useTabEditorType(settingKey);
  const valueColumn = useDefaultValueColumn({
    editorType: rightEditorType,
    settingKey,
  });

  const colDefs = useMemo<ColDef[]>(() => {
    return [
      {
        ...defaultNameColumn,
        cellRenderer: 'agGroupCellRenderer',
      },
      arrowColumn,
      {
        ...valueColumn,
        onCellValueChanged: (params: NewValueParams<MappingRow>) => {
          addChanges(
            {
              ...params.data,
              value: params.newValue,
            },
            {
              isGlobalSetting: false,
              settingKey,
              automationId: automation.id,
            }
          );
        },
      },
      {
        field: 'childSettings',
        colId: 'childSettings',
        type: 'rightAligned',
        sortable: false,
        cellStyle: { display: 'grid', placeContent: 'center' } as any,
        maxWidth: 50,
        width: 50,
        pinned: 'right',
        cellRenderer: (params: ICellRendererParams<MappingRow>) => {
          if (!params.data?.value) return null;

          const openModal = () => openLineExceptionModal(params.data!);

          const removeValue = () => {
            params.node.setDataValue('value', null);
            params.api.refreshCells({
              rowNodes: [params.node],
              columns: ['childSettings'],
              force: true,
            });
          };

          return (
            <EllipsisMenu>
              <EllipsisMenuItem icon={faPlus} onClick={openModal}>
                Add Exception
              </EllipsisMenuItem>
              {params.value && params.value.length < 1 && (
                <EllipsisMenuItem icon={faTrashAlt} onClick={removeValue}>
                  Remove
                </EllipsisMenuItem>
              )}
              {isAdmin ? (
                <EllipsisMenuItem
                  icon={faCopy}
                  onClick={() =>
                    navigator.clipboard
                      .writeText(`${params.data?.settingId}`)
                      .then(() =>
                        showSuccessNotification({
                          message:
                            'The setting ID was added to your clipboard.',
                        })
                      )
                  }
                >
                  Copy ID
                </EllipsisMenuItem>
              ) : null}
            </EllipsisMenu>
          );
        },
      },
    ];
  }, [valueColumn, rightEditorType, settingKey, automation.id]);

  const detailCellRendererParams = {
    detailGridOptions: {
      getRowId: (params: GetRowIdParams<MappingRow>) => params.data.target,
      onCellKeyPress: (params: any) => {
        if (
          params.column?.colId === 'delete' &&
          (params.event?.code === 'Enter' || params.event?.code === 'Space')
        ) {
          params.node?.setDataValue('value', undefined);
        }
      },
      pagination: false,
      headerHeight: 0,
      tooltipShowDelay: 0,
      tooltipHideDelay: 1000,
      context: ensure<MappingTableContext>({ settingKey }),
      columnDefs: [
        {
          field: 'target',
          flex: 1,
          minWidth: 100,
          cellRenderer: LeftSideNameCell,
          cellStyle: { paddingLeft: 70 },
          tooltipField: 'target',
          tooltipComponent: LeftSideTooltip,
        },
        arrowColumn,
        {
          ...valueColumn,
          minWidth: 200,
          onCellValueChanged: (params: NewValueParams<ChildMappingRow>) => {
            const parentGridApi = gridRef.current?.api;

            if (!params.data.parentRowTypeId) {
              showErrorNotification({ message: 'Missing parent row.' });
              return;
            }

            const parentRow: MappingRow = parentGridApi?.getRowNode(
              params.data.parentRowTypeId
            )?.data;

            const childMappings: ChildMappingRow[] = [];
            params.api.forEachNode((node) => {
              if (node?.data) {
                childMappings.push(node.data);
              }
            });

            const index = childMappings.findIndex(
              (i) => i.target === params.data.target
            );
            childMappings[index].value = params.newValue;

            addChanges(
              { ...parentRow, childSettings: childMappings },
              {
                isGlobalSetting: false,
                settingKey,
                automationId: automation.id,
              }
            );

            if (!params.newValue) parentGridApi?.refreshServerSide();
          },
        },
        deleteRowColumn,
      ] as ColDef[],
      className: 'ag-line-exception-details',
      defaultColDef: {
        sortable: false,
      },
    } as GridOptions,
    getDetailRowData: (params: GetDetailRowDataParams) => {
      params.successCallback(params.data.childSettings);
    },
  };

  const openLineExceptionModal = (data: MappingRow) => {
    setActiveRowData(data);
    setActiveExceptions(data.childSettings);
    handlers.open();
  };

  const onLineExecptionSubmit = () => gridRef.current?.api?.refreshServerSide();

  return (
    <>
      <Box sx={{ height: 600 }}>
        <Table
          stopEditingWhenCellsLoseFocus={false}
          ref={gridRef}
          animateRows
          headerHeight={0}
          rowModelType='serverSide'
          serverSideInfiniteScroll
          cacheBlockSize={rowsPerPage}
          paginationPageSize={rowsPerPage}
          serverSideDatasource={dataSource}
          columnDefs={colDefs}
          getRowId={(params: GetRowIdParams<MappingRow>) => params.data?.target}
          isServerSideGroupOpenByDefault={() => true}
          context={ensure<MappingTableContext>({ settingKey })}
          noRowsOverlayComponent={() => 'No Mappings Found'}
          tooltipShowDelay={0}
          tooltipHideDelay={1000}
          alwaysShowVerticalScroll
          gridOptions={{
            rowStyle: {
              overflow: 'visible',
            },
            detailRowAutoHeight: true,
            isRowMaster: (dataItem) => {
              return dataItem.childSettings
                ? dataItem.childSettings.length > 0
                : false;
            },
            // Trigger onClick for modal column
            // onCellKeyPress: (params: any) => {
            //   if (
            //     params.column?.colId === 'childSettings' &&
            //     (params.event?.code === 'Enter' ||
            //       params.event?.code === 'Space')
            //   ) {
            //     params.node.setDataValue('menuOpen', !params.data?.menuOpen);
            //     params.api.refreshCells({
            //       rowNodes: [params.node],
            //       columns: ['childSettings'],
            //       force: true,
            //     });
            //   }
            // },
          }}
          masterDetail={true}
          // suppressContextMenu
          detailCellRendererParams={detailCellRendererParams}
        />

        <MappingModal
          opened={opened}
          closeModal={handlers.close}
          onSubmit={onLineExecptionSubmit}
          exceptions={activeExceptions}
          parentRow={activeRowData}
          settingKey={settingKey}
        />
      </Box>
    </>
  );
});

MappingTable.displayName = 'Mapping Table';
