import { useColors } from '../../styles';
import { IconButton } from '../buttons';
import { faAnglesRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIconProps, Center, Sx } from '@mantine/core';

type InfoButtonCellProps = {
  onIconClick?: () => void;
  withoutButton?: boolean;
  sx?: ((Sx | (Sx | undefined)[]) & Sx) | undefined;
};

export const InfoButtonCell = ({
  onIconClick,
  withoutButton = false,
  ...props
}: Partial<ActionIconProps> & InfoButtonCellProps) => {
  const { black, gray } = useColors();

  if (withoutButton)
    return (
      <Center sx={{ height: '100%' }}>
        <FontAwesomeIcon icon={faAnglesRight} color={black.base} />
      </Center>
    );

  return (
    <IconButton
      radius={100}
      size={25}
      onClick={onIconClick}
      sx={{ ':hover': { backgroundColor: gray.dark + 30 } }}
      {...props}
    >
      <Center>
        <FontAwesomeIcon icon={faAnglesRight} size='sm' color={black.base} />
      </Center>
    </IconButton>
  );
};
