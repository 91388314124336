import { useAutomationChangeStore } from './useAutomationChangeStore';
import {
  getNamespaceAndType,
  useNotifiedV2Mutation,
  useTeam,
} from '@finalytic/data-ui';
import { showErrorNotification } from '@finalytic/ui';
import { expression } from '@finalytic/utils';

export type UpsertSetting = {
  settingId?: string;
  leftConnectionId: string;
  rightConnectionId: string;
  leftType: string;
  rightType: string;
  value: string;
  target: string;
  key: string;
  parentSettingId: string | undefined;
  childrenSettings: UpsertSetting[] | undefined;
};

type Args = {
  removals: string[]; // setting.id[]
  upserts: UpsertSetting[];
  automationId: string;
};

export const useAutomationSettingMutation = () => {
  const [{ id: teamId, finalyticConnectionId }] = useTeam();

  const getChanges = useAutomationChangeStore((store) => store.getChanges);
  const resetChanges = useAutomationChangeStore((store) => store.reset);

  const { mutate, loading } = useNotifiedV2Mutation(
    (q, args: Args & { teamId: string; finalyticConnectionId: string }) => {
      const upserts = q.insert_setting({
        on_conflict: {
          constraint: 'setting_pkey',
          update_columns: [
            'value',
            'target',
            'leftType',
            'rightType',
            'leftConnectionId',
            'rightConnectionId',
            'automationId',
          ],
        },
        objects: args.upserts.map((setting) => ({
          id: setting.settingId,
          automationId: args.automationId,
          group: setting.leftType,
          leftType: setting.leftType,
          rightType: setting.rightType,
          leftConnectionId: setting.leftConnectionId,
          rightConnectionId: setting.rightConnectionId,
          key: setting.key,
          target: setting.target,
          tenant_id: args.teamId,
          value: setting.value,
          parentSettingId: setting.parentSettingId,
          childSettings: {
            on_conflict: {
              constraint: 'setting_pkey',
              update_columns: [
                'value',
                'target',
                'leftType',
                'rightType',
                'leftConnectionId',
                'rightConnectionId',
                'automationId',
              ],
            },
            data:
              setting.childrenSettings?.map((childSetting) => ({
                id: childSetting.settingId,
                automationId: args.automationId,
                group: childSetting.leftType,
                leftType: childSetting.leftType,
                rightType: childSetting.rightType,
                leftConnectionId:
                  childSetting.leftConnectionId || args.finalyticConnectionId,
                rightConnectionId:
                  childSetting.rightConnectionId || setting.rightConnectionId,
                key: childSetting.key,
                target: childSetting.target,
                value: childSetting.value,
                tenant_id: args.teamId,
              })) || [],
          },
        })),
      })?.affected_rows;

      const removedRows = q.delete_setting({
        where: { id: { _in: args.removals } },
      })?.affected_rows;

      return {
        removedRows,
        upserts,
      };
    },
    {
      successMessage: { message: 'Automation successfully saved!' },
      invalidateQueryKeys: ['automations', 'settings'],
    }
  );

  const mutateSettings = async (args: Args) => {
    try {
      const error = [];

      const checkFormulaInput = async (upsert: UpsertSetting) => {
        if (error.length > 0) return;

        const item = { ...upsert };

        try {
          const tree = expression.toTree(item.value?.trim());
          if (!tree) {
            throw new Error('Failed to validate formula input.');
          }

          item.value === expression.toString(tree);
        } catch {
          error.push(true);
        }

        (upsert.childrenSettings || []).forEach((upsert) =>
          checkFormulaInput(upsert)
        );

        return item;
      };

      // throw when formula editor has incorrect value
      args.upserts
        .filter((i) => i.rightType === 'editor.expression')
        .map((upsert) => checkFormulaInput(upsert));

      if (error.length > 0)
        throw new Error('Your automation contains an invalid formula input.');

      const data = await mutate({
        args: { ...args, teamId, finalyticConnectionId },
      });

      return {
        ok: true,
        data,
      };
    } catch (error: any) {
      if (error.message)
        showErrorNotification({
          message: error.message,
        });

      return {
        ok: false,
        data: undefined,
      };
    }
  };

  const saveAutomation = async ({
    automationId,
    connections,
  }: {
    automationId: string | undefined | null;
    connections: { [key: string]: string };
  }) => {
    if (!automationId) {
      showErrorNotification({ message: 'Missing automation.' });
      return {
        ok: false,
      };
    }

    const changes = getChanges(automationId);

    const parentRemovals = Object.values(changes)
      .filter((row) => row.settingId && !row.value)
      .map((row) => row.settingId!);

    // Only child ids that are not already in deleted parents
    const childRemovals = Object.values(changes)
      .filter((row) => row.settingId && !parentRemovals.includes(row.settingId))
      .flatMap((row) =>
        row.childSettings
          .filter((i) => !!i.settingId && !i.value)
          .map((i) => i.settingId!)
      );

    const removals = [...parentRemovals, ...childRemovals];
    const upserts: UpsertSetting[] = [];

    Object.entries(changes).forEach(([uniqueKey, row]) => {
      const splitKey = uniqueKey.split('.');
      const settingKey = splitKey.length === 3 ? splitKey[1] : splitKey[0];

      if (row.value) {
        const leftConnectionId =
          connections[getNamespaceAndType(row.leftType)[0]];
        const rightConnectionId =
          connections[getNamespaceAndType(row.rightType)[0]];
        upserts.push({
          parentSettingId: row.parentSettingId,
          settingId: row.settingId,
          leftType: row.leftType!,
          rightType: row.rightType!,
          leftConnectionId,
          rightConnectionId,
          key: settingKey,
          target: row.target,
          value: row.value,
          childrenSettings: row.childSettings
            ?.filter((child) => !!child.value)
            .map((child) => {
              const leftConnectionId =
                connections[getNamespaceAndType(child.leftType)[0]];
              const rightConnectionId =
                connections[getNamespaceAndType(child.rightType)[0]];
              return {
                settingId: child.settingId,
                leftType: child.leftType!,
                rightType: child.rightType!,
                leftConnectionId,
                rightConnectionId,
                key: settingKey,
                target: child.target,
                value: child.value!,
                childrenSettings: undefined,
                parentSettingId: row.parentSettingId,
              };
            }),
        });
      }
    });

    console.log(upserts);

    const result = await mutateSettings({
      automationId: automationId,
      removals,
      upserts,
    });

    if (result.ok) resetChanges(automationId);

    return result;
  };

  return {
    loading,
    saveAutomation,
  };
};
