import {
  API_URI,
  AUTH_REDIRECT_URL,
  AppRoutes,
  GRAPHQL_URI,
  MaintenanceView,
} from '..';
import { LoadingIndicator } from '@finalytic/ui';
import { Box } from '@mantine/core';
import React, { useEffect, useRef } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import {
  AuthenticateWithRedirectCallback,
  CLERK_FRONTEND_KEY,
  ClerkLoaded,
  ClerkProvider,
  SignInView,
  SignedIn,
  SignedOut,
  useUser,
} from '@finalytic/authentication';

import {
  MagicLinkVerificationView,
  SpotlightComponentProvider,
  SpotlightConfigProvider,
  VerifyTokenView,
} from '../components';
import { TrpcProvider } from '../hooks/trpc';
import { DBProvider } from './DBProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

export const Auth = () => {
  const navigate = useNavigate();

  const isInMaintenance = import.meta.env.VITE_MAINTENANCE === 'true';

  if (isInMaintenance) return <MaintenanceView />;

  return (
    <ClerkProvider
      frontendApi={`${CLERK_FRONTEND_KEY}`}
      navigate={(to: string) => navigate(to)}
    >
      <SignedIn>
        <AuthNewUserDelay>
          <SignedInRoutes />
        </AuthNewUserDelay>
      </SignedIn>
      <SignedOut>
        <SignOutRoutes />
      </SignedOut>
    </ClerkProvider>
  );
};

const SignedInRoutes = () => {
  return (
    <Routes>
      <Route path='hello' element={<Hello />} />

      <Route
        path='verification'
        element={
          <ClerkLoaded>
            <MagicLinkVerificationView authRedirectUrl={AUTH_REDIRECT_URL} />
          </ClerkLoaded>
        }
      />

      <Route
        path='/*'
        element={
          <SpotlightConfigProvider>
            <TrpcProvider queryClient={queryClient}>
              <QueryClientProvider client={queryClient}>
                <DBProvider graphqlUri={GRAPHQL_URI} apiUri={API_URI}>
                  <SpotlightComponentProvider>
                    <AppRoutes />
                  </SpotlightComponentProvider>
                </DBProvider>
              </QueryClientProvider>
            </TrpcProvider>
          </SpotlightConfigProvider>
        }
      />
    </Routes>
  );
};

const SignOutRoutes = () => {
  // const [isNewSignIn] = useQueryParam('newSignIn', StringParam);
  // const showNewSignIn = NEW_SIGN_IN || isNewSignIn;

  return (
    <Routes>
      <Route
        path='token-verify'
        element={
          <ClerkLoaded>
            <VerifyTokenView />
          </ClerkLoaded>
        }
      />
      <Route
        path='/*'
        element={
          <SignInView />
          // showNewSignIn ? (
          //   <SignInView />
          // )
          // : (
          //   <RedirectToSignIn redirectUrl={window.location.href} />
          // )
        }
      />
      <Route path='/sso-callback' element={<SSOCallback />} />
    </Routes>
  );
};

function Hello() {
  const navigate = useNavigate();
  React.useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 3000);
  });
  return (
    <Box mt={'30vh'}>
      <LoadingIndicator isFullPageLoading message='Redirecting' />
    </Box>
  );
}

// Callback for Social logins like Google etc.
function SSOCallback() {
  // Handle the redirect flow by rendering the
  // prebuilt AuthenticateWithRedirectCallback component.
  // This is the final step in the custom OAuth flow
  return <AuthenticateWithRedirectCallback />;
}

const AuthNewUserDelay = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUser();
  const isReady = !!user?.publicMetadata?.allowed_ids;
  const ctx = useRef(0);
  const isError = ctx.current > 10;
  useEffect(() => {
    if (!isReady && !isError) {
      const timeout = setTimeout(() => {
        ctx.current++;
        user?.reload();
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [isReady]);

  if (isError) return <div>Something went wrong, please try again ...</div>;
  else if (!isReady) {
    return (
      <Box mt={'30vh'}>
        <LoadingIndicator isFullPageLoading message='Redirecting ...' />
      </Box>
    );
  }

  return <>{children}</>;
};
