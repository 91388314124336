import { getSourceDescription } from '@finalytic/common';

import { useV2TransactionQuery } from '../../../hooks';
import { useAuditDatasource } from './_hooks';
import { useAuditDrawer } from './useAuditDrawer';
import { LoadingIndicator, Table } from '@finalytic/ui';
import { ColDef, ICellRendererParams } from '@finalytic/ui-grid';
import { toTitleCase } from '@finalytic/utils';
import dayjs from 'dayjs';
import { useMemo } from 'react';

type Props = {
  setAuditId: (id: string) => void;
};

export const AuditDrawerTable = ({ setAuditId }: Props) => {
  const { filter, showFilter } = useAuditDrawer();

  const { dataSource } = useAuditDatasource(filter);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'when',
        flex: 1,
        maxWidth: 220,
        valueFormatter: ({ value }) =>
          value ? dayjs(value).format('MMM DD YYYY, hh:mm:ss a') : '',
      },
      { field: 'who', flex: 1, maxWidth: 150 },
      { field: 'op', headerName: 'Operation', flex: 1, maxWidth: 130 },
      { field: 'what', flex: 1, cellRenderer: WhatCell },
      { field: 'table', hide: !showFilter, flex: 1 },
    ],
    [showFilter]
  );

  return (
    <Table
      columnDefs={columnDefs}
      animateRows
      serverSideDatasource={dataSource}
      serverSideInfiniteScroll
      rowModelType='serverSide'
      cacheBlockSize={150}
      paginationPageSize={150}
      getRowId={({ data }) => data?.id}
      rowStyle={{
        cursor: 'pointer',
      }}
      defaultColDef={{
        resizable: false,
        suppressMovable: true,
        sortable: false,
      }}
      noRowsOverlayComponent={() => 'No History Available'}
      onRowClicked={({ data }) => {
        setAuditId(data.id);
      }}
    />
  );
};

type Delta = { key: string; type: string; value: string }[];

const WhatCell = ({ data }: ICellRendererParams) => {
  const table = data?.table;
  const delta: Delta = data?.what;

  const generalGroup = delta.find((i) => i.key === 'group')?.value;
  const group = generalGroup?.includes('finalytic')
    ? generalGroup?.split('.').reverse()[0]
    : 'source';
  const key = delta.find((i) => i.key === 'key')?.value;
  const targetUuid = delta.find((i) => i.key === 'target_uuid')?.value;
  const target = delta.find((i) => i.key === 'target')?.value;
  const sourceId = delta.find((i) => i.key === 'source_id')?.value;
  const value = delta.find((i) => i.key === 'value')?.value;

  const { data: queryData, isLoading: loading } = useV2TransactionQuery(
    (q, args) => {
      let leftSide: any;
      const rightSource = sourceId
        ? q.sourceById({ id: args.sourceId })
        : undefined;
      const rightSide = getSourceDescription(rightSource);

      // Left side => target/group
      switch (args.group) {
        case 'listing':
          leftSide = q.listing({ id: args.targetUuid })?.name;
          break;

        case 'app':
          leftSide = q.appById({ id: args.targetUuid! })?.name;
          break;

        case 'listingOwner':
          leftSide = q.listingOwner({ id: args.targetUuid })?.listing?.name;
          break;

        case 'source': {
          const leftSource = sourceId
            ? q.sourceById({ id: args.targetUuid })
            : undefined;
          leftSide = getSourceDescription(leftSource);
          break;
        }

        case 'connection':
          leftSide = q.connectionById({ id: args.targetUuid })?.name;
          break;

        default:
          break;
      }

      return {
        leftSide: leftSide || args.target,
        rightSide:
          rightSide ||
          (['rate', 'formula'].includes(args?.key || '') && args.value),
      };
    },
    {
      skip: table !== 'setting' || (delta?.length || 0) < 1 || !generalGroup,
      queryKey: ['sources', 'listings', 'listingOwners', 'connections'],
      variables: {
        group,
        key,
        targetUuid,
        target,
        sourceId,
        value,
      },
    }
  );

  if (loading) return <LoadingIndicator size='xs' />;
  if (queryData)
    return (
      <>
        {toTitleCase(queryData.leftSide)} &rarr; {queryData.rightSide}
      </>
    );

  return delta.map((v) => toTitleCase(v.key.replace('cent', ''))).join(', ');
};
