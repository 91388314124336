import {
  ConfirmModal,
  Header,
  Navbar,
  NavbarBaseProps,
  OwnerPreviewNotification,
  gql,
  useMe,
  useNotifiedMutation,
  useTeam,
  useTeamId,
  useTrpcMutation,
  useTrpcQuery,
} from '../..';
import { useTeamDeletionEventQuery } from '../../queries';
import { useUser } from '@finalytic/authentication';
import { ExtractModal } from '@finalytic/automations';
import {
  DevEnvironmentBanner,
  ErrorBoundary,
  SetupAccountModal,
  SetupAccountSubmitType,
  StringParam,
  TeamDeletionBanner,
  showSuccessNotification,
  useQueryParams,
} from '@finalytic/ui';
import { faRefresh } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppShell,
  Avatar,
  Box,
  LoadingOverlay,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { ReactNode, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router';

function useUserNameMutation() {
  const { mutate } = useNotifiedMutation({
    updateUserById: [
      {
        pk_columns: { id: gql.$`id` },
        _set: { firstName: gql.$`firstName`, lastName: gql.$`lastName` },
      },
      { id: true, firstName: true, lastName: true },
    ],
  });

  return {
    updateUserName: ({
      firstName,
      id,
      lastName,
    }: {
      id: string;
      firstName: string;
      lastName: string;
    }) => mutate({ id: id || 'x', firstName, lastName }),
  };
}

export const AppLayout = ({
  children,
  drawers,
  ...navbarProps
}: {
  children: ReactNode;
  drawers: (() => JSX.Element)[];
} & NavbarBaseProps) => {
  const matches = useMediaQuery('(max-width: 755px)');

  const { user } = useUser();
  const { id, firstName, lastName } = useMe();
  const { updateUserName } = useUserNameMutation();

  const location = useLocation();

  useBillingCheckoutNotification();

  useEffect(() => {
    window.analytics?.page(location.pathname);
  }, [location.pathname]);

  const submitPassword: SetupAccountSubmitType = async ({
    newPass,
    firstName,
    lastName,
  }) => {
    if (!user) {
      return {
        ok: false,
        message: 'Missing user object.',
      };
    }

    await updateUserName({ id, firstName, lastName });

    return await user
      .update({
        unsafeMetadata: {},
        password: newPass,
      })
      .then(() => {
        return { ok: true };
      })
      .catch((error) => {
        return {
          ok: false,
          message: error?.errors[0]?.longMessage || error?.errors[0]?.message,
          title: error?.errors[0]?.code,
        };
      });
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingOverlay visible />}>
        <AppShell
          navbarOffsetBreakpoint='sm'
          navbar={<Navbar {...navbarProps} />}
          header={matches ? <Header {...navbarProps} /> : undefined}
          styles={{
            body: { paddingLeft: matches ? 0 : 20 },
          }}
        >
          {/* // ** Main Content */}
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <ErrorBoundary>{children}</ErrorBoundary>
            <OwnerPreviewNotification />
          </Box>

          {/* // **  Setup Modal when no passwod is set */}
          <SetupAccountModal
            opened={
              !!user?.unsafeMetadata?.needPasswordChange ||
              !!user?.unsafeMetadata?.need_password_change
            }
            onClose={() => null}
            onSubmit={submitPassword}
            hideCancel
            firstName={firstName}
            lastName={lastName}
          />

          <ExtractModal />
        </AppShell>

        {/* // ** Dynamically added drawers depending on app */}
        {drawers.map((Drawer, i) => (
          <Drawer key={i} />
        ))}

        {/* // ** Banners */}
        <TeamDeleteBanner />
        <DevEnvBanner />
      </Suspense>
    </ErrorBoundary>
  );
};

const useBillingCheckoutNotification = () => {
  const { primaryColor } = useMantineTheme();
  const [teamId] = useTeamId();
  const [{ price_id, session_type, status }] = useQueryParams({
    price_id: StringParam,
    session_type: StringParam,
    status: StringParam,
  });

  const appName = primaryColor;

  const { data } = useTrpcQuery(
    'stripe',
    'getProducts',
    { teamId },
    { skip: !teamId || !price_id }
  );

  useEffect(() => {
    if (!data || !price_id) return;

    const product = data?.products?.find(
      (prod) => (prod.default_price as any)?.id === price_id
    );

    if (product && session_type && status === 'success') {
      const sessionType = session_type as
        | 'checkout'
        | 'subscriptionUpdated'
        | 'trial';

      let message = 'Your checkout was successful!';
      if (sessionType === 'subscriptionUpdated')
        message = 'Your subscription was successfully updated!';
      else if (sessionType === 'trial')
        message = 'Your trial sign up was successful.';

      showSuccessNotification({
        title: `Welcome to ${appName}`,
        message,
        icon: <Avatar src={product.images[0]} />,
        color: primaryColor,
        autoClose: 5000,
      });
    }
  }, [price_id, session_type, status, data]);
};

const DevEnvBanner = () => {
  const isDevEnvironment = window.location.host
    ? window.location.host.split('.')[0] === 'dev'
    : false;

  if (!isDevEnvironment) return null;

  return <DevEnvironmentBanner />;
};

const TeamDeleteBanner = () => {
  const [opened, handlers] = useDisclosure(false);

  const { event, hasScheduledDeletion } = useTeamDeletionEventQuery();

  if (!hasScheduledDeletion) return null;

  return (
    <>
      <TeamDeletionBanner
        date={event?.date}
        onClick={event?.id ? handlers.open : undefined}
      />
      {event?.id && (
        <RestartTeamModal
          opened={opened}
          close={handlers.close}
          eventId={event?.id}
        />
      )}
    </>
  );
};

const RestartTeamModal = ({
  close,
  opened,
  eventId,
}: {
  opened: boolean;
  close: () => void;
  eventId: string;
}) => {
  const [{ id: teamId }, refetchTeam] = useTeam();

  const { mutate } = useTrpcMutation('scheduler', 'deleteTeam', {
    successMessage: { message: 'Team deletion was successfully cancelled.' },
    invalidateQueryKeys: ['scheduledEvents'],
  });

  const submit = async () => {
    const res = await mutate({
      scheduledEventId: eventId,
      type: 'cancel',
      tenantId: teamId,
      foreignObjectId: teamId,
    });

    if (res.ok) {
      refetchTeam();
    }

    return {
      isSuccess: res.ok,
    };
  };

  return (
    <ConfirmModal
      opened={opened}
      onClose={close}
      onSubmit={submit}
      title='Cancel Deletion'
      subtitle={
        <>
          Cancel the scheduled team deletion,
          <br />
          and reinstate your team.
        </>
      }
      icon={<FontAwesomeIcon icon={faRefresh} size='2xl' />}
      size={380}
      autoCloseOnSuccess
    />
  );
};
