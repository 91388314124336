import { gql, useQuery } from '../../../hooks';
import { PaymentLinesTable } from './_tables/PaymentLinesTable';
import { PaymentWorkflowsTable } from './_tables/PaymentWorkflowsTable';
import {
  Drawer,
  DrawerHeader,
  LoadingIndicator,
  ResultStatusCell,
} from '@finalytic/ui';
import { StringParam, useQueryParam } from '@finalytic/ui';
import { formatCurrency } from '@finalytic/utils';
import { Avatar, Box, Group, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { useMemo } from 'react';

export type PaymentType = ReturnType<typeof usePayment>;

function usePayment(paymentId?: string | null) {
  /*const { data, isLoading } = useV2TransactionQuery(
    (q, { paymentId }) => {
      const payment = q.payment({ id: paymentId });
      if (!payment) return undefined;
      return {
        lines: payment.lines({}).map((line) => ({
          id: line.id,
          description: line.description,
          type2: line.type2,
          centTotal: line.centTotal,
          lineId: line.lineId,
          reservation: line.reservation
            ? {
                id: line.reservation.id,
                confirmationCode: line.reservation.confirmationCode,
                guestName: line.reservation.guestName,
                checkIn: line.reservation.checkIn,
                checkOut: line.reservation.checkOut,
              }
            : undefined,
        })),
        workflows: payment
          .results({
            order_by: [{ type: 'asc' }, { created_at: 'asc' }],
          })
          .map((result) => ({
            id: result.id,
            message: result.message,
            created_at: result.created_at,
            type: result.type,
            status: result.status,
            target: result.target,
          })),
        payment: {
          id: payment.id,
          status: payment.status,
          payedAt: payment.payedAt,
          centTotal: payment.centTotal,
          currency: payment.currency,
          metadata: payment.metadata({ path: 'payoutDetails' }),
          connection: payment.connection
            ? {
                id: payment.connection.id,
                name: payment.connection.name,
                app: {
                  id: payment.connection.app.id,
                  name: payment.connection.app.name,
                  iconRound: payment.connection.app.iconRound,
                },
              }
            : undefined,
        },
      };
    },
    { variables: { paymentId }, skip: !paymentId }
  );

  return {
    payment: data?.payment,
    paymentLines: data?.lines,
    workflows: data?.workflows,
    workflowsDistinct: [] as any[],
    loading: isLoading,
  };*/
  const [{ data, fetching }] = useQuery(
    {
      payment: [
        {
          id: gql.$`paymentId`,
        },
        {
          id: true,
          status: true,
          payedAt: true,
          centTotal: true,
          currency: true,
          description: true,
          type: true,
          metadata: [{ path: 'payoutDetails' }, true],
          connection: {
            id: true,
            name: true,
            app: {
              id: true,
              name: true,
              iconRound: true,
            },
          },
          lines: [
            {
              where: { skipReconcile: { _neq: true } },
            },
            {
              id: true,
              description: true,
              type2: true,
              centTotal: true,
              lineId: true,
              reservationId: true,
              reservation: {
                id: true,
                confirmationCode: true,
                guestName: true,
                checkIn: true,
                checkOut: true,
              },
            },
          ],
          /*results: [
            {
              order_by: [{ type: 'asc' }, { created_at: 'asc' }],
            },
            {
              id: true,
              message: true,
              created_at: true,
              type: true,
              status: true,
              target: true,
            },
          ],*/
        },
      ],
    },
    {
      pause: !paymentId,
      variables: {
        paymentId,
      },
    }
  );

  const payment = data?.payment;
  const paymentLines = useMemo(
    () =>
      (data?.payment?.lines || []).map((line) => ({
        ...line,
        paymentDescription: data?.payment?.description,
        paymentType: data?.payment?.type,
        reservationId: line.reservationId || 'missingId',
      })),
    [data?.payment]
  );
  /*const workflows = data?.payment?.results || [];

  const workflowsDistinct =
    workflows.length > 0
      ? [...new Map(workflows.map((item) => [item['type'], item])).values()]
      : undefined;
      */
  return {
    payment,
    paymentLines,
    // workflows,
    // workflowsDistinct,
    loading: fetching,
  };
}

export const PaymentDetailDrawer = () => {
  const [paymentId, setQuery] = useQueryParam('payment', StringParam);

  const closeDrawer = () => {
    setQuery(undefined);
  };

  const { payment, paymentLines, loading } = usePayment(paymentId);

  return (
    <Drawer opened={!!paymentId} onClose={closeDrawer} size={1000}>
      <DrawerHeader title='Payment Details' onClose={closeDrawer} />

      {/* Modal Header */}
      {loading ? (
        <LoadingIndicator isFullPageLoading={true} />
      ) : (
        <>
          {/* Payment Details */}
          <Box p='md' sx={{ backgroundColor: '#F0F0F2', borderRadius: 10 }}>
            <Group position='apart'>
              <Group mb={5}>
                <Avatar size={60} src={payment?.connection?.app.iconRound} />

                <Stack spacing={0}>
                  <Text component='span' size='sm' sx={{ opacity: 0.5 }}>
                    {dayjs(payment?.payedAt).format('D MMM YYYY')}
                  </Text>
                  <Text component='span' mb={5}>
                    {payment?.connection?.name}
                  </Text>
                  {/*<Group spacing={4}>
                    {workflowsDistinct?.map((result) => (
                      <ResultStatusCell
                        key={result.id}
                        status={result.status}
                        value={result.type || ''}
                        message={result.message}
                      />
                    ))}
                  </Group>*/}
                </Stack>
              </Group>
              <Box
                sx={{
                  '& > *': {
                    display: 'block',
                    textAlign: 'right',
                  },
                }}
              >
                <Text
                  component='span'
                  weight={500}
                  size='sm'
                  sx={{ opacity: 0.9 }}
                >
                  Total:
                </Text>
                <Text component='span' weight={500} sx={{ fontSize: 23 }}>
                  {payment?.centTotal &&
                    formatCurrency(payment?.centTotal / 100, payment?.currency)}
                </Text>
                {payment?.metadata && (
                  <Text
                    component='span'
                    weight={500}
                    size='sm'
                    sx={{ opacity: 0.9 }}
                  >
                    {payment.metadata}
                  </Text>
                )}
              </Box>
            </Group>
          </Box>

          {/* Payment Lines */}
          <Text component='h3' size='lg' mb={5} px={5}>
            Payment Lines
          </Text>
          <PaymentLinesTable
            rowData={paymentLines}
            currency={payment?.currency as string | undefined}
          />

          {/* Workflows */}
          <Text component='h3' size='lg' mb={5} px={5}>
            Workflows
          </Text>
          {/*<PaymentWorkflowsTable rowData={workflows} />*/}
        </>
      )}
    </Drawer>
  );
};
