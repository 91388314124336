import { useEditAutomationContext } from '../_context';

export type EditorTypeUnion = 'expression' | 'select' | 'percentage';

export function useTabEditorType(settingKey: string) {
  const { template } = useEditAutomationContext();

  const tableTemplate = template.mappings[settingKey];
  const schemaType = tableTemplate.right.schema.split(
    '.'
  )[1] as EditorTypeUnion;
  const editorTypes: EditorTypeUnion[] = ['expression', 'percentage', 'select'];

  const rightEditorType = editorTypes.includes(schemaType)
    ? schemaType
    : 'select';

  return {
    rightEditorType,
  };
}
