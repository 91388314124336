import { SelectItem, SingleSelect } from '@finalytic/ui';
import { ICellEditorParams, ICellRendererParams } from '@finalytic/ui-grid';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group, Text, ThemeIcon } from '@mantine/core';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

type Context = {
  buckets: SelectItem[]; // {value: SourceId, label: SourceType}
};

export const SelectAccountBucketEditorCell = forwardRef(
  (params: ICellEditorParams, ref) => {
    const { buckets }: Context = params.context;
    const value: SelectItem | undefined = buckets.find(
      (i) => i.value === params?.value
    );

    const [newValue, setNewValue] = useState(value);

    const setValue = (newValue: SelectItem) => {
      // Deselect
      if (newValue?.value === value?.value) {
        setNewValue(undefined);
      } else {
        setNewValue(newValue);
      }
    };

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return newValue?.value;
        },
      };
    });

    useEffect(() => {
      if (newValue?.value !== value?.value) {
        params.stopEditing();
      }
    }, [newValue]);

    return (
      <SingleSelect
        data={buckets} // label: source.description ; value: source.id
        value={value} // label: setting.value ; value: setting.id
        withSearch
        setValue={setValue}
        popoverWidth='target'
        onClose={() => params.api.stopEditing()}
        preventClose
        defaultOpen
      />
    );
  }
);

SelectAccountBucketEditorCell.displayName = 'Select Account Bucket Cell';

export const SelectAccountBucketCell = (params: ICellRendererParams) => (
  <Group position='apart' noWrap sx={{ height: '100%' }}>
    <Text>
      {
        (params.context as Context).buckets.find(
          (i) => i.value === params.value
        )?.label
      }
    </Text>
    <ThemeIcon color='dark' variant='outline' sx={{ border: 'none' }}>
      <FontAwesomeIcon size='sm' icon={faChevronDown} />
    </ThemeIcon>
  </Group>
);
