import {
  ActionButton,
  Input,
  Modal,
  showErrorNotification,
} from '@finalytic/ui';
import { Box, Text } from '@mantine/core';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

type Props = {
  opened: boolean;
  close: () => void;
  email: string;
  onSubmit: (email: string) => Promise<{ ok: boolean; errorMessage?: string }>;
};

type FormValues = {
  missingEmail: string;
};

export const ForgotPasswordModal = ({
  close,
  opened,
  onSubmit,
  email,
}: Props) => {
  const methods = useForm<FormValues>({
    defaultValues: { missingEmail: email },
  });

  useEffect(() => {
    if (!opened) {
      setTimeout(() => {
        methods.reset();
      }, 300);
    }
  }, [opened]);

  useEffect(() => {
    methods.setValue('missingEmail', email?.trim() || '');
  }, [email]);

  const handleSuccess = async (data: FormValues) => {
    try {
      close();
      await onSubmit(data.missingEmail);
    } catch (error: any) {
      console.log(error?.message || error);
      showErrorNotification({ message: error?.message || error });
    }
  };

  const handleError = () =>
    showErrorNotification({
      title: 'Incorrect Email',
      message: 'Please enter a valid email address.',
    });

  return (
    <Modal
      opened={opened}
      onClose={close}
      size={500}
      title='Forgot your password?'
      styles={{
        body: {
          padding: 20,
          paddingTop: 'initial',
        },
      }}
    >
      <Text component='p' size='sm' mb='xl'>
        Enter your email address and we will send you a magic link to login and
        reset your password.
      </Text>
      <Text component='span' display='block' size='sm' mb={5}>
        Email
      </Text>
      <Controller
        name='missingEmail'
        control={methods.control}
        rules={{
          // Copilot regex
          pattern: new RegExp(
            // email regex
            '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
            'i'
          ),
          required: true,
        }}
        render={({ field }) => (
          <Input
            value={field.value || ''}
            onChange={field.onChange}
            type='email'
            inputName={field.name}
            placeholderText={`support@${window.location?.host}`}
            required
            mb='xl'
          />
        )}
      />

      <Box>
        <ActionButton
          fullWidth
          onClick={methods.handleSubmit(handleSuccess, handleError)}
          disabled={!methods.formState.isValid}
        >
          Send Email
        </ActionButton>
      </Box>
    </Modal>
  );
};
