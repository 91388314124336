import { InputPercentage } from '@finalytic/ui';
import { Box } from '@mantine/core';
import { useClickOutside, useMergedRef } from '@mantine/hooks';
import { useEffect, useRef } from 'react';

export const PercentageEditor = ({
  value,
  setValue,
  stopEditing,
}: {
  value: number;
  setValue: (v: number) => void;
  stopEditing: () => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const clickOutsideRef = useClickOutside(stopEditing);

  const mergedRef = useMergedRef(inputRef, clickOutsideRef);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <Box
      w='100%'
      sx={{
        '> *': {
          width: '100%',
        },
      }}
    >
      <InputPercentage setValue={setValue} value={value} inputRef={mergedRef} />
    </Box>
  );
};
