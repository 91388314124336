import { createUUID } from '../../utils';
import { gql } from '@finalytic/client';
import { showErrorNotification } from '@finalytic/ui';
import { faCheck, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showNotification, updateNotification } from '@mantine/notifications';
import type {
  AnyVariables,
  CombinedError,
  Operation,
  OperationContext,
  OperationResult,
} from '@urql/core';
import { useMemo } from 'react';
import {
  useClient as useUrqlClient,
  useMutation as useUrqlMutation,
  useQuery as useUrqlQuery,
  useSubscription as useUrqlSubscription,
} from 'urql';
import type {
  UseMutationResponse,
  UseQueryArgs,
  UseSubscriptionResponse,
} from 'urql';
export { gql } from '@finalytic/client';

export type {
  AnyVariables,
  CombinedError,
  Operation,
  OperationContext,
  OperationResult,
  useUrqlQuery,
  UseQueryArgs,
  UseSubscriptionResponse,
  UseMutationResponse,
};

export function useQuery<
  Variables extends AnyVariables,
  Q extends gql.ValueTypes['query_root'] = gql.ValueTypes['query_root']
>(
  query: Q,
  args: Omit<UseQueryArgs<Variables, any>, 'query'> = {} as any,
  refresher?: any
): [
  {
    fetching: boolean;
    stale: boolean;
    data?: gql.MapType<gql.query_root, Q>;
    error?: CombinedError;
    extensions?: Record<string, any>;
    operation?: Operation<gql.MapType<gql.query_root, Q>, Variables>;
  },
  (opts?: Partial<OperationContext>) => void
] {
  return useUrqlQuery<gql.MapType<gql.query_root, Q>, Variables>({
    query: useMemo(() => gql.Zeus.query(query), [refresher]),
    ...args,
  } as any);
}

export function useSubscription<
  Variables extends AnyVariables,
  Q extends gql.ValueTypes['query_root'] = gql.ValueTypes['query_root']
>(
  query: Q,
  args: Omit<UseQueryArgs<Variables, any>, 'query'> = {} as any,
  refresher?: any
): UseSubscriptionResponse<gql.MapType<gql.query_root, Q>, Variables> {
  return useUrqlSubscription<
    gql.MapType<gql.query_root, Q>,
    gql.MapType<gql.query_root, Q>,
    Variables
  >({
    query: useMemo(() => gql.Zeus.subscription(query), [refresher]),
    ...args,
  } as any);
}

export function useMutation<
  Variables extends Record<string, unknown>,
  Q extends gql.ValueTypes['mutation_root'] = gql.ValueTypes['mutation_root']
>(
  mutation: Q,
  refresher?: any
): UseMutationResponse<gql.MapType<gql.mutation_root, Q>, Variables> {
  return useUrqlMutation<gql.MapType<gql.mutation_root, Q>, Variables>(
    useMemo(() => gql.Zeus.mutation(mutation), [refresher])
  );
}

export function useNotifiedMutation<
  Variables extends Record<string, unknown>,
  Q extends gql.ValueTypes['mutation_root'] = gql.ValueTypes['mutation_root']
>(
  mutation: Q,
  options?: {
    refresher?: any;
    successMessage?: { id?: string; title?: string; message?: string };
  }
) {
  const [props, mutate] = useUrqlMutation<
    gql.MapType<gql.mutation_root, Q>,
    Variables
  >(useMemo(() => gql.Zeus.mutation(mutation), [options?.refresher]));

  const extendedMutation = async (
    variables: Variables,
    context?: Partial<OperationContext>
  ) => {
    // Return with only error notifcation when successMessage undefined
    if (!options?.successMessage) {
      return await mutate(variables, context).then((res) => {
        if (res.error) {
          showErrorNotification({
            title: res.error.name,
            message: res.error.message,
          });
        }
        return res;
      });
    }

    // Create updatable notifcation
    const id = options.successMessage.id || createUUID();
    showNotification({
      id,
      loading: true,
      title: 'Loading...',
      message: 'We will update you shortly.',
      autoClose: false,
      radius: 10,
    });

    return await mutate(variables, context).then((res) => {
      updateNotification({
        id,
        message:
          (res.error?.message
            ? res.error.message.replace('[GraphQL] ', '')
            : undefined) ||
          options?.successMessage?.message ||
          'Sucessfully updated your action.',
        title:
          (res.error?.name ? 'Error' : undefined) ||
          options?.successMessage?.title ||
          'Success!',
        color: res.error ? 'red' : 'teal',
        icon: res.error ? (
          <FontAwesomeIcon icon={faX} size='sm' />
        ) : (
          <FontAwesomeIcon icon={faCheck} />
        ),
        radius: 10,
      });
      return res;
    });
  };

  return {
    mutate: extendedMutation,
    loading: props.fetching,
    error: props.error,
  };
}

export type HasuraClient = ReturnType<typeof useClient>;
export function useClient(): {
  query: <
    Variables extends Record<string, unknown>,
    Q extends gql.ValueTypes['query_root'] = gql.ValueTypes['query_root']
  >(
    query: Q,
    args?: Variables
  ) => Promise<OperationResult<gql.MapType<gql.query_root, Q>, Variables>>;
} {
  const client = useUrqlClient();
  return {
    query: <
      Variables extends Record<string, unknown>,
      Q extends gql.ValueTypes['query_root'] = gql.ValueTypes['query_root']
    >(
      query: Q,
      args?: Variables
    ) =>
      client
        .query<gql.MapType<gql.query_root, Q>, Variables>(
          gql.Zeus.query(query),
          args as any
        )
        .toPromise(),
  };
}
