import { Box, Text } from '@mantine/core';
import { PropsWithChildren, ReactNode } from 'react';

export type SidebarContentContainerProps = {
  title: ReactNode;
  subtitle: ReactNode;
  spacing?: number;
};

export const SidebarContentContainer = ({
  subtitle,
  title,
  spacing = 200,
  children,
}: PropsWithChildren<SidebarContentContainerProps>) => {
  return (
    <>
      <Box mb={spacing}>{children}</Box>
      <Text component='h2' size={24} color='white' align='center'>
        {title}
      </Text>
      <Text component='p' color='white' align='center' maw={460} mx='auto'>
        {subtitle}
      </Text>
    </>
  );
};
