import {
  ensure,
  listingIsExcluded,
  useDashboardType,
  useMe,
  usePartnerTeams,
  useTeamId,
} from '../..';
import { ReservationsFilterParams } from './_types';
import { reservation_bool_exp } from '@finalytic/graphql';
import { day, hasValue } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import { useMemo } from 'react';

export function useWhereReservations({
  // amount,
  date,
  dateType,
  search,
  connectionId,
  listingId,
  teamId,
  onlyEnabledListingByAutomationId,
  bookingChannel,
}: ReservationsFilterParams & { startRow?: number; endRow?: number }) {
  const [currentTeamId] = useTeamId();
  const [teams] = usePartnerTeams();
  const [dashboard] = useDashboardType();
  const { ownerships } = useMe();

  const searchQuery = useMemo(() => {
    const trimmedSearch = search?.trim();
    if (trimmedSearch) {
      return ensure<reservation_bool_exp[]>([
        {
          guestName: { _ilike: `%${trimmedSearch}%` },
        },
        {
          uniqueRef: { _ilike: `%${trimmedSearch}%` },
        },
        {
          confirmationCode: { _ilike: `%${trimmedSearch}%` },
        },
        {
          pmsReferenceCode: { _ilike: `%${trimmedSearch}%` },
        },
      ]);
    }
    return undefined;
  }, [search]);

  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 500);

  const startDate = date?.[0] ? day(date[0]).yyyymmdd() : undefined;
  const endDate = date?.[1] ? day(date[1]).yyyymmdd() : undefined;

  console.log(startDate, endDate, date);

  const dateBool = useMemo<reservation_bool_exp[]>(() => {
    if (!startDate) return [{}];
    const start = startDate;
    const end = endDate || day(start).add(1, 'd').yyyymmdd();

    if (dateType === 'checkIn') {
      return [
        {
          checkIn: { _gte: start, _lt: end },
        },
      ];
    }
    if (dateType === 'checkOut') {
      return [
        {
          checkOut: { _gte: start, _lt: end },
        },
      ];
    }
    if (dateType === 'bookedAt') {
      return [
        {
          bookedAt: { _gte: start, _lt: end },
        },
      ];
    }
    /*if (!checkOutDate) {
      return [
        {
          checkIn: { _gte: checkInDayStart, _lt: checkInDayEnd },
        },
        {
          checkOut: { _gte: checkInDayStart, _lt: checkInDayEnd },
        },
        {
          checkIn: { _lt: checkInDayStart },
          checkOut: { _gte: checkInDayEnd },
        },
      ];
    }*/

    return [
      {
        checkIn: { _gte: start, _lt: end },
      },
      {
        checkOut: { _gte: start, _lt: end },
      },
      {
        checkIn: { _lt: start },
        checkOut: { _gte: end },
      },
    ];
  }, [dateType, startDate, endDate]);

  return ensure<reservation_bool_exp>({
    paymentLines: dashboard === 'owner' ? {} : undefined,
    tenantId:
      teams.length > 0
        ? teamId
          ? { _eq: teamId }
          : { _in: teams.map((i) => i.id) }
        : { _eq: currentTeamId },
    status: {
      _is_null: false,
      _nin: ['inquired', 'inquiry'],
    },
    connection: connectionId
      ? // connectionName || connectionApp
        {
          id: connectionId ? { _eq: connectionId } : undefined,
          // app: connectionApp ? { id: { _eq: connectionApp } } : undefined,
          status: { _neq: 'deleted' },
        }
      : { status: { _neq: 'deleted' } },
    channel: bookingChannel ? { id: { _eq: bookingChannel } } : undefined,
    listingConnection:
      dashboard === 'owner' || listingId
        ? {
            listingId: listingId
              ? { _eq: listingId }
              : { _in: ownerships.map((i) => i.listingId) },
          }
        : undefined,
    _and: [
      {
        _or: dateBool,
      },
      debouncedSearchQuery ? { _or: debouncedSearchQuery } : undefined,
      onlyEnabledListingByAutomationId
        ? {
            listingConnection: {
              listing: onlyEnabledListingByAutomationId
                ? listingIsExcluded(onlyEnabledListingByAutomationId)
                : undefined,
            },
          }
        : undefined,
    ].filter(hasValue),
  });
}
