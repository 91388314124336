import {
  Layout,
  LayoutProps,
  OnboardingPrice,
  SidebarPricing,
  SimplePricingTable,
  SimplePricingTableProps,
} from '../components';
import { PricingSlider } from '@finalytic/data-ui';
import { ActionButton, showErrorNotification } from '@finalytic/ui';
import { Stack, useMantineTheme } from '@mantine/core';
import { useState } from 'react';

type PricingViewProps = {
  onViewSubmit: (price: OnboardingPrice) => void;
  prices: SimplePricingTableProps['prices'];
  loading?: boolean;
};

export const PricingView = ({
  prices,
  onViewSubmit,
  loading,
}: PricingViewProps) => {
  const { primaryColor } = useMantineTheme();

  const [properties, setProperties] = useState(1);

  const [activePrice, setActivePrice] = useState<OnboardingPrice>();

  const setPrice = (price: OnboardingPrice) =>
    setActivePrice((active) =>
      active?.priceId === price.priceId ? undefined : price
    );

  const submit = () => {
    if (!activePrice)
      return showErrorNotification({ message: 'Missing selected price.' });

    onViewSubmit(activePrice);
  };

  return (
    <>
      <PricingSlider properties={properties} setProperties={setProperties} />
      <Stack mx='auto' mt='sm' spacing={30}>
        <SimplePricingTable
          prices={prices}
          onPriceSelect={setPrice}
          activePriceId={activePrice?.priceId}
          properties={properties}
        />
        <ActionButton
          color={primaryColor === 'VRPlatform' ? primaryColor : undefined}
          mx='auto'
          w={450}
          disabled={!activePrice}
          onClick={submit}
          loading={loading}
        >
          Continue with card verification
        </ActionButton>
      </Stack>
    </>
  );
};

export const PricingStorybookView = ({
  title,
  ...props
}: PricingViewProps & LayoutProps) => {
  return (
    <Layout
      title={title}
      handleLogout={undefined}
      setTeam={() => null}
      team={undefined}
      sidebar={<SidebarPricing />}
      sidebarPaddingTop='20vh'
      teams={[]}
    >
      <PricingView {...props} />
    </Layout>
  );
};
