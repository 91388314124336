import { Group, Paper, Text } from '@mantine/core';
import { ReactNode } from 'react';

type Props = {
  title: string;
  withBorder?: boolean;
  padding?: string | number;
  children: ReactNode;
};
export const OverviewSectionContainer = ({
  title,
  withBorder,
  children,
  padding,
}: Props) => {
  return (
    <Group
      position='apart'
      align='flex-start'
      sx={(theme) => ({
        [`@media (max-width: ${theme.breakpoints.md})`]: {
          flexDirection: 'column',
        },
      })}
    >
      <Text size='lg' weight={500} sx={{ flex: 1, maxWidth: 280 }}>
        {title}
      </Text>
      <Paper
        p={padding !== undefined ? padding : withBorder ? 'xl' : undefined}
        radius='lg'
        withBorder={withBorder}
        sx={{ flex: 1, width: '100%' }}
      >
        {children}
      </Paper>
    </Group>
  );
};
