import { useTeam, useV2TransactionQuery } from '../../../hooks';
import { formatOwnerName } from '../../../utils';
import { useMemo } from 'react';

export type ReservationLinesType = ReturnType<
  typeof useReservation
>['reservationLines'];

export type ReservationPaymentLinesType = ReturnType<
  typeof useReservation
>['paymentLines'];

export function useReservation(reservationId?: string | null) {
  const [{ apps }] = useTeam();

  const {
    data,
    isLoading: loading,
    refetch,
  } = useV2TransactionQuery(
    (q, args) => {
      const reservation = q.reservation({
        id: args.reservationId,
      });

      return {
        reservation: {
          id: reservation?.id,
          bookedAt: reservation?.bookedAt,
          connection: {
            id: reservation?.connection?.id,
            app: {
              id: reservation?.connection?.app?.id,
              iconRound: reservation?.connection?.app?.iconRound,
              name: reservation?.connection?.app?.name,
            },
          },
          checkIn: reservation?.checkIn,
          checkOut: reservation?.checkOut,
          guestName: reservation?.guestName,
          status: reservation?.status,
          centTotal: reservation?.centTotal,
          currency: reservation?.currency,
          confirmationCode: reservation?.confirmationCode,
          pmsReferenceCode: reservation?.pmsReferenceCode,
          listing: {
            id: reservation?.listing?.id,
            name: reservation?.listing?.title || reservation?.listing?.name,
          },
          listingConnection: {
            id: reservation?.listingConnection?.id,
            name: reservation?.listingConnection?.name,
            listing: {
              id: reservation?.listingConnection?.listing?.id,
              name:
                reservation?.listingConnection?.listing?.title ||
                reservation?.listingConnection?.listing?.name,
            },
          },
          files: reservation
            ?.files({
              order_by: [{ startDate: 'desc_nulls_last' }],
            })
            .map((file) => ({
              id: file.id,
              type: file.type,
              filename: file.filename,
              ownerName: file?.owner
                ? formatOwnerName({
                    firstName: file.owner.firstName,
                    lastName: file.owner.lastName,
                    companyName: file.owner.companyName,
                  })
                : '',
              startDate: file.startDate,
              endDate: file.endDate,
            })),
          innvoiceLines:
            reservation
              ?.paymentLines({
                where: {
                  skipReconcile: { _eq: true },
                  type: { _eq: 'invoice_line' },
                },
              })
              .map((line) => ({
                id: line?.id,
                description: line?.description,
                type2: line?.type2,
                centTotal: line?.centTotal,
                lineId: line?.lineId,
                skipReconcile: line?.skipReconcile,
                paymentId: line?.paymentId,
                accountingType: line
                  .settingsByType2({
                    limit: 1,
                    where: { key: { _eq: 'accountingType' } },
                    order_by: [
                      {
                        created_at: 'asc',
                      },
                    ],
                  })
                  .map((setting) => ({
                    value: setting?.value,
                    key: setting.key,
                  }))?.[0]?.value,
                payment: {
                  id: line?.payment?.id,
                  type: line?.payment?.type,
                  payedAt: line?.payment?.payedAt,
                  description: line?.payment?.description,
                  connection: {
                    id: line?.payment?.connection?.id,
                    appId: line?.payment?.connection?.appId,
                    app: {
                      iconRound: line?.payment?.connection?.app?.iconRound,
                    },
                  },
                },
              })) || [],
          paymentLines:
            reservation
              ?.paymentLines({
                where: {
                  skipReconcile: { _eq: false },
                  payment: { type: { _in: ['payout', 'custom'] } },
                },
              })
              .map((line) => ({
                id: line?.id,
                description: line?.description,
                type2: line?.type2,
                centTotal: line?.centTotal,
                lineId: line?.lineId,
                skipReconcile: line?.skipReconcile,
                paymentId: line?.paymentId,
                settingsByType2: line
                  .settingsByType2({
                    where: { key: { _eq: 'accountingType' } },
                  })
                  .map((setting) => ({
                    value: setting?.value,
                    key: setting.key,
                  })),
                payment: {
                  id: line?.payment?.id,
                  type: line?.payment?.type,
                  payedAt: line?.payment?.payedAt,
                  description: line?.payment?.description,
                  connection: {
                    id: line?.payment?.connection?.id,
                    appId: line?.payment?.connection?.appId,
                    app: {
                      iconRound: line?.payment?.connection?.app?.iconRound,
                    },
                  },
                },
              })) || [],
        },
      };
    },
    {
      queryKey: ['files'],
      skip: !reservationId,
      variables: {
        reservationId,
      },
    }
  );

  return useMemo(() => {
    const hasPaymentGateway = !!apps.find(
      (i) => i.category === 'paymentGateway'
    )?.id;

    return {
      reservation: data?.reservation,
      reservationLines: data?.reservation?.innvoiceLines.map((x) => ({
        ...x,
        reservation: data?.reservation,
      })),
      // payments: Object.entries(
      //   groupBy(data?.reservation?.paymentLines || [], 'paymentId')
      // )
      //   // .filter(([, value]) => !!value[0].payment?.payedAt)
      //   .map(([key, value]) => ({
      //     id: key,
      //     payedAt: value[0].payment?.payedAt,
      //     centTotal: sum(value, 'centTotal'),
      //     type2: value[0].payment?.type,
      //   }))
      //   .filter((payment) =>
      //     hasPaymentGateway ? payment.type2 === 'payout' : true
      //   )
      //   .sort((a, b) => (utc(a.payedAt).isBefore(b.payedAt) ? -1 : 1)),
      paymentLines: data?.reservation?.paymentLines
        .filter((x) => (hasPaymentGateway ? x.payment.type === 'payout' : true))
        .map((x) => ({ ...x, reservation: data?.reservation })),
      loading,
      refetch,
    };
  }, [data, apps, loading]);
}
