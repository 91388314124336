import {
  useDashboardType,
  usePartnerTeams,
  useTeam,
  useV2TransactionSubscription,
} from '../../hooks';
import { NavbarIssueBadge } from '@finalytic/ui';
import { faHouse } from '@fortawesome/pro-solid-svg-icons';

export const ListingsIssueBadge = () => {
  const [{ id: teamId }] = useTeam();
  const [dashboard] = useDashboardType();
  const [partners] = usePartnerTeams();

  const { data: amount } = useV2TransactionSubscription(
    (q, { partners, teamId }) => {
      const count = q
        .listingConnectionAggregate({
          where: {
            tenantId:
              dashboard !== 'partner'
                ? { _eq: teamId }
                : { _in: partners.map((i) => i.id) },
            listingId: { _is_null: true },
          },
        })
        .aggregate?.count();

      return count || 0;
    },
    { partners, teamId }
  );

  return (
    <NavbarIssueBadge
      amount={amount || 0}
      icon={faHouse}
      tooltipText={(label, amount) =>
        `${label} listing connection${amount > 1 ? 's' : ''} not mapped`
      }
    />
  );
};
