import {
  AdditionalMappingCellParams,
  MappingEditorCell,
  MappingNameCell,
  useTabEditorType,
} from '../../../components';
import { Team, useSettingByIdMutation, useTeam } from '../../../hooks';
import { ColDef } from '@finalytic/ui-grid';
import { ensure } from '@finalytic/utils';
import { createStyles } from '@mantine/core';

type Mapping = any;

export function getAutomationSourceTypes(
  automations: Team['automations'],
  targetKey: string
) {
  return automations.flatMap((automation) =>
    Object.values(automation.mappings || {})
      .filter(
        (mapping: Mapping) =>
          mapping?.left?.schema?.split('.').reverse()[0] === targetKey
      )
      .map((mapping: Mapping) => mapping.right.schema)
  );
}

export const useAutomationColumns = (targetKey = 'listing'): ColDef[] => {
  const [team] = useTeam();

  const { mutate } = useSettingByIdMutation();

  const { classes } = useStyles();

  const automations = team.automations.filter(
    (automation) =>
      automation.status === 'active' &&
      Object.values(automation.mappings || {}).some(
        (mapping: Mapping) =>
          mapping?.left?.schema?.split('.').reverse()[0] === targetKey
      )
  );

  return automations.flatMap<ColDef>(
    (automation, automationIndex, automationArray) => {
      const mappings = Object.entries(automation.mappings)
        .filter(
          ([, mapping]: [string, Mapping]) =>
            mapping?.left?.schema?.split('.').reverse()[0] === targetKey
        )
        .map(([mappingKey, mapping]: [string, Mapping]) => ({
          mappingKey,
          ...mapping,
        }));

      return mappings.map<ColDef>((mapping) => {
        const leftType = mapping.left.schema;
        const rightType = mapping.right.schema;
        const settingKey = mapping.mappingKey;

        // Hide column if there already is a mapping with the same rightType and leftType and rightConnections and leftConnections
        if (
          automationArray.slice(0, automationIndex).some((a) =>
            Object.entries(a.mappings)
              .map(([mappingKey, mapping]: [string, Mapping]) => ({
                mappingKey,
                ...mapping,
              }))
              .some(
                (m: Mapping) =>
                  m.right.schema === rightType &&
                  // rightConnection === rightConnection && leftConnection === leftConnection
                  // rightConnection === leftConnection && leftConnection === rightConnection
                  ((a.rightConnectionId === automation.rightConnectionId &&
                    a.leftConnectionId === automation.leftConnectionId) ||
                    a.rightConnectionId === automation.leftConnectionId ||
                    a.leftConnectionId === automation.rightConnectionId)
              )
          )
        ) {
          return {
            hide: true,
          };
        }

        const { rightEditorType: editorType } = useTabEditorType({
          automationId: automation.automationId,
          settingKey,
        });

        return {
          field: `automation-${settingKey}`,
          editable: true,
          // headerName: `${mapping.label} | ${automation.title}`,
          headerName: mapping.label,
          // hide: !isSuperAdmin,
          minWidth: 300,
          maxWidth: 500,
          cellClass: classes.cell,
          cellEditor: MappingEditorCell,
          cellRenderer: MappingNameCell,
          singleClickEdit: true,
          cellEditorParams: ensure<AdditionalMappingCellParams>({
            settingKey,
            automationId: automation.automationId,
            loadDataInCell: true,
          }),
          cellRendererParams: ensure<AdditionalMappingCellParams>({
            settingKey,
            automationId: automation.automationId,
            loadDataInCell: true,
          }),
          valueSetter: (params) => {
            const targetId = params.data.id;
            const newValue: { value: string; settingId: string | undefined } =
              params.newValue;
            const settingId = newValue.settingId;

            if (newValue?.value) {
              mutate({
                type: 'upsert',
                setting: {
                  id: settingId,
                  key: settingKey,
                  target: targetId,
                  value: newValue.value,
                  leftType,
                  rightType,
                  automationId: automation.automationId,
                  teamId: team.id,
                  leftConnectionId: automation.leftConnectionId,
                  rightConnectionId: automation.rightConnectionId,
                },
              });

              return true;
            }

            if (!newValue?.value && settingId) {
              mutate({ type: 'remove', settingId });
              return true;
            }
            return false;
          },
          suppressKeyboardEvent: (params) => {
            const key = params.event.code;

            const KEY_UP = 'ArrowUp';
            const KEY_DOWN = 'ArrowDown';
            const KEY_ENTER = 'Enter';

            if (editorType === 'expression') {
              // return true (to suppress) if editing and user hit up/down keys
              const gridShouldDoNothing =
                params.editing &&
                (key === KEY_UP || key === KEY_DOWN || key === KEY_ENTER);
              return gridShouldDoNothing;
            }
            return false;
          },
        };
      });
    }
  );
};

const useStyles = createStyles((theme) => ({
  cell: {
    '.ag-cell-wrapper': {
      height: '100%',
    },
  },
}));
