import { useTeamId, useV2TransactionQuery } from '../../../hooks';
import { useOwnerMutations } from './_hooks';
import {
  ActionButton,
  AddressType,
  Input,
  InputAddress,
  InputContainer,
  Modal,
  ModalFooter,
  Select,
  Switch,
  TransparentButton,
  showErrorNotification,
  showSuccessNotification,
  useColors,
} from '@finalytic/ui';
import { faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Group, Stack, useMantineTheme } from '@mantine/core';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

type AddOwnerModalProps = {
  opened: boolean;
  refetchOwners?: () => void;
  onClose: () => void;
  ownerValues?: FormInputs;
  ownerId?: string;
  ownerStatus?: string;
};

type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  address?: AddressType;
  companyName?: string;
  sendInvite?: boolean;
  listingIds: string[];
};

export const AddOwnerModal = ({
  refetchOwners,
  opened,
  onClose: _onClose,
  ownerValues,
  ownerId,
  ownerStatus,
}: AddOwnerModalProps) => {
  const { gray } = useColors();

  const theme = useMantineTheme();

  const { listings } = useTeamListings({ ownerId });
  const { insert, updateWithOwnerShips } = useOwnerMutations(refetchOwners);

  const { handleSubmit, reset, control, getFieldState, formState } =
    useForm<FormInputs>({
      defaultValues: ownerValues || {
        firstName: '',
        lastName: '',
        email: '',
        address: undefined,
        phone: '',
        companyName: '',
        listingIds: [],
      },
      mode: 'onChange',
    });

  const { isValid, isDirty, isSubmitting } = formState;

  const companyNameState = getFieldState('companyName', formState);
  const firstNameState = getFieldState('firstName', formState);
  const lastNameState = getFieldState('lastName', formState);

  useEffect(() => {
    if (ownerValues) {
      reset(ownerValues);
    }
  }, [ownerValues]);

  const onClose = () => {
    _onClose();
    setTimeout(() => reset(), 500);
  };

  const onSubmit = async (data: FormInputs) => {
    const {
      address,
      email,
      firstName,
      phone,
      lastName,
      companyName,
      listingIds,
      sendInvite,
    } = data;

    try {
      if (!ownerId) {
        const result = await insert({
          email,
          addressLine1: address?.line1,
          addressCity: address?.city,
          addressPostcode: address?.postcode,
          addressCountry: address?.country,
          firstName: firstName,
          name: lastName,
          lastName: lastName,
          phone,
          companyName,
          listingIds,
          sendInvite,
        });

        if (!result?.ok) throw new Error('Failed to insert new owner.');
      } else {
        // check old listingIds vs new ones
        const prevListingIds = ownerValues?.listingIds || [];
        const newOwnershipIds = listingIds.filter(
          (newValue) => !prevListingIds.includes(newValue)
        );
        const removeOwnershipIds = prevListingIds.filter(
          (oldValue) => !listingIds.includes(oldValue)
        );

        const result = await updateWithOwnerShips(ownerId, {
          input: {
            addressLine1: address?.line1,
            addressCity: address?.city,
            addressPostcode: address?.postcode,
            addressCountry: address?.country,
            firstName: firstName,
            name: lastName,
            lastName: lastName,
            phone,
            companyName,
          },
          newListingIds: newOwnershipIds,
          removeListingIds: removeOwnershipIds,
        });

        if (result.some((i) => !!i.error))
          throw new Error(result.find((i) => !!i.error)?.error?.message);
      }

      onClose();
      showSuccessNotification({
        title: 'Success!',
        message: ownerId
          ? 'Owner was successfully updated.'
          : 'Owner was successfully added to your team.',
      });
    } catch (error: any) {
      showErrorNotification({
        title: 'Error',
        message: error?.message || 'Updating Owner Failed.',
      });
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnClickOutside={false}
      size={560}
      title={!ownerId ? 'Add Owner' : 'Edit Owner'}
    >
      <Box component='form' onSubmit={handleSubmit(onSubmit)}>
        <Stack mt={20} mb={30} spacing={20}>
          <Controller
            control={control}
            name='companyName'
            rules={{
              required: !firstNameState.isDirty && !lastNameState.isDirty,
            }}
            render={({ field: { name, onChange, value } }) => (
              <InputContainer htmlFor={name} label='Company Name'>
                <Input
                  value={value}
                  onChange={onChange}
                  type='text'
                  inputName={name}
                  placeholderText='Company Name'
                />
              </InputContainer>
            )}
          />

          <Controller
            control={control}
            name='firstName'
            rules={{
              required: !companyNameState.isDirty && !lastNameState.isDirty,
            }}
            render={({ field: { name, onChange, value } }) => (
              <InputContainer htmlFor={name} label='First Name'>
                <Input
                  value={value}
                  onChange={onChange}
                  type='text'
                  inputName={name}
                  placeholderText='John'
                  data-autofocus
                />
              </InputContainer>
            )}
          />

          <Controller
            control={control}
            name='lastName'
            rules={{
              required: !companyNameState.isDirty && !firstNameState.isDirty,
            }}
            render={({ field: { name, onChange, value } }) => (
              <InputContainer htmlFor={name} label='Last Name'>
                <Input
                  value={value}
                  onChange={onChange}
                  type='text'
                  inputName={name}
                  placeholderText='Doe'
                />
              </InputContainer>
            )}
          />

          <Controller
            control={control}
            name='email'
            rules={{
              required: true,
              pattern: {
                value:
                  // eslint-disable-next-line no-control-regex
                  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                message: 'Please enter a valid email address.',
              },
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <InputContainer
                htmlFor={name}
                label='Email'
                error={error?.message}
              >
                <Input
                  inputName={name}
                  placeholderText={`mail@${theme.primaryColor.toLowerCase()}`}
                  type='email'
                  value={value}
                  onChange={onChange}
                  disabled={!!ownerStatus && ownerStatus !== 'unconfirmed'}
                  error={!!error}
                />
              </InputContainer>
            )}
          />

          <Controller
            control={control}
            name='listingIds'
            defaultValue={[]}
            render={({ field: { name, value, onChange } }) => {
              return (
                <InputContainer htmlFor={name} label='Listings'>
                  <Select
                    data={listings}
                    filterSelectedValues
                    setValue={(i) => onChange([...value, i.value])}
                    removeValue={(i) =>
                      onChange(value.filter((x) => x !== i.value))
                    }
                    value={(listings || []).filter((i) =>
                      (value || []).includes(i.value)
                    )}
                    placeholder='Owner Listings'
                    popoverWidth='target'
                    multiple
                    withBorder
                    withSearch
                  />
                </InputContainer>
              );
            }}
          />

          <Divider my='sm' />

          <Controller
            control={control}
            name='phone'
            render={({ field: { name, value, onChange } }) => (
              <InputContainer htmlFor={name} label='Phone'>
                <Input
                  inputName={name}
                  value={value}
                  onChange={onChange}
                  placeholderText='+23 1234 5678'
                  type='tel'
                />
              </InputContainer>
            )}
          />

          <Controller
            control={control}
            name='address'
            render={({ field: { name, value, onChange } }) => (
              <InputContainer htmlFor={name} label='Address'>
                <InputAddress
                  address={value}
                  onSubmit={(value) => onChange(value)}
                />
              </InputContainer>
            )}
          />
        </Stack>
        <ModalFooter>
          <Group position='apart'>
            <TransparentButton
              color={gray.dark}
              onClick={() => reset()}
              size='xs'
              leftIcon={<FontAwesomeIcon icon={faRotateLeft} />}
            >
              Reset
            </TransparentButton>
            <Group my={5}>
              <Controller
                control={control}
                name='sendInvite'
                render={({ field: { name, value, onChange } }) => (
                  <Switch
                    id={name}
                    name={name}
                    disabled={!!ownerId}
                    color='teal'
                    checked={value}
                    onChange={onChange}
                    offLabel='NO INVITE'
                    onLabel='SEND INVITE'
                    size='md'
                  />
                )}
              />
              <TransparentButton
                color={gray.dark}
                type='reset'
                onClick={onClose}
              >
                Cancel
              </TransparentButton>
              <ActionButton
                type='submit'
                loading={isSubmitting}
                disabled={!isDirty || !isValid}
              >
                {!ownerId ? 'Add Owner' : 'Save changes'}
              </ActionButton>
            </Group>
          </Group>
        </ModalFooter>
      </Box>
    </Modal>
  );
};

function useTeamListings({ ownerId }: { ownerId?: string }) {
  const [teamId] = useTeamId();

  const { data: listings } = useV2TransactionQuery(
    (q, args) => {
      return (
        q
          .listings({
            where: {
              status: { _neq: 'disabled' },
              ...(args.ownerId
                ? {
                    tenant: {
                      members: {
                        userId: { _eq: args.ownerId },
                      },
                    },
                  }
                : {
                    tenantId: { _eq: teamId },
                  }),
            },
            order_by: [{ name: 'asc_nulls_first' }],
          })
          .map((i) => ({ label: i.title || i.name || '', value: i.id })) || []
      );
    },
    {
      variables: {
        ownerId,
      },
      queryKey: 'listings',
    }
  );

  return { listings: listings || [] };
}
