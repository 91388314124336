import {
  gqlV2,
  useRunDrawer,
  useV2Client,
  useV2Mutation,
} from '@finalytic/data-ui';
import { showErrorNotification } from '@finalytic/ui';
import { waitUntil } from '@finalytic/utils';

export function useStatementGenerateMutation(
  automationId: string | undefined | null
) {
  const { runTask, setResult, runTaskLoading } = useRunDrawer();

  const client = useV2Client();

  // Legacy
  const [generateLegacy, { isLoading: loading }] = useV2Mutation(
    (q, args: gqlV2.generate_owner_statements_request) => {
      const res = q.generateOwnerStatements({ object: args });

      return {
        ok: res?.ok,
        data: res?.data,
      };
    },
    {
      invalidateQueryKeys: ['ownerStatements', 'ownerStatementTemplates'],
    }
  );

  const generate = async ({
    legacy,
    leftConnectionId,
    date,
    listingIds = [],
    refreshAfter,
  }: {
    legacy: boolean;
    leftConnectionId: string;
    date: string;
    listingIds: string[] | undefined;
    refreshAfter: (() => void) | undefined;
  }) => {
    if (!automationId)
      return showErrorNotification({ message: 'Missing automation id.' });

    const ids = listingIds.length > 0 ? listingIds : undefined;

    let taskId: string | undefined;

    if (legacy) {
      const res = await generateLegacy({
        args: {
          connectionId: leftConnectionId,
          date,
          listingIds: ids,
        },
      });

      taskId = res?.data?.runId;
      if (res?.data?.runId) setResult(res.data.runId);
    } else {
      const task = await runTask({
        id: automationId,
        input: listingIds,
        params: {
          month: date,
          listingId: ids,
        },
      });

      taskId = task?.runId;
    }

    if (refreshAfter) {
      await waitUntil(
        async () =>
          await client.query((q) => {
            const res = q.task({ id: taskId });

            return {
              id: res?.id,
              status: res?.status,
            };
          }),
        (res) => {
          console.log(res.status);

          return res?.status === 'success';
        },
        { retryEvery: '1s', timeoutAfter: '1m' }
      );

      refreshAfter();
    }
    return taskId;
  };

  return {
    generate,
    loading: loading || runTaskLoading,
  };
}
