import {
  AddListingToOwnerButton,
  AddListingToOwnerButtonProps,
} from '../button';
import { useColors } from '@finalytic/ui';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group, Text } from '@mantine/core';

type Props = {
  values: { label: string; id: string }[];
  icon: IconDefinition;
};

export const OwnerListingsCell = ({
  icon,
  refetchTable,
  select,
  values,
  teamId,
  id,
  buttonText,
  openAddOwnerModal,
}: AddListingToOwnerButtonProps & Props) => {
  const maxDisplayedValues = 2;
  const { gray } = useColors();

  if (!id) return null;

  if (values.length < 1)
    return (
      <AddListingToOwnerButton
        id={id}
        currentIds={[]}
        select={select}
        teamId={teamId}
        refetchTable={refetchTable}
        openAddOwnerModal={openAddOwnerModal}
        buttonText={buttonText}
      />
    );

  return (
    <Group noWrap spacing='sm' align='center' sx={{ height: '100%' }}>
      {values.slice(0, maxDisplayedValues).map((listing) => (
        <Group
          key={listing.id}
          noWrap
          spacing={6}
          px={8}
          py={1}
          sx={(theme) => ({
            borderRadius: theme.radius.sm,
            backgroundColor: theme.colors.gray[2],
            display: 'flex',
          })}
        >
          <FontAwesomeIcon icon={icon} color={gray.dark} />
          <Text
            size='xs'
            lineClamp={1}
            sx={{ maxWidth: 130, display: 'block' }}
          >
            {listing.label}
          </Text>
        </Group>
      ))}
      {values.length > maxDisplayedValues && (
        <Text color='gray' size='xs'>
          &amp; {values.length - maxDisplayedValues} more
        </Text>
      )}
    </Group>
  );
};
