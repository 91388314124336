import { Popover, PopoverProps, Stack } from '@mantine/core';
import { ReactNode } from 'react';

export const FilterPopover = (props: PopoverProps & { target: ReactNode }) => {
  return (
    <Popover position='bottom-start' radius={10} {...props} shadow='md'>
      <Popover.Target>{props.target}</Popover.Target>
      <Popover.Dropdown>
        <Stack>{props.children}</Stack>
      </Popover.Dropdown>
    </Popover>
  );
};
