import { Table } from '@finalytic/ui';

import { IServerSideDatasource } from '@finalytic/ui-grid';

import {
  gqlV2,
  useTasksTableColumnDefs,
  useV2Client,
} from '@finalytic/data-ui';
import { toTitleCase } from '@finalytic/utils';
import { useMemo, useState } from 'react';

type Props = { where: gqlV2.action_bool_exp };

type RowData = {
  id: string;
  message: string | undefined;
  status: string | undefined;
  title: string | undefined;
};

export const ActionTable = (props: Props) => {
  const { dataSource, paginationSize } = useDatasource(props);

  const colDefs = useTasksTableColumnDefs();

  return (
    <Table
      columnDefs={colDefs}
      serverSideDatasource={dataSource}
      rowModelType='serverSide'
      serverSideInfiniteScroll
      cacheBlockSize={paginationSize}
      paginationPageSize={paginationSize}
      isTopRounded={false}
      animateRows
      getRowId={({ data }) => data?.id}
      defaultColDef={{
        resizable: false,
        suppressMovable: true,
      }}
    />
  );
};

export function useDatasource({ where }: Props) {
  const [refetchKey, setRefetchKey] = useState(0);
  const client = useV2Client();
  const rowsPerPage = 100;

  const dataSource = useMemo<IServerSideDatasource>(() => {
    console.log('Refresh datasource');
    const fn = async ({ offset, limit }: { offset: number; limit: number }) => {
      console.log(`Refresh data ${offset}/${limit}`);

      const data = await client.query(
        (query) => {
          const aggregate =
            query.aggregateActions({ where }).aggregate?.count() || 0;

          const list = query
            .actions({
              where,
              limit,
              offset,
              order_by: [
                {
                  createdAt: 'asc',
                },
              ],
            })
            .map<RowData>((action) => {
              return {
                id: action.id,
                status: action.status || '',
                message: action.title || toTitleCase(action.status),
                type: action.schema?.uniqueRef || '',
                //outputFile: action.output?.dataHref || '',
                //inputFile: task.input?.dataHref || '',
                title: action.uniqueRef,
              };
            });
          return { list, aggregate };
        },
        {
          noCache: true,
        }
      );
      return data;
    };
    return {
      getRows: (params) => {
        fn({
          offset: params.request.startRow || 0,
          limit: rowsPerPage,
        })
          .then((data) => {
            console.log('Refresh datasource success');
            const rowData = data?.list || [];

            if (data && rowData.length === 0) {
              params.api.showNoRowsOverlay();
            } else {
              params.api.hideOverlay();
            }

            params.success({
              rowData,
              rowCount: data?.aggregate,
            });
          })
          .catch(() => params.fail());
      },
    };
  }, [where, refetchKey]);
  return {
    dataSource,
    refetch: () => {
      console.log('Set refresh key datasource');
      setRefetchKey((key) => key + 1);
    },
    paginationSize: rowsPerPage,
  };
}
