export function useCellSelectTypes() {
  return [
    { value: 'field', label: 'Field' },
    { value: 'metadata', label: 'Metadata' },
    { value: 'sumAccounts', label: 'Collections' },
    { value: 'sumColumns', label: 'Sum of columns' },
    { value: 'subtractColumns', label: 'Difference of columns' },
    { value: 'formula', label: 'Formula' },
  ];
}

export const field_select_options = [
  { value: 'reservation.guestName', label: 'Guest name' },
  { value: 'reservation.confirmationCode', label: 'Confirmation code' },
  { value: 'reservation.checkIn', label: 'Reservation Dates' },
  { value: 'total', label: 'Total' },
];
