import { Box, Sx } from '@mantine/core';
import { ReactNode } from 'react';

type ModalFooterProps = {
  children: ReactNode;
  sx?: Sx;
};

export const ModalFooter = ({ children, sx }: ModalFooterProps) => (
  <Box
    py={10}
    px={20}
    sx={{
      position: 'sticky',
      bottom: 0,
      marginLeft: '-1rem',
      marginRight: '-1rem',
      backgroundColor: '#F4F4F7',
      borderRadius: '0 0 10px 10px',
      ...sx,
    }}
  >
    {children}
  </Box>
);
