export const ReportsIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.47727 20.738H18.4643C19.8647 20.738 21 19.6027 21 18.2022V13.1308C21 12.7489 20.6904 12.4393 20.3084 12.4393H17.0812V4.70508C17.0812 3.60051 16.1857 2.70508 15.0812 2.70508H5.25C4.14543 2.70508 3.25 3.60051 3.25 4.70508V17.5107C3.25 19.2931 4.6949 20.738 6.47727 20.738ZM17.3117 18.2022V13.8224H19.6169V18.2022C19.6169 18.8389 19.1009 19.3548 18.4643 19.3548C17.8277 19.3548 17.3117 18.8389 17.3117 18.2022ZM6.47729 7.25C6.06308 7.25 5.72729 7.58579 5.72729 8C5.72729 8.41421 6.06308 8.75 6.47729 8.75H10.1656C10.5798 8.75 10.9156 8.41421 10.9156 8C10.9156 7.58579 10.5798 7.25 10.1656 7.25H6.47729ZM5.72729 11C5.72729 10.5858 6.06308 10.25 6.47729 10.25H13.8539C14.2681 10.25 14.6039 10.5858 14.6039 11C14.6039 11.4142 14.2681 11.75 13.8539 11.75H6.47729C6.06308 11.75 5.72729 11.4142 5.72729 11ZM6.47729 13.25C6.06308 13.25 5.72729 13.5858 5.72729 14C5.72729 14.4142 6.06308 14.75 6.47729 14.75H13.8539C14.2681 14.75 14.6039 14.4142 14.6039 14C14.6039 13.5858 14.2681 13.25 13.8539 13.25H6.47729Z'
      fill='url(#paint0_linear_1705_41995)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_1705_41995'
        x1='3.25'
        y1='3.7069'
        x2='16.4002'
        y2='23.321'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.6' />
        <stop offset='1' stopColor='white' stopOpacity='0.35' />
      </linearGradient>
    </defs>
  </svg>
);
