import { IconButton, LoadingIndicator } from '@finalytic/ui';
import { faPaperPlane, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Center,
  Group,
  ScrollArea,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useEffect, useState } from 'react';

export const HubspotContactForm = ({
  region,
  portalId,
  formId,
  closePopover,
}: {
  region: string;
  portalId: string;
  formId: string;
  closePopover?: () => void;
}) => {
  const [loading, setLoading] = useState(true);
  const { colors } = useMantineTheme();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    const createForm = () => {
      // @TS-ignore
      if ((window as any).hbspt) {
        // @TS-ignore
        (window as any).hbspt.forms.create({
          region,
          portalId,
          formId,
          target: '#hubspotForm',
        });

        setLoading(false);
      }
    };

    script.addEventListener('load', createForm);

    return () => {
      script.removeEventListener('load', createForm);
    };
  }, []);

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Group
        position='apart'
        pl='sm'
        pr={7}
        pt={5}
        pb={7}
        sx={(theme) => ({
          background: 'white',
          boxShadow: theme.shadows.md,
        })}
      >
        <Group>
          <FontAwesomeIcon color={colors.gray[6]} icon={faPaperPlane} />{' '}
          <Text color={colors.gray[6]} weight={500}>
            Customer Support
          </Text>
        </Group>
        {closePopover && (
          <IconButton onClick={closePopover}>
            <FontAwesomeIcon size='xs' icon={faX} />
          </IconButton>
        )}
      </Group>
      <ScrollArea
        pt='xs'
        type='always'
        offsetScrollbars
        // mah={500}
        h={500}
        w={300}
        mah={'70vh'}
        maw={'100%'}
      >
        {loading && (
          <Center sx={{ height: '100%', minHeight: 200 }}>
            <LoadingIndicator size='md' />
          </Center>
        )}

        <Box sx={(theme) => ({ padding: theme.spacing.xs })} id='hubspotForm' />
      </ScrollArea>
    </Box>
  );
};
