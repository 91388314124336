import { NavbarRoute, NavbarSection } from '../components';
import {
  useMe,
  useOwnerPreviewId,
  useTeamId,
  useV2TransactionQuery,
} from '../hooks';
import { getStatementYearlySummaryHref } from '@finalytic/owner-statements';
import {
  ListingsIcon,
  ReportsIcon,
  ReservationsIcon,
  StatementsIcon,
} from '@finalytic/ui';
import { utc } from '@finalytic/utils';

export const OwnerNavigation = ({
  navbarIsExpanded,
}: {
  navbarIsExpanded: boolean;
}) => {
  const [teamId] = useTeamId();
  const {
    ownerPreview: { userId: ownerId },
  } = useOwnerPreviewId();
  const { id: meId } = useMe();
  const { data } = useV2TransactionQuery(
    (q, args) => {
      const settings = q
        .setting({
          where: {
            key: { _eq: 'tenantSettings' },
            group: { _eq: 'ownerPortal' },
            tenant_id: { _eq: args.teamId },
          },
        })
        .map((setting) => ({
          id: setting.id,
          target: setting.target,
        }));

      const statements = q
        .ownerStatements({
          where: {
            tenantId: { _eq: args.teamId },
            status: { _in: ['posted', 'published'] },
          },
          limit: 1,
          order_by: [{ updatedAt: 'desc_nulls_last' }],
        })
        .map((statement) => ({
          id: statement.id,
          startAt: statement.startAt,
        }));

      return {
        settings,
        statements,
      };
    },
    {
      variables: { teamId },
      skip: !teamId,
      queryKey: ['settings', 'ownerStatements'],
    }
  );

  const statements = data?.statements || [];
  const date = statements[0]?.startAt || utc();

  const showReservations = !!data?.settings.find(
    (i) => i.target === 'showReservations'
  );

  return (
    <>
      <NavbarSection>
        <NavbarRoute
          title='Statements'
          link='/statements'
          icon={<StatementsIcon />}
          navbarIsExpanded={navbarIsExpanded}
        />
        <NavbarRoute
          title='Summary'
          link={getStatementYearlySummaryHref({
            ownerId: ownerId || meId,
            date,
          })}
          icon={<ReportsIcon />}
          navbarIsExpanded={navbarIsExpanded}
        />
      </NavbarSection>

      <NavbarSection>
        {showReservations && (
          <NavbarRoute
            title='Reservations'
            link='/reservations'
            icon={<ReservationsIcon />}
            navbarIsExpanded={navbarIsExpanded}
          />
        )}

        <NavbarRoute
          title='Listings'
          link='/listings'
          icon={<ListingsIcon />}
          navbarIsExpanded={navbarIsExpanded}
        />
      </NavbarSection>

      <NavbarSection noBorderBottom>
        <NavbarRoute
          title='Files'
          link='/files'
          icon={<ReportsIcon />}
          navbarIsExpanded={navbarIsExpanded}
        />
      </NavbarSection>
    </>
  );
};
