import { useNotifiedV2Mutation } from '../../hooks';
import { showErrorNotification } from '@finalytic/ui';

export function useFileDownload() {
  const { mutate, loading } = useNotifiedV2Mutation(
    (q, args: { fileId: string }) => {
      const res = q.requestFileDownload({
        object: { id: args.fileId },
      });

      return res?.data?.signedUrl;
    },
    { successMessage: { message: 'Successful file download.' } }
  );

  const download = async (fileId: string) => {
    const url = await mutate({ args: { fileId } });

    if (url) {
      window.open(url, '_blank');
    } else {
      console.log(url);
      showErrorNotification({ message: 'Missing download url.' });
    }
  };

  return {
    download,
    loading,
  };
}
