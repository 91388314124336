import { StatementFormulaEditor } from '../../../components';
import { SectionSettingColumnType } from '../_types';
import { field_select_options } from './_select-options';
import { NetRevenueColumn } from '@finalytic/common/src';
import { InputFormula, Select, SelectItem } from '@finalytic/ui';
import { ICellEditorParams, ICellRendererParams } from '@finalytic/ui-grid';
import { expression } from '@finalytic/utils';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Anchor,
  Box,
  Center,
  Flex,
  Group,
  Text,
  ThemeIcon,
} from '@mantine/core';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';

export const CELL_HEIGHT = 48;

export const SelectColumnValueEditorCell = forwardRef(
  (params: ICellEditorParams, ref) => {
    const gridData: SectionSettingColumnType[] = [];
    params.api.forEachNode((node) => gridData.push(node.data));

    const availableColumns = gridData
      .filter((row) => row.type === 'sumAccounts' && row.id !== params.data.id)
      .map((row) => ({ value: row.id, label: row.name }));

    const collectionColumns = gridData as NetRevenueColumn[];

    const value: (string | undefined) | string[] = params?.value;

    const rowType = params.data.type;

    const [singleSelectValue, setSingleSelectValue] = useState<string>(
      value as string
    );
    const [multiSelectValue, setMutliSelectValue] = useState<string[]>(
      value as string[]
    );

    const activeValues = useMemo(() => {
      switch (rowType) {
        case 'sumColumns':
          return availableColumns.filter((i) =>
            multiSelectValue.includes(i.value)
          );
        case 'subtractColumns':
          return multiSelectValue
            .map((id: string) => availableColumns.find((i) => i.value === id))
            .filter((i) => Boolean(i?.value)) as SelectItem[];
        default:
          return [];
      }
    }, [rowType, availableColumns, multiSelectValue]);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          if (rowType === 'field') return singleSelectValue;
          if (rowType === 'formula') {
            try {
              // beautify formula when correct
              const tree = expression.toTree(singleSelectValue);
              return expression.toString(tree);
            } catch (error) {
              return singleSelectValue;
            }
          }
          return multiSelectValue;
        },
      };
    });

    useEffect(() => {
      if (rowType === 'field' && singleSelectValue !== value) {
        params.stopEditing();
      }
    }, [singleSelectValue]);

    if (rowType === 'field') {
      return (
        <Box w='100%'>
          <Select
            data={field_select_options}
            setValue={(item) => setSingleSelectValue(item.value)}
            removeValue={() => params.stopEditing()}
            value={field_select_options.find((i) => i.value === value)}
            popoverWidth='target'
            preventClose
            defaultOpen
            withSearch
            withBorder={false}
            onClose={params.stopEditing}
            offset={10}
          />
        </Box>
      );
    }

    if (rowType === 'formula') {
      return (
        <Flex direction='row' sx={{ '&, > * ': { width: '100%' } }}>
          <StatementFormulaEditor
            collectionColumns={collectionColumns}
            value={singleSelectValue}
            setValue={setSingleSelectValue}
            cellWidth={params.eGridCell.clientWidth}
            onEnter={params.stopEditing}
          />
        </Flex>
      );
    }

    if (rowType === 'subtractColumns' || rowType === 'sumColumns') {
      return (
        <Box sx={{ backgroundColor: 'white', width: '100%', minWidth: 400 }}>
          <Select
            multiple
            data={availableColumns.filter(
              (i) => !multiSelectValue.includes(i.value)
            )}
            value={activeValues}
            setValue={(item) => {
              if (item?.value) setMutliSelectValue((e) => [...e, item.value]);
            }}
            removeValue={(item) => {
              if (item?.value) {
                setMutliSelectValue((e) =>
                  e.filter((i: string) => i !== item.value)
                );
              }
            }}
            offset={10}
            popoverWidth='target'
            onClose={params.stopEditing}
            withSearch
            defaultOpen
            withBorder={false}
          />
        </Box>
      );
    }

    return null;
  }
);

SelectColumnValueEditorCell.displayName = 'Select Column Value Editor Cell';

export const SelectColumnValueCell = (params: ICellRendererParams) => {
  const rowType: string | undefined = params.data?.type;

  let text: string | undefined = undefined;

  switch (rowType) {
    case 'field':
      text = field_select_options.find((i) => i.value === params.value)?.label;
      break;

    case 'metadata':
    case 'formula':
      break;

    default: {
      const gridData: string[] = [];
      params.api.forEachNode((node) => {
        if (params.value.includes(node.data.id)) {
          gridData.push(node.data.name);
        }
      });
      text = gridData.join(', ');
      break;
    }
  }

  if (rowType === 'metadata') {
    return (
      <Center inline h={CELL_HEIGHT}>
        {params.value}
      </Center>
    );
  }

  if (rowType === 'formula') {
    const value = params.value;
    return (
      <Box h={CELL_HEIGHT}>
        <InputFormula
          value={value}
          setValue={() => null}
          placeholder=''
          fields={[]}
          rounded={false}
          withBorder={false}
          disabled
          hideIcon={!value}
        />
      </Box>
    );
  }

  if (rowType !== 'sumAccounts') {
    return (
      <Group
        position={text ? 'apart' : 'right'}
        noWrap
        sx={{ height: '', minHeight: CELL_HEIGHT }}
      >
        <Text
          component='span'
          sx={{ color: 'inherit', display: 'block' }}
          lineClamp={1}
        >
          {text}
        </Text>
        <ThemeIcon color='dark' variant='outline' sx={{ border: 'none' }}>
          <FontAwesomeIcon size='sm' icon={faChevronDown} />
        </ThemeIcon>
      </Group>
    );
  }

  return (
    <Center inline h={CELL_HEIGHT}>
      <Anchor
        component={Link}
        to={`account-mapping?mapping=${params.data.id}`}
        sx={{ color: 'inherit', display: 'block' }}
        lineClamp={1}
      >
        {params.value.length === 1
          ? `${params.value.length} account mapped`
          : `${params.value.length} accounts mapped`}{' '}
        &rarr;
      </Anchor>
    </Center>
  );
};
