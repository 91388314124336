import { DelayedInputSearch } from '../../inputs';
import { LoadingIndicator } from '../../loading-indicator';
import { FilterPopover, PopoverButton, PopoverHeader } from '../_components';
import { SegmentedControl } from './SegmentedControl';
import { Box, Center, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMemo, useState } from 'react';

export type SwitchFilterDataType = { label: string; value: string }[];

type SwitchFilterProps = {
  data: SwitchFilterDataType;
  defaulButtonLabel: string;
  withSearch: boolean;
  filterValue?: string;
  setFilterValue: (value: string | undefined) => void;
  icon?: ({ invertColor }: { invertColor?: boolean }) => JSX.Element;
  loading?: boolean;
};

export const SwitchFilter = ({
  data,
  defaulButtonLabel,
  withSearch,
  filterValue,
  setFilterValue,
  icon,
  loading,
}: SwitchFilterProps) => {
  const [opened, handlers] = useDisclosure(false);
  const [searchInput, setSearchInput] = useState('');

  if (!data) return null;

  const onClosePopover = () => {
    handlers.close();
  };

  const filteredData = useMemo(
    () =>
      data.filter(({ label }) =>
        label?.toLowerCase().includes(searchInput.toLowerCase())
      ),
    [data, searchInput]
  );

  const resetFilter = () => {
    handlers.close();
    setFilterValue(undefined);
  };

  const title = useMemo(() => {
    return (
      data.find((x) => x.value === filterValue)?.label ||
      filterValue ||
      defaulButtonLabel
    );
  }, [data, filterValue]);
  return (
    <FilterPopover
      opened={opened}
      onClose={onClosePopover}
      target={
        <PopoverButton
          onClick={handlers.toggle}
          title={title}
          Icon={icon}
          isActive={!!filterValue}
        />
      }
    >
      <PopoverHeader
        title={defaulButtonLabel}
        handleFilterReset={resetFilter}
      />

      <Box sx={{ minWidth: 170 }}>
        {loading ? (
          <Center>
            <LoadingIndicator size='sm' />
          </Center>
        ) : (
          <Stack>
            {withSearch && (
              <DelayedInputSearch
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                placeholder='Search...'
                autoFocus
              />
            )}
            <Box sx={{ marginTop: !withSearch ? -15 : 0 }}>
              {filteredData.length > 0 ? (
                <SegmentedControl
                  data={filteredData}
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                />
              ) : (
                <Text mb={6} align='center' size={13} color='gray' weight={500}>
                  No options available...
                </Text>
              )}
            </Box>
          </Stack>
        )}
      </Box>
    </FilterPopover>
  );
};
