import { StatementTemplate } from '../_types';
import { Maybe, sortBy } from '@finalytic/utils';

export const getStatementTemplate = <
  T extends { template: Maybe<StatementTemplate> }
>(
  statements: T[]
) =>
  sortBy(
    (statements || []).map((st) => st.template),
    (x) => x?.version || 0
  ).reverse()[0];
