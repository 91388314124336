import { useColors } from '../../../styles';
import { ActionButton, TransparentButton } from '../../buttons';
import { InputContainer } from '../../input-container';
import { Modal } from '../Modal';
import { ModalFooter } from '../ModalFooter';
import { Controller, SubmitErrorHandler, useForm } from 'react-hook-form';

import { showErrorNotification, showSuccessNotification } from '../../../hooks';
import { Input, InputPassword, passwordRegex } from '../../inputs';
import { Box, Group, Stack, Tooltip } from '@mantine/core';

export type SetupAccountSubmitType = (value: {
  newPass: string;
  firstName: string;
  lastName: string;
}) => Promise<{ ok: boolean; message?: string; title?: string }>;

type SetupAccountModalProps = {
  opened: boolean;
  onClose: () => void;
  onSubmit: SetupAccountSubmitType;
  hideCancel?: boolean;
  firstName?: string;
  lastName?: string;
};

type FormInputs = {
  setupFirstName: string;
  setupLastName: string;
  setupNewPass: string;
  setupVerifyPass: string;
};

export const SetupAccountModal = ({
  opened,
  onClose,
  onSubmit,
  hideCancel = false,
  firstName,
  lastName,
}: SetupAccountModalProps) => {
  const { gray } = useColors();

  const closeDuration = 4000;

  const { control, formState, handleSubmit } = useForm<FormInputs>({
    mode: 'onSubmit',
  });

  const submit = async (data: FormInputs) => {
    if (data.setupNewPass !== data.setupVerifyPass) {
      showErrorNotification({
        title: "Passwords don't match",
        message: 'Your password are not matching please try again.',
        autoClose: closeDuration,
      });
      return;
    }

    await onSubmit({
      newPass: data.setupNewPass,
      firstName: data.setupFirstName,
      lastName: data.setupLastName,
    }).then((data) => {
      if (!data.ok) {
        showErrorNotification({
          title: data.title || 'Error updating.',
          message:
            data.message || 'Please reach out to us if this error persists.',
          autoClose: closeDuration,
        });
        return;
      }

      showSuccessNotification({
        title: 'Update successful!',
        message: 'Your password was sucessfully updated.',
      });
    });
  };

  const submitInvalid: SubmitErrorHandler<FormInputs> = (errors) => {
    const values = Object.entries(errors);

    showErrorNotification({
      message:
        values.length > 0 ? (
          <Box component='ol' my={0}>
            {values.map(([key, value]) => (
              <li key={key}>{value.message}</li>
            ))}
          </Box>
        ) : (
          'Please reach out to us if this error persists.'
        ),
      autoClose: closeDuration,
    });
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={628}
      title={'Setup Account'}
      withCloseButton={false}
    >
      <Box>
        <Stack mt={20} mb={50} spacing={24}>
          <Controller
            name='setupFirstName'
            defaultValue={firstName}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Please enter a first name.',
              },
            }}
            render={({
              field: { value, onChange, name },
              fieldState: { error },
            }) => (
              <InputContainer
                htmlFor={name}
                label={'First Name'}
                error={error?.message}
              >
                <Input
                  value={value}
                  onChange={onChange}
                  inputName={name}
                  placeholderText='First Name'
                  type='text'
                  error={!!error}
                />
              </InputContainer>
            )}
          />
          <Controller
            name='setupLastName'
            defaultValue={lastName}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Please enter a last name.',
              },
            }}
            render={({
              field: { value, onChange, name },
              fieldState: { error },
            }) => (
              <InputContainer
                htmlFor={name}
                label={'Last Name'}
                error={error?.message}
              >
                <Input
                  value={value}
                  onChange={onChange}
                  inputName={name}
                  placeholderText='Last Name'
                  type='text'
                  error={!!error}
                />
              </InputContainer>
            )}
          />
          <Controller
            name='setupNewPass'
            defaultValue=''
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Please enter a password.',
              },
              pattern: {
                value: passwordRegex,
                message:
                  'Please enter a lower/uppercase letter, one number and at least 8 characters.',
              },
            }}
            render={({
              field: { value, onChange, name },
              fieldState: { error },
            }) => (
              <InputContainer
                htmlFor={name}
                label={'Password'}
                error={error?.message}
              >
                <InputPassword
                  value={value}
                  onChange={onChange}
                  name={name}
                  placeholder='Password'
                  error={error?.message}
                />
              </InputContainer>
            )}
          />
          <Controller
            name='setupVerifyPass'
            defaultValue=''
            control={control}
            render={({
              field: { value, onChange, name },
              fieldState: { error },
            }) => (
              <InputContainer
                htmlFor={name}
                label='Confirm password'
                error={error?.message}
              >
                <InputPassword
                  value={value}
                  onChange={onChange}
                  name={name}
                  withPopover={false}
                  error={error?.message}
                  placeholder='Confirm password'
                />
              </InputContainer>
            )}
          />
        </Stack>
        <ModalFooter>
          <Group position='right' my={5}>
            {!hideCancel && (
              <TransparentButton onClick={onClose} color={gray.dark}>
                Cancel
              </TransparentButton>
            )}
            <Tooltip
              label='Please enter a lower/uppercase letter, one number and at least 8 characters.'
              disabled={formState.isValid}
              radius={8}
              withArrow
              position='top-end'
              styles={{
                arrow: {
                  right: '10px !important',
                },
              }}
            >
              <Box>
                <ActionButton
                  loading={formState.isSubmitting}
                  onClick={handleSubmit(submit, submitInvalid)}
                >
                  Submit
                </ActionButton>
              </Box>
            </Tooltip>
          </Group>
        </ModalFooter>
      </Box>
    </Modal>
  );
};
