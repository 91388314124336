import { useTeam, useTeamId, useV2TransactionQuery } from '../hooks';

export function useTeamDeletionEventQuery() {
  const [{ id: teamId, status: teamStatus }] = useTeam();

  const hasScheduledDeletion = teamStatus === 'archived';

  const {
    data: event,
    isLoading: loading,
    refetch,
  } = useV2TransactionQuery(
    (q, args) => {
      const events = q.scheduledEvents({
        where: {
          objectId: { _eq: args.teamId },
          tenantId: { _eq: args.teamId },
          status: { _in: ['scheduled', 'pending'] },
          tableName: { _eq: 'tenant' },
          op: { _eq: 'deleteTeam' },
        },
        limit: 1,
        order_by: [{ createdAt: 'desc' }],
      });

      const event = events?.[0];

      return {
        id: event?.id,
        date: event?.scheduledAt,
      };
    },
    {
      skip: !teamId,
      variables: {
        teamId,
      },
      queryKey: 'scheduledEvents',
    }
  );

  return {
    event,
    loading,
    refetch,
    hasScheduledDeletion: hasScheduledDeletion || !!event?.id,
  };
}

export function useConnectionDeletionEventQuery(
  connectionId: string | undefined
) {
  const [teamId] = useTeamId();

  const {
    data: event,
    isLoading: loading,
    refetch,
  } = useV2TransactionQuery(
    (q, args) => {
      const events = q.scheduledEvents({
        where: {
          objectId: { _eq: args.connectionId },
          tenantId: { _eq: args.teamId },
          status: { _in: ['scheduled', 'pending'] },
          tableName: { _eq: 'connection' },
          op: { _eq: 'deleteConnection' },
        },
        limit: 1,
        order_by: [{ createdAt: 'desc' }],
      });

      const event = events?.[0];

      return {
        id: event?.id,
        date: event?.scheduledAt,
      };
    },
    {
      skip: !connectionId,
      variables: {
        teamId,
        connectionId,
      },
      queryKey: 'scheduledEvents',
    }
  );

  return {
    event,
    loading,
    refetch,
    hasScheduledDeletion: !!event?.id,
  };
}
