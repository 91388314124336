export const CopyIdIcon = ({ color }: { color?: string }) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.33334 10H2.66668C1.9303 10 1.33334 9.40311 1.33334 8.66671V2.66671C1.33334 1.93033 1.9303 1.33337 2.66668 1.33337H8.66668C9.40308 1.33337 10 1.93033 10 2.66671V3.33337M7.33334 6.00004H13.3333C14.0697 6.00004 14.6667 6.59699 14.6667 7.33337V13.3334C14.6667 14.0698 14.0697 14.6667 13.3333 14.6667H7.33334C6.59696 14.6667 6.00001 14.0698 6.00001 13.3334V7.33337C6.00001 6.59699 6.59696 6.00004 7.33334 6.00004Z'
      stroke={color || '#5C6178'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
