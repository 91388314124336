import { useNotifiedV2Mutation, useTeamId } from '../../../hooks';
import {
  ActionButton,
  Input,
  InputContainer,
  Modal,
  ModalFooter,
  TransparentButton,
  showErrorNotification,
  useColors,
} from '@finalytic/ui';
import { Box, Group, Stack } from '@mantine/core';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

type AddTeamModalProps = {
  opened: boolean;
  onClose: () => void;
  modalTitle?: string;
  teamName?: string;
};

type FormInputs = { teamName: string };

export const AddTeamModal = ({
  opened,
  onClose,
  modalTitle = 'Add New Team',
  teamName,
}: AddTeamModalProps) => {
  const { gray } = useColors();

  const [, switchTeam] = useTeamId();
  const navigate = useNavigate();

  const { mutate: insert } = useNotifiedV2Mutation(
    (q, args: { name: string }) => {
      const res = q.addTeam({ object: { name: args.name } });

      return {
        ok: !!res.ok,
        data: res?.data,
      };
    },
    {
      successMessage: {
        message: 'Your team was successfully created.',
      },
    }
  );
  const {
    formState,
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<FormInputs>({
    mode: 'onChange',
  });

  useEffect(() => {
    resetForm({ teamName });
  }, [teamName]);

  const submit = async (data: FormInputs) => {
    if (!data.teamName?.trim())
      return showErrorNotification({
        title: 'Invalid name',
        message: 'Please enter a valid team name.',
        color: 'yellow',
      });

    try {
      const result = await insert({
        args: { name: data.teamName.trim() },
      });

      const newTeamId = result?.data?.id;
      if (newTeamId) {
        switchTeam(newTeamId);
      }
      resetForm();
      navigate('/');

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} size='lg' title={modalTitle}>
      <Box component='form' onSubmit={handleSubmit(submit)}>
        <Stack mt={20} mb={50} spacing={20}>
          <Controller
            name='teamName'
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { name, onChange, value } }) => (
              <InputContainer htmlFor={name} label='Team Name'>
                <Input
                  inputName={name}
                  onChange={onChange}
                  value={value}
                  type='text'
                  placeholderText={'Enter a team name'}
                  data-autofocus
                  required
                />
              </InputContainer>
            )}
          />
        </Stack>
        <ModalFooter>
          <Group position='right' my={5}>
            <TransparentButton color={gray.dark} onClick={onClose}>
              Cancel
            </TransparentButton>
            <ActionButton
              type='submit'
              loading={formState.isSubmitting}
              disabled={!formState.isValid}
            >
              Create Team
            </ActionButton>
          </Group>
        </ModalFooter>
      </Box>
    </Modal>
  );
};
