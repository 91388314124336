import { Maybe, source } from '@finalytic/graphql';

export function getSourceDescription(source: Maybe<source> | undefined) {
  const remoteId = source?.remoteId || '';
  const status = source?.status || '';
  const description = source?.description || '';

  const prefix = remoteId && remoteId.length < 10 ? `[${remoteId}] ` : '';
  return (
    (status === 'inactive'
      ? `**ARCHIVED** ${prefix}${description}`
      : `${prefix}${description || 'No description'}`) || ''
  );
}
