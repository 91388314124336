import { useOwnerPreviewId } from './owner';
import { useMe } from './team';
import * as React from 'react';

export type dashboardTypes = 'admin' | 'partner' | 'propertyManager' | 'owner';

export const dashboardContext = React.createContext<
  [dashboardTypes, (dashboardType: dashboardTypes) => void]
>(['propertyManager', () => undefined]);

export function DashboardTypeProvider({
  children,
  dashboard,
}: {
  children: React.ReactNode;
  dashboard?: dashboardTypes;
}) {
  const { ownerPreview } = useOwnerPreviewId();
  const [dashboardType, setDashboardType] =
    React.useState<dashboardTypes>('propertyManager');

  const user = useMe();
  React.useEffect(() => {
    if (user?.currentTeam?.type) {
      if (ownerPreview?.userId || user?.type === 'owner') {
        setDashboardType('owner');
      } else {
        setDashboardType(
          (user?.currentTeam?.type as dashboardTypes) || 'propertyManager'
        );
      }
    }
  }, [user?.currentTeam?.id, ownerPreview?.userId, user?.type]);

  const dashboardValue = React.useMemo(
    () => [dashboard || dashboardType, setDashboardType],
    [dashboardType, dashboard]
  );

  return (
    <dashboardContext.Provider value={dashboardValue as any}>
      {children}
    </dashboardContext.Provider>
  );
}

export function useDashboardType() {
  const role = React.useContext(dashboardContext);
  return role;
}
