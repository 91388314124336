import { Switch as MSwitch, SwitchProps } from '@mantine/core';

export const Switch = ({ sx, styles, ...props }: SwitchProps) => {
  return (
    <MSwitch
      {...props}
      sx={{ display: 'flex', ...sx }}
      styles={(theme, params, context) => ({
        track: { cursor: 'pointer' },
        ...(typeof styles === 'function'
          ? styles(theme, params, context)
          : styles || {}),
      })}
    />
  );
};
