export const ReconnectIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4 4.66663H12V7.99996C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 7.99996V4.66663Z'
      stroke='#5C6178'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 1.33337V4.66671'
      stroke='#5C6178'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 12V14.6667'
      stroke='#5C6178'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6 1.33337V4.66671'
      stroke='#5C6178'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
