import { CalendarFilterDateType, SelectButton } from '../..';
import { RangeCalendar } from './RangeCalendar';
import { day } from '@finalytic/utils';
import {
  Box,
  Divider,
  Group,
  SegmentedControl,
  Stack,
  Text,
} from '@mantine/core';
import { useEffect, useState } from 'react';

export type CheckInOutType = 'all' | 'checkIn' | 'checkOut' | 'bookedAt';
export type CheckInOutTypeHandler = (value: CheckInOutType) => void;

const SwitchData = [
  { label: 'Within', value: 'all' },
  { label: 'Check In', value: 'checkIn' },
  { label: 'Check Out', value: 'checkOut' },
  { label: 'Booked', value: 'bookedAt' },
];

export type ExtendedCalendarProps = {
  dateType?: CheckInOutType;
  setDateType?: CheckInOutTypeHandler;
  filterValue?: CalendarFilterDateType;
  setFilterValue: (value: CalendarFilterDateType | undefined) => void;
};

export const ExtendedCalendar = ({
  filterValue: dateValue,
  setFilterValue: setDateValue,
  dateType,
  setDateType,
}: ExtendedCalendarProps) => {
  const [buttonLabel, setButtonLabel] = useState('');
  const filterIsActive = !!(dateValue && !dateValue?.includes(null));

  useEffect(() => {
    if (filterIsActive && dateValue) {
      const first = day(dateValue[0]);
      const second = day(dateValue[1]);
      const firstDateFormat =
        first.year() === second.year() ? 'MMM DD' : 'MMM DD YYYY';
      const firstDate = first.format(firstDateFormat);
      const secondDate = second.format('MMM DD YYYY');
      setButtonLabel(`${firstDate} - ${secondDate}`);
    } else {
      setButtonLabel('');
    }
  }, [dateValue]);

  const selectOptions = [
    'Past Week',
    'Past Month',
    'Past Quarter',
    'Past Year',
  ] as const;

  const [selectOption, setSelectOption] = useState<
    typeof selectOptions[number] | undefined
  >(undefined);

  const setCalendarDatesManually = (
    value: CalendarFilterDateType | undefined
  ) => {
    setDateValue(value);
    if (selectOption) {
      setSelectOption(undefined);
    }
  };

  useEffect(() => {
    if (selectOption) {
      const today = new Date();
      let secondDate: Date = new Date();

      switch (selectOption) {
        case 'Past Week':
          secondDate = day(today).subtract(7, 'days').toDate();
          break;
        case 'Past Month':
          secondDate = day(today).subtract(1, 'month').toDate();
          break;
        case 'Past Quarter':
          secondDate = day(today).subtract(3, 'months').toDate();
          break;
        case 'Past Year':
          secondDate = day(today).subtract(1, 'year').toDate();
          break;

        default:
          break;
      }

      setDateValue([secondDate, today]);
    }
  }, [selectOption]);

  return (
    <Group align='flex-start' mt={-10} sx={{ minHeight: 315 }}>
      <Box sx={{ width: setDateType ? 260 : 180 }}>
        {setDateType && (
          <>
            <SegmentedControl
              mt={5}
              size='xs'
              radius='md'
              data={SwitchData}
              value={dateType}
              onChange={(value) => setDateType(value as CheckInOutType)}
            />
            <Divider mb={12} mt={16} />
          </>
        )}
        <Stack spacing={3}>
          {selectOptions.map((item, index) => (
            <SelectButton
              key={item}
              isActive={selectOption === item}
              label={item}
              onClick={() => setSelectOption(item)}
              sx={
                selectOption === item
                  ? { backgroundColor: '#5C617820' }
                  : undefined
              }
              data-autofocus={index === 0 ? true : false}
            />
          ))}
        </Stack>
        <Divider mb={12} mt={10} />
        {buttonLabel && (
          <Text component='span' size='xs' mx={7} color='#5C6178' weight={500}>
            {buttonLabel}
          </Text>
        )}
      </Box>
      <RangeCalendar
        values={dateValue || [null, null]}
        onChange={setCalendarDatesManually}
      />
    </Group>
  );
};
