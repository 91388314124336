import { OwnerType } from '../useOwners';
import { ICellRendererParams } from '@finalytic/ui-grid';
import { faEnvelope, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Group, Text, useMantineTheme } from '@mantine/core';

import { InviteOwnerModal } from '../../../components';
import { formatOwnerName } from '../../../utils';
import { useColors } from '@finalytic/ui';
import { ReactNode, useState } from 'react';

export const OwnerNameCell = ({
  data,
  context,
}: ICellRendererParams<OwnerType>) => {
  const theme = useMantineTheme();

  const refetchTable = context.refetchTable;

  return (
    <Group noWrap pt={5} pb={8} sx={{ minHeight: 45 }}>
      <FontAwesomeIcon icon={faUser} size='xl' color={theme.colors.gray[9]} />

      <Box>
        <Text lineClamp={1} sx={{ display: 'block' }}>
          {formatOwnerName(
            {
              companyName: data?.companyName,
              firstName: data?.firstName,
              lastName: data?.lastName,
            },
            { lastNameFirst: true }
          )}
        </Text>
        <Text color='gray' size='xs' lineClamp={1} sx={{ display: 'block' }}>
          {data?.email}
          {data?.memberships && (
            <>
              {data?.memberships[0]?.status === 'inactive' && (
                <ReinviteButton
                  email={data.email}
                  name={`${data?.firstName} ${data?.lastName}`}
                  refetchTable={refetchTable}
                  isReinvite={false}
                >
                  Send invite
                </ReinviteButton>
              )}
              {data?.memberships[0]?.status === 'unconfirmed' && (
                <ReinviteButton
                  email={data.email}
                  name={`${data?.firstName} ${data?.lastName}`}
                  refetchTable={refetchTable}
                  isReinvite={true}
                >
                  Resend invite
                </ReinviteButton>
              )}
            </>
          )}
        </Text>
      </Box>
    </Group>
  );
};

const ReinviteButton = ({
  name,
  email,
  refetchTable,
  children,
  isReinvite,
}: {
  name?: string;
  email?: string;
  refetchTable: () => void;
  children: ReactNode;
  isReinvite: boolean;
}) => {
  const { purple } = useColors();
  const [inviteOpen, setInviteOpen] = useState(false);

  return (
    <>
      <Box
        component={Button}
        ml={6}
        py={0}
        px={4}
        sx={(theme) => ({
          height: 20,
          background: 'none',
          display: 'inline',
          outlineColor: purple.light,
          outlineWidth: 0,
          cursor: 'pointer',
          borderRadius: theme.radius.lg,
          ':hover': {
            backgroundColor: `${purple.base + 30} !important`,
          },
        })}
        ref={(ref: any) => {
          if (!ref) return;

          ref.onclick = (e: any) => {
            console.log('native event handler');
            e.stopPropagation(); // this works
            setInviteOpen(true);
            // put your logic here instead because e.stopPropagation() will
            // stop React's synthetic event
          };
        }}
      >
        <FontAwesomeIcon icon={faEnvelope} color={purple.base} />{' '}
        <Text
          component='span'
          color={purple.base}
          size='xs'
          ml={6}
          weight={500}
        >
          {children}
        </Text>
      </Box>

      <InviteOwnerModal
        email={email!}
        name={name!}
        isReinvite={isReinvite}
        refetchTable={refetchTable}
        opened={inviteOpen}
        closeModal={() => setInviteOpen(false)}
      />
    </>
  );
};
