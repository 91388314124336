import { useConnectionModalContext } from '../_context';
import { FilterByCategoryButton } from '../components/FilterByCategoryButton';
import { useTeamRole } from '@finalytic/data-ui';
import {
  ConnectButton,
  ConnectionCategoryCell,
  InputSearch,
  NoConnectionsTableOverlay,
  Table,
  useColors,
} from '@finalytic/ui';
import { CellStyle, ColDef, ICellRendererParams } from '@finalytic/ui-grid';
import { Avatar, Box, Center, Group, Text } from '@mantine/core';
import { ChangeEventHandler, useMemo, useState } from 'react';

const integrationTypeNames: { [key: string]: string } = {
  pms: 'PMS',
  // bookingChannel: 'OTAs',
  accounting: 'Accounting',
  // paymentGateway: 'Payments',
  service: 'Other',
};

const centerCellStyling: CellStyle = {
  display: 'flex',
  alignItems: 'center',
};

const formatCamelToTitleCase = (value: string) => {
  if (!value) return '';
  const res = value?.replace(/([A-Z])/g, ' $1');
  return res.charAt(0).toUpperCase() + res.slice(1);
};

export const ConnectionListModalScreen = () => {
  const { gray } = useColors();
  const { isSuperAdmin } = useTeamRole();

  const { apps, setApp } = useConnectionModalContext();

  const [activeTypeFilter, setActiveTypeFilter] = useState<
    string | undefined
  >();
  const [searchInput, setSearchInput] = useState<string>('');

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'name',
      headerName: 'Name',
      cellRenderer: (params: ICellRendererParams) => (
        <Group spacing={14} sx={{ height: '100%' }} noWrap>
          <Avatar
            src={params.data.iconRound}
            radius={100}
            sx={{ display: 'inline-block' }}
            size='sm'
          />
          <Text component='span' weight={500} size='sm'>
            {params.value}
          </Text>
          {params.data?.connections?.length > 0 && (
            <Center
              component='span'
              px={8}
              sx={{
                height: 25,
                borderRadius: 100,
                border: '1px solid',
                borderColor: gray.dark + 30,
                backgroundColor: '#fff',
                fontSize: 12,
              }}
            >
              {params.data?.connections?.length} added
            </Center>
          )}
        </Group>
      ),
      sort: 'asc',
    },
    {
      field: 'type',
      headerName: 'Type',
      cellStyle: centerCellStyling,
      valueFormatter: (params) => formatCamelToTitleCase(params.value),
      cellRenderer: ConnectionCategoryCell,
    },
    {
      field: 'Connect',
      headerName: '',
      cellRenderer: ({ data }: ICellRendererParams) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        >
          <ConnectButton onClick={() => setApp(data)} />
        </Box>
      ),
      type: 'rightAligned',
    },
  ]);

  const resetFilter = () => setActiveTypeFilter(undefined);

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchInput(event.target.value);
  };

  // Filter integrations based on text input and category buttons
  const rowData = useMemo(() => {
    return apps.filter(({ type, name = '', id }) => {
      const matchesTitle: boolean = searchInput
        ? name.toLowerCase().includes(searchInput.toLowerCase())
        : true;

      const matchesFilter: boolean = activeTypeFilter
        ? type === activeTypeFilter
        : true;

      const generalFilterIds = !['screencast', 'finalytic', 'test'].includes(
        id
      );

      // TODO: this is temporary: https://linear.app/finalytic/issue/VRP-931#comment-3f8dc61d
      const temporayFilterIds = isSuperAdmin
        ? true
        : ![
            // 'booking-com',
            'vrbo',
            'demo',
            'expedia',
            // PMS
            'apaleo',
            'krossbooking',
            'lodgify',
            'bookingsync',
            // accounting
            'netsuite',
          ].includes(id);

      return (
        matchesTitle && matchesFilter && temporayFilterIds && generalFilterIds
      );
    });
  }, [activeTypeFilter, apps, searchInput, isSuperAdmin]);

  return (
    <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <Group position='apart' align='flex-end' noWrap>
        {/* Filter Category Buttons */}
        <Group
          mt={12}
          spacing={0}
          noWrap
          sx={{
            overflowX: 'scroll',
            flex: 1,
            '::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <FilterByCategoryButton
            onClick={resetFilter}
            isActive={!activeTypeFilter}
          >
            All
          </FilterByCategoryButton>

          {Object.entries(integrationTypeNames).map(([key, name]) => (
            <FilterByCategoryButton
              key={key}
              onClick={() => setActiveTypeFilter(key)}
              isActive={activeTypeFilter === key}
            >
              {name}
            </FilterByCategoryButton>
          ))}
        </Group>

        {/* Search input current connections by title */}
        <InputSearch
          value={searchInput}
          onChange={onSearchChange}
          reset={() => setSearchInput('')}
          mb={4}
          placeholder='Search a connection'
        />
      </Group>
      <Box
        sx={{ maxHeight: '100%', height: '100%' }}
        ml={-20}
        mr={-20}
        mb={-20}
      >
        <Table
          columnDefs={columnDefs}
          rowData={rowData}
          pagination={false}
          onGridSizeChanged={({ api }) => api.sizeColumnsToFit()}
          gridOptions={{
            suppressCellFocus: true,
            rowHeight: 60,
            sortingOrder: ['asc', 'desc'],
          }}
          isTopRounded={false}
          isBottomRounded={false}
          domLayout='normal'
          noRowsOverlayComponent={NoConnectionsTableOverlay}
        />
      </Box>
    </Box>
  );
};
