import { useNotifiedV2Mutation } from '../../../../hooks';
import { OwnerNameCell } from '../../../../views/owners/_components';
import {
  AddListingToOwnerButton,
  ListingOwnerEllipsisMenu,
  useOwnerSplitMutation,
} from '../../../button';
import { ListingOwnerRow } from '../useListingDetailData';
import { OwnershipSplitCell } from './OwnershipSplitCell';
import { SelectOwnershipVendor } from './SelectOwnershipVendor';
import { TableTitle } from './TableTitle';
import { Maybe } from '@finalytic/graphql';
import {
  LoadingIndicator,
  NoOwnersTableOverlay,
  SelectItem,
  StringParam,
  Table,
  TableTooltip,
  useColors,
  useQueryParamSet,
} from '@finalytic/ui';
import {
  ColDef,
  EditableCallbackParams,
  ICellRendererParams,
  ITooltipParams,
} from '@finalytic/ui-grid';
import { Box, Group, Portal, Text, Tooltip, createStyles } from '@mantine/core';
import { useMemo } from 'react';

type ListingOwnerTableProps = {
  rowData?: ListingOwnerRow[];
  refetch: () => void;
  listingId?: string | null;
  teamId?: string;
  vendors: SelectItem[];
};

export const ListingOwnerTable = ({
  rowData,
  refetch,
  listingId,
  teamId,
  vendors,
}: ListingOwnerTableProps) => {
  const setOwner = useQueryParamSet('owner', StringParam);
  const { black } = useColors();
  const { classes } = useTableStyles();

  const { mutate: updateSplit, loading: loadingSplitMutation } =
    useOwnerSplitMutation();

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'lastName',
        sort: 'asc',
        headerName: 'Owners',
        flex: 1,
        minWidth: 200,
        cellRenderer: OwnerNameCell,
      },
      {
        field: 'vendor',
        sortable: false,
        minWidth: 150,
        flex: 1,
        cellStyle: () => ({
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '100%',
        }),
        cellRenderer: SelectOwnershipVendor,
      },
      {
        field: 'ownerSplit',
        sortable: false,
        type: 'numericColumn',
        minWidth: 100,
        editable: (params: EditableCallbackParams<ListingOwnerRow>) =>
          !!params.data?.vendor?.settingId,
        singleClickEdit: true,
        cellEditor: OwnershipSplitCell,
        cellRenderer: ({
          value,
          context,
          data,
        }: ICellRendererParams<ListingOwnerRow, Maybe<number>>) => {
          if (!data?.vendor?.settingId && typeof value !== 'number')
            return (
              <Group position='right'>
                <Tooltip
                  position='right'
                  withArrow
                  withinPortal
                  label='Please select a vendor first to update the ownership split.'
                >
                  <Box w='40px'>0 %</Box>
                </Tooltip>
              </Group>
            );

          return context?.loadingSplitMutation ? (
            <LoadingIndicator />
          ) : (
            <Box>
              <Text align='right' w='100%'>
                {typeof value === 'number' ? value : 'AUTO'} %
              </Text>
            </Box>
          );
        },
        onCellValueChanged: ({ newValue, context, data }) => {
          if (context.updateSplit) {
            context.updateSplit({
              args: {
                listingOwnerId: data.ownershipId,
                split: typeof newValue === 'number' ? newValue : null,
              },
            });
          }
        },
      },
      {
        type: 'rightAligned',
        width: 50,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: ({
          data,
          context,
        }: ICellRendererParams<ListingOwnerRow>) => {
          if (!data?.ownershipId) return null;

          return (
            <ListingOwnerEllipsisMenu
              ownershipId={data.ownershipId}
              refetchTable={context.refetchTable}
              withOwnerSplitReset
            />
          );
        },
      },
    ],
    [vendors]
  );

  if (!listingId || !teamId) return null;

  return (
    <>
      <Group mt='lg' mb='xs' position='apart'>
        <TableTitle>Owners</TableTitle>
        <AddListingToOwnerButton
          currentIds={rowData?.map((i) => i.id) || []}
          id={listingId}
          refetchTable={refetch}
          select='owner'
          teamId={teamId}
          buttonText='Add owner'
        />
      </Group>
      <Box>
        <Table
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{ resizable: false }}
          pagination={false}
          suppressCellFocus={true}
          rowHeight={55}
          headerHeight={0}
          rowClass={classes.row}
          className={classes.table}
          domLayout='autoHeight'
          animateRows
          getRowId={({ data }) => data?.id}
          stopEditingWhenCellsLoseFocus
          suppressCsvExport
          suppressExcelExport
          noRowsOverlayComponent={() => (
            <NoOwnersTableOverlay size={50} text='No Owners Mapped' pt={0} />
          )}
          onCellClicked={({ column, data }) => {
            if (column.getColId() !== 'ownerSplit') {
              setOwner(data?.ownerId, 'replace');
            }
          }}
          context={{
            refetchTable: refetch,
            vendors,
            loadingSplitMutation,
            updateSplit,
          }}
        />
      </Box>
    </>
  );
};

const useTableStyles = createStyles(() => ({
  row: {
    cursor: 'pointer',
    '&.ag-row-first': {
      borderTop: 'none',
    },
    '&.ag-row-last': {
      borderBottom: 'none',
    },
  },
  table: {
    '.ag-header': {
      borderBottom: 'none',
    },
  },
}));
