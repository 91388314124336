import { ListingsIcon } from '../../icons';
import { OverlayContainer, OverlayContainerProps } from './_components';

export const NoListingsTableOverlay = ({
  text = 'No Listings Available',
  ...stackProps
}: {
  text?: string;
} & Omit<OverlayContainerProps, 'icon' | 'children'>) => (
  <OverlayContainer icon={<ListingsIcon />} {...stackProps}>
    {text}
  </OverlayContainer>
);
