import { Navigate, Route, Routes } from 'react-router-dom';

import { gql, useBilling, useQuery, useTeamId } from '../../hooks';

import { VerifyTokenView } from '../../components';
import { LoadingIndicator } from '@finalytic/ui';
import { Suspense, lazy } from 'react';

const ReservationsView = lazy(
  () => import('../../views/reservations/ReservationsView')
);
const OwnerPortalListingsView = lazy(
  () => import('../../views/OwnerPortalListingsView')
);
const UnitStatementView = lazy(() => import('../../views/UnitStatementView'));
const UnitStatementsListView = lazy(
  () => import('../../views/UnitStatementListsView')
);
const StatementSummaryView = lazy(() => import('../../views/StatementSummary'));
const FilesView = lazy(() => import('../../views/files/FilesView'));
const SettingsViewV2 = lazy(
  () => import('../../views/settings-v2/SettingsViewV2')
);

export const OwnerRoutes = () => {
  const [teamId] = useTeamId();

  const { tableLimits } = useBilling();

  const [{ data }] = useQuery(
    {
      setting: [
        {
          where: {
            key: { _eq: 'tenantSettings' },
            group: { _eq: 'ownerPortal' },
            tenant_id: { _eq: gql.$`teamId` },
          },
        },
        { id: true, target: true },
      ],
    },
    {
      variables: {
        teamId,
      },
    }
  );

  const showReservations = !!data?.setting.find(
    (i) => i.target === 'showReservations'
  );

  return (
    <Suspense fallback={<LoadingIndicator isFullPageLoading />}>
      <Routes>
        <Route
          path='token-verify'
          element={<VerifyTokenView isAuthenticated />}
        />

        <Route index element={<Navigate to='/statements' />} />
        <Route path='owners' element={<Navigate to='/statements' />} />

        {/* Redirect from old path to new */}
        <Route path='unit-statements' element={<Navigate to='/statements' />} />
        <Route path='statements'>
          <Route
            index
            element={<UnitStatementsListView limit={tableLimits.statements} />}
          />
          <Route path=':listingId' element={<UnitStatementView />} />
          <Route path='summary' element={<StatementSummaryView />} />
        </Route>

        {showReservations && (
          <Route
            path='reservations'
            element={<ReservationsView limit={tableLimits.statements} />}
          />
        )}

        <Route path='listings' element={<OwnerPortalListingsView />} />

        <Route path='files' index element={<FilesView />} />

        <Route path='settings/*' element={<SettingsViewV2 />} />
        {/* <Route path='settings'>
        <Route index element={<SettingsView />} />
        <Route path=':tab' element={<SettingsView />} />
      </Route> */}
      </Routes>
    </Suspense>
  );
};
