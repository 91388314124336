import {
  useDashboardType,
  useOwnerPreviewId,
  useTeam,
  useTeamId,
  useTeamRole,
  useV2TransactionQuery,
} from '../../hooks';
import { AddTeamModal } from '../modal';
import { SupportButton } from './NavbarButtons';
import { SettingsPopoverMenu } from './SettingsPopoverMenu';
import { CloseMenuIcon } from './_icons';
import { IconButton, TeamSwitch, useOwnerPortalColor } from '@finalytic/ui';
import {
  faArrowLeft,
  faLeftToLine,
  faRightToLine,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Group,
  Navbar as MantineNavbar,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { useSpotlight } from '@mantine/spotlight';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

function useTeams() {
  const { data: teams, isLoading: loading } = useV2TransactionQuery((q) => {
    const teams = q
      .tenant({
        order_by: [{ name: 'asc' }],
        where: { status: { _neq: 'deleted' } },
      })
      .map((i) => ({ id: i.id, name: i.name, type: i.type })) as {
      id: string;
      name: string;
      type: string;
    }[];

    return teams;
  });

  return {
    teams: teams || [],
    loading,
  };
}

export type NavigationComponent = ({
  navbarIsExpanded,
}: {
  navbarIsExpanded: boolean;
}) => JSX.Element;

export type NavbarBaseProps = {
  navigation: NavigationComponent;
};

type NavbarProps = {
  closeMobileMenu?: () => void;
};

function useDesktopExpand() {
  const [isDesktopExpanded, setIsDesktopExpanded] = useLocalStorage({
    key: 'navbar-expanded',
    defaultValue: true,
  });

  return {
    isDesktopExpanded,
    setIsDesktopExpanded,
  };
}

export const Navbar = ({
  closeMobileMenu,
  navigation: Navigation,
}: NavbarBaseProps & NavbarProps) => {
  const { isDesktopExpanded } = useDesktopExpand();

  const theme = useMantineTheme();
  const navBgColor = theme.colors[theme.primaryColor][8];

  const { teams, loading } = useTeams();

  const [team] = useTeam();
  const [dashboard] = useDashboardType();

  const ownerPortalColor = useOwnerPortalColor(team?.colorPrimary);
  const navbarBgColor = dashboard === 'owner' ? ownerPortalColor : navBgColor;

  const isExpanded = closeMobileMenu ?? isDesktopExpanded;

  return (
    <>
      <MantineNavbar
        width={{ base: isExpanded ? 280 : 80 }}
        sx={(theme) => {
          const sideBarPadding = theme.spacing.sm;

          return {
            transition: 'all 0.3s ease-out',
            backgroundColor: navbarBgColor,
            height: `calc(100vh - 2*${sideBarPadding})`,
            margin: sideBarPadding,
            borderRadius: theme.spacing.md,
            '@media (max-width: 755px)': {
              // Hide Sidebar on mobile
              display: closeMobileMenu ? undefined : 'none',
            },
          };
        }}
      >
        <NavbarHeader
          isExpanded={!!isExpanded}
          closeMobileMenu={closeMobileMenu}
          teams={teams}
        />
        <MantineNavbar.Section
          grow
          component={ScrollArea}
          px='sm'
          mt={isExpanded ? 'md' : undefined}
          sx={{ width: isExpanded ? 280 : 80, height: '100%' }}
        >
          {!loading && (
            <Stack
              align={isExpanded ? undefined : 'center'}
              pt={!isExpanded ? 'sm' : undefined}
            >
              {/* Routes defined by Sections => Then parents => And parents with possible children */}
              {Navigation && (
                <Navigation navbarIsExpanded={!!isDesktopExpanded} />
              )}
              {/* {dashboard === 'owner' ? (
              <OwnerNavigation navbarIsExpanded={!!isDesktopExpanded} />
            ) : (
              <DashboardNavigation navbarIsExpanded={!!isDesktopExpanded} />
            )} */}
            </Stack>
          )}
        </MantineNavbar.Section>

        {/* {dashboard !== 'owner' ? (
        <TasksNavItem navbarExtended={!!isExpanded} />
      ) : null}
      <NovuItem navbarExtended={!!isExpanded} /> */}

        <SupportButton navbarExtended={!!isExpanded} />
        {/* <OnboardingCallButton navbarExtended={!!isExpanded} /> */}

        <MantineNavbar.Section
          px='xs'
          pb='xs'
          pt={4}
          sx={{
            borderTop: '1px solid #C6C6C630',
            width: isExpanded ? 280 : 80,
          }}
        >
          <SettingsPopoverMenu
            isNavbarExpanded={!!isDesktopExpanded}
            isOwnerDashboard={dashboard === 'owner'}
          />
        </MantineNavbar.Section>
      </MantineNavbar>
    </>
  );
};

const NavbarHeader = ({
  isExpanded,
  closeMobileMenu,
  teams,
}: {
  isExpanded: boolean;
  closeMobileMenu?: () => void;
  teams: { id: string; name: string; type?: string }[];
}) => {
  const spotlight = useSpotlight();
  const { ownerPreview } = useOwnerPreviewId();
  const [, setTeamId] = useTeamId();
  const [team] = useTeam();
  const { isPartnerAdmin } = useTeamRole();

  const { setIsDesktopExpanded, isDesktopExpanded } = useDesktopExpand();

  const [addModalOpened, addModalHandlers] = useDisclosure(false);
  const [addTeamName, setAddTeamName] = useState<string>();

  const [dashboard] = useDashboardType();
  const location = useLocation();

  const openAddTeamModal = (teamName: string | undefined) => {
    setAddTeamName(teamName);
    addModalHandlers.open();
  };

  const handleNavbarExpand = () => setIsDesktopExpanded?.((e) => !e);

  const ownerPortalLogo = dashboard === 'owner' ? team.logo : undefined;

  const isSettingsView = location.pathname.startsWith('/settings');

  return (
    <MantineNavbar.Section
      component={Group}
      noWrap
      px='md'
      pt='md'
      spacing={!isExpanded ? 0 : undefined}
      position={isExpanded ? 'apart' : 'center'}
      sx={{
        width: isExpanded ? 280 : 80,
        height: '100%',

        paddingBottom: !isExpanded ? 10 : undefined,
        borderBottom: !isExpanded ? '1px solid #C6C6C630' : undefined,
        flexDirection: isExpanded ? undefined : 'column',
      }}
    >
      {isSettingsView ? (
        <Group spacing={'xs'}>
          <IconButton
            component={Link}
            to='/'
            sx={{
              '&:hover *': {
                color: 'white',
                fill: 'white',
              },
            }}
            mb={!isExpanded ? 'xs' : undefined}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
          {isExpanded && (
            <Text component='span' size='lg' color='white'>
              Settings
            </Text>
          )}
        </Group>
      ) : (
        <TeamSwitch
          logo={ownerPortalLogo}
          addTeam={
            dashboard === 'owner' ? undefined : (v) => openAddTeamModal(v)
          }
          onLogoClick={
            isPartnerAdmin
              ? () => {
                  spotlight.openSpotlight();
                }
              : undefined
          }
          teams={teams}
          team={team}
          setTeam={setTeamId}
          isNavigationExpanded={!!isExpanded}
          canSwitch={Boolean(!ownerPreview?.userId && teams.length > 1)}
        />
      )}

      <ActionIcon
        onClick={closeMobileMenu || handleNavbarExpand}
        variant='transparent'
        sx={{
          borderRadius: '20%',
          ':hover': {
            backgroundColor: '#00000060',
          },
        }}
      >
        {closeMobileMenu ? (
          <CloseMenuIcon />
        ) : isDesktopExpanded ? (
          <FontAwesomeIcon icon={faLeftToLine} color='#ffffff80' />
        ) : (
          <FontAwesomeIcon icon={faRightToLine} color='#ffffff80' />
        )}
      </ActionIcon>

      <AddTeamModal
        onClose={addModalHandlers.close}
        opened={addModalOpened}
        teamName={addTeamName}
      />
    </MantineNavbar.Section>
  );
};
