import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Center } from '@mantine/core';

export const ArrowCell = () => {
  return (
    <Center sx={{ height: '100%' }}>
      <FontAwesomeIcon size='sm' icon={faArrowRight} />
    </Center>
  );
};
