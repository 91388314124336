import { ICellRendererParams } from '@finalytic/ui-grid';
import { faHouse } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Group, Text, useMantineTheme } from '@mantine/core';

export const ListingNameCell = ({ value, data }: ICellRendererParams) => {
  const theme = useMantineTheme();

  const address = data?.address || data?.listing?.address;

  return (
    <Group noWrap pt={5} pb={8} sx={{ minHeight: 45 }}>
      <FontAwesomeIcon icon={faHouse} size='lg' color={theme.colors.gray[9]} />

      <Box sx={{ overflow: 'hidden' }}>
        <Text lineClamp={1} sx={{ display: 'block' }}>
          {value}
        </Text>
        {address && (
          <Text color='gray' size='xs' lineClamp={1} sx={{ display: 'block' }}>
            {address}
          </Text>
        )}
      </Box>
    </Group>
  );
};
