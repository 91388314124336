import { TagCell } from './TagCell';
import { Box, Tooltip } from '@mantine/core';
import { useMemo } from 'react';

export const ResultStatusCell = ({
  value,
  status,
  message,
}: {
  status?: string;
  value: string;
  message?: string;
}) => {
  const color = useMemo(() => {
    switch (status) {
      case 'skip':
        return 'yellow';
      case 'fail':
        return 'red';
      case 'ok':
        return 'green';
      case 'failed':
        return 'red';
      case 'completed':
        return 'green';
      default:
        return undefined;
    }
  }, [status]);

  if (message) {
    return (
      <Tooltip
        label={message}
        radius={4}
        withinPortal
        withArrow
        position='top-start'
        offset={3}
        styles={{
          tooltip: {
            maxWidth: 500,
            fontSize: 13,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        }}
      >
        <Box>
          <TagCell color={color}>{value}</TagCell>
        </Box>
      </Tooltip>
    );
  }

  return <TagCell color={color}>{value}</TagCell>;
};
