import { Maybe, hasValue } from '@finalytic/utils';

type Address = {
  city: Maybe<string>;
  line1: Maybe<string>;
  postcode: Maybe<string>;
  country: Maybe<string>;
};

export const formatAddress = (values: Address) => {
  const temp: Address = {
    line1: '',
    city: '',
    postcode: '',
    country: '',
  };

  Object.entries(values).forEach(([key, value]) => {
    if (value) temp[key as keyof Address] = value;
  });

  return Object.values(temp).filter(hasValue).join(', ');
};
