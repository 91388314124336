import { InputByType } from './InputByType';
import { FormInputValueChangeType } from './_components/_base-input-types';
import { Schema } from './_types';
import { ObjectJsonSchema, sortBy, toTitleCase } from '@finalytic/utils';
import { Box, Stack, StackProps, Text } from '@mantine/core';
import { ReactElement, useMemo } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

export type InputsBySchemaProps = {
  schema: Schema;
  methods: UseFormReturn;
  hideFields?: string[];
  onInputValueChange?: FormInputValueChangeType;
};

export const InputsBySchema = ({
  schema,
  methods,
  hideFields = [],
  onInputValueChange,
  ...stackProps
}: InputsBySchemaProps & StackProps) => {
  const sortedSchema = useMemo(() => {
    return sortBy(
      Object.entries(schema).filter((x) => !hideFields.includes(x[0])),
      (x) => (x[1].optional ? 1 : 0)
    );
  }, [schema, ...hideFields]);
  return (
    <FormProvider {...methods}>
      <Stack mb={10} spacing={12} {...stackProps}>
        {sortedSchema.map(([key, value]) => (
          <Box key={key}>
            <InputByType
              objectKey={key}
              value={value}
              onInputValueChange={onInputValueChange}
            />
            {value.description && <Text size='sm'>{value.description}</Text>}
          </Box>
        ))}
      </Stack>
    </FormProvider>
  );
};

export type InputsByJSONSchemaProps = {
  ignoreKeys?: string[];
  schema: ObjectJsonSchema;
  methods: UseFormReturn;
  renderContainer?: (
    children: ReactElement<any, any>
  ) => ReactElement<any, any>;
};

export const InputsByJSONSchema = ({
  ignoreKeys,
  schema: jsonSchema,
  methods,
  renderContainer,
}: InputsByJSONSchemaProps) => {
  const schema = useMemo(() => {
    return jsonSchemaProperties(jsonSchema, ignoreKeys);
  }, [jsonSchema]);
  if (!methods || !schema) return null;

  const component = <InputsBySchema methods={methods} schema={schema} />;
  if (renderContainer) {
    return renderContainer(component) || null;
  }
  return component;
};

export function jsonSchemaProperties(
  jsonSchema: ObjectJsonSchema,
  ignoreKeys?: string[]
) {
  const schema: Schema = {};
  if (jsonSchema?.properties)
    for (const key in jsonSchema.properties) {
      if (ignoreKeys?.includes(key)) continue;
      const value = jsonSchema.properties[key];
      schema[key] = {
        type:
          key === 'month'
            ? 'month'
            : key === 'date'
            ? 'date'
            : (value.type as any),
        label: value.title || toTitleCase(key),
        description: value.description,
        optional: value.nullable,
        defaultValue: value.default,
        disabled: value.readOnly,
        placeholder: value.examples?.[0],
      };
    }
  if (Object.keys(schema).length === 0) return undefined;
  return schema;
}
// {
//   "$schema": "http://json-schema.org/draft-04/schema#",
//   "$id": "https://example.com/employee.schema.json",
//   "title": "Record of employee",
//   "description": "This document records the details of an employee",
//   "type": "object",
//   "properties": {
//       "date": {
//           "description": "The date to re-generate statements for",
//           "type": "string",
//           "format": "month"
//       }
//   }
// }
