import { defaultStyles } from './_styles';
import {
  DatePickerInput,
  DatePickerInputProps,
  DatePickerValue,
} from '@mantine/dates';

export type InputRangeValueType = DatePickerValue<'range'>;

export const InputRangeDate = ({
  styles,
  ...props
}: DatePickerInputProps<'range'>) => {
  return (
    <DatePickerInput
      type='range'
      color='dark'
      popoverProps={{
        withinPortal: true,
        withArrow: true,
      }}
      styles={{
        ...defaultStyles,
        ...styles,
      }}
      {...props}
    />
  );
};
