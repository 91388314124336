/**
 * The result of a successful sign-in.
 */
export interface AccessTokenModel {
  /** Password */
  status?: 'ok' | 'error';

  /** User ID */
  userId?: string;

  /** Issued at date */
  issuedAt?: string;

  /** Expires at date */
  expiresAt?: string;

  /** Bearer AccessToken */
  accessToken?: string;

  /** Tenant ID */
  tenantId?: string;

  /** Actions */
  actions?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

/**
 * Represents a team.
 */
export interface TeamModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Name
   * @example Daemon Property Management
   */
  name?: string;

  /**
   * Website
   * @example www.daemon-properties.com
   */
  website?: string;

  /**
   * E-Mail (Billing)
   * @example matt@daemon-properties.com
   */
  email?: string;

  /**
   * Support Phone (for PDFs)
   * @example +49 881 82819094
   */
  supportPhone?: string;

  /**
   * Support E-Mail (for PDFs)
   * @example help@daemon-properties.com
   */
  supportEmail?: string;
}

/**
 * Represents a billing link.
 */
export interface BillingLinkModel {
  /**
   * Link
   * @example https://example.com/link
   */
  link?: string;
}

/**
 * Represents a signed upload url.
 */
export interface FileSignedUrl {
  /** Signed URL */
  signedUrl?: string;

  /** Acknowledgement Token */
  acknowledgementToken?: string;
}

export interface ApiTokenModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * API Token Value
   * @example NiqwiOq22_2qwscnI
   */
  apiToken?: string;

  /**
   * Hint
   * @example Niqw
   */
  hint?: string;

  /**
   * Role
   * @example user
   */
  role?: string;

  /**
   * Expires At
   * @example never
   */
  expiresAt?: string;

  /**
   * Scope
   * @example *
   */
  scope?: string;
}

export interface PaginatedApiToken {
  data?: ApiTokenModel[];
  page?: number;
  totalPages?: number;
  results?: number;
  totalResults?: number;
  filters?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

/**
 * Represents a webhook.
 */
export interface WebhookModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id: string;

  /**
   * URL
   * @example https://domain.com/webhook
   */
  url?: string;

  /**
   * Types
   * @example ["transaction.write"]
   */
  types?: string[];
}

export interface ContactModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Name
   * @example John Doe
   */
  name?: string;

  /**
   * Unique Reference
   * @example CUST_98218
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"bankAccount":{"iban":"DE02300209000106531065","bic":"BYLADEM1001"}}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

export interface PaginatedContact {
  data?: ContactModel[];
  page?: number;
  totalPages?: number;
  results?: number;
  totalResults?: number;
  filters?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

export interface ContactModelInsert {
  /**
   * Name
   * @example John Doe
   */
  name?: string;

  /**
   * Unique Reference
   * @example CUST_98218
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"bankAccount":{"iban":"DE02300209000106531065","bic":"BYLADEM1001"}}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

/**
 * Represents a ledgerAccount.
 */
export interface LedgerAccountModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Name
   * @example Sales
   */
  name?: string;

  /**
   * Unique Reference
   * @example 200
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"comment":"Income from any normal business activity.","type":"REVENUE"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

export interface PaginatedLedgerAccount {
  data?: SourceModel[];
  page?: number;
  totalPages?: number;
  results?: number;
  totalResults?: number;
  filters?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

/**
 * Represents a ledgerAccount.
 */
export interface LedgerAccountModelInsert {
  /**
   * Name
   * @example Sales
   */
  name?: string;

  /**
   * Unique Reference
   * @example 200
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"comment":"Income from any normal business activity.","type":"REVENUE"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

/**
 * Represents a taxRate.
 */
export interface TaxRateModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Name
   * @example 15% GST on Income
   */
  name?: string;

  /**
   * Unique Reference
   * @example OUTPUT2
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"canApplyToAssets":true,"canApplyToEquity":true}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

export interface PaginatedTaxRate {
  data?: TaxRateModel[];
  page?: number;
  totalPages?: number;
  results?: number;
  totalResults?: number;
  filters?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

/**
 * Represents a taxRate.
 */
export interface TaxRateModelInsert {
  /**
   * Name
   * @example 15% GST on Income
   */
  name?: string;

  /**
   * Unique Reference
   * @example OUTPUT2
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"canApplyToAssets":true,"canApplyToEquity":true}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

/**
 * Represents a bankAccount.
 */
export interface BankAccount {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Name
   * @example HSBC Business Account
   */
  name?: string;

  /**
   * Unique Reference
   * @example HSBC-1
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"iban":"DE02300209000106531065","bic":"BYLADEM1001"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

export interface PaginatedBankAccount {
  data?: BankAccount[];
  page?: number;
  totalPages?: number;
  results?: number;
  totalResults?: number;
  filters?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

/**
 * Represents a bankAccount.
 */
export interface BankAccountInsert {
  /**
   * Name
   * @example HSBC Business Account
   */
  name?: string;

  /**
   * Unique Reference
   * @example HSBC-1
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"iban":"DE02300209000106531065","bic":"BYLADEM1001"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

export interface ConnectionModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Name
   * @example Connection 1
   */
  name?: string;

  /**
   * Type
   * @example service
   */
  type?: string;

  /**
   * Service ID
   * @example airbnb
   */
  serviceId?: string;

  /**
   * App ID
   * @example airbnb
   */
  appId?: string;

  /** Last task message */
  lastTaskMessage?: string;

  /**
   * Status
   * @example enabled
   */
  status?: 'enabled' | 'disabled' | 'error';

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"comment":"Income from any normal business activity.","type":"REVENUE"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

export interface ListingSource {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Connection ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  connectionId?: string;
  connection?: ConnectionModel;

  /**
   * Name
   * @example 14 Sunflower Lane
   */
  name?: string;

  /**
   * Unique Reference
   * @example 83971782
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"address":"12 Sunflower Lane, Greater London, England E14 8L, United Kingdom","bathrooms":1,"bedrooms":2}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /**
   * Beds
   * Number of beds
   * @example 1
   */
  beds?: number;

  /**
   * Bedrooms
   * Number of bedrooms
   * @example 1
   */
  bedrooms?: number;

  /**
   * Address
   * Full address
   * @example Some address 11, London
   */
  address?: string;
}

/**
 * Represents a expiring file link.
 */
export interface FileModel {
  /**
   * Expires At
   * Expires At (ISO date)
   * @example 2021-01-22T16:51:53.933Z
   */
  expiresAt?: string;

  /**
   * Link
   * The link to the file.
   * @example https://finalytic.io/file.pdf
   */
  link?: string;
}

export interface Listing {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Name
   * @example Sunflower Lane
   */
  name?: string;

  /**
   * Unique Code
   * @example LND_SUN1
   */
  code?: string;

  /**
   * Unique Reference
   * @example LND_SUN1
   */
  uniqueRef?: string;

  /**
   * Owner Contact ID
   * The owner contact ID.
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  ownerContactId?: string;
  ownerContact?: ContactModel;

  /**
   * Commission ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  commissionId?: string;

  /**
   * Connected Items
   * IDs of entities this listing is connected to.
   * @example ["XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"]
   */
  connections?: ListingSource[];

  /**
   * Beds
   * Number of beds
   * @example 1
   */
  beds?: number;

  /**
   * Bedrooms
   * Number of bedrooms
   * @example 1
   */
  bedrooms?: number;

  /**
   * Address
   * Full address
   * @example Some address 11, London
   */
  address?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"bankAccount":{"iban":"DE02300209000106531065","bic":"BYLADEM1001"}}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

export interface ListingInsert {
  /**
   * Name
   * @example Sunflower Lane
   */
  name?: string;

  /**
   * Unique Code
   * @example LND_SUN1
   */
  code?: string;

  /**
   * Unique Reference
   * @example LND_SUN1
   */
  uniqueRef?: string;

  /**
   * Owner Contact ID
   * The owner contact ID.
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  ownerContactId?: string;
  ownerContact?: ContactModel;

  /**
   * Commission ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  commissionId?: string;

  /**
   * Connect to Items
   * IDs of entities to add to this listing.
   * @example ["XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"]
   */
  connect?: string[];

  /**
   * Beds
   * Number of beds
   * @example 1
   */
  beds?: number;

  /**
   * Bedrooms
   * Number of bedrooms
   * @example 1
   */
  bedrooms?: number;

  /**
   * Address
   * Full address
   * @example Some address 11, London
   */
  address?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"bankAccount":{"iban":"DE02300209000106531065","bic":"BYLADEM1001"}}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

export interface PaymentLineModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Listing ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  listingId?: string;
  listing?: Listing;

  /**
   * Listing Connection ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  listingConnectionId?: string;
  listingConnection?: ListingSource;

  /**
   * Amount (original)
   * @example 1000
   */
  originalAmount?: number;

  /**
   * Currency (original)
   * @example eur
   */
  originalCurrency?: number;

  /**
   * Amount
   * @example 1000
   */
  amount?: number;

  /**
   * Unique Reference
   * @example AB9982XH-9-A
   */
  uniqueRef?: string;

  /**
   * Type
   * @example unclassified
   */
  type?:
    | 'accommodation'
    | 'adjustment'
    | 'cancellation'
    | 'cleaning_fee'
    | 'correction'
    | 'deposit'
    | 'deviation'
    | 'discount'
    | 'ota_fee'
    | 'payment_fee'
    | 'payment_reserve'
    | 'resolution'
    | 'service_fee'
    | 'tax'
    | 'unclassified';

  /**
   * Description
   * @example Installation
   */
  description?: string;

  /**
   * Payment ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  paymentId?: string;

  /**
   * Metadata
   * @example {"comment":"Some comment"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /**
   * Reservation ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  reservationId?: string;
  reservation?: ReservationModel;

  /** Invoice Lines */
  invoiceLines?: InvoiceLineModel[];
}

export interface ReservationModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Connection ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  connectionId?: string;
  connection?: ConnectionModel;

  /**
   * Listing ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  listingId?: string;
  listing?: Listing;

  /**
   * Listing Connection ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  listingConnectionId?: string;
  listingConnection?: ListingSource;

  /**
   * Booked At
   * @example 2022-04-12T08:37:18.761Z
   */
  bookedAt?: string;

  /**
   * Start
   * @example 2022-04-12T08:37:18.762Z
   */
  checkIn?: string;

  /**
   * End
   * @example 2022-04-12T08:37:18.762Z
   */
  checkOut?: string;

  /**
   * Nights
   * @example 5
   */
  nights?: number;

  /**
   * Booking Platform
   * @example airbnb
   */
  bookingPlatform?: string;

  /**
   * Guest Name
   * @example Joe Average
   */
  guestName?: string;

  /**
   * Booker Name
   * @example Joe Average
   */
  bookerName?: string;

  /**
   * Guests
   * @example 2
   */
  guests?: number;

  /**
   * Unique Reference
   * @example 83971782
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"address":"12 Sunflower Lane, Greater London, England E14 8L, United Kingdom","bathrooms":1,"bedrooms":2}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /**
   * confirmationCode
   * @example HMII911ZZ
   */
  confirmationCode?: string;
  __dangerousAmounts?: ReservationAmounts;

  /**
   * Currency
   * @example GBP
   */
  currency?: string;

  /** Payment Lines */
  paymentLines?: PaymentLineModel[];

  /** Payments */
  payments?: PaymentModel[];

  /**
   * Status
   * @example booked
   */
  status?: 'booked' | 'inquired' | 'inquiry' | 'cancelled';
}

export interface ReservationAmounts {
  /**
   * Total Amount
   * @example 1000
   */
  amount?: number;

  /**
   * Accommodation Amount
   * @example 1000
   */
  amountAccommodation?: number;

  /**
   * Adjustments Amount
   * @example 1000
   */
  amountAdjustments?: number;

  /**
   * Servie Amount
   * @example 1000
   */
  amountService?: number;

  /**
   * Payment Fee Amount
   * @example 1000
   */
  amountPaymentFee?: number;

  /**
   * Cancellations Amount
   * @example 1000
   */
  amountCancellation?: number;

  /**
   * OTA Amount
   * @example 1000
   */
  amountOta?: number;

  /**
   * Cleaning Amount
   * @example 1000
   */
  amountCleaning?: number;

  /**
   * City Tax Amount
   * @example 1000
   */
  amountCityTax?: number;
}

export interface PaymentModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Connection ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  connectionId?: string;
  connection?: ConnectionModel;

  /**
   * Amount
   * @example 1000
   */
  amount?: number;

  /**
   * Currency
   * @example GBP
   */
  currency?: string;

  /**
   * Description
   * @example Airbnb Payout
   */
  description?: string;

  /**
   * Date
   * @example 2022-04-12T08:37:18.765Z
   */
  date?: string;

  /**
   * Unique Reference
   * @example 83971782
   */
  uniqueRef?: string;

  /**
   * Is Reconciled
   * @example false
   */
  isReconciled?: boolean;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"address":"12 Sunflower Lane, Greater London, England E14 8L, United Kingdom","bathrooms":1,"bedrooms":2}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /**
   * Status
   * @example arrived
   */
  status?: 'arrived' | 'pending' | 'cancelled';

  /**
   * Type
   * @example custom
   */
  type?: 'payout' | 'custom';

  /** Invoices */
  invoices?: InvoiceModel[];

  /** Line Items */
  lines?: PaymentLineModel[];
}

export interface InvoiceModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Owner ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  ownerId?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
  owner?: ContactModel;

  /**
   * Listing ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  listingId?: string;
  listing?: Listing;

  /**
   * Listing Connection ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  listingConnectionId?: string;
  listingConnection?: ListingSource;

  /**
   * Meta Data
   * @example {"comment":"Some comment"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /**
   * Unique Reference
   * @example HSBC-1
   */
  uniqueRef?: string;

  /**
   * ISO Date
   * @example 2020-01-01
   */
  date?: string;

  /**
   * Total
   * @example 1000
   */
  total?: number;

  /**
   * Currency
   * @example EUR
   */
  currency?: string;

  /**
   * Reference
   * @example UA-3001
   */
  reference?: string;

  /**
   * Status
   * @example closed
   */
  status?: 'closed' | 'pending' | 'sent' | 'roll' | 'cancelled';

  /** Line Items */
  lines?: InvoiceLineModel[];
}

/**
 * Represents a owner statement line.
 */
export interface InvoiceLineModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Unique Reference
   * @example HSBC-1
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * @example {"comment":"Some comment"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /**
   * Invoice Mode
   * @example forward_and_commission
   */
  mode?:
    | 'retain'
    | 'retain_and_commission'
    | 'forward'
    | 'forward_and_commission';

  /**
   * Commission
   * @example 10
   */
  commission?: number;

  /**
   * Amount
   * @example 1000
   */
  amount?: number;

  /**
   * Total
   * @example 1010
   */
  total?: number;

  /**
   * Description
   * @example Accommodation
   */
  description?: string;

  /**
   * Invoice ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  invoiceId?: string;

  /**
   * Payment-Line ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  paymentLineId?: string;

  /**
   * Payment ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  paymentId?: string;

  /**
   * Rollover Invoice ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  rolloverInvoiceId?: string;

  /**
   * Reservation ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  reservationId?: string;
  reservation?: ReservationModel;
  paymentLine?: PaymentLineModel;
  rolloverInvoice?: InvoiceModel;

  /**
   * Line Origin
   * @example rollover
   */
  origin?: 'payment' | 'reservation' | 'rollover';
}

export interface PaymentLineGroupModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Listing ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  listingId?: string;
  listing?: Listing;

  /**
   * Listing Connection ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  listingConnectionId?: string;
  listingConnection?: ListingSource;

  /**
   * Meta Data
   * @example {"comment":"Some comment"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /**
   * Unique Reference
   * @example HSBC-1
   */
  uniqueRef?: string;

  /**
   * Key
   * @example xxxx-xxxx-xxxx
   */
  key?: string;

  /**
   * Date
   * @example 2021-04-01
   */
  date?: string;

  /**
   * Currency
   * @example eur
   */
  currency?: string;

  /**
   * Date Range (from, to)
   * @example ["2021-04-01"]
   */
  dateRange?: string[];
  owner?: ContactModel;

  /** Line Items */
  lines?: PaymentLineModel[];

  /** Listings */
  listings?: Listing[];

  /** Payments */
  payments?: PaymentModel[];

  /** Reservations */
  reservations?: ReservationModel[];
}

export interface Payment {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Connection ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  connectionId?: string;
  connection?: ConnectionModel;

  /**
   * Amount
   * @example 1000
   */
  amount?: number;

  /**
   * Currency
   * @example GBP
   */
  currency?: string;

  /**
   * Description
   * @example Airbnb Payout
   */
  description?: string;

  /**
   * Date
   * @example 2022-04-12T08:37:18.765Z
   */
  date?: string;

  /**
   * Unique Reference
   * @example 83971782
   */
  uniqueRef?: string;

  /**
   * Is Reconciled
   * @example false
   */
  isReconciled?: boolean;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"address":"12 Sunflower Lane, Greater London, England E14 8L, United Kingdom","bathrooms":1,"bedrooms":2}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /**
   * Status
   * @example arrived
   */
  status?: 'arrived' | 'pending' | 'cancelled';

  /**
   * Type
   * @example custom
   */
  type?: 'payout' | 'custom';

  /** Invoices */
  invoices?: InvoiceModel[];

  /** Line Items */
  lines?: PaymentLineModel[];
}

export interface PaymentModelInsert {
  /**
   * Amount
   * @example 1000
   */
  amount?: number;

  /**
   * Currency
   * @example GBP
   */
  currency?: string;

  /**
   * Description
   * @example Airbnb Payout
   */
  description?: string;

  /**
   * Date
   * @example 2022-04-12T08:37:18.765Z
   */
  date?: string;

  /**
   * Unique Reference
   * @example 83971782
   */
  uniqueRef?: string;

  /**
   * Is Reconciled
   * @example false
   */
  isReconciled?: boolean;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"address":"12 Sunflower Lane, Greater London, England E14 8L, United Kingdom","bathrooms":1,"bedrooms":2}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /**
   * Status
   * @example arrived
   */
  status?: 'arrived' | 'pending' | 'cancelled';

  /**
   * Type
   * @example custom
   */
  type?: 'payout' | 'custom';

  /** Line Items */
  lines?: PaymentLineModel[];
}

/**
 * Represents a source.
 */
export interface SourceModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Name
   * @example Sales
   */
  name?: string;

  /**
   * Unique Reference
   * @example 200
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"comment":"Income from any normal business activity.","type":"REVENUE"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

/**
 * Represents a source.
 */
export interface SourceModelInsert {
  /**
   * Name
   * @example Sales
   */
  name?: string;

  /**
   * Unique Reference
   * @example 200
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {"comment":"Income from any normal business activity.","type":"REVENUE"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

/**
 * Represents a setting.
 */
export interface SettingModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /** Key */
  key?: string;

  /** Value */
  value?: string;

  /** Group */
  group?: string;

  /** Target */
  target?: string;
}

export interface PaginatedSettings {
  data?: SettingModel[];
  page?: number;
  totalPages?: number;
  results?: number;
  totalResults?: number;
  filters?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

/**
 * Represents a setting.
 */
export interface SettingModelInsert {
  /** Key */
  key?: string;

  /** Value */
  value?: string;

  /** Group */
  group?: string;

  /** Target */
  target?: string;
}

/**
 * Represents a owner statement line.
 */
export interface InvoiceLineModelInsertInsertNoInvoice {
  /**
   * Unique Reference
   * @example HSBC-1
   */
  uniqueRef?: string;

  /**
   * Meta Data
   * @example {"comment":"Some comment"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /**
   * Invoice Mode
   * @example forward_and_commission
   */
  mode?:
    | 'retain'
    | 'retain_and_commission'
    | 'forward'
    | 'forward_and_commission';

  /**
   * Commission
   * @example 10
   */
  commission?: number;

  /**
   * Amount
   * @example 1000
   */
  amount?: number;

  /**
   * Total
   * @example 1010
   */
  total?: number;

  /**
   * Description
   * @example Accommodation
   */
  description?: string;

  /**
   * Payment-Line ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  paymentLineId?: string;

  /**
   * Payment ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  paymentId?: string;

  /**
   * Rollover Invoice ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  rolloverInvoiceId?: string;

  /**
   * Reservation ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  reservationId?: string;
  reservation?: ReservationModel;
  paymentLine?: PaymentLineModel;
  rolloverInvoice?: InvoiceModel;

  /**
   * Line Origin
   * @example rollover
   */
  origin?: 'payment' | 'reservation' | 'rollover';
}

export interface InvoiceModelInsert {
  /**
   * Owner ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  ownerId?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /**
   * Listing ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  listingId?: string;

  /**
   * Listing Connection ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  listingConnectionId?: string;

  /**
   * Meta Data
   * @example {"comment":"Some comment"}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /**
   * Unique Reference
   * @example HSBC-1
   */
  uniqueRef?: string;

  /**
   * ISO Date
   * @example 2020-01-01
   */
  date?: string;

  /**
   * Total
   * @example 1000
   */
  total?: number;

  /**
   * Currency
   * @example EUR
   */
  currency?: string;

  /**
   * Reference
   * @example UA-3001
   */
  reference?: string;

  /**
   * Status
   * @example closed
   */
  status?: 'closed' | 'pending' | 'sent' | 'roll' | 'cancelled';

  /** Line Items */
  lines?: InvoiceLineModel[];
}

export interface ReviewModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Unique Reference
   * @example I_98218
   */
  uniqueRef?: string;

  /** Ratings */
  ratings?: ReviewRatingModel[];

  /** Tags */
  tags?: ReviewTagModel[];

  /**
   * Comments
   * @example ...
   */
  comments?: string;

  /**
   * Private Feedback
   * @example ....
   */
  privateFeedback?: string;

  /**
   * Total Rating
   * @example 5
   */
  totalRating?: number;

  /**
   * Meta Data
   * Metadata can contain any additional information.
   * @example {}
   */
  metadata?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;

  /** Reservation ID */
  reservationId?: string;
  reservation?: ReservationModel;
}

export interface ReviewRatingModel {
  /**
   * Type
   * @example checkin
   */
  type?: string;

  /**
   * Rating
   * @example 5
   */
  rating?: number;

  /**
   * Comments
   * @example ...
   */
  comments?: string;
}

export interface ReviewTagModel {
  /**
   * Type
   * @example 21
   */
  type?: string;

  /**
   * Description
   * @example ...
   */
  description?: string;
}

export interface PaginatedReviews {
  data?: ReviewModel[];
  page?: number;
  totalPages?: number;
  results?: number;
  totalResults?: number;
  filters?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

export interface MetricModel {
  /**
   * ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  id?: string;

  /**
   * Listing Connection ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  listingConnectionId?: string;
  listingConnection?: ListingSource;

  /**
   * Connection ID
   * @example XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   */
  connectionId?: string;
  connection?: ConnectionModel;

  /**
   * Type
   * @example rating
   */
  type?: string;

  /**
   * Date
   * @example 2021-01-01
   */
  date?: string;

  /**
   * Value
   * @example 0.081
   */
  value?: string;
}

export interface PaginatedMetrics {
  data?: MetricModel[];
  page?: number;
  totalPages?: number;
  results?: number;
  totalResults?: number;
  filters?:
    | number
    | null
    | number
    | null
    | string
    | null
    | boolean
    | null
    | any[]
    | null
    | object
    | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl = 'https://api.finalytic.io';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === 'number' ? value : `${value}`
    )}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...(params2?.headers || {}),
      },
    };
  }

  private createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${
        queryString ? `?${queryString}` : ''
      }`,
      {
        ...requestParams,
        headers: {
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
          ...(requestParams.headers || {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Finalytic API
 * @version 1.1.2
 * @baseUrl https://api.finalytic.io
 *
 * Finalytic Financial data access API.
 */
export class Api<
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  v2 = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthStatus
     * @request GET:/v2/auth/status
     * @secure
     */
    authStatus: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: '/v2/auth/status',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthLogin
     * @request POST:/v2/auth/login
     * @secure
     */
    authLogin: (
      data: { email: string; password: string },
      query?: { mode?: string },
      params: RequestParams = {}
    ) =>
      this.request<AccessTokenModel, any>({
        path: '/v2/auth/login',
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name Teams
     * @request GET:/v2/teams
     * @secure
     */
    teams: (params: RequestParams = {}) =>
      this.request<TeamModel[], any>({
        path: '/v2/teams',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get a billing management link
     *
     * @tags Teams
     * @name TeamBillingLink
     * @request GET:/v2/teams/billing
     * @secure
     */
    teamBillingLink: (params: RequestParams = {}) =>
      this.request<BillingLinkModel, any>({
        path: '/v2/teams/billing',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name TeamInviteMember
     * @request POST:/v2/teams/invite
     * @secure
     */
    teamInviteMember: (
      data: { email: string; tenantId: string },
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: '/v2/teams/invite',
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name TeamsRequestUpload
     * @request GET:/v2/teams/request-upload
     * @secure
     */
    teamsRequestUpload: (
      query?: { fileName?: string; connectionId?: string },
      params: RequestParams = {}
    ) =>
      this.request<FileSignedUrl, any>({
        path: '/v2/teams/request-upload',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name TeamsRequestUploadAcknowledge
     * @request POST:/v2/teams/request-upload-acknowledge
     * @secure
     */
    teamsRequestUploadAcknowledge: (
      query?: { token?: string },
      params: RequestParams = {}
    ) =>
      this.request<FileSignedUrl, any>({
        path: '/v2/teams/request-upload-acknowledge',
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Teams
     * @name TeamExec
     * @request POST:/v2/teams/exec
     * @secure
     */
    teamExec: (
      query?: { operation?: string },
      data?: object,
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: '/v2/teams/exec',
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete an API Token
     *
     * @tags ApiTokens
     * @name DeleteApiToken
     * @request DELETE:/v2/api-tokens/{id}
     * @secure
     */
    deleteApiToken: (id: string, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/v2/api-tokens/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get API token by id
     *
     * @tags ApiTokens
     * @name GetApiToken
     * @request GET:/v2/api-tokens/{id}
     * @secure
     */
    getApiToken: (id: string, params: RequestParams = {}) =>
      this.request<ApiTokenModel, any>({
        path: `/v2/api-tokens/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all API tokens
     *
     * @tags ApiTokens
     * @name GetApiTokens
     * @request GET:/v2/api-tokens
     * @secure
     */
    getApiTokens: (params: RequestParams = {}) =>
      this.request<PaginatedApiToken, any>({
        path: '/v2/api-tokens',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new API token
     *
     * @tags ApiTokens
     * @name CreateApiToken
     * @request POST:/v2/api-tokens
     * @secure
     */
    createApiToken: (
      query?: { clear?: boolean; role?: 'viewer' | 'user'; expiresAt?: string },
      params: RequestParams = {}
    ) =>
      this.request<ApiTokenModel, any>({
        path: '/v2/api-tokens',
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a webhook
     *
     * @tags Webhooks
     * @name WebhooksPostWebhooks
     * @request POST:/v2/webhooks
     * @secure
     */
    webhooksPostWebhooks: (data?: WebhookModel, params: RequestParams = {}) =>
      this.request<WebhookModel, any>({
        path: '/v2/webhooks',
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get possible webhook types
     *
     * @tags Webhooks
     * @name WebhookTypes
     * @request GET:/v2/webhooks/types
     * @secure
     */
    webhookTypes: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: '/v2/webhooks/types',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a webhook
     *
     * @tags Webhooks
     * @name WebhooksDeleteWebhookById
     * @request DELETE:/v2/webhooks/{id}
     * @secure
     */
    webhooksDeleteWebhookById: (id: string, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/v2/webhooks/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contacts
     * @name ContactsList
     * @request GET:/v2/contacts
     * @secure
     */
    contactsList: (
      query?: {
        connectionId?: string;
        expand?: string;
        pageSize?: number;
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedContact, any>({
        path: '/v2/contacts',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contacts
     * @name ContactsInsert
     * @request POST:/v2/contacts
     * @secure
     */
    contactsInsert: (
      query: { connectionId: string },
      data: ContactModelInsert,
      params: RequestParams = {}
    ) =>
      this.request<ContactModel, any>({
        path: '/v2/contacts',
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contacts
     * @name ContactsGet
     * @request GET:/v2/contacts/{id}
     * @secure
     */
    contactsGet: (id: string, params: RequestParams = {}) =>
      this.request<ContactModel, any>({
        path: `/v2/contacts/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contacts
     * @name ContactsUpdate
     * @request POST:/v2/contacts/{id}
     * @secure
     */
    contactsUpdate: (
      id: string,
      data: ContactModelInsert,
      params: RequestParams = {}
    ) =>
      this.request<ContactModel, any>({
        path: `/v2/contacts/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contacts
     * @name ContactsDelete
     * @request DELETE:/v2/contacts/{id}
     * @secure
     */
    contactsDelete: (id: string, params: RequestParams = {}) =>
      this.request<ContactModel, any>({
        path: `/v2/contacts/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LedgerAccounts
     * @name LedgerAccountsList
     * @request GET:/v2/ledger-accounts
     * @secure
     */
    ledgerAccountsList: (
      query?: {
        connectionId?: string;
        expand?: string;
        pageSize?: number;
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedLedgerAccount, any>({
        path: '/v2/ledger-accounts',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LedgerAccounts
     * @name LedgerAccountsInsert
     * @request POST:/v2/ledger-accounts
     * @secure
     */
    ledgerAccountsInsert: (
      query: { connectionId: string },
      data: LedgerAccountModelInsert,
      params: RequestParams = {}
    ) =>
      this.request<LedgerAccountModel, any>({
        path: '/v2/ledger-accounts',
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LedgerAccounts
     * @name LedgerAccountsGet
     * @request GET:/v2/ledger-accounts/{id}
     * @secure
     */
    ledgerAccountsGet: (id: string, params: RequestParams = {}) =>
      this.request<LedgerAccountModel, any>({
        path: `/v2/ledger-accounts/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LedgerAccounts
     * @name LedgerAccountsUpdate
     * @request POST:/v2/ledger-accounts/{id}
     * @secure
     */
    ledgerAccountsUpdate: (
      id: string,
      data: LedgerAccountModelInsert,
      params: RequestParams = {}
    ) =>
      this.request<LedgerAccountModel, any>({
        path: `/v2/ledger-accounts/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LedgerAccounts
     * @name LedgerAccountsDelete
     * @request DELETE:/v2/ledger-accounts/{id}
     * @secure
     */
    ledgerAccountsDelete: (id: string, params: RequestParams = {}) =>
      this.request<LedgerAccountModel, any>({
        path: `/v2/ledger-accounts/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaxRates
     * @name TaxRatesList
     * @request GET:/v2/tax-rates
     * @secure
     */
    taxRatesList: (
      query?: {
        connectionId?: string;
        expand?: string;
        pageSize?: number;
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedTaxRate, any>({
        path: '/v2/tax-rates',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaxRates
     * @name TaxRatesInsert
     * @request POST:/v2/tax-rates
     * @secure
     */
    taxRatesInsert: (
      query: { connectionId: string },
      data: TaxRateModelInsert,
      params: RequestParams = {}
    ) =>
      this.request<TaxRateModel, any>({
        path: '/v2/tax-rates',
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaxRates
     * @name TaxRatesGet
     * @request GET:/v2/tax-rates/{id}
     * @secure
     */
    taxRatesGet: (id: string, params: RequestParams = {}) =>
      this.request<TaxRateModel, any>({
        path: `/v2/tax-rates/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaxRates
     * @name TaxRatesUpdate
     * @request POST:/v2/tax-rates/{id}
     * @secure
     */
    taxRatesUpdate: (
      id: string,
      data: TaxRateModelInsert,
      params: RequestParams = {}
    ) =>
      this.request<TaxRateModel, any>({
        path: `/v2/tax-rates/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TaxRates
     * @name TaxRatesDelete
     * @request DELETE:/v2/tax-rates/{id}
     * @secure
     */
    taxRatesDelete: (id: string, params: RequestParams = {}) =>
      this.request<TaxRateModel, any>({
        path: `/v2/tax-rates/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccounts
     * @name BankAccountsList
     * @request GET:/v2/bank-accounts
     * @secure
     */
    bankAccountsList: (
      query?: {
        connectionId?: string;
        expand?: string;
        pageSize?: number;
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedBankAccount, any>({
        path: '/v2/bank-accounts',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccounts
     * @name BankAccountsInsert
     * @request POST:/v2/bank-accounts
     * @secure
     */
    bankAccountsInsert: (
      query: { connectionId: string },
      data: BankAccountInsert,
      params: RequestParams = {}
    ) =>
      this.request<BankAccount, any>({
        path: '/v2/bank-accounts',
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccounts
     * @name BankAccountsGet
     * @request GET:/v2/bank-accounts/{id}
     * @secure
     */
    bankAccountsGet: (id: string, params: RequestParams = {}) =>
      this.request<BankAccount, any>({
        path: `/v2/bank-accounts/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccounts
     * @name BankAccountsUpdate
     * @request POST:/v2/bank-accounts/{id}
     * @secure
     */
    bankAccountsUpdate: (
      id: string,
      data: BankAccountInsert,
      params: RequestParams = {}
    ) =>
      this.request<BankAccount, any>({
        path: `/v2/bank-accounts/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BankAccounts
     * @name BankAccountsDelete
     * @request DELETE:/v2/bank-accounts/{id}
     * @secure
     */
    bankAccountsDelete: (id: string, params: RequestParams = {}) =>
      this.request<BankAccount, any>({
        path: `/v2/bank-accounts/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name Connections
     * @request GET:/v2/connections
     * @secure
     */
    connections: (
      query?: {
        type?: string;
        appId?: string;
        status?: 'disabled' | 'enabled' | 'error';
        orderBy?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ConnectionModel[], any>({
        path: '/v2/connections',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name Connect
     * @request POST:/v2/connections
     * @secure
     */
    connect: (
      data:
        | number
        | null
        | number
        | null
        | string
        | null
        | boolean
        | null
        | any[]
        | null
        | object
        | null,
      query?: {
        appId?: string;
        sessionKey?: string;
        connectionId?: string;
        sync?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<ConnectionModel, any>({
        path: '/v2/connections',
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsInfo
     * @request GET:/v2/connections/connect
     * @secure
     */
    connectionsInfo: (
      query: { appId: string; dev?: boolean; connectionId?: string },
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: '/v2/connections/connect',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsOauthCallback
     * @request POST:/v2/connections/oauth-callback
     * @secure
     */
    connectionsOauthCallback: (data: object, params: RequestParams = {}) =>
      this.request<ConnectionModel, any>({
        path: '/v2/connections/oauth-callback',
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name Connection
     * @request GET:/v2/connections/{id}
     * @secure
     */
    connection: (id: string, params: RequestParams = {}) =>
      this.request<ConnectionModel, any>({
        path: `/v2/connections/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionDelete
     * @request DELETE:/v2/connections/{id}
     * @secure
     */
    connectionDelete: (id: string, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/v2/connections/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsUpdate
     * @request POST:/v2/connections/{id}
     * @secure
     */
    connectionsUpdate: (
      id: string,
      data?: { name?: string; metadata?: object },
      params: RequestParams = {}
    ) =>
      this.request<ConnectionModel, any>({
        path: `/v2/connections/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsExec
     * @request POST:/v2/connections/{id}/exec
     * @secure
     */
    connectionsExec: (
      id: string,
      query?: { operation?: string },
      data?:
        | number
        | null
        | number
        | null
        | string
        | null
        | boolean
        | null
        | any[]
        | null
        | object
        | null,
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: `/v2/connections/${id}/exec`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionFetch
     * @request POST:/v2/connections/{id}/fetch
     * @secure
     */
    connectionFetch: (
      id: string,
      data?:
        | number
        | null
        | number
        | null
        | string
        | null
        | boolean
        | null
        | any[]
        | null
        | object
        | null,
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: `/v2/connections/${id}/fetch`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsFetch
     * @request POST:/v2/connections/fetch
     * @secure
     */
    connectionsFetch: (
      data?: { connectionId?: string; forceFull?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: '/v2/connections/fetch',
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsParse
     * @request POST:/v2/connections/parse
     * @secure
     */
    connectionsParse: (
      data?: { connectionId?: string; ids?: string[] },
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: '/v2/connections/parse',
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Automations
     * @name ReconcilePayouts
     * @request POST:/v2/automations/reconcile
     * @secure
     */
    reconcilePayouts: (data?: { ids?: string[] }, params: RequestParams = {}) =>
      this.request<object, any>({
        path: '/v2/automations/reconcile',
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Listings
     * @name ListingsConnections
     * @request GET:/v2/listings/connections
     * @secure
     */
    listingsConnections: (
      query?: {
        pageSize?: number;
        page?: number;
        expand?: string;
        connected?: boolean;
        uniqueRef?: string;
        orderBy?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: ListingSource[];
          page?: number;
          totalPages?: number;
          results?: number;
          totalResults?: number;
          filters?:
            | number
            | null
            | number
            | null
            | string
            | null
            | boolean
            | null
            | any[]
            | null
            | object
            | null;
        },
        any
      >({
        path: '/v2/listings/connections',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Listings
     * @name ListingsAsCsv
     * @request GET:/v2/listings/csv
     * @secure
     */
    listingsAsCsv: (
      query?: {
        hasOwner?: boolean;
        hasCommission?: boolean;
        metadata?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<FileModel, any>({
        path: '/v2/listings/csv',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Listings
     * @name Listings
     * @request GET:/v2/listings
     * @secure
     */
    listings: (
      query?: {
        pageSize?: number;
        page?: number;
        hasOwner?: boolean;
        hasCommission?: boolean;
        metadata?: string;
        expand?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: Listing[];
          page?: number;
          totalPages?: number;
          results?: number;
          totalResults?: number;
          filters?:
            | number
            | null
            | number
            | null
            | string
            | null
            | boolean
            | null
            | any[]
            | null
            | object
            | null;
        },
        any
      >({
        path: '/v2/listings',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Listings
     * @name InsertListing
     * @request POST:/v2/listings
     * @secure
     */
    insertListing: (data?: ListingInsert, params: RequestParams = {}) =>
      this.request<Listing, any>({
        path: '/v2/listings',
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Listings
     * @name Listing
     * @request GET:/v2/listings/{id}
     * @secure
     */
    listing: (id: string, params: RequestParams = {}) =>
      this.request<Listing, any>({
        path: `/v2/listings/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Listings
     * @name DeleteListing
     * @request DELETE:/v2/listings/{id}
     * @secure
     */
    deleteListing: (id: string, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/v2/listings/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Listings
     * @name UpdateListing
     * @request POST:/v2/listings/{id}
     * @secure
     */
    updateListing: (
      id: string,
      data?: ListingInsert,
      params: RequestParams = {}
    ) =>
      this.request<Listing, any>({
        path: `/v2/listings/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name PaymentDelete
     * @request DELETE:/v2/payments/{id}
     * @secure
     */
    paymentDelete: (id: string, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/v2/payments/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name Payment
     * @request GET:/v2/payments/{id}
     * @secure
     */
    payment: (
      id: string,
      query?: { includeSuperseededLines?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<PaymentModel, any>({
        path: `/v2/payments/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name PaymentUpdate
     * @request POST:/v2/payments/{id}
     * @secure
     */
    paymentUpdate: (
      id: string,
      data: PaymentModelInsert,
      params: RequestParams = {}
    ) =>
      this.request<Payment, any>({
        path: `/v2/payments/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name UpdatePaymentLines
     * @request POST:/v2/payments/lines
     * @secure
     */
    updatePaymentLines: (
      data: (PaymentLineModel | null)[],
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: PaymentLineModel[];
          page?: number;
          totalPages?: number;
          results?: number;
          totalResults?: number;
          filters?:
            | number
            | null
            | number
            | null
            | string
            | null
            | boolean
            | null
            | any[]
            | null
            | object
            | null;
        },
        any
      >({
        path: '/v2/payments/lines',
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name Lines
     * @request GET:/v2/payments/lines
     * @secure
     */
    lines: (
      query: {
        pageSize?: number;
        page?: number;
        expand?: string;
        date: string;
        amount?: string;
        connectionId?: string;
        serviceId?: string;
        type?: string;
        listingId?: string;
        listingConnectionId?: string;
        paymentId?: string;
        reservationId?: string;
        hasInvoices?: 'false' | 'falseOrPartial' | 'true';
        metadata?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: PaymentLineModel[];
          page?: number;
          totalPages?: number;
          results?: number;
          totalResults?: number;
          filters?:
            | number
            | null
            | number
            | null
            | string
            | null
            | boolean
            | null
            | any[]
            | null
            | object
            | null;
        },
        any
      >({
        path: '/v2/payments/lines',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name LinesGroups
     * @request GET:/v2/payments/lines/groups
     * @secure
     */
    linesGroups: (
      query: {
        expand?: string;
        date: string;
        amount?: string;
        connectionId?: string;
        groupBy?:
          | 'payment'
          | 'reservation'
          | 'monthly'
          | 'weekly'
          | 'daily'
          | 'cronExp';
        dateField?:
          | 'payment'
          | 'intersection'
          | 'checkIn'
          | 'checkOut'
          | 'simpleCheckIn';
        gracePeriod?: object;
        perOwner?: boolean;
        hasInvoices?: boolean;
        includeSuperseededLines?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaymentLineGroupModel[], any>({
        path: '/v2/payments/lines/groups',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name PaymentLineDescriptions
     * @request GET:/v2/payments/line-descriptions
     * @secure
     */
    paymentLineDescriptions: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: '/v2/payments/line-descriptions',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name PaymentLineTypes
     * @request GET:/v2/payments/line-types
     * @secure
     */
    paymentLineTypes: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: '/v2/payments/line-types',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name PaymentLineTypes2
     * @request GET:/v2/payments/line-types2
     * @secure
     */
    paymentLineTypes2: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: '/v2/payments/line-types2',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name PaymentsAsCsv
     * @request GET:/v2/payments/csv
     * @secure
     */
    paymentsAsCsv: (
      query: {
        date: string;
        id?: string;
        type?: 'payout' | 'custom';
        amount?: string;
        connectionId?: string;
        appId?: string;
        reservationId?: string;
        isReconciled?: boolean;
        orderBy?: string;
        hasInvoices?: 'all' | 'none' | 'some';
        includeSuperseededLines?: boolean;
        includeLines?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<FileModel, any>({
        path: '/v2/payments/csv',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name Payments
     * @request GET:/v2/payments
     * @secure
     */
    payments: (
      query?: {
        pageSize?: number;
        page?: number;
        expand?: string;
        date?: string;
        id?: string;
        type?: 'payout' | 'custom';
        amount?: string;
        connectionId?: string;
        appId?: string;
        reservationId?: string;
        isReconciled?: boolean;
        orderBy?: string;
        hasInvoices?: 'all' | 'none' | 'some';
        includeSuperseededLines?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: {
            id?: string;
            connectionId?: string;
            connection?: ConnectionModel;
            amount?: number;
            currency?: string;
            description?: string;
            date?: string;
            uniqueRef?: string;
            isReconciled?: boolean;
            metadata?:
              | number
              | null
              | number
              | null
              | string
              | null
              | boolean
              | null
              | any[]
              | null
              | object
              | null;
            status?: 'arrived' | 'pending' | 'cancelled';
            type?: 'payout' | 'custom';
            invoices?: InvoiceModel[];
            lines?: PaymentLineModel[];
          }[];
          page?: number;
          totalPages?: number;
          results?: number;
          totalResults?: number;
          filters?:
            | number
            | null
            | number
            | null
            | string
            | null
            | boolean
            | null
            | any[]
            | null
            | object
            | null;
        },
        any
      >({
        path: '/v2/payments',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name PaymentInsert
     * @request POST:/v2/payments
     * @secure
     */
    paymentInsert: (
      query: { connectionId: string },
      data: PaymentModelInsert,
      params: RequestParams = {}
    ) =>
      this.request<Payment, any>({
        path: '/v2/payments',
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reservations
     * @name LinkReservationToPaymentLines
     * @request POST:/v2/reservations/{id}/link/payment-lines
     * @secure
     */
    linkReservationToPaymentLines: (
      id: string,
      data: { paymentLineIds: string[] },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: PaymentLineModel[];
          page?: number;
          totalPages?: number;
          results?: number;
          totalResults?: number;
          filters?:
            | number
            | null
            | number
            | null
            | string
            | null
            | boolean
            | null
            | any[]
            | null
            | object
            | null;
        },
        any
      >({
        path: `/v2/reservations/${id}/link/payment-lines`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reservations
     * @name ReservationsAsCsv
     * @request GET:/v2/reservations/csv
     * @secure
     */
    reservationsAsCsv: (
      query?: {
        date?: string;
        amount?: string;
        dateField?:
          | 'payment'
          | 'intersection'
          | 'bookedAt'
          | 'checkIn'
          | 'checkOut';
        connectionId?: string;
        status?: string;
        uniqueRef?: string;
        confirmationCode?: string;
        hasPayouts?: boolean;
        serviceId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<FileModel, any>({
        path: '/v2/reservations/csv',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reservations
     * @name ReservationBookingPlatforms
     * @request GET:/v2/reservations/booking-platforms
     * @secure
     */
    reservationBookingPlatforms: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: '/v2/reservations/booking-platforms',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reservations
     * @name Reservation
     * @request GET:/v2/reservations/{id}
     * @secure
     */
    reservation: (id: string, params: RequestParams = {}) =>
      this.request<ReservationModel, any>({
        path: `/v2/reservations/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reservations
     * @name Reservations
     * @request GET:/v2/reservations
     * @secure
     */
    reservations: (
      query?: {
        pageSize?: number;
        page?: number;
        expand?: string;
        date?: string;
        amount?: string;
        dateField?:
          | 'payment'
          | 'intersection'
          | 'bookedAt'
          | 'checkIn'
          | 'checkOut';
        connectionId?: string;
        status?: string;
        uniqueRef?: string;
        confirmationCode?: string;
        guestName?: string;
        hasPayouts?: boolean;
        appId?: string;
        serviceId?: string;
        listingId?: string;
        listingConnectionId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: ReservationModel[];
          page?: number;
          totalPages?: number;
          results?: number;
          totalResults?: number;
          filters?:
            | number
            | null
            | number
            | null
            | string
            | null
            | boolean
            | null
            | any[]
            | null
            | object
            | null;
        },
        any
      >({
        path: '/v2/reservations',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sources
     * @name SourcesList
     * @request GET:/v2/sources
     * @secure
     */
    sourcesList: (
      query?: {
        type?: string;
        connectionId?: string;
        expand?: string;
        pageSize?: number;
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedLedgerAccount, any>({
        path: '/v2/sources',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sources
     * @name SourcesInsert
     * @request POST:/v2/sources
     * @secure
     */
    sourcesInsert: (
      query: { connectionId: string; type?: string },
      data: SourceModelInsert,
      params: RequestParams = {}
    ) =>
      this.request<SourceModel, any>({
        path: '/v2/sources',
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sources
     * @name SourcesGet
     * @request GET:/v2/sources/{id}
     * @secure
     */
    sourcesGet: (
      id: string,
      query?: { type?: string },
      params: RequestParams = {}
    ) =>
      this.request<SourceModel, any>({
        path: `/v2/sources/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sources
     * @name SourcesUpdate
     * @request POST:/v2/sources/{id}
     * @secure
     */
    sourcesUpdate: (
      id: string,
      data: SourceModelInsert,
      params: RequestParams = {}
    ) =>
      this.request<SourceModel, any>({
        path: `/v2/sources/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Sources
     * @name SourcesDelete
     * @request DELETE:/v2/sources/{id}
     * @secure
     */
    sourcesDelete: (id: string, params: RequestParams = {}) =>
      this.request<SourceModel, any>({
        path: `/v2/sources/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name SettingsList
     * @request GET:/v2/settings
     * @secure
     */
    settingsList: (query?: { group?: string }, params: RequestParams = {}) =>
      this.request<PaginatedSettings, any>({
        path: '/v2/settings',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name SettingsInsert
     * @request POST:/v2/settings
     * @secure
     */
    settingsInsert: (data: SettingModelInsert, params: RequestParams = {}) =>
      this.request<SettingModel, any>({
        path: '/v2/settings',
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Commissions
     * @name CommissionsRoot
     * @request GET:/v2/commissions
     * @secure
     */
    commissionsRoot: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: '/v2/commissions',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoices
     * @name Invoices
     * @request GET:/v2/invoices
     * @secure
     */
    invoices: (
      query?: {
        expand?: string;
        date?: string;
        listingId?: string;
        contactId?: string;
        status?: 'open' | 'closed' | 'sent' | 'cancelled' | 'rollover';
        page?: number;
        paymentId?: string;
        id?: string;
        pageSize?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: InvoiceModel[];
          page?: number;
          totalPages?: number;
          results?: number;
          totalResults?: number;
          filters?:
            | number
            | null
            | number
            | null
            | string
            | null
            | boolean
            | null
            | any[]
            | null
            | object
            | null;
        },
        any
      >({
        path: '/v2/invoices',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoices
     * @name InvoiceCreate
     * @request POST:/v2/invoices
     * @secure
     */
    invoiceCreate: (data: InvoiceModelInsert[], params: RequestParams = {}) =>
      this.request<InvoiceModel[], any>({
        path: '/v2/invoices',
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoices
     * @name InvoicePdf
     * @request GET:/v2/invoices/{id}/pdf
     * @secure
     */
    invoicePdf: (id: string, params: RequestParams = {}) =>
      this.request<FileModel, any>({
        path: `/v2/invoices/${id}/pdf`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoices
     * @name Invoice
     * @request GET:/v2/invoices/{invoiceId}
     * @secure
     */
    invoice: (invoiceId: string, params: RequestParams = {}) =>
      this.request<InvoiceModel, any>({
        path: `/v2/invoices/${invoiceId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoices
     * @name InvoiceDeleteLines
     * @request DELETE:/v2/invoices/{invoiceId}/lines
     * @secure
     */
    invoiceDeleteLines: (
      invoiceId: string,
      query: { id: string },
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: `/v2/invoices/${invoiceId}/lines`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoices
     * @name InvoiceInsertLines
     * @request POST:/v2/invoices/{invoiceId}/lines
     * @secure
     */
    invoiceInsertLines: (
      invoiceId: string,
      data?: InvoiceLineModelInsertInsertNoInvoice[],
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data?: InvoiceLineModel[];
          page?: number;
          totalPages?: number;
          results?: number;
          totalResults?: number;
          filters?:
            | number
            | null
            | number
            | null
            | string
            | null
            | boolean
            | null
            | any[]
            | null
            | object
            | null;
        },
        any
      >({
        path: `/v2/invoices/${invoiceId}/lines`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Invoices
     * @name InvoiceUpdate
     * @request POST:/v2/invoices/{id}
     * @secure
     */
    invoiceUpdate: (
      id: string,
      data?: {
        metadata?: number | number | string | boolean | any[] | object;
        reference?: number | number | string | boolean | any[] | object;
        status?: 'cancelled' | 'sent' | 'closed' | 'open' | 'rollover';
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoiceModel, any>({
        path: `/v2/invoices/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags IO
     * @name IoSepaXml
     * @request GET:/v2/io/sepa-xml
     * @secure
     */
    ioSepaXml: (
      query: {
        initiator: string;
        iban: string;
        bic: string;
        version: 'pain.001.001.02' | 'pain.001.001.03' | 'pain.008.001.02';
        paymentInfoId?: string;
        paymentMethod?: string;
        senderName?: string;
        date: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<FileModel, any>({
        path: '/v2/io/sepa-xml',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags IO
     * @name IoSageCoala
     * @request GET:/v2/io/sage-coala/{paymentId}
     * @secure
     */
    ioSageCoala: (paymentId: string, params: RequestParams = {}) =>
      this.request<FileModel, any>({
        path: `/v2/io/sage-coala/${paymentId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name Review
     * @request GET:/v2/reviews/{id}
     * @secure
     */
    review: (id: string, params: RequestParams = {}) =>
      this.request<ReviewModel, any>({
        path: `/v2/reviews/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Reviews
     * @name Reviews
     * @request GET:/v2/reviews
     * @secure
     */
    reviews: (
      query?: {
        connectionId?: string;
        pageSize?: number;
        page?: number;
        expand?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedReviews, any>({
        path: '/v2/reviews',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Metrics
     * @name Metrics
     * @request GET:/v2/metrics
     * @secure
     */
    metrics: (
      query?: {
        pageSize?: number;
        page?: number;
        date?: string;
        connectionId?: string;
        listingConnectionId?: string;
        type?: string;
        expand?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedMetrics, any>({
        path: '/v2/metrics',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Metrics
     * @name MetricsTypes
     * @request GET:/v2/metrics/types
     * @secure
     */
    metricsTypes: (
      query?: {
        date?: string;
        connectionId?: string;
        listingConnectionId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<string[], any>({
        path: '/v2/metrics/types',
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags QueueController
     * @name QueueControllerQp
     * @request POST:/v2/queue
     * @secure
     */
    queueControllerQp: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: '/v2/queue',
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QueueController
     * @name QueueControllerQc
     * @request POST:/v2/queue/c
     * @secure
     */
    queueControllerQc: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: '/v2/queue/c',
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags QueueController
     * @name QueueControllerCleanseDoubleBookings
     * @request GET:/v2/queue/cleanse-double-bookings
     * @secure
     */
    queueControllerCleanseDoubleBookings: (params: RequestParams = {}) =>
      this.request<object, any>({
        path: '/v2/queue/cleanse-double-bookings',
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  v3 = {
    /**
     * No description
     *
     * @tags ConnectionsV3Controller
     * @name V3ConnectionSync
     * @request POST:/v3/connections/{id}/sync
     * @secure
     */
    v3ConnectionSync: (id: string, data?: object, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/v3/connections/${id}/sync`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ConnectionsV3Controller
     * @name V3ConnectionWebhook
     * @request POST:/v3/connections/{id}/webhook
     * @secure
     */
    v3ConnectionWebhook: (
      id: string,
      data?: object,
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: `/v3/connections/${id}/webhook`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServicesV3Controller
     * @name V3ServiceOauthCallback
     * @request POST:/v3/services/oauth-callback
     * @secure
     */
    v3ServiceOauthCallback: (data: object, params: RequestParams = {}) =>
      this.request<ConnectionModel, any>({
        path: '/v3/services/oauth-callback',
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ServicesV3Controller
     * @name V3ServiceConnect
     * @request POST:/v3/services/{serviceId}
     * @secure
     */
    v3ServiceConnect: (
      serviceId: string,
      data:
        | number
        | null
        | number
        | null
        | string
        | null
        | boolean
        | null
        | any[]
        | null
        | object
        | null,
      query?: { appId?: string; sessionKey?: string; connectionId?: string },
      params: RequestParams = {}
    ) =>
      this.request<ConnectionModel, any>({
        path: `/v3/services/${serviceId}`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
