import {
  IntegrationList,
  Layout,
  LayoutProps,
  OnboardingIntegration,
  SidebarConnect,
} from '../components';
import { useIntegrationHover } from '../hooks';
import { Button, Input } from '@finalytic/ui';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, useMantineTheme } from '@mantine/core';
import { ChangeEventHandler, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

type ConnectViewProps = {
  onIntegrationSubmit: (integration: OnboardingIntegration) => void;
  onSearch: (searchInput: string) => void;
  onMissingIntegrationSubmit: () => void;
  integrations: OnboardingIntegration[];
  loading: boolean;
} & (
  | {
      type: 'Accounting';
      handleSkipOnboarding: () => void;
    }
  | {
      type: 'PMS';
    }
);

export const ConnectView = ({
  onIntegrationSubmit,
  integrations,
  onMissingIntegrationSubmit,
  loading,
  ...props
}: ConnectViewProps) => {
  const [search, setSearch] = useState('');

  const location = useLocation();

  const { primaryColor: appName } = useMantineTheme();

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const s = event.target.value;

    setSearch(s);
    props.onSearch(s);
  };

  const { setAccounting, setPms } = useIntegrationHover();

  const setIntegration = (integration: OnboardingIntegration | undefined) => {
    if (props.type === 'Accounting') {
      setAccounting(integration);
    } else {
      setPms(integration);
    }
  };

  return (
    <Box maw={550} mx='auto'>
      {/* Input */}
      <Input
        value={search}
        onChange={onInputChange}
        inputName='integrationSearch'
        placeholderText={`Search for your ${props.type}...`}
        icon={<FontAwesomeIcon icon={faSearch} />}
        sx={{ input: { borderColor: '#EEEFF1' } }}
        type='text'
        mb='xl'
      />
      {/* Applist */}
      <IntegrationList
        integrations={integrations}
        notListedTitle={
          props.type === 'Accounting'
            ? 'My Accounting is not listed'
            : 'My PMS is not listed'
        }
        loading={loading}
        setIntegration={setIntegration}
        handleIntegrationSubmit={onIntegrationSubmit}
        handleMissingIntegration={onMissingIntegrationSubmit}
      />

      {
        props.type === 'Accounting' && (
          <Button
            fullWidth
            mt={70}
            styles={{ label: { fontWeight: 400 } }}
            onClick={props?.handleSkipOnboarding}
          >
            Finish onboarding
          </Button>
        )
        // (appName === 'VRPlatform' ? (
        //   <Button
        //     component={Link}
        //     to={`/pricing${location.search}`}
        //     fullWidth
        //     mt={70}
        //     styles={{ label: { fontWeight: 400 } }}
        //   >
        //     Skip this step
        //   </Button>
        // ) : (
        //   <Button
        //     fullWidth
        //     mt={70}
        //     styles={{ label: { fontWeight: 400 } }}
        //     onClick={props?.handleSkipOnboarding}
        //   >
        //     Finish onboarding
        //   </Button>
        // ))
      }
    </Box>
  );
};

export const ConnectStorybookView = ({
  title,
  ...props
}: ConnectViewProps & LayoutProps) => {
  const { pms, accounting } = useIntegrationHover();

  return (
    <Layout
      title={title}
      handleLogout={undefined}
      setTeam={() => null}
      team={undefined}
      sidebar={
        <SidebarConnect
          pms={
            props.type === 'PMS'
              ? pms
              : {
                  id: 'apaleo',
                  icon: 'https://imagedelivery.net/Ftdv9WftbW1DctaiLSVfFA/b39826af-5759-4b72-7946-d09e22bb7000/smol2',
                  name: 'Apaleo',
                }
          }
          accounting={accounting}
          title={
            props.type === 'PMS' ? 'Choose your PMS' : 'Choose your Accounting'
          }
          subtitle='We will collect, clean and organize your data. So you can derive valuable insights and take action on your findings.'
        />
      }
      teams={[]}
    >
      <ConnectView {...props} />
    </Layout>
  );
};
