import { useApiFetch } from '../../../hooks';
import {
  Box,
  CloseButton,
  MultiSelect,
  MultiSelectValueProps,
  SelectItemProps,
  Sx,
} from '@mantine/core';
import { forwardRef } from 'react';

type WebhookTypesSelectProps = {
  inputName: string;
  selected: string[];
  setSelected: (value: string[]) => void;
  sx?: Sx;
};

export const WebhookTypesSelect = ({
  inputName,
  selected,
  setSelected,
  sx,
  ...registerForm
}: WebhookTypesSelectProps) => {
  const webhookTypes = useApiFetch('webhookTypes');

  return (
    <MultiSelect
      name={inputName}
      data={(webhookTypes.data as string[]) || []}
      limit={20}
      valueComponent={InputItem}
      itemComponent={ListItem}
      searchable
      value={selected}
      {...registerForm}
      onChange={setSelected}
      placeholder='+ Search to add'
      sx={{
        borderRadius: 8,
        ...sx,
      }}
      styles={{
        input: {
          paddingTop: '5px',
          paddingBottom: '5px',
          fontSize: '13px',
          borderRadius: 8,
          '&::placeholder': {
            color: '#BEC0C9',
            fontWeight: 500,
          },
        },

        searchInput: {
          '&::placeholder': {
            color: '#BEC0C9',
            fontWeight: 500,
          },
        },
        item: {
          borderRadius: 8,
          ':hover': {
            backgroundColor: '#5C617820',
          },
        },
        dropdown: {
          borderRadius: 8,
        },
      }}
    />
  );
};

function InputItem({ label, onRemove, ...others }: MultiSelectValueProps) {
  return (
    <div {...others}>
      <Box
        sx={(theme) => ({
          display: 'flex',
          cursor: 'default',
          alignItems: 'center',
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
          border: `1px solid ${
            theme.colorScheme === 'dark'
              ? theme.colors.dark[7]
              : theme.colors.gray[4]
          }`,
          paddingLeft: 10,
          borderRadius: 4,
        })}
      >
        <Box sx={{ lineHeight: 1, fontSize: 12 }}>{label}</Box>
        <CloseButton
          onMouseDown={onRemove}
          variant='transparent'
          size={22}
          iconSize={14}
          tabIndex={-1}
        />
      </Box>
    </div>
  );
}

const ListItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, ...others }, ref) => {
    return (
      <div ref={ref} {...others}>
        <Box
          component='span'
          mr={5}
          sx={{
            border: '1px solid #EEEFF1',
            borderRadius: 5,
            padding: '2px 7px',
            backgroundColor: '#fff',
            fontSize: 13,
          }}
        >
          {label}
        </Box>
      </div>
    );
  }
);

ListItem.displayName = '';
