import { useTeamId, useV2TransactionQuery } from '../../../hooks';
import { getSourceDescription } from '@finalytic/common';
import { FormulaField, InputFormula } from '@finalytic/ui';
import { useState } from 'react';

function useAccounts(search: string) {
  const [teamId] = useTeamId();

  const { data, isLoading } = useV2TransactionQuery(
    (q, { teamId, search }) => {
      const vars: FormulaField[] = [
        {
          displayValue: '"var_rate"',
          id: 'rate',
          label: 'Rate',
          type: 'variable',
        },
        {
          displayValue: '"res_nights"',
          id: 'reservation.nights',
          label: 'Reservation Nights',
          type: 'field',
        },
        {
          displayValue: '"res_guests"',
          id: 'reservation.guests',
          label: 'Reservation Guests',
          type: 'field',
        },
        {
          displayValue: '"amnt_total"',
          id: 'amount.total',
          label: 'Total',
          type: 'line amount',
        },
        {
          displayValue: '"amnt_accommodation"',
          id: 'amount.accommodation',
          label: 'Accommodation',
          type: 'line amount',
        },
      ].filter((x) =>
        search ? x.label.toLowerCase().includes(search.toLowerCase()) : true
      );
      const items = q
        .source({
          limit: 30,
          order_by: [{ description: 'asc' }],
          where: {
            type: { _eq: 'account' },
            tenantId: { _eq: teamId },
            isArchived: { _neq: true },
            _or: search
              ? [
                  {
                    description: { _ilike: `%${search.trim()}%` },
                  },
                  {
                    remoteId: { _eq: `${search.trim()}` },
                  },
                ]
              : undefined,
          },
        })
        .map<FormulaField>((item) => ({
          displayValue: `"acc_${item.remoteId}"`,
          id: item.id,
          type: 'account',
          label: getSourceDescription(item) || 'Missing description',
        }));

      return [...vars, ...items];
    },
    {
      variables: {
        teamId: teamId,
        search,
      },
      skip: !teamId,
      queryKey: 'sources',
    }
  );

  return {
    accounts: data || [],
    loading: isLoading,
  };
}

export const FormulaEditor = ({
  value,
  setValue,
  onEnter,
}: {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onEnter: () => void;
}) => {
  const [search, setSearch] = useState('');

  const { accounts, loading } = useAccounts(search);

  return (
    <InputFormula
      value={value}
      setValue={setValue}
      fields={accounts}
      placeholder='Please enter a formula'
      rounded={false}
      onSearchInput={setSearch}
      onEnter={onEnter}
      loading={loading}
      autoFocus
    />
  );
};
