import { SidebarCard } from './SidebarCard';
import { SidebarContentContainer } from './SidebarContentContainer';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, useMantineTheme } from '@mantine/core';

export const SidebarPricing = () => {
  const { primaryColor: appName } = useMantineTheme();

  const features = [
    `Try ${appName} with no risk and no commitment.`,
    'Your 14-day free trial begins today, March 14th 2023.',
    'You can cancel the trial anytime.',
    'Your billing cycle starts when your free trial ends.',
  ];

  return (
    <SidebarContentContainer
      title='Select your Trial Plan'
      subtitle='Your 14-day free trial begins today. You can cancel the trial anytime. We will not charge you for first 14 days.'
      spacing={100}
    >
      <SidebarCard>
        <List
          spacing='md'
          size='sm'
          center
          styles={{ item: { color: 'white' } }}
          icon={<FontAwesomeIcon icon={faCheck} />}
        >
          {features.map((feature) => (
            <List.Item key={feature}>{feature}</List.Item>
          ))}
        </List>
      </SidebarCard>
    </SidebarContentContainer>
  );
};
