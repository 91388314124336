import { ReconnectIcon } from '../../../icons';
import { useColors } from '../../../styles';
import { Button } from '@mantine/core';

export const ConnectButton = ({
  onClick,
  label = 'Connect',
}: {
  onClick: () => void;
  label?: string;
}) => {
  const { black, border } = useColors();

  return (
    <Button
      onClick={onClick}
      variant='outline'
      radius={10}
      px={8}
      sx={{
        height: 30,
        borderColor: border.gray,
        color: black.base,
        fontWeight: 500,
        backgroundColor: '#fff',
      }}
      leftIcon={<ReconnectIcon />}
    >
      {label}
    </Button>
  );
};
