import { useConnectionModalContext } from '../_context';
import { ConnectingContainer } from '../components/ConnectingContainer';
import { Button, useColors } from '@finalytic/ui';
import { faCheck, faExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as MantineButton, Stack, Text } from '@mantine/core';

type ConnectionResultModalScreenProps = {
  type: 'success' | 'error';
};

export const ConnectionResultModalScreen = ({
  type,
}: ConnectionResultModalScreenProps) => {
  const { app, resetView, setView, errorMessage } = useConnectionModalContext();

  const { green, purple } = useColors();

  const isSuccess = type === 'success';

  return (
    <ConnectingContainer
      icon={
        isSuccess ? (
          <FontAwesomeIcon icon={faCheck} color='#fff' size='lg' />
        ) : (
          <FontAwesomeIcon icon={faExclamation} color='#FF5656' size='lg' />
        )
      }
      showSingleIcon
      iconBackgroundColor={isSuccess ? green.base : '#FFEEEE'}
      text={
        isSuccess ? (
          <>
            <Text weight={500} sx={{ fontSize: 22, letterSpacing: '0.5px' }}>
              {app?.name} connected successfully
            </Text>
          </>
        ) : (
          <>
            <Text size='md'>{app?.name} connection failed</Text>
            {errorMessage && (
              <Text weight={500} sx={{ fontSize: 22, letterSpacing: '0.5px' }}>
                {errorMessage}
              </Text>
            )}
          </>
        )
      }
    >
      {/* Buttons */}
      <Stack sx={{ width: 210, margin: '30px auto 0' }}>
        <MantineButton
          radius={10}
          onClick={resetView}
          sx={{
            backgroundColor: purple.base,
            ':hover': {
              backgroundColor: purple.light,
            },
          }}
        >
          {isSuccess ? 'Add another connection' : 'Retry'}
        </MantineButton>

        <Button
          onClick={() => (isSuccess ? setView('close-view') : resetView())}
        >
          {isSuccess ? 'Back to connections' : 'Cancel'}
        </Button>
      </Stack>
    </ConnectingContainer>
  );
};
