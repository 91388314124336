import { useV2TransactionQuery } from '../../../hooks';
import { AuditFilterParams } from './_types';
import {
  DelayedInputSearch,
  FilterWrapper,
  ResetFilterButton,
  SwitchFilter,
  SwitchFilterDataType,
} from '@finalytic/ui';
import { Group } from '@mantine/core';

function useAuditilters() {
  const { data, isLoading: loading } = useV2TransactionQuery((query, args) => {
    const tables = query
      .auditLogs({
        distinct_on: ['tableName'],
        order_by: [{ tableName: 'asc_nulls_last' }],
      })
      .map((i) => i.tableName);

    const teams = query
      .tenant({ order_by: [{ name: 'asc_nulls_last' }] })
      .map((i) => ({ id: i.id, name: i.name }));
    return {
      tables,
      teams,
    };
  });

  const tables = (data?.tables?.map((i) => ({
    label: i,
    value: i,
  })) || []) as SwitchFilterDataType;

  const teams = (data?.teams?.map((i) => ({
    label: i.name,
    value: i.id,
  })) || []) as SwitchFilterDataType;

  return {
    tables,
    teams,
    loading: loading,
  };
}

type AuditFilterProps = {
  filter: AuditFilterParams;
  setFilter: (state: Partial<AuditFilterParams>) => void;
  resetFilter: () => void;
};

export const AuditFilter = ({
  filter,
  setFilter,
  resetFilter,
}: AuditFilterProps) => {
  const { tables, loading, teams } = useAuditilters();

  const setTable = (value?: string) => setFilter({ table: value });
  const setTeam = (value?: string) => setFilter({ teamId: value });
  const setSearch = (value?: string) => setFilter({ objectId: value });

  return (
    <Group position='apart'>
      <FilterWrapper>
        {/* Team ID */}
        <SwitchFilter
          defaulButtonLabel={'Team'}
          loading={loading}
          data={teams}
          filterValue={filter.teamId || undefined}
          setFilterValue={setTeam}
          withSearch
        />

        {/* Tables */}
        <SwitchFilter
          defaulButtonLabel={'Table'}
          loading={loading}
          data={tables}
          filterValue={filter.table || undefined}
          setFilterValue={setTable}
          withSearch
        />

        <ResetFilterButton onClick={resetFilter} />
      </FilterWrapper>
      <DelayedInputSearch
        setSearchInput={setSearch}
        searchInput={filter.objectId || undefined}
        placeholder='Enter objectID'
      />
    </Group>
  );
};
