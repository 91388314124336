import { useTeamId } from '../team';
import { Api, ContentType } from './codegen';
import { useAuth } from '@finalytic/authentication';
import React from 'react';

const context = React.createContext<Api<any>['v2']>(undefined as any);

export function ApiProvider({
  children,
  url,
  teamId: tid,
}: {
  children: React.ReactNode;
  teamId?: string;
  url: string;
}) {
  const { getToken, userId } = useAuth();
  const [globTid] = useTeamId();
  const teamId = tid || globTid;
  return (
    <context.Provider
      value={React.useMemo(() => {
        return new Api({
          baseApiParams: {
            headers: {
              'Content-Type': ContentType.Json,
              'X-Tenant-Id': teamId || '',
            },
          },
          securityWorker: async () => {
            const accessToken = await getToken({ template: 'Hasura' });
            return {
              headers: accessToken
                ? {
                    Authorization: `Bearer ${accessToken}`,
                  }
                : undefined,
            };
          },
          baseUrl: url?.replace('/v1', ''),
        }).v2;
      }, [userId, teamId])}
    >
      {children}
    </context.Provider>
  );
}

export type UseApiOptions<TResult> = {
  onStart?: () => void;
  onSuccess?: (data: TResult) => void;
  onError?: (error: any) => void;
};
export type ArgumentTypes<F extends (...args: any[]) => any> = F extends (
  ...args: infer A
) => any
  ? A
  : never;
export type Then<T> = T extends PromiseLike<infer U> ? U : T;
export function useApi<
  TFunc extends keyof Api<any>['v2'],
  TResult extends Then<ReturnType<Api<any>['v2'][TFunc]>>['data'],
  TArgs extends ArgumentTypes<Api<any>['v2'][TFunc]>
>(fc: TFunc, options: UseApiOptions<TResult> = {}) {
  const api = React.useContext(context);
  const ref = React.useRef<{
    runningFor: number;
    data?: TResult;
    error?: any;
    loading: boolean;
  }>({ data: undefined, error: undefined, loading: false, runningFor: 0 });
  const [, setState] = React.useState<number>(0);
  function func(...args: TArgs) {
    ref.current = { loading: true, runningFor: 0 };
    setState(+new Date());
    if (options.onStart) options.onStart();
    /*if (options.trace) {
      headers['Trace-Token'] = `${dayjs(new Date()).format(
        'YYYYMMDDHHmmss'
      )}-${generateId()}`;
    }
    if (headers['Trace-Token']) {
      const ref: { hide?: () => void } = {};
      const toastResult = toast.addToast({
        id: headers['Trace-Token'],
        toastLifeTimeMs: 1000 * 60 * 60,
        title: <Toast toastRef={ref} id={headers['Trace-Token']} />,
      });
      ref.hide = toastResult.hide;
    }*/
    const cancel = false;
    const timeout = setInterval(() => {
      if (cancel) {
        clearInterval(timeout);
        return;
      }
      ref.current.runningFor = ref.current.runningFor + 5;
      setState(+new Date());
    }, 5000);
    return (api[fc] as any)(...args)
      .then((result: { data: TResult; status?: number }) => {
        clearInterval(timeout);
        if ('status' in result && `${(result as any).status}` !== '200') {
          throw (result as any).error || result.data;
        }
        if (result.data && typeof result.data === 'object') {
          if (
            'status' in result.data &&
            (result.data as any).status === 'issue'
          ) {
            throw (result as any).error || result.data;
          }
          if (
            'status' in result.data &&
            (result.data as any).status === 'error'
          ) {
            throw (result as any).error || result.data;
          }
          if ('type' in result.data && (result.data as any).type === 'issue') {
            throw (result as any).error || result.data;
          }
        }
        ref.current = {
          loading: false,
          data: result.data,
          runningFor: 0,
        };
        setState(+new Date());
        if (options.onSuccess) options.onSuccess(result.data);
        return result.data;
      })
      .catch((error: any) => {
        error = error?.error || error;
        clearInterval(timeout);
        ref.current = { loading: false, error, runningFor: 0 };
        setState(+new Date());
        if (options.onError) options.onError(error);
        throw error;
      }) as Promise<TResult>;
  }
  func.loading = ref.current.loading;
  func.error = ref.current.error;
  func.data = ref.current.data;
  func.runningFor = ref.current.runningFor;
  return func;
  // return React.useContext(context);
}

export function useApiFetch<
  TFunc extends keyof Api<any>['v2'],
  TResult extends Then<ReturnType<Api<any>['v2'][TFunc]>>['data'],
  TArgs extends ArgumentTypes<Api<any>['v2'][TFunc]>
>(
  fc: TFunc,
  arg1?: TArgs[0],
  options?: UseApiOptions<TResult> & { skip?: boolean; body?: TArgs[1] }
) {
  const api = useApi<TFunc, TResult, TArgs>(fc, options);
  React.useEffect(() => {
    if (options?.skip) {
      return;
    }
    (api as any)(arg1, options?.body).catch(() => undefined);
  }, [JSON.stringify(arg1), JSON.stringify(options?.body), options?.skip]);
  return api;
}
