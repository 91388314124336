import { Avatar, Center, Tooltip } from '@mantine/core';

type TeamIconCellProps = {
  name?: string;
  logo?: string;
  colorPrimary?: string;
};

export const TeamIconCell = ({
  name,
  logo,
  colorPrimary,
}: TeamIconCellProps) => {
  const initials = (name || '').split(' ').map((i) => i?.[0]?.toUpperCase());

  return (
    <Center>
      <Tooltip label={name} withinPortal withArrow>
        <Avatar
          src={logo}
          radius='xl'
          size={30}
          color={colorPrimary}
          styles={{ image: { objectFit: 'contain' } }}
        >
          {!logo ? initials : undefined}
        </Avatar>
      </Tooltip>
    </Center>
  );
};
