import { ChildMappingRow } from '../../_tables';
import React from 'react';

// export type ChangeType = {
//   value: string | undefined; // Source
//   group: string; // Owner / Connection etc.
//   id: string | undefined; // owner.id // connection.id
//   settingId?: string; // setting.id for that line
// };

export function useModalChanges() {
  const rowDataRef = React.useRef<{
    [s: string]: ChildMappingRow;
  }>({});
  const [changes, setChanges] = React.useState<number>(0);

  return {
    changes() {
      return rowDataRef.current;
    },
    add(key: string, data: ChildMappingRow) {
      if (!data.settingId && !data.value) {
        // empty change
        delete rowDataRef.current[key];
      } else {
        rowDataRef.current[key] = data;
      }
      setChanges(Object.keys(rowDataRef.current).length);
    },
    reset() {
      rowDataRef.current = {};
      setChanges(0);
    },
    has(key: string) {
      return !!rowDataRef.current[key];
    },
    numberOfChanges: changes,
  };
}
