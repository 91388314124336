import { Navigate, Route, Routes } from 'react-router-dom';

import { VerifyTokenView } from '../../components';
import { useBilling, useDashboardType, useTeamRole } from '../../hooks';
import { LoadingIndicator } from '@finalytic/ui';
import { useMantineTheme } from '@mantine/core';
import { Suspense, lazy } from 'react';

const EditAutomationView = lazy(() => import('../../views/EditAutomationView'));
const AutomationsView = lazy(() => import('../../views/AutomationsView'));
const MetricsView = lazy(() => import('../../views/MetricsView'));
const ConnectionsView = lazy(() => import('../../views/ConnectionsView'));
const StatementListView = lazy(() => import('../../views/StatementListView'));
const StatementSettingsView = lazy(
  () => import('../../views/StatementSettings')
);
const StatementView = lazy(() => import('../../views/StatementView'));
const StatementSummaryView = lazy(() => import('../../views/StatementSummary'));
const ReservationsView = lazy(
  () => import('../../views/reservations/ReservationsView')
);
const FilesView = lazy(() => import('../../views/files/FilesView'));
const ListingsView = lazy(() => import('../../views/listings/ListingsView'));
const PaymentsFilterView = lazy(
  () => import('../../views/payments/PaymentsFilterView')
);
const PaymentsView = lazy(() => import('../../views/payments/PaymentsView'));
const PmDashboard = lazy(() => import('../../views/dashboard-pm/PmDashboard'));
const LineMasterListViewV2 = lazy(
  () => import('../../views/line-master-list-v2/LineMasterListViewV2')
);
const PartnerTeamsView = lazy(
  () => import('../../views/partner-teams/PartnerTeamsView')
);
const ClientMembersView = lazy(
  () => import('../../views/client-members/ClientMembersView')
);
const SettingsViewV2 = lazy(
  () => import('../../views/settings-v2/SettingsViewV2')
);

const ReportsView = lazy(() => import('../../views/reports/ReportsView'));
const OwnersView = lazy(() => import('../../views/owners/OwnersView'));

const TestingView = lazy(() => import('../../views/testing/TestingView'));

const TrpcTestingView = lazy(
  () => import('../../views/testing/TrpcTestingView')
);

export const PmRoutes = () => {
  const { primaryColor: appName } = useMantineTheme();
  const [dashboard] = useDashboardType();

  const { isPartnerAdmin } = useTeamRole();

  const isUsingNewDashboard = !!isPartnerAdmin;

  const { tableLimits: limit } = useBilling();

  const tableLimits =
    appName === 'VRPlatform'
      ? limit
      : { payments: undefined, reservations: undefined, statements: undefined };

  return (
    <Suspense fallback={<LoadingIndicator isFullPageLoading />}>
      <Routes>
        <Route
          path='token-verify'
          element={<VerifyTokenView isAuthenticated />}
        />

        <Route
          index
          element={
            isUsingNewDashboard ? <PmDashboard /> : <Navigate to='/payments' />
          }
        />

        <Route
          path='payments'
          element={<PaymentsView limit={tableLimits.payments} />}
        >
          <Route index element={<PaymentsFilterView />} />
          <Route path=':view' element={<PaymentsFilterView />} />
        </Route>

        <Route
          path='reservations'
          element={<ReservationsView limit={tableLimits.reservations} />}
        />
        <Route path='listings' element={<ListingsView />} />

        {/* <Route path='tasks' element={<TasksListView />} /> */}

        <Route path='metrics' element={<MetricsView />} />
        <Route path='testing' element={<TestingView />} />
        <Route path='trpc-testing' element={<TrpcTestingView />} />
        <Route path='automations' element={<AutomationsView />} />
        <Route path='automations/:id' element={<EditAutomationView />} />

        <Route path='reports'>
          <Route index element={<ReportsView />} />
          <Route path=':id' element={<ReportsView />} />
        </Route>

        {dashboard === 'partner' && (
          <>
            <Route
              path='line-master-list'
              element={
                // isSuperAdmin ? <LineMasterListViewV2 /> : <LineMasterListView />
                <LineMasterListViewV2 />
              }
            />
            <Route
              path='partner-teams'
              element={
                // isSuperAdmin ? <LineMasterListViewV2 /> : <LineMasterListView />
                <PartnerTeamsView />
              }
            />
            <Route
              path='client-members'
              element={
                // isSuperAdmin ? <LineMasterListViewV2 /> : <LineMasterListView />
                <ClientMembersView />
              }
            />
          </>
        )}

        <Route path='connections' element={<ConnectionsView />} />

        <Route
          path='owner-statements'
          element={<Navigate to='/statements' />}
        />
        <Route path='owners' element={<OwnersView />} />
        <Route path='statements'>
          <Route
            index
            element={<StatementListView limit={tableLimits.statements} />}
          />
          <Route path=':listingId' element={<StatementView />} />
          <Route path='summary' element={<StatementSummaryView />} />
          <Route path='settings/*' element={<StatementSettingsView />} />
        </Route>

        <Route path='settings/*' element={<SettingsViewV2 />} />
        {/* {isPartnerAdmin ? (
      ) : (
        <Route path='settings'>
          <Route index element={<SettingsView />} />
          <Route path=':tab' element={<SettingsView />} />
        </Route>
      )} */}

        <Route path='files' index element={<FilesView />} />

        <Route
          path='callback'
          element={
            <Navigate
              to={`/connections${window.location.search || ''}&callback=true`}
            />
          }
        />
      </Routes>
    </Suspense>
  );
};
