import { Button } from '../buttons';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* eslint-disable react/prop-types */
import { Avatar, Card, Center, Text } from '@mantine/core';
import {
  ErrorBoundary as Boundary,
  ErrorBoundaryProps,
  FallbackRender,
} from '@sentry/react';

const ErrorFallback: FallbackRender = ({ error, resetError }) => {
  return (
    <Center sx={{ height: '100%' }}>
      <Card
        role='alert'
        radius='lg'
        sx={(theme) => ({
          width: '100%',
          maxWidth: 300,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          boxShadow: 'rgb(0 0 0 / 16%) 0px 24px 48px',
          border: '1px solid',
          borderColor: theme.colors.gray[2],
        })}
      >
        <Avatar color='red' variant='filled' mt='md' size={35} radius={35}>
          <FontAwesomeIcon icon={faX} />
        </Avatar>
        <Text component='p' mb='sm'>
          {error.message || 'Missing error message.'}
        </Text>
        <Text
          mb='lg'
          sx={(theme) => ({
            fontSize: theme.fontSizes.sm,
          })}
        >
          Something went wrong! Please let us know if this error persists.
        </Text>
        <Button onClick={resetError} fullWidth>
          Try again
        </Button>
      </Card>
    </Center>
  );
};

// const ErrorInPortal = ({ children }: { children: ReactNode }) => (
//   <Portal>
//     <Box sx={{ position: 'absolute', inset: 0 }}>
//       <Overlay
//         opacity={0.3}
//         color="#000"
//         sx={{ position: 'absolute', inset: 0 }}
//       />
//       {children}
//     </Box>
//   </Portal>
// );

export function ErrorBoundary({
  children,
  onReset,
  ...props
}: ErrorBoundaryProps) {
  return (
    <Boundary fallback={ErrorFallback} onReset={onReset} {...props}>
      {children}
    </Boundary>
  );
}
