import { StatementFilterParams } from '../types/statement-types';
import {
  ensure,
  gqlV2,
  useDashboardType,
  useMe,
  useOwnerPreviewId,
  useTeamId,
  useV2TransactionQuery,
} from '@finalytic/data-ui';
import { useMemo } from 'react';

export type ListStatement = ReturnType<
  typeof useStatementsList
>['ownerStatements'][number];

export function useStatementsList({
  filter: { search, status, startAt, statementIds },
  billingLimit,
}: {
  billingLimit: number | undefined;
  filter: StatementFilterParams;
}) {
  const [tid] = useTeamId();
  const { ownerPreview } = useOwnerPreviewId();
  const [dashboard] = useDashboardType();
  const { ownerships } = useMe();

  const teamId = ownerPreview?.teamId || tid;

  const searchQuery = useMemo(() => {
    if (search) {
      const trimmedSearch = search.trim();
      return ensure<gqlV2.owner_statement_bool_exp[]>([
        {
          listing: {
            ownerships: {
              owner: {
                _or: [
                  {
                    lastName: { _ilike: `%${trimmedSearch}%` },
                  },
                  {
                    firstName: { _ilike: `%${trimmedSearch}%` },
                  },
                  {
                    companyName: { _ilike: `%${trimmedSearch}%` },
                  },
                ],
              },
            },
          },
        },
        {
          listing: {
            name: { _ilike: `%${trimmedSearch}%` },
          },
        },
      ]);
    }
    return undefined;
  }, [search]);

  const {
    data,
    isLoading: loading,
    refetch,
  } = useV2TransactionQuery(
    (q, args) => {
      const ownerStatements = q
        .ownerStatements({
          where: args.where,
          // limit: args.limit,
          order_by: [{ owner: { lastName: 'asc_nulls_first' } }],
        })
        .map((statement) => ({
          id: statement.id,
          status: statement.status,
          startAt: statement.startAt,
          endAt: statement.endAt,
          currency: statement.currency,
          centTotal: statement.centTotal,
          centBalanceStart: statement.centBalanceStart,
          centPayedOut: statement.centPayedOut,
          centBalanceEnd: statement.centBalanceEnd,
          templateId: statement.templateId,
          owners: statement.owners().map((ship) => ({
            id: ship.id,
            owner: {
              id: ship.owner?.id,
              name: ship.owner?.name,
              firstName: ship.owner?.firstName,
              lastName: ship.owner?.lastName,
              companyName: ship.owner?.companyName,
            },
          })),
          listing: {
            id: statement.listing?.id,
            name: statement.listing?.title || statement.listing?.name,
          },
        }));

      const ownerStatementsAggregateCount =
        q.ownerStatementAggregate({ where: args.where }).aggregate?.count() ||
        0;

      return {
        ownerStatements,
        ownerStatementsAggregateCount,
      };
    },
    {
      queryKey: 'ownerStatements',
      variables: {
        where: ensure<gqlV2.owner_statement_bool_exp>({
          tenantId: { _eq: teamId },
          id: statementIds?.length ? { _in: statementIds } : undefined,
          status:
            dashboard === 'owner'
              ? { _in: ['published', 'posted'] }
              : status
              ? { _eq: status }
              : undefined,
          startAt: { _eq: startAt },
          listingId:
            dashboard === 'owner'
              ? { _in: ownerships.map((i) => i.listingId) }
              : undefined,
          _or: searchQuery,
        }),
        limit: billingLimit,
      },
    }
  );

  const statements = data?.ownerStatements || [];
  const statementsAmount =
    billingLimit && (data?.ownerStatementsAggregateCount || 0) > billingLimit
      ? data?.ownerStatementsAggregateCount
      : billingLimit;

  const ownerStatements: typeof statements = billingLimit
    ? [
        ...statements,
        ...new Array(statementsAmount! - billingLimit).fill({
          ...statements[0],
          centTotal: 0,
          centBalanceStart: 0,
          centPayedOut: 0,
          centBalanceEnd: 0,
        }),
      ]
    : statements;

  return {
    refetch,
    ownerStatements,
    loading,
  };
}
