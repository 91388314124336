import { IconDefinition, faBolt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Text, Tooltip } from '@mantine/core';
import { useMemo } from 'react';

export const NavbarIssueBadge = ({
  amount,
  icon = faBolt,
  tooltipText,
  onClick,
}: {
  amount: number;
  icon?: IconDefinition;
  tooltipText: (label: number | string, amount: number) => string;
  onClick?: () => void;
}) => {
  const { label, tooltip } = useMemo(() => {
    const a = amount > 99 ? '99+' : amount;

    return { tooltip: tooltipText(a, amount), label: a };
  }, [amount, tooltipText]);

  const clicked: any = (event: MouseEvent) => {
    event.preventDefault();

    if (onClick) onClick();
  };

  if (amount < 1) return null;

  return (
    <Tooltip
      label={tooltip}
      withArrow
      position='top-end'
      radius={10}
      styles={{
        arrow: {
          right: '18px !important',
        },
      }}
    >
      <Box
        component={onClick ? 'button' : 'div'}
        onClick={onClick ? clicked : undefined}
        px='xs'
        py={2}
        sx={(theme) => ({
          cursor: onClick ? 'pointer' : undefined,
          display: 'flex',
          flexWrap: 'nowrap',
          alignItems: 'center',
          backgroundColor: '#83184380',
          borderRadius: theme.radius.lg,
          'span, & > svg.lightning > path': {
            color: '#F9A8D4' + '!important',
            fill: '#F9A8D4' + '!important',
            opacity: 'initial',
          },
          transition: 'transform 0.1s ease-out',
          border: 'none',
          ':hover': !onClick
            ? undefined
            : {
                backgroundColor: '#831843',
              },
          ':active': !onClick
            ? undefined
            : {
                transform: 'translateY(1px)',
              },
        })}
      >
        <FontAwesomeIcon icon={icon} size='xs' className='lightning' />
        <Text ml={5} component='span' size='xs' weight={500}>
          {label}
        </Text>
      </Box>
    </Tooltip>
  );
};
