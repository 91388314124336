import {
  ConnectionModalContextProvider,
  ConnectionModalContextProviderProps,
  useConnectionModalContext,
} from './_context';
import { viewsUnion } from './_types';
import { ConnectionConfirmModalScreen } from './screens/ConnectionConfirmModalScreen';
import { ConnectionListModalScreen } from './screens/ConnectionListModalScreen';
import { ConnectionLoadingModalScreen } from './screens/ConnectionLoadingModalScreen';
import { ConnectionParametersModalScreen } from './screens/ConnectionParametersModalScreen';
import { ConnectionResultModalScreen } from './screens/ConnectionResultModalScreen';
import { Modal } from '@finalytic/ui';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Group, Text } from '@mantine/core';
import { useMemo } from 'react';

export type ConnectionModalProps = InternalConnectionModalProps &
  ConnectionModalContextProviderProps;

export type InternalConnectionModalProps = {
  opened: boolean;
  onClose: () => void;
  view?: viewsUnion;
  loadingText?: string;
};

const ActiveView = ({
  opened,
  onClose: _onClose,
  view: storybookView,
  loadingText,
}: InternalConnectionModalProps) => {
  const {
    view: contextView,
    resetView,
    app,
    presetApp,
  } = useConnectionModalContext();

  const view = storybookView || contextView;

  const isListView = view === 'list-view';

  const onClose = () => {
    _onClose();
    // Set timeout to prevent ui glitching
    setTimeout(() => {
      resetView();
    }, 300);
  };

  const active = useMemo(() => {
    switch (view) {
      case 'list-view':
        if (app) {
          return <ConnectionConfirmModalScreen />;
        }
        return <ConnectionListModalScreen />;

      case 'confirm-view':
        return <ConnectionConfirmModalScreen />;

      case 'loading-view':
        return <ConnectionLoadingModalScreen text={loadingText} />;

      case 'success-view':
        return <ConnectionResultModalScreen type='success' />;

      case 'error-view':
        return <ConnectionResultModalScreen type='error' />;

      case 'enter-parameter-view':
        return <ConnectionParametersModalScreen />;

      case 'close-view':
        onClose();
        return null;

      default:
        return <ConnectionListModalScreen />;
    }
  }, [view, app, loadingText]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={1000}
      title={
        isListView && !app ? 'Add a Connection' : !presetApp && <BackIcon />
      }
      styles={{
        root: {
          visibility: view === 'screencast-view' ? 'hidden' : undefined,
          overflowX: 'hidden',
        },
        header: { backgroundColor: 'transparent' },
        body: {
          height: '500px',
          minHeight: '500px',
          maxHeight: '700px',
        },
      }}
    >
      {active}
    </Modal>
  );
};

const BackIcon = ({ title }: { title?: string }) => {
  const { resetView } = useConnectionModalContext();

  return (
    <Group noWrap spacing={5}>
      <ActionIcon onClick={resetView}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </ActionIcon>
      {title && (
        <Text size='lg' component='span' pb={1}>
          {title}
        </Text>
      )}
    </Group>
  );
};

export const ConnectionModal = ({
  refetchConnections,
  apps,
  presetApp,
  addConnection,
  ...activeViewProps
}: ConnectionModalProps) => {
  return (
    <ConnectionModalContextProvider
      addConnection={addConnection}
      refetchConnections={refetchConnections}
      apps={apps}
      presetApp={presetApp}
    >
      <ActiveView {...activeViewProps} />
    </ConnectionModalContextProvider>
  );
};
