import { useQuery } from './graphql';
import { ArrayElement } from '@finalytic/utils';
import * as React from 'react';

function _useApps() {
  const ref = React.useRef();

  // console.log('USER ID', id);
  const [{ data, fetching, error }] = useQuery(
    {
      app: [
        {},
        {
          id: true,
          name: true,
          iconRound: true,
        },
      ],
    },
    { pause: !ref.current }
  );
  return {
    apps: data?.app,
    loading: fetching,
    error,
  };
}

// Warning: Cannot update a component (`AppsProvider`) while rendering a different component (`AppsProvider`). To locate the bad setState() call inside `AppsProvider`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render

export const appsContext = React.createContext<{
  [s: string]: ArrayElement<NonNullable<ReturnType<typeof _useApps>['apps']>>;
}>(undefined as any);

/*export function AppsProvider({ children }: { children: React.ReactNode }) {
  const { apps } = _useApps();
  const memo = React.useMemo(() => {
    return apps?.reduce((acc, app) => ({ ...acc, [app.id]: app }), {}) || {};
  }, [apps]);

  return <context.Provider value={memo}>{children}</context.Provider>;
}*/

export function useApps() {
  const role = React.useContext(appsContext);
  return role;
}
