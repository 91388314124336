import { MappingRow } from '../_tables';
import { create } from 'zustand';

export type AutomationChangeStore = {
  changes: { [automationId: string]: { [s: string]: MappingRow } };
  filter: {
    [automationId: string]: {
      [tabKey: string]: { search: string; unmappedOnly: boolean };
    };
  };
  missingSettings: { [key: string]: number };
  numberOfChanges: number;
};

export type AutomationChangeActions = {
  setMissingSettings: (key: string, number: number) => void;
  reset: (automationId?: string) => void;
  getChanges: (
    automationId: string
  ) => AutomationChangeStore['changes'][string];
  setFilter: (
    automationId: string,
    tabKey: string,
    filer: { search?: string; unmappedOnly?: boolean }
  ) => void;
  globalChanges: (
    automationId: string
  ) => AutomationChangeStore['changes'][string];
  mappingChanges: (
    automationId: string
  ) => AutomationChangeStore['changes'][string];
  add: (
    data: MappingRow,
    {
      isGlobalSetting,
      settingKey,
      automationId,
    }: { isGlobalSetting: boolean; settingKey: string; automationId: string }
  ) => void;
  find: ({
    isGlobalSetting,
    settingKey,
    target,
  }: {
    target: string;
    settingKey: string;
    isGlobalSetting: boolean;
    automationId: string;
  }) => MappingRow;
};

const initialState: AutomationChangeStore = {
  changes: {},
  filter: {},
  missingSettings: {},
  numberOfChanges: 0,
};

export const useAutomationChangeStore = create<
  AutomationChangeStore & AutomationChangeActions
>()((set, get) => ({
  ...initialState,
  getChanges: (automationId) => get()['changes'][automationId],
  reset: (automationId) =>
    set((store) => {
      if (automationId) {
        const updatedChanges = { ...store.changes };
        delete updatedChanges[automationId];
        return { ...store, ...initialState, changes: updatedChanges };
      } else {
        return { ...store, ...initialState };
      }
    }),
  setMissingSettings: (key, num) =>
    set((store) => {
      const t = { ...store.missingSettings };
      t[key] = num;
      return { ...store, missingSettings: t };
    }),
  setFilter: (automationId, tabKey, newFilter) =>
    set((store) => {
      const filter = { ...store.filter };

      if (!filter[automationId] || !filter[automationId][tabKey])
        filter[automationId] = {
          ...(filter[automationId] || {}),
          [tabKey]: { search: '', unmappedOnly: false },
        };
      filter[automationId] = {
        ...filter[automationId],
        [tabKey]: { ...filter[automationId][tabKey], ...newFilter },
      };

      return { ...store, filter };
    }),
  add: (data, { isGlobalSetting, settingKey, automationId }) =>
    set((state) => {
      const key = `${isGlobalSetting ? 'global.' : ''}${settingKey}.${
        data.target
      }`;

      const changes = { ...state.changes };

      if (!data.settingId && !data.value && changes[automationId]) {
        // empty change
        delete changes[automationId][key];
      } else {
        if (!changes[automationId]) changes[automationId] = {};
        changes[automationId][key] = data;
      }

      return {
        ...state,
        changes,
        numberOfChanges: Object.keys(changes[automationId]).length,
      };
    }),
  find: ({ isGlobalSetting, settingKey, target, automationId }) => {
    const key = `${isGlobalSetting ? 'global.' : ''}${settingKey}.${target}`;
    return { ...get()['changes'][automationId] }[key];
  },
  globalChanges: (automationId) => {
    const d = get()['changes'][automationId];

    if (d) {
      const t = { ...d };
      Object.keys(d).forEach((key) => {
        if (key.split('.')[0] !== 'global') {
          delete t[key];
        }
      });
      return t;
    }
    return {};
  },
  mappingChanges: (automationId) => {
    const d = get()['changes'][automationId];

    if (d) {
      const t = { ...d };
      Object.keys(d).forEach((key) => {
        if (['global', 'setting'].includes(key.split('.')[0])) {
          delete t[key];
        }
      });
      return t;
    }
    return {};
  },
}));
