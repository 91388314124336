import { InputsBySchema, Schema, SchemaOutputType } from '../../inputs';
import { ErrorScreen, SuccessScreen } from './_screens';
import {
  ActionButton,
  LoadingIndicator,
  TransparentButton,
} from '@finalytic/ui';
import { Modal } from '@finalytic/ui';
import { showErrorNotification } from '@finalytic/ui';
import { useColors } from '@finalytic/ui';
import { waitFor } from '@finalytic/utils';
import { Box, Center, Stack, Text } from '@mantine/core';
import { ElementType, ReactNode, useEffect, useState } from 'react';
import { SubmitErrorHandler, UseFormReturn, useForm } from 'react-hook-form';

export type ConfirmModalProps<T extends Schema> = {
  autoCloseOnSuccess?: boolean | number | string;
  autoSubmit?: boolean;
  opened: boolean;
  onSuccess?: (result: SchemaOutputType<T>) => void;
  onClose?: (success: boolean) => void;
  title: string;
  subtitle?: ReactNode;
  onSubmit: SubmitConfirmModalType<SchemaOutputType<T>>;
  icon?: ReactNode;
  schema?: T;
  size?: string | number;
  submitButtonTitle?: string;
  withCloseButton?: boolean;
  value?: ConfirmResultType;
  customSubmitBody?: ElementType<{
    methods: UseFormReturn<SchemaOutputType<T>, any>;
    submit: (onInvalid?: SubmitErrorHandler<SchemaOutputType<T>>) => void;
  }>;
};

export type ConfirmResultType = {
  message?: string;
  isSuccess: boolean;
  autoCloseOnSuccess?: ConfirmModalProps<any>['autoCloseOnSuccess'];
};
export type SubmitConfirmModalType<T = any> = (
  value?: T
) => Promise<ConfirmResultType>;

export const ConfirmModal = <T extends Schema>({
  opened,
  onClose: $onClose,
  onSuccess,
  title,
  subtitle,
  onSubmit,
  autoSubmit,
  icon,
  schema,
  autoCloseOnSuccess,
  size = 'sm',
  submitButtonTitle = 'Submit',
  value,
  customSubmitBody: CustomSubmitBody,
  withCloseButton = false,
}: ConfirmModalProps<T>) => {
  const { gray } = useColors();

  const [r, setResult] = useState<ConfirmResultType>({
    isSuccess: false,
    message: undefined,
    autoCloseOnSuccess: undefined,
  });
  const result = value || r;

  const [loading, setLoading] = useState(false);

  const methods = useForm<SchemaOutputType<T>>({ mode: 'onChange' });

  const submit = async (data: SchemaOutputType<T>) => {
    setLoading(true);
    try {
      const result = await onSubmit(data);
      setResult(result);
      if (result.isSuccess) {
        if (onSuccess) onSuccess(data);
        const $autoCloseOnSuccess =
          result.autoCloseOnSuccess || autoCloseOnSuccess;
        if ($autoCloseOnSuccess) {
          await waitFor(
            $autoCloseOnSuccess === true ? '1s' : $autoCloseOnSuccess
          );
          onClose();
        }
      }
      /*showSuccessNotification({
        message:
          'Your action was successfull! This notification will close in 2 seconds.',
        title: 'Success!',
      });*/
    } catch (error: any) {
      console.log({ error });
      showErrorNotification({
        title: 'Please try again.',
        message: error?.message || error || 'Something went wrong...',
      });
    }
  };

  const onClose = () => {
    setTimeout(() => {
      setLoading(false);
      setResult({ isSuccess: false, message: undefined });
    }, 300);
    if ($onClose) $onClose(result.isSuccess);
  };

  const reset = () => {
    setLoading(false);
    setResult({ isSuccess: false, message: undefined });
  };

  useEffect(() => {
    if (autoSubmit && opened) {
      submit({} as any);
    }
  }, [autoSubmit, opened]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={size}
      withCloseButton={withCloseButton}
      title={withCloseButton && title}
    >
      <Box
        mb='sm'
        sx={{
          minHeight: icon ? 250 : undefined,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: !CustomSubmitBody ? 'flex-end' : undefined,
        }}
      >
        {!result?.message ? (
          loading ? (
            // LOADING SCREEN
            <Center sx={{ flex: 1, minHeight: 250 }}>
              <LoadingIndicator
                isFullPageLoading={false}
                message='Loading...'
              />
            </Center>
          ) : CustomSubmitBody ? (
            <CustomSubmitBody
              methods={methods}
              submit={(onInvalid) => methods.handleSubmit(submit, onInvalid)()}
            />
          ) : (
            // SUBMIT SCREEN
            <>
              <Stack align='center' mb={20}>
                {icon && <Center mt={10}>{icon}</Center>}
                <Box mb={10} mt={15} sx={{ textAlign: 'center' }}>
                  {!withCloseButton && title && schema && (
                    <Text weight={500} mb={10}>
                      {title}
                    </Text>
                  )}
                  {schema && (
                    <InputsBySchema schema={schema} methods={methods as any} />
                  )}
                  {subtitle && <Text size='sm'>{subtitle}</Text>}
                </Box>
              </Stack>

              <ActionButton
                onClick={methods.handleSubmit(submit)}
                disabled={schema && !methods.formState.isValid}
                fullWidth
                mb={10}
                sx={{ height: 40 }}
              >
                {submitButtonTitle}
              </ActionButton>
              <TransparentButton
                onClick={onClose}
                fullWidth
                sx={{ color: gray.dark }}
              >
                Cancel
              </TransparentButton>
            </>
          )
        ) : result.isSuccess ? (
          // SUCCESS SCREEN
          <SuccessScreen onClose={onClose} resultMessage={result.message} />
        ) : (
          // ERROR SCREEN
          <ErrorScreen
            onClose={onClose}
            resultMessage={result.message}
            onSubmit={reset}
          />
        )}
      </Box>
    </Modal>
  );
};
