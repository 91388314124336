import { useTeamId } from '../..';
import { useClerk, useUser } from '@finalytic/authentication';
import { Button, LoadingIndicator } from '@finalytic/ui';
import { Center } from '@mantine/core';
import { useEffect } from 'react';

export const EmptyView = ({
  title = 'No team available for this user.',
  loading = false,
}: {
  title?: string;
  loading?: boolean;
}) => {
  const { signOut } = useClerk();
  const [teamId] = useTeamId();
  const { user } = useUser();

  useEffect(() => {
    let interval: any | undefined;

    if (loading) {
      interval = setInterval(async () => {
        await user?.reload(); // refetch on interval clerk public metadata with tenant_ids
        if (teamId || user?.publicMetadata?.user_id) {
          // window.location.reload(); // when there is
        }
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [loading]);

  return (
    <Center sx={{ height: '100vh', flexDirection: 'column' }}>
      {loading ? (
        <LoadingIndicator isFullPageLoading message='Loading Dashboard...' />
      ) : (
        <>
          <p>{title}</p>
          <Button onClick={() => signOut()}>Logout</Button>
        </>
      )}
    </Center>
  );
};
