import {
  useNotifiedV2Mutation,
  useOwnerPreviewId,
  useTrpcMutation,
} from '../../../hooks';
import { InviteOwnerModal } from '../../modal';
import {
  DeleteModal,
  EllipsisMenu,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  showErrorNotification,
} from '@finalytic/ui';
import {
  faClipboard,
  faEye,
  faPaperPlane,
  faRotateRight,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { useClipboard } from '@mantine/hooks';
import { useState } from 'react';

type OwnerDrawerMenuButtonProps = {
  ownerId?: string;
  teamId?: string;
  firstName: string;
  lastName: string;
  email: string;
  isReinvite: boolean;
  refetchTable: () => void;
  closeDrawer: () => void;
};

export const OwnerDrawerMenuButton = ({
  ownerId,
  teamId,
  firstName,
  lastName,
  isReinvite,
  email,
  refetchTable,
  closeDrawer,
}: OwnerDrawerMenuButtonProps) => {
  const [inviteOpen, setInviteOpen] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);

  const { setOwnerPreview } = useOwnerPreviewId();

  const { copy } = useClipboard();

  const { mutate: remove } = useNotifiedV2Mutation(
    (q, args: { userId: string; teamId: string }) => {
      const event = q.insertScheduledEvent({
        object: {
          objectId: args.userId,
          tenantId: args.teamId,
          op: 'archiveOwner',
          tableName: 'users',
        },
      });

      return {
        ok: !!event?.id,
      };
    },
    {
      successMessage: {
        title: 'Success!',
        message: 'You successfully removed this owner from your team.',
      },
      invalidateQueryKeys: ['users', 'scheduledEvents'],
    }
  );

  const removeOwner = async () => {
    if (ownerId && teamId) {
      await remove({ args: { userId: ownerId, teamId } }).then((res) => {
        if (res.ok) {
          window.analytics?.track('Owner removed', {
            ownerId,
            teamId,
          });
          closeDrawer();
        }
      });
    }
  };

  const { mutate: mutateInvitationUrl } = useTrpcMutation(
    'actions',
    'copyInvitationUrl',
    {
      successMessage: {
        title: 'Copied to clipboard!',
        message: 'Successfully received invitation url.',
      },
    }
  );

  if (!ownerId || !teamId) return null;

  const enablePreview = () => setOwnerPreview({ userId: ownerId });

  const receiveInvitationUrl = async () => {
    const res = await mutateInvitationUrl({ teamId, userId: ownerId });
    if (res.signedInvitationUrl) {
      window.analytics?.track('Invitation url copied', {
        ownerId,
        teamId,
      });

      copy(res.signedInvitationUrl);
    } else {
      showErrorNotification({
        title: 'Failed to copy url.',
        message: 'Missing invite url.',
      });
    }
  };

  return (
    <>
      <EllipsisMenu width={250}>
        <EllipsisMenuItem icon={faEye} onClick={enablePreview}>
          Owner Preview
        </EllipsisMenuItem>

        {isReinvite && <EllipsisMenuDivider />}
        <EllipsisMenuItem
          onClick={() => setInviteOpen(true)}
          icon={isReinvite ? faRotateRight : faPaperPlane}
        >
          {isReinvite ? 'Resend email invitation' : 'Email invite to app'}
        </EllipsisMenuItem>

        {isReinvite && (
          <EllipsisMenuItem onClick={receiveInvitationUrl} icon={faClipboard}>
            Copy invitation url
          </EllipsisMenuItem>
        )}

        <EllipsisMenuDivider />
        <EllipsisMenuDangerItem onClick={() => setRemoveOpen(true)}>
          Delete Owner
        </EllipsisMenuDangerItem>
      </EllipsisMenu>

      <DeleteModal
        opened={removeOpen}
        onClose={() => setRemoveOpen(false)}
        title={
          firstName
            ? `Would you like to remove: ${firstName}${
                lastName ? ` ${lastName}?` : '?'
              }`
            : 'Are you sure to remove this owner?'
        }
        subtitle='Removal will not change any previously finished statements. The owner will also not loose access to the statements dashboard if previously given.'
        onSubmit={removeOwner}
        size={400}
      />

      <InviteOwnerModal
        email={email}
        name={`${firstName} ${lastName}`}
        isReinvite={isReinvite}
        refetchTable={refetchTable}
        opened={inviteOpen}
        closeModal={() => setInviteOpen(false)}
      />
    </>
  );
};
