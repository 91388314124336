import { useNotifiedV2Mutation, useTeamRole } from '../../hooks';
import {
  EllipsisMenu,
  EllipsisMenuDangerItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  EllipsisMenuLabel,
} from '@finalytic/ui';
import { faPercent, faPercentage } from '@fortawesome/pro-solid-svg-icons';
import { useClipboard } from '@mantine/hooks';

export const ListingOwnerEllipsisMenu = ({
  ownershipId,
  refetchTable,
  withOwnerSplitReset,
}: {
  ownershipId: string;
  refetchTable: () => void;
  withOwnerSplitReset: boolean;
}) => {
  const { isSuperAdmin } = useTeamRole();

  const { mutate: removeOwnership } = useNotifiedV2Mutation(
    (
      q,
      args: {
        ownershipId: string;
      }
    ) => {
      const res = q.deleteListingOwner({
        id: args.ownershipId,
      });
      return {
        ok: !!res?.id,
      };
    },
    {
      successMessage: {
        title: 'Success!',
        message: 'You successfully removed the ownership.',
      },
      invalidateQueryKeys: ['listingOwners'],
    }
  );
  const { mutate: resetSplit } = useOwnerSplitMutation();

  const { copy } = useClipboard();

  return (
    <EllipsisMenu>
      {withOwnerSplitReset && (
        <>
          <EllipsisMenuLabel>General</EllipsisMenuLabel>
          <EllipsisMenuItem
            icon={faPercent}
            onClick={() =>
              resetSplit({
                args: {
                  listingOwnerId: ownershipId,
                  split: null,
                },
              }).then(refetchTable)
            }
          >
            Reset owner split
          </EllipsisMenuItem>
          <EllipsisMenuDivider />
        </>
      )}
      {isSuperAdmin && (
        <>
          <EllipsisMenuLabel>Super Admin</EllipsisMenuLabel>
          <EllipsisMenuItem onClick={() => copy(ownershipId)}>
            Copy listing owner ID
          </EllipsisMenuItem>
          <EllipsisMenuDivider />
        </>
      )}
      <EllipsisMenuDangerItem
        onClick={() =>
          removeOwnership({ args: { ownershipId } }).then(refetchTable)
        }
      >
        Remove ownership
      </EllipsisMenuDangerItem>
    </EllipsisMenu>
  );
};

export function useOwnerSplitMutation() {
  return useNotifiedV2Mutation(
    (
      q,
      args: {
        listingOwnerId: string;
        split: number | null;
      }
    ) => {
      const res = q.updateListingOwner({
        pk_columns: { id: args.listingOwnerId },
        _set: { split: args.split },
      });
      return {
        ok: !!res?.id,
      };
    },
    {
      successMessage: {
        title: 'Ownership split updated',
        message: 'You successfully the split for this ownership.',
      },
    }
  );
}
