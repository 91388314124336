import { Banner } from './Banner';
import { Text } from '@mantine/core';

export const DevEnvironmentBanner = () => {
  return (
    <Banner>
      <Text size='xs' component='p' m={0}>
        You are previewing{' '}
        <Text weight={500} component='span'>
          Development
        </Text>
        .
      </Text>
    </Banner>
  );
};
