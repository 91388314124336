import { groupStatementLinesBy } from '../_helpers';
import {
  ExpenseRow,
  ExpenseSection,
  OtherSection,
  StatementLine,
  StatementSummaryUnion,
} from '../_types';
import {
  formatCurrency,
  sortBy,
  sum,
  toTitleCase,
  utc,
} from '@finalytic/utils';

export const getExpenseSections = ({
  templateSections,
  lines,
  groupedBy,
}: {
  templateSections: OtherSection[];
  lines: StatementLine[];
  groupedBy: StatementSummaryUnion;
}): ExpenseSection[] => {
  const currency = lines[0]?.currency || 'USD';

  return templateSections.map((section) => {
    const rows = getSectionLines({
      groupedBy,
      section,
      statementLines: lines,
    });

    const total = sum(rows, 'value');
    const totalFormatted = formatCurrency(total, currency);
    return {
      id: section.id,
      title: section.name,
      aggregated: !!section.aggregate,
      collapsedInPmsDashboard: !!section.collapsedInPmsDashboard,
      showTypeColumn: !!section.showTypeColumn,
      showZeroAmount: !!section.showZeroAmounts,
      rows,
      total,
      totalFormatted,
    };
  });
};

const getSectionLines = ({
  section,
  statementLines,
  groupedBy,
}: {
  statementLines: StatementLine[];
  section: OtherSection;
  groupedBy: StatementSummaryUnion;
}): ExpenseRow[] => {
  const accounts = section.value;
  const sectionLines = sortBy(
    statementLines.filter((i) => {
      const showZeroAmounts = section.showZeroAmounts
        ? true
        : i.centTotal !== 0;

      const isIncluded =
        accounts.includes(i.group?.remoteId || '') && !i?.isOwnerPayout;

      return isIncluded && showZeroAmounts;
    }),
    'date'
  );

  // groupByReservation means no grouping => show each line
  if (groupedBy === 'groupByReservation') {
    return sectionLines.map<ExpenseRow>((line) => {
      const amount = (line.centTotal || 0) / 100;
      const currency = line.currency || 'USD';
      return {
        id: line.id,
        name: utc(line.date).format('DD MMM YYYY'),
        description: line.description || '',
        type: line.group?.description || '',
        value: amount,
        formattedValue: formatCurrency(amount, currency),
      };
    });
    // If grouped otherwise we can omit description & type
  } else if (groupedBy === 'groupByMonth') {
    const group = groupStatementLinesBy({
      groupedBy,
      lines: sectionLines,
      groupedListingAndMonth: false,
    });

    return Object.entries(group).map<ExpenseRow>(([key, lines]) => {
      const amount = sum(lines, 'centTotal') / 100;
      const currency = lines[0].currency || 'USD';

      return {
        id: key,
        name: utc(key).format('MMM YYYY'),
        description: '',
        type: '',
        value: amount,
        formattedValue: formatCurrency(amount, currency),
      };
    });
  } else if (groupedBy === 'groupByBookingChannel') {
    const group = groupStatementLinesBy({
      groupedBy,
      lines: sectionLines,
      groupedListingAndMonth: false,
    });

    return Object.entries(group).map<ExpenseRow>(([key, lines]) => {
      const amount = sum(lines, 'centTotal') / 100;
      const currency = lines[0].currency || 'USD';

      return {
        id: key,
        name: toTitleCase(key) || '',
        description: '',
        type: '',
        value: amount,
        formattedValue: formatCurrency(amount, currency),
      };
    });
  } else if (groupedBy === 'groupByListing') {
    const group = groupStatementLinesBy({
      groupedBy,
      lines: sectionLines,
      groupedListingAndMonth: false,
    });

    return Object.entries(group).map<ExpenseRow>(([key, lines]) => {
      const amount = sum(lines, 'centTotal') / 100;
      const currency = lines[0].currency || 'USD';

      return {
        id: key,
        name: lines[0].reservation?.listing?.name || 'Missing listing name',
        description: '',
        type: '',
        value: amount,
        formattedValue: formatCurrency(amount, currency),
      };
    });
  }

  // should never be reached
  return [];
};
