import { useStatementAccounts, useUnmapped } from './_hooks';
import { useTeam, useV2TransactionSubscription } from '@finalytic/data-ui';
import { NavbarIssueBadge } from '@finalytic/ui';
import { faCoins } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router';

export const AccountsIssueBadge = () => {
  const [{ id: teamId }] = useTeam();
  const goto = useNavigate();

  const { accounts, loading } = useStatementAccounts();

  const { data: template } = useV2TransactionSubscription(
    (q, { teamId }) => {
      const templates = q
        .ownerStatementTemplates({
          limit: 1,
          where: { tenantId: { _eq: teamId } },
          order_by: [{ createdAt: 'desc' }],
        })
        .map((i) => ({ id: i.id, template: i.data() }));

      return templates[0];
    },
    { teamId }
  );

  const { unmappedAccounts } = useUnmapped({
    accounts,
    loading,
    template: template?.template,
  });

  return (
    <NavbarIssueBadge
      amount={unmappedAccounts.length || 0}
      onClick={() => goto('/statements/settings/account-mapping?unmapped=1')}
      icon={faCoins}
      tooltipText={(label, amount) =>
        `${label} account${amount > 1 ? 's' : ''} unmapped`
      }
    />
  );
};
