import { WhiteTagCell } from './WhiteTagCell';
import { ICellRendererParams } from '@finalytic/ui-grid';
import { toTitleCase } from '@finalytic/utils';

export const ConnectionCategoryCell = (params: ICellRendererParams) => {
  return (
    <WhiteTagCell sx={{ lineHeight: '1rem' }}>
      {toTitleCase(params.value)}
    </WhiteTagCell>
  );
};
