import { Button, ButtonProps } from '@mantine/core';
import { ComponentPropsWithRef } from 'react';

export const DangerButton = ({
  children,
  onClick,
  sx,
  ...props
}: ButtonProps & ComponentPropsWithRef<'button'>) => {
  return (
    <Button
      onClick={onClick}
      radius={10}
      px={10}
      color='red'
      sx={(theme) => ({
        backgroundColor: theme.colors.red[7],
        ':hover': {
          backgroundColor: theme.colors.red[8],
        },
        ...(sx as any),
      })}
      {...props}
    >
      {children}
    </Button>
  );
};
