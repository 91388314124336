import { ActionButton, Button } from '../buttons';
import { Box, Checkbox, Group, Menu, Text } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';

export const SRMHeaderSelectCell = (props: any) => {
  const refButton = useRef(null);
  const [checked, setChecked] = useState(false);
  const [opened, setOpened] = useState(false);
  const open = () => setOpened(true);
  const close = () => setOpened(false);

  const showCheckbox = props.column?.colDef?.checkboxSelection;
  const onSelectAllPages: undefined | ((selected: boolean) => void) =
    props?.onSelectAllPages;

  const onMenuClicked = () => {
    props.showColumnMenu(refButton.current);
  };

  const toggleSelect = (checked: boolean) => {
    if (checked) {
      props?.api?.forEachNode((node: any) => {
        node.selectThisNode(true);
      });
      const event: any = {
        type: 'selectionChanged',
        api: props.api,
        columnApi: props.columnApi,
      };

      props?.api?.eventService?.dispatchEvent(event);
      setChecked(checked);

      const pageNumber: number = props?.api?.paginationGetTotalPages() || 0;

      if (onSelectAllPages && pageNumber > 1) open();
    } else {
      setChecked(false);
      onSelectAllPages?.(false);
      props?.api.deselectAll();
    }
  };

  const onPageChanged = () => {
    props?.api.deselectAll();

    setChecked(false);
    if (onSelectAllPages) {
      close();
      onSelectAllPages(false);
    }
  };

  useEffect(() => {
    props.api.addEventListener('paginationChanged', onPageChanged);

    return () => {
      props.api.removeEventListener('paginationChanged', onPageChanged);
    };
  }, []);

  let menu = null;
  if (props.enableMenu) {
    menu = (
      <div
        ref={refButton}
        className='customHeaderMenuButton'
        onClick={() => onMenuClicked()}
      >
        <i className={`fa ${props.menuIcon}`} />
      </div>
    );
  }

  return (
    <>
      <Menu
        opened={opened}
        onChange={setOpened}
        onClose={close}
        withinPortal
        withArrow
        position='bottom-start'
        radius='md'
        // arrowOffset={20}
        offset={15}
        shadow='md'
      >
        <Menu.Target>
          <Box />
        </Menu.Target>
        {/* Menu content */}
        <Menu.Dropdown p='sm'>
          <Text mb='sm' weight={500}>
            There is more than 1 page. Which Items would you like to select?
          </Text>
          <Group noWrap w='100%'>
            <ActionButton
              fullWidth
              onClick={() => {
                onSelectAllPages?.(true);
                close();
              }}
            >
              All pages
            </ActionButton>
            <Button fullWidth onClick={close}>
              Current page
            </Button>
          </Group>
        </Menu.Dropdown>
      </Menu>

      <Group spacing={4}>
        {showCheckbox && (
          <Checkbox
            color='blue'
            checked={checked}
            onChange={(e) => toggleSelect(e.currentTarget.checked)}
          />
        )}
        {menu}
        <div>{props.displayName}</div>
      </Group>
    </>
  );
};
