import { useColors } from '../../../styles';
import { Button, Group, Text } from '@mantine/core';

type PopoverHeaderProps = {
  title: string;
  handleFilterReset: () => void;
};

export const PopoverHeader = ({
  title,
  handleFilterReset,
}: PopoverHeaderProps) => {
  const { purple } = useColors();

  return (
    <Group position='apart' mb={10}>
      <Group spacing={12}>
        <Text size='lg' weight={600}>
          {title}
        </Text>
      </Group>
      <Button
        radius={3}
        variant='white'
        onClick={handleFilterReset}
        size='xs'
        p={0}
        sx={{
          color: purple.base,
          height: '20px',
          '&:focus': {
            outlineColor: purple.light,
          },
        }}
      >
        Clear
      </Button>
    </Group>
  );
};
