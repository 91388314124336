import { ColDef, ICellRendererParams } from '@finalytic/ui-grid';
import { useMemo } from 'react';

import { useAuditDrawer } from '../../components';
import { useNotifiedV2Mutation } from '../../hooks';
import { useFileDownload } from './_hooks';
import {
  DeleteModal,
  EllipsisMenu,
  EllipsisMenuItem,
  EllipsisMenuLabel,
  IconButton,
  NoFilesTableOverlay,
  Table,
  useColors,
} from '@finalytic/ui';
import {
  faDownload,
  faHistory,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';

type Props = {
  rowData: {
    id: string | undefined;
    type: string | undefined;
    ownerName: string | undefined;
    startDate: string | undefined;
  }[];
  refetch: () => void;
  hideOwnerColumn?: boolean;
  hideNoRowOverlay?: boolean;
  pagination?: boolean;
};

export const FilesTable = ({
  rowData,
  refetch,
  hideOwnerColumn,
  hideNoRowOverlay,
  pagination = true,
}: Props) => {
  const { classes } = useStyles();

  const { download, loading: downloadLoading } = useFileDownload();

  const columnDefs = useMemo<ColDef[]>(
    () => [
      { field: 'filename', headerName: 'Name', flex: 1, sortable: false },
      {
        field: 'ownerName',
        headerName: 'Owner',
        flex: 1,
        hide: hideOwnerColumn,
        sortable: false,
      },
      {
        field: 'startDate',
        headerName: 'Date',
        flex: 1,
        // sort: 'desc',
        resizable: false,
        sortable: false,
      },
      {
        colId: 'download',
        resizable: false,
        width: 50,
        sortable: false,
        suppressMovable: true,
        maxWidth: 50,
        cellStyle: { display: 'grid', placeContent: 'center' },
        cellRenderer: FileDownloadCell,
      },
      {
        colId: 'menu',
        resizable: false,
        sortable: false,
        width: 50,
        maxWidth: 50,
        pinned: 'right',
        cellStyle: { display: 'grid', placeContent: 'center' },
        cellRenderer: FileMenuCell,
      },
    ],
    [hideOwnerColumn]
  );

  return (
    <Table
      rowData={rowData}
      columnDefs={columnDefs}
      rowClass={classes.row}
      pagination={pagination}
      defaultColDef={{
        suppressMovable: true,
      }}
      context={{
        refetch,
        downloadLoading,
      }}
      rowStyle={{ cursor: 'pointer' }}
      onCellClicked={(params) => {
        if (params.column.getColId() !== 'menu') {
          download(params.data.id);
        }
      }}
      suppressCellFocus={true}
      getRowId={(params) => params.data?.id}
      enableCellChangeFlash={false}
      noRowsOverlayComponent={
        hideNoRowOverlay
          ? undefined
          : () => <NoFilesTableOverlay text='Add a new file' />
      }
    />
  );
};

const FileDownloadCell = ({ data, context }: ICellRendererParams) => {
  return (
    <IconButton
      className='file-download-icon'
      loading={context?.downloadLoading}
      disabled={!data?.id}
    >
      <FontAwesomeIcon icon={faDownload} />
    </IconButton>
  );
};

const FileMenuCell = ({ data, context }: ICellRendererParams) => {
  const fileName = data?.filename;
  const id = data?.id;
  const refetch = context.refetch;

  const { red } = useColors();
  const [opened, handlers] = useDisclosure(false);
  const { open: openAuditDrawer } = useAuditDrawer();

  const { mutate, loading } = useNotifiedV2Mutation(
    (q, args: { fileId: string }) => {
      const res = q.deleteFileStorageById({
        id: args.fileId,
      });

      return {
        ok: !!res?.id,
      };
    },
    {
      successMessage: { message: `File ${fileName} was successfully removed.` },
    }
  );

  const remove = async (fileId: string) => {
    const res = await mutate({ args: { fileId } });
    if (res.ok && refetch) refetch();
  };

  const openHistory = () => openAuditDrawer(id);

  return (
    <>
      <EllipsisMenu>
        <EllipsisMenuLabel>General</EllipsisMenuLabel>
        <EllipsisMenuItem icon={faHistory} onClick={openHistory}>
          History
        </EllipsisMenuItem>
        <EllipsisMenuLabel>Danger</EllipsisMenuLabel>
        <EllipsisMenuItem
          icon={faTrashAlt}
          disabled={loading || !id}
          onClick={handlers.open}
          sx={{ color: red.base }}
          iconColor={red.base}
        >
          Delete File
        </EllipsisMenuItem>
      </EllipsisMenu>
      <DeleteModal
        opened={opened}
        onClose={handlers.close}
        onSubmit={() => remove(id)}
        title='Are you sure to remove this file?'
        subtitle={`The file ${fileName} will be permanently removed for all users.`}
      />
    </>
  );
};

const useStyles = createStyles(() => ({
  row: {
    '.file-download-icon': {
      opacity: 0,
      transition: 'opacity 0.2s ease-out',
    },
    '&:hover .file-download-icon': {
      opacity: 1,
    },
  },
}));
