import { z } from 'zod';

import {
  GqlClientV2,
  owner_statement,
  owner_statement_line_bool_exp,
} from '@finalytic/graphql';
import { sortBy } from '@finalytic/utils';

import { formatAddress } from '../format-address';
import { getSourceDescription } from '../get-source-description';
import { Statement } from './_types';
import { ensure } from '@finalytic/utils';

export const statementExportWorkerInput = {
  statementIds: z.array(z.string()).optional(),
  ownerId: z.string().optional(),
  vendorId: z.string().optional(),
  startAt: z.string().nullable().optional(),
  endAt: z.string().nullable().optional(),
  teamId: z.string(),
  status: z.array(z.string()).optional(),
  listingId: z.string().nullable().optional(),
  groupedBy: z.enum([
    'groupByReservation',
    'groupByMonth',
    'groupByListing',
    'groupByBookingChannel',
  ]),
  dashboard: z.enum(['propertyManager', 'owner']),
};

const zodStatement = z.object(statementExportWorkerInput);

export type StatementInput = z.infer<typeof zodStatement>;

export const useStatementExportData = async (
  client: GqlClientV2,
  args: StatementInput
) => {
  const statements = await client.query((query) => {
    return query
      .ownerStatements({
        where: args.statementIds
          ? { id: { _in: args.statementIds } }
          : {
              listing:
                args.vendorId || args.listingId
                  ? {
                      ownerships: args.vendorId
                        ? {
                            settingsLeft: {
                              key: { _eq: 'vendor' },
                              value: { _eq: args.vendorId }, // this is setting.rightSource.value
                            },
                          }
                        : undefined,
                      id: args.listingId
                        ? {
                            _eq: args.listingId,
                          }
                        : undefined,
                    }
                  : undefined,
              startAt: {
                _gte: args.startAt,
                _lte: args.endAt,
              },
              owners: args.ownerId
                ? {
                    ownerId: { _eq: args.ownerId },
                  }
                : undefined,
              tenantId: {
                _eq: args.teamId,
              },
              status: args.status ? { _in: args.status as any } : undefined,
            },
        order_by: [{ startAt: 'asc' }],
      })
      .map((statement) =>
        getStatementQuery(statement, { vendorSourceId: args.vendorId })
      );
  });

  const template = sortBy(
    statements?.map((st) => st.template),
    (x) => x?.version || 0
  ).reverse()[0];

  const statementLines = statements.flatMap((statement) => statement.lines);

  return {
    statements,
    template,
    statementLines,
  };
};

export const getStatementQuery = (
  statement: owner_statement,
  filter?: {
    lines?: owner_statement_line_bool_exp;
    vendorSourceId?: string | null;
  }
) =>
  ensure<Statement>({
    id: statement.id,
    startAt: statement.startAt,
    status: statement.status,
    centBalanceEnd: statement.centBalanceEnd || 0,
    centBalanceStart: statement.centBalanceStart || 0,
    centPayedOut: statement.centPayedOut || 0,
    centTotal: statement.centTotal || 0,
    currency: statement.currency,
    template: {
      id: statement.template?.id,
      data: statement.template?.data(),
      billingAccountId: statement.template?.billingAccountId,
      balanceStartAt: statement.template?.balanceStartAt,
      version: statement.template?.version,
    },
    tenant: {
      id: statement.tenantId,
      name: statement.tenant?.name,
      companyName: statement.tenant?.companyName,
      logo: statement.tenant?.logo,
      taxId: statement?.tenant?.companyTaxCode,
      address: formatAddress({
        line1: statement.tenant.addressLine1,
        city: statement.tenant.addressCity,
        postcode: statement.tenant.addressPostcode,
        country: statement.tenant.addressCountry,
      }),
      phone: statement?.tenant?.supportPhone,
      email: statement?.tenant?.email || statement.tenant?.supportEmail,
    },
    statementOwners: statement.owners().map((ship) => {
      const ownerId = ship.owner.id;

      const ownerShipsWithVendor = statement.listing
        ?.ownerships({
          where: {
            settingsLeft: {
              key: { _eq: 'vendor' },
              value: { _eq: filter?.vendorSourceId },
            },
          },
        })
        ?.map((ship) => {
          const setting = ship.settingsLeft({
            where: { key: { _eq: 'vendor' } },
            limit: 1,
          })[0];

          return {
            label: getSourceDescription(setting?.rightSource),
            value: setting?.rightSource?.id,
            ownerId: ship.ownerId,
            listingId: statement.listingId,
          };
        });

      return {
        id: ship.id,
        split: ship.split,
        owner: {
          id: ownerId,
          email: ship.owner.email,
          firstName: ship.owner.firstName,
          lastName: ship.owner.lastName,
          companyName: ship.owner.companyName,
          vendor: ownerShipsWithVendor?.[0]?.label,
          address: formatAddress({
            line1: ship.owner.addressLine1,
            city: ship.owner.addressCity,
            postcode: ship.owner.addressPostcode,
            country: ship.owner.addressCountry,
          }),
          phone: ship.owner.phone,
        },
      };
    }),
    listing: {
      id: statement.listing?.id,
      name: statement.listing?.title || statement.listing?.name,
      address: statement.listing?.address || '',
      imageUri: statement.listing?.imageUri,
      ownerships:
        statement.listing?.ownerships().map((ship) => ({
          id: ship.id,
          owner: {
            name: ship.owner.name,
            companyName: ship.owner.companyName,
            firstName: ship.owner.firstName,
            lastName: ship.owner.lastName,
            id: ship.owner.id,
            email: ship.owner.email,
            phone: ship.owner.phone,

            addressLine1: ship.owner.addressLine1,
            addressCity: ship.owner.addressCity,
            addressPostcode: ship.owner.addressPostcode,
            addressCountry: ship.owner.addressCountry,
          },
        })) || [],
    },

    lines: statement
      .lines({
        where: filter?.lines || {
          role: { _eq: 'bill' },
        },
        order_by: [{ reservation: { checkIn: 'asc_nulls_last' } }],
      })
      .map((line) => {
        const listing = {
          id: statement?.listing?.id,
          address: statement?.listing?.address,
          imageUri: statement?.listing?.imageUri,
          name: statement?.listing?.title || statement?.listing?.name,
          ownerships: undefined,
        };

        return {
          id: line.id,
          statementId: statement.id,
          centTotal: line.centTotal || 0,
          description: line.description,
          currency: line.currency,
          date: line.date,
          isOwnerPayout: line.isOwnerPayout,
          role: line.role || undefined,
          metadata: line.metadata(),
          listing,
          group: {
            id: line.group?.id,
            remoteId: line.group?.remoteId,
            type: line.group?.type,
            description: line.group?.description,
          },
          reservation: {
            id: line.reservation?.id,
            currency: line.reservation?.currency,
            checkIn: line.reservation?.checkIn,
            checkOut: line.reservation?.checkOut,
            nights: line.reservation?.nights,
            status: line.reservation?.status,
            pmsReferenceCode: line.reservation?.pmsReferenceCode,
            confirmationCode: line.reservation?.confirmationCode,
            guestName: line.reservation?.guestName,
            guests: line.reservation?.guests,
            listing,
            bookingChannel: line.reservation?.channel?.uniqueRef,
          },
        };
      }),
  });
