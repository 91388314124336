import { FormulaEditor } from './FormulaEditor';
import { PercentageEditor } from './PercentageEditor';
import { SelectEditor } from './SelectEditor';
import { TextEditor } from './TextEditor';
import { useSingleSettingByTargetQuery } from './_hooks';
import { AdditionalMappingCellParams } from './mapping-cell-types';
import { useTabEditorType } from './useTabEditorType';
import { ICellEditorParams } from '@finalytic/ui-grid';
import { Box } from '@mantine/core';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

export const MappingEditorCell = forwardRef(
  (params: ICellEditorParams & AdditionalMappingCellParams, ref) => {
    const value: string | undefined = params?.value;
    const { automationId, settingKey, loadDataInCell } = params;

    const { rightEditorType: editorType, rightEditorParams } = useTabEditorType(
      {
        settingKey,
        automationId,
      }
    );

    const [newValue, setNewValue] = useState<string | undefined>(
      !loadDataInCell ? value || '' : undefined
    );
    const [newPercentageValue, setNewPercentageValue] = useState<number>(
      value ? parseFloat(value) : 0
    );
    const [newTextValue, setNewTextValue] = useState<string>(
      params.value || ''
    );

    const { setting } = useSingleSettingByTargetQuery({
      automationId,
      loadDataInCell,
      settingKey,
      targetId: params.data.id,
    });

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      // For dynamic columns in listing table
      if (loadDataInCell) {
        return {
          getValue() {
            switch (editorType) {
              case 'percentage': {
                const v = newPercentageValue
                  ? newPercentageValue.toFixed(2).toString()
                  : null;
                if (v !== setting?.value)
                  return { value: v, settingId: setting?.settingId };
                return {};
              }
              case 'text': {
                if (newTextValue !== setting?.value)
                  return { value: newTextValue, settingId: setting?.settingId };
                return {};
              }

              default: {
                if ((setting?.value || '') !== newValue) {
                  return { value: newValue, settingId: setting?.settingId };
                }
                return {};
              }
            }
          },
        };
      }

      // for columns in automation edit view
      return {
        // * always returns the setting.value => meaning sourceId or percentage/formular text
        getValue() {
          switch (editorType) {
            case 'percentage': {
              const v = newPercentageValue
                ? newPercentageValue.toFixed(2).toString()
                : null;
              return v;
            }

            case 'text': {
              return newTextValue;
            }

            default: {
              if (value !== newValue) {
                return newValue;
              } else {
                return params.value;
              }
            }
          }
        },
      };
    });

    // Only for selects
    useEffect(() => {
      if (editorType === 'select') {
        if (loadDataInCell) {
          if (newValue !== undefined && newValue !== setting?.value) {
            params.stopEditing();
          }
        } else {
          if (newValue !== (value || '')) {
            params.stopEditing();
            params.api.refreshCells({
              columns: ['childSettings'],
              rowNodes: [params.node],
              force: true,
            });
          }
        }
      }
    }, [newValue, editorType, loadDataInCell, setting?.value]);

    useEffect(() => {
      if (loadDataInCell) setNewValue(setting?.value as string | undefined);
    }, [setting?.value, loadDataInCell]);

    switch (editorType) {
      case 'select': {
        return (
          <Box sx={{ maxWidth: '100%', width: '100%' }}>
            <SelectEditor
              onSelectClose={params.stopEditing}
              setCellValue={setNewValue}
              value={newValue || (setting?.value as string | undefined)}
              settingKey={settingKey}
              automationId={automationId}
              options={rightEditorParams?.options}
            />
          </Box>
        );
      }

      case 'expression': {
        return (
          <FormulaEditor
            value={newValue || setting?.value || ''}
            setValue={setNewValue as any}
            onEnter={params.stopEditing}
          />
        );
      }

      case 'percentage': {
        return (
          <PercentageEditor
            value={newPercentageValue || parseFloat(setting?.value || '0')}
            setValue={setNewPercentageValue}
            stopEditing={params.stopEditing}
          />
        );
      }

      case 'text': {
        return <TextEditor value={newTextValue} setValue={setNewTextValue} />;
      }

      default:
        return null;
    }
  }
);

MappingEditorCell.displayName = 'MappingEditorCell';
