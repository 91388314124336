export const LockIcon = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.5'>
      <path
        d='M5.33341 6.66667H4.66675C3.56218 6.66667 2.66675 7.5621 2.66675 8.66667V12C2.66675 13.1046 3.56218 14 4.66675 14H11.3334C12.438 14 13.3334 13.1046 13.3334 12V8.66667C13.3334 7.5621 12.438 6.66667 11.3334 6.66667H10.6667M5.33341 6.66667V4.66667C5.33341 3.19391 6.52732 2 8.00008 2V2C9.47284 2 10.6667 3.19391 10.6667 4.66667V6.66667M5.33341 6.66667H10.6667'
        stroke='#141719'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);
