import { Notification } from '@mantine/core';

import { useMe, useOwnerPreviewId } from '../../hooks';
import { formatOwnerName } from '../../utils';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router';

export const OwnerPreviewNotification = () => {
  const { ownerPreview, resetOwnerPreview: reset } = useOwnerPreviewId();
  const { firstName, lastName, companyName } = useMe();

  const ownerPortalStatementBaseUrl = 'statements';

  const push = useNavigate();
  const matches = useMatch({
    path: `/${ownerPortalStatementBaseUrl}/*`,
  });
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const resetPreview = () => {
    setLoading(true);
    reset();
    if (matches) {
      push(location);
    }

    setLoading(false);
  };

  if (!ownerPreview?.userId) return null;

  return (
    <Notification
      radius={10}
      icon={<FontAwesomeIcon icon={faUser} />}
      color='dark'
      title='Owner Preview'
      loading={loading}
      onClose={resetPreview}
      styles={() => ({
        root: {
          position: 'absolute',
          transition: 'left 0.3s ease-out',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
        },
      })}
    >
      You are previewing as{' '}
      <b>{formatOwnerName({ firstName, lastName, companyName })}</b>.
    </Notification>
  );
};
