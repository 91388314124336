import { FormInputBaseType } from './_base-input-types';
import { SingleSelect } from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { Text } from '@mantine/core';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type SelectType = {
  options: string[];
  withSearch?: boolean;
};

type Props = SelectType & FormInputBaseType;

export const Select = ({
  options,
  label,
  defaultValue,
  formKey,
  placeholder,
  withSearch,
  required,
  onValueChange,
}: Props) => {
  const { control } = useFormContext();

  const opts = useMemo(
    () => options.map((i) => ({ label: toTitleCase(i) || i, value: i })),
    [options]
  );

  return (
    <>
      {label && (
        <Text size='sm' weight={500}>
          {label}
        </Text>
      )}
      <Controller
        control={control}
        name={formKey}
        defaultValue={defaultValue || undefined}
        rules={{ required }}
        render={({ field: { onChange, value } }) => {
          return (
            <SingleSelect
              data={opts}
              withSearch={withSearch}
              value={{ label: toTitleCase(value) || value, value }}
              setValue={(item) => {
                onValueChange?.(formKey, item.value);
                onChange(item.value);
              }}
              popoverWidth='target'
              withBorder
              placeholder={placeholder}
            />
          );
        }}
      />
    </>
  );
};
