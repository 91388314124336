import { PaymentType } from '../PaymentDetailDrawer';
import {
  InfoButtonCell,
  StringParam,
  Table,
  useQueryParamSet,
} from '@finalytic/ui';
import { ColDef, ICellRendererParams } from '@finalytic/ui-grid';
import { formatCurrency, isUUID } from '@finalytic/utils';
import { Box } from '@mantine/core';
import { utc } from 'dayjs';
import { useMemo, useState } from 'react';

type PaymentLinesTableProps = {
  rowData: PaymentType['paymentLines'];
  currency: string | undefined;
};

export const PaymentLinesTable = ({
  rowData,
  currency,
}: PaymentLinesTableProps) => {
  const setReservation = useQueryParamSet('reservation', StringParam);

  const missingReservationId = 'missingId';

  const navigateToReservationDetails = (reservationId: string) =>
    setReservation(reservationId);

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'reservationId',
      headerName: '',
      rowGroup: true,
      showRowGroup: true,
      width: 50,
      cellRenderer: 'agGroupCellRenderer',
      flex: 0,
    },
    {
      field: 'reservation.guestName',
      headerName: 'Description',
      width: 180,
      cellRenderer: ({ data, node }: ICellRendererParams) => {
        console.log(!!data?.reservationId);

        if (!data) {
          const reservation = node.allLeafChildren[0]?.data?.reservation;
          const guestName = !reservation?.checkIn
            ? 'No Reservation'
            : !reservation?.confirmationCode
            ? 'No Confirmation Code'
            : reservation?.guestName || 'No Guest Name';
          const code = reservation?.confirmationCode || '';
          const confirmationCode = code
            ? `#${isUUID(code) ? `${code.split('-')[0]}...` : code}`
            : '';
          const checkIn = reservation?.checkIn || '';
          const checkOut = reservation?.checkOut || '';

          const dates =
            checkIn && checkOut
              ? `(${utc(checkIn).format('DD MMM')} - ${utc(checkOut).format(
                  'DD MMM YYYY'
                )})`
              : '';

          return [guestName, confirmationCode, dates]
            .filter((i) => !!i)
            .join(' ');
        }
        return data?.description;
      },
    },
    // {
    //   field: 'reservation.checkIn',
    //   headerName: 'Check In',
    //   minWidth: 120,
    //   maxWidth: 120,
    //   width: 120,
    //   aggFunc: (params) => dayjs(params.values[0]).format('D MMM YYYY'),
    //   valueFormatter: ({ value }) => dayjs(value).format('D MMM YYYY'),
    // },
    // {
    //   field: 'description',
    //   minWidth: 240,
    //   flex: 1,

    //   aggFunc: (params) => {
    //     const paymentType =
    //       params.rowNode.allLeafChildren[0]?.data?.paymentDescription || '';
    //     return paymentType;
    //   },
    // },
    // {
    //   field: 'type2',
    //   headerName: 'Type',
    //   minWidth: 150,
    //   maxWidth: 150,
    //   aggFunc: (params) => {
    //     const paymentType =
    //       params.rowNode.allLeafChildren[0]?.data?.paymentType || '';
    //     return paymentType;
    //   },
    //   cellRenderer: (params: ICellRendererParams) => {
    //     if (!params.value) return null;

    //     return <WhiteTagCell>{params.value}</WhiteTagCell>;
    //   },
    // },
    {
      headerName: 'Amount',
      field: 'centTotal',
      type: 'numericColumn',
      aggFunc: 'sum',
      minWidth: 120,
      maxWidth: 120,
      valueFormatter: ({ value }) => formatCurrency(value / 100, currency),
      suppressSizeToFit: true,
    },
    {
      field: 'icon',
      sortable: false,
      width: 60,
      resizable: false,
      maxWidth: 60,
      headerName: '',
      headerTooltip: 'tool',

      cellRenderer: ({ data, node }: ICellRendererParams) => {
        const reservationGroupId =
          node.allLeafChildren?.[0]?.data?.reservationId;

        return !data && reservationGroupId !== missingReservationId ? (
          <InfoButtonCell withoutButton />
        ) : null;
      },
      suppressSizeToFit: true,
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
    };
  }, []);

  return (
    <Box sx={{ minHeight: 300 }} mb={10}>
      <Table
        animateRows
        getRowId={({ data }) => data?.id}
        groupDisplayType='custom'
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowData={rowData}
        suppressAggFuncInHeader
        pagination={false}
        getRowStyle={(params) => {
          if (!params.data) {
            return { cursor: 'pointer' };
          }
        }}
        onCellClicked={(params) => {
          const reservationGroupId =
            params?.node?.allLeafChildren?.[0]?.data?.reservationId;

          if (
            params.colDef.field !== 'reservationId' &&
            !params.data &&
            reservationGroupId !== missingReservationId
          ) {
            navigateToReservationDetails(reservationGroupId);
          }

          if (
            params.colDef.field === 'reservationId' &&
            params.node.isExpandable()
          ) {
            params.node.setExpanded(!params.node.expanded);
          }
        }}
      />
    </Box>
  );
};
