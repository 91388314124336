import {
  QueryParamConfig,
  QueryParamConfigMap,
  QueryParamConfigMapWithInherit,
  QueryParamOptions,
  useQueryParam,
  useQueryParams,
} from 'use-query-params';

export * from 'use-query-params';
export * from 'use-query-params/adapters/react-router-6';

export function useQueryParamSet<T, TT>(
  key: string,
  config: QueryParamConfig<T, TT>
) {
  const q = useQueryParam<T, TT>(key, config);
  return q[1];
}

export function useQueryParamsSet<
  T extends QueryParamConfigMapWithInherit,
  TT extends QueryParamConfigMap
>(paramConfigMap: T, options?: QueryParamOptions) {
  const q = useQueryParams<T, TT>(paramConfigMap, options);
  return q[1];
}
