import { useColors } from '@finalytic/ui';
import { Button, Sx } from '@mantine/core';
import { ReactNode } from 'react';

type FilterByCategoryButtonProps = {
  children: ReactNode;
  onClick: () => void;
  isActive: boolean;
  sx?: Sx;
};

export const FilterByCategoryButton = ({
  children,
  onClick,
  isActive,
  sx,
}: FilterByCategoryButtonProps) => {
  const { black } = useColors();
  return (
    <Button
      variant='white'
      onClick={onClick}
      pb={2}
      px={12}
      sx={{
        height: '35px',
        position: 'relative',
        color: 'initial',
        fontSize: '15px',
        textDecoration: 'none',
        flexShrink: 0,
        border: 0,
        fontWeight: isActive ? 600 : 400,
        '&:after': {
          position: 'absolute',
          content: '""',
          bottom: 0,
          left: 0,
          right: 0,
          height: 3,
          backgroundColor: isActive ? black.base : '',
          borderRadius: '5px 5px 0 0',
        },
        '.view-filter': {
          padding: '3px 6px',
          borderRadius: 10,
        },
        '&:hover .view-filter': {
          backgroundColor: !isActive ? '#EEEFF1' : '',
        },
        '&:hover .view-filter-dropdown': {
          opacity: 1,
        },
        button: {
          height: 25,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};
