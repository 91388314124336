import {
  useDashboardType,
  usePartnerTeams,
  useTeam,
  useV2TransactionSubscription,
} from '@finalytic/data-ui';
import { NavbarIssueBadge } from '@finalytic/ui';
import { useNavigate } from 'react-router';

export const ConnectionIssueBadge = () => {
  const [{ id: teamId }] = useTeam();
  const [dashboard] = useDashboardType();
  const [partners] = usePartnerTeams();

  const goto = useNavigate();

  const { data: amount } = useV2TransactionSubscription(
    (q, { partners, teamId }) => {
      const count =
        q
          .connectionAggregate({
            where: {
              tenantId:
                dashboard !== 'partner'
                  ? { _eq: teamId }
                  : { _in: partners.map((i) => i.id) },
              fetchStatus: { _eq: 'fail' },
              status: { _eq: 'enabled' },
              appId: { _neq: 'finalytic' },
            },
          })
          .aggregate?.count() || 0;

      return count;
    },
    { partners, teamId, dashboard }
  );

  return (
    <NavbarIssueBadge
      tooltipText={(label, amount) =>
        `${label} failed connection${amount > 1 ? 's' : ''}`
      }
      amount={amount || 0}
      onClick={() => goto('/connections?status=enabled&fetchStatus=fail')}
    />
  );
};
