import { httpBatchLink } from '@trpc/client';

import { PLATFORM } from '../../env';
import { useSpotlightContext } from '../spotlight';
import { useClerk } from '@finalytic/authentication';
import { CombinedServerType, RouterInput } from '@finalytic/trpc-combined';
import { useMantineTheme } from '@mantine/core';
import { QueryClient } from '@tanstack/react-query';
import { TRPCClientRuntime, createTRPCReact } from '@trpc/react-query';
import { PropsWithChildren, useMemo } from 'react';

export const trpc = createTRPCReact<CombinedServerType>();

const getWorkerUrl = (
  runtime: TRPCClientRuntime,
  {
    devEndpoint,
    prodServer,
    headers,
  }: {
    devEndpoint: string;
    prodServer: string;
    headers: () => Promise<Record<string, string>>;
  }
) =>
  httpBatchLink({
    url: import.meta.env.DEV
      ? `http://127.0.0.1:8778/${devEndpoint}`
      : `https://${prodServer}.dev7695.workers.dev`,
    headers,
  })(runtime);

export const useTrpcClient = () => {
  const auth = useClerk();

  const { primaryColor: appName } = useMantineTheme();

  const spotlight = useSpotlightContext();

  return useMemo(() => {
    const getHeaders = async () => {
      const accessToken = await auth.session?.getToken({
        template: 'Hasura',
      });

      const headers: { [s: string]: string } = {
        authorization: `Bearer ${accessToken}`,
        'bot-protection-key': '7qDfTjjUcuLoXTqMb3sEA',
        ['Finalytic-Platform']: PLATFORM || appName.toLowerCase(),
        platform: PLATFORM || appName.toLowerCase(),
      };
      if (spotlight.current.hypervisorQueue)
        headers['Finalytic-Hypervisor-Queue'] =
          spotlight.current.hypervisorQueue;
      if (spotlight.current.integrationQueue)
        headers['Finalytic-Integration-Queue'] =
          spotlight.current.integrationQueue;
      return headers;
    };

    return trpc.createClient({
      links: [
        // create a custom ending link
        (runtime) => {
          // initialize the different links for different targets
          const servers: {
            [s in keyof RouterInput]: any;
          } = {
            generateCube: getWorkerUrl(runtime, {
              devEndpoint: 'trpc-generate-cube',
              prodServer: 'trpc-generate-cube',
              headers: getHeaders,
            }),
            scheduler: getWorkerUrl(runtime, {
              devEndpoint: 'trpc-scheduler',
              prodServer: 'trpc-scheduler',
              headers: getHeaders,
            }),
            pdfExport: getWorkerUrl(runtime, {
              devEndpoint: 'trpc-pdf-export',
              prodServer: 'trpc-pdf-export',
              headers: getHeaders,
            }),
            csvExport: getWorkerUrl(runtime, {
              devEndpoint: 'trpc-csv-export',
              prodServer: 'trpc-csv-export',
              headers: getHeaders,
            }),
            stripe: getWorkerUrl(runtime, {
              devEndpoint: 'trpc-stripe',
              prodServer: 'trpc-stripe',
              headers: getHeaders,
            }),
            ecosystem: getWorkerUrl(runtime, {
              devEndpoint: 'trpc-ecosystem',
              prodServer: 'trpc-ecosystem',
              headers: getHeaders,
            }),
            actions: getWorkerUrl(runtime, {
              devEndpoint: 'trpc-actions',
              prodServer: 'trpc-actions',
              headers: getHeaders,
            }),
            svix: getWorkerUrl(runtime, {
              devEndpoint: 'trpc-svix',
              prodServer: 'trpc-svix',
              headers: getHeaders,
            }),
            files: undefined,
            notifications: undefined,
          };
          return (ctx) => {
            const { op } = ctx;
            // split the path by `.` as the first part will signify the server target name
            const pathParts = op.path.split('.');

            // first part of the query should be `server1` or `server2`
            const serverName =
              pathParts.shift() as string as keyof typeof servers;

            // combine the rest of the parts of the paths
            // -- this is what we're actually calling the target server with
            const path = pathParts.join('.');
            if (import.meta.env.DEV) {
              console.log(`> calling ${serverName} on path ${path}`, {
                input: op.input,
              });
            }

            const link = servers[serverName];

            return link({
              ...ctx,
              op: {
                ...op,
                // override the target path with the prefix removed
                path,
              },
            });
          };
        },
      ],
    });
  }, [auth, spotlight, appName]);
};

export const TrpcProvider = ({
  queryClient,
  children,
}: PropsWithChildren<{ queryClient: QueryClient }>) => {
  const trpcClient = useTrpcClient();

  return (
    // <QueryClientProvider client={queryClient}>
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      {children}
    </trpc.Provider>
    // </QueryClientProvider>
  );
};
