import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Center, ThemeIcon } from '@mantine/core';

export const DeleteRowCell = () => {
  return (
    <Center h='100%'>
      <ThemeIcon
        variant='outline'
        color='gray'
        sx={{
          border: 'none',
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </ThemeIcon>
    </Center>
  );
};
