import { TableSettingsType } from './table-settings-types';
import { AgGridReact } from '@finalytic/ui-grid';
import { RefObject } from 'react';

export const onTableSettingChange = (
  gridRef: RefObject<AgGridReact>,
  settings: TableSettingsType,
  defaultColumns: string[]
) => {
  if (!gridRef?.current) return;

  if (settings.sortBy) {
    gridRef.current?.columnApi?.applyColumnState({
      defaultState: { sort: null },
      state: [
        { colId: settings.sortBy, sort: settings.sortDirection || 'asc' },
      ],
    });
  }

  if (settings.groupBy) {
    gridRef.current?.columnApi?.applyColumnState({
      defaultState: { rowGroup: null },
      state: [
        {
          colId: settings.groupBy,
          rowGroup: settings.groupBy === '-' ? false : true,
        },
      ],
    });
  }

  if (settings.shownColumns) {
    gridRef.current?.columnApi?.applyColumnState({
      // defaultState: { hide: false },
      state: defaultColumns.map((id) => ({
        colId: id,
        hide: settings.shownColumns.includes(id) ? false : true,
        flex: 1,
      })),
    });
  }
};
