import { useColors } from '../../styles';
import { Text } from '@mantine/core';

export type TableRowProps = {
  label: string;
  formattedAmount: string;
  formattedAmountVs?: string;
  isPrimary?: boolean;
  isThemed?: boolean;
  isDraft?: boolean;
};

export const TableRow = ({
  label,
  formattedAmount,
  isPrimary,
  formattedAmountVs,
  isThemed = false,
}: TableRowProps) => {
  const { purple, red } = useColors();

  return (
    <tr style={{ color: isPrimary && !isThemed ? purple.base : undefined }}>
      <td
        style={{
          fontWeight: isPrimary ? 500 : undefined,
        }}
      >
        {label}
      </td>
      <td
        style={{
          fontWeight: isPrimary ? 600 : undefined,
          fontSize: isPrimary ? '16px' : undefined,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {formattedAmount}
        {formattedAmountVs ? (
          <Text
            sx={{
              fontSize: 12,
              color: red.base,
            }}
          >
            vs {formattedAmountVs}
          </Text>
        ) : null}
      </td>
    </tr>
  );
};
