import {
  NoListingsTableOverlay,
  SRMHeaderSelectCell,
  StatusPill,
  Table,
  TableStylingProps,
  TeamIconCell,
} from '@finalytic/ui';

import { AddOwnerModal, OwnerListingsCell } from '../../components';
import { useDashboardType, useV2Client } from '../../hooks';
import { formatOwnerName } from '../../utils';
import { ListingNameCell } from './_components';
import { ListingFilterParams, ListingTableContext } from './_types';
import {
  AgGridReact,
  ColDef,
  ICellRendererParams,
  IServerSideDatasource,
  RowSelectedEvent,
} from '@finalytic/ui-grid';

import { useAutomationColumns } from './_automations';
import { useWhereListings } from './useWhereListings';
import { StringParam, useQueryParam } from '@finalytic/ui';
import { ensure } from '@finalytic/utils';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { Avatar, Group, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { forwardRef, useMemo, useState } from 'react';

export type ListingRowSelectedHandler = (
  event: RowSelectedEvent<ListingType>
) => void;

type Props = {
  filterState?: ListingFilterParams;
  rowSelectable?: boolean;
  multiSelect?: boolean;
  onSelectAllPages?: (selected: boolean) => void;
  onRowSelected?: ListingRowSelectedHandler;
} & TableStylingProps;

export const ListingsTable = forwardRef<AgGridReact, Props>(
  (
    {
      filterState,
      rowSelectable = false,
      multiSelect = false,
      onSelectAllPages,
      onRowSelected,
      ...tableStylingProps
    },
    ref
  ) => {
    const [addOwnerOpened, addOwnerHandlers] = useDisclosure(false);
    const [addListing, setAddListing] = useState<string>();

    const closeAddOwnerModal = () => {
      addOwnerHandlers.close();
      setAddListing(undefined);
    };

    const openAddOwnerModal = (listingId: string) => {
      setAddListing(listingId);
      addOwnerHandlers.open();
    };

    const rowsPerPage = 150;
    const [dashboardType] = useDashboardType();

    const client = useV2Client();
    const where = useWhereListings(filterState || {});

    const automationColumns = useAutomationColumns();

    const [, openListingDrawer] = useQueryParam('listing', StringParam);

    const columnDefs = useMemo<ColDef[]>(
      () => [
        {
          checkboxSelection: rowSelectable,
          headerComponent: SRMHeaderSelectCell,
          headerComponentParams: { onSelectAllPages },
          field: 'name',
          headerName: 'Listing',
          sort: 'asc',
          flex: 1,
          minWidth: 250,
          cellRenderer: ListingNameCell,
        },
        {
          field: 'connections',
          width: 120,
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          headerComponent: () => (
            <Text align='center' sx={{ width: '100%' }}>
              Connections
            </Text>
          ),
          cellRenderer: (
            params: ICellRendererParams<ListingType, ListingType['connections']>
          ) => {
            const max = 4;

            const connections = params.value || [];
            const isTooMuch = connections.length > max;
            const slicedConnections = connections.slice(0, max);
            const restConnections = connections.slice(max);
            return (
              <Avatar.Group sx={{ background: 'transparent' }}>
                {slicedConnections.map((i) => (
                  <Tooltip
                    label={i?.name}
                    key={i?.id}
                    withinPortal
                    withArrow
                    position='top'
                  >
                    <Avatar
                      src={i?.iconRound}
                      size='sm'
                      sx={{
                        background: 'transparent',
                        border: 'transparent',
                      }}
                    />
                  </Tooltip>
                ))}
                {isTooMuch && (
                  <Tooltip
                    withArrow
                    withinPortal
                    radius='sm'
                    label={
                      <>
                        {restConnections.map((i) => (
                          <Group spacing='xs' key={i.id} noWrap px='xs'>
                            <Avatar
                              src={i?.iconRound}
                              size={'xs'}
                              sx={{
                                background: 'transparent',
                                border: 'transparent',
                              }}
                            />
                            <Text>{i.name}</Text>
                          </Group>
                        ))}
                      </>
                    }
                  >
                    <Avatar radius='xl' size='sm'>
                      +{connections.length - max}
                    </Avatar>
                  </Tooltip>
                )}
              </Avatar.Group>
            );
          },
        },
        {
          field: 'tenant.name',
          hide: dashboardType !== 'partner',
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          headerName: '',
          minWidth: 70,
          width: 70,
          maxWidth: 70,
          resizable: false,
          suppressMenu: true,
          cellRenderer: ({ data }: ICellRendererParams<ListingType>) => (
            <TeamIconCell
              name={data?.tenant?.name}
              logo={data?.tenant?.logo || undefined}
              colorPrimary={data?.tenant?.colorPrimary || undefined}
            />
          ),
        },
        {
          headerComponent: () => (
            <Text align='center' sx={{ width: '100%' }}>
              Automations
            </Text>
          ),
          field: 'status',
          width: 130,
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          cellRenderer: ({ value }: ICellRendererParams<ListingType>) => {
            if (value === 'Disabled')
              return <StatusPill label='Disabled' type='yellow' />;
            if (value === 'Enabled')
              return <StatusPill label='Enabled' type='green' />;
            return <StatusPill label='Partially disabled' type='yellow' />;
          },
        },
        {
          field: 'ownerships',
          headerName: 'Owners',
          minWidth: 350,
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%',
            // overflow: 'scroll',
          }),
          cellRenderer: ({
            value,
            data,
            api,
            context: tableContext,
          }: ICellRendererParams<ListingType, ListingType['ownerships']>) => {
            const context: ListingTableContext = tableContext;

            return (
              <OwnerListingsCell
                icon={faUser}
                id={data?.id}
                currentIds={[]}
                refetchTable={() => api.refreshServerSide()}
                select='owner'
                teamId={data?.tenantId}
                openAddOwnerModal={context.openAddOwnerModal}
                values={
                  value.map((i) => ({
                    id: i.id || '',
                    label: formatOwnerName({
                      firstName: i.owner.firstName,
                      lastName: i.owner.lastName,
                      companyName: i.owner.companyName,
                    }),
                  })) || []
                }
                buttonText='Add owner'
              />
            );
          },
        },
        ...automationColumns,
      ],
      [automationColumns, onSelectAllPages]
    );

    const dataSource = useMemo((): IServerSideDatasource => {
      return {
        getRows: (params) => {
          getListings(client, {
            where,
            offset: params.request.startRow || 0,
            limit: rowsPerPage,
          })
            .then((data) => {
              const rowData = data?.listings || [];

              if (data && rowData.length === 0) {
                params.api.showNoRowsOverlay();
              } else {
                params.api.hideOverlay();
              }

              params.success({
                rowData,
                rowCount: data?.listingsAggregate,
              });
            })
            .catch(() => params.fail());
        },
      };
    }, [JSON.stringify(where)]);

    return (
      <>
        <Table
          rowSelection={multiSelect ? 'multiple' : 'single'}
          ref={ref}
          serverSideDatasource={dataSource}
          serverSideInfiniteScroll
          rowModelType='serverSide'
          cacheBlockSize={rowsPerPage}
          paginationPageSize={rowsPerPage}
          columnDefs={columnDefs}
          rowHeight={58}
          getRowId={({ data }) => data?.id}
          noRowsOverlayComponent={() => <NoListingsTableOverlay />}
          rowStyle={{ cursor: 'pointer' }}
          gridOptions={{
            suppressRowClickSelection: true,
            suppressExcelExport: true,
            suppressCsvExport: true,
          }}
          context={ensure<ListingTableContext>({
            openAddOwnerModal,
          })}
          onRowSelected={onRowSelected}
          defaultColDef={{ resizable: false, sortable: false }}
          onCellClicked={(params) => {
            const colId = params.column.getColId();

            if (colId.includes('automation')) return;

            if (rowSelectable && colId === 'name') {
              params.node.setSelected(!params.node.isSelected());
              return;
            }

            openListingDrawer(params.data.id);
          }}
          {...tableStylingProps}
        />
        {/* Invite Owner Modal */}
        <AddOwnerModal
          opened={addOwnerOpened}
          onClose={closeAddOwnerModal}
          refetchOwners={() => {
            if ((ref as any)?.current?.api) {
              (ref as any).current.api.refreshServerSide({ purge: false });
            }
          }}
          ownerValues={{
            email: '',
            firstName: '',
            lastName: '',
            listingIds: addListing ? [addListing] : [],
          }}
        />
      </>
    );
  }
);

ListingsTable.displayName = 'ListingsTable';

export type ListingType = Awaited<
  ReturnType<typeof getListings>
>['listings'][0];

async function getListings(
  client: ReturnType<typeof useV2Client>,
  {
    where,
    offset,
    limit,
  }: {
    where: any;
    offset: number;
    limit: number;
  }
) {
  const { listings, listingsAggregate } = await client.query(
    (q) => {
      const listings = q
        .listings({
          where,
          limit,
          offset,
          order_by: [{ name: 'asc_nulls_first' }],
        })
        .map((listing) => {
          const disabledSetting = listing
            .settingsRight({
              where: {
                key: { _eq: 'exclude' },
                leftType: {
                  _in: ['finalytic.listing', 'disableOwnerStatement'],
                },
                rightType: { _eq: 'schema.boolean' },
                automationId: { _is_null: false },
              },
              limit: 1,
              order_by: [{ created_at: 'desc' }],
            })
            .map((setting) => setting.id)[0];

          const status =
            listing.status === 'disabled'
              ? 'Disabled'
              : disabledSetting
              ? 'Partially disabled'
              : 'Enabled';

          return {
            id: listing.id,
            name: listing.title || listing.name,
            address: listing.address,
            tenantId: listing.tenantId,
            tenant: {
              name: listing.tenant.name,
              logo: listing.tenant.logo,
              colorPrimary: listing.tenant.colorPrimary,
            },
            status,
            connections: listing
              .connections({
                order_by: [{ connection: { app: { name: 'asc' } } }],
              })
              .map((conn) => ({
                id: conn.id,
                name: conn.name,
                uniqueRef: conn.uniqueRef,
                iconRound: conn.connection.app.iconRound,
              })),
            ownerships: listing
              .ownerships({ order_by: [{ owner: { firstName: 'asc' } }] })
              .map((ownership) => ({
                id: ownership.id,
                owner: {
                  id: ownership.owner.id,
                  firstName: ownership.owner.firstName,
                  lastName: ownership.owner.lastName,
                  email: ownership.owner.email,
                  companyName: ownership.owner.companyName,
                },
              })),
          };
        });

      const listingsAggregate =
        q.listingAggregate({ where }).aggregate?.count() || 0;

      return {
        listings,
        listingsAggregate,
      };
    },
    { noCache: true }
  );

  return {
    listings,
    listingsAggregate,
  };
}
