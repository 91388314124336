import { OnboardingPrice } from '../components';
import { PricingView } from '../views';
import {
  trpc,
  useTeamId,
  useTrpcMutation,
  useTrpcQuery,
} from '@finalytic/data-ui';
import { useMemo } from 'react';

export const OnboardingPricingRoute = () => {
  const formattedProducts = useBilling();

  const { mutate, loading } = useBillingMutation();

  const onViewSubmit = async (price: OnboardingPrice) => {
    const result = await mutate(price.priceId);

    // Redirect to success/cancel page
    if (result.url) {
      window.location.href = result.url;
    }
  };

  return (
    <PricingView
      prices={formattedProducts}
      onViewSubmit={onViewSubmit}
      loading={loading}
    />
  );
};

function useBilling() {
  const [teamId] = useTeamId();
  const { data } = useTrpcQuery('stripe', 'getProducts', { teamId });

  return useMemo(() => {
    return (
      data?.products
        .filter((i) => !!i.metadata.hasuraKey)
        .sort(
          (a, b) =>
            parseInt(a.metadata?.level || '0') -
            parseInt(b.metadata?.level || '0')
        )
        .map<OnboardingPrice>((i) => {
          const features = Object.entries(i.metadata as Record<string, string>)
            .filter(([key, value]) => (key.includes('feature') ? value : null))
            .sort(([key1], [key2]) => {
              const getLevel = (k: string) =>
                parseInt(k.split('_').reverse()[0]);
              const level1 = getLevel(key1);
              const level2 = getLevel(key2);

              return level2 < level1 ? 1 : 0;
            })
            .map(([, value]) => value);

          const defaultPrice = i.default_price as any;

          return {
            icon: i.images[0],
            priceId: defaultPrice?.id,
            productId: i.id,
            title: i.name,
            unit_label: i.unit_label,
            unit_amount: defaultPrice?.tiers[0].unit_amount,
            currency: defaultPrice?.currency,
            features,
            isFeatured: parseInt(i.metadata?.level || '0') === 2,
            tiers: defaultPrice?.tiers || [],
          };
        }) || []
    );
  }, [data]);
}

function useBillingMutation() {
  const [teamId] = useTeamId();

  const { mutate, loading } = useTrpcMutation('stripe', 'createCheckout', {
    successMessage: {
      title: 'Checkout Success!',
      message: 'Successfully redirected to checkout.',
    },
  });

  return {
    mutate: (priceId: string) =>
      mutate({ priceId, teamId, redirectOrigin: window.location.origin }),
    loading,
  };
}
