import { useOwnerPortalColor } from '../../../styles';
import { IconButton } from '../../buttons';
import { Select as FinalyticSelect, SelectItem } from '../../select';
import { faCaretDown, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group, Stack, Sx, Text } from '@mantine/core';
import { ComponentProps, useMemo } from 'react';

export type StatementSelectFilterItem = SelectItem;

type Props = {
  bgColor?: string;
  clearable?: boolean;
  disabled?: boolean;
  placeholder?: string;
  searchPlaceholder?: string;
  label?: string;
  searchable?: boolean;
  sort: ComponentProps<typeof FinalyticSelect>['sort'];

  value?: string | null;
  onChange: (id: string) => void;
  data: SelectItem[];
  sx?: Sx;
};

export const StatementSelectFilter = ({
  value,
  data,
  onChange,
  bgColor,
  clearable,
  disabled,
  placeholder,
  searchable,
  searchPlaceholder,
  label,
  sort,
  sx = {},
}: Props) => {
  const bg = useOwnerPortalColor(bgColor);

  const v = useMemo(() => data.find((i) => i.value === value), [value, data]);

  const removeValue = clearable ? () => onChange('') : () => null;

  const target = (
    <Group
      noWrap
      position='apart'
      spacing={5}
      px='xs'
      sx={(theme) => ({
        backgroundColor: bg,
        color: '#fff',
        fontWeight: 500,
        borderRadius: theme.radius.md,
        width: 200,
        height: 27,
        lineHeight: '1em',
        overflow: 'hidden',
        fontSize: 13,
        '&:focus-within': {
          borderColor: theme.colors.gray[3],
        },
        ...sx,
      })}
    >
      <Text
        component='span'
        lineClamp={1}
        sx={{ opacity: !v?.label ? 0.8 : undefined }}
      >
        {v?.label || placeholder}
      </Text>
      {clearable && v?.value ? (
        <IconButton
          onClick={removeValue}
          miw={0}
          w={14}
          ref={(ref: any) => {
            if (!ref) return;

            ref.onclick = (e: any) => {
              e.stopPropagation(); // prevent row onclick
              removeValue();
            };
          }}
        >
          <FontAwesomeIcon color='white' size='sm' icon={faXmark} />
        </IconButton>
      ) : (
        <FontAwesomeIcon icon={faCaretDown} />
      )}
    </Group>
  );

  const Select = () => (
    <FinalyticSelect
      data={data}
      value={v}
      popoverWidth='target'
      withArrow={false}
      removeValue={removeValue}
      setValue={(value) => onChange(value.value)}
      withSearch={searchable}
      searchPlaceholder={searchPlaceholder}
      disabled={disabled}
      placeholder={placeholder}
      withBorder
      customTarget={target}
      offset={8}
      sort={sort}
    />
  );

  return label ? (
    <Stack spacing={2}>
      <Text size='sm' component='p' m={0} weight={500}>
        {label}
      </Text>
      {<Select />}
    </Stack>
  ) : (
    <Select />
  );
};
