import { FormInputBaseType } from './_base-input-types';
import { InputNumber } from '@finalytic/ui';
import { Input as MantineInput } from '@mantine/core';
import { Controller, useFormContext } from 'react-hook-form';

type Props = FormInputBaseType;

export const NumberInput = ({
  label,
  formKey,
  defaultValue = '',
  required = true,
  disabled,
  placeholder,
  onValueChange,
}: Props) => {
  const { control } = useFormContext();

  return (
    <MantineInput.Wrapper label={label}>
      <Controller
        control={control}
        name={formKey}
        defaultValue={defaultValue}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => (
          <InputNumber
            placeholder={placeholder || label || ''}
            value={Number(value)}
            onChange={(value) => {
              onValueChange?.(formKey, value.toString());
              onChange(value);
            }}
            inputName={formKey}
            disabled={disabled}
            sx={{ input: { borderColor: '#CED0D780' } }}
          />
        )}
      />
    </MantineInput.Wrapper>
  );
};
