import { NavbarRoute } from '../components';
import {
  trpc,
  useDashboardType,
  useTeamId,
  useTeamRole,
  useTrpcQuery,
} from '../hooks';
import {
  AnronApiTokensIcon,
  AnronBillingIcon,
  AnronGeneralIcon,
  AnronMembersIcon,
  AnronOwnerPortalIcon,
  AnronOwnerStatementsIcon,
  AnronPlansIcon,
  AnronSecurityIcon,
  AnronUserIcon,
  AnronWebhookIcon,
} from '@finalytic/ui';
import { Box, Stack, Text, useMantineTheme } from '@mantine/core';
import { ReactNode, useEffect, useState } from 'react';

function useShowBilling() {
  const [teamId] = useTeamId();
  const { data, loading } = useTrpcQuery('stripe', 'getCustomer', { teamId });
  const { primaryColor: appName } = useMantineTheme();

  return {
    showBilling: appName === 'VRPlatform' && !!data?.customer?.id,
    loading,
  };
}

export const SettingsNavigation = ({
  navbarIsExpanded,
}: {
  navbarIsExpanded: boolean;
}) => {
  const { primaryColor: appName } = useMantineTheme();
  const [dashboard] = useDashboardType();
  const { isSuperAdmin } = useTeamRole();

  const { showBilling } = useShowBilling();

  return (
    <Stack spacing='xl'>
      <Section title='User Account'>
        <NavbarRoute
          title='Profile'
          link='/settings/profile'
          icon={<AnronUserIcon />}
          navbarIsExpanded={navbarIsExpanded}
          v2Icon
        />

        <NavbarRoute
          title='Security'
          link='/settings/security'
          icon={<AnronSecurityIcon />}
          navbarIsExpanded={navbarIsExpanded}
          v2Icon
        />
        {/* <NavbarRoute
          title="Notifications"
          link="/settings/notifications"
          icon={<AnronNotificationIcon />}
          navbarIsExpanded={navbarIsExpanded}
          v2Icon
        /> */}
      </Section>

      {/* {dashboard === 'partner' && (
        <Section title='Partner'>
          <NavbarRoute
            title='Clients'
            link='/settings/partner/clients'
            icon={<AnronMembersIcon />}
            navbarIsExpanded={navbarIsExpanded}
            v2Icon
          />
        </Section>
      )} */}

      {dashboard !== 'owner' && (
        <>
          <Section title='Team'>
            <NavbarRoute
              title='General'
              link='/settings/team/general'
              icon={<AnronGeneralIcon />}
              navbarIsExpanded={navbarIsExpanded}
              v2Icon
            />

            <NavbarRoute
              title='Members'
              link='/settings/team/members'
              icon={<AnronMembersIcon />}
              navbarIsExpanded={navbarIsExpanded}
              v2Icon
            />
            {showBilling && isSuperAdmin && (
              <>
                <NavbarRoute
                  title='Billing'
                  link='/settings/team/billing'
                  icon={<AnronBillingIcon />}
                  navbarIsExpanded={navbarIsExpanded}
                  v2Icon
                />
                <NavbarRoute
                  title='Plans'
                  link='/settings/team/plans'
                  icon={<AnronPlansIcon />}
                  navbarIsExpanded={navbarIsExpanded}
                  v2Icon
                />
              </>
            )}
          </Section>

          <Section title='Integrations'>
            <NavbarRoute
              title='Api tokens'
              link='/settings/integrations/api-tokens'
              icon={<AnronApiTokensIcon />}
              navbarIsExpanded={navbarIsExpanded}
              v2Icon
            />

            {appName.toLowerCase() !== 'vrplatform' && (
              <NavbarRoute
                title='Webhooks'
                link='/settings/integrations/webhooks'
                icon={<AnronWebhookIcon />}
                navbarIsExpanded={navbarIsExpanded}
                v2Icon
              />
            )}
          </Section>

          <Section title='Features'>
            <NavbarRoute
              title='Owner Portal'
              link='/settings/features/owner-portal'
              icon={<AnronOwnerPortalIcon />}
              navbarIsExpanded={navbarIsExpanded}
              v2Icon
            />

            {isSuperAdmin && (
              <NavbarRoute
                title='Owner Statements'
                link='/settings/features/owner-statements'
                icon={<AnronOwnerStatementsIcon />}
                navbarIsExpanded={navbarIsExpanded}
                v2Icon
              />
            )}
          </Section>
        </>
      )}
    </Stack>
  );
};

const Section = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => (
  <Box>
    <Text
      component='span'
      mb='xs'
      color='white'
      pl={5}
      sx={{ opacity: 0.5, display: 'block' }}
    >
      {title}
    </Text>
    <Stack spacing='xs'>{children}</Stack>
  </Box>
);
