import { OnboardingIntegration } from '../components';
import { MissingIntegrationModal } from '../components/MissingIntegrationModal';
import { ConnectView } from '../views';
import { AddConnectionModal, IntegrationType } from '@finalytic/connections';
import {
  gqlV2,
  useApi,
  useNotifiedV2Mutation,
  useTeam,
  useV2TransactionQuery,
} from '@finalytic/data-ui';
import { useMantineTheme } from '@mantine/core';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

export const OnboardingConnectRoute = ({
  type,
}: {
  type: 'Accounting' | 'PMS';
}) => {
  const [search, setSearch] = useState('');
  const [
    { id: teamId, propertyManagementSystems, accountingPlatforms },
    refetchTeam,
  ] = useTeam();
  const { primaryColor: appName } = useMantineTheme();
  const [debouncedSearch] = useDebouncedValue(search, 400);
  const location = useLocation();

  const goto = useNavigate();

  const [app, setApp] = useState<IntegrationType>();

  const [opened, handlers] = useDisclosure(false);

  const { mutate: updateTeam } = useNotifiedV2Mutation(
    (q, args: { teamId: string }) => {
      const res = q.updateTenantById({
        pk_columns: { id: args.teamId },
        _set: { status: 'active' },
      });

      return {
        ok: !!res?.id,
      };
    },
    {
      successMessage: {
        title: "You're all set up!",
        message: `You are connected and ready to explore ${appName}!`,
      },
    }
  );

  const finishOnboarding = async () => {
    const res = await updateTeam({ args: { teamId } });

    goto('/');
    if (res?.ok) refetchTeam();
  };

  const { data, isLoading: loadingIntegrations } = useIntegrations({
    querySearch: debouncedSearch.trim().toLowerCase(),
    type,
  });

  const connect = (integration: IntegrationType) => setApp(integration);

  const submitMissingIntegration = async (integrationWebsite: string) => {
    try {
      class IntegrationRequestError extends Error {
        constructor(readonly website: string) {
          super(`Integration missing: ${website}`);
          this.name = 'IntegrationRequestError';
        }
      }

      Sentry.captureException(new IntegrationRequestError(integrationWebsite), {
        level: 'info',
        tags: {
          integrationWebsite,
          sendIntegrationNotification: 'true',
          type,
        },
      });

      handlers.close();
      return { ok: true };
    } catch (error: any) {
      return { ok: false, errorMessage: error?.message };
    }
  };

  useEffect(() => {
    // TODO: remove when enable pricing
    if ((accountingPlatforms || []).length > 0) finishOnboarding();
  }, [accountingPlatforms]);

  if (propertyManagementSystems.length > 0 && type === 'PMS')
    return <Navigate to={`/connect-accounting${location.search}`} />;

  if (type === 'Accounting') {
    if (propertyManagementSystems.length < 1)
      return <Navigate to={`/connect-pms${location.search}`} />;
    // TODO: enable when enable pricing
    // else if (accountingPlatforms.length > 0)
    //   return <Navigate to={`/pricing${location.search}`} />;
  }

  const Connect =
    type === 'Accounting' ? (
      <ConnectView
        integrations={data?.integrations || []}
        onIntegrationSubmit={connect}
        onSearch={(s) => setSearch(s)}
        type='Accounting'
        onMissingIntegrationSubmit={handlers.open}
        handleSkipOnboarding={finishOnboarding}
        loading={loadingIntegrations}
      />
    ) : (
      <ConnectView
        integrations={data?.integrations || []}
        onIntegrationSubmit={connect}
        onSearch={(s) => setSearch(s)}
        type='PMS'
        onMissingIntegrationSubmit={handlers.open}
        loading={loadingIntegrations}
      />
    );

  return (
    <>
      {/* ConnectView */}
      {Connect}

      {/* Add connection Modal */}
      <ConnectionModal
        integrations={data?.integrations || []}
        activeIntegration={app}
        removeActiveIntegration={() => setApp(undefined)}
      />

      {/* SubmitMissingIntegration */}
      <MissingIntegrationModal
        opened={opened}
        close={handlers.close}
        type={type}
        onSubmit={submitMissingIntegration}
      />
    </>
  );
};

const ConnectionModal = ({
  integrations,
  activeIntegration,
  removeActiveIntegration,
}: {
  integrations: IntegrationType[];
  activeIntegration: IntegrationType | undefined;
  removeActiveIntegration: () => void;
}) => {
  const [_, refetchTeam] = useTeam();
  const [opened, handlers] = useDisclosure(false);

  const closeModal = () => {
    handlers.close();
    setTimeout(() => {
      removeActiveIntegration();
    }, 500);
  };

  useEffect(() => {
    if (activeIntegration) handlers.open();
  }, [activeIntegration]);

  return (
    <AddConnectionModal
      integrations={integrations}
      opened={opened}
      close={closeModal}
      open={handlers.open}
      onSuccess={refetchTeam}
      presetApp={activeIntegration}
    />
  );
};

const useIntegrations = ({
  type,
  querySearch,
}: {
  querySearch: string;
  type: 'PMS' | 'Accounting';
}) => {
  return useV2TransactionQuery(
    (q, args) => {
      const getIntegration = (app: gqlV2.app) => ({
        id: app.id!,
        name: app.name!,
        type: app.type,
        icon: app.iconRound!,
        iconRound: app.iconRound!,
        authentication: app.authentication(),
      });

      if (args.type === 'Accounting') {
        const integrations = q
          .app({
            where: {
              name: args.querySearch
                ? { _ilike: `%${querySearch}%` }
                : undefined,
              id: {
                _in: ['quickbooks', 'xero', 'intacct'],
              },
            },
            order_by: [
              {
                name: 'asc',
              },
            ],
          })
          .map<OnboardingIntegration>((app) => getIntegration(app));

        return {
          integrations,
        };
      }

      const integrations = q
        .app({
          where: {
            name: args.querySearch ? { _ilike: `%${querySearch}%` } : undefined,
            id: {
              _in: [
                'hostaway',
                'guesty',
                'hostfully',
                'bookingAutomation',
                // 'hospitable',
                'trackhs',
              ],
            },
            // _or: [
            //   {
            //     type: {
            //       _eq: 'pms',
            //     },
            //   },
            //   {
            //     category: {
            //       _eq: 'propertyManagementSystem',
            //     },
            //   },
            // ],
          },
          order_by: [
            {
              name: 'asc',
            },
          ],
        })
        .map<OnboardingIntegration>((app) => getIntegration(app));

      return {
        integrations,
      };
    },
    {
      variables: {
        querySearch,
        type,
      },
    }
  );
};
