import { useNotifiedV2Mutation } from '../../../..';
import { useListingWasUpdated } from '../useListingWasUpdated';
import { Maybe } from '@finalytic/graphql';
import {
  ActionButton,
  BooleanParam,
  IconButton,
  Input,
  useColors,
  useQueryParam,
} from '@finalytic/ui';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Group,
  Input as MantineInput,
  Stack,
  Sx,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { ReactNode } from 'react';
import { Controller, useForm } from 'react-hook-form';

type FormInputs = {
  name: Maybe<string | undefined>;
  title: Maybe<string | undefined>;
  address: Maybe<string | undefined>;
};

type EditListingFormProps = {
  listingId: string;
  disableAddressInput: boolean;
  cancelEdit: () => void;
};

function useOwnerMutations() {
  const { mutate } = useNotifiedV2Mutation(
    (q, args: { listingId: string } & FormInputs) => {
      const listing = q.updateListing({
        pk_columns: { id: args.listingId },
        _set: {
          address: args.address,
          title: args.title,
          name: args.name,
        },
      });

      return {
        ok: !!listing?.id,
      };
    },
    {
      successMessage: { message: 'Successfully updated listing.' },
      invalidateQueryKeys: ['listings'],
    }
  );

  return {
    update: ({ listingId, input }: { listingId: string; input: FormInputs }) =>
      mutate({ args: { listingId, ...input } }),
  };
}

export const EditListingForm = ({
  cancelEdit,
  address,
  listingId,
  name,
  title,
  disableAddressInput,
}: FormInputs & EditListingFormProps) => {
  const { primaryColor: appName } = useMantineTheme();
  const { update } = useOwnerMutations();
  const { setListingEdited } = useListingWasUpdated();

  const {
    control,
    formState: { isDirty, isSubmitting, isValid },
    handleSubmit,
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      address,
      name,
      title,
    },
  });

  const submit = async (input: FormInputs) => {
    const res = await update({
      listingId,
      input,
    });
    if (res?.ok) {
      setListingEdited(true);
      cancelEdit();
    }
  };

  // const requireCompany = !firstNameState && !lastNameState;
  // const requireFirstName = !companyNameState && !lastNameState;
  // const requireLastName = !companyNameState && !firstNameState;

  return (
    <>
      <Group position='apart' my='lg'>
        <Group noWrap spacing={5} pt={5}>
          <IconButton onClick={cancelEdit}>
            <FontAwesomeIcon icon={faChevronLeft} color='#000' />
          </IconButton>
          <Text size='lg' weight={500} color='#000'>
            Overview
          </Text>
        </Group>
        <ActionButton
          onClick={handleSubmit(submit)}
          loading={isSubmitting}
          disabled={!isDirty || !isValid || !listingId}
        >
          Save
        </ActionButton>
      </Group>

      <Stack px={10}>
        <Controller
          control={control}
          name='name'
          rules={{ required: true }}
          render={({ field: { name, value, onChange } }) => (
            <InputWrapper name={name} label='Name'>
              <Input
                inputName={name}
                value={value || ''}
                onChange={onChange}
                disabled={true}
                placeholderText='Listing Name'
                type='text'
              />
            </InputWrapper>
          )}
        />

        <Controller
          control={control}
          name='title'
          render={({ field: { name, value, onChange } }) => (
            <InputWrapper name={name} label='Title'>
              <Input
                inputName={name}
                value={value || ''}
                onChange={onChange}
                placeholderText='Enter a title...'
                type='text'
              />
              <InputDescription>
                Use title to overwrite your listing name inside {appName}.
              </InputDescription>
            </InputWrapper>
          )}
        />

        <Controller
          control={control}
          name='address'
          render={({ field: { name, value, onChange } }) => (
            <InputWrapper name={name} label='Address'>
              <Tooltip
                disabled={!disableAddressInput}
                label='The address is synced by the PMS.'
                withArrow
              >
                <Box>
                  <Input
                    inputName={name}
                    value={value || ''}
                    onChange={onChange}
                    placeholderText='Enter an address...'
                    type='text'
                    disabled={disableAddressInput}
                  />
                </Box>
              </Tooltip>

              {disableAddressInput && (
                <InputDescription>
                  * This address is synced by the PMS.
                </InputDescription>
              )}
            </InputWrapper>
          )}
        />
      </Stack>
    </>
  );
};

const InputDescription = ({ children }: { children: ReactNode }) => {
  const { gray } = useColors();

  return (
    <Text pt={4} size='xs' m={0} component='p' color={gray.dark}>
      {children}
    </Text>
  );
};

const InputWrapper = ({
  name,
  label,
  children,
  sx,
  withAsterisk = false,
}: {
  children: ReactNode;
  label: string;
  name: string;
  sx?: Sx;
  withAsterisk?: boolean;
}) => {
  const { gray } = useColors();

  return (
    <MantineInput.Wrapper
      id={name}
      label={label}
      withAsterisk={withAsterisk}
      sx={{ flex: 1, ...sx }}
      styles={{
        label: {
          color: gray.dark,
          fontWeight: 500,
          fontSize: 13,
          marginBottom: 3,
        },
      }}
    >
      {children}
    </MantineInput.Wrapper>
  );
};
