import { Text, TextProps } from '@mantine/core';
import { ReactNode } from 'react';

export const TableTitle = ({
  children,
  ...props
}: { children: ReactNode } & TextProps) => (
  <Text weight={'bold'} size='lg' mb={0} px={5} {...props}>
    {children}
  </Text>
);
