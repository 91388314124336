import { StatementFilterParams } from '../../types/statement-types';
import { gqlV2, useTeamId, useV2TransactionQuery } from '@finalytic/data-ui';
import {
  FilterWrapper,
  LoadingIndicator,
  StatementSelectFilter,
  StringParam,
  SwitchFilter,
  SwitchFilterDataType,
  useQueryParam,
} from '@finalytic/ui';
import { ensure, sortBy, toTitleCase, utc } from '@finalytic/utils';
import { useEffect, useMemo } from 'react';

type Props = {
  filter: StatementFilterParams;
  setFilter: (val: StatementFilterParams) => void;

  statementsLoading: boolean;
};

const useStatementListDate = () => {
  const [_date, setDate] = useQueryParam('date', StringParam);

  return {
    _date,
    setDate,
  };
};

function useStatementFilterData() {
  const [teamId] = useTeamId();

  const {
    data,
    isLoading: loading,
    refetch,
  } = useV2TransactionQuery(
    (q, args) => {
      const ownerStatements = q
        .ownerStatements({
          where: args.where,
          distinct_on: ['ownerId', 'status'],
        })
        .map((statement) => ({
          id: statement.id,
          status: statement.status || '',
          startAt: statement.startAt,
          ownerId: statement.ownerId,
          owner: {
            id: statement?.owner?.id,
            name: statement?.owner?.name,
            firstName: statement?.owner?.firstName,
            lastName: statement?.owner?.lastName,
          },
        }));

      const months = q
        .ownerStatements({
          where: args.where,
          distinct_on: ['startAt'],
          order_by: [{ startAt: 'desc' }],
        })
        .map((statement) => ({
          id: statement.id,
          startAt: statement.startAt || '',
        }));

      return {
        ownerStatements,
        months,
      };
    },
    {
      queryKey: ['ownerStatements'],
      variables: {
        where: ensure<gqlV2.owner_statement_bool_exp>({
          tenantId: { _eq: teamId },
        }),
      },
    }
  );

  const statementStatusTypes: SwitchFilterDataType =
    [...new Set(data?.ownerStatements.map((i) => i.status))]?.map((i) => ({
      label: toTitleCase(i) || '',
      value: i,
    })) || [];

  const availableMonths = useMemo<SwitchFilterDataType>(() => {
    const sortByMonth = sortBy(data?.months || [], (x) => x.startAt);

    return sortByMonth
      .map((i: { id: string; startAt: string }) => ({
        label: utc(i.startAt).format('MMM YYYY'),
        value: utc(i.startAt).format('YYYY-MM-DD'),
        group: utc(i.startAt).format('YYYY '),
      }))
      .reverse();
  }, [data?.months]);

  const statementOwnerTypes = useMemo<SwitchFilterDataType>(
    () =>
      (
        data?.ownerStatements.map((i) => ({
          label: `${i.owner?.firstName} ${i.owner?.lastName || i.owner?.name}`,
          value: i.ownerId,
        })) || []
      )
        .filter((i) => !!i.value)
        .sort((a, b) => (b.label > a.label ? -1 : 1)),
    [data?.ownerStatements]
  );

  return {
    statementStatusTypes,
    statementOwnerTypes,
    availableMonths,
    loading,
    refetch,
  };
}

export const StatementListFilter = ({
  filter: filterState,
  setFilter: setFilterState,
  statementsLoading,
}: Props) => {
  const { statementStatusTypes, statementOwnerTypes, availableMonths } =
    useStatementFilterData();

  const { _date, setDate } = useStatementListDate();

  const date = useMemo(() => {
    if (_date) return _date;
    const lastMonth = availableMonths?.find(
      (i) => i.value === utc().subtract(1, 'month').format('YYYY-MM-01')
    );
    return lastMonth?.value || availableMonths[0]?.value;
  }, [_date, availableMonths]);

  useEffect(() => {
    setFilterState({ startAt: date });
  }, [date]);

  const showOwnerSearch = useMemo(
    () => statementOwnerTypes.length > 10,
    [statementOwnerTypes]
  );

  return (
    <FilterWrapper my={0}>
      {availableMonths?.length > 0 && (
        <StatementSelectFilter
          sort={false}
          value={filterState.startAt || availableMonths[0]?.value}
          data={availableMonths}
          onChange={setDate}
        />
      )}
      {statementStatusTypes.length > 0 && (
        <SwitchFilter
          data={statementStatusTypes}
          defaulButtonLabel='Status'
          withSearch={showOwnerSearch}
          filterValue={filterState.status}
          setFilterValue={(value?: string) =>
            setFilterState({ status: value as any })
          }
        />
      )}

      {statementOwnerTypes.length > 0 && (
        <SwitchFilter
          data={statementOwnerTypes}
          defaulButtonLabel='Owner'
          withSearch={false}
          filterValue={filterState.ownerId}
          setFilterValue={(value?: string) =>
            setFilterState({ ownerId: value })
          }
        />
      )}
      {statementsLoading && <LoadingIndicator size='xs' />}
    </FilterWrapper>
  );
};
