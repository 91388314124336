export const VrpDarkLogo = ({ size = 38 }: { size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 38 37'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24.623 10.6557C24.623 7.62843 27.1453 5.17432 30.2567 5.17432C33.3681 5.17432 35.8904 7.62843 35.8904 10.6557C35.8904 13.683 33.3681 16.1372 30.2567 16.1372C27.1453 16.1372 24.623 13.683 24.623 10.6557Z'
      fill='white'
    />
    <path
      d='M9.7832 10.9305C9.7832 7.78398 12.6694 5.18604 15.9033 5.18604C19.1372 5.18604 20.3961 7.24292 22.3854 9.72362C23.2239 10.7692 27.9763 17.1987 27.9763 17.1987C27.9763 17.1987 28.9989 18.928 28.9989 20.3464C28.9989 23.8683 23.2286 29.4089 23.2286 29.4089L10.6342 13.777C10.6342 13.777 9.7832 12.9674 9.7832 10.9305Z'
      fill='white'
      fillOpacity='0.8'
    />
    <path
      d='M2.14893 10.8807C2.14893 7.73417 5.03514 5.13623 8.26903 5.13623C11.5029 5.13623 12.7618 7.19312 14.7511 9.67385C15.5896 10.7194 19.7993 16.1031 22.7336 20.0681C24.7605 22.8071 24.9131 22.8097 24.9131 26.2025C24.9131 29.5953 20.5328 31.0805 19.1969 31.1493C16.3849 31.2941 14.4312 28.9336 14.4312 28.9336L2.99992 13.7272C2.99992 13.7272 2.14893 12.9176 2.14893 10.8807Z'
      fill='white'
    />
  </svg>
);
