import { FormInputBaseType } from './_base-input-types';
import { InputNumber } from '@finalytic/ui';
import { Input as MantineInput } from '@mantine/core';
import { Controller, useFormContext } from 'react-hook-form';

type CurrencyInputType = {
  currency?: string;
};

type Props = CurrencyInputType & FormInputBaseType;

export const CurrencyInput = ({
  label,
  formKey,
  defaultValue = '',
  required = true,
  disabled,
  placeholder,
  currency,
  onValueChange,
}: Props) => {
  const { control } = useFormContext();

  // TODO: Fix possible currencies
  return (
    <MantineInput.Wrapper label={label}>
      <Controller
        control={control}
        name={formKey}
        defaultValue={defaultValue}
        rules={{ required: required }}
        render={({ field: { onChange, value } }) => (
          <InputNumber
            placeholder={placeholder || label || ''}
            value={value}
            onChange={(value) => {
              onValueChange?.(formKey, value.toString());
              onChange(value);
            }}
            inputName={formKey}
            disabled={disabled}
            sx={{ input: { borderColor: '#CED0D780' } }}
            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            formatter={(value) =>
              !Number.isNaN(parseFloat(value || ''))
                ? `$ ${value
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    .replace(/[^(0-9|,|\.|$)]/g, '')}`
                : ''
            }
          />
        )}
      />
    </MantineInput.Wrapper>
  );
};
