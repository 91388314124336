import {
  useStatementGenerateMutation,
  useStatementPdfV2Mutation,
} from '../../hooks';
import { getStatementYearlySummaryHref } from '../../utils';
import { useStatementRunDrawer } from '../buttons';
import { StatementInput } from '@finalytic/common';
import {
  useAuditDrawer,
  useDashboardType,
  useNotifiedV2Mutation,
  useOwnerPreviewId,
  useTeamId,
  useTeamRole,
  useTrpcMutation,
} from '@finalytic/data-ui';
import {
  DeleteModal,
  EllipsisMenu,
  EllipsisMenuItem,
  EllipsisMenuLabel,
  StringParam,
  downloadFromUrl,
  showErrorNotification,
  useColors,
  useQueryParam,
} from '@finalytic/ui';
import { Maybe, utc } from '@finalytic/utils';
import {
  faBallotCheck,
  faCopy,
  faFileCsv,
  faHistory,
  faHouse,
  faRefresh,
  faTrashAlt,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { useClipboard, useDisclosure } from '@mantine/hooks';
import { Link, useLocation, useNavigate } from 'react-router-dom';

type Props = {
  disabled?: boolean;
  statementId: string | undefined;
  statementTemplateId: string | undefined;
  date: string | undefined;
  ownerId: string | undefined;
  listingId: string | undefined;
  status: Maybe<string>;
  onStatementDeleted: () => void;
  onStatementGenerated: () => void;
};

function useStatementDeleteMutation() {
  const {
    error,
    loading,
    mutate: remove,
  } = useNotifiedV2Mutation(
    (q, args: { statementId: string }) => {
      const res = q.deleteOwnerStatement({ id: args.statementId });

      return {
        ok: !!res?.id,
        data: {
          id: res?.id,
        },
      };
    },
    {
      successMessage: {
        message: 'Statement was successfully removed.',
      },
    }
  );

  return { remove, loading, error };
}

export const StatementEllipsisMenu = ({
  disabled,
  statementId,
  date: d,
  listingId,
  ownerId,
  status,
  onStatementDeleted,
  statementTemplateId,
  onStatementGenerated,
}: Props) => {
  const [teamId] = useTeamId();
  const { isPartnerAdmin, isSuperAdmin } = useTeamRole();
  const goto = useNavigate();
  const [dashboard] = useDashboardType();
  const { open: openAuditDrawer } = useAuditDrawer();
  const { automation, isLegacyAutomation } = useStatementRunDrawer();
  const { generate } = useStatementGenerateMutation(automation?.automationId);
  const { setOwnerPreview } = useOwnerPreviewId();
  const { red } = useColors();
  const { copy } = useClipboard();
  const [queryDate] = useQueryParam('date', StringParam);
  const location = useLocation();

  const date =
    d || queryDate ? utc(d || queryDate).format('YYYY-MM-01') : undefined;

  const [opened, handlers] = useDisclosure(false);

  const { remove } = useStatementDeleteMutation();
  const { mutate: exportCsv } = useTrpcMutation('csvExport', 'statements', {
    successMessage: {
      message: 'Statement Csv was successfully exported.',
    },
  });
  const { downloadPdf } = useStatementPdfV2Mutation();

  const setPreview = () => {
    if (!ownerId)
      return showErrorNotification({
        title: 'Owner Preview',
        message: 'This statement is missing an owner.',
        color: 'yellow',
        icon: null,
      });

    setOwnerPreview({
      userId: ownerId,
    });

    const search = new URLSearchParams(location.search);
    if (date) search.set('date', date);

    goto(`/statements/${listingId}?${search.toString()}`);
  };

  const openHistory = () => openAuditDrawer(statementId);

  const allowDeleteAndGenerate = status === 'draft' && statementId;

  const removeStatement = async (id: string) => {
    const res = await remove({ args: { statementId: id } });

    if (res?.ok) onStatementDeleted();
  };

  const exportArgs: StatementInput = {
    statementIds: statementId ? [statementId] : undefined,
    groupedBy: 'groupByReservation',
    ownerId: ownerId,
    teamId,
    dashboard: dashboard === 'owner' ? 'owner' : 'propertyManager',
  };

  const exportStatementCsv = async () => {
    const res = await exportCsv(exportArgs);

    if (res.data?.url) {
      downloadFromUrl(res.data.url, 'statement_csv');
    }
  };

  const exportStatementPdf = async () => downloadPdf(exportArgs);

  const generateStatement = () => {
    if (!automation?.automationId)
      return showErrorNotification({ message: 'Missing automation id.' });

    if (!date)
      return showErrorNotification({ message: 'Missing statement date.' });

    generate({
      date: date!,
      legacy: isLegacyAutomation,
      leftConnectionId: automation.leftConnectionId,
      listingIds: listingId ? [listingId] : [],
      refreshAfter: onStatementGenerated,
      // input: [listingId],
      // params: {
      //   month: date, // '2022-01-01',
      //   listingId: [listingId],
    });
  };

  const ExportSection = (
    <>
      <EllipsisMenuLabel>Export</EllipsisMenuLabel>
      <EllipsisMenuItem
        icon={faFileCsv}
        disabled={!statementId}
        onClick={exportStatementPdf}
      >
        Export PDF
      </EllipsisMenuItem>
      <EllipsisMenuItem
        icon={faFileCsv}
        disabled={!statementId}
        onClick={exportStatementCsv}
      >
        Export CSV
      </EllipsisMenuItem>
    </>
  );

  if (dashboard === 'owner') {
    return (
      <EllipsisMenu disabled={disabled} width={220}>
        {ExportSection}
      </EllipsisMenu>
    );
  }

  return (
    <>
      <EllipsisMenu disabled={disabled} width={220}>
        <EllipsisMenuLabel>General</EllipsisMenuLabel>

        {date && allowDeleteAndGenerate && (
          <EllipsisMenuItem icon={faRefresh} onClick={generateStatement}>
            Regenerate Statement
          </EllipsisMenuItem>
        )}
        <EllipsisMenuItem icon={faHistory} onClick={openHistory}>
          Statement History
        </EllipsisMenuItem>
        <EllipsisMenuItem icon={faBallotCheck} onClick={setPreview}>
          Owner Preview
        </EllipsisMenuItem>

        {/* Export */}
        {ExportSection}

        {isPartnerAdmin && (
          <>
            <EllipsisMenuLabel>Summary</EllipsisMenuLabel>

            <EllipsisMenuItem
              icon={faHouse}
              component={Link}
              disabled={!ownerId}
              to={getStatementYearlySummaryHref({
                date,
                ownerId: ownerId!,
                listingId,
              })}
            >
              By Listing
            </EllipsisMenuItem>
            <EllipsisMenuItem
              icon={faUser}
              component={Link}
              disabled={!ownerId}
              to={getStatementYearlySummaryHref({
                date,
                ownerId: ownerId!,
              })}
            >
              By Owner
            </EllipsisMenuItem>
          </>
        )}

        {isSuperAdmin && (
          <>
            <EllipsisMenuLabel>Super Admin</EllipsisMenuLabel>

            <EllipsisMenuItem icon={faCopy} onClick={() => copy(statementId)}>
              Copy Statement Id
            </EllipsisMenuItem>
            <EllipsisMenuItem
              icon={faCopy}
              onClick={() => copy(statementTemplateId)}
            >
              Copy Template Id
            </EllipsisMenuItem>
          </>
        )}

        {allowDeleteAndGenerate && (
          <>
            <EllipsisMenuLabel>Danger</EllipsisMenuLabel>
            <EllipsisMenuItem
              disabled={!allowDeleteAndGenerate}
              icon={faTrashAlt}
              sx={{ color: red.base }}
              iconColor={red.base}
              onClick={handlers.open}
            >
              Delete Statement
            </EllipsisMenuItem>
          </>
        )}
      </EllipsisMenu>
      <DeleteModal
        opened={opened}
        onClose={handlers.close}
        title='Are you sure to remove this statement?'
        subtitle={
          'The statement will be removed and can be newly generated using the generate modal.'
        }
        onSubmit={() => removeStatement(statementId!)}
      />
    </>
  );
};
