import { Badge, BadgeProps } from '@mantine/core';

type Props = {
  numberOfChanges: number;
};

export const ChangeBadge = ({
  numberOfChanges,
  ...badgeProps
}: Props & BadgeProps) => (
  <Badge
    color={numberOfChanges > 0 ? undefined : 'gray'}
    size='sm'
    {...badgeProps}
  >
    Changes: {numberOfChanges}
  </Badge>
);
