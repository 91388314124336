import { useColors } from '@finalytic/ui';
import { Progress } from '@mantine/core';
import { useEffect, useState } from 'react';

export const ConnectionProgressBar = () => {
  const { purple } = useColors();

  const [progressValue, setProgressValue] = useState(2);

  useEffect(() => {
    setTimeout(() => {
      if (progressValue < 100) {
        setProgressValue((n) => (n % 2 ? n + 5 : n + 15));
      }
    }, 2000);
  }, [progressValue]);

  return (
    <Progress
      // color="#333"
      // size={50}
      radius={20}
      striped={false}
      sections={[
        {
          value: progressValue,
          color: purple.base,
        },
      ]}
    />
  );
};
