import { OnboardingIntegration } from './_types';
import { Button } from '@finalytic/ui';
import { Avatar, SimpleGrid, SimpleGridProps, Skeleton } from '@mantine/core';

type SetIntegrationHandler = (
  integration: OnboardingIntegration | undefined
) => void;

type Props = {
  integrations: OnboardingIntegration[];
  notListedTitle: string | undefined;
  setIntegration: SetIntegrationHandler;
  handleIntegrationSubmit: (integration: OnboardingIntegration) => void;
  handleMissingIntegration: () => void;
  loading: boolean;
};

export const IntegrationList = ({
  integrations,
  notListedTitle,
  setIntegration,
  handleIntegrationSubmit,
  handleMissingIntegration,
  loading,
  ...props
}: Props & SimpleGridProps) => {
  if (loading)
    return (
      <SimpleGrid cols={2} {...props}>
        <Skeleton w='100%' height={55} />
        <Skeleton w='100%' height={55} />
        <Skeleton w='100%' height={55} />
        <Skeleton w='100%' height={55} />
        <Skeleton w='100%' height={55} />
        <Skeleton w='100%' height={55} />
      </SimpleGrid>
    );

  return (
    <SimpleGrid cols={!loading && !integrations.length ? 1 : 2} {...props}>
      {integrations.map((app) => (
        <AppButton
          key={app.id}
          {...app}
          setIntegration={setIntegration}
          handleIntegrationSubmit={handleIntegrationSubmit}
        />
      ))}
      {notListedTitle && (
        <NotListedButton
          title={notListedTitle}
          onClick={handleMissingIntegration}
        />
      )}
    </SimpleGrid>
  );
};

const AppButton = ({
  setIntegration,
  handleIntegrationSubmit,
  ...props
}: OnboardingIntegration & {
  setIntegration: SetIntegrationHandler;
  handleIntegrationSubmit: (integration: OnboardingIntegration) => void;
}) => {
  const { name, icon } = props;
  return (
    <Button
      variant='white'
      onClick={() => handleIntegrationSubmit(props)}
      onMouseEnter={() => setIntegration(props)}
      onMouseLeave={() => setIntegration(undefined)}
      h={55}
      styles={(theme) => ({
        root: {
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
          transition: 'background-color 0.2s ease-in-out',
          ':hover': {
            backgroundColor: theme.colors.gray[0],
          },
        },
        inner: {
          justifyContent: 'flex-start',
        },
        label: { fontWeight: 400 },
      })}
      leftIcon={<Avatar size='sm' src={icon} />}
    >
      {name}
    </Button>
  );
};

const NotListedButton = ({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) => {
  return (
    <Button
      onClick={onClick}
      variant='white'
      h={55}
      styles={(theme) => ({
        root: {
          transition: 'background-color 0.1s ease-in-out',
          backgroundColor: theme.colors.gray[0],
          ':hover': {
            backgroundColor: theme.colors.gray[1],
          },
        },
        label: { fontWeight: 400 },
      })}
    >
      {title}
    </Button>
  );
};
