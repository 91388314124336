import { Layout, SidebarConnect, SidebarPricing } from './components';
import { useIntegrationHover } from './hooks';
import { OnboardingConnectRoute } from './routes/OnboardingConnectRoute';
import { OnboardingPricingRoute } from './routes/OnboardingPricingRoute';
import { useClerk } from '@finalytic/authentication';
import { useTeam, useTeamId, useV2TransactionQuery } from '@finalytic/data-ui';
import { ComponentProps } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

export const OnboardingRoutes = () => {
  const [{ propertyManagementSystems }] = useTeam();

  const location = useLocation();

  const hasPms = propertyManagementSystems.length > 0;
  return (
    <Routes>
      <Route
        path='/*'
        element={
          <Navigate
            to={
              hasPms
                ? `/connect-accounting${location.search}`
                : `/connect-pms${location.search}`
            }
          />
        }
      />
      <Route element={<OnboardingLayout />}>
        <Route
          path='connect-pms'
          element={<OnboardingConnectRoute type='PMS' />}
        />
        <Route
          path='connect-accounting'
          element={<OnboardingConnectRoute type='Accounting' />}
        />
        <Route path='pricing' element={<OnboardingPricingRoute />} />
      </Route>
      <Route
        path='callback'
        element={
          <Navigate
            to={`/connect-pms${window.location.search || ''}&callback=true`}
          />
        }
      />
    </Routes>
  );
};

type SidebarProps = Pick<
  ComponentProps<typeof Layout>,
  'sidebar' | 'sidebarPaddingTop' | 'title'
>;

const OnboardingLayout = () => {
  const { signOut } = useClerk();

  const [team] = useTeam();

  const { propertyManagementSystems } = team;

  const location = useLocation();

  const [, setTeamId] = useTeamId();
  const goto = useNavigate();

  const { accounting, pms } = useIntegrationHover();

  const { data } = useV2TransactionQuery(
    (q, args) => {
      const teams = q
        .tenant({
          order_by: [{ name: 'asc' }],
          where: { status: { _neq: 'deleted' } },
        })
        .map((i) => ({ id: i.id, name: i.name, type: i.type })) as {
        id: string;
        name: string;
        type: string;
      }[];

      if (args.pmsId) {
        const p = q.connectionById({ id: args.pmsId });

        const pms = {
          id: p?.id,
          name: p?.name || '',
          icon: p?.app.iconRound || '',
        };

        return {
          teams,
          pms,
        };
      }

      return { teams };
    },
    {
      variables: {
        pmsId: propertyManagementSystems[0]?.id,
      },
    }
  );

  const setTeam = (teamId: string, teamType?: string) => {
    setTeamId(teamId);
    goto('/');
  };

  const side: Record<string, SidebarProps> = {
    '/pricing': {
      title: 'Start your 14-Day Free Trial',
      sidebar: <SidebarPricing />,
      sidebarPaddingTop: '20vh',
    },
    '/connect-pms': {
      title: 'Connect your PMS',
      sidebar: (
        <SidebarConnect
          pms={data?.pms || pms}
          accounting={accounting}
          title='Choose your PMS'
          subtitle='We will collect, clean and organize your data. So you can derive valuable insights and take action on your findings.'
        />
      ),
    },
    '/connect-accounting': {
      title: 'Connect your Accounting',
      sidebar: (
        <SidebarConnect
          pms={data?.pms || pms}
          accounting={accounting}
          title='Choose your Accounting'
          subtitle='We will collect, clean and organize your data. So you can derive valuable insights and take action on your findings.'
        />
      ),
    },
  };

  return (
    <Layout
      showHelpCenter
      handleLogout={signOut}
      setTeam={setTeam}
      teams={data?.teams || []}
      team={team}
      {...side[location.pathname]}
    >
      <Outlet />
    </Layout>
  );
};
