export const ConnectionsIcon = ({ color = 'white' }: { color?: string }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.9 2.00059C9.9 1.50353 9.49706 1.10059 9 1.10059C8.50294 1.10059 8.1 1.50353 8.1 2.00059V5.7999H7.2C6.53726 5.7999 6 6.33716 6 6.99991V11.9999C6 15.0086 8.21449 17.5 11.1024 17.9332C11.1008 17.9553 11.1 17.9776 11.1 18.0001V22.0001C11.1 22.4972 11.5029 22.9001 12 22.9001C12.4971 22.9001 12.9 22.4972 12.9 22.0001V18.0001C12.9 17.9776 12.8992 17.9553 12.8976 17.9332C15.7855 17.5 18 15.0086 18 11.9999V6.9999C18 6.33716 17.4627 5.7999 16.8 5.7999H15.9V2.00059C15.9 1.50353 15.4971 1.10059 15 1.10059C14.5029 1.10059 14.1 1.50353 14.1 2.00059V5.7999H9.9V2.00059Z'
      fill='url(#paint0_linear_1705_42401)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_1705_42401'
        x1='6'
        y1='2.31167'
        x2='22.9057'
        y2='16.4133'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={color} stopOpacity='0.6' />
        <stop offset='1' stopColor={color} stopOpacity='0.35' />
      </linearGradient>
    </defs>
  </svg>
);
