import { useDashboardType, useMe } from '../../hooks';
import { HubspotContactForm } from '../hubspot';
import { TransparentButton } from '@finalytic/ui';
import {
  IconDefinition,
  faPaperPlane,
  faPhoneAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { forwardRef, useMemo } from 'react';

export const SupportButton = ({
  navbarExtended,
}: {
  navbarExtended: boolean;
}) => {
  const { primaryColor: appName } = useMantineTheme();
  const [dashboard] = useDashboardType();

  if (appName !== 'VRPlatform' || dashboard === 'owner') return null;

  const [opened, handlers] = useDisclosure(false);

  const borderRadius = 'md';

  return (
    <Popover
      position='top-start'
      withArrow
      radius={borderRadius}
      opened={opened}
      withinPortal
      arrowOffset={10}
      onClose={handlers.close}
      styles={(theme) => ({
        dropdown: {
          padding: 0,
          margin: 0,
          '& > div:first-of-type': {
            overflow: 'hidden',
            borderRadius: theme.radius[borderRadius],
          },
        },
      })}
    >
      <Popover.Target>
        <NavbarSupportButton
          title='Support'
          icon={faPaperPlane}
          navbarExtended={navbarExtended}
          onClick={handlers.toggle}
        />
      </Popover.Target>
      <Popover.Dropdown>
        <HubspotContactForm
          region='na1'
          formId='82451f2c-6d15-45b7-aea2-055e392f74f3'
          portalId='6111349'
          closePopover={handlers.close}
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export const OnboardingCallButton = ({
  navbarExtended,
}: {
  navbarExtended: boolean;
}) => {
  const [dashboard] = useDashboardType();
  const { primaryColor: appName } = useMantineTheme();
  const { memberships } = useMe();

  const canBookCall = useMemo(
    () =>
      memberships.length === 1 &&
      dashboard === 'propertyManager' &&
      appName === 'VRPlatform',
    [memberships, dashboard, appName]
  );

  if (!canBookCall) return null;

  return (
    <NavbarSupportButton
      title='Book Onboarding Call'
      href='https://calendly.com/maudet-ximplifi/30m'
      icon={faPhoneAlt}
      navbarExtended={navbarExtended}
    />
  );
};

const NavbarSupportButton = forwardRef<
  HTMLButtonElement,
  {
    href?: string;
    onClick?: () => void;
    navbarExtended: boolean;
    title: string;
    icon: IconDefinition;
  }
>(({ href, navbarExtended, title, icon, onClick }, ref) => {
  const { colors } = useMantineTheme();

  const tooltipIcon = <FontAwesomeIcon color={colors.gray[6]} icon={icon} />;

  return (
    <TransparentButton
      ref={ref}
      component={href ? 'a' : undefined}
      onClick={onClick}
      href={href}
      sx={{
        background: 'none',
        border: 'none',
        height: 30,
        '&:disabled': {
          background: 'transparent',
        },
      }}
      px='sm'
      styles={{
        inner: navbarExtended
          ? {
              justifyContent: 'space-between',
            }
          : undefined,
      }}
      size='xs'
      mx='xs'
      mb={4}
      rightIcon={navbarExtended ? tooltipIcon : null}
    >
      {navbarExtended ? title : tooltipIcon}
    </TransparentButton>
  );
});

NavbarSupportButton.displayName = 'NavbarSupportButton';
