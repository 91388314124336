export const GRAPHQL_URI = `${
  import.meta.env.VITE_GRAPHQL_URI || 'https://finalytic.hasura.app/v1/graphql'
}`;

export const AUTH_REDIRECT_URL = import.meta.env.DEV
  ? 'https://tolocalhost.com/verification'
  : `https://${window.location.host}/verification`;

export const API_URI =
  import.meta.env.VITE_API_URI || 'https://api.finalytic.io/v1';

export const CLERK_FRONTEND_KEY = window.location.host.includes('finalytic.io')
  ? 'clerk.finalytic.io'
  : 'clerk.9qv33.fsu0l.lcl.dev';

export const STRIPE_PUBLISHABLE_KEY =
  import.meta.env.STRIPE_PUBLISHABLE_KEY ||
  // Dummy stripe key
  'pk_test_51M9tj7I6tPNv1U9aovuj4J3n0Xh1W0Jg9u7vEtFYIT4T2EwR2Ek1fePyAOUCgxQQBb2K7ZiPCr9JhOQCJBbitcZT00mxa8Auu1';

export const NEW_SIGN_IN = false;

export const PLATFORM: 'finalytic' | 'vrplatform' = import.meta.env
  .VITE_PLATFORM_NAME;

// Google Maps api key
export const GOOGLE_MAPS_KEY = 'AIzaSyBTcW3kF8xwTfQPMCgZENHrUfHjO9w4Wy8';
