import { QueryKeyUnion, gqlV2, useV2Client } from '.';
import { useMemo } from 'react';
import { create } from 'zustand';

type DatasourceRefetchStore = {
  datasources: { [automationId in QueryKeyUnion]?: number };
};

export type DatasourceRefetchActions = {
  refetchStore: (storeKey: QueryKeyUnion[] | QueryKeyUnion) => void;
};

const initialState: DatasourceRefetchStore = {
  datasources: {},
};

export function useDataSourceV2(k: QueryKeyUnion[] | QueryKeyUnion) {
  const client = useV2Client();

  const store = useDataSourceStore();

  const keys = Array.isArray(k) ? k : [k];

  const fetch = useMemo(
    () => <T>(q: (sub: gqlV2.Query) => T) => client.query(q, { noCache: true }),
    [client.query, keys.forEach((key) => store.datasources[key])]
  );

  return {
    fetch,
    refetch: (storeKey: QueryKeyUnion[] | QueryKeyUnion) =>
      store.refetchStore(storeKey),
    keys: useMemo(
      () => keys.map((key) => store.datasources[key]),
      [store.datasources, keys]
    ),
  };
}

const useDataSourceStore = create<
  DatasourceRefetchStore & DatasourceRefetchActions
>()((set, get) => ({
  ...initialState,
  refetchStore: (storeKey) => {
    const store = get();
    const storeKeys = Array.isArray(storeKey) ? storeKey : [storeKey];
    storeKeys.forEach((key) => {
      const value = store.datasources[key];
      set((state) => ({
        ...state,
        datasources: {
          ...state.datasources,
          [key]: value ? value + 1 : 1,
        },
      }));
    });
  },
}));
