import { gql, useClient, useDashboardType, useTeam } from '../../../hooks';
import { PaymentType, usePaymentsDatasource } from './usePaymentsDatasource';
import { payment_bool_exp } from '@finalytic/graphql';
import {
  ConnectionWithIconCell,
  InfoButtonCell,
  LoadingIndicator,
  NoPaymentsTableOverlay,
  ResultStatusCell,
  SRMHeaderSelectCell,
  StringParam,
  Table,
  TableContainer,
  TableStylingProps,
  TeamIconCell,
  showErrorNotification,
  useQueryParamSet,
} from '@finalytic/ui';
import { AgGridReact, ColDef, ICellRendererParams } from '@finalytic/ui-grid';
import { formatCurrency } from '@finalytic/utils';
import { Group } from '@mantine/core';
import { utc } from 'dayjs';
import { Key, forwardRef, useMemo } from 'react';

type Props = {
  where: payment_bool_exp;
  limit: number | undefined;
  rowSelectable?: boolean;
  multiSelect?: boolean;
  onSelectAllPages?: (selected: boolean) => void;
} & TableStylingProps;

export const PaymentTable = forwardRef<AgGridReact, Props>(
  (
    {
      where,
      rowSelectable = false,
      multiSelect = false,
      limit,
      onSelectAllPages,
      ...tableStylingProps
    },
    ref
  ) => {
    const rowsPerPage = 100;

    const client = useClient();

    const [dashboardType] = useDashboardType();
    const [{ featureFlags }] = useTeam();
    const isTeamDashboard = ['partner'].includes(dashboardType);

    const showAutomationColumn =
      dashboardType !== 'owner' && featureFlags.includes('mappingsV2');

    const setSearchParams = useQueryParamSet('payment', StringParam);

    const openDrawer = (paymentId?: string) => {
      if (!paymentId)
        return showErrorNotification({ message: 'Missing payment id.' });

      setSearchParams(paymentId);
    };

    const columnDefs = useMemo<ColDef[]>(
      () => [
        {
          field: 'connection.name',
          checkboxSelection: rowSelectable,
          headerName: 'Connection',
          headerComponent: SRMHeaderSelectCell,
          headerComponentParams: { onSelectAllPages },
          minWidth: 200,
          flex: 1,
          cellRenderer: (params: ICellRendererParams) => (
            <ConnectionWithIconCell
              title={params.value}
              icon={params.data?.connection?.app?.iconRound}
            />
          ),
        },
        {
          field: 'results',
          headerName: 'Automations',
          flex: 1,
          hide: !showAutomationColumn,
          minWidth: 200,
          cellClass: 'no-scrollbar',
          cellRenderer: (params: ICellRendererParams) => {
            const results = params.data.actions.length
              ? params.data.actions
              : params.value;
            if (results?.length < 1) return null;
            return (
              <Group
                noWrap
                align='center'
                className='no-scrollbar'
                sx={{
                  overflowX: 'scroll',
                  lineHeight: 'initial',
                  height: '100%',
                }}
                spacing={4}
              >
                {results?.map(
                  (result: {
                    id: Key | null | undefined;
                    status: string | undefined;
                    type: string;
                    message: string | undefined;
                  }) => (
                    <ResultStatusCell
                      key={result.id}
                      status={result.status}
                      value={result.type?.replace('create', '')}
                      message={result.message}
                    />
                  )
                )}
              </Group>
            );
          },
        },
        {
          field: 'tenant.name',
          hide: !isTeamDashboard,
          headerName: 'Team',
          width: rowSelectable ? 100 : 70,
          resizable: false,
          suppressMenu: true,
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          cellRenderer: (params: ICellRendererParams<PaymentType>) => (
            <TeamIconCell
              logo={params.data?.tenant?.logo}
              name={params.data?.tenant?.name}
              colorPrimary={params.data?.tenant?.colorPrimary}
            />
          ),
        },
        {
          field: 'payedAt',
          headerName: 'Date',
          valueFormatter: ({ value }) => utc(value).format('D MMM YYYY'), // '25/01/2019'
        },

        {
          headerName: 'Amount',
          resizable: false,
          field: 'centTotal',
          type: 'numericColumn',
          valueFormatter: ({ value, data }) =>
            formatCurrency(value / 100, data.currency),
        },

        {
          field: 'icon',
          hide: !rowSelectable,
          sortable: false,
          resizable: false,
          width: 60,
          headerName: '',
          headerTooltip: 'tool',
          cellStyle: { display: 'grid', placeContent: 'center' },
          cellRenderer: (params: ICellRendererParams<PaymentType>) => (
            <InfoButtonCell onIconClick={() => openDrawer(params.data?.id)} />
          ),
          suppressSizeToFit: true,
        },
      ],
      [
        openDrawer,
        rowSelectable,
        showAutomationColumn,
        isTeamDashboard,
        onSelectAllPages,
      ]
    );

    const { dataSource, paginationSize } = usePaymentsDatasource({
      where,
      billingLimit: limit,
    });

    return (
      <TableContainer>
        <Table
          ref={ref}
          columnDefs={columnDefs}
          serverSideDatasource={dataSource}
          serverSideInfiniteScroll
          rowModelType='serverSide'
          rowSelection={multiSelect ? 'multiple' : 'single'}
          cacheBlockSize={paginationSize}
          paginationPageSize={paginationSize}
          rowStyle={{ cursor: 'pointer' }}
          getRowId={(params) => params?.data?.id}
          getRowClass={(params) => {
            if (limit && params.rowIndex > limit - 1) return 'limit-blurred';
          }}
          onRowClicked={({ data, rowIndex }) =>
            rowSelectable || (limit && (rowIndex || 0) > limit - 1)
              ? undefined
              : openDrawer(data?.id)
          }
          defaultColDef={{
            sortable: false,
          }}
          gridOptions={{
            suppressCellFocus: true,
            rowMultiSelectWithClick: true,
            suppressRowClickSelection: true,
            suppressExcelExport: true,
            suppressCsvExport: true,
            onCellClicked: (e) => {
              if (e.column.getColId() !== 'icon') {
                const selected = e.node.isSelected();
                e.node.setSelected(!selected);
              }
            },
          }}
          noRowsOverlayComponent={() => (
            <NoPaymentsTableOverlay text='Add a connection to show payments' />
          )}
          loadingOverlayComponent={
            <LoadingIndicator isFullPageLoading={false} />
          }
          {...tableStylingProps}
        />
      </TableContainer>
    );
  }
);

PaymentTable.displayName = 'Payment Table';
