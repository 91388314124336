import { useDashboardType } from './dashboard';
import { gqlV2, useNotifiedV2Mutation } from './graphql-v2';
import { useMe, useTeam, useTeamId } from './team';
import { Price } from '@finalytic/ui';
import { useMantineTheme } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';

type BillingContext = {
  isActive: boolean;
  hasStatements: boolean;
  hasManagementCommission: boolean;
  tableLimits: {
    payments: number | undefined;
    reservations: number | undefined;
    statements: number | undefined;
  };
};

export const useBilling = (): BillingContext => {
  // type FeatureFlag = 'ownerStatements' | 'managementCommission';
  // type TeamStatus =  "active" | "onboarding" | "deleted" | "canceled"
  // const { email } = useMe();
  // const [{ featureFlags, status }] = useTeam();
  const [dashboard] = useDashboardType();
  // const { primaryColor: appName } = useMantineTheme();

  if (['admin', 'partner'].includes(dashboard))
    return {
      isActive: true,
      hasManagementCommission: true,
      hasStatements: true,
      tableLimits: {
        payments: undefined,
        reservations: undefined,
        statements: undefined,
      },
    };

  // if (import.meta.env.DEV && appName.toLowerCase() === 'vrplatform') {
  //   const isActive = ['active'].includes(status);

  //   const hasManagementCommission =
  //     isActive && featureFlags.includes('managementCommission');
  //   const hasStatements = isActive && featureFlags.includes('ownerStatements');

  //   return {
  //     isActive,
  //     hasStatements,
  //     hasManagementCommission,
  //     tableLimits: {
  //       payments: !isActive ? 5 : undefined,
  //       reservations: !isActive ? 5 : undefined,
  //       statements: !hasStatements ? 5 : undefined,
  //     },
  //   };
  // }

  return {
    isActive: true,
    hasStatements: true,
    hasManagementCommission: true,
    tableLimits: {
      payments: undefined,
      reservations: undefined,
      statements: undefined,
    },
  };
};

export const useBillingData = () => {
  const [teamId] = useTeamId();
  const [products, setProducts] = useState<any[]>([]);
  const [activeSubscription, setActiveSubscription] = useState<
    ActiveSubscription | undefined
  >();

  const [refetch, setRefetch] = useState(0);

  const { getSubscriptions } = useBillingMutations();

  const formattedProducts = useMemo<Price[]>(() => {
    const activeSubscriptionLevel = parseInt(
      activeSubscription?.plan?.metadata?.level || '0'
    );

    return products
      ? products
          .filter((i) => !!i.metadata.hasuraKey)
          .sort(
            (a, b) =>
              parseInt(a.metadata?.level || 0) -
              parseInt(b.metadata?.level || 0)
          )
          .map((i) => {
            const features = Object.entries(
              i.metadata as Record<string, string>
            )
              .filter(([key, value]) =>
                key.includes('feature') ? value : null
              )
              .sort(([key1], [key2]) => {
                const getLevel = (k: string) =>
                  parseInt(k.split('_').reverse()[0]);
                const level1 = getLevel(key1);
                const level2 = getLevel(key2);

                return level2 < level1 ? 1 : 0;
              })
              .map(([, value]) => value);

            const tiers: Price['tiers'] = i.default_price?.tiers;

            return {
              icon: i.images[0],
              priceId: i.default_price?.id,
              productId: i.id,
              title: i.name,
              unit_label: i.unit_label,
              unit_amount: i.default_price?.tiers[0].unit_amount,
              currency: i.default_price?.currency,
              buttonLabel: !activeSubscription
                ? 'Activate'
                : parseInt(i.metadata?.level) < activeSubscriptionLevel
                ? 'Downgrade'
                : 'Upgrade',
              features,
              tiers,
              isFeatured: parseInt(i.metadata?.level || '0') === 2,
              isActive: i.default_price?.id === activeSubscription?.plan?.id,
            };
          })
      : [];
  }, [products, activeSubscription]);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      if (teamId) {
        const result = await getSubscriptions({ args: { teamId } });

        setActiveSubscription(result?.data?.subscriptions[0]);
        setProducts(result?.data?.products);
      }
    };

    fetchSubscriptions();
  }, [teamId, refetch]);

  const activeIcon = useMemo(
    () =>
      formattedProducts?.find(
        (prod) => prod?.priceId === activeSubscription?.plan.id
      )?.icon,
    [formattedProducts, activeSubscription]
  );

  return {
    activeSubscription,
    activeIcon,
    isSubscriptionCancelled: !!activeSubscription?.cancel_at_period_end,
    formattedProducts,
    refetchBilling: () => setRefetch(+new Date()),
  };
};

export const useBillingMutations = () => {
  const { mutate: getSubscriptions } = useNotifiedV2Mutation(
    (q, args: { teamId: string }) => {
      const res = q.stripeGetSubscriptions({ object: { teamId: args.teamId } });

      return {
        ok: res?.ok,
        data: res?.data,
      };
    }
  );

  const { mutate: createBillingPortal, loading: billingLoading } =
    useNotifiedV2Mutation(
      (q, args: gqlV2.stripe_create_billing_portal_request) => {
        const res = q.stripeCreateBillingPortal({ object: args });

        return {
          ok: res?.ok,
          data: res?.data,
        };
      }
    );

  const { mutate: cancelSubscription } = useNotifiedV2Mutation(
    (q, args: gqlV2.stripe_cancel_subscription_request) => {
      const res = q.stripeCancelSubscription({ object: args });

      return {
        ok: res?.ok,
        data: res?.data,
      };
    },
    {
      successMessage: {
        message: 'We successfully cancelled your subscription.',
      },
    }
  );

  const { mutate: mutateSubscription } = useNotifiedV2Mutation(
    (q, args: gqlV2.stripe_change_subscription_request) => {
      const res = q.stripeChangeSubscription({ object: args });

      return {
        ok: res?.ok,
        data: res?.data,
      };
    },
    {
      successMessage: {
        message: 'We successfully activated your subscription.',
      },
    }
  );

  return {
    mutateSubscription,
    cancelSubscription,
    createBillingPortal,
    getSubscriptions,
    billingLoading,
  };
};

export type ActiveSubscription = typeof ExampleSubscription;

const ExampleSubscription = {
  application: null,
  application_fee_percent: null,
  automatic_tax: {
    enabled: false,
  },
  billing_cycle_anchor: 1672640414,
  billing_thresholds: null,
  cancel_at: null,
  cancel_at_period_end: false,
  canceled_at: null,
  collection_method: 'charge_automatically',
  created: 1672640414,
  currency: 'usd',
  current_period_end: 1675318814,
  current_period_start: 1672640414,
  customer: 'cus_N5sLd5rKDGmrXk',
  days_until_due: null,
  default_payment_method: 'pm_1MLhNXI6tPNv1U9askR0IgHs',
  default_source: null,
  default_tax_rates: [],
  description: null,
  discount: null,
  ended_at: null,
  id: 'sub_1MLhNaI6tPNv1U9aT1U4azal',
  items: {
    data: [
      {
        billing_thresholds: null,
        created: 1672640414,
        id: 'si_N5t9YccwNJ9rxK',
        metadata: {},
        object: 'subscription_item',
        plan: {
          active: true,
          aggregate_usage: 'max',
          amount: null,
          amount_decimal: null,
          billing_scheme: 'tiered',
          created: 1671448919,
          currency: 'usd',
          id: 'price_1MGhPvI6tPNv1U9aF98FaLCF',
          interval: 'month',
          interval_count: 1,
          livemode: false,
          metadata: {
            hasuraKey: 'VRPBronze',
            level: '0',
          },
          nickname: 'Base',
          object: 'plan',
          product: 'prod_N0ir7Tddj4HBo0',
          tiers_mode: 'graduated',
          transform_usage: null,
          trial_period_days: null,
          usage_type: 'metered',
        },
        price: {
          active: true,
          billing_scheme: 'tiered',
          created: 1671448919,
          currency: 'usd',
          custom_unit_amount: null,
          id: 'price_1MGhPvI6tPNv1U9aF98FaLCF',
          livemode: false,
          lookup_key: null,
          metadata: {
            hasuraKey: 'VRPBronze',
            level: '0',
          },
          nickname: 'Base',
          object: 'price',
          product: 'prod_N0ir7Tddj4HBo0',
          recurring: {
            aggregate_usage: 'max',
            interval: 'month',
            interval_count: 1,
            trial_period_days: null,
            usage_type: 'metered',
          },
          tax_behavior: 'unspecified',
          tiers_mode: 'graduated',
          transform_quantity: null,
          type: 'recurring',
          unit_amount: null,
          unit_amount_decimal: null,
        },
        subscription: 'sub_1MLhNaI6tPNv1U9aT1U4azal',
        tax_rates: [],
      },
    ],
    has_more: false,
    object: 'list',
    total_count: 1,
    url: '/v1/subscription_items?subscription=sub_1MLhNaI6tPNv1U9aT1U4azal',
  },
  latest_invoice: 'in_1MLhNaI6tPNv1U9af0ojhecM',
  livemode: false,
  metadata: {},
  next_pending_invoice_item_invoice: null,
  object: 'subscription',
  on_behalf_of: null,
  pause_collection: null,
  payment_settings: {
    payment_method_options: null,
    payment_method_types: null,
    save_default_payment_method: 'off',
  },
  pending_invoice_item_interval: null,
  pending_setup_intent: null,
  pending_update: null,
  plan: {
    active: true,
    aggregate_usage: 'max',
    amount: null,
    amount_decimal: null,
    billing_scheme: 'tiered',
    created: 1671448919,
    currency: 'usd',
    id: 'price_1MGhPvI6tPNv1U9aF98FaLCF',
    interval: 'month',
    interval_count: 1,
    livemode: false,
    metadata: {
      hasuraKey: 'VRPBronze',
      level: '0',
    },
    nickname: 'Base',
    object: 'plan',
    product: 'prod_N0ir7Tddj4HBo0',
    tiers_mode: 'graduated',
    transform_usage: null,
    trial_period_days: null,
    usage_type: 'metered',
  },
  quantity: 1,
  schedule: null,
  start_date: 1672640414,
  status: 'active',
  test_clock: null,
  transfer_data: null,
  trial_end: null,
  trial_start: null,
};
