import { OverlayContainer } from './_components';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StackProps } from '@mantine/core';

export const NoOwnersTableOverlay = ({
  text = 'No Owners Available',
  size,
  ...stackProps
}: {
  text?: string;
  size?: number | string;
} & StackProps) => (
  <OverlayContainer
    icon={<FontAwesomeIcon icon={faUser} />}
    size={size}
    {...stackProps}
  >
    {text}
  </OverlayContainer>
);
