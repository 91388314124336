import { FormInputBaseType } from './_base-input-types';
import { InputRangeDate, InputRangeValueType } from '@finalytic/ui';
import { Input as MantineInput } from '@mantine/core';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const DateInput = ({
  label,
  formKey,
  defaultValue = '',
  required = true,
  disabled,
  placeholder,
  onValueChange,
}: FormInputBaseType) => {
  const { control } = useFormContext();

  return (
    <MantineInput.Wrapper label={label}>
      <Controller
        control={control}
        name={formKey}
        defaultValue={defaultValue}
        rules={{ required: required }}
        render={({ field: { onChange, value: formValue } }) => {
          const extendedOnChange = (value: string) => {
            onValueChange?.(formKey, value);
            onChange(value);
          };

          // value format: YYYY-MM-DD || YYYY-MM-DD...YYYY-MM-DD
          const value = useMemo(() => {
            if (!(formValue as string).includes('...'))
              return [
                formValue ? dayjs(formValue).toDate() : null,
                null,
              ] as InputRangeValueType;

            const v = (formValue as string).split('...');

            return v
              ? (v.map((i) => {
                  return i ? dayjs(i).toDate() : null;
                }) as InputRangeValueType)
              : undefined;
          }, [formValue]);

          const onInputChange = (value: InputRangeValueType) => {
            const filtered = value.filter(Boolean);

            if (filtered.length > 1) {
              extendedOnChange(
                value
                  .map((i: InputRangeValueType[number]) =>
                    dayjs(i).format('YYYY-MM-DD')
                  )
                  .join('...')
              );
            } else if (filtered.length === 1) {
              extendedOnChange(dayjs(filtered[0]).format('YYYY-MM-DD'));
            } else if (filtered.length === 0) {
              extendedOnChange('');
            }
          };

          return (
            <InputRangeDate
              placeholder={placeholder || label || ''}
              value={value || [null, null]}
              onChange={onInputChange}
              name={formKey}
              disabled={disabled}
              clearable
              sx={{ input: { borderColor: '#CED0D780' } }}
            />
          );
        }}
      />
    </MantineInput.Wrapper>
  );
};
