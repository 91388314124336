import { useMe } from '../../hooks';
import { OwnerRoutes } from './OwnerRoutes';
import { PmRoutes } from './PmRoutes';

export const DashboardRoutes = () => {
  const user = useMe();

  if (user?.type === 'owner') {
    return <OwnerRoutes />;
  }

  return <PmRoutes />;
};
