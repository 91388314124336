import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { gqlV2, useNotifiedV2Mutation, useTeamId } from '../../../hooks';
import { ConfirmModal, ConfirmResultType } from '../confirm-modal';
import { Logo, useColors } from '@finalytic/ui';
import { Avatar, Sx, useMantineTheme } from '@mantine/core';

type InviteOwnerModalProps = {
  name: string;
  email: string;
  isReinvite: boolean;
  refetchTable: () => void;
  opened: boolean;
  closeModal: () => void;
};

export const InviteOwnerModal = ({
  email,
  name,
  isReinvite,
  refetchTable,
  opened,
  closeModal,
}: InviteOwnerModalProps) => {
  const { black } = useColors();

  const [teamId] = useTeamId();
  const { primaryColor: appName } = useMantineTheme();

  const { mutate } = useNotifiedV2Mutation(
    (q, args: gqlV2.invite_user_request) => {
      const res = q.inviteUser({ object: args });

      return {
        ok: res?.ok,
        data: res?.data,
      };
    },
    {
      successMessage: {
        title: 'Owner invited to platform.',
        message:
          'An email has been sent to the owner inviting them to the platform.',
      },
    }
  );

  const invite = async (): Promise<ConfirmResultType> => {
    const result = await mutate({
      args: {
        role: 'owner',
        email: email,
        tenantId: teamId,
      },
    });

    if (result.ok) {
      refetchTable();
    }

    const isSuccess = !!result.ok;
    return {
      isSuccess,
      message: isSuccess
        ? 'Owner was successfully invited to the platform.'
        : 'Failed to invite owner. Please try again.',
    };
  };

  const iconStyling: Sx = {
    border: '6px solid #fff',
  };

  const dropShadow =
    'drop-shadow(0px 74px 45px rgba(0, 0, 0, 0.02)) drop-shadow(0px 33px 33px rgba(0, 0, 0, 0.05)) drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.05)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05))';

  return (
    <ConfirmModal
      title={isReinvite ? 'Resend Invitation' : 'Invite to app'}
      subtitle={`You're about to invite ${name}, they will get an E-Mail invite to join ${appName}.`}
      opened={opened}
      onClose={closeModal}
      onSubmit={invite}
      icon={
        <Avatar.Group
          spacing={30}
          sx={{
            borderRadius: 100,
            filter: dropShadow,
            msFilter: dropShadow,
            MozFilter: dropShadow,
            WebkitFilter: dropShadow,
          }}
        >
          <Avatar
            size={100}
            radius={100}
            sx={{
              ...iconStyling,
              '.mantine-Avatar-placeholder': {
                backgroundColor: black.base,
              },
            }}
          >
            <Logo width={100} color='#fff' />
          </Avatar>
          <Avatar
            size={100}
            radius={100}
            sx={{
              ...iconStyling,
            }}
          >
            <FontAwesomeIcon icon={faUser} />
          </Avatar>
        </Avatar.Group>
      }
    />
  );
};
