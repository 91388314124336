import { BaseDrawerProps } from './_types';
import { Box, Drawer as MantineDrawer, Sx } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { ReactNode } from 'react';

type Props = {
  containerSx?: Sx;
  header?: ReactNode;
};

export const Drawer = ({
  children,
  opened,
  onClose,
  containerSx,
  header,
  styles,
  ...props
}: BaseDrawerProps & Props) => {
  const [opened1] = useDebouncedValue(opened, 0);

  return (
    <MantineDrawer
      opened={opened1}
      onClose={onClose}
      position='right'
      transitionProps={{
        duration: 300,
      }}
      withinPortal
      withCloseButton={false}
      overlayProps={{
        color: '#bababa',
        opacity: 0.2,
        blur: 1,
      }}
      styles={{
        content: {
          overflow: 'scroll',
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        root: {
          display: 'flex',
          flexDirection: 'column',
        },
        body: {
          display: 'flex',
          padding: 0,
          flex: 1,
          height: '100vh',
        },
        ...styles,
      }}
      {...props}
    >
      {header}
      <Box
        sx={(theme) => {
          const sideBarPadding = theme.spacing.sm;

          return {
            backgroundColor: 'white',
            flex: 1,
            margin: sideBarPadding,
            borderRadius: theme.spacing.md,
            padding: `0px ${theme.spacing.sm}`,
            overflowX: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            ...containerSx,
          };
        }}
      >
        {children}
      </Box>
    </MantineDrawer>
  );
};
