import {
  SidebarContentContainer,
  SidebarContentContainerProps,
} from './SidebarContentContainer';
import { OnboardingIntegration } from './_types';
import { Logo, VrpDarkLogo } from '@finalytic/ui';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Group, Text, useMantineTheme } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

type App = OnboardingIntegration | undefined | null;

type SidebarConnectProps = {
  pms: App;
  accounting: App;
} & SidebarContentContainerProps;

export const SidebarConnect = ({
  pms,
  accounting,
  subtitle,
  title,
}: SidebarConnectProps) => {
  return (
    <SidebarContentContainer title={title} subtitle={subtitle}>
      <Group noWrap spacing={0}>
        <Step app={pms} type='PMS' />
        <Divider />
        <Step app={undefined} type='app' />
        <Divider />
        <Step app={accounting} type='Accounting' />
      </Group>
    </SidebarContentContainer>
  );
};

type StepProps = {
  app: App;
  type: 'PMS' | 'Accounting' | 'app';
};

const Step = (props: StepProps) => {
  const { primaryColor: appName } = useMantineTheme();

  const { type } = props;

  return (
    <Box sx={{ position: 'relative' }}>
      <Icon {...props} />
      <Text
        component='span'
        align='center'
        color='gray'
        size='sm'
        sx={{
          position: 'absolute',
          top: 100,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        {type === 'app' ? appName : type}
      </Text>
    </Box>
  );
};

const Icon = ({ app, type }: StepProps) => {
  const { primaryColor: appName } = useMantineTheme();
  const iconSize = 50;
  const [icon] = useDebouncedValue(app?.icon, 80);
  const [iconOpacity] = useDebouncedValue(icon, 50);

  const iconStyles = (background: string, opacity?: number) => ({
    image: {
      opacity,
      transition: '0.2s all ease-in-out',
    },
    placeholderIcon: {
      opacity,
      transition: '0.2s all ease-in-out',
    },
    placeholder: {
      backgroundColor: 'initial',
    },
    root: {
      // boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
      transition: '0.1s all ease-out',
      boxShadow: type !== 'app' && icon ? '0px 0px 10px #ffffff50' : undefined,
      background,
    },
  });

  if (type === 'app')
    return (
      <Box sx={{ position: 'relative' }}>
        <Avatar
          size={iconSize}
          radius={iconSize}
          styles={iconStyles(
            'linear-gradient(147.33deg, rgba(7, 7, 7, 0.47) 7.22%, rgba(0, 0, 0, 0.03) 93.2%)'
          )}
        >
          {appName === 'VRPlatform' ? (
            <VrpDarkLogo size={30} />
          ) : (
            <Logo width={40} background='transparent' color='#fff' />
          )}
        </Avatar>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <Rings />
        </Box>
      </Box>
    );

  return (
    <Avatar
      src={icon}
      size={iconSize}
      radius={iconSize}
      styles={{
        ...iconStyles(
          'linear-gradient(147.33deg, rgba(249, 249, 249, 0.1) 7.22%, rgba(255, 255, 255, 0.05) 93.2%)',
          iconOpacity ? 1 : 0
        ),
      }}
      className={`connection-icon-${type}`}
    >
      <FontAwesomeIcon icon={faPlus} color='white' />
    </Avatar>
  );
};

const Rings = () => (
  <svg
    width='240'
    height='240'
    viewBox='0 0 240 240'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.5'>
      <rect
        x='0.25'
        y='0.25'
        width='239.5'
        height='239.5'
        rx='119.75'
        fill='url(#paint0_linear_338_2982)'
        fillOpacity='0.4'
      />
      <rect
        x='30.25'
        y='30.25'
        width='179.5'
        height='179.5'
        rx='89.75'
        fill='url(#paint1_linear_338_2982)'
        fillOpacity='0.4'
      />
      <rect
        x='60.25'
        y='60.25'
        width='119.5'
        height='119.5'
        rx='59.75'
        fill='url(#paint2_linear_338_2982)'
        fillOpacity='0.3'
      />
      <rect
        x='60.25'
        y='60.25'
        width='119.5'
        height='119.5'
        rx='59.75'
        stroke='url(#paint3_linear_338_2982)'
        strokeWidth='0.5'
      />
      <rect
        x='30.25'
        y='30.25'
        width='179.5'
        height='179.5'
        rx='89.75'
        stroke='url(#paint4_linear_338_2982)'
        strokeWidth='0.5'
      />
      <rect
        x='0.25'
        y='0.25'
        width='239.5'
        height='239.5'
        rx='119.75'
        stroke='url(#paint5_linear_338_2982)'
        strokeWidth='0.5'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_338_2982'
        x1='44.3282'
        y1='1.97286e-06'
        x2='198.254'
        y2='240'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9F9F9' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.05' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_338_2982'
        x1='63.2462'
        y1='30'
        x2='178.691'
        y2='210'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9F9F9' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.05' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_338_2982'
        x1='82.1641'
        y1='60'
        x2='159.127'
        y2='180'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F9F9F9' stopOpacity='0.1' />
        <stop offset='1' stopColor='white' stopOpacity='0.05' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_338_2982'
        x1='94.1396'
        y1='54'
        x2='133.14'
        y2='180'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.17' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_338_2982'
        x1='49.7579'
        y1='15.1666'
        x2='146.846'
        y2='201.357'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.15' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
      <linearGradient
        id='paint5_linear_338_2982'
        x1='62.3667'
        y1='-21.399'
        x2='164.918'
        y2='274.067'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' stopOpacity='0.17' />
        <stop offset='1' stopColor='white' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>
);

const Divider = () => {
  return (
    <svg
      width='61'
      height='5'
      viewBox='0 0 61 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <line
        opacity='0.5'
        x1='4'
        y1='2'
        x2='58'
        y2='2'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeDasharray='0.01 20'
      />
      <rect width='61' height='5' fill='url(#paint0_linear_338_3638)' />
      <defs>
        <linearGradient
          id='paint0_linear_338_3638'
          x1='60.2584'
          y1='2.49998'
          x2='0.683324'
          y2='2.49999'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0' />
          <stop offset='0.459881' stopColor='white' stopOpacity='0.5' />
          <stop offset='1' stopColor='white' stopOpacity='0.1' />
        </linearGradient>
      </defs>
    </svg>
  );
};
