import { formatOwnerName } from '../../format-owner-name';
import {
  FormattedStatement,
  Statement,
  StatementSummaryUnion,
} from '../_types';
import { hasValue, utc } from '@finalytic/utils';

export const getStatementInfo = ({
  ownerId,
  statements,
  groupedBy,
}: {
  statements: Statement[];
  ownerId: string | undefined;
  groupedBy: StatementSummaryUnion;
}): {
  header: FormattedStatement['header'] | undefined;
  footer: FormattedStatement['footer'];
  ownerSplit: number | undefined;
} => {
  const statementOwnerStatement: Statement | undefined = statements.find(
    (i) => !!i.statementOwners.find((i) => i.owner.id === ownerId)
  );

  const statement = statementOwnerStatement || statements[0];

  if (!statement)
    return {
      header: undefined,
      ownerSplit: undefined,
      footer: '',
    };

  const statementOwner = ownerId
    ? statement.statementOwners.find((i) => i.owner.id === ownerId)
    : statement.statementOwners[0];

  const month =
    groupedBy !== 'groupByReservation'
      ? undefined
      : statement?.startAt
      ? utc(statement?.startAt).format('MMMM YYYY')
      : 'Missing Month';

  const header: FormattedStatement['header'] = {
    statement: {
      month,
      subline: `Owner Statement | ${month || 'Summary'}`,
    },
    team: {
      name: statement.tenant?.name || statement.tenant?.companyName || '',
      address: statement.tenant.address,
      email: statement.tenant.email,
      phone: statement.tenant.phone,
      taxId: statement.tenant.taxId,
      logo: statement.tenant.logo,
    },
    listing: {
      name: statement.listing?.name,
      address: statement.listing?.address,
    },
    owner: statementOwner?.id
      ? {
          companyName: statementOwner.owner.vendor,
          name: formatOwnerName(statementOwner.owner),
          address: statementOwner.owner.address,
          email: statementOwner.owner.email,
          phone: statementOwner.owner.phone,
        }
      : undefined,
  };

  const footerValues = [
    header.team.name,
    header.team.address,
    header.team.phone,
    // header.team.email,
    header.team.taxId,
  ].filter(hasValue);

  return {
    header,
    ownerSplit: statementOwner?.split || undefined,
    footer: footerValues.length > 0 ? `©︎ ${footerValues.join(', ')}` : '',
  };
};
