import {
  IconButton,
  Logo,
  Select,
  SelectItem,
  TeamSwitch as TSwitch,
  useColors,
} from '@finalytic/ui';
import { faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Group,
  MantineSizes,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ComponentProps, PropsWithChildren, useMemo } from 'react';

type TeamSwitchProps = ComponentProps<typeof TSwitch>;

export type LayoutDataProps = {
  handleLogout: (() => void) | undefined;
  teams: TeamSwitchProps['teams'];
  team: TeamSwitchProps['team'];
  setTeam: TeamSwitchProps['setTeam'];
  showHelpCenter?: boolean;
};

export type LayoutProps = {
  title: string;
  sidebar: React.ReactNode;
  sidebarPaddingTop?: string | number;
};

export const Layout = ({
  title,
  children,
  sidebar,
  sidebarPaddingTop = '28vh',
  handleLogout,
  showHelpCenter,
  ...teamSwitchProps
}: PropsWithChildren<LayoutProps & LayoutDataProps>) => {
  const windowPadding: keyof MantineSizes = 'lg';

  const colors = useColors();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <Flex
      p={windowPadding}
      direction={isMobile ? 'column' : 'row'}
      align='stretch'
      sx={{
        minHeight: isMobile ? undefined : '100vh',
      }}
    >
      <Box
        sx={{ flex: 1 }}
        pb={isMobile ? '10vh' : undefined}
        px={isMobile ? undefined : 'sm'}
      >
        <Group position='apart' mb='3vh' px='xs'>
          <Group>
            {handleLogout && (
              <Tooltip label='Sign out' withArrow>
                <Box sx={{ transform: 'rotate(180deg)' }}>
                  <IconButton onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOut} />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
            {teamSwitchProps.teams.length > 1 && (
              <Box
                miw='230px'
                sx={() => ({ '*': { color: '#000000!important' } })}
              >
                <TeamSwitch
                  setTeam={teamSwitchProps.setTeam}
                  team={teamSwitchProps.team}
                  teams={teamSwitchProps.teams}
                />
              </Box>
            )}
          </Group>
          {showHelpCenter && (
            <Button
              variant='white'
              component={'a'}
              href='https://support.ximplifi.com/knowledge/preparing-qbo-for-the-vrplatform-implementation'
              target='_blank'
            >
              Help center
            </Button>
          )}
        </Group>
        <Stack mx='auto' align='center' spacing={0}>
          <RoundLogo />
          <Text component='h1' size={24} mb='lg'>
            {title}
          </Text>
        </Stack>
        {children}
      </Box>
      <Box
        sx={(theme) => {
          const hueColor =
            theme.primaryColor === 'Finalytic'
              ? colors.purple.base
              : colors.blue.light;

          return {
            flex: 1,
            zIndex: -2,
            borderRadius: theme.radius.md,
            backgroundColor: theme.colors.dark[6],
            minHeight: '100%',
            position: 'relative',
            '&::after': {
              position: 'absolute',
              content: '""',
              zIndex: -1,
              top: '20%',
              width: '50%',
              left: '25%',
              height: '20%',
              backgroundColor: hueColor,
              transition: 'background-color 0.3s ease-in-out',
              opacity: 0.4,
              filter: 'blur(150px)',
            },
          };
        }}
      >
        <Box
          sx={{
            paddingTop: sidebarPaddingTop,
            paddingBottom: isMobile ? '10vh' : undefined,
            paddingInline: 30,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // overflow: 'scroll',
          }}
        >
          {sidebar}
        </Box>
      </Box>
    </Flex>
  );
};

const TeamSwitch = ({
  setTeam,
  team,
  teams,
}: {
  teams: TeamSwitchProps['teams'];
  team: TeamSwitchProps['team'];
  setTeam: TeamSwitchProps['setTeam'];
}) => {
  const value = useMemo<SelectItem>(
    () => ({ label: team?.name || '', value: team?.id || '' }),
    [team]
  );

  const data = useMemo<SelectItem[]>(
    () =>
      teams?.map((i) => ({
        label: i?.name || '',
        value: i?.id || '',
        pinned: i.type !== 'propertyManager',
      })) || [],
    [teams]
  );

  return (
    <Select
      withBorder
      withArrow
      withSearch
      searchPlaceholder='Search teams...'
      position='bottom-start'
      data={data}
      value={value}
      removeValue={() => null}
      setValue={(val) => setTeam(val.value)}
    />
  );
};

const RoundLogo = () => {
  const { primaryColor: appName } = useMantineTheme();
  const { black } = useColors();

  return (
    <Avatar
      size={45}
      radius={45}
      sx={{
        '.mantine-Avatar-placeholder': {
          backgroundColor: appName === 'Finalytic' ? black.base : 'white',
        },
      }}
    >
      <Logo width={40} color='#fff' />
    </Avatar>
  );
};
