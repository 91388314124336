import {
  gql,
  useDashboardType,
  useMe,
  useQuery,
  useTeam,
  useTeamRole,
} from '../hooks';
import { ConnectionIssueBadge } from '@finalytic/connections';
import { AccountsIssueBadge } from '@finalytic/owner-statements';
import {
  AnronDashboardIcon,
  ConnectionsIcon,
  ListingsIcon,
  OwnerIcon,
  PaymentsIcon,
  ReportsIcon,
  ReservationsIcon,
  StatementsIcon,
  WorkflowIcon,
} from '@finalytic/ui';

import { NavbarRoute, NavbarSection } from '../components';
import { LineMasterIssueBadge, ListingsIssueBadge } from '../views';
import { groupBy } from '@finalytic/utils';
import { useMemo } from 'react';

function useTeamViews() {
  const [{ featureFlags, id: teamId }] = useTeam();

  const [{ data }] = useQuery(
    {
      views: [
        {
          where: {
            _or: [
              {
                tenantId: { _eq: gql.$`teamId` },
                type: { _eq: 'payments' },
                group: { _eq: 'payments' },
              },
              {
                tenantId: { _eq: gql.$`teamId` },
                group: { _eq: 'Reports' },
              },
              {
                isDefault: {
                  _eq: true,
                },
              },
            ],
          },
          order_by: [{ sortOrder: 'asc' }],
        },
        {
          id: true,
          title: true,
          group: true,
          retoolViewId: true,
          sortOrder: true,
        },
      ],
    },
    {
      variables: {
        teamId,
      },
    }
  );

  const views = useMemo(
    () => groupBy(data?.views || [], (x) => x.group!),
    [data?.views]
  );

  const payViews = views?.payments?.map((i) => ({
    id: i.id as string,
    title: i.title,
    url: i.id,
    group: i.group as string,
  }));

  const reViews = views?.Reports?.map((i) => ({
    id: i.id!,
    title: i.title,
    url: i.retoolViewId!,
    group: i.group!.toLowerCase(),
  }));

  return {
    paymentViews: payViews || undefined,
    retoolViews: reViews || [],
    featureFlags,
  };
}

export const PmNavigation = ({
  navbarIsExpanded,
}: {
  navbarIsExpanded: boolean;
}) => {
  const { paymentViews, retoolViews, featureFlags } = useTeamViews();
  const [dashboard] = useDashboardType();

  const { isPartnerAdmin, isSuperAdmin } = useTeamRole();
  // const isAutomationPreview =
  //   dashboard === 'admin' ||
  //   email?.includes('@ximplifi.com') ||
  //   email?.includes('@setup.inc') ||
  //   email?.includes('@extenteam.com') ||
  //   isSuperAdmin;
  // const showAutomation = !featureFlags?.includes('hideAutomationNavigation');

  const isUsingNewDashboard = !!isPartnerAdmin;

  const showMappingsV2 = featureFlags?.includes('mappingsV2');

  const reViews = retoolViews.filter(
    (x) => !showMappingsV2 || x.url !== '11ef2837-7612-48db-a7c7-68c621d41efc'
  );
  return (
    <>
      <NavbarSection>
        {isUsingNewDashboard && (
          <NavbarRoute
            title='Dashboard'
            link='/'
            icon={<AnronDashboardIcon color='#ffffff80' />}
            navbarIsExpanded={navbarIsExpanded}
            v2Icon
          />
        )}

        <NavbarRoute
          title='Payments'
          link='/payments'
          icon={<PaymentsIcon />}
          subRoutes={paymentViews}
          navbarIsExpanded={navbarIsExpanded}
        />

        <NavbarRoute
          title='Reservations'
          link='/reservations'
          icon={<ReservationsIcon />}
          navbarIsExpanded={navbarIsExpanded}
        />

        <NavbarRoute
          title='Listings'
          link='/listings'
          icon={<ListingsIcon />}
          navbarIsExpanded={navbarIsExpanded}
          notification={<ListingsIssueBadge />}
        />
        <NavbarRoute
          title='Owners'
          link='/owners'
          icon={<OwnerIcon />}
          navbarIsExpanded={navbarIsExpanded}
        />

        {reViews?.length > 0 && (
          <NavbarRoute
            title='Reports'
            link={`/${retoolViews[0].group}/${retoolViews[0].url}`}
            icon={<ReportsIcon />}
            navbarIsExpanded={navbarIsExpanded}
            subRoutes={retoolViews}
          />
        )}

        {isSuperAdmin && dashboard === 'admin' && (
          <NavbarRoute
            title='Metrics'
            link={'/metrics'}
            icon={<ReportsIcon />}
            navbarIsExpanded={navbarIsExpanded}
          />
        )}
      </NavbarSection>

      {dashboard === 'partner' && (
        <NavbarSection>
          <NavbarRoute
            title='Line Master List'
            link={'/line-master-list'}
            icon={<ReportsIcon />}
            navbarIsExpanded={navbarIsExpanded}
            notification={<LineMasterIssueBadge />}
          />
          <NavbarRoute
            title='Partner Teams'
            link={'/partner-teams'}
            icon={<ReportsIcon />}
            navbarIsExpanded={navbarIsExpanded}
          />
          <NavbarRoute
            title='Client Members'
            link={'/client-members'}
            icon={<ReportsIcon />}
            navbarIsExpanded={navbarIsExpanded}
          />
        </NavbarSection>
      )}

      <NavbarSection>
        <NavbarRoute
          title='Files'
          link='/files'
          icon={<ReportsIcon />}
          navbarIsExpanded={navbarIsExpanded}
        />
      </NavbarSection>

      {showMappingsV2 && (
        <NavbarSection>
          <NavbarRoute
            title='Mappings'
            link='/reports/11ef2837-7612-48db-a7c7-68c621d41efc'
            icon={<ReportsIcon />}
            navbarIsExpanded={navbarIsExpanded}
          />
        </NavbarSection>
      )}

      <NavbarSection>
        <NavbarRoute
          title='Automations'
          link='/automations'
          icon={<WorkflowIcon />}
          navbarIsExpanded={navbarIsExpanded}
        />
      </NavbarSection>

      <NavbarSection>
        <NavbarRoute
          title='Statements'
          link='/statements'
          icon={<StatementsIcon />}
          navbarIsExpanded={navbarIsExpanded}
          notification={<AccountsIssueBadge />}
        />
      </NavbarSection>
      <NavbarSection noBorderBottom>
        <NavbarRoute
          title='Connections'
          link='/connections'
          icon={<ConnectionsIcon />}
          navbarIsExpanded={navbarIsExpanded}
          notification={<ConnectionIssueBadge />}
        />
      </NavbarSection>
    </>
  );
};
