import { Stack } from '@mantine/core';
import { ReactNode } from 'react';

export const NavbarSection = ({
  children,
  noBorderBottom = false,
}: {
  children: ReactNode;
  noBorderBottom?: boolean;
}) => (
  <Stack
    sx={{
      borderBottom: noBorderBottom ? undefined : '1px solid #C6C6C630',
      paddingBottom: 15,
    }}
    spacing={10}
  >
    {children}
  </Stack>
);
