import {
  useRunDrawer,
  useTeamId,
  useTeamRole,
  useV2TransactionSubscription,
} from '../../hooks';
import {
  Breadcrumbs,
  DelayedInputSearch,
  EllipsisMenu,
  EllipsisMenuItem,
  Table,
  TableContainer,
  WhiteTagCell,
  showErrorNotification,
  showSuccessNotification,
} from '@finalytic/ui';
import {
  CellRendererSelectorFunc,
  ColDef,
  GetDetailRowDataParams,
  GetRowIdParams,
  GridOptions,
  ICellRendererParams,
  ITooltipParams,
  ValueGetterParams,
} from '@finalytic/ui-grid';
import { day, ensure, hasValue, toTitleCase } from '@finalytic/utils';
import {
  faCheck,
  faCheckCircle,
  faCopy,
  faDownload,
  faEllipsisH,
  faHistory,
  faObjectsColumn,
  faTimesCircle,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  Group,
  Paper,
  Text,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useMemo, useState } from 'react';

export const TasksListView = () => {
  const [searchInput, setSearchInput] = useState('');

  return (
    <>
      <Breadcrumbs pageTitle='Task History'>
        <Group>
          <DelayedInputSearch
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            placeholder='Search list...'
          />
        </Group>
      </Breadcrumbs>

      <TableContainer mt='lg'>
        <TasksTable />
      </TableContainer>
    </>
  );
};

export const useTasksTableColumnDefs = (props?: { hide?: 'type'[] }) => {
  const { isSuperAdmin } = useTeamRole();

  const hide = props?.hide || [];

  const { colors } = useMantineTheme();
  const { runTask } = useRunDrawer();
  const retryTask = async (automationId: string, payload: any) => {
    if (!automationId)
      return showErrorNotification({
        message: 'Missing automation id.',
      });

    await runTask({
      id: automationId,
      input: [],
      params: payload,
    });
  };

  const openFileUrl = (url: string) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      console.log(url);
      showErrorNotification({ message: 'Missing file url.' });
    }
  };

  return useMemo<ColDef[]>(
    () =>
      [
        {
          cellRenderer: ({ value }: ICellRendererParams) => {
            const status = value?.toLowerCase().includes('partial')
              ? 'partial'
              : value?.toLowerCase().includes('running') ||
                value?.toLowerCase().includes('pending') ||
                value?.toLowerCase().includes('queued') ||
                value?.toLowerCase().includes('starting')
              ? 'running'
              : value?.toLowerCase().includes('fail') ||
                value?.toLowerCase().includes('failed')
              ? 'fail'
              : 'ok';
            return (
              <Group noWrap>
                <FontAwesomeIcon
                  icon={
                    status === 'running'
                      ? faEllipsisH
                      : status === 'fail'
                      ? faTimesCircle
                      : faCheckCircle
                  }
                  color={
                    status === 'partial' || status === 'running'
                      ? colors.yellow[5]
                      : status === 'fail'
                      ? colors.red[5]
                      : colors.green[5]
                  }
                />
              </Group>
            );
          },
          headerName: '',
          field: 'status',
          maxWidth: 30,
          minWidth: 30,
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
        {
          field: 'title',
          maxWidth: 300,
          minWidth: 200,
          valueGetter: ({ data }: ValueGetterParams) =>
            data?.title || data?.payload?.date || toTitleCase(data?.type || ''),
          cellRendererSelector: ensure<CellRendererSelectorFunc>((params) => {
            if (params.data?.childs?.length) {
              return { component: 'agGroupCellRenderer' };
            }

            return { component: () => params.value };
          }),
        },
        {
          field: 'message',
          flex: 1,
          minWidth: 150,
          tooltipField: 'message',
          tooltipComponent: CellTooltip,
          cellRenderer: ({ value, data }: ICellRendererParams) => {
            const successChilds = data?.success || 0;
            const errorChilds = data?.failed || 0;
            if (successChilds < 1 && errorChilds < 1) return value || '';

            return (
              <Group align='center' h='100%' position='apart' noWrap>
                <Text lineClamp={1} display='inline'>
                  {value}
                </Text>
                <SuccessErrors successes={successChilds} errors={errorChilds} />
              </Group>
            );
          },
        },
        {
          field: 'type',
          hide: hide.includes('type'),
          cellRenderer: ({ value }: ICellRendererParams) =>
            value && <WhiteTagCell>{toTitleCase(value)}</WhiteTagCell>,
          minWidth: 250,
        },
        {
          colId: 'menu',
          cellStyle: { display: 'grid', placeContent: 'center' } as any,
          maxWidth: 50,
          width: 50,
          pinned: 'right' as const,
          cellRenderer: ({ node, data }: ICellRendererParams<any>) => {
            if (node?.rowPinned === 'bottom') return undefined;

            if (data?.kind === 'action') {
              return (
                <EllipsisMenu>
                  <EllipsisMenuItem
                    icon={faObjectsColumn}
                    onClick={() => undefined}
                  >
                    View
                  </EllipsisMenuItem>
                  {isSuperAdmin && (
                    <EllipsisMenuItem
                      icon={faCopy}
                      onClick={() =>
                        navigator.clipboard.writeText(`${data?.id}`).then(() =>
                          showSuccessNotification({
                            message: 'The ID was added to your clipboard.',
                          })
                        )
                      }
                    >
                      Copy ID
                    </EllipsisMenuItem>
                  )}
                  {data.outputFile ? (
                    <EllipsisMenuItem
                      icon={faDownload}
                      onClick={() => openFileUrl(data.outputFile)}
                    >
                      Download (output)
                    </EllipsisMenuItem>
                  ) : null}
                  {data.inputFile ? (
                    <EllipsisMenuItem
                      icon={faDownload}
                      onClick={() => openFileUrl(data.inputFile)}
                    >
                      Download (input)
                    </EllipsisMenuItem>
                  ) : null}
                </EllipsisMenu>
              );
            } else {
              return (
                <EllipsisMenu>
                  <EllipsisMenuItem
                    icon={faHistory}
                    onClick={() => retryTask(data?.automationId, data?.payload)}
                  >
                    Retry
                  </EllipsisMenuItem>
                  {data.outputFile ? (
                    <EllipsisMenuItem
                      icon={faDownload}
                      onClick={() => openFileUrl(data.outputFile)}
                    >
                      Download (output)
                    </EllipsisMenuItem>
                  ) : null}
                  {data.inputFile ? (
                    <EllipsisMenuItem
                      icon={faDownload}
                      onClick={() => openFileUrl(data.inputFile)}
                    >
                      Download (input)
                    </EllipsisMenuItem>
                  ) : null}
                  {isSuperAdmin && (
                    <EllipsisMenuItem
                      icon={faCopy}
                      onClick={() =>
                        navigator.clipboard.writeText(`${data?.id}`).then(() =>
                          showSuccessNotification({
                            message: 'The ID was added to your clipboard.',
                          })
                        )
                      }
                    >
                      Copy ID
                    </EllipsisMenuItem>
                  )}
                  {isSuperAdmin && (
                    <EllipsisMenuItem
                      icon={faCopy}
                      onClick={() => {
                        /*if (!data?.workflowId)
                          return showErrorNotification({
                            message: 'Missing workflow id',
                          });
                          */
                        navigator.clipboard
                          .writeText(`${data?.workflowId || data?.id}`)
                          .then(() =>
                            showSuccessNotification({
                              message:
                                'The Workflow ID was added to your clipboard.',
                            })
                          );
                      }}
                    >
                      Copy Workflow ID
                    </EllipsisMenuItem>
                  )}
                </EllipsisMenu>
              );
            }
          },
        },
      ].filter(hasValue),
    []
  );
};

export const TasksTable = ({ taskId }: { taskId?: string }) => {
  const [teamId] = useTeamId();

  const colDefs = useTasksTableColumnDefs({ hide: ['type'] });

  const tasks = useV2TransactionSubscription(
    (q, { teamId, taskId }) => {
      const tasks = q.tasks({
        order_by: [{ createdAt: 'desc' }],
        limit: 100,
        where: taskId
          ? {
              id: taskId
                ? {
                    _eq: taskId,
                  }
                : undefined,
            }
          : {
              tenantId: {
                _eq: teamId,
              },
              automation_id: {
                _is_null: false,
              },
              parentId: {
                _is_null: true,
              },
            },
      });
      return tasks.map((task) => {
        const childs =
          task
            .automationChildTasks({
              limit: 100,
              where: {
                type: {
                  _eq: 'job',
                },
              },
              order_by: [{ createdAt: 'asc' }],
            })
            ?.map((x) => ({
              id: x.id,
              title: formatTaskTitle(x.title || x.payload()?.date),
              status: x.status,
              type: x.type,
              message: x.message,
              workflowId: x.temporalWorkflowId,
              kind: 'task',
              automationId: task.automation?.id,
              payload: x.payload(),
              failed: x
                .jobChildTasks_aggregate({
                  where: {
                    type: {
                      _eq: 'action',
                    },
                    status2: {
                      _eq: 'failed',
                    },
                  },
                })
                .aggregate?.count(),
              success: x
                .jobChildTasks_aggregate({
                  where: {
                    type: {
                      _eq: 'action',
                    },
                    status2: {
                      _eq: 'completed',
                    },
                  },
                })
                .aggregate?.count(),
              childs: x
                .jobChildTasks({
                  limit: 100,
                  where: {
                    type: {
                      _eq: 'action',
                    },
                    status2: {
                      _eq: 'failed',
                    },
                  },
                  order_by: [{ createdAt: 'asc' }],
                })
                ?.map((x) => ({
                  id: x.id,

                  title: x.output?.type || x.payload()?.uniqueRef,
                  status: x.status,
                  type: x.type,
                  message: x.message,
                  workflowId: x.temporalWorkflowId,
                  kind: 'action',
                  automationId: task.automation?.id,
                  payload: x.payload(),
                  output: {
                    uniqueRef: x.output?.remoteId,
                    type: x.output?.type,
                  },
                })),
            })) || [];

        const status = childs.find(
          (x) => x.status === 'running' || x.status === 'starting'
        )
          ? 'Running'
          : childs.find((x) => x.status === 'fail')
          ? 'Partial fail'
          : toTitleCase(task.status || '');

        return {
          id: task.id,
          title: formatTaskTitle(task.title || task.payload()?.date),
          name: task.automation?.ttemplate?.name,
          automationId: task.automation?.id,
          workflowId: task.temporalWorkflowId,
          payload: task.payload(),
          target: [task.connection?.name, task.listing?.name]
            .filter((x) => x)
            .join(' - '),
          type: task.type,
          status,
          message: task.message,
          childs,
          success: 0,
          failed: 0,
        };
      });
    },
    {
      teamId,
      taskId,
    }
  );

  const detailCellRendererParams = (level: any) => ({
    detailGridOptions: {
      masterDetail: true,
      groupDefaultExpanded: 0,
      getRowId: (params: GetRowIdParams) => params.data.id,
      suppressCellFocus: true,
      pagination: false,
      animateRows: true,
      detailCellRendererParams: () =>
        detailCellRendererParams(typeof level === 'number' ? level + 1 : 2),
      rowStyle: { cursor: 'pointer' },
      detailRowHeight: 300,
      detailRowAutoHeight: true,
      columnDefs: [
        {
          field: ' ',
          maxWidth: (typeof level === 'number' ? level : 1) * 25,
          minWidth: (typeof level === 'number' ? level : 1) * 25,
        },
        ...colDefs,
        // { field: 'key', width: 150, cellStyle: { textAlign: 'right' } },
      ] as ColDef[],
      className: 'ag-line-exception-details',
      defaultColDef: {
        sortable: false,
      },
    } as GridOptions,
    getDetailRowData: async (params: GetDetailRowDataParams) => {
      /*if (params.data.type === 'job') {
        params.successCallback(
          await client.query((q) =>
            q.actions({ where: { jobTaskId: params.data.id } }).map((x) => ({
              id: x.id,
              status: x.status,
              message: x.type,
              type: x.type,
              kind: 'action',
              inputFile: x.inputFileId,
              outputFile: x.outputFileId,
            }))
          )
        );
      } else {*/
      if (params.data.childs) params.successCallback(params.data.childs);
      //}
    },
  });

  return (
    <Table
      detailRowAutoHeight
      animateRows
      isTopRounded={false}
      columnDefs={colDefs}
      rowData={tasks.data || []}
      getRowId={({ data }) => data.id}
      masterDetail
      detailCellRendererParams={detailCellRendererParams}
      tooltipShowDelay={0}
      tooltipHideDelay={3000}
      groupDefaultExpanded={1}
      defaultColDef={{
        resizable: false,
        suppressMovable: true,
      }}
    />
  );
};

const l = '0000-00-00'.length;
export function formatTaskTitle(title?: string | null, i?: number): string {
  if (title?.length === l && title?.split('-').length === 3) {
    if (i === 0) {
      return day(title).format('MMM D');
    }
    return day(title).format('MMM D, YYYY');
  } else if (title?.length === l * 2 + 3 && title.includes('...')) {
    return title.split('...').map(formatTaskTitle).join(' - ');
  }
  return '';
}

export const SuccessErrors = ({
  errors,
  successes,
  onErrorClick,
  onSuccessClick,
}: {
  successes: number;
  errors: number;
  onSuccessClick?: () => void;
  onErrorClick?: () => void;
}) => {
  if (successes < 1 && errors < 1) return null;

  return (
    <Group noWrap h='100%' align='center'>
      {successes > 0 && (
        <Tooltip withArrow withinPortal label={`Completed: ${successes}`}>
          <Badge
            color='green'
            onClick={onSuccessClick}
            leftSection={<FontAwesomeIcon icon={faCheck} />}
          >
            {successes}
          </Badge>
        </Tooltip>
      )}
      {errors > 0 && (
        <Tooltip withArrow withinPortal label={`Issues: ${errors}`}>
          <Badge
            color='red'
            onClick={onErrorClick}
            leftSection={<FontAwesomeIcon icon={faXmark} />}
          >
            {errors}
          </Badge>
        </Tooltip>
      )}
    </Group>
  );
};

const CellTooltip = ({ value }: ITooltipParams) => (
  <Paper shadow='md' radius='md' px='md' py={5} sx={{ maxWidth: 400 }}>
    <Text component='span' size='sm'>
      {value}
    </Text>
  </Paper>
);
