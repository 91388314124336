import { useEditAutomationContext } from '../../_context';
import { useTabEditorType } from '../../_hooks/useTabEditorType';
import {
  ChildMappingRow,
  MappingRow,
  MappingTableContext,
} from '../../_tables';
import { useModalChanges } from './useModalChanges';
import {
  AdditionalMappingCellParams,
  MappingEditorCell,
  MappingNameCell,
} from '@finalytic/data-ui';
import { Table } from '@finalytic/ui';
import { ColDef, GetRowIdParams, NewValueParams } from '@finalytic/ui-grid';
import { ensure } from '@finalytic/utils';
import { Box } from '@mantine/core';
import { useMemo } from 'react';

export const ModalTable = ({
  view,
  rowData,
  searchInput,
  modalChanges,
  settingKey,
}: {
  view: string;
  rowData: MappingRow[];
  searchInput?: string;
  modalChanges: ReturnType<typeof useModalChanges>;
  settingKey: string;
}) => {
  const { automation } = useEditAutomationContext();
  const { rightEditorType } = useTabEditorType(settingKey);

  const columnDefs = useMemo<ColDef[]>(() => {
    const cellParams = ensure<AdditionalMappingCellParams>({
      automationId: automation.id,
      loadDataInCell: false,
      settingKey: settingKey,
    });

    return [
      {
        field: 'name',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
        },
        sortable: false,
        flex: 1,
        sort: 'asc',
      },
      {
        field: 'value',
        flex: 1,
        resizable: false,
        sortable: false,
        onCellValueChanged: (params: NewValueParams<ChildMappingRow>) => {
          const key = `${params?.data?.target}`;
          modalChanges.add(key, params.data);
        },
        singleClickEdit: true,
        editable: true,

        cellEditor: MappingEditorCell,
        cellRenderer: MappingNameCell,
        cellRendererParams: cellParams,
        cellEditorParams: cellParams,

        suppressKeyboardEvent: (params) => {
          const key = params.event.code;

          const KEY_UP = 'ArrowUp';
          const KEY_DOWN = 'ArrowDown';
          const KEY_ENTER = 'Enter';

          if (rightEditorType === 'expression') {
            // return true (to suppress) if editing and user hit up/down keys
            const gridShouldDoNothing =
              params.editing &&
              (key === KEY_UP || key === KEY_DOWN || key === KEY_ENTER);
            return gridShouldDoNothing;
          }
          return false;
        },
      },
    ];
  }, [rightEditorType]);

  const filteredRowData = useMemo((): MappingRow[] => {
    if (!searchInput) return rowData;
    return rowData.filter((i) =>
      i?.name?.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [rowData, searchInput, view]);

  return (
    <Box sx={{ height: 350, maxHeight: '80vh' }} mb={20} mt={15}>
      <Table
        columnDefs={columnDefs}
        rowData={filteredRowData}
        pagination={false}
        getRowId={(params: GetRowIdParams<MappingRow>) => params.data?.target}
        context={ensure<MappingTableContext>({
          settingKey,
        })}
      />
    </Box>
  );
};
