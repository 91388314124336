import { useNotifiedV2Mutation } from './graphql-v2';
import { useTeam } from './team';
import { Mutation, setting_insert_input } from '@finalytic/graphql';

type ExtraMutation = <T>(q: Mutation) => T;

export type SettingMutationInput =
  | {
      type: 'upsert';
      setting: {
        id?: string;
        leftType?: string;
        rightType?: string;
        value?: string;
        target?: string;
        key?: string;
        lineClassification?: string;
        automationId?: string;
        listingId?: string;
        teamId: string;
        partner?: string;
        leftConnectionId?: string;
        rightConnectionId?: string;
        parentSettingId?: string;
      };
    }
  | {
      type: 'remove';
      settingId: string;
    };

type MutationArgs = SettingMutationInput & { extraMutation?: ExtraMutation };

export function useSettingByIdMutation(successMessage?: string) {
  const { loading, mutate } = useNotifiedV2Mutation(
    (q, args: MutationArgs) => {
      let ok = false;

      if (args.type === 'remove') {
        const res = q.delete_setting_by_pk({ id: args.settingId });

        ok = !!res?.id;
      }

      if (args.type === 'upsert') {
        const res = q.insert_setting_one({
          on_conflict: {
            constraint: 'setting_pkey',
            update_columns: [
              'value',
              'group',
              'leftType',
              'rightType',
              'lineClassification',
              'listingId',
              'target',
            ],
          },
          object: {
            id: args.setting.id,
            key: args.setting.key,
            leftType: args.setting.leftType,
            group: args.setting.leftType,
            rightType: args.setting.rightType,
            value: args.setting.value,
            target: args.setting.target,
            automationId: args.setting.automationId,
            tenant_id: args.setting.teamId,
            lineClassification: args.setting.lineClassification,
            listingId: args.setting.listingId,
            partner: args.setting.partner,
            leftConnectionId: args.setting.leftConnectionId,
            rightConnectionId: args.setting.rightConnectionId,
            parentSettingId: args.setting.parentSettingId,
          },
        });

        ok = !!res?.id;
      }

      if (args.extraMutation) {
        const id = args.extraMutation<string | undefined>(q);
        return {
          id,
          ok,
        };
      }

      return {
        ok,
      };
    },
    {
      successMessage: {
        message: successMessage || 'Updated setting successfully.',
      },
      invalidateQueryKeys: ['settings'],
    }
  );

  return {
    loading,
    mutate: (args: MutationArgs) =>
      mutate({
        args,
      }),
  };
}

export const useDisableListingMutation = (listingName?: string) => {
  const [{ finalyticConnectionId }] = useTeam();

  const { mutate, loading } = useNotifiedV2Mutation(
    (
      q,
      args:
        | { type: 'remove'; settingId: string }
        | {
            type: 'upsert';
            setting: setting_insert_input;
          }
    ) => {
      if (args.type === 'remove') {
        const res = q.delete_setting_by_pk({ id: args.settingId });

        return {
          ok: !!res.id,
        };
      } else {
        if (args.setting.id) {
          const res = q.update_setting_by_pk({
            pk_columns: { id: args.setting.id },
            _set: args.setting,
          });

          return {
            ok: !!res.id,
          };
        } else {
          const res = q.insert_setting_one({ object: args.setting });

          return {
            ok: !!res.id,
          };
        }
      }
    },
    {
      successMessage: {
        title: 'Updated settings',
        message: listingName
          ? `Successfully updated ${listingName}.`
          : 'Successfully updated listing.',
      },
    }
  );

  const toggle = ({
    settingId,
    listingId,
    teamId,
    automationId,
    rightConnectionId,
  }: {
    listingId: string;
    teamId: string;
    settingId: string | undefined;
    automationId: string;
    rightConnectionId: string;
  }) =>
    settingId
      ? mutate({
          args: {
            type: 'remove',
            settingId,
          },
        })
      : mutate({
          args: {
            type: 'upsert',
            setting: {
              id: settingId,
              listingId: listingId,
              key: 'exclude',
              value: 'true',
              target: listingId,
              leftType: 'finalytic.listing',
              group: 'finalytic.listing',
              rightType: 'schema.boolean',
              leftConnectionId: finalyticConnectionId,
              rightConnectionId,
              tenant_id: teamId,
              automationId,
            },
          },
        });

  return {
    loading,
    toggle,
  };
};
