import { useConnectionModalContext } from '../_context';
import { ConnectingContainer } from '../components/ConnectingContainer';
import {
  InputsByJSONSchema,
  InputsBySchema,
  Schema,
  SchemaInputsStateType,
} from '@finalytic/data-ui';
import { ActionButton, Button } from '@finalytic/ui';
import { Box, Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export const ConnectionParametersModalScreen = () => {
  const { app, setView, issue, connectApp, resetView } =
    useConnectionModalContext();

  const [isRedirected, setIsRedirected] = useState(false);

  const methods = useForm({ mode: 'onChange' });

  const formIsValid = ['link', 'oauth'].includes(issue?.action.type || '')
    ? isRedirected
    : methods.formState.isValid;

  const confirmParameters = (data: SchemaInputsStateType) => {
    connectApp({ input: data, sessionKey: issue?.state.sessionKey });
  };

  useEffect(() => {
    if (!issue) setView('list-view');

    if (['link', 'oauth'].includes(issue?.action.type || ''))
      setIsRedirected(false);
  }, [issue]);

  const redirect = () => {
    if (issue?.action.type === 'link')
      window.open(issue?.action.value as string);
    else window.location.href = issue?.action.value as string;
    setIsRedirected(true);
  };

  return (
    <ConnectingContainer
      icon={app?.iconRound}
      text={
        issue?.message && (
          <Text weight={500} sx={{ fontSize: 22, letterSpacing: '0.5px' }}>
            {issue.message}
          </Text>
        )
      }
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          minWidth: '100%',
          [`@media (min-width: ${theme.breakpoints.xs})`]: {
            minWidth: 380,
          },
        })}
      >
        {issue?.action.type === 'form' && issue.action.value.properties ? (
          <InputsByJSONSchema
            schema={issue?.action.value as any}
            methods={methods}
          />
        ) : issue?.action.type === 'form' ? (
          <InputsBySchema
            schema={issue.action.value as Schema}
            methods={methods}
          />
        ) : null}

        {['link', 'oauth'].includes(issue?.action.type || '') && (
          <ActionButton onClick={redirect} disabled={isRedirected}>
            {issue?.action.type === 'link'
              ? `Open to ${new URL(issue?.action.value as string).host} ...`
              : `Redirect to ${
                  new URL(issue?.action.value as string).host
                } ...`}
          </ActionButton>
        )}
      </Box>

      {/* Buttons */}
      <Stack
        sx={(theme) => ({
          width: '100%',
          margin: '10px auto 0',
          maxWidth: '100%',
          [`@media (min-width: ${theme.breakpoints.xs})`]: {
            maxWidth: 380,
          },
        })}
      >
        {issue?.action.type !== 'oauth' ? (
          <ActionButton
            onClick={methods.handleSubmit(confirmParameters)}
            disabled={!formIsValid}
            sx={{ height: 40 }}
          >
            Submit
          </ActionButton>
        ) : null}

        <Button onClick={resetView}>Cancel</Button>
      </Stack>
    </ConnectingContainer>
  );
};
