import { ReservationLinesType } from '../_hooks';
import { Table, WhiteTagCell } from '@finalytic/ui';
import {
  ColDef,
  GridSizeChangedEvent,
  ICellRendererParams,
} from '@finalytic/ui-grid';
import { formatCurrency, sum } from '@finalytic/utils';
import { useMemo } from 'react';

type ReservationPaymentsTableProps = {
  rowData: ReservationLinesType | undefined;
  currency: string | undefined;
};

const isInvoicable = (row: any) => row?.accountingType === 'invoice';
export const ReservationFinancialsTable = ({
  rowData = [],
  currency,
}: ReservationPaymentsTableProps) => {
  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'description',
      },
      {
        headerName: 'Accounting',
        field: 'settingsByType2',
        width: 150,
        maxWidth: 150,
        cellRenderer: ({ value }: ICellRendererParams) =>
          value?.length ? (
            <WhiteTagCell>{value[0].value}</WhiteTagCell>
          ) : undefined,
      },
      {
        width: 120,
        maxWidth: 120,
        headerName: 'Amount',
        field: 'centTotal',
        type: 'numericColumn',
        aggFunc: 'sum',
        cellStyle: ({ data, node }) => {
          if (node.footer) return {};
          if (isInvoicable(data)) return {} as any;
          return {
            textDecoration: 'line-through',
          };
        },
        valueFormatter: ({ value, context, node }) => {
          if (node?.footer)
            return formatCurrency(
              sum(
                rowData?.filter((x) => isInvoicable(x)),
                'centTotal'
              ) / 100,
              context?.currency
            );
          return formatCurrency(value / 100, context?.currency);
        },
      },
      // {
      //   field: 'Icon',
      //   sortable: false,
      //   width: 60,
      //   headerName: '',
      //   headerTooltip: 'tool',
      //   cellRenderer: ({ data }: ICellRendererParams) => (
      //     <InfoButtonCell onIconClick={() => navigate(data.paymentId)} />
      //   ),
      //   suppressSizeToFit: true,
      // },
    ],
    [rowData]
  );

  const gridSizeChanged = ({ api }: GridSizeChangedEvent) => {
    api.sizeColumnsToFit();
  };

  return (
    <Table
      animateRows
      columnDefs={columnDefs}
      getRowId={({ data }) => data?.id}
      rowData={rowData}
      groupIncludeTotalFooter
      gridOptions={{
        autoGroupColumnDef: {
          minWidth: 200,
          headerName: 'Guest Name',
        },
        groupDefaultExpanded: 1,
      }}
      defaultColDef={{
        resizable: false,
        suppressMovable: true,
        sortable: false,
      }}
      getRowStyle={(params) => {
        if (!params.data && !params.node.footer) {
          return { cursor: 'pointer' };
        }

        if (params.node.footer) {
          return {
            fontWeight: 'bold',
          } as any;
        }
      }}
      context={{
        currency,
      }}
      onGridSizeChanged={gridSizeChanged}
      pagination={false}
    />
  );
};
