import { FormInputBaseType } from './_base-input-types';
import { StatementSelectFilter } from '@finalytic/ui';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export const MonthSelect = ({
  label,
  formKey,
  defaultValue,
  onValueChange,
}: FormInputBaseType) => {
  const { control, setValue } = useFormContext();

  const previousYearMonths = 15 - dayjs().get('month') + 1;

  const dates =
    Array(12 + previousYearMonths)
      .fill(new Date())
      .map((i: Date, index) => {
        const d = dayjs(i).add(6 - index, 'months');
        return {
          label: d.format('MMMM YYYY'),
          value: `${d.format('YYYY-MM')}-01`,
          group: `${d.format('YYYY')}`, // Add space at the front to prevent incorrect group sorting
        };
      }) || [];

  useEffect(() => {
    setValue(formKey, defaultValue);
  }, [defaultValue]);

  console.log(dates);

  return (
    <Controller
      control={control}
      name={formKey}
      defaultValue={defaultValue || dayjs().format('YYYY-MM-01')}
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => (
        <StatementSelectFilter
          sort={false}
          data={dates}
          value={value}
          onChange={(val) => {
            onValueChange?.(formKey, val);
            onChange(val);
          }}
          label={label}
          sx={{ width: '100%', height: 35 }}
        />
      )}
    />
  );
};
