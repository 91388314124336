import { StatusPillType } from './_types';
import { MantineNumberSize, Text } from '@mantine/core';

type StatusPillProps = {
  type: StatusPillType;
  label: string;
  textSize?: MantineNumberSize;
};

export const StatusPill = ({
  type,
  label,
  textSize = 'xs',
}: StatusPillProps) => {
  if (!type) return null;

  let styles: { backgroundColor: string; color: string } | undefined;

  if (type === 'green')
    styles = { backgroundColor: '#D4F7DC', color: '#15692A' };

  if (type === 'red') styles = { backgroundColor: '#FFE0ED', color: '#9F4167' };

  if (type === 'yellow')
    styles = { backgroundColor: '#FFEBCC', color: '#804C00' };

  return (
    <Text
      component='span'
      sx={{ ...styles, borderRadius: 6 }}
      px={6}
      py={2}
      size={textSize}
      weight={500}
      transform='capitalize'
    >
      {label}
    </Text>
  );
};
