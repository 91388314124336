import { useTeam, useV2TransactionQuery } from '../../../hooks';
import { formatOwnerName } from '../../../utils';
import { ListingStatementRow } from './_components';
import { getSourceDescription } from '@finalytic/common';

export type ListingOwnerRow = ReturnType<
  typeof useListingDetailsData
>['ownerships'][number];

export function useListingDetailsData(listingId?: string | null) {
  const [team] = useTeam();

  const automationIds = (team?.automations || []).map((a) => a.automationId);

  const {
    data,
    isLoading: loading,
    refetch,
  } = useV2TransactionQuery(
    (q, { listingId: id, automationIds }) => {
      const listingId = id!;
      const listing = q
        .listings({ where: { id: { _eq: listingId } } })
        .map((listing) => ({
          id: listing.id,
          name: listing.name,
          status: listing.status || 'enabled',
          title: listing.title,
          address: listing.address,
          tenantId: listing.tenantId,
          connection:
            listing
              .connections({ order_by: [{ name: 'asc' }] })
              .map((conn) => ({
                id: conn.id,
                name: conn.name,
                uniqueRef: conn.uniqueRef,
                iconRound: conn.connection.app.iconRound,
              })) || [],
          pmsConnectionId: listing.connectionId,
          ownerships: listing
            .ownerships({ order_by: [{ owner: { firstName: 'asc' } }] })
            .map((ownership) => ({
              id: ownership.id,
              ownershipId: ownership.id,
              ownerId: ownership.owner?.id,
              firstName: ownership.owner?.firstName,
              lastName: ownership.owner?.lastName,
              email: ownership.owner?.email,
              companyName: ownership.owner?.companyName,
              vendor: ownership
                .settingsLeft({ where: { key: { _eq: 'vendor' } }, limit: 1 })
                .map((setting) => ({
                  label:
                    setting.rightSource?.description ||
                    setting.rightSource?.remoteId ||
                    '',
                  value: setting.value || '',
                  settingId: setting.id,
                }))[0],
              ownerSplit: ownership.split,
            })),
          statements: listing
            .ownerStatements({ order_by: [{ startAt: 'desc' }] })
            .map<ListingStatementRow>((statement) => ({
              id: statement.id,
              listingId,
              month: statement.startAt,
              ownerName:
                statement.listing
                  ?.ownerships({})
                  .map((ship) =>
                    formatOwnerName({
                      firstName: ship.owner.firstName || '',
                      companyName: ship.owner.companyName || '',
                      lastName: ship.owner.lastName || '',
                    })
                  )
                  .join(', ') || '',
            })),
        }))[0];

      return {
        listing,
      };
    },
    {
      queryKey: 'listings',
      variables: { listingId, automationIds },
      skip: !listingId,
    }
  );

  const {
    data: data2,
    refetch: refetch2,
    isLoading: loading2,
  } = useV2TransactionQuery(
    (q, args) => {
      const listingTenantId = args.listingTenantId;

      const vendors = listingTenantId
        ? q
            .source({
              where: {
                tenantId: { _eq: listingTenantId },
                type: { _eq: 'vendor' },
              },
              order_by: [{ id: 'asc' }],
            })
            .map((item) => ({
              label: getSourceDescription(item) || item.remoteId || '',
              value: item.id || '',
            })) || []
        : [];

      return {
        vendors,
      };
    },
    {
      queryKey: ['settings', 'sources', 'settings', 'teams'],
      variables: {
        listingTenantId: data?.listing?.tenantId,
        listingId,
      },
      skip: !listingId || !data?.listing.id,
    }
  );

  const listing = data?.listing;

  const vendors = data2?.vendors || [];

  return {
    listing,
    ownerships: data?.listing.ownerships || [],
    vendors,
    loading: loading || loading2,
    refetch: () => {
      refetch();
      refetch2();
    },
  };
}
