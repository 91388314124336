import { SignInForm } from '../components/SignInForm';
import { useSignInForm } from '../hooks/useSignInForm';
import {
  OnboardingLayout,
  SidebarCard,
  SidebarContentContainer,
} from '@finalytic/onboarding';
import { ActionButton, IconButton } from '@finalytic/ui';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Group,
  Text,
  Transition,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ReactNode } from 'react';
import { useLocation } from 'react-router';

function useIsMobile() {
  const theme = useMantineTheme();

  return useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
}

export const SignInView = () => {
  const formProps = useSignInForm();

  const { primaryColor: appName } = useMantineTheme();

  const location = useLocation();

  const isMobile = useIsMobile();

  const title = formProps.showingSignUp
    ? `Sign up to ${appName}`
    : `Sign in to ${appName}`;

  const showVerification = location.pathname === '/verification';

  return (
    <OnboardingLayout
      title={title}
      sidebar={<SidebarSignIn />}
      sidebarPaddingTop={isMobile ? '5vh' : '23vh'}
      handleLogout={undefined}
      setTeam={() => null}
      teams={[]}
      team={undefined}
    >
      <FadeIn mounted={formProps.showingSignUp || showVerification}>
        <IconButton
          onClick={formProps.returnToDefault}
          sx={{ position: 'absolute', left: 15, top: 15 }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </IconButton>
      </FadeIn>

      {showVerification ? (
        <Confirmation
          handleResendConfirmation={formProps.handleResendVerification}
          loading={formProps.loading}
        />
      ) : (
        <Box mt={40}>
          <SignInForm {...formProps} />
        </Box>
      )}
    </OnboardingLayout>
  );
};

const FadeIn = ({
  children,
  mounted,
  ...props
}: {
  mounted: boolean;
  children: ReactNode;
  duration?: number;
  exitDuration?: number;
}) => {
  return (
    <Transition
      mounted={mounted}
      transition='fade'
      exitDuration={props.exitDuration}
      duration={props.duration}
    >
      {(style) => <div style={style}>{children}</div>}
    </Transition>
  );
};
export const StoryboardSignInView = (
  props: ReturnType<typeof useSignInForm>
) => {
  const isMobile = useIsMobile();

  const location = useLocation();

  console.log(location);

  return (
    <OnboardingLayout
      title={'Sign in to your account'}
      sidebar={<SidebarSignIn />}
      sidebarPaddingTop={isMobile ? '5vh' : '18vh'}
      handleLogout={undefined}
      setTeam={() => null}
      teams={[]}
      team={undefined}
    >
      <Box mt={70}>
        <SignInForm {...props} />
      </Box>
    </OnboardingLayout>
  );
};

const Confirmation = ({
  handleResendConfirmation,
  loading,
}: { handleResendConfirmation: () => Promise<void>; loading: boolean }) => {
  return (
    <Box maw={'470px'} mx='auto'>
      <Text component='p' align='center' mt='7vh' mb='8vh'>
        Please check your email for a confirmation link. If you didn't receive
        the email, we will gladly send you another.
      </Text>
      <ActionButton
        loading={loading}
        onClick={() => handleResendConfirmation()}
        fullWidth
      >
        Resend confirmation email
      </ActionButton>
    </Box>
  );
};

const reviews: ReviewCardProps[] = [
  {
    text: '“The integration is absolutely time saving.”',
    icon: 'https://imagedelivery.net/Ftdv9WftbW1DctaiLSVfFA/7e672d6c-8f0f-4cbf-6d86-4a55e0ad8400/smol2',
    jobTitle: 'Premium Beach Condos',
    name: '',
  },
  {
    text: '“Using VRPlatform has let to cleaner, and more understandable owner statements.”',
    icon: 'https://imagedelivery.net/Ftdv9WftbW1DctaiLSVfFA/5a11a8c3-57c6-4863-ea29-a5a974e15600/smol2',
    jobTitle: 'PMI Blue Reach',
    name: '',
  },
];

const SidebarSignIn = () => {
  const isMobile = useIsMobile();

  return (
    <SidebarContentContainer
      title='The Finance Tool for Short-term Managers'
      subtitle='Stop wrestling with CSVs, logging into different OTAs, and manually cleaning financial data. Start automating reconciliation, owner statements and payments to move your business forward.'
      spacing={50}
    >
      <Group
        noWrap={isMobile ? false : true}
        sx={{ flexDirection: isMobile ? 'column' : 'row' }}
        align={'stretch'}
      >
        {reviews.map((review, index) => (
          <ReviewCard {...review} key={index} />
        ))}
      </Group>
    </SidebarContentContainer>
  );
};

type ReviewCardProps = {
  text: string;
  icon: string | undefined;
  name: string;
  jobTitle: string;
};

const ReviewCard = ({ text, icon, jobTitle, name }: ReviewCardProps) => {
  return (
    <SidebarCard
      p='md'
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      // mih={235}
    >
      <Text size='sm' component='p' m={0} mb='sm'>
        {text}
      </Text>
      <Group noWrap>
        <Avatar size='md' radius={100} src={icon} />
        <Box>
          <Text component='p' m={0} size={12}>
            {name}
          </Text>
          <Text component='p' m={0} size={12}>
            {jobTitle}
          </Text>
        </Box>
      </Group>
    </SidebarCard>
  );
};
