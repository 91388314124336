import { ensure, gqlV2, usePartnerTeams, useTeamId } from '../..';
import { ListingFilterParams } from './_types';
import { useDebouncedValue } from '@mantine/hooks';
import { useMemo } from 'react';

export function useWhereListings({
  search,
  teamId,
  status,
  disabledByAutomationId,
}: ListingFilterParams & { startRow?: number; endRow?: number }) {
  const [currentTeamId] = useTeamId();
  const [teams] = usePartnerTeams();

  const searchQuery = useMemo(() => {
    if (search) {
      const trimmedSearch = search.trim();

      return ensure<gqlV2.listing_bool_exp[]>([
        {
          address: { _ilike: `%${trimmedSearch}%` },
        },
        {
          uniqueRef: { _ilike: `%${trimmedSearch}%` },
        },
        {
          name: { _ilike: `%${trimmedSearch}%` },
        },
        {
          title: { _ilike: `%${trimmedSearch}%` },
        },
        {
          ownerships: {
            owner: {
              _or: [
                { firstName: { _ilike: `%${trimmedSearch}%` } },
                { lastName: { _ilike: `%${trimmedSearch}%` } },
                { companyName: { _ilike: `%${trimmedSearch}%` } },
              ],
            },
          },
        },
      ]);
    }
    return undefined;
  }, [search]);

  const [debouncedSearchQuery] = useDebouncedValue(searchQuery, 500);

  return ensure<gqlV2.listing_bool_exp>({
    tenantId:
      teams.length > 0
        ? teamId
          ? { _eq: teamId }
          : { _in: teams.map((i) => i.id) }
        : { _eq: currentTeamId },
    // status: status && status.length > 0 ? { _in: status } : undefined,
    status: status === 'disabled' ? { _eq: status } : { _neq: 'disabled' },
    _not: disabledByAutomationId
      ? {
          settingsRight: {
            key: { _eq: 'exclude' },
            automationId: { _eq: disabledByAutomationId },
            leftType: { _eq: 'finalytic.listing' },
            rightType: { _eq: 'schema.boolean' },
          },
        }
      : undefined,

    _or: debouncedSearchQuery,
  });
}
