import { ExtendedCustomColors } from '../styles';
import { formatCurrency as format } from '@finalytic/utils';

type FormatCurrency = (
  a: string | number,
  currency?: string,
  theme?: ExtendedCustomColors
) => string;

export const formatCurrency: FormatCurrency = (
  a,
  currency,
  theme = 'Finalytic'
) => format(a, currency ? currency : theme === 'Finalytic' ? undefined : 'USD');
