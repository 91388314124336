import {
  CheckInput,
  CurrencyInput,
  DateInput,
  MonthSelect,
  NumberInput,
  Select,
  SelectData,
  TextInput,
} from './_components';
import { FormInputValueChangeType } from './_components/_base-input-types';
import { Schema } from './_types';
import { ValueOf } from '@finalytic/utils';
import { Box } from '@mantine/core';
import { useMemo } from 'react';

export const InputByType = ({
  objectKey,
  value,
  onInputValueChange,
}: {
  objectKey: string;
  value: ValueOf<Schema>;
  onInputValueChange?: FormInputValueChangeType;
}) => {
  const inputByType = useMemo(() => {
    switch (value.type) {
      case 'month':
        return (
          <MonthSelect
            defaultValue={value.defaultValue}
            formKey={objectKey}
            onValueChange={onInputValueChange}
            disabled={value.disabled}
            label={value.label}
            placeholder={value.placeholder}
            required={!value.optional}
          />
        );
      case 'date':
        return (
          <DateInput
            formKey={objectKey}
            label={value.label}
            defaultValue={value.defaultValue}
            disabled={value.disabled}
            required={!value.optional}
            placeholder={value.placeholder}
            onValueChange={onInputValueChange}
          />
        );
      case 'text':
      case 'string':
      case 'email':
      case 'password':
        return (
          <TextInput
            formKey={objectKey}
            inputType={
              ['text', 'string'].includes(value.type) ? 'text' : value.type
            }
            label={value.label}
            defaultValue={value.defaultValue}
            disabled={value.disabled}
            required={!value.optional}
            placeholder={value.placeholder}
            onValueChange={onInputValueChange}
          />
        );
      case 'boolean':
        return (
          <CheckInput
            formKey={objectKey}
            label={value.label}
            defaultValue={value.defaultValue}
            disabled={value.disabled}
            onValueChange={onInputValueChange}
            placeholder={value.placeholder}
            required={!value.optional}
          />
        );

      case 'number':
        return (
          <NumberInput
            formKey={objectKey}
            label={value.label}
            defaultValue={value.defaultValue}
            disabled={value.disabled}
            required={!value.optional}
            placeholder={value.placeholder}
            onValueChange={onInputValueChange}
          />
        );

      case 'currency':
        return (
          <CurrencyInput
            formKey={objectKey}
            label={value.label}
            defaultValue={value.defaultValue}
            disabled={value.disabled}
            required={!value.optional}
            placeholder={value.placeholder}
            currency={value.currency}
            onValueChange={onInputValueChange}
          />
        );
      case 'select':
        return (
          <Select
            options={value.options}
            label={value.label}
            defaultValue={value.defaultValue}
            formKey={objectKey}
            placeholder={value.placeholder}
            withSearch={value.withSearch}
            required={!value.optional}
            disabled={value.disabled}
            onValueChange={onInputValueChange}
          />
        );
      case 'select-data':
        return (
          <SelectData
            disabled={value.disabled}
            label={value.label}
            defaultValue={value.defaultValue}
            formKey={objectKey}
            placeholder={value.placeholder}
            required={!value.optional}
            sourceType={value.sourceType}
            connectionId={value.connectionId}
            onValueChange={onInputValueChange}
          />
        );

      case 'info':
        return <Box>{value.children}</Box>;

      default:
        return null;
    }
  }, [objectKey, value]);

  return inputByType;
};
