import { useMagicLink, useSignIn, useSignUp } from '@clerk/clerk-react';
import { OAuthStrategy } from '@clerk/types';
import { Schema } from '@finalytic/data-ui';
import { showErrorNotification, showSuccessNotification } from '@finalytic/ui';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
type FormInputs = { [key: string]: string };

export const loginSchema: Schema = {
  email: { type: 'email', label: 'Email' },
  password: { type: 'password', label: 'Password' },
};

export const signupSchema: Schema = {
  firstName: { type: 'text', label: 'First Name' },
  lastName: { type: 'text', label: 'Last Name' },
  email: { type: 'email', label: 'Email' },
  password: { type: 'password', label: 'Password' },
};

export function useSignInForm() {
  const { signIn, setActive: setSignInActive } = useSignIn();
  const { signUp, setActive } = useSignUp();

  const { startMagicLinkFlow } = useMagicLink(signIn!);

  const location = useLocation();
  const goto = useNavigate();

  const [loading, setLoading] = useState(false);

  const methods = useForm<FormInputs>({ mode: 'onChange' });

  // Handle sign up / new user flow
  const submitSignUp = async (formData?: FormInputs) => {
    setLoading(true);

    try {
      const data = formData || methods.getValues();

      if (!data?.email) throw new Error('Missing email address.');

      const result = await signUp?.create({
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.email,
        password: data.password,
        unsafeMetadata: {
          onboardingV2: true,
        },
      });

      if (result?.status === 'complete') {
        setActive!({ session: result.createdSessionId });
      } else {
        const res = await signUp?.prepareEmailAddressVerification({
          redirectUrl: `${window.location.origin}/?onboardingV2=true`,
          strategy: 'email_link',
        });

        if (res?.id) {
          goto('/verification');
          showSuccessNotification({
            title: 'Success!',
            message: 'Please check your email to verify your account.',
          });
        }
      }
    } catch (err: any) {
      console.error(err);
      const errorMessage = err.errors?.[0]?.longMessage || err?.message || err;
      console.error('error', errorMessage);
      showErrorNotification({
        title: formData
          ? "We couldn't sign you up."
          : "We couldn't resend your email verification.",
        message: errorMessage,
      });
    }

    setLoading(false);
  };

  // Handle email with password flow
  const submitLogin = async (data: FormInputs) => {
    if (!data.password) {
      showErrorNotification({ message: 'Please enter a password.' });
      return;
    }

    setLoading(true);

    if (!signIn) return console.error('no sign in');

    await signIn
      .create({
        identifier: data.email,
        password: data.password,
      })
      .then((result) => {
        if (result.status === 'complete') {
          setSignInActive({ session: result.createdSessionId });
          window.analytics?.track('User Signed In', {
            firstName: result.userData.firstName,
            lastName: result.userData.lastName,
            identifier: result.identifier,
          });
          goto(`/${location.search}`);
        }
        // else {
        //   console.log(result);
        // }
      })
      .catch((err) => {
        const errorMessage = err.errors[0].longMessage;
        console.error('error', errorMessage);
        showErrorNotification({
          title: "We couldn't sign you up.",
          message: errorMessage,
        });
      });

    setLoading(false);
  };
  const showingSignUp = location.pathname === '/sign-up';

  const schema = showingSignUp ? signupSchema : loginSchema;

  const handleSubmit = (data: FormInputs) =>
    showingSignUp ? submitSignUp(data) : submitLogin(data);

  const redirectTo = (view: 'login' | 'sign-up') => goto(`/${view}`);

  const returnToDefault = () => {
    methods.reset(undefined, { keepValues: true });
    setLoading(false);
    redirectTo('login');
  };

  const signInWithSocials = async (strategy: OAuthStrategy) => {
    try {
      window.analytics?.track('User Signing In With Socials', {
        strategy,
      });
      await signIn?.authenticateWithRedirect({
        strategy,
        redirectUrl: '/sso-callback',
        redirectUrlComplete: '/',
      });
    } catch (error: any) {
      console.log(error);
      showErrorNotification({
        message: error?.message || 'An error occurred',
      });
    }
  };

  const sendMagicLink = async (email: string) => {
    if (!signUp) {
      showErrorNotification({
        title: 'An error occurred',
        message: 'Missing Sign Up Function. Please refresh the browser tab.',
      });
      return {
        ok: false,
        errorMessage:
          'Missing Sign Up Function. Please refresh the browser tab.',
      };
    }
    window.analytics?.track('User Sending Magic Link', {
      email,
    });

    const si = await signIn!.create({ identifier: email });
    const factors = si!.supportedFirstFactors.find(
      (ff) => ff.strategy === 'email_link' && ff.safeIdentifier === email
    );
    // Start the magic link flow.
    // Pass your app URL that users will be navigated
    // when they click the magic link from their
    // email inbox.
    // su will hold the updated sign up object.

    showSuccessNotification({
      title: 'Email sent!',
      message:
        'You will shortly receive a magic link to log in to your dashboard. You can reset your password there!',
    });

    const res = await startMagicLinkFlow({
      emailAddressId: (factors as any)?.emailAddressId,
      redirectUrl: `https://${window.location.host}/verification`,
    });

    const verification = res.firstFactorVerification;

    if (verification.verifiedFromTheSameClient()) {
      showSuccessNotification({ message: "You're already signed in!" });
    } else if (verification.status === 'expired') {
      showErrorNotification({ message: 'Your session is expired' });
    }

    if (res.status === 'complete') {
      setSignInActive!({ session: res.createdSessionId });
    }

    return {
      ok: true,
    };
  };

  return {
    handleSubmit,
    returnToDefault,
    schema,
    loading,
    methods,
    showingSignUp,
    signInWithSocials,
    sendMagicLink,
    handleResendVerification: submitSignUp,
  };
}
