import { IconButton } from '../../../components/buttons';
import { useColors } from '../../../styles';
import { LoadingIndicator } from '../../loading-indicator';
import { Input } from '../input';
import { AddressType } from './_types';
import { faCheck, faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Group, Popover, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { ReactNode, useEffect, useMemo } from 'react';

export interface InputAddressProps {
  onSubmit: (address?: AddressType) => void;
  address?: AddressType;
  placeholder?: string;
  loading?: boolean;
}

export const InputAddress = ({
  address,
  onSubmit,
  placeholder = 'Enter address',
  loading,
}: InputAddressProps) => {
  const { purple } = useColors();

  const [opened, handlers] = useDisclosure(false);

  const emptyState: AddressType = {
    city: '',
    line1: '',
    postcode: '',
    country: '',
  };

  const form = useForm<AddressType>({
    initialValues: address || emptyState,
  });

  useEffect(() => form.setValues({ ...(address || {}) }), [address]);

  const submit = () => {
    if (!Object.values(form.values).some((i) => i.length < 1)) {
      onSubmit(form.values);
      form.setErrors({
        city: undefined,
        country: undefined,
        line1: undefined,
        postcode: undefined,
      });
      handlers.close();
    } else {
      Object.entries(form.values).forEach(([key, val]) => {
        if (val.length < 1 || !val) {
          form.setFieldError(key as keyof AddressType, true);
        }
      });
    }
  };

  const reset = () => {
    onSubmit(address);

    if (address) {
      form.setValues({ ...(address || {}) });
    } else form.reset();
    handlers.close();
  };

  const { buttonLabel, hasAddress } = useMemo(() => {
    const buttonValues = Object.values(address || {});
    const hasAddress = buttonValues.filter(Boolean).length > 0;

    const buttonLabel = hasAddress
      ? `${address?.line1}, ${address?.city}, ${address?.postcode}, ${address?.country}`
      : placeholder;
    return {
      buttonLabel,
      hasAddress,
    };
  }, [address]);
  return (
    <>
      <Popover
        opened={opened}
        onClose={handlers.close}
        radius={10}
        position='top'
        withArrow
        width={300}
        shadow='md'
        styles={{
          dropdown: {
            paddingTop: 7,
          },
        }}
      >
        <Popover.Target>
          <Group
            position='apart'
            noWrap
            sx={{
              border: '1px solid #ced4da',
              padding: '10px 16px',
              borderRadius: 8,
              cursor: 'pointer',
            }}
            onClick={handlers.toggle}
          >
            <Text
              sx={{ fontSize: 13, color: hasAddress ? undefined : '#BEC0C9' }}
              weight={!hasAddress ? 500 : undefined}
            >
              {buttonLabel}
            </Text>
            {loading && <LoadingIndicator size='xs' mr={-5} />}
          </Group>
        </Popover.Target>
        <Popover.Dropdown>
          <Group position='apart' mb={10}>
            <Text size='sm' weight={500}>
              Enter Address
            </Text>
            <Group noWrap spacing={3}>
              <IconButton onClick={reset}>
                <FontAwesomeIcon icon={faRotateLeft} size='xs' />
              </IconButton>
              <IconButton onClick={submit}>
                <FontAwesomeIcon icon={faCheck} color={purple.base} />
              </IconButton>
            </Group>
          </Group>
          <Box component='form'>
            <Stack>
              <FormInput
                inputName='line1'
                placeholder='Street Name'
                data-autofocus
                error={form.errors.line1}
                {...form.getInputProps('line1')}
              />
              <FormInput
                inputName='city'
                placeholder='City'
                error={form.errors.city}
                {...form.getInputProps('city')}
              />
              <FormInput
                inputName='postcode'
                placeholder='Post Code'
                error={form.errors.postcode}
                {...form.getInputProps('postcode')}
              />
              <FormInput
                inputName='country'
                placeholder='Country'
                error={form.errors.country}
                {...form.getInputProps('country')}
              />
            </Stack>
          </Box>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

const FormInput = ({
  inputName,
  placeholder,
  error,
  ...props
}: {
  inputName: string;
  error: ReactNode;
  placeholder: string;
}) => {
  const { themeColors } = useColors();
  return (
    <Input
      inputName={inputName}
      placeholderText={placeholder}
      type='text'
      sx={{
        input: error ? { borderColor: themeColors.red[3] } : undefined,
      }}
      {...props}
    />
  );
};
