import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Stack, Sx } from '@mantine/core';

import { useColors } from '../../../styles';
import { FilterValueType } from '../_types';

type Props = {
  data: {
    label: string;
    value: string;
  }[];
  setFilterValue: (e: string) => void;
  filterValue: FilterValueType;
  sx?: Sx;
};

export const SegmentedControl = ({
  data,
  setFilterValue,
  filterValue,
  sx,
}: Props) => {
  const { purple } = useColors();

  if (!data) return null;

  return (
    <Box sx={{ maxHeight: 300, overflowY: 'scroll' }}>
      <Stack spacing={5}>
        {data.map((item, index) => {
          const isActive = item.value === filterValue;
          const isLast = index + 1 === data.length;
          return (
            <Button
              key={item.value}
              variant='subtle'
              onClick={() => setFilterValue(item.value)}
              rightIcon={
                isActive && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    size='sm'
                    color='rgb(131, 48, 213)'
                  />
                )
              }
              px={5}
              radius={10}
              sx={{
                ...sx,
              }}
              styles={{
                root: {
                  width: '100%',
                  maxWidth: 250,
                  height: 30,
                  position: 'relative',
                  transition: 'all 0.2s ease-out',
                  backgroundColor: isActive ? 'rgb(243, 234, 251)' : undefined,

                  '&:hover': {
                    backgroundColor: !isActive
                      ? 'transparent'
                      : 'rgb(243, 234, 251)',
                  },
                  '&::after': !isLast
                    ? {
                        content: '""',
                        position: 'absolute',
                        right: 0,
                        left: 0,
                        bottom: -4,
                        height: 1,
                        backgroundColor: '#5C617815',
                      }
                    : undefined,
                },
                label: {
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  fontWeight: 500,
                  lineHeight: 'normal',
                  color: isActive ? purple.base : '#5C6178',
                  fontSize: 13,
                  span: {
                    textAlign: 'left',
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflowY: 'hidden',
                  },
                },
              }}
            >
              <span>{item.label}</span>
            </Button>
          );
        })}
      </Stack>
    </Box>
  );
};
