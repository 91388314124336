import { LockIcon } from './_icons';
import { useCellSelectTypes } from './_select-options';
import { Select } from '@finalytic/ui';
import { ICellEditorParams, ICellRendererParams } from '@finalytic/ui-grid';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Group, ThemeIcon } from '@mantine/core';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

export const SelectColumnTypeEditorCell = forwardRef(
  ({ ...params }: ICellEditorParams, ref) => {
    const value: (string | undefined) | string[] = params?.value;

    const [singleSelectValue, setSingleSelectValue] = useState(value);
    const select_types = useCellSelectTypes();

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return singleSelectValue;
        },
      };
    });

    useEffect(() => {
      if (singleSelectValue !== value) {
        params.stopEditing();
      }
    }, [singleSelectValue]);

    return (
      <Box w='100%'>
        <Select
          data={select_types}
          setValue={(item) => setSingleSelectValue(item.value)}
          removeValue={() => params.stopEditing()}
          value={select_types.find((i) => i.value === params.value)}
          onClose={params.stopEditing}
          popoverWidth='target'
          preventClose
          defaultOpen
          withSearch
        />
      </Box>
    );
  }
);

SelectColumnTypeEditorCell.displayName = 'Select Column Type Editor Cell';

export const SelectColumnTypeCell = (params: ICellRendererParams) => {
  const select_types = useCellSelectTypes();

  return (
    <Group position={'apart'} noWrap sx={{ height: '100%', minHeight: 45 }}>
      {select_types.find((i) => i.value === params.value)?.label}
      <ThemeIcon color='dark' variant='outline' sx={{ border: 'none' }}>
        {params.data.locked ? (
          <LockIcon />
        ) : (
          <FontAwesomeIcon size='sm' icon={faChevronDown} />
        )}
      </ThemeIcon>
    </Group>
  );
};
