import { ConfirmModalResultIcon } from '../components';
import { useV2TransactionSubscription } from './graphql-v2';
import {
  Modal,
  TableCollapse,
  TableRow,
  TransparentButton,
  useColors,
} from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { faCheck, faCross } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Center, Table } from '@mantine/core';
import {
  NotificationProps,
  notifications,
  updateNotification,
} from '@mantine/notifications';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const isDone = (task: { status?: string | null }) =>
  ['success', 'fail', 'failed'].includes(task?.status || 'x');

const isSuccess = (task: { status?: string | null }) =>
  ['success'].includes(task?.status || 'x');

const isFail = (task: { status?: string | null }) =>
  ['fail', 'failed'].includes(task?.status || 'x');

export type TaskOnDone = (success: boolean) => void;
export type TasksContext = [
  string[],
  (id: string, onDone?: TaskOnDone) => void
];

export const tasksContext = createContext<TasksContext>([[], () => undefined]);
export function useTasksContext() {
  return useContext(tasksContext);
}

export function TasksProvider({ children }: { children: React.ReactNode }) {
  const [taskIds, setTaskIds] = useState<string[]>([]);
  const promises = useRef<{ [id: string]: TaskOnDone }>({});

  const value = useMemo(
    () => [
      taskIds,
      (id: string, onDone?: TaskOnDone) => {
        if (!id) {
          throw new Error('No id provided');
        }
        setTaskIds((ids) => [...ids, id]);
        if (onDone) promises.current[id] = onDone;
      },
    ],
    [taskIds]
  );

  return (
    <tasksContext.Provider value={value as any}>
      <Notifications
        promises={promises.current}
        taskIds={taskIds}
        setTaskIds={setTaskIds}
      />
      {children}
    </tasksContext.Provider>
  );
}

function Notifications({
  promises,
  taskIds,
  setTaskIds,
}: {
  promises: { [id: string]: TaskOnDone };
  taskIds: string[];
  setTaskIds: (fn: (ids: string[]) => string[]) => void;
}) {
  const { green, red, gray } = useColors();
  const [activeTaskId, setActiveId] = useState<string>();
  const tasks = useTasks(taskIds);
  const ref = useRef<{ [s: string]: NotificationProps & { id: string } }>({});

  useEffect(() => {
    for (const id of taskIds) {
      ref.current[id] = {
        id,
        title: 'Task started',
        message: 'Please wait ...',
        onClose: () => setTaskIds(() => taskIds.filter((tid) => tid !== id)),
        onClick: (e) => {
          const hasClass = (e: HTMLElement, className: string) =>
            typeof e?.className?.includes === 'function' &&
            e?.className?.includes(className);
          const isClose =
            hasClass(e.target as HTMLDivElement, 'closeButton') ||
            hasClass(
              (e.target as HTMLDivElement).parentElement as HTMLDivElement,
              'closeButton'
            );
          if (!isClose) setActiveId(() => id);
        },
        autoClose: false,
        color: 'orange',
        loading: true,
        radius: 10,
      };
      notifications.update(ref.current[id]);
    }
  }, [taskIds]);

  useEffect(() => {
    const rmIds: string[] = [];
    for (const taskId of taskIds) {
      const task = tasks.find((x) => x.id === taskId);
      if (!task) continue;
      if (taskId === activeTaskId) {
        ref.current[taskId] = {
          ...ref.current[taskId],
          hidden: true,
        };
      } else if (isDone(task)) {
        rmIds.push(taskId);
        ref.current[taskId] = {
          ...ref.current[taskId],
          hidden: false,
          title: toTitleCase(task.status || ''),
          message: task.message,
          autoClose: true,
          color: task.status === 'success' ? 'green' : 'red',
          loading: false,
          radius: 10,
        };
        if (promises[taskId]) {
          Promise.resolve(promises[taskId](isSuccess(task))).catch(
            (err) => err
          );
          delete promises[taskId];
        }
      } else {
        ref.current[taskId] = {
          ...ref.current[taskId],
          hidden: false,
          title: `${toTitleCase(task.status || '')}`,
          message: task.children.length
            ? `${task.children.filter(isDone).length} of ${
                task.children.length
              } done`
            : task.message,
          color: 'blue',
          loading: true,
          radius: 10,
        };
      }
      updateNotification({
        ...ref.current[taskId],
        id: taskId,
      });
    }
    if (rmIds.length) {
      setTaskIds((ids) => ids.filter((x) => !rmIds.includes(x)));
      if (activeTaskId && rmIds.includes(activeTaskId))
        setActiveId(() => undefined);
    }
  }, [tasks?.map((x) => x.message).join('|'), activeTaskId]);

  const activeTask = activeTaskId
    ? tasks.find((x) => x.id === activeTaskId)
    : undefined;

  return (
    <>
      {activeTask ? (
        <Modal
          opened
          onClose={() => setActiveId(() => undefined)}
          withCloseButton
          title='Task details'
        >
          <Box
            sx={{
              minHeight: 320,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <>
              {isDone(activeTask) ? (
                <Center mt={10}>
                  {isFail(activeTask) ? (
                    <ConfirmModalResultIcon color={red.base}>
                      <FontAwesomeIcon icon={faCross} color='#fff' size='lg' />
                    </ConfirmModalResultIcon>
                  ) : (
                    <ConfirmModalResultIcon color={green.base}>
                      <FontAwesomeIcon icon={faCheck} color='#fff' size='lg' />
                    </ConfirmModalResultIcon>
                  )}
                </Center>
              ) : null}
              <Box mb={10} sx={{ textAlign: 'center' }}>
                <TableCollapse
                  title={activeTask.message!}
                  table={
                    <Box
                      sx={{
                        paddingBottom: 3,
                      }}
                    >
                      <Table
                        sx={{
                          '& tr td:first-of-type': {
                            paddingLeft: '12px',
                          },
                          '& tr td:nth-of-type(2)': {
                            textAlign: 'right',
                            paddingRight: '20px',
                          },
                        }}
                      >
                        <tbody>
                          {activeTask.children.map((data, index) => (
                            <TableRow
                              key={data.id}
                              label={data.message!}
                              formattedAmount={data.status!}
                              isPrimary={index === 0}
                            />
                          ))}
                        </tbody>
                      </Table>
                    </Box>
                  }
                />
              </Box>
              <TransparentButton
                onClick={() => setActiveId(() => undefined)}
                fullWidth
                sx={{ color: gray.dark }}
              >
                Hide
              </TransparentButton>
            </>
          </Box>
        </Modal>
      ) : null}
    </>
  );
}

function useTasks(taskIds: string[]) {
  const { data } = useV2TransactionSubscription(
    (q, { taskIds }) =>
      q
        .tasks({
          where: {
            id: { _in: taskIds },
          },
          order_by: [{ createdAt: 'desc' }],
          limit: 1,
        })
        .map((task) => ({
          id: task.id,
          status: task.status,
          message: task.message,
          children: task
            .children({
              order_by: [{ createdAt: 'desc' }],
            })
            .map((child) => ({
              id: child.id,
              status: child.status,
              message: child.message,
            })),
        })),
    {
      taskIds,
    }
  );

  return data || [];
}
