import {
  SpotlightContext,
  spotlightContext,
  useMe,
  useSpotlightContext,
  useTeamId,
  useTeamRole,
} from '../../hooks';
import { useAuditDrawer } from '../drawer/audit-drawer/useAuditDrawer';
import { showErrorNotification, showSuccessNotification } from '@finalytic/ui';
import { faHome, faSearch, faTag } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClipboard, useSessionStorage } from '@mantine/hooks';
import { hideNotification, showNotification } from '@mantine/notifications';
import {
  SpotlightAction,
  SpotlightProvider as Provider,
} from '@mantine/spotlight';
import { ReactNode, useMemo, useRef, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';

type Ctx = {
  spotlight: SpotlightContext;
  navigate: NavigateFunction;
  openAuditDrawer: () => void;
  shareUrl: () => void;

  copyTeamId: () => void;
  teamId: string;

  copySentryUuid: () => void;
  sentryUuid: string | undefined;
};

type ActionFn = (ctx: Ctx) => SpotlightAction[];

const baseActions: ActionFn = ({ navigate, shareUrl }: Ctx) => [
  {
    group: 'Navigate',
    title: 'Home',
    description: 'Get to home page',
    onTrigger: () => navigate('/'),
    icon: <FontAwesomeIcon icon={faHome} />,
  },
  {
    group: 'Navigate',
    title: 'Share URL',
    description: 'Share your current view with a team member',
    onTrigger: shareUrl,
    icon: <FontAwesomeIcon icon={faHome} />,
  },
];
const partnerAdminActions: ActionFn = ({
  sentryUuid,
  copySentryUuid,
  copyTeamId,
  teamId,
}: Ctx) => {
  const actions: SpotlightAction[] = [];

  actions.push({
    title: 'Copy Team ID',
    group: 'Partner Actions',
    description: `${teamId}`,
    onTrigger: copyTeamId,
    icon: <FontAwesomeIcon icon={faTag} />,
  });

  actions.push({
    title: 'Copy Session ID',
    group: 'Partner Actions',
    description: sentryUuid,
    onTrigger: copySentryUuid,
    icon: <FontAwesomeIcon icon={faTag} />,
  });

  return actions;
};

const superAdminActions: ActionFn = ({ spotlight, openAuditDrawer }: Ctx) => {
  const actions: SpotlightAction[] = [];

  actions.push({
    title: 'Audit Drawer',
    group: 'Super Admin Actions',
    description: 'Open Audit Drawer',
    onTrigger: openAuditDrawer,
    icon: <FontAwesomeIcon icon={faTag} />,
  });

  actions.push({
    title: 'Hypervisor Queue',
    group: 'Queue',
    description: spotlight.hypervisorQueue
      ? 'Reset hypervisor queue'
      : 'Set hypervisor queue',
    onTrigger: () => {
      const input = prompt('Enter hypervisor queue');
      if (!input) {
        hideNotification('hypervisor-queue');
        return spotlight.set((state) => ({
          ...state,
          hypervisorQueue: undefined,
        }));
      }
      showNotification({
        id: 'hypervisor-queue',
        onClose: () =>
          spotlight.set((state) => ({ ...state, devQueues: false })),
        autoClose: false,
        title: 'Hypervisor Queue',
        message: input,
        color: 'orange',
      });
      spotlight.set({ ...spotlight, hypervisorQueue: input });
    },
    icon: <FontAwesomeIcon icon={faTag} />,
  });
  actions.push({
    title: 'Integration Queue',
    group: 'Queue',
    description: spotlight.integrationQueue
      ? 'Reset integration queue'
      : 'Set integration queue',
    onTrigger: () => {
      const input = prompt('Enter integration queue');
      if (!input) {
        hideNotification('integration-queue');
        return spotlight.set((state) => ({
          ...state,
          integrationQueue: undefined,
        }));
      }
      showNotification({
        id: 'integration-queue',
        onClose: () =>
          spotlight.set((state) => ({ ...state, devQueues: false })),
        autoClose: false,
        title: 'Integration Queue',
        message: input,
        color: 'orange',
      });
      spotlight.set({ ...spotlight, integrationQueue: input });
    },
    icon: <FontAwesomeIcon icon={faTag} />,
  });

  return actions;
};

export function SpotlightComponentProvider({
  children,
}: {
  children: ReactNode;
}) {
  const spotlight = useSpotlightContext();
  const navigate = useNavigate();
  const user = useMe();
  const { copy } = useClipboard();
  const { openWithFilter: openAuditDrawer } = useAuditDrawer();

  const [teamId] = useTeamId();
  const { isPartnerAdmin, isSuperAdmin } = useTeamRole();
  const copyTeamId = () => copy(teamId);

  const [replaySession] = useSessionStorage<{ id: string } | undefined>({
    key: 'sentryReplaySession',
  });
  const copySentryUuid = () => {
    if (replaySession?.id) {
      copy(replaySession.id);
      showSuccessNotification({
        title: 'Success!',
        message: 'Your session id was added to your clipboard.',
      });
    } else {
      showErrorNotification({
        message: 'Missing session id. Please try again.',
      });
    }
  };

  const shareUrl = () => {
    const n = new URL(window.location.href);
    n.searchParams.set('sti', teamId);
    copy(n.href);
    showSuccessNotification({
      title: 'Copied URL.',
      message: 'Successfully copied url.',
    });
  };

  const actions = useMemo(() => {
    const ctx: Ctx = {
      spotlight,
      navigate,
      openAuditDrawer,
      shareUrl,
      teamId,
      copyTeamId,
      sentryUuid: replaySession?.id,
      copySentryUuid,
    };
    if (isSuperAdmin || user.email === 'dev.ricardo.gomez@gmail.com') {
      return [
        ...baseActions(ctx),
        ...partnerAdminActions(ctx),
        ...superAdminActions(ctx),
      ];
    }
    if (isPartnerAdmin) {
      return [...baseActions(ctx), ...partnerAdminActions(ctx)];
    }
    return baseActions(ctx);
  }, [user?.id, spotlight]);
  return (
    <Provider
      actions={actions}
      searchIcon={<FontAwesomeIcon icon={faSearch} />}
      searchPlaceholder='Search...'
      shortcut={['mod + P', 'mod + K', '/']}
      nothingFoundMessage='Nothing found...'
      radius='md'
      closeOnActionTrigger
    >
      {children}
    </Provider>
  );
}

export function SpotlightConfigProvider({ children }: { children: ReactNode }) {
  const ref = useRef<SpotlightContext>({} as any);
  const [_state, setState] = useState<
    Omit<SpotlightContext, 'current' | 'set'>
  >({
    hypervisorQueue: undefined,
    integrationQueue: undefined,
  });
  const state = useMemo(() => {
    if (ref.current)
      for (const [key, value] of Object.entries(_state)) {
        (ref.current as any)[key] = value;
      }
    return { ..._state, current: ref.current!, set: setState };
  }, [_state]);

  return (
    <spotlightContext.Provider value={state}>
      {children}
    </spotlightContext.Provider>
  );
}
