import {
  FormattedStatement,
  NetRevenueColumn,
  Statement,
  StatementLine,
  StatementSummaryUnion,
} from './_types';
import { getExpenseSections } from './utils/get-expense-sections';
import { getNetRevenueRows } from './utils/get-net-revenue-row';
import { getNetRevenueTotalRow } from './utils/get-net-revenue-total-row';
import { getStatementInfo } from './utils/get-statement-info';
import {
  getSummaryRows,
  getSummaryTotals,
} from './utils/get-statement-summary';
import { getStatementTemplate } from './utils/get-statement-template';
import { ensure, hasValue, sortBy, utc } from '@finalytic/utils';

type StatementType = 'propertyManager' | 'owner';

type Props = {
  statements: Statement[];
  ownerId: string | undefined;
  groupedBy: StatementSummaryUnion;
  type: StatementType;
};

export const getFormattedStatement = ({
  statements,
  groupedBy,
  type,
  ownerId,
}: Props): FormattedStatement => {
  const template = getStatementTemplate(statements);
  const statementLines = statements.flatMap((st) => st.lines);

  // HEADER
  const { header, ownerSplit, footer } = getStatementInfo({
    ownerId,
    statements,
    groupedBy,
  });

  // NET REVENUE
  const netRevenueTemplate = template?.data.netRevenueSections[0];
  const netRevenueTitle = netRevenueTemplate?.title || '';
  const netRevenueTableColumns = netRevenueTemplate?.columns || [];
  const netRevenueTableAggregated = !!netRevenueTemplate?.aggregate;

  const visibleNetRevenueColumns = netRevenueTableColumns.filter((col) => {
    if (!col.visible) return false;

    if (groupedBy !== 'groupByReservation' && col.type === 'field')
      return false;

    const hiddenFromOwner = type === 'owner' ? !col.hideOnPortal : true;

    return hiddenFromOwner;
  });

  const netRevenueLines = getNetRevenueLines({
    lines: statementLines,
    columns: netRevenueTableColumns,
  });

  const netRevenueRows = sortBy(
    getNetRevenueRows({
      columns: netRevenueTableColumns,
      lines: netRevenueLines,
      groupedBy,
    }),
    (x) => (groupedBy === 'groupByMonth' ? utc(x.name).unix() : x.name || '')
  );

  const netRevenueTotalRow = getNetRevenueTotalRow({
    columns: netRevenueTableColumns,
    groupedBy,
    lines: netRevenueLines,
    rows: netRevenueRows,
  });

  // EXPENSES
  const expenseTemplateSections = template?.data.otherSections || [];
  const expenseSections = getExpenseSections({
    lines: statementLines,
    templateSections: expenseTemplateSections,
    groupedBy,
  });

  // SUMMARY
  const summaryRows = getSummaryRows({ statements, groupedBy, ownerSplit });

  const summaryTotals = getSummaryTotals({
    rows: summaryRows,
    groupedBy,
    currency: statements[0]?.currency,
    statements,
    ownerSplit,
  });

  const showSummaryTable = ensure<StatementSummaryUnion[]>([
    'groupByListing',
    'groupByMonth',
  ]).includes(groupedBy);

  const summary = {
    rows: summaryRows,
    totals: summaryTotals,
    showSummaryTable,
  };

  const statementTemplate = {
    version: template?.version || 1,
    billingAccountId: template?.billingAccountId || undefined,
  };

  return {
    header,
    template: statementTemplate,
    netRevenue: {
      title: netRevenueTitle,
      rows: netRevenueTableAggregated
        ? netRevenueTotalRow
          ? [netRevenueTotalRow]
          : []
        : netRevenueRows,
      totals: netRevenueTotalRow,
      tableColumns: visibleNetRevenueColumns,
      aggregated: netRevenueTableAggregated,
    },
    expenses: {
      sections: expenseSections,
    },
    summary,
    footer,
  };
};

const getNetRevenueLines = ({
  lines,
  columns,
}: {
  lines: StatementLine[];
  columns: NetRevenueColumn[];
}): StatementLine[] => {
  const netRevAccounts = columns
    .flatMap((c) => (c.type === 'sumAccounts' ? c.value : undefined))
    .filter(hasValue);

  return lines.filter(
    (line) =>
      netRevAccounts.includes(line?.group?.remoteId || '') &&
      !line.isOwnerPayout
  );
};
