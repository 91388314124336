export const WorkflowIcon = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_2218_65430)'>
      <path
        d='M19.6698 3.51846C19.6698 3.29868 19.5969 3.1154 19.4501 2.96888L17.031 0.549786C16.8845 0.403137 16.7013 0.329834 16.4813 0.329834C16.2612 0.329834 16.0781 0.403137 15.9315 0.549786L0.219952 16.2613C0.073132 16.408 0 16.5914 0 16.8113C0 17.0313 0.073132 17.2143 0.219952 17.361L2.63896 19.78C2.78578 19.9267 2.9688 20 3.18884 20C3.40866 20 3.5919 19.927 3.73859 19.78L19.4503 4.06834C19.5969 3.92156 19.6698 3.7385 19.6698 3.51846ZM14.2087 7.09825L12.9015 5.79094L16.4811 2.2112L17.7883 3.51846L14.2087 7.09825Z'
        fill='white'
      />
      <path
        d='M2.79793 3.12752L3.16441 1.93027L4.36161 1.5638L3.16441 1.19724L2.79793 0L2.43137 1.19724L1.23413 1.5638L2.43137 1.93027L2.79793 3.12752Z'
        fill='white'
      />
      <path
        d='M5.9742 4.64267L6.70736 7.03737L7.44052 4.64267L9.83514 3.90951L7.44052 3.17644L6.70736 0.781738L5.9742 3.17644L3.57959 3.90951L5.9742 4.64267Z'
        fill='white'
      />
      <path
        d='M18.8027 9.01629L18.4363 7.81909L18.0699 9.01629L16.8726 9.38281L18.0699 9.74932L18.4363 10.9464L18.8027 9.74932L20.0001 9.38281L18.8027 9.01629Z'
        fill='white'
      />
      <path
        d='M10.6169 3.12752L10.9835 1.93027L12.1807 1.5638L10.9835 1.19724L10.6169 0L10.2506 1.19724L9.05322 1.5638L10.2506 1.93027L10.6169 3.12752Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_2218_65430'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
