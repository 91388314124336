import { getSourceDescription } from '@finalytic/common';

import { useTeamId, useV2TransactionQuery } from '../../../../hooks';
import { FormInputBaseType } from './_base-input-types';
import { Select, SelectItem } from '@finalytic/ui';
import { Text } from '@mantine/core';
import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type SourceType = {
  name: string;
  namespace:
    | {
        name: string;
      }
    | string;
};
type SelectDataType = {
  sourceType: string | SourceType;
  connectionId?: string | null;
};

type Props = SelectDataType & FormInputBaseType;

export function getNamespaceAndType(
  sourceType?: string | SourceType | undefined | null
): Readonly<[string, string]> {
  if (!sourceType) return ['', ''];
  if (typeof sourceType === 'string') {
    return sourceType.includes('.')
      ? (sourceType.split('.') as any)
      : ['', sourceType];
  }
  if (sourceType.namespace) {
    if (typeof sourceType.namespace === 'string')
      return [sourceType.namespace, sourceType.name];
    return [sourceType.namespace.name, sourceType.name];
  }
  return ['', ''];
  // name, namespace.name
}

function useSources(
  sourceType: string | undefined | SourceType,
  connectionId?: string | null
) {
  const [teamId] = useTeamId();
  const [search, setSearch] = useState('');

  const { data, isLoading: loading } = useV2TransactionQuery(
    (q, { sourceType, teamId, search, connectionId }) => {
      const [namespace, name] = getNamespaceAndType(sourceType);
      return q
        .source({
          where: {
            type: { _eq: name },
            connectionId: connectionId ? { _eq: connectionId } : undefined,
            connection:
              namespace && !connectionId
                ? { appId: { _eq: namespace } }
                : undefined,
            tenantId: { _eq: teamId },
            _or: search
              ? [
                  {
                    description: { _ilike: `%${search.trim()}%` },
                  },
                  {
                    remoteId: { _ilike: `%${search.trim()}%` },
                  },
                ]
              : undefined,
          },
          order_by: [{ description: 'asc' }],
          limit: 50,
        })
        .map((source) => ({
          id: source.id,
          type: source.type,
          description: getSourceDescription(source),
        }));
    },
    {
      variables: {
        sourceType,
        teamId,
        connectionId,
        search,
      },
      skip: !teamId || !sourceType,
      queryKey: ['sources'],
    }
  );

  const sources = useMemo<SelectItem[]>(
    () =>
      (data || []).map((source) => ({
        value: source.id || '',
        label: source.description || '',
      })),
    [data]
  );

  return {
    sources,
    loading,
    setSearch,
  };
}

function useSingleSource(sourceId: string | undefined) {
  const { data, isLoading: loading } = useV2TransactionQuery(
    (q, { sourceId }) => {
      const source = q.sourceById({
        id: sourceId,
      });
      return {
        id: source?.id,
        description: getSourceDescription(source),
      };
    },
    {
      variables: {
        sourceId,
      },
      skip: !sourceId,
      queryKey: 'sources',
    }
  );

  return {
    source: data,
    loading,
  };
}

export const SelectData = ({
  connectionId,
  label,
  defaultValue,
  formKey,
  placeholder,
  required,
  sourceType,
  onValueChange,
}: Props) => {
  const { control } = useFormContext();

  const { loading, setSearch, sources } = useSources(sourceType, connectionId);

  return (
    <>
      {label && (
        <Text size='sm' weight={500}>
          {label}
        </Text>
      )}
      <Controller
        control={control}
        name={formKey}
        defaultValue={defaultValue || undefined}
        rules={{ required }}
        render={({ field: { onChange, value } }) => {
          const { source, loading: loadingSingleSource } =
            useSingleSource(value);

          const selectValue = useMemo(
            () =>
              source?.id
                ? {
                    label: source.description as string,
                    value: source.id as string,
                  }
                : undefined,
            [source?.id]
          );

          const extendedOnChange = (val: string | undefined) => {
            onValueChange?.(formKey, val);
            onChange(val);
          };

          return (
            <Select
              data={sources}
              withSearch
              loading={loading}
              value={selectValue}
              setValue={(item) => extendedOnChange(item?.value)}
              removeValue={() => extendedOnChange('')}
              popoverWidth='target'
              onSearchInput={setSearch}
              withBorder
              placeholder={placeholder}
              searchPlaceholder={placeholder}
              inputLoading={loadingSingleSource}
            />
          );
        }}
      />
    </>
  );
};
