import { createStyles } from '@mantine/core';

export const useStyles = createStyles(
  (theme, { v2Icon }: { v2Icon: boolean }) => ({
    navLink: {
      '> div': {
        height: v2Icon ? 35 : undefined,
      },
      '& path, & rect': {
        fill: v2Icon ? undefined : '#ffffff80 !important',
      },
      ':hover > div, &.active > div': {
        backgroundColor: '#00000060',
        borderRadius: 10,
        cursor: 'pointer',

        span: {
          opacity: 1,
        },

        '& path, & rect': {
          fill: v2Icon ? undefined : '#f3f3f3 !important',
          stroke: v2Icon ? 'white' : undefined,
        },
      },
    },
    subNavLink: {
      '& div:hover, &.active > div': {
        backgroundColor: '#00000060',

        span: {
          opacity: 1,
        },
      },
    },
  })
);
