import { gqlV2, useV2TransactionQuery } from '@finalytic/data-ui';
import {
  Drawer,
  DrawerHeader,
  LoadingIndicator,
  StatusPill,
  StatusPillType,
  StringParam,
  Table,
  useQueryParam,
} from '@finalytic/ui';
import { ColDef, ICellRendererParams } from '@finalytic/ui-grid';
import { ensure } from '@finalytic/utils';
import { Box } from '@mantine/core';
import dayjs from 'dayjs';

function useConnectionDetails(connectionId?: string | null) {
  const {
    data,
    isLoading: loading,
    refetch,
  } = useV2TransactionQuery(
    (q, args) => {
      const tasks = q
        .tasks({ where: args.where, order_by: [{ createdAt: 'desc' }] })
        .map((task) => ({
          id: task?.id,
          status: task?.status,
          message: task?.message,
          updatedAt: task?.updatedAt,
          createdAt: task?.createdAt,
          traceId: task?.traceId,
        }));

      return {
        tasks,
      };
    },
    {
      skip: !connectionId,
      queryKey: 'tasks',
      variables: {
        where: ensure<gqlV2.task_bool_exp>({
          connectionId: {
            _eq: connectionId,
          },
          _or: [
            {
              queue: { _eq: 'legacy' },
            },
            {
              queue: { _is_null: true },
            },
          ],
        }),
      },
    }
  );

  const tasks = data?.tasks || [];

  return {
    tasks,
    loading,
    refetch,
  };
}

export const ConnectionDetailDrawer = () => {
  const [connectionId, setQuery] = useQueryParam(
    'connectionDetailId',
    StringParam
  );

  const closeDrawer = () => {
    setQuery(undefined);
  };

  const { tasks, loading } = useConnectionDetails(connectionId || undefined);

  const columnDefs: ColDef[] = [
    {
      field: 'createdAt',
      valueFormatter: ({ value }) => dayjs(value).format('YYYY-MM-DD HH:mm'),
    },
    {
      field: 'updatedAt',
      valueFormatter: ({ value }) => dayjs(value).format('YYYY-MM-DD HH:mm'),
    },
    {
      field: 'message',
      flex: 1,
      cellRenderer: ({ value, data }: ICellRendererParams<typeof tasks[0]>) => {
        return (
          <a
            target='_blank'
            href={`https://app.mezmo.com/1df0f207bc/logs/view?q=meta.runId:${
              data?.traceId?.split('|')?.[1]
            }`}
            rel='noreferrer'
          >
            {value}
          </a>
        );
      },
    },
    {
      field: 'status',
      cellRenderer: ({ value }: ICellRendererParams<typeof tasks[0]>) => {
        let color: StatusPillType | undefined = 'yellow';

        switch (value) {
          case 'failed':
            color = 'red';
            break;
          case 'fail':
            color = 'red';
            break;
          case 'running':
            color = 'yellow';
            break;
          case 'success':
            color = 'green';
            break;
          default:
            break;
        }

        return <StatusPill type={color} label={value} />;
      },
    },
  ];

  return (
    <Drawer opened={!!connectionId} onClose={closeDrawer} size={1000}>
      <DrawerHeader title='Connection Tasks' onClose={closeDrawer} />

      {/* Modal Header */}
      {loading ? (
        <LoadingIndicator isFullPageLoading={true} />
      ) : (
        <>
          <Box pb={'sm'} sx={{ flex: 1 }}>
            <Table columnDefs={columnDefs} rowData={tasks} />
          </Box>
        </>
      )}
    </Drawer>
  );
};
