import { useTeam } from '../../../hooks';

export type EditorTypeUnion = 'expression' | 'select' | 'percentage' | 'text';

export function useTabEditorType({
  automationId,
  settingKey,
}: {
  automationId: string | undefined;
  settingKey: string | undefined;
}) {
  const [{ automations }] = useTeam();

  const mappings = automations.find(
    (i) => i.automationId === automationId
  )?.mappings;

  if (!mappings) return { rightEditorType: 'select' };

  const tableTemplate = mappings[settingKey || ''];
  const schemaType = tableTemplate.right.schema.split(
    '.'
  )[1] as EditorTypeUnion;
  const rightEditorParams = tableTemplate.right.params;
  const editorTypes: EditorTypeUnion[] = [
    'expression',
    'percentage',
    'select',
    'text',
  ];

  const rightEditorType = editorTypes.includes(schemaType)
    ? schemaType
    : 'select';

  return {
    rightEditorType,
    rightEditorParams,
  };
}
