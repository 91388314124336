import { ExtendedCustomColors } from '../../styles';
import { Affix, Box, BoxProps } from '@mantine/core';
import { ReactNode } from 'react';

export type BannerProps = {
  children?: ReactNode;
  themeColor?: ExtendedCustomColors;
} & Omit<BoxProps, 'sx'>;

export const Banner = ({
  children,
  themeColor = 'gray',
  ...boxProps
}: BannerProps) => {
  return (
    <Affix position={{ top: 0, left: 0, right: 0 }}>
      <Box
        sx={(theme) => ({
          boxShadow: theme.shadows.md,
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 100,
          display: 'flex',
          justifyContent: 'center',
          border: '1px solid',
          borderTop: 'none',
          borderColor: theme.colors[themeColor][5],
          backgroundColor: theme.colors[themeColor][0],
          padding: `5px ${theme.spacing.sm} `,
          borderRadius: `0  0 ${theme.radius.lg} ${theme.radius.lg}`,
        })}
        {...boxProps}
      >
        {children}
      </Box>
    </Affix>
  );
};
