import { gql, useApi, useMe, useQuery, useTeamId } from '../../hooks';
import { SettingsIcon } from './_icons';
import { useAuth, useUser } from '@finalytic/authentication';
import {
  FeedbackFormInput,
  FeedbackModal,
  showErrorNotification,
  useColors,
} from '@finalytic/ui';
import {
  faCog,
  faDollar,
  faEnvelope,
  faMap,
  faSignOut,
  faUpRightFromSquare,
  faVolumeOff,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Box,
  Divider,
  Group,
  Menu,
  Stack,
  Sx,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { captureException } from '@sentry/react';

type SettingsPopoverMenuProps = {
  isNavbarExpanded: boolean;
  isOwnerDashboard?: boolean;
};

function useTeamData() {
  const [teamId] = useTeamId();
  const [{ data, fetching }] = useQuery(
    {
      tenant: [
        {
          where: {
            id: { _eq: gql.$`teamId` },
          },
          limit: 1,
        },
        {
          id: true,
          stripeId: true,
        },
      ],
    },

    {
      variables: {
        teamId,
      },
    }
  );

  const teamStripeId = data?.tenant[0]?.stripeId || '';

  return {
    teamStripeId,
    loading: fetching,
  };
}

export const SettingsPopoverMenu = ({
  isNavbarExpanded,
  isOwnerDashboard = false,
}: SettingsPopoverMenuProps) => {
  const { black, gray } = useColors();
  const { primaryColor } = useMantineTheme();
  const dividerColor = gray.light + 80;

  const statusPageLink = 'https://status.finalytic.io/';

  const { teamStripeId } = useTeamData();
  const { user } = useUser();
  const { realUserId } = useMe();

  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const email = user?.primaryEmailAddress?.emailAddress;

  const { signOut: sOut } = useAuth();

  const signOut = () => {
    window.analytics?.track('User Signed Out', {
      email,
      lastName,
      firstName,
      userId: realUserId,
    });
    sOut();
  };

  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const onClose = () => setOpen(false);

  const billing: () => void = useApi('teamBillingLink', {
    onSuccess: (result) => window.open(result.link || '_blank'),
    onError: (error) =>
      showErrorNotification({
        title: error.code,
        message: error.message,
      }),
  });

  const submitFeedback = async (data: FeedbackFormInput) => {
    try {
      class FeedbackRequestError extends Error {
        constructor(readonly feedback: string) {
          super(`User Feedback: ${feedback}`);
          this.name = 'FeedbackRequestError';
        }
      }

      captureException(new FeedbackRequestError(data.userInput), {
        level: 'info',
        tags: {
          userInput: data.userInput,
          priority: data.importance,
          sendIntegrationNotification: 'true',
        },
      });

      return {
        ok: true,
        message:
          'Your feedback was submitted! Thank you! We will get back to you soon.',
      };
    } catch (error: any) {
      return {
        ok: false,
        message:
          error?.message ||
          "We couldn't process your feedback. Please try again.",
      };
    }
  };

  const disabled: Sx = {
    cursor: 'not-allowed',
    color: gray.dark,
  };

  return (
    <>
      <Menu
        radius={10}
        withArrow
        width={isNavbarExpanded ? 'target' : 200}
        withinPortal
        position='top-start'
        shadow='lg'
        styles={{
          dropdown: { padding: '0px !important' },
          item: {
            ':hover': {
              backgroundColor: gray.dark + 20,
            },
            margin: '5px 4px',
            width: 'calc(100% - 8px)',
            lineHeight: 'normal',
          },
          itemIcon: {
            width: 12,
          },
          arrow: {
            left: '14px !important',
          },
        }}
      >
        <Menu.Target>
          <Group
            spacing={0}
            px='xs'
            py='sm'
            position='apart'
            noWrap={isNavbarExpanded}
            sx={(theme) => ({
              cursor: 'pointer',
              borderRadius: theme.radius.md,
              ':hover': {
                backgroundColor: '#5C617840',
              },
            })}
          >
            <Group spacing={10} noWrap sx={{ overflowX: 'hidden' }}>
              <Avatar
                src={
                  user?.profileImageUrl ||
                  'https://www.gravatar.com/avatar?d=mp'
                }
                radius={100}
              />
              {isNavbarExpanded && (
                <Stack
                  spacing={0}
                  sx={{
                    color: '#fff',
                    '*': {
                      maxWidth: 165,
                      textOverflow: 'ellipsis',
                      overflowX: 'hidden',
                      whiteSpace: 'nowrap',
                    },
                  }}
                >
                  <Text>
                    {firstName} {lastName}
                  </Text>
                  <Text
                    sx={{
                      opacity: 0.8,
                    }}
                    size='xs'
                  >
                    {email}
                  </Text>
                </Stack>
              )}
            </Group>

            {isNavbarExpanded && <SettingsIcon />}
          </Group>
        </Menu.Target>
        <Menu.Dropdown>
          <Box
            component='a'
            href={statusPageLink}
            target='_blank'
            rel='noreferrer'
            sx={{
              textDecoration: 'none',
              '@keyframes pulse': {
                '0%, 100%': {},
                '50%': {
                  transform: 'scale(1.3)',
                },
              },
              '&:hover ': {
                textDecoration: 'underline',
                textDecorationColor: gray.dark,
                '.status': {
                  animation: 'pulse 1.5s ease-in-out infinite',
                },
              },
            }}
          >
            <Group
              spacing={12}
              sx={(theme) => ({
                backgroundColor: gray.light + 40,
                margin: '-1px -1px 0',
                borderRadius: `${theme.radius.md} ${theme.radius.md} 0 0`,
                height: 38,
                paddingLeft: '15px',
              })}
            >
              <Box
                className='status'
                sx={(theme) => ({
                  borderRadius: '100%',
                  backgroundColor: theme.colors.teal[4],
                  width: 8,
                  height: 8,
                })}
              />
              <Text component='span' weight={500} size='xs' color={gray.dark}>
                All systems are online
              </Text>
            </Group>
          </Box>

          {primaryColor !== 'VRPlatform' && (
            <>
              <Menu.Item
                onClick={openModal}
                icon={
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size='sm'
                    color={black.base}
                  />
                }
              >
                Send Feedback
              </Menu.Item>

              {!isOwnerDashboard && (
                <>
                  <Menu.Item
                    sx={disabled}
                    icon={
                      <FontAwesomeIcon
                        icon={faVolumeOff}
                        size='sm'
                        color={gray.dark}
                      />
                    }
                  >
                    Changelog
                  </Menu.Item>

                  <Menu.Item
                    sx={disabled}
                    icon={
                      <FontAwesomeIcon
                        icon={faMap}
                        size='sm'
                        color={gray.dark}
                      />
                    }
                  >
                    Roadmap
                  </Menu.Item>
                </>
              )}
              <Divider color={dividerColor} />
            </>
          )}

          {teamStripeId && !isOwnerDashboard && (
            <Menu.Item
              onClick={() => billing()}
              icon={
                <FontAwesomeIcon icon={faDollar} size='sm' color={black.base} />
              }
              rightSection={
                <FontAwesomeIcon icon={faUpRightFromSquare} size='sm' />
              }
            >
              Billing
            </Menu.Item>
          )}

          <Menu.Item
            component={Link}
            to='/settings'
            icon={<FontAwesomeIcon icon={faCog} size='xs' color={black.base} />}
          >
            Settings
          </Menu.Item>

          <Menu.Item
            onClick={() => signOut()}
            icon={
              <FontAwesomeIcon icon={faSignOut} size='sm' color={black.base} />
            }
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <FeedbackModal
        opened={open}
        onClose={onClose}
        onSubmit={submitFeedback}
      />
    </>
  );
};
