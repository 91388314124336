import { Navbar, NavbarBaseProps } from '../navbar';
import { Logo } from '@finalytic/ui';
import {
  Burger,
  Drawer,
  Group,
  Header as MantineHeader,
  useMantineTheme,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const Header = (navbarProps: NavbarBaseProps) => {
  const location = useLocation();

  const [opened, setOpened] = useState(false);
  const title = opened ? 'Close navigation' : 'Open navigation';

  const theme = useMantineTheme();

  const toggleMenu = () => setOpened((o) => !o);
  const closeMenu = () => setOpened(false);

  useEffect(() => {
    if (opened) {
      setOpened(false);
    }
  }, [location]);

  return (
    <MantineHeader
      height={60}
      sx={{
        '@media (min-width: 755px)': {
          display: 'none',
        },
        boxShadow: '0 3px 5px rgba(57, 63, 72, 0.1)',
      }}
    >
      <Group position='apart' mx={10} sx={{ height: '100%' }}>
        <Logo />

        <Burger opened={opened} onClick={toggleMenu} title={title} />
      </Group>
      <Drawer
        opened={opened}
        onClose={closeMenu}
        position='right'
        withCloseButton={false}
        styles={{
          root: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '& nav': {
              top: '0px !important',
            },
          },
        }}
        overlayProps={{ color: theme.colors.gray[2], opacity: 0, blur: 3 }}
      >
        <Navbar closeMobileMenu={closeMenu} {...navbarProps} />
        {/* <Navbar
          backgroundColor={getOwnerPortalColor(team?.colorPrimary)}
          closeMobileMenu={closeMenu}
          logo={team?.logo}
          routes={<AvailableRoutes navbarIsExpanded={true} />}
        /> */}
      </Drawer>
    </MantineHeader>
  );
};
