import { getSourceDescription } from '@finalytic/common';

import { useTeam, useTeamId, useV2TransactionQuery } from '../../../hooks';
import { getNamespaceAndType } from '../../inputs';
import { Select, SelectItem } from '@finalytic/ui';
import { isUUID, toTitleCase } from '@finalytic/utils';
import { useMemo, useState } from 'react';

function useSources({
  sourceId,
  settingKey,
  search,
  automationId,
  customSkip,
}: {
  sourceId: string | undefined;
  settingKey: string | undefined;
  search: string | undefined;
  automationId: string | undefined;
  customSkip: boolean;
}) {
  const [teamId] = useTeamId();
  const [{ automations }] = useTeam();
  const automation = automations.find((i) => i.automationId === automationId);
  const mappings = automation?.mappings;
  const connections = automation?.connections || {};

  const rightType = mappings[settingKey || '']?.right?.schema;

  const trimmedSearch = search?.trim();

  const { data, isLoading } = useV2TransactionQuery(
    (q, { rightType, teamId, connections, trimmedSearch, sourceId }) => {
      const queryLimit = 50;

      const [appId, type] = getNamespaceAndType(rightType);
      const sources = q
        .source({
          where: {
            type: {
              _eq: type,
            },
            tenantId: { _eq: teamId },
            connectionId: { _eq: connections[appId] },
            _or: trimmedSearch
              ? [
                  { description: { _ilike: `%${trimmedSearch}%` } },
                  { remoteId: { _ilike: `%${trimmedSearch}%` } },
                ]
              : undefined,
          },
          order_by: [{ description: 'asc' }],
          limit: queryLimit,
        })
        .map<SelectItem>((item) => ({
          label: getSourceDescription(item) || '',
          value: item.id,
        }));

      if (sourceId && isUUID(sourceId)) {
        const source = q.sourceById({ id: sourceId });
        const value = {
          label: getSourceDescription(source),
          value: source?.id,
        };
        return {
          sources,
          sourceById: value?.value ? value : undefined,
        };
      }

      return {
        sources,
        sourceById: undefined,
      };
    },
    {
      variables: {
        teamId: teamId,
        connections,
        trimmedSearch,
        sourceId,
        rightType,
      },
      skip: customSkip || !teamId || !rightType || !automationId || !settingKey,
      queryKey: 'sources',
    }
  );

  return {
    sources: (data?.sources || []) as any[],
    loading: isLoading,
    source: (data?.sourceById as any) || undefined,
  };
}

export const SelectEditor = ({
  value,
  setCellValue,
  onSelectClose,
  settingKey,
  automationId,
  options,
}: {
  value: string | undefined;
  setCellValue: (v: string) => void;
  onSelectClose: () => void;
  settingKey: string | undefined;
  automationId: string | undefined;
  options: string[] | undefined;
}) => {
  const setValue = (newValue: SelectItem) => setCellValue(newValue.value);

  const isCustomOptions = !!options && options.length > 0;

  const [search, setSearch] = useState<string>('');

  const removeValue = () => setCellValue('');

  const { loading, source, sources } = useSources({
    sourceId: value,
    search,
    settingKey,
    automationId,
    customSkip: isCustomOptions,
  });

  const data = useMemo(() => {
    if (isCustomOptions)
      return options.map((o) => ({ label: toTitleCase(o), value: o }));

    return sources;
  }, [options, sources]);

  const currentValue = useMemo(() => {
    if (isCustomOptions && value) {
      return { label: toTitleCase(value), value };
    }

    return value ? (source as any) : undefined;
  }, [value, source, isCustomOptions]);

  return (
    <Select
      data={data} // label: source.description ; value: source.id
      value={currentValue} // label: setting.value ; value: setting.id
      withSearch
      loading={loading}
      setValue={setValue}
      removeValue={removeValue}
      popoverWidth='target'
      onClose={onSelectClose}
      searchPlaceholder='Search options...'
      onSearchInput={(v) => setSearch(v || '')}
      defaultOpen
      preventClose
    />
  );
};
