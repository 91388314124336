import { useColors } from '../../styles';
import { Box, Group, Text } from '@mantine/core';
import { ReactNode } from 'react';

type InputContainerProps = {
  htmlFor?: string;
  label: string;
  children: ReactNode;
  inputWidth?: number;
  error?: string;
};

export const InputContainer = ({
  htmlFor,
  label,
  children,
  inputWidth,
  error,
}: InputContainerProps) => {
  const { gray } = useColors();

  return (
    <Box>
      {error && (
        <Text
          component='span'
          size='xs'
          color='red'
          mt={-18}
          sx={{ textAlign: 'right', display: 'block' }}
        >
          {error}
        </Text>
      )}
      <Group
        position='apart'
        sx={{
          width: '100%',
          '@media (max-width: 755px)': {
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: '4px',
          },
        }}
      >
        <Text
          component='label'
          size='sm'
          htmlFor={htmlFor}
          color={gray.dark}
          sx={{ flexBasis: '20%', flexShrink: 0 }}
        >
          {label}
        </Text>
        <Box
          sx={{
            flex: 1,
            '@media (min-width: 755px)': {
              maxWidth: inputWidth ? `${inputWidth}` : '360px',
            },
          }}
        >
          {children}
        </Box>
      </Group>
    </Box>
  );
};
