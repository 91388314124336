import { CustomThemes } from '@finalytic/ui';
import { useMantineTheme } from '@mantine/core';

const extensions: {
  [key in CustomThemes]: {
    id: string;
    store_url: string;
  };
} = {
  Finalytic: {
    id: 'lmjjdicajpllfgekjbnnjobadadidoih',
    store_url:
      'https://chrome.google.com/webstore/detail/finalytic-connect-web-ext/lmjjdicajpllfgekjbnnjobadadidoih',
  },
  VRPlatform: {
    id: 'hphpodppjfegboiaddkpkceedjjjjjma',
    store_url:
      'https://chrome.google.com/webstore/detail/vrplatform-connect-web-ex/hphpodppjfegboiaddkpkceedjjjjjma',
  },
};

export const useExtension = () => {
  const { primaryColor: appName } = useMantineTheme();

  const extension = extensions[appName as CustomThemes];

  const sendMessage = (
    { message, data }: { message: string; data: object },
    callback?: (reply: { exists: boolean }) => void
  ) => {
    if (window.chrome?.runtime?.sendMessage) {
      window.chrome.runtime.sendMessage(
        extension.id,
        {
          data: {
            ...data,
            message,
          },
        },
        {},
        callback as any
      );
    }
  };

  return { sendMessage, extension };
};
