import { Box, Stack, StackProps, Text } from '@mantine/core';
import { ReactNode } from 'react';

export type OverlayContainerProps = {
  icon: ReactNode;
  children: ReactNode;
  size?: number | string;
} & StackProps;

export const OverlayContainer = ({
  icon,
  children,
  size = 150,
  ...props
}: OverlayContainerProps) => (
  <Stack align='center' pt={60} {...props}>
    <Box
      sx={{
        border: 'none',
        width: size,
        height: size,
        svg: {
          height: '100%',
          width: '100%',
          '& path, & rect': {
            fill: '#CED0D7 !important',
          },
        },
      }}
    >
      {icon}
    </Box>
    <Text color='#CED0D7' weight={500} size='md'>
      {children}
    </Text>
  </Stack>
);
