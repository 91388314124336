import { ConfirmModalResultIcon } from '../_components';
import { ActionButton, TransparentButton, useColors } from '@finalytic/ui';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, Text } from '@mantine/core';

type SuccessScreenProps = {
  resultMessage: string;
  onClose: () => void;
};

export const SuccessScreen = ({
  resultMessage,
  onClose,
}: SuccessScreenProps) => {
  const { green, gray } = useColors();

  return (
    <>
      <Stack
        spacing={0}
        align='center'
        justify='center'
        mb='md'
        sx={{ flex: 1 }}
      >
        <ConfirmModalResultIcon color={green.base}>
          <FontAwesomeIcon icon={faCheck} color='#fff' size='lg' />
        </ConfirmModalResultIcon>
        <Text weight={500} mb={10} sx={{ textAlign: 'center' }}>
          {resultMessage}
        </Text>
      </Stack>

      <ActionButton onClick={onClose} fullWidth mb={10} sx={{ height: 40 }}>
        Close
      </ActionButton>
      <TransparentButton onClick={onClose} fullWidth sx={{ color: gray.dark }}>
        Cancel
      </TransparentButton>
    </>
  );
};
