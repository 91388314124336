import { Table, WhiteTagCell, showErrorNotification } from '@finalytic/ui';

import {
  ColDef,
  ICellRendererParams,
  IServerSideDatasource,
  ITooltipParams,
} from '@finalytic/ui-grid';

import {
  SuccessErrors,
  gqlV2,
  useRunDrawer,
  useTeamRole,
  useV2Client,
  useV2TransactionQuery,
  useV2TransactionSubscription,
} from '@finalytic/data-ui';
import {
  EllipsisMenu,
  EllipsisMenuItem,
  showSuccessNotification,
} from '@finalytic/ui';
import { hasValue } from '@finalytic/utils';
import {
  faCheckCircle,
  faEllipsisH,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faCopy,
  faDownload,
  faHistory,
  faObjectsColumn,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group, Paper, Text, useMantineTheme } from '@mantine/core';
import { useMemo } from 'react';

type Props = { where: gqlV2.jobPlan_bool_exp };

export const useRetryTask = () => {
  const { runTask } = useRunDrawer();

  const retryTask = async (props: {
    automationId: string | undefined;
    retryPlanId: string | undefined;
    params: any;
  }) => {
    const automationId = props?.automationId;
    const retryPlanId = props?.retryPlanId;
    const params = props?.params;

    if (!automationId)
      return showErrorNotification({
        message: 'Missing automation id.',
      });

    const p = params || {};
    if (retryPlanId) {
      p.retryPlanId = retryPlanId;
    }

    await runTask({
      id: automationId,
      input: [],
      params: p,
    });
  };

  return { retryTask };
};

const CellTooltip = ({ value }: ITooltipParams) => (
  <Paper shadow='md' radius='md' px='md' py={5} sx={{ maxWidth: 400 }}>
    <Text component='span' size='sm'>
      {value}
    </Text>
  </Paper>
);

export const useTasksTableColumnDefs = (props?: { hide?: 'type'[] }) => {
  const { isSuperAdmin } = useTeamRole();

  const { colors } = useMantineTheme();
  const { retryTask } = useRetryTask();

  const openFileUrl = (url: string) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      console.log(url);
      showErrorNotification({ message: 'Missing file url.' });
    }
  };

  return useMemo<ColDef[]>(
    () =>
      [
        {
          cellRenderer: ({ value }: ICellRendererParams) => {
            const status = value?.toLowerCase().includes('partial')
              ? 'partial'
              : value?.toLowerCase().includes('running') ||
                value?.toLowerCase().includes('queued') ||
                value?.toLowerCase().includes('pending') ||
                value?.toLowerCase().includes('started') ||
                value?.toLowerCase().includes('starting')
              ? 'running'
              : value?.toLowerCase().includes('fail') ||
                value?.toLowerCase().includes('failed')
              ? 'fail'
              : 'ok';
            return (
              <Group noWrap>
                <FontAwesomeIcon
                  icon={
                    status === 'running'
                      ? faEllipsisH
                      : status === 'fail'
                      ? faTimesCircle
                      : faCheckCircle
                  }
                  color={
                    status === 'partial' || status === 'running'
                      ? colors.yellow[5]
                      : status === 'fail'
                      ? colors.red[5]
                      : colors.green[5]
                  }
                />
              </Group>
            );
          },
          headerName: '',
          field: 'status',
          maxWidth: 30,
          minWidth: 30,
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
        {
          field: 'title',
          width: 200,
          tooltipField: 'title',
          sort: 'asc' as const,
          tooltipComponent: CellTooltip,
          // cellRenderer: ({ value, data }: ICellRendererParams) => {
          //   const successChilds = data?.success || 0;
          //   const errorChilds = data?.failed || 0;
          //   if (successChilds < 1 && errorChilds < 1) return value || '';

          //   return (
          //     <Group align='flex-start' h='100%' position='apart' noWrap>
          //       <Text lineClamp={1} display='inline'>
          //         {value}
          //       </Text>
          //       {/* <SuccessErrors successes={successChilds} errors={errorChilds} /> */}
          //     </Group>
          //   );
          // },
        },
        {
          field: 'message',
          flex: 1,
          minWidth: 150,
          tooltipField: 'message',
          tooltipComponent: CellTooltip,
          cellRenderer: ({ value, data }: ICellRendererParams) => {
            const successChilds = data?.success || 0;
            const errorChilds = data?.failed || 0;
            if (successChilds < 1 && errorChilds < 1) return value || '';

            return (
              <Group align='center' h='100%' position='apart' noWrap>
                <Text lineClamp={1} display='inline'>
                  {value}
                </Text>
                <SuccessErrors successes={successChilds} errors={errorChilds} />
              </Group>
            );
          },
        },
        {
          colId: 'menu',
          cellStyle: { display: 'grid', placeContent: 'center' } as any,
          maxWidth: 50,
          width: 50,
          pinned: 'right' as const,
          cellRenderer: ({ node, data }: ICellRendererParams<any>) => {
            if (node?.rowPinned === 'bottom') return undefined;

            console.log(data);

            if (data?.kind === 'action') {
              return (
                <EllipsisMenu>
                  <EllipsisMenuItem
                    icon={faObjectsColumn}
                    onClick={() => undefined}
                  >
                    View
                  </EllipsisMenuItem>
                  {isSuperAdmin && (
                    <EllipsisMenuItem
                      icon={faCopy}
                      onClick={() =>
                        navigator.clipboard.writeText(`${data?.id}`).then(() =>
                          showSuccessNotification({
                            message: 'The ID was added to your clipboard.',
                          })
                        )
                      }
                    >
                      Copy ID
                    </EllipsisMenuItem>
                  )}
                  {data.outputFile ? (
                    <EllipsisMenuItem
                      icon={faDownload}
                      onClick={() => openFileUrl(data.outputFile)}
                    >
                      Download (output)
                    </EllipsisMenuItem>
                  ) : null}
                  {data.inputFile ? (
                    <EllipsisMenuItem
                      icon={faDownload}
                      onClick={() => openFileUrl(data.inputFile)}
                    >
                      Download (input)
                    </EllipsisMenuItem>
                  ) : null}
                </EllipsisMenu>
              );
            } else {
              return (
                <EllipsisMenu>
                  <EllipsisMenuItem
                    icon={faHistory}
                    onClick={() =>
                      retryTask({
                        automationId: data?.automationId,
                        params: data?.payload,
                        retryPlanId: undefined,
                      })
                    }
                  >
                    Retry
                  </EllipsisMenuItem>
                  {data.outputFile ? (
                    <EllipsisMenuItem
                      icon={faDownload}
                      onClick={() => openFileUrl(data.outputFile)}
                    >
                      Download (output)
                    </EllipsisMenuItem>
                  ) : null}
                  {data.inputFile ? (
                    <EllipsisMenuItem
                      icon={faDownload}
                      onClick={() => openFileUrl(data.inputFile)}
                    >
                      Download (input)
                    </EllipsisMenuItem>
                  ) : null}
                  {isSuperAdmin && (
                    <EllipsisMenuItem
                      icon={faCopy}
                      onClick={() =>
                        navigator.clipboard.writeText(`${data?.id}`).then(() =>
                          showSuccessNotification({
                            message: 'The ID was added to your clipboard.',
                          })
                        )
                      }
                    >
                      Copy ID
                    </EllipsisMenuItem>
                  )}
                  {isSuperAdmin && (
                    <EllipsisMenuItem
                      icon={faCopy}
                      onClick={() => {
                        /*if (!data?.workflowId)
                          return showErrorNotification({
                            message: 'Missing workflow id',
                          });
                          */
                        navigator.clipboard
                          .writeText(`${data?.workflowId || data?.id}`)
                          .then(() =>
                            showSuccessNotification({
                              message:
                                'The Workflow ID was added to your clipboard.',
                            })
                          );
                      }}
                    >
                      Copy Workflow ID
                    </EllipsisMenuItem>
                  )}
                </EllipsisMenu>
              );
            }
          },
        },
      ].filter(hasValue),
    []
  );
};
export const JobTable = (props: Props) => {
  const { data } = useV2TransactionSubscription(
    (q, { where }) => {
      return (
        q
          .jobPlans({
            where,
            order_by: [
              {
                createdAt: 'asc',
              },
            ],
          })
          .map((plan) => {
            return {
              id: plan.id,
              automationId: plan.automationId,
              params: plan.params(),
              jobs: plan.jobs().map((item) => ({
                id: item.id,
                status: item.status || '',
                title: item.params()?.title || '',
                message: item.title || 'Running',
                automationId: item.automationId,
                success:
                  item
                    .actions_aggregate({
                      where: { status: { _in: ['completed', 'success'] } },
                    })
                    .aggregate?.count() || 0,
                failed:
                  item
                    .actions_aggregate({ where: { status: { _eq: 'failed' } } })
                    .aggregate?.count() || 0,
              })),
            };
          })?.[0] || {}
      );
    },
    {
      where: props.where,
    }
  );

  const colDefs = useTasksTableColumnDefs();

  return (
    <Table
      columnDefs={colDefs}
      rowData={data?.jobs || []}
      isTopRounded={false}
      animateRows
      getRowId={({ data }) => data?.id}
      defaultColDef={{
        resizable: false,
        suppressMovable: true,
      }}
    />
  );
};
