export const ModalDeleteIcon = () => (
  <svg
    width='80'
    height='80'
    viewBox='0 0 80 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M20.0002 23.3334V60C20.0002 61.7681 20.7025 63.4638 21.9528 64.7141C23.203 65.9643 24.8987 66.6667 26.6668 66.6667H53.3335C55.1016 66.6667 56.7973 65.9643 58.0475 64.7141C59.2978 63.4638 60.0002 61.7681 60.0002 60V23.3334M30.0002 23.3334C30.0002 20.6812 31.0537 18.1377 32.9291 16.2623C34.8045 14.3869 37.348 13.3334 40.0002 13.3334V13.3334C42.6523 13.3334 45.1959 14.3869 47.0712 16.2623C48.9466 18.1377 50.0002 20.6812 50.0002 23.3334V23.3334H30.0002ZM30.0002 23.3334H50.0002H30.0002ZM30.0002 23.3334H20.0002H30.0002ZM50.0002 23.3334H60.0002H50.0002ZM66.6668 23.3334H60.0002H66.6668ZM13.3335 23.3334H20.0002H13.3335Z'
      stroke='#E6E7EA'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
