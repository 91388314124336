import { TransparentButton } from '../button-transparent/TransparentButton';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';

type ResetFilterButtonProps = {
  onClick: () => void;
  children?: ReactNode;
  hide?: boolean;
};

export const ResetFilterButton = ({
  onClick,
  children = 'Reset',
  hide,
}: ResetFilterButtonProps) => {
  if (hide) return null;

  return (
    <TransparentButton
      color={'#333'}
      size='sm'
      onClick={onClick}
      sx={{
        fontSize: 12,
        height: 25,
        padding: '0 10px',
        '&:hover': {
          backgroundColor: '#33333310',
        },
      }}
      leftIcon={<FontAwesomeIcon icon={faXmark} color='#33333390' />}
    >
      {children}
    </TransparentButton>
  );
};
