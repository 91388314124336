import { QueryKeyUnion, useInvalidateQueries } from '../graphql-v2';
import { trpc } from './trpc';
import {
  CombinedServerType,
  RouterInput,
  RouterOutput,
  inferAvailableProcedureTypes,
} from '@finalytic/trpc-combined';
import { showErrorNotification } from '@finalytic/ui';
import { ensure } from '@finalytic/utils';
import { faCheck, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showNotification, updateNotification } from '@mantine/notifications';
import { UseQueryOptions } from '@tanstack/react-query';
import { useId } from 'react';

type Options = {
  notificationId?: string;
  successMessage?: {
    title?: string;
    message: string;
  };
  errorMessage?: {
    title?: string;
    message: string;
  };
  invalidateQueryKeys?: QueryKeyUnion[] | true;
};

interface QueryOptions extends Options {
  skip?: boolean;
}

export const useTrpcQuery = <
  TType extends keyof RouterInput,
  TAction extends inferAvailableProcedureTypes<TType, 'query'>
>(
  type: TType,
  action: TAction,
  args: RouterInput[TType][TAction],
  options: QueryOptions = {}
) => {
  const invalidate = useInvalidateQueries(options.invalidateQueryKeys);
  const method = (trpc[type] as any)[action];

  const {
    data,
    isFetching: loading,
    refetch,
  } = method.useQuery(
    args,
    ensure<UseQueryOptions>({
      enabled: !options?.skip,
      onSuccess: () => invalidate(),
      onError: (error: any) => {
        showErrorNotification({
          title:
            options?.errorMessage?.title ||
            `Mutation Error: ${error.data?.httpStatus} ${error.data?.code}`,
          message: options?.errorMessage?.message || error.message,
        });
        invalidate();
      },
      refetchOnWindowFocus: false,
    })
  );

  return {
    data: data as RouterOutput[TType][TAction] | undefined,
    loading,
    refetch,
  };
};

export const useTrpcMutation = <
  TType extends keyof RouterInput,
  TAction extends inferAvailableProcedureTypes<TType, 'mutation'>
>(
  type: TType,
  action: TAction,
  options: Options = {}
) => {
  const notificationId = useId();
  const notifyId = options?.notificationId || notificationId;

  const showNotify =
    !!options?.successMessage?.message || !!options?.notificationId;

  const invalidate = useInvalidateQueries(options.invalidateQueryKeys);
  const method = (trpc[type] as any)[action];
  const { mutateAsync, isLoading: loading } = method.useMutation({
    onSuccess: () => {
      if (options?.successMessage?.message) {
        updateNotification({
          id: notifyId,
          title: options?.successMessage?.title || 'Success!',
          message:
            options?.successMessage?.message ||
            'Successfully updated your action.',
          color: 'teal',
          icon: <FontAwesomeIcon icon={faCheck} />,
          radius: 10,
        });
      }
    },
    onError: (error: any) => {
      if (showNotify) {
        updateNotification({
          id: notifyId,
          title:
            options?.errorMessage?.title ||
            `Mutation Error: ${error.data?.httpStatus} ${error.data?.code}`,
          message: options?.errorMessage?.message || error.message,
          color: 'red',
          icon: <FontAwesomeIcon icon={faX} size='sm' />,
          radius: 10,
        });
      } else {
        showErrorNotification({
          title:
            options?.errorMessage?.title ||
            `Mutation Error: ${error.data?.httpStatus} ${error.data?.code}`,
          message: options?.errorMessage?.message || error.message,
        });
      }
    },
  });
  const mutate = async (
    args: RouterInput[TType][TAction]
  ): Promise<RouterOutput[TType][TAction]> => {
    if (showNotify) {
      showNotification({
        id: notifyId,
        loading: true,
        title: 'Loading...',
        message: 'We will update you shortly.',
        autoClose: false,
        radius: 10,
      });
    }

    const result = await mutateAsync(args);
    invalidate();
    return result;
  };

  return {
    mutate,
    loading,
  };
};
