export const formatOwnerName = (
  {
    companyName,
    firstName,
    lastName,
  }: {
    firstName?: string | null;
    lastName?: string | null;
    companyName?: string | null;
  },
  options?: { lastNameFirst: boolean }
) => {
  const first = firstName?.trim() || '';
  const last = lastName?.trim() || '';
  const company = companyName?.trim() || '';

  // const dash = (!!first || !!last) && company ? ' - ' : '';

  if (!first && !last && !company) return 'Missing name';

  if (first || last) {
    if (options?.lastNameFirst) return `${last ? `${last}, ` : ''}${first}`;

    return `${first ? `${first} ` : ''}${last}`;
  }

  return `${company}`;
};
