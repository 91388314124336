import {
  EditListingForm,
  ListingConnectionsTable,
  ListingMenuButton,
  ListingOwnerTable,
  ListingSettings,
  ListingStatementsTable,
} from './_components';
import { useListingDetailsData } from './useListingDetailData';
import {
  Drawer,
  DrawerHeader,
  IconButton,
  LoadingIndicator,
  StringParam,
  useQueryParam,
} from '@finalytic/ui';
import { ensure } from '@finalytic/utils';
import { faHouse, faPencil, faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Center,
  Divider,
  Group,
  Stack,
  Tabs,
  Text,
  Transition,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';

type DrawerViews = 'overview' | 'edit';

export const ListingDetailDrawer = () => {
  const [listingId, setQuery] = useQueryParam('listing', StringParam);
  const [view, setView] = useState<DrawerViews>('overview');

  const { colors } = useMantineTheme();
  const { listing, ownerships, loading, refetch, vendors } =
    useListingDetailsData(listingId);

  const closeDrawer = () => {
    setQuery(undefined);
  };

  const isPMSConnection = !!listing?.pmsConnectionId;

  return (
    <Drawer opened={!!listingId} onClose={closeDrawer} size={800}>
      <DrawerHeader
        onClose={closeDrawer}
        title={
          listing?.id &&
          listing.id === listingId && (
            <>
              <Group noWrap py='md' sx={{ width: '100%' }}>
                <FontAwesomeIcon
                  icon={faHouse}
                  size='2xl'
                  color={colors.gray[9]}
                />
                <Box sx={{ width: '100%' }}>
                  <Text weight={500}>{listing?.title || listing?.name}</Text>
                  <Text mt={5} size='sm' color='gray'>
                    {listing?.address || 'Missing address...'}
                  </Text>
                </Box>
              </Group>
              <IconButton
                onClick={() =>
                  setView((e) => (e === 'edit' ? 'overview' : 'edit'))
                }
              >
                <FontAwesomeIcon icon={view === 'overview' ? faPencil : faX} />
              </IconButton>
              <ListingMenuButton
                listingId={listing.id}
                listingTeamId={listing.tenantId}
                listingName={listing?.name}
                isPMSListing={isPMSConnection}
                listingConnections={listing?.connection || []}
                listingStatements={listing?.statements || []}
                onSuccess={closeDrawer}
              />
            </>
          )
        }
      />

      <Divider sx={{ opacity: 0.3 }} />

      {listing?.id && listing.id === listingId ? (
        <Tabs
          value={view}
          keepMounted={true}
          styles={(theme) => ({ panel: { paddingBottom: theme.spacing.sm } })}
        >
          <Tabs.Panel value={ensure<DrawerViews>('overview')}>
            <Transition mounted={view === 'overview'} transition='fade'>
              {(styles) => (
                <Stack spacing='sm' style={styles}>
                  <ListingOwnerTable
                    rowData={ownerships}
                    refetch={refetch}
                    listingId={listingId}
                    teamId={listing?.tenantId}
                    vendors={vendors}
                  />

                  <ListingSettings
                    listingId={listing.id}
                    listingName={listing?.title || listing?.name}
                    listingTeamId={listing.tenantId}
                  />
                  <ListingConnectionsTable
                    rowData={listing?.connection as any[]}
                  />
                  <ListingStatementsTable rowData={listing?.statements || []} />
                </Stack>
              )}
            </Transition>
          </Tabs.Panel>

          <Tabs.Panel value={ensure<DrawerViews>('edit')}>
            <Transition mounted={view === 'edit'} transition='fade'>
              {(styles) => (
                <Box style={styles}>
                  <EditListingForm
                    address={listing?.address}
                    listingId={listing.id}
                    name={listing?.name}
                    title={listing?.title}
                    cancelEdit={() => setView('overview')}
                    disableAddressInput={isPMSConnection}
                  />
                </Box>
              )}
            </Transition>
          </Tabs.Panel>
        </Tabs>
      ) : loading ? (
        <LoadingIndicator isFullPageLoading={true} />
      ) : (
        <Center sx={{ flex: 1 }}>
          <Text>Missing listing.</Text>
        </Center>
      )}
    </Drawer>
  );
};
