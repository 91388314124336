import { gql, usePartnerTeams, useQuery, useTeamId } from '../../../hooks';
import { PaymentsFilterParams } from '../_types';
import {
  AmountFilter,
  CalendarFilterDateType,
  DateFilter,
  FilterWrapper,
  ResetFilterButton,
  SelectItem,
  SwitchFilter,
  SwitchFilterDataType,
} from '@finalytic/ui';
import { useMemo } from 'react';

function usePaymentFilters() {
  const [teamId] = useTeamId();

  const [teams] = usePartnerTeams();

  const whereTenantId =
    teams.length > 0 ? { _in: teams.map((i) => i.id) } : { _eq: teamId };

  const [{ data, fetching: loading }] = useQuery(
    {
      connection: [
        {
          where: {
            tenantId: gql.$`whereTenantId`,
            payments: {},
          },
          order_by: [{ name: 'asc' }],
        },
        {
          id: true,
          name: true,
        },
      ],

      paymentLines: [
        {
          where: {
            tenantId: gql.$`whereTenantId`,
          },
          distinct_on: ['type2'],
        },
        {
          id: true,
          type2: true,
        },
      ],
    },
    {
      variables: {
        whereTenantId,
      },
    }
  );

  const [{ data: appData, fetching: loading2 }] = useQuery(
    {
      connection: [
        {
          where: {
            tenantId: gql.$`whereTenantId`,
            payments: {},
          },
          distinct_on: ['appId'],
        },
        {
          id: true,
          appId: true,
          app: {
            id: true,
            name: true,
          },
        },
      ],
    },
    {
      variables: {
        whereTenantId,
      },
    }
  );

  const connectionTypes = (data?.connection.map((i) => ({
    label: i.name,
    value: i.id,
  })) || []) as SwitchFilterDataType;

  const appTypes = (appData?.connection?.map((i) => ({
    label: i.app.name,
    value: i.app.id,
  })) || []) as SwitchFilterDataType;

  const type2Types = (data?.paymentLines
    .filter((i) => !!i.type2)
    .map((i) => ({
      label: i.type2,
      value: i.type2,
    })) || []) as SwitchFilterDataType;

  const partnerTeams = useMemo(
    (): SelectItem[] => teams.map((i) => ({ label: i.name, value: i.id })),
    [teams]
  );

  return {
    connectionTypes,
    appTypes,
    type2Types,
    partnerTeams,
    loading: loading || loading2,
  };
}

type PaymentFilterProps = {
  filterState: PaymentsFilterParams;
  setFilterState: (state: Partial<PaymentsFilterParams>) => void;
  resetFilterState: () => void;
};

export const PaymentFilter = ({
  filterState,
  setFilterState,
  resetFilterState,
}: PaymentFilterProps) => {
  const { appTypes, connectionTypes, type2Types, partnerTeams, loading } =
    usePaymentFilters();

  const setType2Filter = (value?: string) => setFilterState({ type2: value });
  const setTeamIdFilter = (value?: string) => setFilterState({ teamId: value });

  const setDateFilter = (value: CalendarFilterDateType | undefined) =>
    setFilterState({
      date: value,
    });

  const setAmountFilter = ({ lt, gt }: { lt?: number; gt?: number }) =>
    setFilterState({
      amount: {
        lt,
        gt,
      },
    });

  const setAppFilter = (value: string | undefined) =>
    setFilterState({ connectionApp: value });

  const setConnectionFilter = (value?: string) =>
    setFilterState({ connectionName: value });

  const filterActive = useMemo(() => {
    const activeAmountFilter = Object.values(filterState.amount || {}).some(
      (i) => typeof i === 'number'
    );

    const copy = { ...filterState };
    copy.amount = undefined;

    return !!activeAmountFilter || Object.values(copy).some((i) => !!i);
  }, [filterState]);

  return (
    <FilterWrapper>
      {/* By Partner Teams */}
      {partnerTeams.length > 0 && (
        <SwitchFilter
          defaulButtonLabel={'Team'}
          data={partnerTeams}
          filterValue={filterState.teamId}
          setFilterValue={setTeamIdFilter}
          withSearch
        />
      )}

      {/* Date */}
      <DateFilter
        defaulButtonLabel='Date'
        setFilterValue={setDateFilter}
        filterValue={filterState.date}
      />

      {/* Cent Total */}
      <AmountFilter
        filterValue={filterState.amount}
        defaultButtonLabel='Amount'
        setFilterValue={setAmountFilter}
      />

      {/* Integrations / Apps */}
      <SwitchFilter
        defaulButtonLabel={'Integration'}
        loading={loading}
        data={appTypes}
        filterValue={filterState.connectionApp}
        setFilterValue={setAppFilter}
        withSearch
      />

      {/* Connections */}
      <SwitchFilter
        defaulButtonLabel={'Connection'}
        loading={loading}
        data={connectionTypes}
        filterValue={filterState.connectionName}
        setFilterValue={setConnectionFilter}
        withSearch
      />

      {/* Payment Lines Type2 */}
      <SwitchFilter
        defaulButtonLabel={'Line Type'}
        loading={loading}
        data={type2Types}
        filterValue={filterState.type2}
        setFilterValue={setType2Filter}
        withSearch
      />

      <ResetFilterButton hide={!filterActive} onClick={resetFilterState} />
    </FilterWrapper>
  );
};
