import {
  ActionButton,
  CalendarFilterDateType,
  CheckInOutType,
  ExtendedCalendar,
  Modal,
  ModalFooter,
  TransparentButton,
  showErrorNotification,
  useColors,
} from '../../../';
import { Box, Group, Switch } from '@mantine/core';
import { useMemo, useState } from 'react';

export const downloadFromUrl = (path: string, filename: string) => {
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export type ExportModalSubmit = (data: {
  startDate: Date;
  endDate: Date;
  includeLines: boolean;
  dateType: CheckInOutType | undefined;
}) => Promise<{
  ok: boolean;
  url?: string;
}>;

type ExportModalProps = {
  opened: boolean;
  onClose: () => void;
  submit: ExportModalSubmit;
  downloadFileName: string;
  modalTitle?: string;
  withDateType?: boolean;
};

export const ExportModal = ({
  opened,
  onClose,
  submit,
  downloadFileName = 'export_file',
  modalTitle = 'Export CSV',
  withDateType,
}: ExportModalProps) => {
  const { gray } = useColors();

  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [hasPayouts, setHasPayouts] = useState<boolean>(false);
  const [dates, setDates] = useState<CalendarFilterDateType>();
  const [dateType, setDateType] = useState<CheckInOutType>();

  const filterIsActive = useMemo(
    () => !!(dates && !dates?.includes(null)),
    [dates]
  );

  const handleExport = async () => {
    setIsGenerating(true);
    if (!filterIsActive) return;

    const startDate = dates![0];
    const endDate = dates![1];

    if (!startDate || !endDate) {
      setIsGenerating(false);
      return showErrorNotification({ message: 'Missing date input.' });
    }

    const data = await submit({
      endDate,
      startDate,
      includeLines: hasPayouts,
      dateType,
    });

    setIsGenerating(false);

    if (!data.url) {
      return showErrorNotification({ message: 'Missing download url.' });
    }

    downloadFromUrl(data.url, downloadFileName);

    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} size={800} title={modalTitle}>
      <Box>
        <Box pt={5} sx={{ minHeight: 300 }}>
          <ExtendedCalendar
            setFilterValue={setDates}
            dateType={dateType || 'all'}
            setDateType={withDateType ? setDateType : undefined}
            filterValue={dates}
          />
        </Box>

        {/* Footer */}
        <ModalFooter>
          <Group position='apart' my={5}>
            <Switch
              checked={hasPayouts}
              onChange={(event) => setHasPayouts(event.currentTarget.checked)}
              color='teal'
              radius={18}
              label='Include lines'
              size='md'
              styles={(theme) => ({
                root: {
                  display: 'flex',
                },
                track: { backgroundColor: '#5f5f5f', cursor: 'pointer' },
                labelWrapper: {
                  label: {
                    fontSize: theme.fontSizes.xs,
                    cursor: 'pointer',
                  },
                },
              })}
            />
            <Group>
              <TransparentButton onClick={onClose} color={gray.dark}>
                Cancel
              </TransparentButton>
              <ActionButton
                onClick={handleExport}
                loading={isGenerating}
                disabled={!filterIsActive}
              >
                Download CSV
              </ActionButton>
            </Group>
          </Group>
        </ModalFooter>
      </Box>
    </Modal>
  );
};
