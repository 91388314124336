import { useConnectionModalContext } from '../_context';
import { ConnectingContainer } from '../components/ConnectingContainer';
import { InputsBySchema, Schema, useExtension } from '@finalytic/data-ui';
import { ActionButton, StringParam, useQueryParam } from '@finalytic/ui';
import { Box, Stack, Text, useMantineTheme } from '@mantine/core';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

type SchemaInputType = {
  [key: string]: string | number | undefined;
};

type AuthType = {
  schema?: Schema;
  type?: string;
  uri?: string;
  extension?: { url: string };
};

export const ConnectionConfirmModalScreen = () => {
  const { app, connectApp, resetView } = useConnectionModalContext();
  const auth = app?.authentication as AuthType;

  const { extension } = useExtension();
  const { primaryColor: appName } = useMantineTheme();

  const [connectionId] = useQueryParam('connectionId', StringParam);

  const methods = useForm({ mode: 'onChange' });

  const addConnection = (data: SchemaInputType) => {
    if (auth?.type === 'credentials' && auth.schema) {
      // Credentials auth
      connectApp({ input: data });
    } else if (auth?.uri) {
      // oAuth redirect
      window.location.href = auth.uri;
    }
  };

  useEffect(() => {
    if (auth && !auth.type) {
      connectApp({ input: {} });
    }
  }, [auth]);

  const openStore = () => window.open(extension.store_url, '_blank');

  const openExtension = async () => {
    openStore();
    resetView();
    // setLoadExtension(true);

    // sendMessage(
    //   {
    //     message: 'redirect',
    //     data: {
    //       redirect_url: auth.extension?.url,
    //       appId: app?.id,
    //       connectionId,
    //     },
    //   },
    //   function (reply) {
    //     const lastError = chrome.runtime.lastError;

    //     console.log(lastError);

    //     if (
    //       lastError &&
    //       lastError.message ===
    //         'Could not establish connection. Receiving end does not exist.'
    //     ) {
    //       setLoadExtension(false);
    //       openStore();
    //     }

    //     if (reply?.exists) {
    //       resetView();
    //     } else {
    //       openStore();
    //       setLoadExtension(false);
    //     }
    //   }
    // );
  };

  return (
    <ConnectingContainer
      icon={app?.iconRound}
      text={
        <>
          <Text size='md'>{connectionId ? 'Reconnect' : 'Connecting'} to</Text>
          <Text weight={500} sx={{ fontSize: 22, letterSpacing: '0.5px' }}>
            {app?.name || 'Empty'}
          </Text>
        </>
      }
    >
      {auth.extension ? (
        <>
          <Text>
            Please connect to {app?.name} using our browser extension.
          </Text>
          <ActionButton onClick={openExtension}>Open Extension</ActionButton>
        </>
      ) : (
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            minWidth: '100%',
            [`@media (min-width: ${theme.breakpoints.xs})`]: {
              minWidth: 380,
            },
          })}
        >
          {auth?.schema && (
            <InputsBySchema
              schema={auth.schema}
              methods={methods}
              // Hide for sage intacct :()
              hideFields={
                appName === 'VRPlatform'
                  ? ['senderId', 'senderPassword']
                  : undefined
              }
            />
          )}
          {/* Buttons */}
          <Stack mt={!auth?.schema ? 40 : 10}>
            <ActionButton
              onClick={methods.handleSubmit(addConnection)}
              sx={{ height: 40 }}
              disabled={
                auth?.type === 'credentials'
                  ? !methods.formState.isValid
                  : undefined
              }
            >
              Connect
            </ActionButton>
          </Stack>
        </Box>
      )}
    </ConnectingContainer>
  );
};
