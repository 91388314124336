import { useSettingByIdMutation, useTeam } from '../../../../hooks';
import {
  Select,
  SelectItem,
  TransparentButton,
  showErrorNotification,
  useColors,
} from '@finalytic/ui';
import { ICellRendererParams } from '@finalytic/ui-grid';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

type Props = {
  settingGroup: string;
  settingKey: string;
};

export type VendorSource =
  | {
      label: string;
      value: string;
      settingId: string;
    }
  | undefined;

export const SelectOwnershipVendor = (
  params: ICellRendererParams<any, VendorSource> & Props
) => {
  const [{ automations, id: teamId }] = useTeam();

  const mappingKey = 'vendor';

  const statementAutomation = (automations || []).find((a) =>
    Object.keys(a.mappings || {}).includes(mappingKey)
  );

  const leftType = statementAutomation?.mappings[mappingKey]?.left?.schema;
  const rightType = statementAutomation?.mappings[mappingKey]?.right?.schema;

  const { mutate } = useSettingByIdMutation('Updated vendor successfully.');

  const { purple, gray } = useColors();
  const [open, setOpen] = useState(false);

  const refetch = params.context.refetchTable;
  const vendors: SelectItem[] = params.context.vendors || [];

  const forceCellRefresh = () =>
    params.api.refreshCells({
      columns: ['ownerSplit'],
      force: true,
      rowNodes: [params.node],
    });

  const setValue = (item: SelectItem) => {
    mutate({
      type: 'upsert',
      setting: {
        id: params.value?.settingId,
        value: item.value,
        teamId,
        key: mappingKey,
        leftType,
        rightType,
        target: params.data.id,
        leftConnectionId: statementAutomation?.leftConnectionId,
        rightConnectionId: statementAutomation?.rightConnectionId,
        automationId: statementAutomation?.automationId,
      },
    })
      .then(refetch)
      .then(forceCellRefresh);
  };

  const removeValue = () => {
    if (!params.value?.settingId) {
      showErrorNotification({ message: 'Missing previous setting.' });
      return;
    }
    mutate({
      type: 'remove',
      settingId: params.value.settingId,
      extraMutation: (q) => {
        const res = q.updateListingOwner({
          pk_columns: { id: params.data.id },
          _set: { split: null },
        })?.id;

        return res;
      },
    })
      .then(refetch)
      .then(forceCellRefresh);
  };

  if (!statementAutomation?.mappings[mappingKey]) return null;

  return (
    <Select
      data={vendors} // label: source.description ; value: source.id
      value={params.value || undefined} // label: setting.value ; value: setting.id
      removeValue={removeValue}
      withSearch
      opened={open}
      onClose={() => setOpen(false)}
      setValue={setValue}
      popoverWidth={400}
      searchPlaceholder='Search vendors...'
      customTarget={
        <TransparentButton
          ref={(ref) => {
            if (!ref) return;
            ref.onclick = (e: any) => {
              e.stopPropagation(); // this works
              setOpen((o) => !o);
            };
          }}
          leftIcon={
            !params.value?.value ? <FontAwesomeIcon icon={faPlus} /> : undefined
          }
          sx={{
            height: 25,
            '& .mantine-Button-label': {
              opacity: 1,
              fontSize: 13,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              display: 'block',
            },
            maxWidth: '220px',
          }}
          color={params.value?.value ? gray.dark : purple.base}
        >
          {params.value?.label || 'Add vendor'}
        </TransparentButton>
      }
    />
  );
};
