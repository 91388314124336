import { useMe, useV2TransactionQuery } from '../../hooks';
import { createStyles } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

interface HubspotConversationsContextType {
  toggleWidget: () => void;
  isOpen: boolean;
  unreadMessagesCount: number;
}

const HubspotConversationsContext =
  createContext<HubspotConversationsContextType | null>(null);

const HUBSPOT_INLINE_EMBED_ELEMENT_ID =
  'hubspot-conversations-inline-embed-selector';

function useRealUserEmail(id: string | undefined) {
  const { data: userEmail } = useV2TransactionQuery(
    (q, args) => {
      const userEmail = q.userById({ id: args.id })?.email;

      return userEmail;
    },
    {
      queryKey: 'users',
      skip: !id,
      variables: {
        id,
      },
    }
  );

  return userEmail;
}

function eraseHubspotCookies() {
  const hostname = window.location.hostname.split('.');

  // subdomains like portal.vrplatform.app are set as .vrplatform.app in cookies
  const domain =
    hostname.length === 3
      ? `.${hostname.slice(1).join('.')}`
      : hostname.join('');

  // Main anaylytics cookie
  document.cookie = `${'__hstc'}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
  // Cookie keeping track of visitor identity
  document.cookie = `${'hubspotutk'}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
  // Cookie to keep track of sessions
  document.cookie = `${'__hssc'}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
  // This cookie is used to recognize visitors who chat with you via the chatflows tool.
  document.cookie = `${'messagesUtk'}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
}

export const HubspotConversationsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isReady, setIsReady] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const ref = useClickOutside(() => setIsOpen(false));

  const { classes } = useStyles(isOpen);

  const user = useMe();
  const userEmail = useRealUserEmail(user?.realUserId);

  const hideWidget = useCallback(() => {
    setIsOpen(false);
  }, []);

  const showWidget = useCallback(() => {
    if (!isReady) return;

    setTimeout(() => {
      if (!window?.HubSpotConversations?.widget) return;
      window.HubSpotConversations.widget.load();
      window.HubSpotConversations.widget.open();
    }, 100);

    setIsOpen(true);
  }, [isReady]);

  const toggleWidget = useCallback(() => {
    if (isOpen) {
      hideWidget();
      setIsOpen(false);
    } else {
      setIsOpen(true);
      showWidget();
    }
  }, [hideWidget, isOpen, showWidget]);

  const onConversationsReady = useCallback(() => {
    setIsReady(true);
  }, []);

  useEffect(
    function init() {
      if (window.HubSpotConversations) {
        onConversationsReady();
      } else {
        window.hsConversationsOnReady = [onConversationsReady];
      }
    },
    [onConversationsReady]
  );

  useEffect(
    function updateSettingsAndLoadWidget() {
      // Reset Hubspot identification cookie
      eraseHubspotCookies();

      window.hsConversationsSettings = {
        ...window.hsConversationsSettings,
        loadImmediately: true,
        identificationEmail: userEmail || '',
      };

      if (userEmail) window.HubSpotConversations?.widget?.load();
    },
    [userEmail]
  );

  useEffect(
    function addEventListeners() {
      if (!isReady) return;

      function listener(payload: { unreadCount: number }) {
        setUnreadMessagesCount(payload.unreadCount);
      }

      window.HubSpotConversations.on(
        'unreadConversationCountChanged',
        listener
      );

      return () => {
        window.HubSpotConversations.off(
          'unreadConversationCountChanged',
          listener
        );
      };
    },
    [isReady]
  );

  useEffect(
    function refreshConversationsOnRouteChange() {
      if (!isReady) return;

      window.HubSpotConversations?.widget?.refresh();
    },
    [isReady]
  );

  const value = useMemo(
    () => ({
      isOpen,
      toggleWidget,
      unreadMessagesCount,
    }),
    [isOpen, toggleWidget, unreadMessagesCount]
  );

  return (
    <HubspotConversationsContext.Provider value={value}>
      {children}

      {ReactDOM.createPortal(
        <div
          ref={ref}
          className={classes.chatWidgetContainer}
          id={HUBSPOT_INLINE_EMBED_ELEMENT_ID}
          tabIndex={isOpen ? -1 : undefined}
          style={{ display: !isOpen ? 'none' : undefined }}
        />,
        document.body
      )}
    </HubspotConversationsContext.Provider>
  );
};

const useStyles = createStyles((theme, isOpen: boolean) => ({
  chatWidgetContainer: {
    overflow: 'hidden',
    zIndex: 400, // this is the max possible value
    position: 'fixed',
    bottom: 140,
    left: theme.spacing.xl,
    width: 376,
    // height: 500,
    height: isOpen ? 500 : 0,
    opacity: isOpen ? 1 : 0,
    transition: 'opacity 0.4s ease-out',
    display: 'block',
    borderRadius: 10,
    backgroundColor: theme.white,
    boxShadow: '0 5px 20px rgb(0 0 0 / 10%)',

    '#hubspot-conversations-inline-parent': {
      width: '100%',
      height: '100%',
    },

    '#hubspot-conversations-inline-iframe': {
      width: '100%',
      height: '100%',
      border: 'none',
    },
  },
}));

export function useHubspotConversations() {
  const context = useContext(HubspotConversationsContext);

  if (context === null) {
    throw new Error(
      'useHubspotConversations must be used within HubspotConversationsProvider'
    );
  }

  return context;
}

declare global {
  interface Window {
    hsConversationsSettings: Record<string, any>;
    hsConversationsOnReady: Array<() => void>;
    HubSpotConversations: {
      on: any;
      off: any;
      widget: {
        status: () => { loaded: boolean; pending: boolean };
        load: (params?: { widgetOpen: boolean }) => void;
        remove: () => void;
        open: () => void;
        close: () => void;
        refresh: (openToNewThread?: boolean) => void;
      };
    };
  }
}
