import { ReservationPaymentLinesType } from '../_hooks';
import {
  InfoButtonCell,
  StringParam,
  Table,
  useQueryParamSet,
} from '@finalytic/ui';
import { ColDef, ICellRendererParams } from '@finalytic/ui-grid';
import { formatCurrency, toTitleCase } from '@finalytic/utils';
import { utc } from 'dayjs';
import { useMemo } from 'react';

type ReservationPaymentsTableProps = {
  rowData: ReservationPaymentLinesType | undefined;
};

export const ReservationPaymentsTable = ({
  rowData,
}: ReservationPaymentsTableProps) => {
  const setPayment = useQueryParamSet('payment', StringParam);

  const navigate = (paymentId: string) => setPayment(paymentId);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'paymentId',
        headerName: '',
        rowGroup: true,
        showRowGroup: true,
        cellRenderer: 'agGroupCellRenderer',
        width: 50,
        cellStyle: (params) => {
          if (params.node.footer)
            return {
              display: 'none',
            };
        },
      },
      {
        field: 'payment.payedAt',
        headerName: 'Paid On',
        sort: 'asc',
        flex: 1,
        aggFunc: (params) => {
          const data = params.rowNode.allLeafChildren?.[0]?.data;
          const payedAt = data?.payment?.payedAt || '';
          const description = data?.payment?.description || 'Payment';
          return [
            toTitleCase(description),
            payedAt ? `(${utc(payedAt).format('DD MMM YYYY')})` : '',
          ]
            .filter((i) => !!i)
            .join(' ');
        },
        cellRenderer: ({ data, node, value }: ICellRendererParams) => {
          if (node.footer) return null;

          if (!data) return value;

          return data?.description;
        },
        // valueFormatter: ({ value, node }) =>
        //   value && !node?.footer ? utc(value).format('DD MMM YYYY') : '',
      },
      {
        field: 'centTotal',
        headerName: 'Amount',
        type: 'numericColumn',
        aggFunc: 'sum',
        // cellStyle: (params) => {
        //   if (!params.data)
        //     return {
        //       fontWeight: 500,
        //     };

        //   return undefined;
        // },
        valueFormatter: ({ value, data, node }) => {
          return formatCurrency(
            value / 100,
            data?.reservation?.currency ||
              node?.allLeafChildren?.[0]?.data?.reservation?.currency
          );
        },
      },
      {
        sortable: false,
        width: 60,
        cellRenderer: ({ data, node }: ICellRendererParams) => {
          if (data || node.footer) return null;
          return <InfoButtonCell withoutButton />;
        },
        suppressSizeToFit: true,
      },
    ],
    []
  );

  return (
    <Table
      animateRows
      columnDefs={columnDefs}
      rowData={rowData}
      groupDisplayType='custom'
      groupIncludeTotalFooter
      gridOptions={{
        groupDefaultExpanded: 1,
      }}
      onCellClicked={(params) => {
        if (
          !params.data &&
          !params.node.footer &&
          params.colDef.field !== 'paymentId'
        ) {
          navigate(params.node.allLeafChildren[0]?.data?.payment?.id);
        }

        if (params.colDef.field === 'paymentId' && params.node.isExpandable()) {
          params.node.setExpanded(!params.node.expanded);
        }
      }}
      getRowStyle={(params) => {
        if (!params.data && !params.node.footer) {
          return { cursor: 'pointer' };
        }

        if (params.node.footer) {
          return {
            fontWeight: 'bold',
          } as any;
        }
      }}
      defaultColDef={{
        resizable: false,
        suppressMovable: true,
        sortable: false,
      }}
      pagination={false}
      suppressAggFuncInHeader
    />
  );
};
