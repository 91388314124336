import { ArrayParam, StringParam, useQueryParam } from '@finalytic/ui';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';

export const runParamKey = 'runAutomationId'; // string
export const previewParamKey = 'previewIds'; // string[]
export const resultParamKey = 'resultIds'; // string[]
export const resultViewParamKey = 'runResultView'; // string

import { getNamespaceAndType } from '../components';
import { gqlV2, useNotifiedV2Mutation } from './graphql-v2';
import { ensure } from '@finalytic/utils';

type DrawerType = 'run' | 'preview' | 'result';

export const useRunDrawer = () => {
  const { mutate: runTask, loading: runTaskLoading } = useRunMutation();

  const [automationId, setAutomationId] = useQueryParam(
    runParamKey,
    StringParam
  );
  const [preview, setPreview] = useQueryParam(previewParamKey, ArrayParam);
  const [result, setResult] = useQueryParam(resultParamKey, StringParam);
  const [, setResultView] = useQueryParam(resultViewParamKey, StringParam);

  const navigate = useNavigate();
  const navigateBack = (v?: number) => navigate(typeof v === 'number' ? v : -1);

  const activeDrawer = useMemo<DrawerType | undefined>(() => {
    if (result) return 'result';
    if (preview && preview.length > 0) return 'preview';
    if (automationId) return 'run';
    return undefined;
  }, [automationId, preview, result]);

  const close = (drawer?: DrawerType) => {
    if (drawer === 'preview') return setPreview(undefined);
    if (drawer === 'run') return setAutomationId(undefined);
    if (drawer === 'result') {
      setResultView(undefined);
      return setResult(undefined);
    }

    setPreview(undefined);
    setAutomationId(undefined);
    setResult(undefined);
    setResultView(undefined);
  };

  const openDrawer = (automationId: string) => setAutomationId(automationId);

  return {
    activeDrawer,
    close,
    setPreview,
    setAutomationId,
    automationId,
    navigateBack,
    openDrawer,
    result,
    setResult,
    runTask: async (args: gqlV2.run_automation_request) => {
      const result = await runTask(args);
      setResult(result.taskId);
      return result;
    },
    runTaskLoading,
    getAutomationInput,
  };
};

const useRunMutation = () => {
  const { mutate, loading } = useNotifiedV2Mutation(
    (mutate, args: gqlV2.run_automation_request) => {
      const { runId, workflowId, taskId } = mutate.runAutomation({
        object: args,
      }) as gqlV2.run_automation_response;
      return { runId, workflowId, taskId };
    },
    {
      /*successMessage: {
        message:
          "Successfully set up task, follow the progress to see when it's completed.",
      },*/
    }
  );

  const runAutomation = async (arg: gqlV2.run_automation_request) => {
    return await mutate({
      args: ensure<gqlV2.run_automation_request>(arg),
    });
  };

  return {
    mutate: runAutomation,
    loading,
  };
};

export const getAutomationInput = (
  template:
    | {
        label: string | undefined;
        name: string | undefined;
        input: string | undefined;
        params: any;
      }
    | undefined
): {
  field: string;
  namespace: string;
  type: string;
  schema: string;
} => {
  if (!template) return { field: '', namespace: '', type: '', schema: '' };
  if (template.input && !['form', 'report'].includes(template.input)) {
    const [namespace, type] = getNamespaceAndType(template.input);
    return {
      field: 'input',
      namespace,
      type,
      schema: `${namespace}.${type}`,
    };
  }
  const d = Object.entries(template.params?.properties || {})
    .filter(
      ([key, value]: [string, any]) =>
        value.schema && (key.endsWith('Id') || key.endsWith('No'))
    )
    .map(([field, value]: [string, any]) => {
      const [namespace, type] = getNamespaceAndType(value.schema);
      return { field, namespace, type, schema: `${namespace}.${type}` };
    })[0];
  if (d) return d;
  return { field: '', namespace: '', type: '', schema: '' };
};
