import { useStyles } from './_styles';
import { IParentRoute } from './_types';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Anchor,
  Box,
  Collapse,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

interface NavbarRouteProps extends IParentRoute {
  navbarIsExpanded: boolean;
  v2Icon?: boolean;
}

export const NavbarRoute = ({
  title,
  icon,
  subRoutes,
  link,
  onClick,
  navbarIsExpanded,
  notification,
  v2Icon = false,
}: NavbarRouteProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapse: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    // event.stopPropagation();
    setIsCollapsed((e) => !e);
  };

  const { classes } = useStyles({ v2Icon });

  const hasRightIcon = navbarIsExpanded && (subRoutes || notification);
  const hasSubRoutes = navbarIsExpanded && subRoutes;
  const hasNotification = navbarIsExpanded && notification;

  const ParentLink = (
    <Group
      spacing={4}
      noWrap
      position='apart'
      py={5}
      px={8}
      mb={hasRightIcon ? 5 : undefined}
    >
      {/* Left Icon & Route Title */}
      <Group spacing={8} noWrap>
        {icon}
        {navbarIsExpanded && (
          <Text
            component='span'
            sx={{
              flex: 1,
              maxWidth: 140,
              lineHeight: 'normal',

              textOverflow: 'ellipsis',
              overflowX: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </Text>
        )}
      </Group>

      {/* Notification Icon */}
      {hasNotification && notification}

      {/* Chevron Icon for subroutes */}
      {hasSubRoutes && (
        <Box
          sx={{
            borderRadius: 8,
            '&:hover': {
              backgroundColor: '#5C617840',
            },
          }}
        >
          <ActionIcon
            variant='transparent'
            sx={{
              transform: isCollapsed ? 'rotate(180deg)' : '',
              transition: 'transform 0.3s ease-out',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={hasRightIcon ? handleCollapse : undefined}
          >
            <FontAwesomeIcon icon={faCaretDown} />
          </ActionIcon>
        </Box>
      )}
    </Group>
  );

  return (
    <Box
      sx={{
        '& span': {
          opacity: '50%',
          color: '#fff',
        },
        '& a, & a:hover': {
          textDecoration: 'none',
        },
      }}
    >
      {hasSubRoutes ? (
        <Anchor
          component={NavLink}
          to={link || ''}
          onClick={onClick}
          className={classes.navLink}
        >
          <Box className={classes.navLink}>{ParentLink}</Box>
        </Anchor>
      ) : (
        // Active Styling defined with css
        <Anchor
          component={NavLink}
          to={link || ''}
          onClick={onClick}
          className={classes.navLink}
        >
          {ParentLink}
        </Anchor>
      )}

      {/* Route subRoutes */}
      {hasSubRoutes && (
        <Collapse in={isCollapsed}>
          <Stack spacing={0}>
            {subRoutes.map((childRoute, ci) => (
              <Group
                key={`${childRoute?.title}${ci}`}
                ml={16}
                align='flex-end'
                position='left'
                spacing={2}
              >
                <Box mb={4} sx={{ opacity: 0.5 }}>
                  {ci === 0 ? (
                    <svg
                      width='18'
                      height='29'
                      viewBox='0 0 18 29'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M17 28.5C17.5523 28.5 18 28.0523 18 27.5C18 26.9477 17.5523 26.5 17 26.5L17 28.5ZM0 4.56748e-08L8.15732e-07 19.5L2 19.5L2 -4.56748e-08L0 4.56748e-08ZM9 28.5L17 28.5L17 26.5L9 26.5L9 28.5ZM8.15732e-07 19.5C1.02366e-06 24.4706 4.02944 28.5 9 28.5L9 26.5C5.13401 26.5 2 23.366 2 19.5L8.15732e-07 19.5Z'
                        fill='#494A4C'
                      />
                    </svg>
                  ) : (
                    <svg
                      width='18'
                      height='71'
                      viewBox='0 0 18 71'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ marginTop: '-2.4rem' }}
                    >
                      <path
                        d='M17 70.2593C17.5523 70.2593 18 69.8115 18 69.2593C18 68.707 17.5523 68.2593 17 68.2593L17 70.2593ZM0 4.56748e-08L2.56262e-06 61.2593L2 61.2593L2 -4.56748e-08L0 4.56748e-08ZM9 70.2593L17 70.2593L17 68.2593L9 68.2593L9 70.2593ZM2.56262e-06 61.2593C2.77055e-06 66.2298 4.02944 70.2593 9 70.2593L9 68.2593C5.13401 68.2593 2 65.1253 2 61.2593L2.56262e-06 61.2593Z'
                        fill='#494A4C'
                      />
                    </svg>
                  )}
                </Box>
                <Anchor
                  component={NavLink}
                  to={`${childRoute.group}/${childRoute.url}`}
                  style={{ flex: 1 }}
                  className={classes.subNavLink}
                >
                  <Box
                    pl={8}
                    py={2}
                    sx={{
                      flex: 1,
                      borderRadius: 10,
                      cursor: 'pointer',
                    }}
                  >
                    <span>{childRoute.title}</span>
                  </Box>
                </Anchor>
              </Group>
            ))}
          </Stack>
        </Collapse>
      )}
    </Box>
  );
};
