import { Popover, PopoverContent, SingleSelectButton } from './_components';
import { SelectItem } from './_types';
import { Box, Popover as MantinePopover } from '@mantine/core';
import { ReactNode, useState } from 'react';

type SingleSelectProps = {
  withSearch?: boolean;
  data: SelectItem[];
  value?: SelectItem;
  setValue: (value: SelectItem) => void;
  popoverWidth?: number | string;
  withBorder?: boolean;
  placeholder?: string;
  preventClose?: boolean;
  defaultOpen?: boolean;
  onClose?: () => void;
  customTarget?: ReactNode;
  customOpenState?: boolean;
  disabled?: boolean;
};

export const SingleSelect = ({
  withSearch,
  data,
  value,
  setValue,
  popoverWidth = 350,
  withBorder = false,
  placeholder,
  preventClose = false,
  defaultOpen = false,
  onClose,
  customTarget,
  customOpenState,
  disabled,
}: SingleSelectProps) => {
  const [opened, setOpened] = useState(defaultOpen);

  const set = (value: SelectItem) => {
    setValue(value);
    if (!preventClose) setOpened(false);
  };

  const close = () => {
    if (onClose) onClose();
    setOpened(false);
  };

  const toggle = () => (!disabled ? setOpened((o) => !o) : null);

  return (
    <Popover
      opened={customOpenState || opened}
      onClose={close}
      popoverWidth={popoverWidth}
    >
      <MantinePopover.Target>
        {!customTarget ? (
          <SingleSelectButton
            label={value?.label || ''}
            onClick={toggle}
            opened={opened}
            withBorder={withBorder}
            placeholder={placeholder}
            disabled={!!disabled}
            loading={false}
          />
        ) : (
          <Box onClick={toggle}>{customTarget}</Box>
        )}
      </MantinePopover.Target>
      <MantinePopover.Dropdown>
        <PopoverContent
          activeValue={value}
          setValue={set}
          data={data}
          withSearch={withSearch}
        />
      </MantinePopover.Dropdown>
    </Popover>
  );
};
