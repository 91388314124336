import { Input } from '@finalytic/ui';
import { useEffect, useRef } from 'react';

export const TextEditor = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (v: string) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <Input
      onChange={(e) => setValue(e.currentTarget.value)}
      type='text'
      inputName='text'
      placeholderText=''
      radius={0}
      value={value}
      ref={inputRef}
    />
  );
};
