import { NetRevenueColumn, StatementLine } from '../../statements/_types';
import { defaultStatementFormulaFields } from './default-statement-formula-fields';
import { formatColumnsToFormulaFields } from './format-columns-to-formula-fields';
import { groupBy, sum } from '@finalytic/utils';

export const replaceStatementFormulaFields = (
  expression: string,
  tableColumns: NetRevenueColumn[],
  statementLines: StatementLine[]
) => {
  const match = /"(.*?)"/g;
  const expressionFields = [...expression.matchAll(match)].map((res) => res[0]);

  const getTotalByRemoteId = (id: string) =>
    (sum(
      statementLines.filter((i) => i.group?.remoteId === id),
      (x) => x.centTotal
    ) || 0) / 100;

  const fields = [
    ...defaultStatementFormulaFields,
    ...formatColumnsToFormulaFields(tableColumns),
  ];

  expressionFields.forEach((field) => {
    const cleanField = field.slice(1, -1);
    const fieldType = cleanField.split('.')[0];

    const replace = (value: string) => {
      const reg = new RegExp(field, 'g');
      return expression.replace(reg, value);
    };

    switch (fieldType) {
      case 'acc': {
        const remoteId = cleanField.split('.').reverse()[0];
        const total = getTotalByRemoteId(remoteId);

        expression = replace(total.toString());
        break;
      }

      case 'col': {
        const columnName = cleanField.slice(4);
        const column = tableColumns.find((col) => col.name === columnName);
        const columnId = column?.id;
        if (!column) break;

        if (column.type === 'sumAccounts') {
          const remoteIds = column.value || [];

          const total = (remoteIds as string[]).reduce(
            (prev, curr) => prev + getTotalByRemoteId(curr),
            0
          );
          expression = replace(total.toString());
        } else if (column.type === 'sumColumns') {
          const sumsOfColumns = tableColumns
            .filter((col) => column.value.includes(col.id))
            .map((col) =>
              (col.value as string[]).reduce(
                (prev, curr) => prev + getTotalByRemoteId(curr),
                0
              )
            );

          const total = sum(sumsOfColumns);
          expression = replace(total.toString());
        } else if (column.type === 'metadata') {
          const metadataKey = column.value;
          const defaultValue = 0;

          const filteredLines =
            statementLines?.filter((line) => line?.metadata?.[metadataKey]) ||
            [];

          const byReservation = groupBy(
            filteredLines,
            (x) => x.reservation?.id || 'missing_reservation'
          );

          const value =
            sum(
              Object.values(byReservation).map(
                (lines) => lines[0].metadata?.[metadataKey]
              )
            ) || defaultValue;

          expression = replace(value.toString());
        }
        break;
      }

      default: {
        // default is fields like reservation_nights or reservation_guests
        const defField = fields.find((i) => i.displayValue === field);
        if (!defField) break;
        const byReservation = groupBy(
          statementLines,
          (x) => x.reservation?.id || 'missing_reservation'
        );

        const value = sum(
          Object.values(byReservation).map((lines) =>
            accessNestedObject2(lines[0], defField.id)
          )
        );
        expression = replace(value.toString());
        break;
      }
    }
  });

  return expression;
};

const accessNestedObject2 = (o: Record<string, any>, s: string) => {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  const a = s.split('.');
  let t: any;
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      t = o[k];
    } else {
      return;
    }
  }
  return t;
};
