import { Box, BoxProps, Slider, Text } from '@mantine/core';
import { useMemo } from 'react';

type Props = {
  properties: number;
  setProperties: (num: number) => void;
};

export const usePriceByProperties = (
  sliderCount: number,
  tiers: {
    up_to: number;
    unit_amount: number;
  }[]
) => {
  return useMemo(() => {
    const properties = sliderCount === 1 ? sliderCount : sliderCount * 3;

    return tiers.reduce((acc, tier, index, arr) => {
      const lastUpTo = arr[index - 1]?.up_to || 0;
      const upTo = tier.up_to || lastUpTo + 10;

      let newVal = 0;

      if (properties <= upTo && properties > lastUpTo) {
        newVal = (properties + 10 - upTo) * tier.unit_amount;
      } else if (arr.length === index + 1 && properties > upTo) {
        newVal = (properties - upTo) * tier.unit_amount;
      } else if (properties > upTo) {
        newVal = 10 * tier.unit_amount;
      }

      return acc + newVal;
    }, 0);
  }, [sliderCount, tiers]);
};

export const PricingSlider = ({
  properties,
  setProperties,
  ...boxProps
}: Props & BoxProps) => {
  return (
    <Box
      p='xl'
      mb='xl'
      mt='md'
      maw={640}
      mx='auto'
      sx={(theme) => ({
        borderRadius: theme.radius.md,
        backgroundColor: theme.colors.gray[0],
      })}
      {...boxProps}
    >
      <Text m={0} mb='lg' component='p' size={14}>
        How many properties do you manage?
      </Text>
      <Slider
        value={properties}
        onChange={setProperties}
        mb='sm'
        min={1}
        marks={[
          { value: 1, label: '1' },
          { value: 33, label: '100' },
          { value: 66, label: '200' },
          { value: 100, label: '300+' },
        ]}
        label={(num) => `${num !== 1 ? num * 3 : num} properties`}
        styles={{
          trackContainer: {
            maxWidth: '100%',
          },
        }}
      />
    </Box>
  );
};
