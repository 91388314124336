import { MantineNumberSize, Paper, Text } from '@mantine/core';
import { ReactNode } from 'react';

export const TableTooltip = ({
  children,
  maxWidth = 400,
  fontSize = 'sm',
}: {
  children: ReactNode;
  maxWidth?: number;
  fontSize?: MantineNumberSize;
}) => (
  <Paper shadow='md' radius='md' px='md' py={5} sx={{ maxWidth }}>
    <Text component='span' size={fontSize}>
      {children}
    </Text>
  </Paper>
);
