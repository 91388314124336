import { createContext, useContext } from 'react';

export type SpotlightContextRaw = {
  hypervisorQueue?: string;
  integrationQueue?: string;
};
export type SpotlightContext = {
  current: SpotlightContextRaw;
  set: SpotlightSetState;
} & SpotlightContextRaw;
export type SpotlightSetState = React.Dispatch<
  React.SetStateAction<SpotlightContextRaw>
>;

export const spotlightContext = createContext<SpotlightContext>(
  undefined as any
);

export function useSpotlightContext() {
  return useContext(spotlightContext);
}
