import { ListStatement } from '../../hooks';
import { StatementStatusBadge } from '../badges';
import { StatementEllipsisMenu } from '../ellipsis-menu';
import { getStatementSummary } from '@finalytic/common';
import { formatOwnerName } from '@finalytic/data-ui';
import {
  NoStatementsTableOverlay,
  Table,
  TableStylingProps,
  useSavedColumnDefs,
} from '@finalytic/ui';
import {
  AgGridReact,
  CellClickedEvent,
  ICellRendererParams,
  ValueFormatterParams,
} from '@finalytic/ui-grid';
import { ensure, formatCurrency, sum, utc } from '@finalytic/utils';
import { Box, Group, Text } from '@mantine/core';
import { forwardRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

type StatementListTableProps = {
  rowLimit?: number;
  rowData: ListStatement[];
  onRowSelected?: (val: string[]) => void;
  refetch: () => void;
  selectable: boolean;
} & TableStylingProps;

export const StatementListTable = forwardRef<
  AgGridReact,
  StatementListTableProps
>(
  (
    {
      rowLimit,
      rowData,
      onRowSelected,
      refetch,
      selectable,
      ...tableStylingProps
    },
    ref
  ) => {
    const navigate = useNavigate();

    const ownerStatements = useMemo(() => {
      return rowData.map((ownerStatement) => {
        const { formatted, amounts } = getStatementSummary(
          ownerStatement as any
        )!;
        return {
          ...ownerStatement,
          formatted,
          amounts,
        };
      });
    }, [rowData]);

    const formatNumberColumn = ({
      data,
      column,
    }: ValueFormatterParams<any, any>) => {
      const colId = column.getId().split('.')[1];

      return data.formatted[colId] || '';
    };

    const columnDefs = useSavedColumnDefs(
      [
        {
          field: 'owner',
          headerName: 'Owners',
          minWidth: 150,
          sort: rowLimit ? undefined : 'asc',
          checkboxSelection: selectable,
          headerCheckboxSelection: selectable,
          valueGetter: ({ data, node }) => {
            if (node?.rowPinned === 'bottom') return 'Total:';

            if (!data?.listing) return;

            return ensure<ListStatement>(data)
              .owners?.map((x) =>
                formatOwnerName(
                  {
                    firstName: x.owner.firstName,
                    lastName: x.owner.lastName,
                    companyName: x.owner.companyName,
                  },
                  { lastNameFirst: true }
                )
              )
              .join(', ');
          },
          cellRenderer: (params: ICellRendererParams<ListStatement>) => {
            return (
              <Box ml='xs' sx={{ display: 'block', overflow: 'hidden' }}>
                <Text
                  size='sm'
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {params.value}
                </Text>
                <Text
                  size='xs'
                  color='gray'
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {params.data?.listing?.name}
                </Text>
              </Box>
            );
          },
        },

        {
          field: 'listing.name',
          hide: true,
        },
        // { field: 'endAt', hide: true },
        // {
        //   field: 'startAt',
        //   headerName: 'Start / End',
        //   valueGetter: ({ data, node }) => {
        //     if (node?.rowPinned === 'bottom') return undefined;
        //     const formatWithYear = (value: string) =>
        //       dayjs(value).utc().format('MMM DD YYYY');
        //     const formatNoYear = (value: string) =>
        //       dayjs(value).utc().format('MMM DD');
        //     return formatNoYear(data.startAt) + ' - ' + formatWithYear(data.endAt);
        //   },
        // },
        {
          headerName: 'Starting Balance',
          field: 'amounts.startingBalance',
          flex: 1,
          type: 'numericColumn',
          valueFormatter: formatNumberColumn,
        },
        {
          headerName: 'Net Income',
          field: 'amounts.netIncome',
          flex: 1,
          type: 'numericColumn',
          valueFormatter: formatNumberColumn,
        },
        {
          headerName: 'Current Balance',
          field: 'amounts.currentBalance',
          flex: 1,
          type: 'numericColumn',
          valueFormatter: formatNumberColumn,
        },
        {
          headerName: 'Payout',
          field: 'amounts.payout',
          flex: 1,
          type: 'numericColumn',
          valueFormatter: formatNumberColumn,
        },
        {
          headerName: 'Ending Balance',
          field: 'amounts.endingBalance',
          flex: 1,
          type: 'numericColumn',
          valueFormatter: formatNumberColumn,
        },
        {
          field: 'status',
          enableRowGroup: true,
          rowGroup: true,
          hide: true,
          cellStyle: { alignItems: 'flex-end' },
          cellRenderer: ({
            value,
            node,
          }: ICellRendererParams<ListStatement>) => {
            if (node?.rowPinned === 'bottom') return undefined;

            return <StatementStatusBadge status={value} />;
          },
          type: 'rightAligned',
        },
        {
          colId: 'menu',
          cellStyle: { display: 'grid', placeContent: 'center' } as any,
          maxWidth: 50,
          width: 50,
          suppressMenu: true,
          sortable: false,
          resizable: false,
          suppressMovable: true,
          pinned: 'right',
          cellRenderer: ({
            node,
            data,
            context,
          }: ICellRendererParams<ListStatement>) => {
            if (node?.rowPinned === 'bottom') return undefined;

            const ownerId = (data?.owners || [])?.[0]?.owner?.id;

            return (
              <StatementEllipsisMenu
                listingId={data?.listing?.id}
                date={data?.startAt}
                ownerId={ownerId}
                statementId={data?.id}
                statementTemplateId={data?.templateId}
                status={data?.status}
                onStatementDeleted={context.refetch}
                onStatementGenerated={context.refetch}
              />
            );
          },
        },
      ],
      { memoKeys: [rowLimit, selectable], table: 'statements' }
    );

    const getRowStyle = useCallback((params: any) => {
      if (params.node.rowPinned) {
        return { fontWeight: 'bold' };
      }
    }, []);

    const pinnedBottomRow = useMemo(() => {
      const currency = ownerStatements[0]?.currency;
      const totals = ownerStatements.map((i) => ({ ...i.amounts }));

      const getTotal = (
        key: keyof typeof ownerStatements[number]['formatted']
      ) => {
        if (totals.some((i) => typeof i[key] === 'number')) {
          return formatCurrency(sum(totals, key), currency);
        } else {
          return '-';
        }
      };

      const currentBalance = getTotal('currentBalance');
      const startingBalance = getTotal('startingBalance');
      const endingBalance = getTotal('endingBalance');
      const netIncome = getTotal('netIncome');
      const payout = getTotal('payout');

      return {
        formatted: {
          currentBalance,
          startingBalance,
          netIncome,
          payout,
          endingBalance,
          currency,
        },
      };
    }, [ownerStatements]);

    return (
      <Table
        ref={ref}
        columnDefs={columnDefs}
        defaultColDef={{
          resizable: false,
          sortable: false,
          minWidth: 100,
          flex: 1,
          cellStyle: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          },
        }}
        context={{
          refetch,
        }}
        rowData={ownerStatements}
        groupDisplayType='groupRows'
        groupDefaultExpanded={1}
        // rowGroupPanelShow="always"
        onColumnRowGroupChanged={({ api }) => api.sizeColumnsToFit()}
        groupRowRendererParams={{
          innerRenderer: GroupRowInnerRenderer,
          suppressCount: true,
        }}
        animateRows={true}
        rowStyle={{ cursor: 'pointer' }}
        noRowsOverlayComponent={() => (
          <NoStatementsTableOverlay text='No statements available.' />
        )}
        getRowStyle={getRowStyle}
        getRowClass={(params) => {
          if (rowLimit && params.rowIndex > rowLimit) return 'limit-blurred';
        }}
        gridOptions={{
          suppressCellFocus: true,
          rowSelection: 'multiple',
          suppressRowClickSelection: true,
          rowHeight: 55,
          defaultExcelExportParams: { allColumns: true },
          defaultCsvExportParams: { allColumns: true },
        }}
        alwaysShowVerticalScroll
        onRowSelected={
          onRowSelected
            ? (event) => {
                const rows = event.api.getSelectedRows();
                onRowSelected(rows.map((i) => i.id || ''));
              }
            : undefined
        }
        onCellClicked={(params: CellClickedEvent<ListStatement>) => {
          if (
            params.node.rowPinned === 'bottom' ||
            params.column.getColId() === 'menu'
          )
            return;

          if (rowLimit && (params.rowIndex || 0) > rowLimit - 1) return;

          const ownerId = params.data?.owners?.[0]?.owner.id;
          const withOwner = ownerId ? `&statementOwner=${ownerId}` : '';

          if (selectable && params.column.getColId() === 'owner') {
            params.node.setSelected(!params.node.isSelected());
          } else {
            navigate(
              `/statements/${params.data?.listing?.id}?date=${utc(
                params.data?.startAt
              ).format('YYYY-MM-DD')}${withOwner}`
            );
          }
        }}
        pinnedBottomRowData={[pinnedBottomRow]}
        suppressAggFuncInHeader={true}
        {...tableStylingProps}
      />
    );
  }
);

StatementListTable.displayName = 'StatementListTable';

const GroupRowInnerRenderer = (params: any) => {
  const currency = params.node.allLeafChildren[0]?.data?.currency;

  const total = params.node.aggData?.centTotal
    ? formatCurrency(params.node.aggData.centTotal / 100, currency)
    : '';

  return (
    <Group position='apart' spacing={40}>
      <StatementStatusBadge status={params.node.key} />
      <Text weight={'bold'}>{total}</Text>
    </Group>
  );
};
