import {
  useNotifiedV2Mutation,
  useTeamRole,
  useV2TransactionQuery,
} from '../../../../hooks';
import {
  DeleteModal,
  EllipsisMenu,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  ListingMigrationModal,
  SelectItem,
  showErrorNotification,
} from '@finalytic/ui';
import {
  faArrowRightArrowLeft,
  faCopy,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { Box, Tooltip } from '@mantine/core';
import { useClipboard, useDisclosure } from '@mantine/hooks';
import { ReactNode } from 'react';

function useMutations(props?: { successMessage?: string }) {
  const successMessage =
    props?.successMessage || 'You successfully removed the listing!';

  const { loading, mutate, error } = useNotifiedV2Mutation(
    (
      q,
      args:
        | { listingId: string; type: 'remove' }
        | { listingId: string; type: 'migrate'; newListingId: string }
    ) => {
      if (args.type === 'remove') {
        const res = q.deleteListing({ id: args.listingId });

        return {
          ok: !!res?.id,
        };
      }

      if (args.type === 'migrate') {
        const statements = q.updateOwnerStatements({
          where: {
            listingId: { _eq: args.listingId },
          },
          _set: { listingId: args.newListingId },
        });

        return {
          ok: !!statements?.affected_rows,
          statements: statements?.returning.map((st) => st.id),
        };
      }
    },
    {
      successMessage: { message: successMessage },
      invalidateQueryKeys: ['listings'],
    }
  );

  return {
    loading,
    mutate,
    error,
  };
}

type SharedProps = {
  listingId: string;
  listingTeamId: string | undefined;
  listingName: string | undefined | null;
  onSuccess: () => void;
};

type ListingMenuButtonProps = {
  isPMSListing: boolean;
  listingConnections: { id: string }[];
  listingStatements: { id: string }[];
};

export const ListingMenuButton = ({
  listingId,
  listingName,
  listingTeamId,
  onSuccess,
  isPMSListing,
  listingConnections,
  listingStatements,
}: ListingMenuButtonProps & SharedProps) => {
  const { isSuperAdmin } = useTeamRole();

  const { copy } = useClipboard();

  const [opened, handlers] = useDisclosure(false);
  const [openedMigration, handlersMigration] = useDisclosure(false);

  const { mutate } = useMutations();
  const removeListing = async (id: string) => {
    const res = await mutate({ args: { listingId: id, type: 'remove' } });

    if (res?.ok && onSuccess) onSuccess();
  };

  const hasListingConnections = listingConnections.length > 0;
  const disableRemoving = hasListingConnections || isPMSListing;

  const deleteTooltipLabel = hasListingConnections
    ? 'Remove the listing connections from this listing to delete it.'
    : 'This listing is synced by the PMS.';

  return (
    <>
      <EllipsisMenu width={200}>
        {listingStatements.length > 0 && (
          <EllipsisMenuItem
            onClick={handlersMigration.open}
            icon={faArrowRightArrowLeft}
          >
            Migrate Statements
          </EllipsisMenuItem>
        )}
        {isSuperAdmin && (
          <>
            <EllipsisMenuItem icon={faCopy} onClick={() => copy(listingId)}>
              Copy Listing ID (SuperAdmin)
            </EllipsisMenuItem>
            <EllipsisMenuDivider />
          </>
        )}
        <DisabledTooltip label={deleteTooltipLabel} disabled={!disableRemoving}>
          <EllipsisMenuItem
            onClick={handlers.open}
            disabled={disableRemoving}
            icon={faTrash}
            color='red'
            iconColor='red'
          >
            Delete Listing
          </EllipsisMenuItem>
        </DisabledTooltip>
      </EllipsisMenu>
      {/* <Tooltip
        label={
          hasListingConnections
            ? 'Remove the listing connections from this listing to delete it.'
            : 'This listing is synced by the PMS.'
        }
        withArrow
        withinPortal
        disabled={!disabled}
      >
        <Box
          sx={{
            cursor: disabled ? 'not-allowed' : undefined,
          }}
        >
          <IconButton disabled={disabled} onClick={handlers.open} sx={{}}>
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </Box>
      </Tooltip> */}
      <DeleteModal
        opened={opened}
        onClose={handlers.close}
        title={`Are you sure to remove ${
          listingName ? listingName : 'this listing'
        }?`}
        subtitle='This will remove this listing permanently from your team.'
        onSubmit={() => removeListing(listingId)}
      />
      <MigrationModal
        close={handlersMigration.close}
        opened={openedMigration}
        listingId={listingId}
        listingTeamId={listingTeamId}
        listingName={listingName}
        onSuccess={onSuccess}
      />
    </>
  );
};

const MigrationModal = ({
  listingTeamId,
  listingId,
  listingName,
  onSuccess,
  ...props
}: {
  opened: boolean;
  close: () => void;
} & SharedProps) => {
  const { mutate } = useMutations({
    successMessage: 'You successfully migrated the listing!',
  });

  const { data: listings, isLoading: loading } = useV2TransactionQuery(
    (q, args) => {
      return q
        .listings({
          where: {
            tenantId: { _eq: args.teamId },
            id: { _neq: args.listingId },
            // _not: { ownerStatements: {} },
          },
          order_by: [{ name: 'asc_nulls_last' }],
        })
        .map<SelectItem>((listing) => ({
          value: listing.id,
          label: listing.name || '',
        }));
    },
    {
      skip: !listingTeamId,
      variables: {
        teamId: listingTeamId,
        listingId,
      },
    }
  );

  const submit = async (newListingId: string) => {
    if (!listingId)
      return showErrorNotification({ message: 'Missing listing id.' });
    const res = await mutate({
      args: { listingId, type: 'migrate', newListingId },
    });
    if (res?.ok && onSuccess) onSuccess();
  };

  return (
    <ListingMigrationModal
      {...props}
      onSubmit={submit}
      selectOptions={listings || []}
      loading={loading}
      listingName={listingName}
    />
  );
};

const DisabledTooltip = ({
  disabled,
  label,
  children,
}: {
  disabled: boolean;
  label: ReactNode;
  children: ReactNode;
}) => {
  return (
    <Tooltip label={label} withArrow withinPortal disabled={disabled}>
      <Box
        sx={{
          cursor: disabled ? 'not-allowed' : undefined,
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
