import {
  useDashboardType,
  useMe,
  usePartnerTeams,
  useTeamId,
  useV2TransactionQuery,
} from '../../../hooks';
import { ReservationsFilterParams } from '../_types';
import {
  CalendarFilterDateType,
  CheckInOutType,
  DateFilter,
  FilterWrapper,
  ResetFilterButton,
  SwitchFilter,
  SwitchFilterDataType,
} from '@finalytic/ui';
import { toTitleCase } from '@finalytic/utils';
import { useMemo } from 'react';

type SwitchFilterItem = SwitchFilterDataType[number];

function useReservationFilters() {
  const [teamId] = useTeamId();
  const [teams] = usePartnerTeams();
  const { ownerships } = useMe();

  const {
    data,
    isLoading: loading,
    refetch,
  } = useV2TransactionQuery(
    (q, args) => {
      // const statuses = q
      //   .reservations({
      //     where: {
      //       tenantId: { _in: args.teamIds },
      //     },
      //     distinct_on: ['status'],
      //     order_by: [{ status: 'asc_nulls_last' }],
      //   })
      //   .map<SwitchFilterItem>((res) => ({
      //     label: res.status || '',
      //     value: res.status || '',
      //   }));

      const connections = q
        .connection({
          where: { tenantId: { _in: args.teamIds }, reservations: {} },
          order_by: [{ name: 'asc' }],
        })
        .map<SwitchFilterItem>((res) => ({
          value: res.id,
          label: res.name || 'Missing connection name',
        }));

      const listings = q
        .listings({
          where: {
            tenantId: { _in: args.teamIds },
            id: { _in: ownerships.map((i) => i.listingId) || [] },
          },
          order_by: [{ name: 'asc' }],
        })
        .map<SwitchFilterItem>((res) => ({
          value: res.id,
          label: res.name || '',
        }));

      const bookingChannels = q
        .bookingChannels({
          where: {
            reservations: {
              tenantId: { _in: args.teamIds },
            },
          },
        })
        .map<SwitchFilterItem>((res) => ({
          value: res.id,
          label: toTitleCase(res.uniqueRef) || 'Missing booking channel',
        }));

      // const types = q
      //   .result({
      //     where: { tenant_id: { _in: args.teamIds } },
      //     distinct_on: ['type'],
      //   })
      //   .map<SelectItem>((res) => ({ value: res.type, label: res.type || '' }));

      return {
        bookingChannels,
        connections,
        listings,
      };
    },
    {
      variables: {
        teamIds: teams.length > 0 ? teams.map((i) => i.id) : [teamId],
        ownerships,
      },
    }
  );
  const partnerTeams = useMemo<SwitchFilterDataType>(
    () => teams.map((i) => ({ label: i.name, value: i.id })),
    [teams]
  );

  /*const statusTypes: SwitchFilterDataType = useMemo(
    () =>
      (data?.reservations || [])
        .map((i) => ({
          label: i.status || '',
          value: i.status || '',
        }))
        .filter((i) => !!i.value),
    [data?.reservations]
  );*/

  return {
    // statusTypes,
    connectionTypes: data?.connections || [],
    partnerTeams,
    listings: data?.listings || [],
    bookingChannels: data?.bookingChannels || [],
    loading: loading,
  };
}

type ReservationFilterProps = {
  hide?: ('bookingChannel' | 'connection' | 'status')[];
  filterState: ReservationsFilterParams;
  setFilterState: (state: Partial<ReservationsFilterParams>) => void;
};

export const ReservationFilter = ({
  filterState,
  setFilterState,
  hide,
}: ReservationFilterProps) => {
  const [dashboard] = useDashboardType();

  const {
    // statusTypes,
    connectionTypes,
    partnerTeams,
    listings,
    loading,
    bookingChannels,
  } = useReservationFilters();

  const setTeamIdFilter = (value?: string) => setFilterState({ teamId: value });
  const setListingIdFilter = (value?: string) =>
    setFilterState({ listingId: value });
  // const setStatusFilter = (value?: string) => setFilterState({ status: value });
  const setConnectionFilter = (value?: string) =>
    setFilterState({ connectionId: value });
  const setBookingChannelFilter = (value?: string) =>
    setFilterState({ bookingChannel: value });
  const setDateTypeFilter = (value: CheckInOutType) =>
    setFilterState({ dateType: value });
  const setDateFilter = (value: CalendarFilterDateType | undefined) =>
    setFilterState({
      date: value,
    });

  return (
    <FilterWrapper>
      {/* Team */}
      {partnerTeams.length > 0 && (
        <SwitchFilter
          defaulButtonLabel={'Team'}
          data={partnerTeams}
          filterValue={filterState.teamId}
          loading={loading}
          setFilterValue={setTeamIdFilter}
          withSearch
        />
      )}

      {/* Status */}
      {/*!hide?.includes('status') && (
        <SwitchFilter
          defaulButtonLabel={'Status'}
          data={statusTypes}
          filterValue={filterState.status}
          loading={loading}
          setFilterValue={setStatusFilter}
          icon={StatusIcon}
          withSearch={false}
        />
      )*/}

      {/* Cent Total */}
      {/* <AmountFilter
        filterValue={filterState.amount}
        defaultButtonLabel="Amount"
        setFilterValue={setAmountFilter}
      /> */}

      {/* Check In/Out */}
      <DateFilter
        defaulButtonLabel='Date'
        filterValue={filterState.date}
        setFilterValue={setDateFilter}
        dateType={filterState.dateType}
        setDateType={setDateTypeFilter}
      />

      {dashboard !== 'owner' && (
        <>
          {/* Integrations / Apps */}
          {/* {!hide?.includes('app') && (
            <SwitchFilter
              defaulButtonLabel={'Integration'}
              data={appTypes}
              filterValue={filterState.connectionApp}
              setFilterValue={setAppFilter}
              loading={loading}
              withSearch
            />
          )} */}

          {/* Connections */}
          {!hide?.includes('connection') && (
            <SwitchFilter
              defaulButtonLabel={'Connection'}
              data={connectionTypes}
              filterValue={filterState.connectionId}
              setFilterValue={setConnectionFilter}
              loading={loading}
              withSearch
            />
          )}

          {/* Booking Channels */}
          {!hide?.includes('bookingChannel') && (
            <SwitchFilter
              defaulButtonLabel={'Booking Channel'}
              data={bookingChannels}
              filterValue={filterState.bookingChannel}
              setFilterValue={setBookingChannelFilter}
              loading={loading}
              withSearch
            />
          )}
        </>
      )}

      {dashboard === 'owner' && (
        <SwitchFilter
          defaulButtonLabel={'Listing'}
          data={listings}
          filterValue={filterState.listingId}
          setFilterValue={setListingIdFilter}
          loading={loading}
          withSearch
        />
      )}

      <ResetFilterButton
        hide={!Object.values(filterState).some((i) => !!i)}
        onClick={() =>
          setFilterState({
            connectionId: undefined,
            date: undefined,
            search: undefined,
            teamId: undefined,
            listingId: undefined,
            // status: undefined,
            dateType: undefined,
          })
        }
      >
        Reset
      </ResetFilterButton>
    </FilterWrapper>
  );
};
