import { gql, useDashboardType, useTeam } from '../../../hooks';
import {
  ReservationType,
  useReservationsDatasource,
} from './useReservationsDatasource';
import { reservation_bool_exp } from '@finalytic/graphql';
import {
  InfoButtonCell,
  LoadingIndicator,
  NoReservationsTableOverlay,
  ResultStatusCell,
  SRMHeaderSelectCell,
  StatusPill,
  StatusPillType,
  StringParam,
  Table,
  TableContainer,
  TableStylingProps,
  TeamIconCell,
  formatCurrency,
  useQueryParamSet,
  useSavedColumnDefs,
} from '@finalytic/ui';
import {
  AgGridReact,
  GridSizeChangedEvent,
  ICellRendererParams,
  ValueFormatterParams,
} from '@finalytic/ui-grid';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Group, Sx, Text, useMantineTheme } from '@mantine/core';
import { utc } from 'dayjs';
import { ReactNode, forwardRef } from 'react';

type Props = {
  limit: number | undefined;
  where: reservation_bool_exp;
  rowSelectable?: boolean;
  multiSelect?: boolean;
  onSelectAllPages?: (selected: boolean) => void;
} & TableStylingProps;

export const ReservationTable = forwardRef<AgGridReact, Props>(
  (
    {
      where,
      rowSelectable = false,
      multiSelect = false,
      limit,
      onSelectAllPages,
      ...tableStyling
    },
    ref
  ) => {
    const { primaryColor: appName } = useMantineTheme();

    const cellMinWidth = 150;

    const [dashboardType] = useDashboardType();
    const [{ featureFlags }] = useTeam();
    const isTeamDashboard = ['partner'].includes(dashboardType);

    const showAutomationColumn =
      dashboardType !== 'owner' && featureFlags.includes('mappingsV2');

    const setSearchParams = useQueryParamSet('reservation', StringParam);

    const openDrawer = (id: string) => {
      setSearchParams(id);
    };

    const columnDefs = useSavedColumnDefs(
      [
        {
          checkboxSelection: rowSelectable,
          field: 'connection.name',
          headerName: 'Connection',
          headerComponent: SRMHeaderSelectCell,
          headerComponentParams: { onSelectAllPages },
          cellRenderer: ({ value, data }: ICellRendererParams) => {
            return (
              <CustomCell
                line1={value}
                icon={data?.connection?.app?.iconRound}
                line2={data?.listingConnection?.listing?.name}
              />
            );
          },
          // maxWidth: 200,
          flex: 1,
          minWidth: 180,
        },

        {
          field: 'results',
          headerName: 'Automations',
          minWidth: cellMinWidth,
          flex: 1,
          resizable: true,
          hide: !showAutomationColumn,
          cellClass: 'no-scrollbar',
          cellStyle: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
          },
          cellRenderer: (params: ICellRendererParams) => {
            const results = params.data.actions?.length
              ? params.data.actions
              : params.value;
            if (results?.length < 1) return null;
            return (
              <Group
                noWrap
                sx={{ overflowX: 'hidden', lineHeight: 'initial' }}
                spacing={4}
                position='left'
              >
                {results?.map((result: any) => (
                  <ResultStatusCell
                    key={result.id}
                    status={result.status}
                    value={result.type?.replace('create', '')}
                    message={result.message}
                  />
                ))}
              </Group>
            );
          },
        },

        {
          field: 'tenant.name',
          suppressMenu: true,
          hide: !isTeamDashboard,
          headerName: '',
          resizable: false,
          width: rowSelectable ? 100 : 70,
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }),
          cellRenderer: ({ data }: ICellRendererParams<ReservationType>) => (
            <TeamIconCell
              name={data?.tenant?.name}
              logo={data?.tenant?.logo}
              colorPrimary={data?.tenant?.colorPrimary}
            />
          ),
        },

        {
          field: 'checkIn',
          maxWidth: 200,
          minWidth: cellMinWidth,
          headerName: 'Check In/Out',
          sort: 'desc',
          cellRenderer: ({ data }: ICellRendererParams) => {
            const line1 = `${utc(data?.checkIn).format('DD. MMM')} -
        ${utc(data?.checkOut).format('DD. MMM. YYYY')}`;
            const line2 = `${data?.nights || 0} nights, on ${utc(
              data?.bookedAt
            ).format('DD. MMM')}`;

            return <CustomCell line1={line1} line2={line2} />;
          },
        },
        {
          field: 'checkOut',
          hide: true,
        },
        {
          field: 'bookedAt',
          hide: true,
        },
        {
          field: 'guestName',
          headerName: 'Guest Name',
          flex: 1,
          minWidth: cellMinWidth,
          cellRenderer: (params: ICellRendererParams) => {
            return (
              <CustomCell
                icon={<FontAwesomeIcon icon={faUser} size='lg' />}
                line1={params.value}
                line2={params.data?.confirmationCode}
              />
            );
          },
        },

        {
          field: 'status',
          width: 120,
          minWidth: 120,
          cellRenderer: (params: ICellRendererParams<ReservationType>) => {
            let color: StatusPillType = 'yellow';
            let value: `${gql.reservation_status_enum}` = params.value;

            switch (value) {
              case 'booked':
                color = 'green';
                break;
              case 'cancelled':
                color = 'red';
                break;

              case 'inquired':
                color = 'yellow';
                break;

              case 'inquiry':
                color = 'yellow';
                break;
              case 'payed':
                value = 'booked';
                color = 'green';
                break;
              default:
                break;
            }

            if (!value) return null;

            return (
              <Box>
                <StatusPill type={color} label={value} />
              </Box>
            );
          },
        },

        {
          field: 'paid',
          type: 'numericColumn',
          flex: 1,
          maxWidth: 130,
          minWidth: 110,
          resizable: false,
          valueFormatter: ({
            data,
            value,
          }: ValueFormatterParams<ReservationType>) =>
            formatCurrency((value || 0) / 100, data?.currency, appName),
        },

        {
          field: 'total',
          resizable: false,
          flex: 1,
          maxWidth: 160,
          minWidth: 140,
          type: 'numericColumn',
          valueFormatter: ({
            data,
            value,
          }: ValueFormatterParams<ReservationType>) =>
            formatCurrency((value || 0) / 100, data?.currency, appName),
          cellRenderer: ({ data, valueFormatted }: ICellRendererParams) => {
            return (
              <CustomCell
                // icon={<FontAwesomeIcon icon={faUser} size="lg" />}
                line1={valueFormatted || ''}
                line2={
                  data.unpaid
                    ? `${formatCurrency(
                        (data.unpaid || 0) / 100,
                        data?.currency,
                        appName
                      )} unpaid`
                    : undefined
                }
                sx={{ textAlign: 'right', '> div': { flex: 1 } }}
              />
            );
          },
        },
        // {
        //   field: 'unpaid',
        //   type: 'numericColumn',
        //   flex: 1,
        //   maxWidth: 130,
        //   minWidth: 110,
        //   resizable: false,
        //   valueFormatter: ({
        //     data,
        //     value,
        //   }: ValueFormatterParams<ReservationType>) =>
        //     formatCurrency((value || 0) / 100, data?.currency, appName),
        // },
        {
          field: 'info',
          sortable: false,
          resizable: false,
          hide: dashboardType === 'owner' || !rowSelectable,
          width: 60,
          headerName: '',
          headerTooltip: 'tool',
          cellStyle: { display: 'grid', placeContent: 'center' } as any,
          cellRenderer: (params: ICellRendererParams) => (
            <InfoButtonCell
              mt={0}
              onIconClick={() => {
                openDrawer(params.data.id);
              }}
            />
          ),
          suppressSizeToFit: true,
        },
      ],
      { memoKeys: [dashboardType, appName], table: 'reservations' }
    );

    const { dataSource, paginationSize } = useReservationsDatasource({
      where,
      billingLimit: limit,
    });

    const gridSizeChanged = ({ api, clientWidth }: GridSizeChangedEvent) => {
      if (clientWidth > 600) api.sizeColumnsToFit();
    };

    return (
      <TableContainer>
        <Table
          ref={ref}
          columnDefs={columnDefs}
          serverSideInfiniteScroll
          defaultColDef={{
            sortable: false,
            cellStyle: {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
          }}
          rowSelection={multiSelect ? 'multiple' : 'single'}
          rowModelType='serverSide'
          rowStyle={{ cursor: 'pointer' }}
          getRowClass={(params) => {
            if (limit && params.rowIndex > limit - 1) return 'limit-blurred';
          }}
          cacheBlockSize={paginationSize}
          paginationPageSize={paginationSize}
          serverSideDatasource={dataSource}
          onGridSizeChanged={gridSizeChanged}
          getRowId={({ data }) => data.id}
          onRowClicked={({ data, rowIndex }) =>
            rowSelectable || (limit && (rowIndex || 0) > limit - 1)
              ? undefined
              : openDrawer(data.id)
          }
          gridOptions={{
            rowHeight: 55,
            suppressCellFocus: true,
            suppressRowClickSelection: true,
            suppressExcelExport: true,
            suppressCsvExport: true,
            onCellClicked: (e) => {
              if (e.column.getColId() !== 'info') {
                const selected = e.node.isSelected();
                e.node.setSelected(!selected);
              }
            },
          }}
          noRowsOverlayComponent={() => (
            <NoReservationsTableOverlay
              text={
                dashboardType === 'owner'
                  ? 'No Reservations Available'
                  : 'Add a connection to show reservations'
              }
            />
          )}
          loadingOverlayComponent={
            <LoadingIndicator isFullPageLoading={false} />
          }
          {...tableStyling}
        />
      </TableContainer>
    );
  }
);

ReservationTable.displayName = 'Reservation Table';

const CustomCell = ({
  line1,
  icon,
  line2,
  sx,
}: {
  line1: string;
  line2?: string;
  icon?: string | ReactNode;
  sx?: Sx;
}) => {
  return (
    <Group
      spacing={10}
      noWrap
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        height: '100%',
        ...sx,
      }}
    >
      {icon &&
        (typeof icon === 'string' ? (
          <Avatar size={30} src={icon || null} radius={100} />
        ) : (
          icon
        ))}
      <Box sx={{ display: 'block', overflow: 'hidden' }}>
        <Text size='sm' sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {line1}
        </Text>
        <Text
          size='xs'
          color='gray'
          sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {line2}
        </Text>
      </Box>
    </Group>
  );
};
