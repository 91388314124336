import { gqlV2, useNotifiedV2Mutation } from '../../../';
import { ActionButton, IconButton, Input, useColors } from '@finalytic/ui';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider,
  Group,
  Input as MantineInput,
  Stack,
  Sx,
  Text,
} from '@mantine/core';
import { ReactNode } from 'react';
import { Controller, useForm } from 'react-hook-form';

type FormInputs = {
  firstName?: string;
  lastName?: string;

  email: string;
  phone?: string;
  companyName?: string;

  addressLine1?: string;
  addressCity?: string;
  addressCountry?: string;
  addressPostcode?: string;

  userStatus?: string;
};

type EditOwnerFormProps = {
  ownerId: string;
  cancelEdit: () => void;
};

function useOwnerMutations() {
  const { mutate } = useNotifiedV2Mutation(
    (q, args: { id: string; input: gqlV2.user_set_input }) => {
      const res = q.updateUserById({
        pk_columns: { id: args.id },
        _set: args.input,
      });

      return {
        id: res?.id,
        firstName: res?.firstName,
        lastName: res?.lastName,
        email: res?.email,
        addressCountry: res?.addressCountry,
        addressCity: res?.addressCity,
        addressLine1: res?.addressLine1,
        addressPostcode: res?.addressPostcode,
        companyName: res?.companyName,
      };
    },
    {
      successMessage: {
        id: 'owner-update',
        title: 'Success!',
        message: 'Successfully updated this owner.',
      },
      invalidateQueryKeys: ['owners'],
    }
  );

  return {
    update: ({
      ownerId,
      input,
    }: {
      ownerId: string;
      input: gqlV2.user_set_input;
    }) => mutate({ args: { id: ownerId || 'x', input } }),
  };
}

export const EditOwnerForm = ({
  cancelEdit,
  email,
  ownerId,
  addressCity,
  addressCountry,
  addressLine1,
  addressPostcode,
  firstName,
  lastName,
  phone,
  companyName,
  userStatus,
}: FormInputs & EditOwnerFormProps) => {
  const { gray } = useColors();
  const { update } = useOwnerMutations();

  const {
    control,
    formState: { isDirty, isSubmitting, isValid },
    handleSubmit,
    watch,
  } = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      email,
      addressCity,
      addressCountry,
      addressLine1,
      addressPostcode,
      firstName,
      lastName,
      phone,
      companyName,
    },
  });

  const companyNameState = watch('companyName');
  const firstNameState = watch('firstName');
  const lastNameState = watch('lastName');

  const submit = async (data: FormInputs) => {
    const res = await update({
      ownerId,
      input: { ...data, email: data.email === email ? undefined : data.email },
    });

    if (res?.id) {
      cancelEdit();
    }
  };

  const requireCompany = !firstNameState && !lastNameState;
  const requireFirstName = !companyNameState && !lastNameState;
  const requireLastName = !companyNameState && !firstNameState;

  return (
    <>
      <Group position='apart' my='lg'>
        <Group noWrap spacing={5} pt={5}>
          <IconButton onClick={cancelEdit}>
            <FontAwesomeIcon icon={faChevronLeft} color='#000' />
          </IconButton>
          <Text size='lg' weight={500} color='#000'>
            Overview
          </Text>
        </Group>
        <ActionButton
          onClick={handleSubmit(submit)}
          loading={isSubmitting}
          disabled={!isDirty || !isValid || !ownerId}
        >
          Save
        </ActionButton>
      </Group>

      <Stack px={10}>
        <Controller
          control={control}
          name='email'
          rules={{ required: true }}
          render={({ field: { name, value, onChange } }) => (
            <InputWrapper name={name} label='Email'>
              <Input
                inputName={name}
                value={value}
                onChange={onChange}
                disabled={userStatus === 'active'}
                placeholderText='Enter email'
                type='email'
              />
            </InputWrapper>
          )}
        />

        <Group sx={{ '& > *': { flex: 1 } }}>
          <Controller
            control={control}
            name='firstName'
            rules={{ required: requireFirstName }}
            render={({ field: { name, value, onChange } }) => (
              <InputWrapper
                name={name}
                label='First Name'
                withAsterisk={requireFirstName}
              >
                <Input
                  inputName={name}
                  value={value}
                  onChange={onChange}
                  placeholderText='Enter first name'
                  type='text'
                />
              </InputWrapper>
            )}
          />

          <Controller
            control={control}
            name='lastName'
            rules={{ required: requireLastName }}
            render={({ field: { name, value, onChange } }) => (
              <InputWrapper
                name={name}
                label='Last Name'
                withAsterisk={requireLastName}
              >
                <Input
                  inputName={name}
                  value={value}
                  onChange={onChange}
                  placeholderText='Enter Last name'
                  type='text'
                />
              </InputWrapper>
            )}
          />
        </Group>

        <Controller
          control={control}
          name='companyName'
          rules={{ required: requireCompany }}
          render={({ field: { name, value, onChange } }) => (
            <InputWrapper
              name={name}
              label='Company Name'
              withAsterisk={requireCompany}
            >
              <Input
                inputName={name}
                value={value}
                onChange={onChange}
                placeholderText='Enter Company Name'
                type='text'
              />
            </InputWrapper>
          )}
        />

        <Controller
          control={control}
          name='phone'
          render={({ field: { name, value, onChange } }) => (
            <InputWrapper name={name} label='Phone'>
              <Input
                inputName={name}
                value={value}
                onChange={onChange}
                placeholderText='Enter phone'
                type='phone'
              />
            </InputWrapper>
          )}
        />
      </Stack>

      <Divider color={gray.light + 70} mt={30} mb={20} />

      <Text weight={500} px={10} size='lg' mb='sm' sx={{ letterSpacing: 0.4 }}>
        Address
      </Text>

      <Stack px={10}>
        <Controller
          control={control}
          name='addressLine1'
          render={({ field: { name, value, onChange } }) => (
            <InputWrapper name={name} label='Street'>
              <Input
                inputName={name}
                value={value}
                onChange={onChange}
                placeholderText='Enter street'
                type='text'
              />
            </InputWrapper>
          )}
        />
        <Group sx={{ '& > *': { flex: 1 } }}>
          <Controller
            control={control}
            name='addressPostcode'
            render={({ field: { name, value, onChange } }) => (
              <InputWrapper
                name={name}
                label='Post code'
                sx={{ maxWidth: 150 }}
              >
                <Input
                  inputName={name}
                  value={value}
                  onChange={onChange}
                  placeholderText='Enter post code'
                  type='text'
                />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name='addressCity'
            render={({ field: { name, value, onChange } }) => (
              <InputWrapper name={name} label='City'>
                <Input
                  inputName={name}
                  value={value}
                  onChange={onChange}
                  placeholderText='Enter city'
                  type='text'
                />
              </InputWrapper>
            )}
          />
        </Group>
        <Controller
          control={control}
          name='addressCountry'
          render={({ field: { name, value, onChange } }) => (
            <InputWrapper name={name} label='Country'>
              <Input
                inputName={name}
                value={value}
                onChange={onChange}
                placeholderText='Enter country'
                type='text'
              />
            </InputWrapper>
          )}
        />
      </Stack>
    </>
  );
};

const InputWrapper = ({
  name,
  label,
  children,
  sx,
  withAsterisk = false,
}: {
  children: ReactNode;
  label: string;
  name: string;
  sx?: Sx;
  withAsterisk?: boolean;
}) => {
  const { gray } = useColors();

  return (
    <MantineInput.Wrapper
      id={name}
      label={label}
      withAsterisk={withAsterisk}
      sx={{ flex: 1, ...sx }}
      styles={{
        label: {
          color: gray.dark,
          fontWeight: 500,
          fontSize: 13,
          marginBottom: 3,
        },
      }}
    >
      {children}
    </MantineInput.Wrapper>
  );
};
