import { Box, BoxProps } from '@mantine/core';
import { ReactNode } from 'react';

export const TableContainer = ({
  children,
  sx,
  ...props
}: {
  children: ReactNode;
} & BoxProps) => {
  return (
    <Box
      sx={{
        flex: 1,
        '.ag-cell': {
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            height: 0,
            width: 0,
          },
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
